import React from "react";
import "./UserFeedback.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { Link } from "react-router-dom";
import { FaAngleRight, FaEye } from "react-icons/fa";
import { FcFullTrash } from "react-icons/fc";
import ReactPaginate from 'react-paginate';

class UserFeedback extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      feedbacks : [],
      offset_value : 0,
      current_page : 0,
      per_page : 10,
      page_count : 0,
      page_no_input : 1
    }
    this.handle_page_click = this.handle_page_click.bind(this);
    this.getFeedbacks = this.getFeedbacks.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.feedback_page_ref = React.createRef();
  }

  componentDidMount(){
    if(this.props.history.location.state && typeof this.props.history.location.state == "object" && this.props.history.location.state.hasOwnProperty('offset_value')){
      this.setState({
        offset_value : this.props.history.location.state.offset_value,
        search_input : this.props.history.location.state.search_input,
        current_page : this.props.history.location.state.current_page,
        page_no_input : this.props.history.location.state.page_no_input
      },() => {
        window.scrollTo({
          top : this.feedback_page_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
        this.getFeedbacks();
      });
    }else{ 
      this.getFeedbacks();
    }
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    }); 
  }

  page_no_form_submit(e){
    window.scrollTo({
      top : this.feedback_page_ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getFeedbacks();
    });
  }

  
  getFeedbacks(){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API+"/api/userFeedbacks",
      data : this.context.bind_session({
        offset_value : this.state.offset_value,
      }),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      console.log(return_data);
      if(return_data.feedbacks.length){
        obj_this.setState({
          feedbacks : return_data.feedbacks,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page)
        }, () => {
          obj_this.context.loading(0);
          obj_this.feedback_page_ref.current.style.visibility = "visible";
          obj_this.feedback_page_ref.current.style.opacity = 1;
        });
      }else if(return_data.page_count){
        obj_this.setState({
          offset_value : (obj_this.state.current_page -1) * obj_this.state.per_page,
          current_page : obj_this.state.current_page-1,
        },() => {
          obj_this.getFeedbacks();
        })
      }else{
        obj_this.setState({
          feedbacks : [],
        });
        obj_this.context.loading(0);
        obj_this.feedback_page_ref.current.style.visibility = "visible";
        obj_this.feedback_page_ref.current.style.opacity = 1;
      }

     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
  }

  handle_page_click(e){
    window.scrollTo({
      top : this.feedback_page_ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getFeedbacks()
    });
  }

  render(){
    return(
      <div className="user_feedback_page_container page_container" ref={this.feedback_page_ref}>
        <div className="page_header">
          <div className="page_title">Feedbacks</div>
        </div>
        <div className="custom_table_theme1">
          <div className="table_row header">
            <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Name</div>
            <div className="table_cell notice_type">Email</div>
            <div className="table_cell datetime">Status</div>
            <div className="table_cell datetime">Date</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.feedbacks && this.state.feedbacks.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              // style={{backgroundColor:item.status == "PENDING"?"#ffff007a":""}}
            >
              <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
              <div className="table_cell">{item.name}</div>
              <div className="table_cell notice_type">{item.email}</div>
              <div className="table_cell">
                <div
                  className="status_button"
                  style={{
                    backgroundColor:
                      item.status == "NEW"
                        ? "#fae69d"
                        : "#e3f9dd",
                    color:
                      item.status == "VIEWED"
                        ? "#229a16"
                        : "rgb(240, 137, 26)",
                  }}
                >
                  {item.status}
                </div>
              </div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell" style={{minWidth:"115px"}}>
                <div className="action">
                  <Link className="tooltip approve" to={{
                    pathname:"/Dashboard/ViewFeedback/"+item.id,
                    previousState : {
                      offset_value : this.state.offset_value,
                      current_page : this.state.current_page,
                      search_input : this.state.search_input,
                      page_no_input : this.state.page_no_input,
                    }
                  }}>
                    <span className="tooltipText approve">View</span>
                    <FaEye
                      className="approve_button"/>
                  </Link>
                  <Link className="tooltip delete" to={{
                    pathname : "/Dashboard/ViewFeedback/"+item.id,
                    action : "delete",
                    previousState : {
                      offset_value : this.state.offset_value,
                      current_page : this.state.current_page,
                      search_input : this.state.search_input,
                      page_no_input : this.state.page_no_input,
                    }
                    }} style={{display:item.status == "NEW"?"none":"inline-block"}} >
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button"/>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.feedbacks.length?"none":"block"}}>No results.</div>
        </div>
        <div className="pagination_container" style={{display:this.state.feedbacks.length && this.state.page_count > 1?"block":"none"}}>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handle_page_click}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.current_page}
          />
          <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span>
              <FaAngleRight/>
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default UserFeedback;
UserFeedback.contextType = MyContext;