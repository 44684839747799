import './App.css';
import {BrowserRouter} from "react-router-dom";
import Grid from './components/Grid/Grid';
import Loading from './components/Loading/Loading';
import MyProvider from './components/MyProvider';
import MyContext from './components/MyContext';
import { Trans } from 'react-i18next';
import ScrollToTop from './ScrollToTop';


function App() {
  // const { t } = useTranslation();
  return (
    <MyProvider>
      <BrowserRouter>
        <ScrollToTop>
            <Grid />
            <Loading />
        </ScrollToTop>
      </BrowserRouter>
    </MyProvider>
  );
}

export default App;
// App.contextType = MyContext;