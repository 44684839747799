import React from "react";
import "./BlogCommentList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AddButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {Link} from "react-router-dom";
import {FcApproval, FcFullTrash} from 'react-icons/fc';
import ReactPaginate from 'react-paginate';
import { FaAngleRight } from "react-icons/fa";

class BlogCommentList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      blog_comments : [],
      offset_value : 0,
      current_page : 0,
      per_page : 10,
      page_count : 0,
      page_no_input : 1
    }
    this.approveComment = this.approveComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.getBlogComments = this.getBlogComments.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.blog_comment_list_page_Ref = React.createRef();
  }

  componentDidMount(){
    if(this.props.history.location.state && typeof this.props.history.location.state == "object" && this.props.history.location.state.hasOwnProperty('offset_value')){
      this.setState({
        offset_value : this.props.history.location.state.offset_value,
        search_input : this.props.history.location.state.search_input,
        current_page : this.props.history.location.state.current_page,
        page_no_input : this.props.history.location.state.page_no_input
      },() => {
        window.scrollTo({
          top : this.blog_comment_list_page_Ref.current.offsetTop - 80,
          behavior : "smooth"
        });
        this.getBlogComments();
      });
    }else{ 
      this.getBlogComments();
    }
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    }); 
  }

  page_no_form_submit(e){
    window.scrollTo({
      top : this.blog_comment_list_page_Ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getBlogComments();
    });
  }

  getBlogComments(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/getBlogCommentList",
      data : this.context.bind_session({
        offset_value : this.state.offset_value
      }),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        blog_comments : return_data.blog_comments,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page)
      }, () => {
        obj_this.context.loading(0);
        obj_this.blog_comment_list_page_Ref.current.style.visibility = "visible";
        obj_this.blog_comment_list_page_Ref.current.style.opacity = 1;
      });
      obj_this.context.setHistoryState({
        blog_comments : return_data.blog_comments,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
        offset_value : obj_this.state.offset_value,
        current_page : obj_this.state.current_page,
      },obj_this.props.history);
     }else{
        obj_this.context.loading(0);
        $.alert("Something Went Wrong. Please Try Again.");
     }
     obj_this.context.loading(0);
    }).fail(function(){
      $.alert("Network error");
    })
  }

  handle_page_click(e){
    this.blog_comment_list_page_Ref.current.style.opacity = 0;
    window.scrollTo({
      top : this.blog_comment_list_page_Ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getBlogComments()
    });
  }

  approveComment(comment_id){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveBlogComment",
      data: this.context.bind_session({ comment_id: comment_id}),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          blog_comments : return_data.blog_comments
        });
        $.alert("Comment approved.");
      } else {
        $.alert("Network error!!");
      }
    }).fail(function (return_data) {
      $.alert("Network error!!");
    });
  }

  deleteComment(comment_id){
    if(!window.confirm("Are you sure?")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteBlogComment",
      data: this.context.bind_session({ comment_id: comment_id}),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const blog_comments = obj_this.state.blog_comments.filter((item) => (item.id != comment_id));
        if(blog_comments.length){
          obj_this.setState({
            blog_comments : blog_comments
          });
        }else if(return_data.page_count){
          obj_this.setState({
            offset_value : (obj_this.state.current_page -1) * obj_this.state.per_page,
            current_page : obj_this.state.current_page-1,
          },() => {
            obj_this.getBlogComments();
          })
        }else{
          obj_this.setState({
            blog_comments : [],
          });
          obj_this.context.loading(0);
        }
        $.alert("Comment deleted.");
      } else {
        $.alert("Network error!!");
      }
    }).fail(function (return_data) {
      $.alert("Network error!!");
    });
  }


  render(){
    return(
      <div className="blog_comments_list_page_container page_container" ref={this.blog_comment_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Blog Comments</div>
        </div>
        <div className="custom_table_theme1">
          <div className="table_row header">
            <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell">Blog</div>
            <div className="table_cell notice_type">Comment</div>
            <div className="table_cell fullname">Commented By</div>
            <div className="table_cell">Status</div>
            <div className="table_cell">Published Date</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.blog_comments && this.state.blog_comments.map((item,idx) => (
            <div className="table_row body"
              key={idx} 
             >
              <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
              <div className="table_cell">{item.title}</div>
              <div className="table_cell notice_type">{item.comment}</div>
              <div className="table_cell">{item.name}</div>
              <div className="table_cell">
                <div
                    className="status_button"
                    style={{
                      backgroundColor:
                        item.status == "PENDING"
                          ? "#fae69d"
                          : item.status == "REJECTED"
                          ? "#E26868"
                          : item.status == "DELETED"
                          ? "#fcd7d7"
                          : "",
                      color:
                        item.status == "PENDING"
                          ? "#f0891a"
                          : item.status == "REJECTED"
                          ? "#fcfafa"
                          : item.status == "DELETED"
                          ? "#c41d1d"
                          : "",
                    }}
                  >
                    {item.status}
                  </div>
                </div>
                <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{visibility:item.status=="PENDING"?"visible":"hidden"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approveComment(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.deleteComment(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.blog_comments.length?"none":"block"}}>No results.</div>
        </div>
        <div className="pagination_container" style={{display:this.state.blog_comments.length && this.state.page_count > 1?"block":"none"}}>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handle_page_click}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.current_page}
          />
          <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span>
              <FaAngleRight/>
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default BlogCommentList;
BlogCommentList.contextType = MyContext;