import React from "react";

class FirstComponent extends React.Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    // console.log("Clearing state");
    this.props.history.replace({key : this.props.history.location.key, state : ""})
  }

  render(){
    return(
      <></>
    )
  }
}

export default FirstComponent;