import React from "react";
import "./NewFeedback.scss";
import {InputTheme1} from "../../res/styler/InputThemes/InputThemes";
import $ from "jquery";
import MyContext from "../MyContext";
import ReCAPTCHA from "react-google-recaptcha";

class Feedback extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      name : "",
      email : "",
      message : "",
      captcha_token: ""
    }

    this.form_change = this.form_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.feedback_page_Ref = React.createRef();
    this.captchaRef = React.createRef();
  }

  componentDidMount() {
    $(".header_container").addClass("transparent_header");
  }


  form_submit(e){
    e.preventDefault();
    // console.log(this.state);
    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();
    var obj_this = this;
    var feedback_details = this.state;
    feedback_details.captcha_token = token;
    if(feedback_details.captcha_token.length == 0){
      $.alert("Please verify you are a human!");
      return;
    }
    this.context.loading(1);
    feedback_details = JSON.stringify(this.state);
    $.ajax({
      url : process.env.REACT_APP_API + "/api/saveFeedback",
      data : {
        feedback_details : feedback_details
      },
      type : "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          name : "",
          email : "",
          message : "",
          captcha_token: ""
        });
        alert("Thank you for your valuable feedback.");
        window.scrollTo({
          top : obj_this.feedback_page_Ref.current.offsetTop,
          behavior : "smooth"
        });
        // obj_this.props.history.goBack();
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  form_change(e){
    if(e.target.name == "message"){
      // console.log(e.target.value.length)
      if(e.target.value.length <= 400){
        this.setState({
          message : e.target.value
        });
      }else{
        console.log(1);
        alert("Maximum characters exceeded")
      }
    }else{
      this.setState({
        [e.target.name] : e.target.value
      });
    }
  }
  
  componentWillUnmount() {
    $(".header_container").removeClass("transparent_header");
  }
  render(){
    return(
      <div className="feedback_page_container" ref={this.feedback_page_Ref}>
        <div className="feedback_page_ghost"></div>
        <div className="feedback_title">Feedback</div>
        
        <form className="feedback_form" onSubmit={this.form_submit}>
          <div className="feedback_content">
          We love to hear what YOU think of our website. We have gone 
            to enormous effort to produce this online platform. We 
            know that we can only improve and achieve our ultimate 
            vision of preserving your precious memories forever with 
            valuable input from our users. Please feel free to submit 
            your thoughts.
          </div>
          <InputTheme1
            label = "Name *"
            attrs = {{
              name : "name",
              type : "text",
              value : this.state.name,
              onChange : this.form_change,
              required : true
            }}
          />
          <InputTheme1
            label = "Email *"
            attrs = {{
              name : "email",
              type : "email",
              value : this.state.email,
              onChange : this.form_change,
              required : true
            }}
          />
          <div className="leave_message_label *">Leave a feedback *</div>
          <textarea
            name="message"
            value={this.state.message}
            rows={5}
            onChange={this.form_change}
            maxLength={400}
            required
          />
          <div className="maximum_character_label">Max 400 characters</div>
          <ReCAPTCHA 
                sitekey={process.env.REACT_APP_SITE_KEY} 
                ref={this.captchaRef}
                />
          <button className="save_message_button">
            <div className="button_ghost"></div>
            <div className="button_label">SEND</div>
          </button>
        </form>
      </div>
    )
  }
}

export default Feedback;
Feedback.contextType = MyContext;