import React from "react";
import "./NewCharity.scss";
import {
  InputTheme4,
} from "../../../res/styler/InputThemes/InputThemes";
import ImageUpload from "../../../res/styler/ImageUpload/ImageUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import { GrAdd } from "react-icons/gr";
import { VscChromeClose } from "react-icons/vsc";
import { BsEyeFill } from "react-icons/bs";

class NewCharity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      charity_id: "",
      name: "",
      email: "",
      phone_no1: "",
      phone_no2: "",
      mobile_no1: "",
      mobile_no1_code: "IN",
      mobile_no2: "",
      mobile_no2_code: "IN",
      locality: "",
      address: "",
      register_number: "",
      state: "",
      city: "",
      country: "",
      charity_description: "",
      editorState: EditorState.createEmpty(),
      image: "",
      image_src: "",
      states: [],
      countries: [],
      status: "",
      website: "",
      business_hours: "",
      whatsapp_no: "",
      whatsapp_no_code: "IN",
      gallery_images: [],
      gallery_image_src: "",
      logo_image_flag: 0,
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "",
      banks: [
        {
          account_type: "Domestic",
          account_details: "",
        },
      ],
      images: [],
      introduction: "",
      vision: "",
      mission: "",
      what_we_do: "",
      how_we_do: "",
      how_you_can_help: "",
      zoomed_item: {}
    };
    this.image_change = this.image_change.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.revert_image = this.revert_image.bind(this);
    this.country_change = this.country_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.form_change = this.form_change.bind(this);
    this.mobile_no1_change = this.mobile_no1_change.bind(this);
    this.mobile_no1_code_change = this.mobile_no1_code_change.bind(this);
    this.mobile_no2_change = this.mobile_no2_change.bind(this);
    this.mobile_no2_code_change = this.mobile_no2_code_change.bind(this);
    this.whatsapp_no_change = this.whatsapp_no_change.bind(this);
    this.whatsapp_no_code_change = this.mobile_no1_code_change.bind(this);
    this.gallery_image_change = this.gallery_image_change.bind(this);
    this.add_bank_details_row = this.add_bank_details_row.bind(this);
    this.account_type_change = this.account_type_change.bind(this);
    this.banks_change = this.banks_change.bind(this);
    this.delete_bank_details_row = this.delete_bank_details_row.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.gallery_image_change = this.gallery_image_change.bind(this);
    this.add_gallery_row = this.add_gallery_row.bind(this);
    this.delete_gallery_row = this.delete_gallery_row.bind(this);
    // this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.new_charity_page_ref = React.createRef();
    this.name_ref = React.createRef();
    this.register_no_ref = React.createRef();
    this.description_ref = React.createRef();
    this.address_ref = React.createRef();
    this.locality_ref = React.createRef();
    this.country_ref = React.createRef();
    this.state_ref = React.createRef();
    this.city_ref = React.createRef();
    this.mobile_no1_ref = React.createRef();
    this.about_ref = React.createRef();
    this.introduction_ref = React.createRef();
    this.vision_ref = React.createRef();
    this.mission_ref = React.createRef();
    this.whatsapp_no_ref = React.createRef();
    this.email_ref = React.createRef();
    this.logo_ref = React.createRef();
    this.image_input_ref = React.createRef();
    this.what_we_do_ref = React.createRef();
    this.how_we_do_ref = React.createRef();
    this.how_you_can_ref = React.createRef();
    this.bank_details_ref = React.createRef();
    this.reg_form_ref = React.createRef();
    this.gallery_images_ref = React.createRef();
    this.website_ref = React.createRef();
    this.facebook_ref = React.createRef();
    this.instagram_ref = React.createRef();
    this.twitter_ref = React.createRef();
    this.linkedin_ref = React.createRef();
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    const charity_id = this.props.match.params.charity_id;
    // console.log(this.props.location.pathname);
    if (
      this.props.location.pathname ==
      "/Dashboard/EditCharity/" + charity_id
    ) {
      $.ajax({
        url: process.env.REACT_APP_API + "/api/editCharity",
        data: this.context.bind_session({
          charity_id: charity_id,
        }),
        type: "POST",
      })
        .done(function (return_data) {
          console.log(return_data);
          if (return_data.status == "success") {
            const contentBlock = htmlToDraft(
              return_data.charity.charity_description
            );
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              const editorState = EditorState.createWithContent(contentState);
              return_data.charity.editorState = editorState;
            }
            for(let i =0; i<return_data.charity.gallery_images.length; i++){
              return_data.charity.gallery_images[i].gallery_image_src_old = process.env.REACT_APP_CDN_URL+"/"+"charities/gallery_images/"+return_data.charity.gallery_images[i].gallery_image_src
            }
            obj_this.setState(return_data.charity);
            obj_this.context.loading(0);
          } else {
            alert("Network error!!");
          }
        })
        .fail(function () {
          alert("Network error!!");
        });
    } else {
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getCharityCountries",
        data: this.context.bind_session({}),
        type: "POST",
      })
        .done(function (return_data) {
          // console.log(return_data);
          if (return_data.status == "success") {
            obj_this.setState({
              countries: return_data.countries,
            });
            obj_this.context.loading(0);
          } else {
            alert("Network error!!");
          }
        })
        .fail(function () {
          alert("Network error!!");
        });
    }
    $("form input[type=text]").on("keypress", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        $(this).nextAll("input:first").trigger("focus");
      }
    });
  }

  image_change(e) {
    const file = e.target.files[0];
    // if (!(file.size / 1024 > 2048)){
    this.setState({
      image: file,
    });
    // }
  }

  delete_image() {
    console.log('come on')
    this.setState({
      logo_image_flag: 1,
      image: "",
      image_src: ""
    });
  }

  revert_image() {
    this.setState({
      image: "",
    });
  }

  form_change(e) {
    if (e.target.name == "charity_description") {
      if (e.target.value.length > 400) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }
    if (e.target.name == "introduction") {
      if (e.target.value.length > 700) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }
    if (e.target.name == "vision") {
      if (e.target.value.length > 400) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }

    if (e.target.name == "mission") {
      if (e.target.value.length > 400) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }

    if (e.target.name == "what_we_do") {
      if (e.target.value.length > 400) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }

    if (e.target.name == "how_we_do") {
      if (e.target.value.length > 400) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }

    if (e.target.name == "how_you_can_help") {
      if (e.target.value.length > 400) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // onEditorStateChange(value) {
  //   // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
  //   this.setState({
  //     editorState: value,
  //     charity_description: draftToHtml(convertToRaw(value.getCurrentContent())),
  //   });
  // }

  mobile_no1_change(value) {
    this.setState({
      mobile_no1: value??"",
    });
  }

  mobile_no1_code_change(country) {
    const country_code = country ?? this.state.mobile_no1_code;
    this.setState({
      mobile_no1_code: country_code,
    });
  }

  mobile_no2_change(value) {
    this.setState({
      mobile_no2: value??"",
    });
    // console.log(value)
  }

  mobile_no2_code_change(country) {
    const country_code = country ?? this.state.mobile_no2_code;
    this.setState({
      mobile_no2_code: country_code,
    });
  }

  whatsapp_no_change(value) {
    this.setState({
      whatsapp_no: value ?? "",
    });
  }

  whatsapp_no_code_change(country) {
    const country_code = country ?? this.state.whatsapp_no_code;
    this.setState({
      whatsapp_no_code: country_code,
    });
  }

  country_change(e) {
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: this.context.bind_session({ country: e.target.value }),
      type: "POST",
    })
      .done(function (return_data) {
        // console.log(return_data.cities);
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          const country = obj_this.state.countries.find(
            (item) => item.id === e.target.value
          );
          obj_this.setState({
            states: return_data.states,
            cities: [],
            state: "",
            city: "",
            country: e.target.value,
          });
        } else {
          alert("Network error!!");
        }
      })
      .fail(function (return_data) {
        alert("Network error!!");
      });
  }

  state_change(e) {
    this.setState({
      state: e.target.value,
    });
  }

  gallery_image_change(e, idx) {
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
    var gallery_images = this.state.gallery_images;
    var gallery = gallery_images[idx];
    gallery.image = file;
    gallery.gallery_image_src =  URL.createObjectURL(file);
    this.setState({
      gallery_images: gallery_images
    });
    // }
  }

  add_gallery_row() {
    var gallery_images = this.state.gallery_images;
    for (var i = 0; i < gallery_images.length; i++) {
      if (gallery_images[i]['image'] || gallery_images[i]['gallery_image_src']) {

      } else {
        $.alert("Upload a photo to continue")
        return;
      }
    }
    var item = {
      image: "",
      gallery_image_src: "",
      gallery_image_caption: ""
    }
    gallery_images.push(item);
    this.setState({
      gallery_images: gallery_images
    });
  }

  delete_gallery_row(e,idx) {
    const table_row = $(e.target).parents(".single_image_container");
    table_row.find(".preview_image").attr("src",table_row.next().find(".preview_image").attr("src"));
    var gallery_images = this.state.gallery_images;
    gallery_images[idx]['image'] = undefined;
    gallery_images[idx]['gallery_image_src'] = undefined;
    const new_gallery_images = gallery_images.filter((item, index) => index != idx);
    this.setState({
      gallery_images: new_gallery_images
    });
  }

  add_bank_details_row(idx) {
    var banks = this.state.banks;
    var item = {
      account_type: "Domestic",
      account_details: "",
    };
    banks.splice(idx + 1, 0, item);
    this.setState({
      banks: banks,
    });
  }

  account_type_change(e, idx) {
    var banks = this.state.banks;
    var bank_detail = banks[idx];
    bank_detail.account_type = e.target.value;
    this.setState({
      banks: banks,
    });
  }

  banks_change(e, idx) {
    var banks = this.state.banks;
    var bank_detail = banks[idx];
    bank_detail["account_details"] = e.target.value;
    this.setState({
      banks: banks,
    });
  }

  delete_bank_details_row(idx) {
    var banks = this.state.banks;
    const new_banks = banks.filter((item, index) => index != idx);
    this.setState({
      banks: new_banks,
    });
  }

  form_submit(e) {
    e.preventDefault();
    if (!this.state.name) {
      $.alert("Name of Organisation Required");
      window.scrollTo({
        top: $(this.name_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.name_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (!this.state.register_number) {
      $.alert("Registration Number Required");
      window.scrollTo({
        top: $(this.register_no_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.register_no_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (!this.state.address) {
      $.alert("Address Required");
      window.scrollTo({
        top: $(this.address_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      // $(this.address_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (!this.state.locality) {
      $.alert("Locality Required");
      window.scrollTo({
        top: $(this.locality_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.locality_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (!this.state.country) {
      $.alert("Country Required");
      window.scrollTo({
        top: $(this.country_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (this.state.states.length && !this.state.state) {
      $.alert("State/Province/County/Region Required");
      window.scrollTo({
        top: $(this.state_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.city) {
      $.alert("City/Town Required");
      window.scrollTo({
        top: $(this.city_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.city_ref.current).addClass('input_theme4_SELECTED')
      return;
    }
    if (this.state.mobile_no1.length < 4) {
      $.alert("Mobile Number 1 Required");
      window.scrollTo({
        top: $(this.mobile_no1_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.charity_description) {
      $.alert("Description Required");
      window.scrollTo({
        top: $(this.description_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (this.state.image || this.state.image_src) {
    } else {
      $.alert("Logo Required");
      window.scrollTo({
        top: $(this.logo_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.introduction) {
      // console.log(this.about_ref.current.offsetTop);
      // console.log(this.introduction_ref.current.offsetTop);
      window.scrollTo({
        top: $(this.introduction_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $.alert("About Required");
      return;
    }
    if (!this.state.vision) {
      $.alert("Vision Required");
      window.scrollTo({
        top: $(this.vision_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.mission) {
      $.alert("Mission Required");
      window.scrollTo({
        top: $(this.mission_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (this.state.whatsapp_no.length < 4) {
      $.alert("Whatsapp Number Required");
      window.scrollTo({
        top: $(this.whatsapp_no_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.email) {
      $.alert("Email Required");
      window.scrollTo({
        top: $(this.email_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var re = /\S+@\S+\.\S+/;
    if (!re.test(this.state.email)) {
      $.alert("Please enter an email address");
      window.scrollTo({
        top: $(this.email_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.website) && (this.state.website) ) {
      $.alert("Please enter an valid Website URL");
      window.scrollTo({
        top: $(this.website_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.what_we_do) {
      $.alert('Required: "What we do"');
      window.scrollTo({
        top: $(this.what_we_do_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.how_we_do) {
      $.alert('Required: "How we do"');
      window.scrollTo({
        top: $(this.how_we_do_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.how_you_can_help) {
      $.alert('Required: "How you can help"');
      window.scrollTo({
        top: $(this.how_you_can_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var is_null_value = 0;
    var bank_details = this.state.banks;
    for (var i = 0; i < bank_details.length; i++) {
      if (!bank_details[i].account_details) {
        is_null_value = 1;
        break;
      }
    };
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.facebook) && (this.state.facebook) ) {
      $.alert("Please enter an valid Facebook URL");
      window.scrollTo({
        top: $(this.facebook_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.instagram) && (this.state.instagram) ) {
      $.alert("Please enter an valid Instagram URL");
      window.scrollTo({
        top: $(this.instagram_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.twitter) && (this.state.twitter) ) {
      $.alert("Please enter an valid Twitter URL");
      window.scrollTo({
        top: $(this.twitter_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.linkedin) && (this.state.linkedin) ) {
      $.alert("Please enter an valid LinkedIn URL");
      window.scrollTo({
        top: $(this.linkedin_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (is_null_value) {
      $.alert("Account Details Required");
      window.scrollTo({
        top: $(this.bank_details_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }

    const charity_details = $.extend({}, this.state);
    const form_data = new FormData();
    var gallery_images = charity_details.gallery_images;
    for (var i = 0; i < gallery_images.length; i++) {
      if (gallery_images[i].image || gallery_images[i].gallery_image_src) {

      } else {
        $.alert("It looks like the gallery image section is incomplete. Please complete this section to continue.");
        return;
      }
    }
    for (var i = 0; i < gallery_images.length; i++) {
      if (gallery_images[i].image || gallery_images[i].gallery_image_src) {
        if (charity_details.id) {
          form_data.append('gallery_images_src[' + i + ']', gallery_images[i].gallery_image_src); // change this to value
        }
        form_data.append('gallery_images_list[' + i + ']', gallery_images[i].image ?? ""); // change this to value
      } else {
        // $.alert("It looks like the gallery image section is incomplete. Please complete this section to continue.");
        return;
      }
      delete gallery_images[i]['gallery_image_src_old'];
    }
    form_data.append("charity_details", JSON.stringify(charity_details));
    form_data.append("image", this.state.image);
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
   
    console.log(gallery_images);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveCharityDetails",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    })
      .done(function (return_data) {
        console.log(return_data);
        obj_this.context.loading(0);
        if (return_data.status == "insert") {
          $.alert("Charity details added.");
          obj_this.props.history.push("/Dashboard/Charities/All");
        } else if (return_data.status == "update") {
          $.alert("Charity details Modified.");
          obj_this.props.history.push({
            pathname: "/Dashboard/Charities/All",
            state: obj_this.props.location.previousState ?? ""
          });
        } else if (return_data.status == "exist") {
          $.alert("Charity name already exist");
          obj_this.setState({
            name: "",
          });
          $("input[name='charity_description']").focus();
        } else {
          $.alert("Something Went Wrong. Please Try Again");
        }
      })
      .fail(function () {
        $.alert("Network error");
      });
  }

  render() {
    return (
      <div
        className="new_charity_page_container page_container"
        ref={this.new_charity_page_ref}
      >
        <div className="page_header">
          {this.state.id ? "Edit Charity Details" : "Add Charity Details"}
        </div>
        <form
          className="new_charity_form"
          encType="multipart/form-data"
          onSubmit={this.form_submit}
          ref={this.reg_form_ref}
        >
          <div className="charity_details">
            <div className="">
              <div className="business_details_container block">
                <div className="block_title">Enter Basic Details</div>
                <InputTheme4
                  label="Name of organisation *"
                  attrs={{
                    name: "name",
                    type: "text",
                    value: this.state.name,
                    onChange: this.form_change,
                  }}
                  input_ref={this.name_ref}
                />
                <InputTheme4
                  label="Registration Number *"
                  attrs={{
                    name: "register_number",
                    type: "text",
                    value: this.state.register_number,
                    onChange: this.form_change,
                  }}
                  input_ref={this.register_no_ref}
                />
                <div ref={this.address_ref}>
                  <div className="tooltip" style={{ fontSize: "16px", marginBottom: "5px" }}>Address *</div>
                  <textarea
                    name="address"
                    value={this.state.address}
                    onChange={this.form_change}
                    rows={5}

                  />
                </div>

                {/* <InputTheme4
                  label="Address *"
                  attrs={{
                    name: "address",
                    type: "text",
                    value: this.state.address,
                    onChange: this.form_change,
                  }}
                  input_ref={this.address_ref}
                /> */}
                <InputTheme4
                  label="Locality *"
                  attrs={{
                    name: "locality",
                    type: "text",
                    value: this.state.locality,
                    onChange: this.form_change,
                  }}
                  input_ref={this.locality_ref}
                />
                <FormControl
                  variant="standard"
                  style={{ display: "grid" }}
                  sx={{
                    m: 1,
                    minWidth: 120,
                    height: "55px",
                    margin: "20px 0px",
                  }}
                  ref={this.country_ref}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Country *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.country}
                    onChange={this.country_change}
                    label="Country"
                  >
                    {this.state.countries &&
                      this.state.countries.map((item, idx) => (
                        <MenuItem value={item.id} state={item.name} key={idx}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  style={{ display: "grid" }}
                  sx={{
                    m: 1,
                    minWidth: 120,
                    height: "55px",
                    margin: "20px 0px",
                  }}
                  ref={this.state_ref}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    State/Province/County/Region{" "}
                    {this.state.states.length ? "*" : ""}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.state}
                    onChange={this.state_change}
                    label="State"
                  >
                    {this.state.states &&
                      this.state.states.map((item, idx) => (
                        <MenuItem value={item.id} state={item.name} key={idx}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <InputTheme4
                  label="City/Town *"
                  attrs={{
                    name: "city",
                    type: "text",
                    value: this.state.city,
                    onChange: this.form_change,
                  }}
                  input_ref={this.city_ref}
                />
                <InputTheme4
                  label="Landline"
                  attrs={{
                    name: "phone_no1",
                    type: "text",
                    value: this.state.phone_no1,
                    onChange: this.form_change,
                    // pattern : "[789][0-9]{9}",
                    maxLength: 11,
                  }}
                />
                <div className="mobile_no_input_container">
                  <div
                    className="mobile_no_label"
                    style={{ color: this.state.mobile_no1 ? "#4f0662" : "" }}
                    ref={this.mobile_no1_ref}
                  >
                    Mobile Number 1 *
                  </div>
                  <PhoneInput
                    style={{
                      borderBottom: this.state.mobile_no1
                        ? "2px solid #4f0662"
                        : "",
                      color: this.state.mobile_no1 ? "#111" : "",
                    }}
                    placeholder="Phone number"
                    className="mobile_no1_input"
                    international
                    value={this.state.mobile_no1}
                    onChange={this.mobile_no1_change}
                    defaultCountry={this.state.mobile_no1_code}
                    onCountryChange={(country) =>
                      this.mobile_no1_code_change(country)
                    }
                  />
                </div>
                <div className="mobile_no_input_container">
                  <div
                    className="mobile_no_label"
                    style={{ color: this.state.mobile_no1 ? "#4f0662" : "" }}
                  >
                    Mobile Number 2
                  </div>
                  <PhoneInput
                    style={{
                      borderBottom: this.state.mobile_no2
                        ? "2px solid #4f0662"
                        : "",
                      color: this.state.mobile_no2 ? "#111" : "",
                    }}
                    placeholder="Phone number"
                    international
                    className="mobile_no2_input"
                    value={this.state.mobile_no2}
                    onChange={this.mobile_no2_change}
                    defaultCountry={this.state.mobile_no2_code}
                    // required={true}
                    onCountryChange={(country) =>
                      this.mobile_no2_code_change(country)
                    }
                  />
                </div>
              </div>
              <div className="business_descriptions_container block">
                <div className="block_title" ref={this.about_ref}>Introduction *</div>
                {/* <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="editor_toolbar"
                  wrapperClassName="editor_component"
                  editorClassName="editor_input_area"
                  onEditorStateChange={this.onEditorStateChange}
                  placeholder={"Your charity description (Max 700 characters)"}
                  required={true}
                  handlePastedText={() => false}
                  stripPastedStyles={true}
                  toolbar={{
                    options: [
                      "inline",
                      "fontSize",
                      "list",
                      "textAlign",
                      "link",
                      "remove",
                      "history",
                    ],
                    inline: {
                      options: [
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "superscript",
                        "subscript",
                      ],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                    fontSize: {
                      options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
                    },
                  }}
                /> */}
                <div className="tooltip" style={{ fontSize: "14px" }}>Your Charity Description</div>
                <div className="tooltip">(Max 400 characters)</div>
                <textarea
                  name="charity_description"
                  placeholder=""
                  value={this.state.charity_description}
                  onChange={this.form_change}
                  rows={5}
                  ref={this.description_ref}
                />

                <ImageUpload
                  className="image_upload_component"
                  attrs={{
                    name: "image",
                    accept: "image/png, image/gif, image/jpeg",
                    max_size: 4096,
                    // required : true
                  }}
                  onChange={this.image_change}
                  deleteImage={this.delete_image}
                  revertImage={this.revert_image}
                  src={
                    this.state.image_src
                      ? process.env.REACT_APP_CDN_URL +
                      "/charities/logo/" +
                      this.state.image_src
                      : ""
                  }
                />
                <div className="image_requirements"
                  ref={this.logo_ref}
                >
                  Business Logo *<br></br>
                  <span>230px x 230px recommended. JPG or PNG only.</span>
                </div>
              </div>
              <div className="about_container block">
                <div className="block_title" ref={this.introduction_ref}>About Your Organisation *</div>
                <div className="tooltip" style={{ fontSize: "14px" }}>About Us</div>
                <div className="tooltip">(Max 700 characters)</div>
                <textarea
                  name="introduction"
                  placeholder=""
                  value={this.state.introduction}
                  onChange={this.form_change}
                  rows={5}

                />
                <div ref={this.vision_ref}>
                  <div className="tooltip" style={{ fontSize: "14px" }}>Vision</div>
                  <div className="tooltip">(Max 400 characters)</div>
                  <textarea
                    name="vision"
                    placeholder=""
                    value={this.state.vision}
                    onChange={this.form_change}
                    rows={5}
                  />
                </div>
                <div className="tooltip" style={{ fontSize: "14px" }}>Mission</div>
                <div className="tooltip">(Max 400 characters)</div>
                <textarea
                  name="mission"
                  placeholder=""
                  value={this.state.mission}
                  onChange={this.form_change}
                  rows={5}
                  ref={this.mission_ref}
                />
              </div>

            </div>
            <div>
              <div className="improve_the_world_container block">
                <div className="choose_service_label block_title">
                  Improve the World *
                </div>
                <div className="tooltip" style={{ fontSize: "14px" }}>What we do</div>
                <div className="tooltip">(Max 400 characters)</div>
                <textarea
                  name="what_we_do"
                  placeholder=""
                  value={this.state.what_we_do}
                  onChange={this.form_change}
                  rows={5}
                  ref={this.what_we_do_ref}
                />
                <div className="tooltip" style={{ fontSize: "14px" }}>How we do</div>
                <div className="tooltip">(Max 400 characters)</div>
                <textarea
                  name="how_we_do"
                  placeholder=""
                  value={this.state.how_we_do}
                  onChange={this.form_change}
                  rows={5}
                  ref={this.how_we_do_ref}
                />
                <div className="tooltip" style={{ fontSize: "14px" }}>How you can help?</div>
                <div className="tooltip">(Max 400 characters)</div>
                <textarea
                  name="how_you_can_help"
                  placeholder=""
                  value={this.state.how_you_can_help}
                  onChange={this.form_change}
                  rows={5}
                  ref={this.how_you_can_ref}
                />
              </div>
              <div className="other_details_container block">
                <div className="block_title">Other details</div>
                <div className="mobile_no_input_container">
                  <div
                    className="mobile_no_label"
                    style={{ color: this.state.mobile_no1 ? "#4f0662" : "" }}
                    ref={this.whatsapp_no_ref}
                  >
                    WhatsApp Number *
                  </div>
                  <PhoneInput
                    style={{
                      borderBottom: this.state.whatsapp_no
                        ? "2px solid #4f0662"
                        : "",
                      color: this.state.whatsapp_no ? "#111" : "",
                    }}
                    placeholder="Phone number"
                    className="whatsapp_no_input"
                    international
                    value={this.state.whatsapp_no}
                    onChange={this.whatsapp_no_change}
                    defaultCountry={this.state.whatsapp_no_code}
                    onCountryChange={(country) =>
                      this.whatsapp_no_code_change(country)
                    }
                  />
                </div>
                <InputTheme4
                  label="Email *"
                  attrs={{
                    name: "email",
                    type: "email",
                    value: this.state.email,
                    onChange: this.form_change,
                  }}
                  input_ref={this.email_ref}
                />
                 <div className="alert">* Please include the HTTPS format, for example: https://www.example.com/</div>
                <InputTheme4
                  label="Website"
                  attrs={{
                    name: "website",
                    type: "text",
                    value: this.state.website,
                    onChange: this.form_change,
                  }}
                  input_ref={this.website_ref}
                />
                <InputTheme4
                  label="Business Hours"
                  attrs={{
                    name: "business_hours",
                    type: "text",
                    value: this.state.business_hours,
                    onChange: this.form_change,
                  }}
                />
              </div>
              <div className="social_media_links block">
                <div className="social_media_label block_title">
                  Social Media Links
                </div>
                <div className="alert">* Please include the HTTPS format, for example: https://www.example.com/</div>
                <InputTheme4
                  label="Facebook URL"
                  attrs={{
                    name: "facebook",
                    type: "text",
                    value: this.state.facebook,
                    onChange: this.form_change,
                  }}
                  input_ref={this.facebook_ref}
                />
                <InputTheme4
                  label="Instagram URL"
                  attrs={{
                    name: "instagram",
                    type: "text",
                    value: this.state.instagram,
                    onChange: this.form_change,
                  }}
                  input_ref={this.instagram_ref}
                />
                <InputTheme4
                  label="Twitter URL"
                  attrs={{
                    name: "twitter",
                    type: "text",
                    value: this.state.twitter,
                    onChange: this.form_change,
                  }}
                  input_ref={this.twitter_ref}
                />
                <InputTheme4
                  label="LinkedIn URL"
                  attrs={{
                    name: "linkedin",
                    type: "text",
                    value: this.state.linkedin,
                    onChange: this.form_change,
                  }}
                  input_ref={this.linkedin_ref}
                />
              </div>
              <div className="bank_details_container block" >
                <div className=" block_title">Bank Details *</div>
                <div className="custom_table_theme1" >
                  <div className="table_row header">
                    <div className="table_cell sl_no">Sl No.</div>
                    <div className="table_cell fullname">Account Type</div>
                    <div className="table_cell fullname">Bank Details *</div>
                    <div className="table_cell datetime"></div>
                  </div>
                  {this.state.banks &&
                    this.state.banks.map((item, idx) => (
                      <div className="table_row body" key={idx} >
                        <div className="table_cell sl_no middle">{idx + 1}</div>
                        <div className="table_cell middle">
                          <div className="input_label">Account Type</div>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={(e) => this.account_type_change(e, idx)}
                              value={item.account_type}
                            >
                              <FormControlLabel
                                value="Domestic"
                                control={<Radio />}
                                label="Domestic"
                                style={{ wordBreak: "keep-all" }}
                              />
                              <FormControlLabel
                                value="Foreign"
                                control={<Radio />}
                                label="Foreign"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="table_cell middle">
                          <div className="input_label">Bank details</div>
                          <textarea
                            name="account_details"
                            value={item.account_details}
                            placeholder="Enter bank account details"
                            onChange={(e) => this.banks_change(e, idx)}
                            rows={6}
                          />
                        </div>
                        <div className="table_cell buttons_container middle">
                          <div
                            className="add_row_button"
                            style={{
                              visibility:
                                this.state.banks.length < 5 ? "visible" : "hidden",
                            }}
                            onClick={() => this.add_bank_details_row(idx)}
                          >
                            <AiOutlinePlus />
                          </div>
                          <div
                            className="delete_row_button"
                            style={{
                              visibility:
                                this.state.banks.length != 1 ? "visible" : "hidden",
                            }}
                            onClick={() => this.delete_bank_details_row(idx)}
                          >
                            <AiOutlineDelete />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

          </div>
          <div className="gallery_container block">
            <div className="choose_service_label block_title">Gallery Images</div>
            <div className="other_details_title">Maximum 12 Images ({this.state.gallery_images.length}/12) </div>
            <div className="gallery_image_details_container">
              <div className="gallery_image_details">

                {this.state.gallery_images && this.state.gallery_images.map((item, idx) => (
                  <div className="single_image_container" key={idx} >
                    <div className="">
                      <ImageUpload className="gallery_image"
                        attrs={{
                          name: "gallery_image",
                          accept: "image/png, image/gif, image/jpeg",
                        }}
                        onChange={(e) => { this.gallery_image_change(e, idx) }}
                        // deleteImage = {this.delete_image}
                        // revertImage = {this.revert_image}
                        src={item.gallery_image_src_old ??item.gallery_image_src}
                      />
                    </div>
                    <div className="preview_delete_button_container">
                      <div className="delete_row_button"
                        onClick={(e) => this.delete_gallery_row(e,idx)}
                      >
                        <AiOutlineDelete />
                      </div>
                      <div className="image_preview_button"
                        style={{ display: item.gallery_image_src ? "inline-grid" : "none" }}
                        onClick={() => {
                          this.setState({
                            zoomed_item: item
                          })
                        }}
                      >
                        <BsEyeFill />
                      </div>
                    </div>
                  </div>
                ))}

              </div>
              <div className="button_count_container">
                <button className="other_details_button"
                  type="button"
                  style={{ visibility: this.state.gallery_images.length < 12 ? "visible" : "hidden" }}
                  onClick={this.add_gallery_row}
                ><GrAdd color="green" size={12} /> Add New Image</button>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            {/* <div
              className="password_error"
              style={{
                visibility: this.state.password_error ? "visible" : "hidden",
              }}
            >
              {this.state.password_error}&nbsp;
            </div>
            <br></br> */}
            <ButtonTheme1
              label="Submit"
              className="create_button"
            ></ButtonTheme1>
          </div>
        </form>
        <div className="zoomed_image_ghost" onClick={() => this.setState({ zoomed_item: "" })} style={{ display: this.state.zoomed_item.gallery_image_src_old || this.state.zoomed_item.gallery_image_src  ? "block" : "none" }}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()}>
            <div className="image_caption_container">
              <img src={this.state.zoomed_item.gallery_image_src_old??this.state.zoomed_item.gallery_image_src} />
              <div className="close_zoomed_image_button" onClick={() => this.setState({ zoomed_item: "" })}>
                <VscChromeClose />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewCharity;
NewCharity.contextType = MyContext;
