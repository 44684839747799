import React from "react";
import "./ViewPetTribute.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";

class ViewPetTribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pet_tribute: {
        name: "",
        animal: "",
        breed: "",
        age: "",
        colour: "",
        pet_name: "",
        gender: "",
        favourite_activity: "",
        favourite_food: "",
        favourite_place: "",
        temperament: "",
        likes: "",
        dislikes: "",
        date_of_death: "",
        title: "",
        content: "",
        status: "",
        image_src1: "",
        image_src2: "",
        image_src3: "",
      },
      pet_tribute_comments: [],
      deleted_reason : ""
    };
    this.view_pet_tribute_page_Ref = React.createRef();
    this.delete_reason_container_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.delete_comment = this.delete_comment.bind(this);
    this.approve_comment = this.approve_comment.bind(this);
    this.maximize_block = this.maximize_block.bind(this);
    this.minimize_block = this.minimize_block.bind(this);
    this.delete_notice = this.delete_notice.bind(this);
    this.delete_form_submit = this.delete_form_submit.bind(this);
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/viewPetTributeDetails",
      data: this.context.bind_session({
        pet_tribute_id: this.props.match.params.pet_tribute_id,
      }),
      type: "POST",
    })
      .done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState(
            {
              pet_tribute: return_data.pet_tribute,
              pet_tribute_comments: return_data.pet_tribute_comments,
            },
            () => {
              obj_this.view_pet_tribute_page_Ref.current.style.visibility =
                "visible";
            }
          );
        } else if (return_data.status == "no permission") {
          alert("You have no permission to see this data");
          obj_this.props.history.goBack();
        }
        obj_this.context.loading(0);
      })
      .fail(function () {
        alert("Network error!!");
      });
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  delete_comment(pet_tribute_comment_id) {
    if (!window.confirm("Delete this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteApprovedPetTributeComment",
      data: this.context.bind_session({
        pet_tribute_comment_id: pet_tribute_comment_id,
      }),
      type: "POST",
    })
      .done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          obj_this.setState({
            pet_tribute_comments: return_data.pet_tribute_comments,
          });
          alert("Pet tribute comment deleted");
          // obj_this.props.history.push('/Dashboard/DeathNotices/Approvals');
        } else {
          alert("Network error!!");
        }
      })
      .fail(function (return_data) {
        alert("Network error!!");
      });
  }

  delete_notice() {
    if (!window.confirm("Are your sure")) {
      return;
    }
    this.delete_reason_container_ref.current.style.display = "block";
  }

  delete_form_submit(e) {
    e.preventDefault();
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/deletePetTribute",
      data : this.context.bind_session({
        pet_tribute_id : this.state.pet_tribute.id,
        deleted_reason : this.state.deleted_reason
      }),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        alert("Pet tribute deleted");
        obj_this.delete_reason_container_ref.current.style.display = "none";
        obj_this.props.history.replace({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      }else{
        $.alert("Something Went Wrong. Please Try Again.");
      }
      obj_this.context.loading(0);
     }).fail(function(){
       alert("Network error");
     });
  }

  approve_comment(pet_tribute_comment_id) {
    if (!window.confirm("Approve this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approvePetTributeComment",
      data: this.context.bind_session({
        pet_tribute_comment_id: pet_tribute_comment_id,
      }),
      type: "POST",
    })
      .done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          obj_this.setState({
            pet_tribute_comments: return_data.pet_tribute_comments,
          });
          alert("Pet tribute comment approved");
          // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
        } else {
          alert("Network error!!");
        }
      })
      .fail(function (return_data) {
        alert("Network error!!");
      });
  }

  maximize_block(block) {
    console.log(block);
    $("#" + block).heightAuto();
    $("#" + block)
      .find(".maximize_button")
      .css("display", "none");
    $("#" + block)
      .find(".minimize_button")
      .css("display", "block");
  }

  minimize_block(block) {
    $("#" + block).css("height", "54px");
    $("#" + block)
      .find(".maximize_button")
      .css("display", "block");
    $("#" + block)
      .find(".minimize_button")
      .css("display", "none");
  }

  render() {
    return (
      <div
        className="view_pet_tribute_page_container page_container"
        ref={this.view_pet_tribute_page_Ref}
      >
        <div className="deceased_basic_details_block">
          <div className="page_header">
            <div className="page_title">Pet Tribute</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/PetTributes/MyEntries">Pet Tributes</Link>/
              <span>View</span>
            </div> */}
          </div>
          <div className="deceased_name_images_grid">
            <div className="deceased_name_address_container">
              <div className="deceased_name">{this.state.pet_tribute.name}</div>
              <div className="duration">({new Date(this.state.pet_tribute.date_of_birth).getFullYear()} - {new Date(this.state.pet_tribute.date_of_death).getFullYear()})</div>
            </div>
          </div>
          <div className="deceased_image_and_details_grid">
            <div className="deceased_image_container">
              <div
                className="deceased_image"
                style={{
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CDN_URL +
                    "/" +
                    this.state.pet_tribute.entered_by +
                    "/pet_tribute_images/" +
                    this.state.pet_tribute.image_src1 +
                    "')",
                }}
              ></div>
              </div>
              {/* <div className="deceased_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/"+this.state.pet_tribute.entered_by+"/pet_tribute_images/" + this.state.pet_tribute.image_src2 + "')" }}></div>
                     <div className="deceased_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/"+this.state.pet_tribute.entered_by+"/pet_tribute_images/" + this.state.pet_tribute.image_src3 + "')" }}></div> */}
              <div className="deceased_details_grid">
                <div className="single_detail_container">
                  <div className="detail_label">Category</div>
                  {/* <div>:</div> */}
                  <div className="detail_value">
                    {this.state.pet_tribute.animal}
                  </div>
                  </div>
                  <div className="single_detail_container">
                  <div className="detail_label">Breed</div>
                  {/* <div>:</div> */}
                  <div className="detail_value">
                    {this.state.pet_tribute.breed}
                  </div>
                  </div>
                  <div className="single_detail_container">
                  <div className="detail_label">Gender</div>
                  {/* <div>:</div> */}
                  <div className="detail_value">
                    {this.state.pet_tribute.gender}
                  </div>
                </div>
                <div className="single_detail_container">
                  <div className="detail_label">Born</div>
                  {/* <div>:</div> */}
                  <div className="detail_value">
                    {new Date(
                      this.state.pet_tribute.date_of_birth
                    ).toLocaleDateString("en-GB")}
                  </div>
                  </div>
                  
                  <div className="single_detail_container">
                  <div className="detail_label">Age</div>
                  {/* <div>:</div> */}
                  <div className="detail_value">
                  {this.state.pet_tribute.age}
                  </div>
                  </div>
                  <div className="single_detail_container">
                  <div className="detail_label">Passed Away</div>
                  {/* <div>:</div> */}
                  <div className="detail_value">
                    {new Date(
                      this.state.pet_tribute.date_of_death
                    ).toLocaleDateString("en-GB")}
                  </div>
                  </div>
              </div>
            
          </div>
        </div>
        <div className="deceased_infos_container">

          {/* <div className="single_info_container" id="pet_tribute">
            <div className="single_info_head">
              <div className="heading">STORY</div>
              <div className="minimize_maximize_buttons_container">
                <div
                  className="maximize_button"
                  onClick={() => this.maximize_block("pet_tribute")}
                >
                  <AiOutlinePlus />
                </div>
                <div
                  className="minimize_button"
                  onClick={() => this.minimize_block("pet_tribute")}
                >
                  <AiOutlineMinus />
                </div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="notice_title">{this.state.pet_tribute.title}</div>
              <div
                className="notice_content"
                dangerouslySetInnerHTML={{
                  __html: this.state.pet_tribute.content,
                }}
              />
            </div>
          </div> */}

          {/* <div className="single_info_container" id="about_me"> */}
            {/* <div className="single_info_head">
              <div className="heading">ABOUT {this.state.pet_tribute.name}</div>
              <div className="minimize_maximize_buttons_container">
                <div
                  className="maximize_button"
                  onClick={() => this.maximize_block("about_me")}
                >
                  <AiOutlinePlus />
                </div>
                <div
                  className="minimize_button"
                  onClick={() => this.minimize_block("about_me")}
                >
                  <AiOutlineMinus />
                </div>
              </div>
            </div> */}
            {/* <div className="single_info_body">
              <table className="about_pet_table">
                <tbody>
                  <tr>
                    <td>Age</td>
                    <td>
                      {this.state.pet_tribute.years != 0
                        ? this.state.pet_tribute.years == 1
                          ? +this.state.pet_tribute.years + " Year"
                          : this.state.pet_tribute.years + " Years"
                        : ""}{" "}
                      {this.state.pet_tribute.months != 0
                        ? this.state.pet_tribute.months == 1
                          ? +this.state.pet_tribute.months + " Month"
                          : this.state.pet_tribute.months + " Months"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Pet Name(s)</td>
                    <td>{this.state.pet_tribute.pet_name}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{this.state.pet_tribute.gender}</td>
                  </tr>
                  <tr>
                    <td>Favourite Activity</td>
                    <td>{this.state.pet_tribute.favourite_activity}</td>
                  </tr>
                  <tr>
                    <td>Favourite Food</td>
                    <td>{this.state.pet_tribute.favourite_food}</td>
                  </tr>
                  <tr>
                    <td>Favourite Place</td>
                    <td>{this.state.pet_tribute.favourite_place}</td>
                  </tr>
                  <tr>
                    <td>Likes</td>
                    <td>{this.state.pet_tribute.likes}</td>
                  </tr>
                  <tr>
                    <td>Dislikes</td>
                    <td>{this.state.pet_tribute.dislikes}</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          {/* </div> */}

          <div
            className="single_info_container"
            id="condol_message"
            style={{
              display: this.state.pet_tribute_comments.length
                ? "block"
                : "none",
            }}
          >
            <div className="single_info_head">
              <div className="heading">COMMENTS</div>
              <div className="minimize_maximize_buttons_container">
                <div
                  className="maximize_button"
                  onClick={() => this.maximize_block("condol_message")}
                >
                  <AiOutlinePlus />
                </div>
                <div
                  className="minimize_button"
                  onClick={() => this.minimize_block("condol_message")}
                >
                  <AiOutlineMinus />
                </div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid">
                {this.state.pet_tribute_comments &&
                  this.state.pet_tribute_comments.map((item, idx) => (
                    <div
                      className="single_message"
                      key={idx}
                      // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                    >
                      <div className="msg_container">
                        <div className="tribute_text">{item.comment}</div>
                        <div className="posted_by">
                          Posted by {item.written_by} on{" "}
                          {new Date(item.published_date).toDateString()}
                        </div>
                      </div>
                      {/* <i className="fas fa-check approve_tribute_button" 
                      style={{visibility:this.context.session && item.status == "PENDING"? "visible":"hidden"}}
                      onClick={() => this.approve_comment(item.id)}
                    ></i> */}
                      <i
                        className="fas fa-trash-alt delete_tribute_button"
                        style={{
                          visibility: this.context.session
                            ? "visible"
                            : "hidden",
                        }}
                        onClick={() => this.delete_comment(item.id)}
                      ></i>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="buttons_container" style={{display:this.state.pet_tribute.status == "PUBLISHED" || this.state.pet_tribute.status == "PENDING"?"block":"none"}}>
          <ButtonTheme1
            label="Delete"
            className="delete_button"
            onClick={this.delete_notice}
          ></ButtonTheme1>
        </div>
        <div className="delete_reason_container" ref={this.delete_reason_container_ref}>
          <form className="delete_reason_form" onSubmit={this.delete_form_submit} method="post">
            <div className="delete_reason_title">Delete reason</div>
            <div className="delete_reason_form_body">
              <textarea name="deleted_reason"
                rows={4}
                value={this.state.deleted_reason}
                onChange={this.form_change}
                required
                placeholder="Type here.."
              />
              <button className="delete_form_submit_button">Submit</button>
            </div>
            <div className="delete_form_close_button" onClick={() => { 
              this.delete_reason_container_ref.current.style.display = "none"; 
              this.setState({ 
                deleted_reason : "" }) }}>x</div>
          </form>
        </div>
      </div>
    );
  }
}

export default ViewPetTribute;
ViewPetTribute.contextType = MyContext;
