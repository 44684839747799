import {Component} from "react";
import "./Footer.scss";
import {Link} from "react-router-dom";
import footer from "../../res/images/footer.png";

class Footer extends Component{
  constructor(props) {
    super(props);
  }
  render(){
    if (window.location.pathname.match(/Login/) || window.location.pathname.match(/Register/)){
      return null;
    }
    return(
      <div className="footer_container" style={{backgroundImage:"url('"+footer+"')"}}>
        <div className="page_links_container">
          <Link className="page_link url_scroll_up" to="/">Home</Link>
          <Link className="page_link url_scroll_up content_refresh" to="/DeathNotices" reset_elem=".death_notices_page_container .reset_form_button">Death Notices</Link>
          <Link className="page_link url_scroll_up content_refresh" to="/Memorials" reset_elem=".memorials_page_container .reset_form_button">Memorials</Link>
          <Link className="page_link url_scroll_up content_refresh" to="/InMemoryNotices" reset_elem=".familynotice_page_container .reset_form_button">In Memory Notices</Link>
          <Link className="page_link url_scroll_up pet_tribute_refresh" to="/PetTributes" reset_elem=".pet_tribute_page_container .reset_form_button">Pet Tributes</Link>
          <Link className="page_link url_scroll_up blogs_refresh" to="/Blogs">Blogs</Link>
        </div>
        <div className="services_and_contact_grid">
          <div>
            <div className="contact_label">Services</div>
            <div className="footer_links_container">
              <div><a className="page_link" target={"_blank"} href="https://shop.amrityum.com">Shop</a></div>
              <div><Link className="url_scroll_up blogs_refresh" to="/Blogs">Blogs </Link></div>
              <div><Link className="url_scroll_up charity_refresh" to="/Charities">Charity</Link></div>
              <div><Link className="url_scroll_up services_refresh" to="/Services">Service Directory</Link></div>
              {/* <div><Link to="#">Advertise with us</Link></div> */}
              <div><Link className="url_scroll_up" to="/PartnerInformation">Partner Information </Link></div>
            </div>
          </div>
          <div>
          <div>
            <div className="contact_label">Useful Links</div>
            <div className="footer_links_container">
              <div><Link className="url_scroll_up" to="/Help">Help</Link></div>
              <div><Link className="url_scroll_up" to="/FAQs">FAQs</Link></div>
              <div><Link className="url_scroll_up" to="/Features">Features </Link></div>
              <div><Link className="url_scroll_up" to="/Feedback">Feedback</Link></div>
              <div><Link className="url_scroll_up" to="/ContactUs">Contact Us</Link></div> 

            </div>
          </div>
          </div>
          <div>
            <div className="contact_label">Information</div>
            <div className="footer_links_container">
              <div><Link className="url_scroll_up" to="/Disclaimer">Disclaimer </Link></div>
              <div><Link className="url_scroll_up" to="/PrivacyPolicy">Privacy Policy </Link></div>
              <div><Link className="url_scroll_up" to="/CookiesPolicy">Cookies Policy</Link></div>
              <div><Link className="url_scroll_up" to="/CodeOfConduct">Code of Conduct </Link></div>
              <div><Link className="url_scroll_up" to="/TermsAndConditions">Terms and Conditions</Link></div>
            </div>
          </div>
          <div className="contact_container">
            <div className="contact_label">Get in Touch</div>
            <div className="footer_social_links_container">
              <a href="https://www.facebook.com/people/Amrityum/100077085990115/" target={"_blank"}><i className="fab fa-facebook-f contact_icon"></i></a>
              <a href="https://www.linkedin.com/in/amrityum-lifelog-matters-8a9027236" target={"_blank"}><i className="fab fa-linkedin contact_icon"></i></a>
              <a href="https://www.instagram.com/amrityum" target={"_blank"}><i className="fab fa-instagram contact_icon"></i></a>
              <a href={"https://api.whatsapp.com/send?phone=7558900111"} target={"_blank"}><i className="fab fa-whatsapp contact_icon"></i></a>
              <a href="https://in.pinterest.com/contactamrityum" target={"_blank"}><i className="fab fa-pinterest contact_icon"></i></a>
              <a href="https://twitter.com/amrityum" target={"_blank"}><i className="fab fa-twitter contact_icon"></i></a>
              <a href={"https://www.youtube.com/channel/UCIPjOEE_yeWIl5O8qUuQnAw/featured"} target={"_blank"}>
                <i className="fab fa-youtube contact_icon" ></i>
              </a>
            </div>
            {/* <div className="contact_label" style={{marginTop:"5px"}}>Phone</div> */}
            <div className="contact_value"><a href="tel:+91 7558 900 111">+91 7558 900 111</a></div>
            {/* <div className="contact_label" style={{marginTop:"5px"}}>Email</div> */}
            <div className="contact_value"><a href="mailto:info@amrityum.com">info@amrityum.com</a></div>

          </div>
        </div>
        <div className="copyright_container">&copy; 2023 Amrityum. All rights reserved
        <br></br>
        IT Partner : <a href="https://www.dieutek.com" target={"_blank"} style={{color:"#5d5dc6"}}>Dieutek Developments</a>
        </div>
      </div>
    )
  }
}

export default Footer;