import React from "react";
import "./ManageEmojis.scss";
import {Link} from "react-router-dom";
import $ from "jquery";
import MyContext from "../../MyContext";
import Candle from "../../../res/images/death_notices/candle.png";
import Flower from "../../../res/images/death_notices/flower.png";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";

class Emojis extends React.Component{
  constructor(props){
    super(props);
    this.state={
      tribute_type : 1,
      tribute_types : [],
      tribute_images : [],
      tribute_emojis : []
    }
    this.tribute_type_change = this.tribute_type_change.bind(this);
    this.image_change = this.image_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.delete_emoji = this.delete_emoji.bind(this);
    this.emoji_input_ref = React.createRef();
    this.manage_emojis_page_ref = React.createRef();
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/getTributeTypes",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        tribute_types : return_data.tribute_types,
      },() => {
        obj_this.context.loading(0);
        obj_this.manage_emojis_page_ref.current.style.visibility = "visible";
        obj_this.manage_emojis_page_ref.current.style.opacity = 1;
      });
     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
    $(".manage_emojis_form").on("click",".tribute_type",function(){
      $(".tribute_type").find(".image_label_container").removeClass("selected_tribute_type");
      $(this).find(".image_label_container").addClass("selected_tribute_type");

    })
  }

  tribute_type_change(e) {
    // console.log(e.target.value);
    this.setState({
      tribute_type: e.target.value
    });
  }

  image_change(e){
    this.setState({
      tribute_images: e.target.files,
    });
  }

  form_submit(e){
    e.preventDefault();
    console.log(this.state);
    const form_data = new FormData();
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    const files = this.state.tribute_images;
    console.log(files);
    if(!files.length){
      return;
    }
    for (let i = 0; i < files.length; i++) {
      form_data.append("images[]", files[i])
    }
    form_data.append("tribute_type", this.state.tribute_type);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveTributeImage",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          tribute_types : return_data.tribute_types,
          tribut_images : []
        });
        obj_this.emoji_input_ref.current.value = null;
        const event = new Event("change", { bubbles: true });
        obj_this.emoji_input_ref.current.dispatchEvent(event);
        alert("Emojis added.")
      }else{
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  delete_emoji(id){
    if (!window.confirm("Delete this emoji")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteTributeImage",
      data: this.context.bind_session({ tribute_image_id: id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          tribute_types : return_data.tribute_types
        });
        alert("Emoji deleted");
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="manage_emojis_page_container page_container" ref={this.manage_emojis_page_ref}>
      <div className="page_header">
        <div className="page_title"> Manage Emojis</div>
        {/* <div className="breadcrumbs_container">
          <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
          <span>Emojis</span>
        </div> */}
      </div>
      <div className="emoji_types_container">
      {this.state.tribute_types && this.state.tribute_types.map((type,idx) => (
        <div className="single_emoji_type_container" key={idx}>
          <div className="emoji_type_heading">{type.tribute_type} </div>
          <div className="emojis_container">
            {type.tribute_images && type.tribute_images.map((image,index) => (
              <div className="single_emoji_container">
                <div className="emoji" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+ image.tribute_type_image+"')" }}></div>
                <div className="delete_emoji_button" onClick={() => this.delete_emoji(image.id)}>x</div>              
              </div>
            ))}
          </div>
        </div>
      ))}
      </div>
      <form className="manage_emojis_form" encType="multipart/form-data" onSubmit={this.form_submit}>
        <div className="tribute_label">Choose Emoji Type</div>
        {this.state.tribute_types && this.state.tribute_types.map((type,idx) => (
          <label className="tribute_type" key={idx}>
            <input
              type="radio"
              name="tribute_type"
              value={type.id}
              onChange={this.tribute_type_change}
              checked={this.state.tribute_type == type.id}
            />
            <div className={idx==0 ?"image_label_container selected_tribute_type":"image_label_container"}>
              <div className="tribute_type_image" style={{ backgroundImage: type.tribute_type == "Candle"?"url("+Candle+")":"url("+Flower+")" }}></div>
              <div className="tribute_type_label">{type.tribute_type}</div>
            </div>
          </label>
        ))}
        
        <br></br>
        <div className="tribute_label">Upload images</div>
        <input className="emoji_upload_input"
          ref={this.emoji_input_ref}
          name= "image"
          accept= "image/png, image/gif, image/jpeg"
          type="file"
          onChange={this.image_change}
          src = {process.env.REACT_APP_CDN_URL+"/family_notices/profile_images/"+this.state.image_src}
          multiple
        />
        <br></br>
        <ButtonTheme1
          label="Add Emojis"
          className="create_button"
        ></ButtonTheme1>
      </form>
    </div>
    )
  }
}

export default Emojis;
Emojis.contextType = MyContext;