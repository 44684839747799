import React from "react";
import "./MemorialList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AddButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {Link} from "react-router-dom";
import {FaAngleRight, FaEdit, FaEye, FaInfoCircle, FaPencilAlt} from "react-icons/fa";
import { FcFullTrash } from "react-icons/fc";
import ReactPaginate from 'react-paginate';

class MemorialList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      memorials : [],
      search_input : "",
      deleted_reason : "",
      reported_reason : "",
      deleted_by : "",
      offset_value : 0,
      current_page : 0,
      per_page : 10,
      page_count : 0,
      page_no_input : 1
    }
    this.memorial_list_page_Ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.onAmrityumIDChange = this.onAmrityumIDChange.bind(this);
    // this.delete_notice = this.delete_notice.bind(this);
    this.getMemorials = this.getMemorials.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.notices_XHR = {abort : () => {}}
  }

  componentDidMount(){
    if(this.props.history.location.state && typeof this.props.history.location.state == "object" && this.props.history.location.state.hasOwnProperty('offset_value')){
      this.setState({
        offset_value : this.props.history.location.state.offset_value,
        search_input : this.props.history.location.state.search_input,
        amrityumID : this.props.history.location.state.amrityumID,
        current_page : this.props.history.location.state.current_page,
        page_no_input : this.props.history.location.state.page_no_input
      },() => {
        window.scrollTo({
          top : this.memorial_list_page_Ref.current.offsetTop - 80,
          behavior : "smooth"
        });
        this.getMemorials();
      });
    }else{ 
      this.getMemorials();
    }
  }

  form_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    this.setState({
      [e.target.name]: e.target.value,
      amrityumID : "",
      page_no_input : 1
    }, () => {
      this.getMemorials();
    });
  }

  onAmrityumIDChange(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    this.setState({
      [e.target.name]: e.target.value,
      search_input : "",
      page_no_input : 1
    }, () => {
      this.getMemorials();
    });
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    });
  }

  page_no_form_submit(e){
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      window.scrollTo({
        top : this.memorial_list_page_Ref.current.offsetTop - 80,
        behavior : "smooth"
      });
      this.getMemorials();
    });
  }

  handle_page_click(e){
    window.scrollTo({
      top : this.memorial_list_page_Ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getMemorials()
    });
  }

  getMemorials(phrase){
    var obj_this = this;
    this.context.loading(1);
    this.notices_XHR.abort();
    this.notices_XHR = $.ajax({
      url : process.env.REACT_APP_API + "/api/allMemorials",
      data :this.context.bind_session({
        offset_value : this.state.offset_value,
        search_phrase: this.state.search_input,
        amrityumID : this.state.amrityumID
      }),
      type : "POST"
    }).done(function(return_data){
       console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          memorials : return_data.memorials,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page)
        },() => {
          obj_this.memorial_list_page_Ref.current.style.visibility = "visible";
          obj_this.memorial_list_page_Ref.current.style.opacity = 1;
          obj_this.context.loading(0);
        });
        obj_this.context.setHistoryState({
          memorials : return_data.memorials,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
          offset_value : obj_this.state.offset_value,
          current_page : obj_this.state.current_page,
          search_input : obj_this.state.search_input,
          amrityumID : obj_this.state.amrityumID,
          page_no_input : obj_this.state.page_no_input,
        },obj_this.props.history);
      }else{
        obj_this.context.loading(0);
        $.alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (jqXHR, textStatus) {
      if (textStatus != "abort") {
        alert("Network error");
      } 
    });
  }

  render(){
    return(
      <div className="memorial_list_page_container page_container" ref={this.memorial_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Memorials</div>
          <div className="search_container">
            <input
              className="search_input"
              name="amrityumID"
              type="text"
              value={this.state.amrityumID}
              onChange={this.onAmrityumIDChange}
              placeholder="Search with ID"
            />
            <i className="fas fa-search search_icon"></i>
          </div>
          <div className="search_container">
            <input
              className="search_input"
              name="search_input"
              type="text"
              value={this.state.search_input}
              onChange={this.form_change}
              placeholder="Search..."
            />
            <i className="fas fa-search search_icon"></i>
          </div>
          <div className="new_memorial_button_container">
            <Link to={{pathname: "/Dashboard/Memorials/New", url : "/Dashboard/Memorials/All"}}>
              <AddButtonTheme1 label="Publish a Memorial"/>
            </Link>
          </div>
        </div>
        <div className="memorial_type_select_container">
        </div>
        <div className="custom_table_theme1">
          <div className="table_row header">
            <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">First Name</div>
            <div className="table_cell notice_type">Surname</div>
            {/* <div className="table_cell">House/Family Name</div> */}
            <div className="table_cell datetime">Locality</div>
            <div className="table_cell datetime">State</div>
            <div className="table_cell datetime">Published By</div>
            <div className="table_cell">Status</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.memorials && this.state.memorials.map((item,idx) => (
            <div className="table_row body"
              // style={{backgroundColor:item.status == "PENDING"?"#ebf8a4":item.status == "REJECTED"?"#ffc0cb":""}}
              key={idx} 
              >
              <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
              <div className="table_cell">{item.first_name}</div>
              <div className="table_cell">{item.surname}</div>
              {/* <div className="table_cell">{item.family_or_house_name}</div> */}
              <div className="table_cell">{item.locality}</div>
              <div className="table_cell">{item.flag} {item.state}</div>
              <div className="table_cell">{item.published_by}</div>
              
              <div className="table_cell">
              <div
                    className="status_button"
                    style={{
                      backgroundColor:
                        item.status == "PENDING"
                          ? "#fae69d"
                          : item.status == "REJECTED"
                          ? "#E26868"
                          : item.status == "DELETED"
                          ? "#fcd7d7"
                          : "",
                      color:
                        item.status == "PENDING"
                          ? "#f0891a"
                          : item.status == "REJECTED"
                          ? "#fcfafa"
                          : item.status == "DELETED"
                          ? "#c41d1d"
                          : "",
                    }}
                  >
                    {item.status}
                  </div>
                </div>
                <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
                <div className="table_cell" style={{minWidth:"115px"}}>
                  <div className="action">
                    <Link className="tooltip approve" to={{
                        pathname:"/Memorial/Preview/" + item.id,
                        url:"/Dashboard/Memorials/All",
                        previousState : {
                          offset_value : this.state.offset_value,
                          current_page : this.state.current_page,
                          search_input : this.state.search_input,
                          page_no_input : this.state.page_no_input,
                        }  
                      }}>
                      <span className="tooltipText approve">Preview</span>
                      <FaEye className="approve_button" />
                    </Link>
                    <Link className="tooltip edit" to={{
                        pathname:"/Dashboard/Memorials/Edit/"+item.id,
                        url:"/Dashboard/Memorials/All",
                        previousState : {
                          offset_value : this.state.offset_value,
                          current_page : this.state.current_page,
                          search_input : this.state.search_input,
                          page_no_input : this.state.page_no_input,
                        }
                      }} style={{visibility:(item.status == "PUBLISHED" || item.status == "PENDING") ?"visible":"hidden"}}>
                      <span className="tooltipText edit">Edit</span>
                      <FaPencilAlt className="edit_button"/>
                    </Link>
                    <Link className="tooltip delete" to={{
                        pathname:"/Dashboard/ViewMemorial/"+item.id,
                        url:"/Dashboard/Memorials/All",
                        previousState : {
                          offset_value : this.state.offset_value,
                          current_page : this.state.current_page,
                          search_input : this.state.search_input,
                          page_no_input : this.state.page_no_input,
                        }
                      }} style={{visibility:(item.status == "PUBLISHED") ?"visible":"hidden"}}>
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button" />
                    </Link>
                    <div className="tooltip reason" style={{visibility:item.deleted_reason?"visible":"hidden"}} 
                      onClick={() =>{this.setState({
                        deleted_reason : item.deleted_reason,
                        reported_reason : item.selected_reported_reason,
                        deleted_by : item.deleted_by
                      })}}>
                      <span className="tooltipText reason_text">Reason</span>
                      <FaInfoCircle className="delete_button"/>
                    </div>
                  </div>
                </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.memorials.length?"none":"block"}}>No results.</div>
        </div>
        <div className="pagination_container" style={{display:this.state.memorials.length && this.state.page_count > 1?"block":"none"}}>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handle_page_click}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.current_page}
          />
          <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span>
              <FaAngleRight/>
            </button>
          </form>
        </div>
        <div className="view_message_ghost" style={{display:this.state.deleted_reason?"block":"none"}}>
          <div className="view_message" style={{display:this.state.deleted_reason && this.state.reported_reason?"grid":"block"}}>
            <div className="reported_container" style={{display:this.state.reported_reason?"block":"none"}}>
            <div className="reported_message_title">Reported Reason</div>
              <div className="details_grid">
                <div className="label"></div>
                <div className="value">{this.state.reported_reason}</div>
              </div>
            </div>
              
            <div>
            <div className="delete_message_title">Deleted Reason</div>
            <div className="details_grid">
              <div className="label"></div>
              <div className="value">{this.state.deleted_reason}</div>
            </div>
            <div className="deleted_by">- {this.state.deleted_by}</div>
            </div>
            <div className="close_message_form"
                onClick={() => {
                  this.setState({
                    reported_reason : "",
                    deleted_reason  : "",
                    deleted_by : ""
                  })
                }}
              >
                <div>x</div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MemorialList;
MemorialList.contextType = MyContext;