import { Component } from "react";
import "./Sidebar.scss";
import $ from "jquery";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {Link} from "react-router-dom";
import MyContext from "../MyContext";
import {AiOutlineClose} from "react-icons/ai";
import {HiOutlineMenuAlt1} from "react-icons/hi";
import default_image from "../../res/images/user_icon.png";
import user_img from "../../res/images/user_img.jpg";

class Sidebar extends Component{
  constructor(props){
    super(props);
    $.fn.heightAuto = function () {
      var elem, height, width;
      return this.each(function (i, el) {
          el = $(el);
          elem = el.clone().css({
              "height": "auto",
          }).appendTo($(this).parent());
          //            $(this).parent()
          height = elem.css("height");
              elem.remove();
  
          el.css({
              "height": height
          });
      });
    }

  }

  componentDidMount(){
    // $(".menu_list").prev().append("<svg class='arrow svg-inline--fa fa-angle-left fa-w-8 arrow' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' data-fa-i2svg='><path fill='currentColor' d='M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z'></path></svg>");
    $(".menu_list").prev().append("<i class='arrow fas fa-angle-left'></i>");
    var menu_item_selected_css = {
      "background-color": "#9100b76e",
      "color" : "white"
    },
    menu_item_deselected_css = {
      "background-color": "",
      "color" : ""
    },
    hover_in_css = {
        // "background-color": "#9100b76e",
    },
    hover_out_css = {
        // "background-color": "transparent",
    };

    $(".sidebar_component_container .menu_item").on("click", function () {
    // $(".sidebar_component_container").find("*").stop();
      var clicked_menu_item = $(this);
      if($(window).width() < 880){
        var attr = $(this).attr('href');
        // console.log(attr);
        if (typeof attr !== 'undefined' && attr !== false) {
          
          $(".close_mobile_sidebar_button").trigger("click");
          $(".menu_list").css({
            "height": "0px"
          });
        }
      }
     
      if (!(clicked_menu_item.next().hasClass("menu_list"))) {
        // console.log("Makkalilla\n");
        clicked_menu_item.siblings().find(".arrow").css("transform", "rotate(0deg)");
        clicked_menu_item.off('hover');
        // menu_item_select_theme();
        clicked_menu_item.siblings().find(".menu_list").css({
          "height": "0"
        });
        
        clicked_menu_item.siblings(".menu_list").css({
          "height": "0"
        });
        
        // console.log(clicked_menu_item);
        // clicked_menu_item.parents(".menu_list").heightAuto();
        // if(clicked_menu_item.parent().prev().hasClass("ham_user_details_container")){
        //   return;
        // }
        // clicked_menu_item.parent().prev().css({
        //   backgroundColor : "#613282bd",
        //  color :"white"
        // });
        // clicked_menu_item.parent().prev()[0].scrollIntoView({
        //   behavior: "smooth", // or "auto" or "instant"
        //   block: "nearest" // or "end"
        // });
        
        return;
      }
      var menu_to_open_or_close = clicked_menu_item.next();

      if (menu_to_open_or_close.height() != 0) {
        // console.log("Menu adakanu\n");
        clicked_menu_item.find(".arrow").css("transform", "rotate(0deg)");
        clicked_menu_item.hover(function () {
            $(this).css(hover_in_css);
        }, function () {
            $(this).css(hover_out_css);
        });
        menu_to_open_or_close.find(".menu_list").css({
            "height": "0"
        });
        clicked_menu_item.siblings(".menu_list").css({
            "height": "0"
        });
        menu_to_open_or_close.parents(".menu_list").heightAuto();
      }else {
        // console.log("open sesami\n");
        clicked_menu_item.siblings().find(".arrow").css("transform", "rotate(0deg)");
        clicked_menu_item.find(".arrow").css("transform", "rotate(-450deg)");
        // menu_item_select_theme();

        clicked_menu_item.siblings(".menu_list").css({
            "height": "0"
        });
        //                alert();
        clicked_menu_item.siblings().find(".menu_list").css({
            "height": "0"
        });
        //                console.log(menu_list_height);
        menu_to_open_or_close.heightAuto();
        menu_to_open_or_close.css('background-color',"#e6e6e6");

        menu_to_open_or_close.parents(".menu_list").heightAuto();
      }

      // function menu_item_select_theme() {
      //     clicked_menu_item.off('mouseenter mouseleave');
      //     // console.log("color kalayanu");
      //     $(".menu_item").css(menu_item_deselected_css).hover(function () {
      //         $(this).css(hover_in_css);
      //     }, function () {
      //         $(this).css(hover_out_css);
      //     });
      //     clicked_menu_item.siblings(".menu_list").find(".menu_item").css(menu_item_deselected_css).hover(function () {
      //         $(this).css(hover_in_css);
      //     }, function () {
      //         $(this).css(hover_out_css);
      //     });
      //     clicked_menu_item.css(menu_item_selected_css);
      // }

    });
    // var obj_this = this;
    // $(".sidebar_component_container .menu_item_theme1").on("click",function(){
    //   obj_this.sidebar_active_menu(window.location.pathname)
    // });

    $(".open_mobile_sidebar_button").on("click",function(){
      $(".grid_layout_container").css({
        "grid-template-columns":"230px auto"
      });
      $(".grid_layout_container .sidebar_grid_item").css("display","block");
      $(".sidebar_component_container").css("width","230px");
      // $(".sidebar_component_container .menu_container").css("margin", "0px 7px");
      $(".sidebar_component_container .menu_item span").css("visibility","visible");
      $(".sidebar_component_container .menu_item .arrow").css("visibility","visible");
      $(".sidebar_component_container .user_details_container").css({
        visibility: "visible",
        width : "85%",
        padding : "16px",
      });
      $(this).css("display","none");
      $(".close_mobile_sidebar_button").css("display","block");
    });

    $(".close_mobile_sidebar_button").on("click",function(){
      $(".grid_layout_container").css({
        "grid-template-columns":"50px auto"
      });
      $(".grid_layout_container .sidebar_grid_item").css("display","none");
      $(".sidebar_component_container").css("width","50px");
      // $(".sidebar_component_container .menu_container").css("margin", "0px");
      $(".sidebar_component_container .menu_item span").css("visibility","hidden");
      $(".sidebar_component_container .menu_item .arrow").css("visibility","hidden");
      $(".sidebar_component_container .user_details_container").css({
        width : "0px",
        padding : "0px",
        visibility: "hidden"
      });
      $(this).css("display","none");
      $(".open_mobile_sidebar_button").css("display","block");
    });

    
  }

 
  render(){
    return(
      <div className="sidebar_component_container" style={{width:this.context.show_sidebar?"230px":this.context.show_mobile_sidebar?"50px":"0px"}}>              
        <div className="ham_user_details_container">
          <div className="ham_icon_container">
            <HiOutlineMenuAlt1 className="open_mobile_sidebar_button" />
            <AiOutlineClose className="close_mobile_sidebar_button" />
          </div>
          <div className="user_details_container">
            {this.context.session.image_src?
            (!this.context.session.login_type)?
            <div className="user_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+this.context.session.image_src+"')"}}></div>
            :
            <img className="user_image" src={this.context.session.image_src} referrerPolicy="no-referrer"/>
            :
            <div className="user_image" style={{backgroundImage: "url('"+user_img+"')"}}></div> 
            }
            <div>
              <div className="user_fullname">{this.context.session.fullname}</div>
              <div className="user_type">{this.context.session.user_type == "partner" && this.context.session.partner_status == "APPROVED"?"Partner":""}</div>
            </div>
          </div>
        </div>
        <div className="menu_container">
          <Link  className="menu_item menu_item_theme1" to="/Dashboard">
            <i className="fas fa-chart-pie menu_icon"></i>
            <span>Dashboard</span>
          </Link>
          

          <button className="menu_item menu_item_theme1">
            <i className="fas fa-layer-group"></i>
            <span>Accounts</span>
          </button>

          <div className="menu_list menu_list_theme1">
          {this.context.session.user_type == "admin"?
            <>
            <Link to="/Dashboard/Users" className="menu_item menu_item_theme2">
              <i className="fas fa-users"></i>
              Users
            </Link>
            <Link to="/Dashboard/Partners" className="menu_item menu_item_theme2">
              <i className="fas fa-users"></i>
              Partners
            </Link>
          </>
          :""}
            <Link to="/Dashboard/ManageAccount" className="menu_item menu_item_theme2">
              <i className="fas fa-user menu_icon"></i>
                <span>Settings</span>
            </Link>
          </div>

          <button className="menu_item menu_item_theme1">
            <i className="fas fa-layer-group"></i>
            <span>Death Notices</span>
          </button>

          <div className="menu_list menu_list_theme1">
            <Link to="/Dashboard/DeathNotices/New" className="menu_item menu_item_theme2">
              <i className="far fa-address-card menu_icon"></i>
              <span>New</span>
            </Link>
            {this.context.session.user_type == "admin"?
            <>
            <Link to="/Dashboard/DeathNotices/All" className="menu_item menu_item_theme2">
              <i className="fab fa-stack-overflow"></i>
              <span> All Notices</span>
            </Link>
            </>
            :""}
            <Link to="/Dashboard/DeathNotices/MyEntries" className="menu_item menu_item_theme2">
              <i className="far fa-copy"></i>
              <span>My Notices</span>
            </Link>
            <Link to="/Dashboard/DeathNotices/Approvals"  className="menu_item menu_item_theme2">
              <i className="far fa-check-square"></i>
              <span>Pending Approval</span>
            </Link>
            <Link to="/Dashboard/DeathNotices/ReportedContents"  className="menu_item menu_item_theme2">
              <i className="far fa-check-square"></i>
              <span>Reported Contents</span>
            </Link>
          </div>
          <button className="menu_item menu_item_theme1">
            <i className="fas fa-layer-group"></i>
            <span>Memorials</span>
          </button>
          <div className="menu_list menu_list_theme1">
            <Link to="/Dashboard/Memorials/New" className="menu_item menu_item_theme2">
              <i className="far fa-address-card menu_icon"></i>
              <span>New</span>
            </Link>
            {this.context.session.user_type == "admin"?
            <>
            <Link to="/Dashboard/Memorials/All" className="menu_item menu_item_theme2">
              <i className="fab fa-stack-overflow"></i>
              <span>All Memorials</span>
            </Link>
            </>
            :""}
            <Link to="/Dashboard/Memorials/MyEntries" className="menu_item menu_item_theme2">
              <i className="far fa-copy"></i>
              <span>My Entries</span>
            </Link>
            <Link to="/Dashboard/Memorials/Approvals" className="menu_item menu_item_theme2">
              <i className="far fa-check-square"></i>
              <span>Pending Approval</span>
            </Link>
            <Link to="/Dashboard/Memorials/ReportedContents" className="menu_item menu_item_theme2">
              <i className="far fa-copy"></i>
              <span>Reported Contents</span>
            </Link>
          </div>
          <button className="menu_item menu_item_theme1">
            <i className="fas fa-layer-group"></i>
            <span>In Memory Notices</span>
          </button>
          <div className="menu_list menu_list_theme1">
            <Link to="/Dashboard/InMemoryNotices/New" className="menu_item menu_item_theme2">
              <i className="far fa-address-card menu_icon"></i>
              <span>New</span>
            </Link>
            {this.context.session.user_type == "admin"?
            <>
            <Link to="/Dashboard/InMemoryNotices/All" className="menu_item menu_item_theme2">
              <i className="fab fa-stack-overflow"></i>
              <span>All Notices</span>
            </Link>
            </>
            :""}
            <Link to="/Dashboard/InMemoryNotices/MyEntries" className="menu_item menu_item_theme2">
              <i className="far fa-copy"></i>
              <span>My Notices</span>
            </Link>
            <Link to="/Dashboard/InMemoryNotices/Approvals" className="menu_item menu_item_theme2">
              <i className="far fa-check-square"></i>
              <span>Pending Approval</span>
            </Link>
              <Link to="/Dashboard/InMemoryNotices/ReportedContents" className="menu_item menu_item_theme2">
              <i className="far fa-check-square"></i>
              <span>Reported Contents</span>
            </Link>
            
          </div> 
          <button className="menu_item menu_item_theme1">
            <i className="fas fa-layer-group"></i>
            <span>Pet Tributes</span>
          </button>

          <div className="menu_list menu_list_theme1">
            <Link to="/Dashboard/PetTributes/New" className="menu_item menu_item_theme2">
              <i className="far fa-address-card menu_icon"></i>
                <span>New</span>
            </Link>
            {this.context.session.user_type == "admin"?
            <>
            <Link to="/Dashboard/PetTributes/All" className="menu_item menu_item_theme2">
              <i className="fab fa-stack-overflow"></i>
              <span>All Pet Tributes</span>
            </Link>
            </>
            :""}
            <Link to="/Dashboard/PetTributes/MyEntries" className="menu_item menu_item_theme2">
              <i className="far fa-copy"></i>
              <span>My Entries</span>
            </Link>
            <Link to="/Dashboard/PetTributes/Approvals" className="menu_item menu_item_theme2">
              <i className="far fa-check-square"></i>
              <span>Pending Approval</span>
            </Link>
            <Link to="/Dashboard/PetTributes/ReportedContents" className="menu_item menu_item_theme2">
              <i className="far fa-copy"></i>
              <span>Reported Contents</span>
            </Link>
          </div>
          <button className="menu_item menu_item_theme1">
           <i className="fas fa-layer-group"></i>
           <span>Services</span>
         </button>
         <div className="menu_list menu_list_theme1">
         {this.context.session.user_type == "admin"?
         <>
          <Link to="/Dashboard/Services/All" className="menu_item menu_item_theme2">
           <i className="fab fa-stack-overflow"></i>
           <span>All Service Providers</span>
          </Link>
          <Link to="/Dashboard/Services/Approvals" className="menu_item menu_item_theme2">
           <i className="far fa-check-square"></i>
           <span>Pending Approval</span>
         </Link>
          <Link to="/Dashboard/Services/ReportedContents" className="menu_item menu_item_theme2">
            <i className="far fa-copy"></i>
            <span>Reported Contents</span>
          </Link>
         </>
          :this.context.session.user_type == "partner"?
           <>
            <Link to="/Dashboard/Services/Edit" className="menu_item menu_item_theme2">
              <i className="fa fa-suitcase menu_icon"></i>
              <span>My Services</span>
            </Link>
            <Link to="/Dashboard/Services/Comments" className="menu_item menu_item_theme2">
              <i className="fa fa-suitcase menu_icon"></i>
              <span>Testimonials</span>
            </Link>
            <Link to="/Dashboard/Services/ReportedContents" className="menu_item menu_item_theme2">
              <i className="far fa-copy"></i>
              <span>Reported Testimonials</span>
            </Link>
           </>
          :this.context.session.user_type == "user"?
          <Link to="/Dashboard/NewPartner" className="menu_item menu_item_theme2">
            <i className="fa fa-suitcase menu_icon"></i>
            <span>Register Services</span>
          </Link>
          :""}
          </div>
          <button className="menu_item menu_item_theme1" style={{display:this.context.session.user_type == "admin"?"grid":"none"}}>
           <i className="fas fa-layer-group"></i>
           <span>Charity</span>
          </button>
          <div className="menu_list menu_list_theme1" style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
            <Link to="/Dashboard/NewCharity" className="menu_item menu_item_theme2">
              <i className="fab fa-stack-overflow"></i>
              <span>New Charity</span>
            </Link>
            <Link to="/Dashboard/Charities/All" className="menu_item menu_item_theme2">
              <i className="fab fa-stack-overflow"></i>
              <span>All Charities</span>
            </Link>
            <Link to="/Dashboard/Charities/Comments" className="menu_item menu_item_theme2">
              <i className="fa fa-suitcase menu_icon"></i>
              <span>Testimonials</span>
            </Link>
            <Link to="/Dashboard/Charities/ReportedContents" className="menu_item menu_item_theme2">
              <i className="fab fa-stack-overflow"></i>
              <span>Reported Comments</span>
            </Link>
          </div>
          <button className="menu_item menu_item_theme1" style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
            <i className="fas fa-layer-group"></i>
            <span>Blogs</span>
            </button>
            <div className="menu_list menu_list_theme1"  style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
              <Link to="/Dashboard/Blogs/New" className="menu_item menu_item_theme2">
                <i className="fab fa-stack-overflow"></i>
                <span>New Blog</span>
              </Link>
              <Link to="/Dashboard/Blogs/MyEntries" className="menu_item menu_item_theme2">
                <i className="fab fa-stack-overflow"></i>
                <span>All Blogs</span>
              </Link>
              <Link to="/Dashboard/Blogs/Approvals" className="menu_item menu_item_theme2">
                <i className="fab fa-stack-overflow"></i>
                <span>Comments</span>
              </Link>
              <Link to="/Dashboard/Blogs/ReportedContents" className="menu_item menu_item_theme2">
                <i className="fab fa-stack-overflow"></i>
                <span>Reported Comments</span>
              </Link>
            </div>
            <Link className="menu_item menu_item_theme1" to="/Dashboard/NoticeFeedbacks">
              <i className="fas fa-layer-group"></i>
              <span>Visitor Messages</span>
            </Link>
          {this.context.session.user_type == "admin"?
          <>              
            <Link to="/Dashboard/Feedbacks" className="menu_item menu_item_theme1">
              <i className="fas fa-layer-group"></i>
              <span>Feedbacks</span>
            </Link>
            <Link to="/Dashboard/ManageEmojis" className="menu_item menu_item_theme1">
              <i className="fas fa-layer-group"></i>
              <span>Manage Emojis</span>
            </Link>
            <Link to="/Dashboard/ManageTestimonials" className="menu_item menu_item_theme1">
              <i className="fas fa-layer-group"></i>
              <span>Manage Testimonials</span>
            </Link>
            </>
            :""}  
        </div>              
      </div>
    )
  }
}

export default Sidebar;
Sidebar.contextType = MyContext;