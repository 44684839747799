import React from "react";
import './CodeOfConduct.scss';

class CodeOfConduct extends React.Component{
  constructor(props){
    super(props);
    this.code_of_conduct_ref = React.createRef();
  }

  render(){
    return(
      <div className="code_of_conduct_page_container page_container" ref={this.code_of_conduct_ref}>
        <div className="page_title">
              <div className="title_ghost"></div>
              <div className="title">Code of conduct</div>
          </div>
        <div className="code_of_conduct_container">
          <div className="home_link" onClick={() => this.props.history.push("/")}>HOME</div>
          <div className="page_link">CODE OF CONDUCT</div>
          <p>
          Please read the following Code of Conduct before you start using this website and its services. Users and non-users must not encourage, collaborate, or tolerate the violation of this Code by any other person.
          </p>
          <p>
          We encourage our users to share their ideas and participate in sharing condolences, memories and stories and other discussion forum which makes the use of amrityum.com interactive and rewarding in a very personal way. In the spirit of maintaining a positive, safe and trustworthy environment for everyone, we ask you to abide by our Code of Conduct and adhere to the following rules when posting content and interacting with other users.
          </p>
          <div className="sub_heading">User expectations</div>
          <p>
          Above all, we expect our users to be kind, helpful and respectful. We created this webspace with the intent of creating a safe space for people to share death & Family notices, create lasting memorials for the dear departed sharing about their lives and inviting others to join in expressing their experiences of them, publish life’s events, generate family trees and to honour the pets. This is by no means mandatory, but it is greatly appreciated and helps foster a safe and helpful community.
          </p>
          <div className="sub_heading">Unwelcome content</div>
          <ul className="main_list">
            <li>Adult content: Please do not post anything that will include adult content/taste. </li>
            <li>Child safety: Please do not post anything that will compromise child safely or things that endangers children. We will terminate the accounts of any user we find breaking these rules and may report them to law enforcement. Examples of this include:</li>
            <ul className="sub_list">
              <li>Sexual or suggestive content involving minors</li>
              <li>Content that promotes paedophilia and sexual attraction toward children.</li>
              <li>We know that sometimes people share nude images of their own children with good intentions (e.g. baby photos); however, we generally remove these images because of the potential for abuse by others and to help avoid the possibility of other people misappropriating the images on other sites</li>
            </ul>
            <li>Crude content: Don't post content that is shocking or graphic. (For example, don't post images of accident scenes.)</li>
            <li>Hate speech: We do not allow content that promotes hate or violence towards specific groups, which may include race, ethnicity, nationality, religion, disability, gender, age, veteran status, sexual orientation or gender identity.</li>
            <li>Bullying and harassment: We want users to express opinions, even difficult ones, but don't cross the line by harassing or bullying others. Online harassment is also illegal in many regions and may have serious offline consequences.</li>
            <li>Violence: We want our users to feel safe and respected while using our website. Making threats of harm or death to other users will not be tolerated and severe offenses may be reported to law enforcement.</li>
            <li>Personal and confidential information: It's not okay to publish another person's personal and confidential information in the forums. This includes addresses of their home or business, telephone numbers, credit card information and bank information. This website and it content are public spaces that are indexed by search engines. Therefore, we discourage users from posting personal information.</li>
            <li>Impersonating others: Don't mislead or confuse users by pretending to be someone else or representing a group or organization you don't. </li>
            <li>Spam: We may delete posts that are intended to promote your site or product, especially if they are irrelevant to the discussion.</li>
            <li>Solicitation of goods and services: We discourage posts and links about goods, and services if they are not relevant to the discussion. The posting of links that are related to a discussion will be considered on a case-by-case basis. The following posts will be removed: </li>
            <ul className="sub_list">
              <li>Links to fundraising pages (e.g. GoFundMe)</li>
              <li>Requesting participation in surveys and studies</li>
              <li>Referral links to purchase services</li>
            </ul>
          </ul>
          <div className="sub_heading">Enforcement</div>
          <p>If you encounter a message that you believe violates our content policies, please report it to us using the 'Report' link (located below the post). Alternatively, you can send a screenshot and description of the post to <span>info@amrityum.com</span>.</p>
          <p>Our team will review the reported post for policy violations. If the post does not violate our policies, we will not take any action. If the post does violate our policies, we will take one or more of the following actions based on the severity of the violation:</p>
          <ul className="main_list">
            <li>Removing the content</li>
            <li>Temporary or permanent suspension of offending account</li>
            <li>Reporting the user to law enforcement</li>
          </ul>
          <div className="sub_heading">Medical, Legal and Emergency Advice Disclaimer</div>
          <p>No content posted on our site, including public messages & comments, is to be construed or used as legal, medical, or professional advice.</p>
          <div className="sub_heading">Other questions</div>
          <p>If you have any questions regarding the use and features of the Site, please refer to the Frequently Asked Questions section of our Site. All other questions or comments about the Site should be directed to <span>info@amrityum.com</span>.</p>
        </div>
      </div>
    )
  }
}

export default CodeOfConduct;