import React from "react";
import "./PartnerApprovalList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { ButtonTheme3 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FcFullTrash } from "react-icons/fc";

class PartnerApprovalList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      partners : [],
      search_input : "",
    }
    this.partner_approval_list_page_Ref = React.createRef();
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/pendingApprovalPartners",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        partners : return_data.partners,
      },() => {
        obj_this.partner_approval_list_page_Ref.current.style.visibility = "visible";
        obj_this.partner_approval_list_page_Ref.current.style.opacity = 1;
        obj_this.context.loading(0);
      });
     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
  }

  render(){
    return(
      <div className="partner_approval_list_page_container page_container" ref={this.partner_approval_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Pending Approval</div>
          <div className="new_partner_button_container">
            {/* <ButtonTheme3
              label="Add new partner"
              onClick={()=>this.props.history.push("/Dashboard/NewPartner")}
            /> */}
          </div>
        </div>
        <div className="partner_type_select_container">
        </div>
        <div className="table_title">Partners</div>
        <div className="custom_table_theme1">
          <div className="table_row header">
            <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Business Name</div>
            <div className="table_cell fullname">Partner Name</div>
            <div className="table_cell notice_type">Address</div>
            <div className="table_cell datetime">City</div>
            <div className="table_cell datetime">State</div>
            <div className="table_cell">Status</div>
            <div className="table_cell">Submitted On</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.partners && this.state.partners.map((item,idx) => (
            <div className="table_row body"
              // style={{backgroundColor:item.status == "PENDING"?"#ebf8a4":item.status == "REJECTED"?"#ffc0cb":""}}
              key={idx} 
            >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.business_name}</div>
              <div className="table_cell">{item.published_by}</div>
              <div className="table_cell notice_type">{item.business_address}</div>
              <div className="table_cell">{item.city}</div>
              <div className="table_cell">{item.flag} {item.state}</div>
              <div className="table_cell">
              <div
                    className="status_button"
                    style={{
                      backgroundColor:
                        item.status == "PENDING"
                          ? "#fae69d"
                          : item.status == "REJECTED"
                          ? "#E26868"
                          : item.status == "DELETED"
                          ? "#fcd7d7"
                          : "",
                      color:
                        item.status == "PENDING"
                          ? "#f0891a"
                          : item.status == "REJECTED"
                          ? "#fcfafa"
                          : item.status == "DELETED"
                          ? "#c41d1d"
                          : "",
                    }}
                  >
                    {item.status}
                </div>
              </div>
              <div className="table_cell">{new Date(item.created_at).toLocaleDateString('en-GB')}</div>
              <div className="table_cell" style={{minWidth:"78px"}}>
                  <div className="action">
                    <Link className="tooltip approve" to={{ pathname: "/Preview/Partners/"+item.id, url : "/Dashboard/Services/Approvals",action: "approve"}}>
                      <span className="tooltipText approve">View</span>
                      <FaEye
                        className="approve_button"/>
                    </Link>
                    {/* <Link className="tooltip delete" to={{ pathname: "/Preview/Partners/"+item.id, url : "/Dashboard/Services/Approvals", action : "delete"}}>
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button"/>
                    </Link> */}
                  </div>
                </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.partners.length?"none":"block"}}>No results.</div>
        </div>
      </div>
    )
  }
}

export default PartnerApprovalList;
PartnerApprovalList.contextType = MyContext;