import React from "react";
import "./ManageAccount.scss";
import { InputTheme4, InputTheme1, InputTheme3, TextAreaTheme1} from "../../../res/styler/InputThemes/InputThemes";
import $ from "jquery";
import MyContext from "../../MyContext";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import ImageEditor from "../../../res/styler/ImageEditor/ImageEditor";

class ManageAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id : "",
      fullname : "",
      location : "",
      email : "",
      country_code : "",
      mobile_no : "",
      password : "",
      cnf_password : "",
      password_error : "",
      otp : "",
      mail_status : "",
      file : "",
      image_src : "",
      profile_pic_delete_flag : "",
    }
    this.getBlob = this.getBlob.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.revert_image = this.revert_image.bind(this);
    this.form_change = this.form_change.bind(this);
    this.mobile_no_change = this.mobile_no_change.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.delete_account = this.delete_account.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.manage_account_page_ref = React.createRef();
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/editUserAccount",
      data: this.context.bind_session({}),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        return_data.user.country_code = return_data.user.redirect?return_data.user.country_code:"IN";
        obj_this.setState(return_data.user,() => {
          obj_this.context.loading(0);
          obj_this.manage_account_page_ref.current.style.visibility = "visible";
          obj_this.manage_account_page_ref.current.style.opacity = 1;
        });
      } else {
        obj_this.context.loading(0);
        alert("Network error!!")
      }
    }).fail(function () {
      alert("Network error!!");
    });

  }

  getBlob(blob){
    // pass blob up from the ImageCropper component
    this.setState({
      file : blob
    });
    console.log(blob)
  }

  delete_image(){
     this.setState({
      profile_pic_delete_flag : 1,
      image_src : null,
      file : ""
    });
  }

  revert_image(){
    this.setState({
      image: ""
    });
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value
    },() => {
      var date_of_death = new Date(this.state.date_of_death);
      date_of_death = !isNaN(Date.parse(date_of_death))?date_of_death.toLocaleString('en', {dateStyle: 'short'}):null;
      const notice = "The death has occurred of "+this.state.first_name+" ("+this.state.nick_name+") "+this.state.middle_name+" "+this.state.surname+" ("+this.state.age+") "+this.state.family_or_house_name+" "+this.state.locality+" "+this.state.district_label+" "+ this.state.state_label+" on "+date_of_death;
      this.setState({
        notice_content : notice
      });
    });
  }

  onCountryChange(country){
    const country_code = country??this.state.country_code;
    this.setState({
      country_code : country_code
    })
  }

  mobile_no_change(value){
    this.setState({
      mobile_no : value
    })
  }

  form_submit(e) {
    e.preventDefault();
    console.log(this.state);
    if(this.state.mobile_no.length < 4){
      $(".PhoneInputInput").focus();
      return;
    }
    if(this.state.password){
      var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"@#$%',-./:;<=>?`{}~^&*()_+])[A-Za-z\d][A-Za-z\d!"@#$%',-./:;<=>?`{}~^&*()_+]{7,19}$/);
      if(!pattern.test(this.state.password)){
        $.alert("Your password is not strong enough. Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character.");
        return;
      }
      if(this.state.password != this.state.cnf_password){
        $.alert("Password mismatch!");
        return;
      }
    }
    this.context.loading(1);
    var obj_this = this;
    const user_details = this.state;
    const form_data = new FormData();
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image", this.state.file);
    form_data.append("user_details", JSON.stringify(user_details));
    $.ajax({
      url : process.env.REACT_APP_API + "/api/updateUserDetails",
      data : form_data,
      type : "POST",
      processData: false,
      contentType: false,
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.context.loading(0);
        obj_this.setState({
          password : "",
          cnf_password : ""
        });
        obj_this.context.setSession(return_data.session);
        alert("Account Details Updated");
        obj_this.props.redirect?obj_this.props.history.replace('/'):obj_this.props.history.replace('/Dashboard');
      }else if(return_data.status == "password_change"){
        obj_this.context.delete_session();
        obj_this.context.loading(0);
        alert("Password changed successfully. Please login again to continue");
        obj_this.props.history.replace('/Login');
      }
      else{
        alert("Network error");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  delete_account(){
    if(!window.confirm('Are you sure?')){
      return;
    }
    console.log(this.state);
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/deleteMyAccount",
      data : this.context.bind_session({}),
      type : "POST",
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.context.delete_session();
        alert("Your account deleted");
        obj_this.props.history.replace('/');
      }else{
        alert("Network error");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  render() {
    return (
      <div className="manage_account_page_container page_container" ref={this.manage_account_page_ref}>
        <form className="account_form" encType="multipart/form-data" onSubmit={this.form_submit}>
          <div className="page_header">
            <div className="page_title">Manage Account</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="#">User</Link>/
              <span>Edit</span>
            </div> */}
          </div>
          <div className="account_details">
             <div className="image_editor_container">
              <ImageEditor
                getBlob={this.getBlob}
                image_src = {this.state.image_src}
                current_image =  {this.context.session.login_type=="google" || this.context.session.login_type=="facebook"?this.state.image_src:this.state.image_src?process.env.REACT_APP_CDN_URL+"/user_profile_images/"+this.state.image_src:""}
                deleteImageSrc = {this.delete_image}
                image_width = {230 }
                image_height = {230 }
              />
            </div>
            <div>
              <div className="basic_details">
                <InputTheme4
                  label="Full Name *"
                  attrs={{
                    name: "fullname",
                    type: "text",
                    value: this.state.fullname,
                    onChange: this.form_change,
                    required: true
                  }}
                />
                <InputTheme4
                  label="Location *"
                  attrs={{
                    name: "location",
                    type: "text",
                    value: this.state.location,
                    onChange: this.form_change,
                    required :true
                  }}
                />
                <InputTheme4
                  label="Email *"
                  attrs={{
                    name: "email",
                    type: "email",
                    value: this.state.email,
                    onChange: this.form_change,
                    disabled: true
                  }}
                />
              <div className="mobile_no_input_container">
                <div className="mobile_no_label"style={{color:this.state.mobile_no?"#4f0662":""}}>Phone number *</div>
                <PhoneInput
                  style={{borderBottom:this.state.mobile_no?"2px solid #4f0662":"",color:this.state.mobile_no?"#111":""}}
                  placeholder="Phone number"
                  international
                  value={this.state.mobile_no}
                  onChange={this.mobile_no_change}
                  defaultCountry= {this.state.country_code}
                  required={true}
                  onCountryChange={(country) => this.onCountryChange(country)}
                />
              </div>
              </div>
              <div className="password_grid">
                <InputTheme4
                    label={this.props.redirect?"Create Password":"Change Password"}
                    attrs={{
                      name: "password",
                      type: "password",
                      value: this.state.password,
                      onChange: this.form_change,
                    }}
                  />
                  <InputTheme4
                    label="Confirm Password"
                    attrs={{
                      name: "cnf_password",
                      type: "password",
                      value: this.state.cnf_password,
                      onChange: this.form_change,
                    }}
                  />
                </div>
              <div className="tooltip">Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character.</div>
            </div>
          </div>
          <div className="save_button_container">
            
            <ButtonTheme1
              label="Delete My Account"
              className="delete_button"
              onClick={this.delete_account}
              type="button"
              style={{display:this.context.session.user_type == "admin"?"none":"inline-block"}}
            ></ButtonTheme1>
            <ButtonTheme1
              label="Update"
              className="update_button"
              type="submit"
            ></ButtonTheme1>
          </div>

        </form>
      </div>
    )
  }
}

export default ManageAccount;
ManageAccount.contextType = MyContext;