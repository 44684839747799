import { Component } from "react";
import "./CustomConfirm.scss";
import $ from "jquery";
import MyContext from "../MyContext";

class CustomConfirm extends Component{
  constructor(props){
    super(props);
    this.show_alert_time_out="";
    this.callback="";
    this.state={
      message: "hey",
      state: "hidden"
    };
    
    // $.alert = this.showCustomConfirm;
    this.ok_confirm_box = this.ok_confirm_box.bind(this);
  }

  componentDidMount(){

    // this.props.history.listen((location, action) => {
    // });
  }
  
  // showCustomConfirm(message="",callback){
  //   this.callback = typeof callback != undefined?callback : "";
  //   clearTimeout(this.show_alert_time_out);
  //   $(".custom_alert_box .custom_alert_message").html(message);
  //   $(".custom_alert_container").css("display","block");
  //   this.show_alert_time_out = setTimeout(() => {
  //     $(".custom_alert_container").css("opacity","1");
  //   }, 0);
  // }


  ok_confirm_box(){
    this.context.hide_confirm_box();
    if(this.context.show_confirm_box.callback)
      this.context.show_confirm_box.callback();
  }
  
  render(){
    return(
      <div className="custom_confirm_container" style={{visibility:this.context.show_confirm_box.show_flag?"visible":"hidden", opacity:this.context.show_confirm_box.show_flag?1:0}}>
        <div className="custom_confirm_box">
          <div className="custom_confirm_message">{this.context.show_confirm_box.message}</div>
          <br/>
          <button className="custom_confirm_ok_button" onClick={this.ok_confirm_box}>Ok</button>
          <button className="custom_confirm_cancel_button" onClick={this.context.hide_confirm_box}>Cancel</button>
        </div>
      </div>
    );
  }
}

export default CustomConfirm;
CustomConfirm.contextType = MyContext;