import React from "react";
import "./BlogReportedList.scss";
// import MUITable from "../MUI/MUITable";
// import Button from '@mui/material/Button';
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import $ from "jquery";
import { Link } from 'react-router-dom';
import MyContext from "../../MyContext";
import { AddButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {FcApproval, FcFullTrash} from 'react-icons/fc';

class BlogReportedList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      reported_blogs : [],
      search_input : "",
      blog_comments : [],
    }
    this.blog_reported_list_page_Ref = React.createRef();
    this.delete_reported_comment = this.delete_reported_comment.bind(this);
    this.ignore_reported_comment = this.ignore_reported_comment.bind(this);
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/pendingBlogReportedContents",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
     if(return_data.status == "success"){
      obj_this.setState({
        blog_comments : return_data.comments
      },() => {
        obj_this.context.loading(0);
        obj_this.blog_reported_list_page_Ref.current.style.visibility = "visible";
        obj_this.blog_reported_list_page_Ref.current.style.opacity = 1;
      });
     }else{
       alert("Something Went Wrong. Please Try Again");
     }
     obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    });
  }

 
  delete_reported_comment(comment_id){
    if (!window.confirm("Delete this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteReportedBlogComment",
      data: this.context.bind_session({ comment_id: comment_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          blog_comments : obj_this.state.blog_comments.filter((item) => item.id != comment_id),
        });
        alert("Blog comment deleted");
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  ignore_reported_comment(comment_id, tribute_type){
    if(!window.confirm("Are your sure")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/ignoreReportedBlogComment",
      data: this.context.bind_session({ comment_id: comment_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          blog_comments : obj_this.state.blog_comments.filter((item) => item.id != comment_id),
        });
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="blog_reported_list_page_container page_container" ref={this.blog_reported_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Reported Comments </div>
          <div className="new_blog_button_container">
            {/* <Link to="/Dashboard/InMemoryNotices/New">
              <AddButtonTheme1 label="Publish blog"/>
            </Link> */}
          </div>
        </div>
        {/* <div style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
          <div className="table_title">blogs</div>
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no">Sl no.</div>
              <div className="table_cell fullname">First Name</div>
              <div className="table_cell notice_type">Surname</div>
              <div className="table_cell">House/Family Name</div>
              <div className="table_cell datetime">Locality</div>
              <div className="table_cell datetime">State</div>
              <div className="table_cell datetime">Reported At</div>
              <div className="table_cell">Reported By</div>
              <div className="table_cell">Action</div>
            </div>
            {this.state.reported_blogs && this.state.reported_blogs.map((item,idx) => (
              <div className="table_row body"
                key={idx} 
                >                
                <div className="table_cell sl_no">{idx+1}</div>
                <div className="table_cell">{item.first_name}</div>
                <div className="table_cell">{item.surname}</div>
                <div className="table_cell">{item.family_or_house_name}</div>
                <div className="table_cell">{item.locality}</div>
                <div className="table_cell">{item.state}</div>
                <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
                <div className="table_cell">{item.fullname}</div>
                <div className="table_cell action">
                  <div className="tooltip approve">
                    <span className="tooltipText approve">Ignore</span>
                    <FcApproval className="approve_button" onClick={() => this.ignore_reported_notice(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_reported_notice(item.id)}/>
                  </div>
                </div>
              </div>
            ))}
            <div className="table_row no_results" style={{display:this.state.reported_blogs.length?"none":"block"}}>No results.</div>
          </div>
        </div> */}
        {/* <div style={{display:this.state.blog_comments.length?"block":"none"}}> */}
          {/* <div className="table_title">Comments</div> */}
          <div className="custom_table_theme1">
            <div className="table_row header">
            <div className="table_cell sl_no">Sl No.</div>
              <div className="table_cell fullname">Blog</div>
              <div className="table_cell">Comment</div>
              <div className="table_cell fullname">Reported By</div>
              <div className="table_cell datetime">Reported On</div>
              <div className="table_cell">Action</div>
              
            </div>
            {this.state.blog_comments && this.state.blog_comments.map((item,idx) => (
              <div className="table_row body"
                key={idx}
                >
                <div className="table_cell sl_no">{idx+1}</div>
                <div className="table_cell">{item.title}</div>
                {/* <div className="table_cell">{item.business_name}</div> */}
                <div className="table_cell">
                  {item.comment}
                </div>
                <div className="table_cell">{item.reported_by}</div>
                <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
                <div className="table_cell">
                  <div className="action">
                    <div className="tooltip approve">
                      <span className="tooltipText approve">Ignore</span>
                      <FcApproval className="approve_button" onClick={() => this.ignore_reported_comment(item.id)}/>
                    </div>
                    <div className="tooltip delete">
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button" onClick={() => this.delete_reported_comment(item.id )}/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="table_row no_results" style={{display:this.state.blog_comments.length?"none":"block"}}>No results.</div>
          {/* </div> */}
        </div>
      </div>
    )
  }
}

export default BlogReportedList;
BlogReportedList.contextType = MyContext;