import React from "react";
import "./Memorials.scss";
import $ from "jquery";
import MyContext from "../MyContext";
import {FaUserCircle, FaTh,FaList, FaAngleRight} from "react-icons/fa";
import { ButtonTheme1, ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import title_image from "../../res/images/home/slider5.jpg";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { InputTheme6 } from "../../res/styler/InputThemes/InputThemes";
import default_image from "../../res/images/user_icon.png";
import {Link} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import NoSearchResults from "../NoSearchResults/NoSearchResults";

class Memorials extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      memorials : [],
      citys : [],
      states : [],
      countries : [],
      search_parameters : {
        first_name : "",
        locality : "",
        surname : "",
        city : "",
        state : "",
        country : "",
        middle_name : "",
        family_or_house_name : "",
        nick_name : "",
        nee : "",
        from_date : "",
        to_date : "",
        fromdate : "",
        todate : ""
      },
      offset_value : 0,
      current_page : 0,
      per_page : 16,
      page_count : 0,
      page_no_input : 1
    }
    this.form_change = this.form_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.country_change = this.country_change.bind(this);
    this.reset_search_form = this.reset_search_form.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.memorials_page_Ref = React.createRef();
    this.memorial_block_ref = React.createRef();
    this.search_block_ref = React.createRef();
    this.offset_value = 0;
  }

  componentDidMount(){
    $(".header_container").addClass("transparent_header");
    // this.context.confirm("test",() => {
    //   this.props.history.push("/Dashboard/Memorials/New");
    // });
    if(this.props.history.location.state && typeof this.props.history.location.state == "object"){
      this.setState(this.props.history.location.state,() => {
        this.memorials_page_Ref.current.style.visibility = "visible";
        this.memorials_page_Ref.current.style.opacity = 1;
      });
    }else{ 
      if(this.props.location.search_parameters){
        var obj_this = this;
        this.setState({
          search_parameters : JSON.parse(this.props.location.search_parameters)
        },() => {
          this.context.loading(1);
          this.getMemorials();
        });
      }else{
        this.context.loading(1);
        this.getMemorials(() => {
          window.scrollTo({
            top : this.memorials_page_Ref.current.offsetTop - 80,
            behavior : "smooth"
          });
        });
      }
    }
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    });
  }

  page_no_form_submit(e){
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getMemorials(() => {
        window.scrollTo({
          top : this.memorial_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  form_change(e){
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters[e.target.name] = e.target.value;
    this.setState({search_parameters});
  }

  country_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: { country: e.target.value},
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          states : return_data.states,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    const search_parameters = this.state.search_parameters;
    search_parameters['country'] = e.target.value;
    search_parameters['state'] = "";
    this.setState({search_parameters});
  }

  state_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters['state'] = e.target.value;
    this.setState({search_parameters});
  }

  handle_page_click(e){
    this.context.loading(1);
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getMemorials(() => {
        window.scrollTo({
          top : this.memorial_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  getMemorials(callback){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API + "/api/memorials",
      data :{
        offset_value : this.state.offset_value,
        search_parameters : JSON.stringify(this.state.search_parameters)
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      obj_this.setState({
        memorials : return_data.memorials,
        countries : return_data.countries.length?return_data.countries:obj_this.state.countries,
        states : return_data.states.length?return_data.states:obj_this.state.states,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
      },() => {
        if(obj_this.props.location.search_parameters){
          window.scrollTo({
            top : obj_this.memorial_block_ref.current.offsetTop - 80,
            behavior : "smooth"
          });
        }
      });
      obj_this.context.setHistoryState({
        memorials : return_data.memorials,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
        offset_value : obj_this.state.offset_value,
        search_parameters : obj_this.state.search_parameters,
        current_page : obj_this.state.current_page,
        page_no_input : obj_this.state.page_no_input,
      },obj_this.props.history);
      obj_this.context.loading(0);
      if(callback){
        callback();
      }
      obj_this.memorials_page_Ref.current.style.visibility = "visible";
      obj_this.memorials_page_Ref.current.style.opacity = 1;
    }).fail(function (jqXHR, textStatus) {
      if (textStatus != "abort") {
        alert("Network error");
      } 
    });
  }

  show_hide_advanced_form(){
    if($(".advanced_search_inputs_container").height() == 0){
      $(".advanced_search_inputs_container").heightAuto();
      $(".show_advanced_search_button").find(".icon").html("-");
      $(".show_advanced_search_button").find(".label").html("Hide");
    }else{
      $(".advanced_search_inputs_container").css("height",0);
      $(".show_advanced_search_button").find(".icon").html("+");
      $(".show_advanced_search_button").find(".label").html("Show");
    }
  }

  form_submit(e){
    e.preventDefault();
    const search_parameters = this.state.search_parameters;
    search_parameters['fromdate'] = search_parameters.from_date?search_parameters.from_date.toLocaleDateString():"";
    search_parameters['todate'] = search_parameters.to_date?search_parameters.to_date.toLocaleDateString():"";
    if(this.state.offset_value == 0 ){
      this.setState({
        // memorials : [],
        search_parameters : search_parameters,
        page_no_input : 1
      },() => {
        this.getMemorials(() => {
          window.scrollTo({
            top : this.memorial_block_ref.current.offsetTop - 80,
            behavior : "smooth"
          });
        });
      });
    }
  }

  reset_search_form(callback = ""){
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = {
      first_name : "",
      locality : "",
      country : "",
      surname : "",
      city : "",
      state : "",
      middle_name : "",
      family_or_house_name : "",
      nick_name : "",
      nee : "",
      from_date : null,
      to_date : null,
      fromdate : "",
      todate : ""
    };
    this.setState({
      search_parameters : search_parameters,
      memorials : [],
      page_no_input : 1
    },() => {
      // $(".input_theme6_container input").trigger("focus");
      // $(".input_theme6_container input:last").trigger("blur");
      this.getMemorials(() => {
        if(typeof callback == "function"){
          callback();
        }
      });
    })
  }

  componentWillUnmount(){
    $(".header_container").removeClass("transparent_header")
  }

  render(){
    return(
      <div className="memorials_page_container" ref={this.memorials_page_Ref}>
        <div className="title_block" style={{backgroundImage:"url('"+title_image+"')"}}>
          <div className="title_buttons_container no_select">
            <div className="page_title">Memorials</div>
            <div className="title_content">Pay homage to someone special, share memories, find mutual support, and preserve legacies forever.</div>
            <div className="buttons_container">
              <div className="notice_button_theme1" onClick={() => {
                  if(!this.context.session){
                    this.context.confirm("Sign in to publish a Memorial",() => {
                      this.props.history.push({ pathname: "/Login", url : "/Dashboard/Memorials/New"})
                    });
                    return;
                  }else{
                    this.props.history.push("/Dashboard/Memorials/New");
                  }
              }}>Publish a memorial</div>
              <div className="notice_button_theme1 search_button1" onClick={() => 
                 window.scrollTo({
                  top : this.search_block_ref.current.offsetTop - 80,
                  behavior : "smooth"
                })
                }>Search memorials</div>
              <div className="notice_button_theme1"  onClick={() =>{
                  this.reset_search_form(() => {
                    window.scrollTo({
                      top : this.memorial_block_ref.current.offsetTop - 80,
                      behavior : "smooth"
                    });
                  });
                }}>View all memorials</div>
            </div>
          </div>
        </div>
        <div className="search_block" ref={this.search_block_ref}>
          <div className="search_block_ghost"></div>
          <div className="search_form_container">
            <div className="title">Search</div>
            <div className="heading">Amrityum makes it easier to find Memorials.</div>
            <form className="search_form" onSubmit={this.form_submit} method="post">
              <div className="form_grid">
                <div className="input_container">
                  {/* <div className="title1">First Name</div> */}
                  <InputTheme6 
                    label="First Name"
                    className="search_input"
                    attrs={{
                      type : "text",
                      name : "first_name",
                      value : this.state.search_parameters.first_name,
                      onChange : this.form_change
                    }}
                  />
                </div>
                <div className="input_container">
                  {/* <div className="title1">Surname</div> */}
                  <InputTheme6 
                    label="Surname"
                    className="search_input"
                    attrs={{
                      type : "text",
                      name : "surname",
                      value : this.state.search_parameters.surname,
                      onChange : this.form_change
                    }}
                  />
                </div>
                <div className="input_container">
                  {/* <div className="title1">Locality</div> */}
                  <InputTheme6 
                    label="Locality"
                    className="search_input"
                    attrs={{
                      type : "text",
                      name : "locality",
                      value : this.state.search_parameters.locality,
                      onChange : this.form_change
                    }}
                  />
                </div>
              </div>
              <button className="show_advanced_search_button" 
                onClick={this.show_hide_advanced_form}
                type="button"
              >
                <div className="button_ghost"></div>
                {/* <span className="label">Show</span>&nbsp; */}
                Advanced Search&nbsp;
                <span className="icon">+</span>
              </button>
              <div className="advanced_search_inputs_container">
                <div className="advanced_search_inputs">
                  <div className="input_container">
                    {/* <div className="title1">Middle Name</div> */}
                    <InputTheme6 
                      label="Middle Name"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "middle_name",
                        value : this.state.search_parameters.middle_name,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">House/Family Name</div> */}
                    <InputTheme6 
                      label="House/Family Name"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "family_or_house_name",
                        value : this.state.search_parameters.family_or_house_name,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">Nick Name</div> */}
                    <InputTheme6 
                      label="Nick Name"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "nick_name",
                        value : this.state.search_parameters.nick_name,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">Nee</div> */}
                    <InputTheme6 
                      label="Née"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "nee",
                        value : this.state.search_parameters.nee,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">STATE</div> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Country</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.search_parameters.country}
                        label="Country"
                        onChange={this.country_change}
                      >
                        {this.state.countries && this.state.countries.map((item, index) => (
                          <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">STATE</div> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">State/Province/County/Region</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={
                          this.state.search_parameters.country ? false : true
                        }
                        value={this.state.search_parameters.state}
                        label="State/Province/County/Region"
                        onChange={this.state_change}
                      >
                        {this.state.states && this.state.states.map((item, index) => (
                          <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">Locality</div> */}
                    <InputTheme6 
                      label="City/Town"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "city",
                        value : this.state.search_parameters.city,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  
                  <div className="input_container">
                    {/* <div className="title1">FROM DATE</div> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DatePicker
                        label="From date"
                        value={this.state.search_parameters.from_date}
                        onChange={(newValue) => {
                          this.state.offset_value = 0;
                          this.state.current_page = 0;
                          const search_parameters = this.state.search_parameters;
                          search_parameters['from_date'] = newValue;
                          this.setState({ search_parameters});
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        maxDate={new Date()}
                        required={true}
                        openTo="year"
                        views={["year", "month", "day"]}
                        inputFormat="dd/MM/yyyy"
                        allowSameDateSelection={true}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">TO DATE</div> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DatePicker
                        label="To date"
                        value={this.state.search_parameters.to_date}
                        onChange={(newValue) => {
                          this.state.offset_value = 0;
                          this.state.current_page = 0;
                          const search_parameters = this.state.search_parameters;
                          search_parameters['to_date'] = newValue;
                          this.setState({ search_parameters});
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={this.state.search_parameters.from_date?new Date(this.state.search_parameters.from_date):undefined}
                        maxDate={new Date()}
                        required={true}
                        openTo="year"
                        views={["year", "month", "day"]}
                        inputFormat="dd/MM/yyyy"
                        allowSameDateSelection={true}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="buttons_container">
                <ButtonTheme2 label="Find Memorials" className="search_button"></ButtonTheme2>
                <ButtonTheme2
                  label="Reset"
                  type="button"
                  className="reset_form_button"
                  onClick={this.reset_search_form}
                ></ButtonTheme2>
              </div>
            </form>
          </div>
        </div>
         <div className="view_type_block" ref={this.memorial_block_ref}>
            <div className="heading" style={{visibility:this.props.location.search_parameters?"visible":"hidden"}}>Memorials</div>
            <div className="view_type_container">
              <div className="notices_view_type_button_container">
                <div className="image_view_button selected_view_type"
                onClick={() => {
                  $(".image_view_button").addClass("selected_view_type")
                      $(".text_view_button").removeClass("selected_view_type")
                  $(".memorials_grid").css("display","grid");
                  $(".table_container").css("display","none");
                }}
                ><FaTh /></div>
                <div className="text_view_button"
                onClick={() => {
                  $(".image_view_button").removeClass("selected_view_type")
                      $(".text_view_button").addClass("selected_view_type")
                  $(".memorials_grid").css("display","none");
                  $(".table_container").css("display","block");
                }}
                ><FaList /></div>
              </div>
            </div>
          </div>
          <div className="memorials_block">
            <div className="memorials_grid">
              {this.state.memorials && this.state.memorials.map((item,idx) => (
              <Link className="single_notice_container"
                key={idx}
                to={"/Memorials/"+item.id}
              >
                <div className="image_container">
                  {item.image_src?
                  <div className="deceased_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/memorial_images/"+item.image_src+"')"}}></div>
                  :
                  <div className="deceased_image" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
                  }
                  <div className="image_ghost"></div>
                </div>
                {/* <div className="deceased_name_duration_grid"> */}
                  <div className="deceased_name">{item.first_name} {item.surname}</div>
                  {isNaN(item.age)?
                  <div className="deceased_duration">({item.age})</div>
                  :
                  <div className="deceased_duration">({new Date(item.date_of_birth).getFullYear()} - {new Date(item.date_of_death).getFullYear()})</div>
                  }
                  {/* </div> */}
                <div className="deceased_date">
                  <div className="month">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString('default', { month: 'short' })}</div>
                  <div className="day">{new Date(item.published_date.replace(/-/g, "/")).getDate()}</div>
                </div>
                <div className="notice_details_ghost">
                  <div className="name">{item.first_name} {item.surname}</div>
                  {isNaN(item.age)?
                  <div className="duration">({item.age})</div>
                  :
                  <div className="duration">({new Date(item.date_of_birth).getFullYear()} - {new Date(item.date_of_death).getFullYear()})</div>
  }
                  <div className="locality">{item.locality}</div>
                  <div className="state">{item.city}, {item.state?item.state+", ":""}</div>
                  <div className="country">{item.country}</div>
                </div>
              </Link>
              ))}
            </div>
            <div className="table_container">
              <div className="custom_table_theme2" style={{display:this.state.memorials.length?"table":"none"}}>
                <div className="table_row header">
                  <div className="table_cell sl_no">Sl No.</div>
                  <div className="table_cell"></div>
                  <div className="table_cell fullname">Name</div>
                  <div className="table_cell">Locality</div>
                  <div className="table_cell">State</div>
                  <div className="table_cell">Country</div>
                  <div className="table_cell">Date of Death</div>
                  <div className="table_cell">Date of Birth</div>
                  <div className="table_cell">Published By</div>
                </div>
                {this.state.memorials &&
                this.state.memorials.map((item, idx) => (
                <Link
                  className="table_row body"
                  key={idx}
                  to={"/Memorials/"+ item.id}
                >
                  <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
                  <div className="table_cell">
                    {item.image_src?
                    <div className="person_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/memorial_images/"+item.image_src+"')"}}></div>
                    :
                    <div className="person_image" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
                    }
                  </div>
                  <div className="table_cell fullname">{item.first_name} {item.surname}</div>
                  <div className="table_cell">{item.locality}</div>
                  <div className="table_cell">{item.state}</div>
                  <div className="table_cell">{item.country}</div>
                  <div className="table_cell">{new Date(item.date_of_birth).toLocaleDateString('en-GB')}</div>
                  <div className="table_cell">{new Date(item.date_of_death).toLocaleDateString('en-GB')}</div>
                  <div className="table_cell">{item.published_by}</div>
                </Link>
                ))}
              </div>
            </div>
            {
              this.state.memorials.length?"":
              <NoSearchResults/>
            }
            <div className="pagination_container" style={{display:this.state.memorials.length && this.state.page_count > 1?"block":"none"}}>
              <ReactPaginate
                previousLabel={"PREV"}
                nextLabel={"NEXT"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.page_count}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={this.handle_page_click}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={this.state.current_page}
              />
              <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
                <span className="page_count">Go to page</span>
                <input className="page_no_input"
                  type="number"
                  name="page_count"
                  value={this.state.page_no_input}
                  onChange={this.page_no_change}
                  min={1}
                  max={this.state.page_count}
                />
                <button type="submit" className="page_no_search_button">
                  <span>Go</span>
                  <FaAngleRight/>
                </button>
              </form>
            </div>
          </div>
      </div>
    )
  }
}

export default Memorials;
Memorials.contextType = MyContext;