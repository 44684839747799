import React from "react";
import "./Register.scss";
import image from "../../res/images/signup_background.jpg";
import {Link} from "react-router-dom";
import {InputTheme3} from "../../res/styler/InputThemes/InputThemes";
import $ from "jquery";
import logo from "../../res/images/Amrityum_01.png";
import dove_icon from "../../res/images/AMRITYUM-02.jpg";
import MyContext from "../MyContext";
import { MdOutlineMarkEmailRead, MdOutlineAccountCircle , MdOutlineImage} from 'react-icons/md';
import {HiDotsHorizontal} from "react-icons/hi";
import ImageUpload from "../../res/styler/ImageUpload/ImageUpload";
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import google_icon from "../../res/images/login/google_icon.png";
import facebook_icon from "../../res/images/login/facebook_icon.png";
import email_icon from "../../res/images/login/email_icon.png";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
} from 'reactjs-social-login';
import ManageAccount from "../Dashboard/ManageAccount/ManageAccount";

class Register extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      fullname : "",
      location : "",
      email : "",
      mobile_no : "",
      password : "",
      cnf_password : "",
      password_error : "",
      otp : "",
      mail_status : "",
      image : "",
      email_login : 0,
      country_code : "IN"
    }

    this.reg_form_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.image_change = this.image_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.heartbeat = this.heartbeat.bind(this);
    this.mobile_no_change = this.mobile_no_change.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.otp_form_submit = this.otp_form_submit.bind(this);
    this.resend_otp = this.resend_otp.bind(this);
    this.successGoogleLogin = this.successGoogleLogin.bind(this);
    this.failureGoogleLogin = this.failureGoogleLogin.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.delete_image = this.delete_image.bind(this);
  }

  componentDidMount(){
    $(".header_container .header_menu, .header_container .signin_signup_button").css("display","none");
    $(".header_container .header_menu, .header_container .dropdown").css("display","none");
    $(".header_container .login_button_container").css("display","block");
  }

  componentWillUnmount(){
    $(".header_container .header_menu, .header_container .signin_signup_button").css("display","inline");
    $(".header_container .header_menu, .header_container .dropdown").css("display","inline-block");
    $(".header_container .login_button_container").css("display","none");
  }

  form_change(e){
    this.setState({
      [e.target.name] : e.target.value,
      password_error : ""
    });
  }

  image_change(e) {
    const file = e.target.files[0];
    if (!(file.size / 1024 > 2048)){
      this.setState({
        image: file,
      });
    }
  }

  delete_image(){
    this.setState({
      image : ""
    });
  }

  mobile_no_change(value){
    this.setState({
      mobile_no : value
    })
  }

  onCountryChange(country){
    const country_code = country??this.state.country_code;
    this.setState({
      country_code : country_code
    })
  }

  form_submit(e){
    e.preventDefault();
    console.log(this.state);
    // return;
    if(!this.state.fullname){
      $.alert("Name Required");
      $("input[name='fullname']")[0].focus();
      return;
    }
    if(!this.state.location){
      $.alert("Place Required");
      $("input[name='location']")[0].focus();
      return;
    }
    if(!this.state.email){
      $.alert("Email Required");
      $("input[name='email']")[0].focus();
      return;
    }
    var re = /\S+@\S+\.\S+/;
    console.log(this.state.email)
    if (!re.test(this.state.email)) {
      $.alert("Please enter an email address");
      $("input[name='email']")[0].focus();
      return;
    }
    if (this.state.mobile_no.length < 4) {
      $.alert("Mobile Number Required");
      $(".PhoneInputInput")[0].focus();
      return;
    }
    if(!this.state.password){
      $.alert("Password Required");
      $("input[name='password']")[0].focus();
      return;
    }
    var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"@#$%',-./:;<=>?`{}~^&*()_+])[A-Za-z\d][A-Za-z\d!"@#$%',-./:;<=>?`{}~^&*()_+]{7,19}$/);
    // return;
    if(!pattern.test(this.state.password)){
      this.setState({
        password_error : "Your password is not strong enough. Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character."
      });
      return;
    }
    if(this.state.password != this.state.cnf_password){
      this.setState({
        password_error : "Password mismatch!",
      });
      this.reg_form_ref.current.scrollTo(0, 0);
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    const user_details = this.state;
    const form_data = new FormData();
    form_data.append("image", this.state.image);
    delete user_details.image;
    form_data.append("user_details", JSON.stringify(user_details));
    $.ajax({
      url : process.env.REACT_APP_API + "/api/userRegistration",
      data : form_data,
      type : "POST",
      processData: false,
      contentType: false,
    }).done(function(return_data){
      // console.log(return_data);
      obj_this.context.loading(0);
      if(return_data.status == "success"){
        if(return_data.mail_status == "success"){
          obj_this.setState({
            user_id : return_data.user_id,
            mail_status : return_data.mail_status
          });
          // alert("A OTP has been sent to "+obj_this.state.email);
        }else{
          alert("Registration failed")
          obj_this.setState({
            fullname : "",
            // surname : "",
            email : "",
            location : "",
            mobile_no : "",
            password : "",
            cnf_password : "",
            password_error : "",
            otp : "",
            mail_status : "",
            image : ""
          });
        }
      }else if(return_data.status == "exist"){
        alert("Email address is already registered"); 
      }else if(return_data.status == "deleted"){
        alert("You can't login with this email. Please contact administrator.");
      }else if(return_data.status == "deactivated"){
        alert("You have an account with this email and it is temporarily deleted. Please contact admin to activate your account");
      }else{
        alert("Network error");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  otp_form_submit(e){
    e.preventDefault();
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/otpValidation",
      data : {
        otp : this.state.otp,
        user_id : this.state.user_id
      },
      type : "POST"
    }).done(function(return_data){
      obj_this.context.loading(0);
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.context.setSession(return_data.session);
        setTimeout(obj_this.heartbeat,5000);
        obj_this.props.history.push('/');
      }else if (return_data.status == "timeout"){
        obj_this.setState({
          otp : ""
        });
        alert("OTP expired. Please click resend OTP button");
      }else{
        obj_this.setState({
          otp : ""
        });
        alert("OTP verification failed. Please try again");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  resend_otp(){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/resendOTP",
      data : {
        user_id : this.state.user_id
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      obj_this.context.loading(0);
      if(return_data.status == "success"){
        alert("A new One Time Password has been send to your registered email address. Please enter it below, to continue.");
        obj_this.setState({
          user_id : return_data.user_id,
          mail_status : return_data.mail_status
        });
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  successGoogleLogin(data) {
    // console.log(data);
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/googleLogin",
      data: {
        token_id : data.access_token
      },
      type: "POST"
    }).done(function(return_data) {
      obj_this.context.loading(0);
      if (return_data.status == "success") {
        // console.log(return_data);
        // return;
        obj_this.context.setSession(return_data.session);
        setTimeout(obj_this.heartbeat,5000);
        if(return_data.session.redirect_page == "redirect"){
          alert("Please complete your profile details, to continue using Amrityum.")
          $(".profile_complete_popup").css("display","block");
        }else{
          obj_this.props.history.replace(obj_this.props.location.url ?? "/");
        }
      }else if(return_data.status == "deleted"){
        alert("You can't login with this email. Please contact administrator.")
      }else if(return_data.status == "deactivated"){
        alert("You have an account with this email and it is temporarily deleted. Please contact admin to activate your account");
      }else {
        alert("Login error");
      }
    }).fail(function () {
      alert("Network error");
    });
  }

  failureGoogleLogin(response) {
    console.log(response);
    // alert("Can't sign in to your Google Account")
  }
  
  responseFacebook(data){
    // console.log(data);
    // console.log(data.profile)
    if(data && ("name" in data)){
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/facebookLogin",
        data: {
          token_id : data.accessToken
        },
        type: "POST"
      }).done(function(return_data) {
        // console.log(return_data);
          obj_this.context.loading(0);
        if (return_data.status == "success") {
          // console.log(return_data);
          obj_this.context.setSession(return_data.session);
          setTimeout(obj_this.heartbeat,5000);
          if(return_data.session.redirect_page == "redirect"){
            alert("Please complete your profile details, to continue using Amrityum.")
            $(".profile_complete_popup").css("display","block");
          }else{
            obj_this.props.history.replace(obj_this.props.location.url ?? "/");
          }
        }else if(return_data.status == "deleted"){
          alert("You can't login with this email. Please contact administrator.")
        }else {
          alert("Login error");
        }
      }).fail(function () {
        alert("Network error");
      });
    }else{
      alert("Can't sign in to your Facebook Account")
    }
  }

  heartbeat(){
    // console.log("heartbeat from register");
    var obj_this = this;
    var heartbeat_timeout;
    this.context.heartbeat_XHR = $.ajax({
      url: process.env.REACT_APP_API + "/api/heartbeat",
      data: this.context.bind_session({}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data);
      if(return_data.status == "success"){
        if(window.location.href.includes("/Dashboard/Services/Edit")){
          if(return_data.session.user_type == "user"){
            obj_this.context.setSession(return_data.session);
            alert("We regret to inform you that your services have been deleted/rejected by the administrator. Please check your email for further information regarding the reason for this action");
            obj_this.props.history.replace('/Dashboard');
          }
        }
        obj_this.context.setSession(return_data.session);
        if(obj_this.context.session)
          heartbeat_timeout = setTimeout(obj_this.heartbeat,5000);
      }else if(return_data.status == "deleted"){
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        alert("Sorry, your account has been deleted. Contact Administrator");
        obj_this.props.history.replace('/');
      }else{
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        // alert("Your session has timed out. Please login again.");
        // obj_this.props.history.replace("/Login");
      }
    }).fail(function(jqXHR,textStatus, errorThrown){
      console.log(errorThrown);
      if(errorThrown == "abort"){
        clearTimeout(heartbeat_timeout);
      }else{
        setTimeout(obj_this.heartbeat,5000);
      }
    });
  }

  render(){
    return(
      <div className="register_page_container">
        <div className="register_grid" style={{display:this.context.session?"none":"grid"}}>
          <div className="register_form_container">
          <div className="register_container">
            Already have an account ?&nbsp;
            <Link to="/Login" className="login_link">Login</Link>
          </div>
            {this.state.mail_status?
              <form className="otp_verification_form" onSubmit={this.otp_form_submit}>
                <div className="form_body">
                  <div className="dove_icon" style={{backgroundImage:"url('"+dove_icon+"')"}}></div>
                  <div className="otp_verification">OTP Verification</div>
                  <div className="otp_message">
                    <p> Please enter the One Time Password (OTP), received via email({this.state.email}).  
                    If you didn’t get the email, <b>check your junk folder</b> or try again. </p>
                   <p>
                    By continuing you agree to our <Link to="TermsAndConditions" target="_blank">Terms and Conditions </Link>,<Link to="PrivacyPolicy" target="_blank">
                    Privacy Policy</Link> and  <Link to="/CookiesPolicy" target="_blank">Cookies Statement.</Link> 
                   </p>
                  </div>
                  <InputTheme3 
                    class="otp_input"
                    label="OTP"
                    attrs= {{
                      type : "tel",
                      name : "otp",
                      value : this.state.otp,
                      pattern : "^[0-9]{6}$",
                      onChange : this.form_change,
                      required : true,
                      maxLength:6
                    }}
                  />
                  <div className="resend_otp_button_container">
                    Not received your OTP?&nbsp;
                    <div className="resend_otp_button"
                      onClick={this.resend_otp}
                    > Resend OTP</div>
                  </div>
                  <ButtonTheme2 className="verify_button" label="VERIFY"></ButtonTheme2>
                </div>
              </form>
            : 
              <form className="register_form" onSubmit={this.form_submit} method="post" ref={this.reg_form_ref}>
                <div className="form_body">
                  <img className="logo_image" onClick={() => this.props.history.push('/')} src={logo}/>
                  <h2 className="create_account_label">Create your account now</h2>
                  {!this.state.email_login?
                  <>
                  <div className="signup_description">
                  Gain instant access to everything Amrityum offers as a publisher and a contributor.
                  </div>
                  <div className="signup_buttons_container">
                    <button className="signup_button" onClick={() => this.setState({ email_login: 1})}>
                      <div className="social_icon" style={{backgroundImage:"url('"+email_icon+"')"}}></div>
                      <div className="social_login_label">Sign Up with Email</div>
                    </button>
                    <LoginSocialGoogle
                      client_id={process.env.REACT_APP_GG_APP_ID || ''}
                      // onLoginStart={onLoginStart}
                      redirect_uri="https://amrityum.com"
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={({ data }, IResolveParams) => {
                        this.successGoogleLogin(data)
                      }}
                      onReject={err => {
                        console.log(err);
                        this.context.loading(0);
                      }}
                      onLoginStart={() => {
                        this.context.loading(1);
                      }}
                    >
                      <div className="signup_button">
                        <div className="social_icon" style={{backgroundImage:"url('"+google_icon+"')"}}></div>
                        <div className="social_login_label">Sign Up with Google</div>
                      </div>
                  </LoginSocialGoogle>
                  <LoginSocialFacebook
                    appId={process.env.REACT_APP_FB_APP_ID || ''}
                    fieldsProfile={
                      'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                    }
                    // onLogoutSuccess={this.responseFacebook}
                    redirect_uri="https://amrityum.com"
                    onResolve={({ data }, IResolveParams) => {
                      this.responseFacebook(data);
                    }}
                    onReject={err => {
                      console.log(err);
                      this.context.loading(0);
                    }}
                    onLoginStart={() => {
                      this.context.loading(1);
                    }}
                    >
                    <div className="signup_button">
                      <div className="social_icon" style={{backgroundImage:"url('"+facebook_icon+"')"}}></div>
                      <div className="social_login_label">Sign Up with Facebook</div>
                    </div>
                  </LoginSocialFacebook>
                  </div>
                  </>
                  :
                  <>
                  <div className="form_tabs_toggles_container">
                    <div className="tab_toggle tab_toggle_selected"
                      id="basic_toggle"
                      onClick={(e) => {
                        document.getElementById("account_toggle").classList.remove("tab_toggle_selected");
                        e.currentTarget.classList.add("tab_toggle_selected");
                        document.getElementsByClassName('form_tabs_container')[0].scroll({
                          left : 0,
                          top : 0,
                          // behavior : "smooth"
                        }) ;
                      }}
                    >
                      <div className="icon_container">
                        <MdOutlineImage />
                      </div>
                      <div>Step 1</div>
                    </div>
                    <div className="toggle_separator">
                      <HiDotsHorizontal />
                    </div>
                    <div className="tab_toggle"
                      id="account_toggle"
                      onClick={(e) => {
                        if($("input[name='fullname']").val() == ""){
                          $("input[name='fullname']").trigger("focus");
                          return;
                        }
                        if($("input[name='location']").val() == ""){
                          $("input[name='location']").trigger("focus");
                          return;
                        }
                        document.getElementById("basic_toggle").classList.remove("tab_toggle_selected");
                        e.currentTarget.classList.add("tab_toggle_selected");
                        document.getElementsByClassName('form_tabs_container')[0].scroll({
                          left : document.querySelector(".basic_tab").offsetWidth,
                          top : 0,
                          // behavior : "smooth"
                        });
                      }}
                    >
                      <div className="icon_container">
                        <MdOutlineAccountCircle />
                      </div>
                      <div>Step 2</div>
                    </div>
                  </div>
                  <div className="form_tabs_container">
                    <div className="basic_tab">
                      <div className="upload_image_label">Upload a Profile Image</div>
                      <ImageUpload className="image_upload_component"
                        attrs={{
                          name: "image",
                          accept: "image/png, image/gif, image/jpeg",
                          max_size : 2048
                        }}
                        onChange={this.image_change}
                        deleteImage = {this.delete_image}
                        src = {this.state.image_src?process.env.REACT_APP_CDN_URL+"/death_notices/profile_images/"+this.state.image_src:""}
                      />
                      <InputTheme3
                        label = "Full Name *"
                        attrs = {{
                          name : "fullname",
                          value : this.state.fullname,
                          onChange : this.form_change,
                          // required : true,
                          pattern : "^[A-Za-z ]+$"
                        }}
                        className="register_input"
                      />
                      <div className="email_hint">Your location appears with your name on comments made.</div>
                      <InputTheme3
                        label = "Name of your place *"
                        attrs = {{
                          name : "location",
                          value : this.state.location,
                          onChange : this.form_change,
                          // required : true,
                          pattern : "^[A-Za-z ]+$"
                        }}
                        className="register_input"
                      />
                      <div style={{textAlign:"center"}}>
                        <ButtonTheme2 label="Next >>" 
                          onClick={() => {
                            if($("input[name='fullname']").val() == ""){
                              $("input[name='fullname']")[0].focus();
                              return;
                            }
                            if($("input[name='location']").val() == ""){
                              $("input[name='location']").trigger("focus");
                              return;
                            }
                            document.getElementById("basic_toggle").classList.remove("tab_toggle_selected");
                            document.getElementById("account_toggle").classList.add("tab_toggle_selected");
                            document.getElementsByClassName('form_tabs_container')[0].scroll({
                              left : document.querySelector(".basic_tab").offsetWidth,
                              top : 0,
                              // behavior : "smooth"
                            }) 
                          }}
                          type="button"
                          className="next_button" />
                      </div>
                    </div>
                    <div className="account_tab">
                      <div className="email_hint">Enter your email address to receive a verification code</div>
                      <InputTheme3
                        label = "Email *"
                        attrs = {{
                          name : "email",
                          type : "email",
                          value : this.state.email,
                          onChange : this.form_change,
                          // required : true
                        }}
                        className="email_input register_input"
                      />
                      <PhoneInput
                        style={{border:this.state.mobile_no?"2px solid #5a2d8f":"",color:this.state.mobile_no?"#111":""}}
                        placeholder="Phone number *"
                        international
                        value={this.state.mobile_no}
                        onChange={this.mobile_no_change}
                        defaultCountry="IN"
                        required={true}
                        onCountryChange={(country) => this.onCountryChange(country)}
                      />
                      
                      <InputTheme3
                        label = "Password *"
                        attrs = {{
                          name : "password",
                          type : "password",
                          value : this.state.password,
                          onChange : this.form_change,
                          // required : true,
                          // pattern : "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                        }}
                        className="register_input"
                      />
                      <InputTheme3
                        label = "Confirm password *"
                        className="cnf_input register_input"
                        attrs = {{
                          name : "cnf_password",
                          type : "password",
                          value : this.state.cnf_password,
                          onChange : this.form_change,
                          // required : true
                        }}
                      />
                      <div className="password_error" style={{visibility: this.state.password_error?"visible":"hidden"}}>{this.state.password_error}&nbsp;</div>
                      <div className="password_hint" style={{display:this.state.password?"none":"block"}}> 	
                        Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character.
                      </div>

                      <ButtonTheme2 label="Register" className="register_button" />
                      {/* <div className="terms_and_policy"> By signing up, you agree to the <Link to="/TermsAndConditions">Terms and conditions</Link>
                     &nbsp; and <Link to="/TermsAndConditions">Privacy Policy</Link></div> */}
                    </div>
                  </div>
                  </>
                  }
                </div>
                <div className="close_signup_form_button"
                style={{display:this.state.email_login?"block":"none"}}
                onClick={() => {this.setState({
                    email_login : 0,
                    fullname : "",
                    location : "",
                    email : "",
                    mobile_no : "",
                    password : "",
                    cnf_password : "",
                    image : ""
                  })
                }}>x</div>
                <div className="other_terms_and_policy"> By signing up, you agree to the <Link to="/TermsAndConditions" target="_blank">Terms and conditions</Link>
                    &nbsp; and <Link to="/PrivacyPolicy" target="_blank">Privacy Policy</Link></div>
              </form> 
            }
           
          </div>
          <div className="image_container">
            <div className="image" style={{backgroundImage:"url('"+image+"')"}}></div>
          </div>
        </div>
        {this.context.session?
        <div className="profile_complete_popup">
          <ManageAccount history = {this.props.history} redirect={1}/>
        </div>:""}
      </div>
    )
  }
}

export default Register;
Register.contextType = MyContext;