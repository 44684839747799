import React from "react";
import "./SingleMemorial.scss";
import $ from "jquery";
import {Link} from "react-router-dom";
import MyContext from "../MyContext";
import {GoReport} from 'react-icons/go';
import ImageUpload from "../../res/styler/ImageUpload/ImageUpload";
import {MdOutlineArrowForwardIos,MdOutlineArrowBackIos, MdOutlineKeyboardBackspace , MdOutlineShare, MdOutlinePrint} from 'react-icons/md';
import {FaRegComment,FaUserCircle,FaQuoteRight } from 'react-icons/fa';
import {AiFillHeart, AiOutlineClose} from "react-icons/ai";
import { VscChromeClose } from "react-icons/vsc";
import logo from "../../res/images/Amrityum_01.png";
import {ImArrowRight2} from 'react-icons/im';
import {HiArrowSmRight, HiArrowSmLeft} from "react-icons/hi";
import {BsChevronDoubleDown, BsChevronDoubleUp} from "react-icons/bs";
import default_image from "../../res/images/user_icon.png";
import Footer from "../Footer/Footer";
import {ButtonTheme1, ButtonTheme2} from "../../res/styler/ButtonThemes/ButtonThemes";
import LogoLoading from "../PraveenaLoading/LogoLoading";
import memories from "../../res/images/memorials/default_memories_01.jpg";
import candle from "../../res/images/death_notices/candle.png";
import flower from "../../res/images/death_notices/flower.png";
import note from "../../res/images/death_notices/1177948.png";
import survived from "../../res/images/death_notices/survived.jpg";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import AwesomeSlider from "react-awesome-slider";
import AwsSliderStyles from 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-multi-carousel/lib/styles.css";
import ReCAPTCHA from "react-google-recaptcha";
import user_img from "../../res/images/user_img.jpg";
import logo_icon from "../../res/images/user_icon.png";

class SingleMemorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memorial: {
        first_name: "",
        surname : "",
        date_of_birth: "",
        date_of_passing: "",
        locality: "",
        image_src: ""
      },
      tribute_type: "candle",
      tribute_text: "",
      editorState: EditorState.createEmpty(),
      memories_content : "",
      image : "",
      memory_image_src : "",
      // written_by: "",
      // email: "",
      memorial_memories: [],
      showing_memories : [],
      memorial_tributes: [],
      life_question_answer : "",
      life_questions : [],
      general_condolence_messages : [],
      milestones : [],
      gallery_images : [],
      zoomed_item : {},
      image_index : 0,
      name : "",
      email : "",
      message : "",
      reason : "",
      memorial_condolences : [],
      showing_condolence_messages : [],
      showing_mementos : [],
      predeceased_peoples : [],
      survived_peoples : [],
      charity : {},
      family_notice_mapped_id : "",
      death_notice_mapped_id : "",
      preview : "",
      reject_reason: "",
      captcha_token: "",
      milestone_preview : {},
      milestone_index : 0,
      page_share_url : "",
      fullname : ""
    }
    this.form_change = this.form_change.bind(this);
    this.show_form = this.show_form.bind(this);
    this.hide_form = this.hide_form.bind(this);
    this.life_question_answer_change = this.life_question_answer_change.bind(this);
    this.image_change = this.image_change.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.message_change = this.message_change.bind(this);
    this.report_message = this.report_message.bind(this);
    this.tribute_form_submit = this.tribute_form_submit.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.zoomImage = this.zoomImage.bind(this);
    this.show_more_message = this.show_more_message.bind(this);
    this.minimizeImage = this.minimizeImage.bind(this);
    this.previousImage = this.previousImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.faq_slide_left = this.faq_slide_left.bind(this);
    this.faq_slide_right = this.faq_slide_right.bind(this);
    this.on_like_button_click = this.on_like_button_click.bind(this);
    this.contact_admin_form_submit = this.contact_admin_form_submit.bind(this);
    this.show_report_page_form = this.show_report_page_form.bind(this);
    this.report_page_form_submit = this.report_page_form_submit.bind(this);
    this.bind_likes = this.bind_likes.bind(this);
    this.tribute_type_change = this.tribute_type_change.bind(this);
    this.tribute_image_change = this.tribute_image_change.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.approveMemorial = this.approveMemorial.bind(this);
    this.rejectMemorial = this.rejectMemorial.bind(this);
    this.reject_form_submit = this.reject_form_submit.bind(this);
    this.milestone_preview = this.milestone_preview.bind(this);
    this.milestone_preview_button_click = this.milestone_preview_button_click.bind(this);
    this.reject_reason_container_ref = React.createRef();
    this.single_memorial_page_Ref = React.createRef();
    this.memorial_ref = React.createRef();
    this.family_details_ref = React.createRef();
    this.condolence_ref = React.createRef();
    this.mementos_ref = React.createRef();
    this.memory_story_ref = React.createRef();
    this.biography_ref = React.createRef();
    this.milestone_ref = React.createRef();
    this.gallery_ref = React.createRef();
    this.life_qs_ans_ref = React.createRef();
    this.contact_admin_ref = React.createRef();
    this.donate_ref = React.createRef(); 
    this.captchaRef = React.createRef();
    this.month_names = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.memories_limit = 3;
    this.condolence_limit = 3;
    this.mementos_limit = 3;
    this.user_likes = [];
    this.user_like_count = [];
    this.disclaimer_text = "Please write your correct name and other information requested. Kindly do not post any personal, abusive, defamatory, infringing, obscene, indecent, discriminatory, or unlawful or similar comments. Amrityum.com will not be responsible for any defamatory message posted under this notice. Please note that sending false messages to insult, defame, intimidate, mislead, or deceive people or to intentionally cause public disorder is punishable under law. It is obligatory on amrityum.com to provide all details we hold of senders of such comments, to the authority concerned upon request. Hence, sending offensive comments using amrityum will be purely at your own risk, and in no way will amrityum.com be held responsible."
  }

  componentDidMount() {
    if(window.location.pathname == "/Memorial/Preview/"+this.props.match.params.memorial_id){
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/previewMemorialDetails",
        data: this.context.bind_session({
          'memorial_id': this.props.match.params.memorial_id,
          'email' :this.context.session.email
        }),
        type: "POST"
      }).done(function (return_data) {
        // console.log(return_data);
        if (return_data.status == "success"){
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const memories = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "memory");
          
          const survived_peoples = return_data.memorial_family_details.filter((item) =>  item.type == "survived");
          const predeceased_peoples = return_data.memorial_family_details.filter((item) =>  item.type == "predeceased");
          
          const memorial_condolences = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "note");
          const memorial_tributes = return_data.memorial_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
          obj_this.bind_likes(memorial_condolences,"condolence");
          obj_this.bind_likes(memorial_tributes,"tribute");
          obj_this.bind_likes(memories,"memory");
          const life_questions = return_data.life_questions;
          life_questions.map((question,i) => {
            obj_this.bind_likes(question.answers,"faq");
          });
          life_questions.map((question,idx) => {
            const answers = obj_this.chunk_array(question.answers);
            question.answers = answers;
          });

          obj_this.setState({
            memorial: return_data.memorial,
            memorial_memories : memories,
            showing_memories : memories.slice(0,obj_this.memories_limit),
            life_questions : life_questions,
            general_condolence_messages : return_data.general_condolence_messages,
            memorial_condolences : memorial_condolences,
            showing_condolence_messages : memorial_condolences.slice(0,obj_this.condolence_limit),
            memorial_tributes : memorial_tributes,
            showing_mementos : memorial_tributes.slice(0, obj_this.mementos_limit),
            milestones : return_data.milestones,
            gallery_images : return_data.gallery_images,
            survived_peoples : survived_peoples,
            predeceased_peoples : predeceased_peoples,
            family_notice_mapped_id : return_data.family_notice_mapped_id,
            death_notice_mapped_id : return_data.death_notice_mapped_id,
            charity : return_data.charity_details,
            candle_images : return_data.candle_images,
            flower_images : return_data.flower_images,
            current_tribute_image : return_data.candle_images[0]['tribute_type_image'],
            tribute_image_id : return_data.candle_images[0]['id'],
            preview : 1
          }, () => {
            obj_this.context.loading(0);
            obj_this.single_memorial_page_Ref.current.style.visibility = "visible";
            obj_this.single_memorial_page_Ref.current.style.opacity = 1;
            const elem = $(".biography_block .content_container .content");
            const divHeight = +elem.height();  
            // var lineHeight = +elem.css("line-height").replace('px', '');  
            // const lines = divHeight / lineHeight;
            // console.log(divHeight,lineHeight); 
            console.log(divHeight);
            if(divHeight>250){
              elem.css("height","250px");
              $(".read_more_less_button").css("display","inline-block");
            }
            $(".header_container").css("pointer-events","none");
            $(".like_button,.single_charity, .report_button, .button_theme2_container,.save_answer_button, .all_charity_button, .donate_now_button, .show_hide_memories_button, .custom_button, .memory_button, .save_message_button, .condolence_button, .single_tribute_type").css("pointer-events","none");
            $(".buttons_container").css("display","none");
            window.scrollTo({
              top : obj_this.single_memorial_page_Ref.current.offsetTop - 80,
              behavior : "smooth"
            });
          });
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }else{
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getMemorialDetails",
        data: {
          'memorial_id': this.props.match.params.memorial_id,
          'email' :this.context.session.email
        },
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        console.log(obj_this.context.session)
        if (return_data.status == "success"){
          if(return_data.memorial.status == "DELETED"){
            obj_this.props.history.push('/Memorials')
          }    
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const memories = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "memory");
          
          const survived_peoples = return_data.memorial_family_details.filter((item) =>  item.type == "survived");
          const predeceased_peoples = return_data.memorial_family_details.filter((item) =>  item.type == "predeceased");
          
          const memorial_condolences = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "note");
          const memorial_tributes = return_data.memorial_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
          obj_this.bind_likes(memorial_condolences,"condolence");
          obj_this.bind_likes(memorial_tributes,"tribute");
          obj_this.bind_likes(memories,"memory");
          const life_questions = return_data.life_questions;
          life_questions.map((question,i) => {
            obj_this.bind_likes(question.answers,"faq");
          });
          life_questions.map((question,idx) => {
            const answers = obj_this.chunk_array(question.answers);
            question.answers = answers;
          });

          obj_this.setState({
            memorial: return_data.memorial,
            memorial_memories : memories,
            showing_memories : memories.slice(0,obj_this.memories_limit),
            life_questions : life_questions,
            general_condolence_messages : return_data.general_condolence_messages,
            memorial_condolences : memorial_condolences,
            showing_condolence_messages : memorial_condolences.slice(0,obj_this.condolence_limit),
            memorial_tributes : memorial_tributes,
            showing_mementos : memorial_tributes.slice(0, obj_this.mementos_limit),
            milestones : return_data.milestones,
            gallery_images : return_data.gallery_images,
            survived_peoples : survived_peoples,
            predeceased_peoples : predeceased_peoples,
            family_notice_mapped_id : return_data.family_notice_mapped_id,
            death_notice_mapped_id : return_data.death_notice_mapped_id,
            charity : return_data.charity_details,
            candle_images : return_data.candle_images,
            flower_images : return_data.flower_images,
            current_tribute_image : return_data.candle_images[0]['tribute_type_image'],
            tribute_image_id : return_data.candle_images[0]['id']
          }, () => {
            obj_this.context.loading(0);
            obj_this.single_memorial_page_Ref.current.style.visibility = "visible";
            obj_this.single_memorial_page_Ref.current.style.opacity = 1;
            const elem = $(".biography_block .content_container .content");
            const divHeight = +elem.height();  
            // var lineHeight = +elem.css("line-height").replace('px', '');  
            // const lines = divHeight / lineHeight;
            // console.log(divHeight,lineHeight); 
            // console.log(divHeight);
            if(divHeight>250){
              elem.css("height","250px");
              $(".read_more_less_button").css("display","inline-block");
            }
            var page_share_url = process.env.REACT_APP_SHARE_URL+"/Memorials/"+obj_this.state.memorial.id+"/"+obj_this.state.memorial.first_name.replace(/\s+/g, '-')+"-"+obj_this.state.memorial.surname.replace(/\s+/g, '-')+"/"+obj_this.state.memorial.serial_no;
            var nick_name = "";
            if(obj_this.state.memorial.nick_name){
              nick_name = "("+obj_this.state.memorial.nick_name.replace(/\s+/g, '-')+")";
            }
            var family_or_house_name = "";
            if(obj_this.state.memorial.family_or_house_name){
              family_or_house_name = "-"+obj_this.state.memorial.family_or_house_name.replace(/\s+/g, '-');
            }
            var age = !isNaN(obj_this.state.memorial.age)?"("+obj_this.state.memorial.age.replace(/\s+/g, '-')+")":"";
            var middle_name = obj_this.state.memorial.middle_name?"-"+obj_this.state.memorial.middle_name.replace(/\s+/g, '-'):"";
            page_share_url = process.env.REACT_APP_SHARE_URL+"/Memorials/"+obj_this.state.memorial.id+"/"+obj_this.state.memorial.first_name.replace(/\s+/g, '-')+middle_name+"-"+obj_this.state.memorial.surname.replace(/\s+/g, '-')+family_or_house_name+nick_name+age+"/"+obj_this.state.memorial.locality.replace(/\s+/g, '-')+"/"+obj_this.state.memorial.serial_no;
            var fullname = obj_this.state.memorial.middle_name?" "+obj_this.state.memorial.middle_name:"";
            var house_name = obj_this.state.memorial.family_or_house_name?" "+obj_this.state.memorial.family_or_house_name:"";
            fullname = obj_this.state.memorial.first_name+fullname+" "+obj_this.state.memorial.surname+house_name;
            obj_this.setState({
              page_share_url : page_share_url,
              fullname : fullname
            });
            window.scrollTo({
              top : obj_this.single_memorial_page_Ref.current.offsetTop - 80,
              behavior : "smooth"
            });
            obj_this.props.history.replace("/Memorials/"+obj_this.state.memorial.id+"/"+obj_this.state.memorial.first_name.replace(/\s+/g, '-')+middle_name+"-"+obj_this.state.memorial.surname.replace(/\s+/g, '-')+family_or_house_name+nick_name+age+"/"+obj_this.state.memorial.locality.replace(/\s+/g, '-')+"/"+obj_this.state.memorial.serial_no);
          });
        }
        obj_this.context.loading(0);
      }).fail(function () {
        alert("Network error!!");
      });
    }
    

    $(".memories_stories_container").on("click",".show_more_button",function(){
      if($(this).siblings(".memory_content").height() == 150){
        $(this).siblings(".memory_content").heightAuto();
        $(this).css("box-shadow","none").find(".label").html("Show less");
        $(this).find(".down_arrow").css("display","none");
        $(this).find(".up_arrow").css("display","inline-block");
      }else{
        $(this).siblings(".memory_content").css("height","150px");
        $(this).css("box-shadow","0px -15px 15px white").find(".label").html("Show more");
        $(this).find(".down_arrow").css("display","inline-block");
        $(this).find(".up_arrow").css("display","none");
      }
    });

    $(".milestone_container").on("click",".show_more_button",function(){
      console.log("djlsf")
      if($(this).siblings(".title_description").height() == 170){
        $(this).siblings(".title_description").heightAuto();
        $(this).css("box-shadow","none").find(".label").html("Show less");
        $(this).find(".down_arrow").css("display","none");
        $(this).find(".up_arrow").css("display","inline-block");
      }else{
        $(this).siblings(".title_description").css("height","170px");
        $(this).css("box-shadow","0px -15px 15px white").find(".label").html("Show more");
        $(this).find(".down_arrow").css("display","inline-block");
        $(this).find(".up_arrow").css("display","none");
      }
    });
    setTimeout(function(){
      moveSidebar();
    },500);
    function moveSidebar(){
      var sidebar_left = $(".memorial_sidebar_container").offset().left;
      var sidebar_width = $(".memorial_sidebar_container .sidebar").outerWidth();
      $(".memorial_sidebar_container .sidebar").css({
        position:"fixed",
        top : 100,
        left : sidebar_left,
        width : "300"
      });
    }
    window.addEventListener('resize', onWindowResize);
    function onWindowResize(){
      $(".memorial_sidebar_container .sidebar").css({
        position:"relative",
        top : "",
        left : "",
        width : ""
      });
      moveSidebar();
    }

    $(".toggles_bottom_scroll_icon").on("click",function(){
      console.log($(".toggles_container").scrollTop());
      const scrolled_distance = $(".toggles_container").scrollTop();
      document.getElementsByClassName("toggles_container")[0].scroll({
        top: (scrolled_distance+170), 
        left: 0, 
        behavior: 'smooth' 
      });
    });

    $(".toggles_top_scroll_icon").on("click",function(){
      const scrolled_distance = $(".toggles_container").scrollTop();
      document.getElementsByClassName("toggles_container")[0].scroll({
        top: (scrolled_distance-170), 
        left: 0, 
        behavior: 'smooth' 
      });
    });

    $(".toggles_container").on("scroll",function(){
      if($(this).scrollTop() > 250){
        $(".toggles_bottom_scroll_icon").css("animation","blinker 0s linear infinite");
        $(".toggles_top_scroll_icon").css("animation","blinker 1s linear infinite");
      }
      if($(this).scrollTop() == 0){
        $(".toggles_top_scroll_icon").css("animation","blinker 0s linear infinite");
        $(".toggles_bottom_scroll_icon").css("animation","blinker 1s linear infinite");
      }
    });

    $(".read_more_less_button").on("click",function(){
      if($(this).html() == "Read More"){
        $(this).html("Read Less");
        $(".content_container .content").heightAuto();
      }else{
        $(this).html("Read More");
        $(".content_container .content").css("height","250px");
      }
    });


    $(".show_hide_memories_button").on("click", function(){
      $(".new_memory_story_form").css({"height":"0px", "overflow":"hidden"});
      $(".new_memorial_message_button").css("display","inline-block");
      obj_this.setState({editorState: EditorState.createEmpty(), image: "", image_src: ""});
      $(".delete_button").trigger("click");
    });

    var text = obj_this.disclaimer_text.slice(0, 197);
    $(".disclaimer_text").text(text);
    $(".read_or_hide_disclaimer").on("click",function(){
      $(this).parents("form").css("height","auto");
      var text = $(this).siblings(".disclaimer_text").text();
      if($(this).text() == "Read More"){
        text = obj_this.disclaimer_text;
        $(this).text("Read Less");
      }else{
        text = text.slice(0, 197);
        $(this).text("Read More");
      }
      $(this).siblings(".disclaimer_text").text(text);
    });
  }

  componentDidUpdate(){
    $(".footer_container").css("display","none");
  }

  componentWillUnmount(){
    $(".footer_container").css("display","block");
    $(".header_container").css("pointer-events","auto")
  }

  bind_likes(messages,message_type){
    //this function bind likes count and check whether the message is liked or not by logined user
    messages.map((message) => {
      message.liked = 0;
      message.like_count = 0;
      this.user_likes.map((item) => {
        if(item.content_type == message_type){
          if(item.content_id == message.id){
            if(this.context.session){
              message.liked = 1;
            }
          }
        }
      });
      this.user_like_count.map((item) => {
        if(item.content_type == message_type){
          if(item.content_id == message.id){
            message.like_count = item.like_count;
          }
        }
      });
    });
  }

  chunk_array(arr,chunkSize=2) {
    const result=[];
    for(let i=0;i<arr.length;i+=chunkSize) {
      result.push(arr.slice(i,i+chunkSize));
    }
    return result;
  }

  toggleChange(e,container_ref){
    this.setState({
      tribute_text : ""
    });
    document.querySelectorAll('.toggle').forEach(element => {
      element.classList.remove("toggle_selected"); 
    }); 
    e.target.classList.add("toggle_selected"); 
    e.target.scrollIntoView(); 
    window.scroll({
      top: (container_ref.current.offsetTop-20), 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  milestone_preview(idx){
    this.setState({
      milestone_preview : this.state.milestones[idx]??{},
      milestone_index : idx
    })
  }

  milestone_preview_button_click(idx){
    $(".logo_loading_container").css("display","block");
    $(".zoomed_image_container").css("opacity",0);
    setTimeout(() => {
      $(".logo_loading_container").css("display","none");
      $(".zoomed_image_container").css("opacity",1);
    },2000);
    this.setState({
      milestone_preview : this.state.milestones[idx]??{},
      milestone_index : idx
    })
  }

  tribute_type_change(e) {
    // console.log(e.target.value);
    var current_tribute_image;
    var tribute_image_id;
    if(e.target.value == "flower"){
      current_tribute_image = this.state.flower_images[0].tribute_type_image;
      tribute_image_id = this.state.flower_images[0].id;
    }else if(e.target.value == "candle"){
      current_tribute_image = this.state.candle_images[0].tribute_type_image;
      tribute_image_id = this.state.candle_images[0].id;
    }
    this.setState({
      tribute_type: e.target.value,
      current_tribute_image: current_tribute_image,
      tribute_image_id : tribute_image_id
    });
  }

  tribute_image_change(item){
    this.setState({
      current_tribute_image : item.tribute_type_image,
      tribute_image_id : item.id
    })
  }

  show_form(e,type){
    console.log(type);
    if(this.context.session){
      $("."+type+"_form").heightAuto();
      $("."+type+"_form").css("overflow","unset");
      $("."+type+"_button").css("display","none");
    }else{
      if(type == "condolence"){
        this.context.confirm("Sign in to leave a Tribute",() => {
          this.props.history.push({ pathname: "/Login", url : "/Memorials/"+this.state.memorial.id})
        });
        return;
      }else if(type == "memory"){
        this.context.confirm("Sign in to leave Memories & Stories",() => {
          this.props.history.push({ pathname: "/Login", url : "/Memorials/"+this.state.memorial.id})
        });
        return;
      }
      
    }
  }

  hide_form(type){
    this.setState({
      tribute_text : "",
    });
    $("."+type+"_form").css({ height:"0px", overflow:"hidden"});
    $("."+type+"_button").css("display","inline-block");
    if(type == "condolence"){
      $(".general_messages_container").css({height :"0px",overflow :"hidden"});
    }
  }

  form_change(e) {
    if(e.target.name == "tribute_text"){
      if(e.target.value.length > 250){
        $.alert("Maximum characters exceeded");
        return;
      }else{
        this.setState({
          [e.target.name] : e.target.value
        });
      }
    }else{
      this.setState({
        [e.target.name] : e.target.value
      });
    }
  }

  message_change(e){
    // console.log(e.target.textContent);
    this.setState({
      tribute_text : e.target.textContent
    },() => {
      window.scrollTo({
        top : this.condolence_ref.current.offsetTop,
        behavior : "smooth"
      })
    });
  }

  on_like_button_click(id,type,e){
    if(!this.context.session){
      this.context.confirm("Sign in to continue",() => {
        this.props.history.push({ pathname: "/Login", url : "/Memorials/"+this.props.match.params.memorial_id})
      });
      return;
    }
    console.log($(e.currentTarget).find(".like_button_label").html())
    if($(e.currentTarget).find(".like_button_label").html() == "Like"){
      //like
      var obj_this = this;
      this.context.loading(1);
      const liked_content = {
        notice_type: "memorial" ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/likeContent",
        data: this.context.bind_session({liked_content : JSON.stringify(liked_content)}),
        type: "POST"
      }).done(function (return_data){
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if(type == "condolence"){
            const memorial_condolences = obj_this.state.memorial_condolences;
            memorial_condolences.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
              console.log(item);
            });
            obj_this.setState({
              memorial_condolences :memorial_condolences
            });
          }else if(type == "tribute"){
            const memorial_tributes = obj_this.state.memorial_tributes;
            memorial_tributes.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              memorial_tributes :memorial_tributes
            });
          }else if(type == "prayer"){
            const memorial_prayers = obj_this.state.memorial_prayers;
            memorial_prayers.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              memorial_prayers :memorial_prayers
            });
          }else if(type == "memory"){
            const memories = obj_this.state.memorial_memories;
            memories.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              memorial_memories : memories
            });
          }else if(type == "faq"){
            const life_questions = obj_this.state.life_questions;
            life_questions.map((question) => {
              question.answers.map((container) => {
                container.map((item) => {
                  if(item.id == id){
                    item.liked = 1;
                    ++item.like_count;
                  }
                });
              });
            });
            obj_this.setState({
              life_questions : life_questions
            });
          }
        }else if(return_data.status == "session failed"){
          obj_this.props.history.push({ pathname: "/Login", url : "/Memorials/"+obj_this.props.match.params.memorial_id})
        }else{
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }else{
      //dislike
      var obj_this = this;
      this.context.loading(1);
      const disliked_content = {
        notice_type: "memorial" ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/disLikeContent",
        data: this.context.bind_session({disliked_content : JSON.stringify(disliked_content)}),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if(type == "condolence"){
            const memorial_condolences = obj_this.state.memorial_condolences;
            memorial_condolences.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              memorial_condolences :memorial_condolences
            });
          }else if(type == "tribute"){
            const memorial_tributes = obj_this.state.memorial_tributes;
            memorial_tributes.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              memorial_tributes :memorial_tributes
            });
          }else if(type == "prayer"){
            const memorial_prayers = obj_this.state.memorial_prayers;
            memorial_prayers.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              memorial_prayers :memorial_prayers
            });
          }else if(type == "memory"){
            const memories = obj_this.state.memorial_memories;
            memories.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              memorial_memories : memories
            });
          }else if(type == "faq"){
            const life_questions = obj_this.state.life_questions;
            life_questions.map((question) => {
              question.answers.map((container) => {
                container.map((item) => {
                  if(item.id == id){
                    item.liked = 0;
                    --item.like_count
                  }
                });
              });
            });
            obj_this.setState({
              life_questions : life_questions
            });
          }
        }else if(return_data.status == "session failed"){
          obj_this.props.history.push({ pathname: "/Login", url : "/Memorials/"+obj_this.props.match.params.memorial_id})
        }else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }
  }

  show_more_message(type){
    if(type == "condolence"){
      this.condolence_limit+=3;
      this.setState({
        showing_condolence_messages : this.state.memorial_condolences.slice(0,this.condolence_limit)
      },() => {
        if(window.location.pathname == "/Memorial/Preview/"+this.props.match.params.memorial_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }else if(type == "mementos"){
      this.mementos_limit+=3;
      this.setState({
        showing_mementos : this.state.memorial_tributes.slice(0,this.mementos_limit)
      },() => {
        if(window.location.pathname == "/Memorial/Preview/"+this.props.match.params.memorial_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }else if(type == "prayer"){
      this.prayer_limit+=3;
      this.setState({
        showing_prayers : this.state.memorial_prayers.slice(0,this.prayer_limit)
      },() => {
        if(window.location.pathname == "/Memorial/Preview/"+this.props.match.params.memorial_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }else if(type == "memories"){
      this.memories_limit+=3;
      this.setState({
        showing_memories : this.state.memorial_memories.slice(0,this.memories_limit)
      },() => {
        if(window.location.pathname == "/Memorial/Preview/"+this.props.match.params.memorial_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }
  }

  report_message(id,type){
    if(!this.context.session){
      this.context.confirm("Sign in to continue",() => {
        this.props.history.push({ pathname: "/Login", url : "/Memorials/"+this.props.match.params.memorial_id})
      });
      return;
    }
    this.context.confirm("Report this content",() => {
      var obj_this = this;
      this.context.loading(1);
      const report_data = {
        memorial_id: this.state.memorial.id ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/reportMemorialContent",
        data: this.context.bind_session({report_data : JSON.stringify(report_data)}),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          $.alert("Your request will be reviewed.");
        } else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    });
    return;
  }

  life_question_answer_change(e,idx){
    if(e.target.value.length > 200){
      $.alert("Maximum characters exceeded");
      return;
    }
    var life_questions = this.state.life_questions;
    life_questions[idx].user_answer = e.target.value;
    this.setState({
      life_questions : life_questions
    });
  }

  life_question_answer_form_submit(e,idx,question_id){
    e.preventDefault();
    // console.log(this.state);
    this.context.loading(1);
    var obj_this = this;
    const life_question_answer = {
      notice_id: this.props.match.params.memorial_id,
      answer: this.state.life_questions[idx].user_answer,
      question_id : question_id,
      notice_type : "memorial"
    }
    console.log(life_question_answer);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveLifeQuestionAnswer",
      data: this.context.bind_session({life_question_answer : JSON.stringify(life_question_answer)}),
      type: "POST",
    }).done(function (return_data) {
      console.log(return_data)
      // return;
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const life_questions = return_data.life_questions;
        life_questions.map((question,idx) => {
          const answers = obj_this.chunk_array(question.answers);
          question.answers = answers;
        });
        obj_this.setState({
          life_questions : life_questions
        });
        if(return_data.answer_status == "PUBLISHED"){
          $.alert("Your answer is published");
        }else{
          $.alert("Your message is waiting for administrator approval. Please check back later.");
        }
      } else {
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  image_change(e) {
    const file = e.target.files[0];
    // if (file.size / 1024 > 4096)
    //   alert("File size cannot exceed more than 4MB");
    // else
      this.setState({
        image: file,
      },() => {
        $(".memory_form").heightAuto();
      });
  }

  delete_image(){
    this.setState({
      profile_pic_delete_flag : 1,
      image_src : "",
      image : ""
    });
  }

  onEditorStateChange(value){
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({ 
        editorState :value,
        memories_content : draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  tribute_form_submit(e,form_type) {
    e.preventDefault();
    if(!this.context.session){
      if(form_type == "tribute"){
        this.context.confirm("Sign in to leave Remembrances",() => {
          this.props.history.push({ pathname: "/Login", url : "/Memorials/"+this.props.match.params.memorial_id});
        });
        return;
      }

    }
    if(form_type == "tribute"){
      this.context.confirm("Do you want to publish?",() => {
        this.form_submit(form_type);
      });
      return;
    }else
      this.form_submit(form_type);
  }

  form_submit(form_type){
    this.memories_limit = 3;
    var tribute_text = "";
    if(form_type == "note"){
      tribute_text = this.state.tribute_text;
    }else if(form_type == "memory"){
      tribute_text = this.state.memories_content;
    }
    console.log(this.state);
    // if(this.state.tribute_type == "memory"){
    //   if(this.state.tribute_text == null || this.state.tribute_text.length < 9){
    //     $.alert("Fill notice content");
    //     return;
    //   }
    // }
    // return;
    this.context.loading(1);
    var obj_this = this;
    const tribute_data = {
      memorial_id: this.props.match.params.memorial_id,
      tribute_text: tribute_text,
      tribute_image_id: this.state.tribute_image_id,
      // written_by: this.state.written_by,
      // email: this.state.email,
      tribute_type: this.state.tribute_type,
      form_type : form_type
    }
    const form_data = new FormData();
    form_data.append("tribute_data", JSON.stringify(tribute_data));
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image", this.state.image);
    form_data.append("email", this.context.session.email);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveMemorialTribute",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data)
      // return;
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        document.getElementById("custom_image_input").value = "";
        obj_this.mementos_limit = 3;
        obj_this.condolence_limit = 3;
        obj_this.memories_limit = 3;
        obj_this.user_likes = return_data.user_likes;
        obj_this.user_like_count = return_data.user_like_count;
        const condolence_messages = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "note");
        const memorial_tributes = return_data.memorial_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
        const memories = return_data.memorial_tributes.filter((item) => item.tribute_type == "memory");
        obj_this.bind_likes(condolence_messages,"condolence");
        obj_this.bind_likes(memorial_tributes,"tribute");
        obj_this.bind_likes(memories,"memory");
        if(form_type == "memory"){
          $(".new_memory_story_form").css({"height": "0px", "overflow" : "hidden"});
          $(".new_memorial_message_button").css("display","inline-block");
          // $(".show_hide_memories_button").trigger("click");
        }else if(form_type == "note"){
          $(".condolence_form").css({"height": "0px", "overflow" : "hidden"});
          $(".new_memorial_message_button").css("display","inline-block");
        }
        obj_this.setState({
          showing_mementos: memorial_tributes.slice(0,obj_this.mementos_limit),
          memorial_tributes: memorial_tributes,
          showing_condolence_messages : condolence_messages.slice(0,obj_this.condolence_limit),
          memorial_condolences : condolence_messages,
          message_tribute: "",
          memories_content : "",
          tribute_type: "candle",
          memorial_memories : memories,
          showing_memories : memories.slice(0,obj_this.memories_limit),
          tribute_text: "",
          editorState: EditorState.createEmpty(),
          image : "",
          memory_image_src : "",
        },() => {
          $(".image_modify_buttons .delete_button").trigger("click");
        });

        $(".preview_image").css("background-image","");
        if(return_data.tribute_status == "PUBLISHED"){
          $.alert("Your message is published");
        }else{
          $.alert("Your message is waiting for administrator approval, Please check back later.");
        }
      } else {
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  faq_slide_left(qid,index){
    index=index-1;
    if($("#"+qid+" .answers_slider_container").find("#"+index).length){
      $("#"+qid+" .answers_slider_container").find("#"+index)[0].scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }

  faq_slide_right(qid,index){
    index=index+1;
    if($("#"+qid+" .answers_slider_container").find("#"+index).length){
      $("#"+qid+" .answers_slider_container").find("#"+index)[0].scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }

  zoomImage(item,idx){
    console.log(item);
    this.setState({
      zoomed_item : item,
      image_index : idx
    });
  }

  minimizeImage(item){
    this.setState({
      zoomed_item : {}
    });
  }

  previousImage(){
    const item = this.state.gallery_images[--this.state.image_index];
    if(item)
      this.setState({
        zoomed_item : item,
      });
  }

  nextImage(e){
    const item = this.state.gallery_images[++this.state.image_index];
    if(item)
      this.setState({
        zoomed_item : item,
      });
  }

  contact_admin_form_submit(e){
    e.preventDefault();
    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();
    var obj_this = this;
    var contact_details = {
      name : this.state.name,
      email : this.state.email,
      message : this.state.message,
      notice_id : this.state.memorial.id,
      notice_type : "memorial",
      page_link : window.location.href
    };
    contact_details.captcha_token = token;
    if(contact_details.captcha_token.length == 0){
      $.alert("Please verify you are a human!"); 
      return;
    }
    this.context.loading(1);
    // console.log(contact_details);
    // return;
    contact_details = JSON.stringify(contact_details);
    $.ajax({
      url : process.env.REACT_APP_API + "/api/savePageAdminContact",
      data : {
        contact_details : contact_details
      },
      type : "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        $.alert("Your message will be showed to this page admin.");
        obj_this.setState({
          name : "",
          email : "",
          message : ""
        })
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  show_report_page_form(){
    if(this.context.session){
      $(".report_page_form_ghost").css("display","block");
    }else{
      this.context.confirm("Sign in to report this page",() => {
        this.props.history.push({ pathname: "/Login", url : "/Memorials/"+this.props.match.params.memorial_id});
      });
      return;
    }
  }

  report_page_form_submit(e){
    e.preventDefault();
    console.log(this.state.reason);
    // return;
    var obj_this = this;
    this.context.loading(1);
    $(".report_page_form_ghost").css("display","none");
    const reported_data = {
      notice_id : this.props.match.params.memorial_id,
      notice_type : "memorial",
      reason : this.state.reason
    };
    $.ajax({
      url : process.env.REACT_APP_API+"/api/reportNotice",
      data : this.context.bind_session({
        reported_data : JSON.stringify(reported_data)
      }),
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          reason : ""
        });
        $.alert("Your message is waiting for administrator approval. Please check back later.");
      }else{
        alert("Something Went Wrong, Please Try Again");
      }
      obj_this.context.loading(0);
    }).fail(function(){
        alert("Network error")
    });
  }

  rejectMemorial() {
    if (!window.confirm("Reject this notice")) {
      return;
    }
    this.reject_reason_container_ref.current.style.display = "block";

  }

  reject_form_submit(e) {
    e.preventDefault();
    var obj_this = this
    this.context.loading(1);;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/rejectMemorial",
      data: this.context.bind_session({ memorial_id: this.state.memorial.id, reject_reason: this.state.reject_reason }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        alert("Memorial rejected.");
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  approveMemorial() {
    if (!window.confirm("Approve this notice")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveMemorial",
      data: this.context.bind_session({ memorial_id: this.state.memorial.id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        alert("Memorial approved.");
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render() {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ],
      BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
      ],
      BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
      ]
    };
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
      <>
      <div className="single_memorial_page_container page_container" ref={this.single_memorial_page_Ref}>
        <div className="sidebar_details_grid">
          <div className="left_gap"></div>
          <div className="memorial_sidebar_container">
            <div className="sidebar">
              {this.state.memorial.image_src?
              <div className="deceased_image_icon" onClick={(e) => this.toggleChange(e,this.memorial_ref)} style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/memorial_images/"+this.state.memorial.image_src+"')"}}></div>
              :
              <div className="deceased_image_icon" onClick={(e) => this.toggleChange(e,this.memorial_ref)} style={{backgroundImage: "url('"+default_image+"')"}}></div> 
              }
              <div className="deceased_name" onClick={(e) => this.toggleChange(e,this.memorial_ref)}>{this.state.memorial.first_name} {this.state.memorial.surname}</div>
              {isNaN(this.state.memorial.age)?
              <div className="duration">({this.state.memorial.age})</div>
              :
              <div className="duration">({new Date(this.state.memorial.date_of_birth).getFullYear()} - {new Date(this.state.memorial.date_of_death).getFullYear()})</div>
              }
              <div className="deceased_address">
                <div>{this.state.memorial.family_or_house_name}</div>
                <div>{this.state.memorial.locality}</div>
                <div>{this.state.memorial.city}</div>
                <div>{this.state.memorial.state}</div>
                <div>{this.state.memorial.country}</div>
              </div>
              <div className="toggles_container">
                <div className="toggle toggle_selected" 
                  onClick={(e) => this.toggleChange(e,this.biography_ref)}
                  >Biography</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.family_details_ref)}
                  >Family Details</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.memory_story_ref)}>Memories & Stories</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.milestone_ref)}>Milestones</div>
                 <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.condolence_ref)}
                  id="condolence_toggle"
                  >Tributes</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.mementos_ref)}
                  id="memento_toggle"
                  >Remembrances</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.gallery_ref)}>Gallery</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.life_qs_ans_ref)}
                >
                  <span>Let’s Reminisce</span>
                </div>
                <Link className="toggle" 
                  style={{display:this.state.family_notice_mapped_id?"inline-block":"none",pointerEvents:this.state.preview?"none":"auto"}}
                  to={"/InMemoryNotices/"+this.state.family_notice_mapped_id}
                  target="_blank"
                  rel="noopener noreferrer" 
                  >
                  In Memory Notice</Link>
                <Link className="toggle" 
                  style={{display:this.state.death_notice_mapped_id?"inline-block":"none",pointerEvents:this.state.preview?"none":"auto"}}
                  to={"/DeathNotices/"+this.state.death_notice_mapped_id}
                  target="_blank"
                  rel="noopener noreferrer" 
                  >
                  Death Notice</Link>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.donate_ref)}>
                  <span>Donate to Charity</span>
                </div>
                <a className="toggle" 
                  target={"_blank"} href="https://shop.amrityum.com" style={{pointerEvents:this.state.preview?"none":"auto"}}>Shop</a>
                {/* <div className="toggle" 
                  onClick={() => this.props.history.push("/Feedback")}>
                  <span>Feedback</span>
                </div> */}
                <Link className="toggle" style={{pointerEvents:this.state.preview?"none":"auto"}} to="/Feedback" target={"_blank"}>Feedback</Link>
                <div className="toggle"
                  id="contact_admin_toggle"
                  onClick={(e) => this.toggleChange(e,this.contact_admin_ref)}>
                  <span>Contact Page Admin</span>
                </div>
                  
              </div>
              <div className="toggle_scroll_buttons_container">
                <BsChevronDoubleDown className="toggles_bottom_scroll_icon"></BsChevronDoubleDown>
                <BsChevronDoubleUp className="toggles_top_scroll_icon"></BsChevronDoubleUp>
              </div>
              <div className="share_icons_container">
                <a 
                  href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(window.location.href)}
                  target={"_blank"}
                >
                  <i className="fab fa-facebook-f share_icon" style={{padding:"9px 11px",color:"#0f92f3"}}></i>
                </a>
                <a
                  href={"whatsapp://send?text=" + this.state.memorial.first_name+" "+this.state.memorial.surname + " URL: " + encodeURIComponent(window.location.href)} data-action="share/whatsapp/share" 
                  target={"_blank"}
                >
                  <i className="fab fa-whatsapp share_icon" style={{padding:"9px 11px",color:"#40bf50"}}></i>
                </a>
                {/* <i className="fab fa-instagram share_icon"></i> */}
                <a
                  href={"mailto:?subject=Memorial&body=" + this.state.memorial.first_name+" "+this.state.memorial.surname + " URL: " + window.location.href}
                  target={"_blank"}
                >
                  <i className="far fa-envelope-open share_icon" style={{padding:"9px",color:"#d10a0a"}}></i>
                </a>
                <a
                  href={"https://twitter.com/intent/tweet?url=" + encodeURIComponent(window.location.href)}
                  target={"_blank"}
                >
                  <i className="fab fa-twitter share_icon" style={{padding:"9px",color:"#4ea6e9"}}></i>
                </a>
              </div>
            </div>
          </div>
          <div className="memorial_details_container">
            <div className="preview_title" style={{display:this.state.preview?"block":"none"}}>Preview</div>
            <div className="single_info_container" id="memorial" 
              ref={this.memorial_ref}
              style={{paddingTop:"0px"}}
            >
              <div className="single_info_content memorial_image_details_grid" style={{backgroundColor:"rgb(206 218 220)"}}>
                <div className="details_container">
                  <div className="date_of_death"></div>
                  <div className="deceased_name">{this.state.fullname}</div>
                  {isNaN(this.state.memorial.age)?
                  <div className="deceased_duration">({this.state.memorial.age})</div>
                  :
                  <div className="deceased_duration">({new Date(this.state.memorial.date_of_birth).getFullYear()} - {new Date(this.state.memorial.date_of_death).getFullYear()})</div>
                  }
                  <p className="notice_content">This Amrityum Memorial page is in loving memory of {this.state.memorial.first_name}{this.state.memorial.nick_name?" ("+this.state.memorial.nick_name+") ":""} {this.state.memorial.middle_name} {this.state.memorial.surname}
                   {this.state.memorial.age?" ("+this.state.memorial.age+")":""}{this.state.memorial.nee?" (Née : "+this.state.memorial.nee+")":""}{this.state.memorial.family_or_house_name?", "+this.state.memorial.family_or_house_name+", ":""} {this.state.memorial.locality}, {this.state.memorial.city?this.state.memorial.city+", ":""}{this.state.memorial.state?this.state.memorial.state+", ":""}{this.state.memorial.country} to celebrate a well-lived life. We thank you for your contributions to this page which will offer us great comfort.&nbsp;
                   {this.state.memorial.first_name} was loved by many and will be dearly missed by all friends and family.</p>
                  <p className="notice_content">We do not need a special day to bring you to mind.
                    The days we do not think of you 
                    are very hard to find.</p>
                  <p className="notice_content">You left us all beautiful memories.
                    Your love is still our guide
                    and though we cannot see you,
                    you are always by our side.</p>
                  <p className="notice_content">Just as you were, you will always be, someone special in our memory.</p>
                  <div style={{textAlign:"left",color:"#690090",fontSize:"11px"}}>AMRITYUM ID : {this.state.memorial.serial_no}</div>
                </div>
                {this.state.memorial.image_src?
                <div className="image_container" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/memorial_images/"+this.state.memorial.image_src+"')",boxShadow: "0px 1px 10px #008cb7e6"}}></div>
                :
                <div className="image_container" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
                }
              </div>
            </div>

            <div className="single_info_container" id="biography" ref={this.biography_ref}>
                <div className="single_info_content biography_block">
                  <div className="single_info_head">
                    <h1>BIOGRAPHY</h1>
                  </div>
                  <div className="single_info_body content_container">
                    <div className="content" dangerouslySetInnerHTML={{ __html: this.state.memorial.biography }}/>
                    <div className="read_more_less_button">Read More</div>
                  </div>
              </div>
            </div>

            <div className="single_info_container" 
              ref={this.family_details_ref}
              id="family_details"
              // style={{display:this.state.predeceased_peoples.length || this.state.survived_peoples.length?"block":"none"}}
              >
              <div className="single_info_content services_details_block" 
              style={{backgroundColor:"rgb(206 218 220)"}}>
                <div className="single_info_head">
                  <h1>FAMILY DETAILS</h1>
                </div>
                <div className="single_info_body" >
                  <div className="family_details_container">
                    <div className="family_details_grid">
                      <div className="survived_container" >
                        <div className="survived_by_title">Survived by</div>
                        <div className="peoples">
                          {this.state.survived_peoples && this.state.survived_peoples.map((item,idx) => (
                            <div className="people_container" key={idx}>
                              {item.image_src?
                              <div className="people_image"  style={{ backgroundImage: item.image_src?"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/survived_images/"+item.image_src+"')":"url('/images/user_icon.png')"}}></div>
                              :<div className="people_image" style={{backgroundImage: "url('"+survived+"')"}}></div> 
                              }
                              <div className="people_name">{item.name}</div>
                              <div className="people_relation">{item.relation}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="predeceased_container">
                        <div className="predeceased_by_title">Predeceased by</div>
                        <div className="peoples">
                          {this.state.predeceased_peoples && this.state.predeceased_peoples.map((item,idx) => (
                            <div className="people_container" key={idx}>
                            <div className="people_image"  style={{ backgroundImage: item.image_src?"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/predeceased_images/"+item.image_src+"')":"url('/images/user_icon.png')"}}></div>
                            <div className="people_name">{item.name}</div>
                            <div className="people_relation">{item.relation}</div>
                            </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>      
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" ref={this.memory_story_ref} id="memory_stories">
              <div className="single_info_content" style={{backgroundColor:"#1a2c3d14"}}>
                <div className="single_info_head">
                  <h1>MEMORIES & STORIES</h1>
                </div>
                <div className="single_info_body">               
                <div style={{textAlign:"center"}}>
                <button className="new_memorial_message_button memory_button" 

                       onClick={(e) => {this.show_form(e,"memory")}}
                    >
                      <div className="button_ghost"></div>
                      <i className="fas fa-pencil-alt"></i>&nbsp;
                      <span className="button_label">BRING MEMORIES TO LIFE</span>
                    </button>
                  </div>
               {/* <div className="show_hide_memories_button">BRING MEMORIES TO LIFE</div> */}
                  <form className="new_memory_story_form memory_form" onSubmit={(e) => this.tribute_form_submit(e,"memory")} method="post" encType="multipart/form-data">
                    <div className="form_body">
                      <div className="memory_story_grid">
                        {/* <textarea
                          name="tribute_text"
                          value={this.state.tribute_text}
                          placeholder="Write your memory (Max:3000 characters)"
                          rows={5}
                          onChange={this.form_change}
                          required
                        /> */}
                        
                        <div className="memory_image_container">
                          <ImageUpload 
                            className="image_upload_component"
                            attrs={{
                              name: "image",
                              accept: "image/png, image/gif, image/jpeg"
                            }}
                            onChange={this.image_change}
                            deleteImage={this.delete_image}
                            src = {this.state.memory_image_src?process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/memory_images/"+this.state.memory_image_src:""}
                          />
                        </div>
                        <Editor
                          editorState={this.state.editorState}
                          toolbarClassName="editor_toolbar"
                          wrapperClassName="editor_component"
                          editorClassName="editor_input_area"
                          onEditorStateChange={this.onEditorStateChange}
                          placeholder="Write your memory (Max:3000 characters)"
                          handlePastedText={() => false}
                          stripPastedStyles={true}
                          toolbar={{
                            options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
                            inline: {
                              options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                            },list: {
                              options: ['unordered', 'ordered'],
                            },
                            fontSize: {
                              options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
                            },
                          }}
                        />
                      </div>
                      <div className="disclaimer_container" style={{color:"white"}}>
                        <span style={{fontweight:"500",color:"darkred"}}>Disclaimer:</span> 
                        <div className="disclaimer_text" >
                          Please write your correct name and other information requested.
                          Kindly do not post any personal, abusive, defamatory, infringing, obscene,
                            indecent, discriminatory, or unlawful or similar comments. 
                            Amrityum.com will not be responsible for any defamatory message posted 
                            under this notice.
                            
                          Please note that sending false messages to insult, defame, intimidate, mislead,
                            or deceive people or to intentionally cause public disorder is punishable
                            under law. It is obligatory on amrityum.com to provide all details we
                              hold of senders of such comments, to the authority concerned upon request.
                        
                          Hence, sending offensive comments using amrityum will be purely at your own risk,
                            and in no way will amrityum.com be held responsible.
                        </div>
                        <span className="read_or_hide_disclaimer">Read More</span>
                      </div>
                    <div className="form_footer">
                       <button className="show_hide_memories_button" type="button">
                          <div className="button_ghost"></div>
                          <span className="button_label">Hide</span>
                       </button>
                       <button className="save_memory_story_button" style={{display:this.state.editorState.getCurrentContent().hasText()?"inline-block":"none"}}> <div className="button_ghost"></div>Publish</button>
                    </div>
                  </div> 
                  </form>
                   
                 
                  {/* <div style={{textAlign:"center"}}>
                    <button className="new_memorial_message_button memory_button" 
                      onClick={(e) => {this.show_form(e,"memory")}}
                    >
                      <div className="button_ghost"></div>
                      <i className="fas fa-pencil-alt"></i>&nbsp;
                      <span className="button_label">BRING MEMORIES TO LIFE</span>
                    </button>
                  </div> */}
                  <div className="memories_stories_container">
                    {this.state.showing_memories && this.state.showing_memories.map((item, idx) => (
                      <div className="single_memory"  key={idx} id={item.id}>
                        {item.image_src?
                        <div className="memory_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/tribute_images/"+item.image_src+"')"}} />
                        :
                        <div className="memory_image" style={{backgroundImage:"url('"+memories+"')"}} />
                        }
                        <div className="memory_details">
                          <div className="posted_by">{item.written_by}</div>
                          <div className="posted_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(),{
                            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                            })}</div>
                          {/* <div className="memory_content">{item.tribute_text} </div> */}
                          <div className="memory_content" dangerouslySetInnerHTML={{ __html: item.tribute_text }}/>
                          {/* <div className="show_more_button">
                            <i className="fa fa-chevron-up up_arrow"></i>
                            <div className="label">Show more</div>
                            <i className="fa fa-chevron-down down_arrow"></i>
                          </div> */}
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(item.id,"memory",e)}
                                style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                                {/* <AiOutlineHeart color="white" className="like_icon" style={{display:item.liked?"none":"block"}}/> */}
                                <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                              </div>
                              <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}>  {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                            </div>
                            <div className="report_button" onClick={() => this.report_message(item.id,item.tribute_type)}> 
                              <GoReport />
                              <div className="report_label">REPORT </div> 
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    ))} 
                  </div>
                  <div style={{textAlign:"center"}}>
                    <div className="show_more_messages_button"
                      onClick={() => this.show_more_message("memories")}
                      style={{display:this.state.memorial_memories.length == this.state.showing_memories.length?"none":"inline-block"}}
                    >Show more</div>
                    <div className="show_less_messages_button"
                      onClick={() => {
                        this.setState({
                          showing_memories : this.state.memorial_memories.slice(0,3),
                        },() => {
                          window.scroll({
                            top: (this.memory_story_ref.current.offsetTop-20), 
                            left: 0, 
                            behavior: 'smooth' 
                          });
                        });
                      }}
                      style={{display:this.state.memorial_memories.length > 3 && this.state.memorial_memories.length == this.state.showing_memories.length && this.state.memorial_memories.length?"inline-block":"none"}}
                    >Show less</div>
                  </div>
                </div>
              </div>
            </div> 

            <div className="single_info_container single_list" ref={this.milestone_ref} id="milestones">
              <div className="single_info_content">
                <div className="single_info_head">
                  <h1>MILESTONES</h1>
                </div>
                <div className="tool_tip" style={{display:this.state.milestones.length?"none":"block"}}>
                Every life has a story, and it's important to record and preserve those stories so that people can look back on them later and remember the impact they had on the world.
                  </div>
                <div className="single_info_body">
                  <div className="milestone_container">
                    <div
                      className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-purple-50"
                    >
                      {this.state.milestones && this.state.milestones.map((item,idx) => (
                        idx % 2?
                      <div className="flex flex-row-reverse md:contents" key={idx}>
                        <div
                          className="single_milestone col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md"
                        >
                          <h2 className="font-bold text-lg mb-1 milestone_date">{new Date(item.date).toLocaleString(new Date().getTimezoneOffset(),{
                               day: 'numeric', month: 'long', year: 'numeric'
                              })}</h2>
                          <div className="image_descriptions_grid">
                            <div className="description_container">
                              <div className="title_description">
                                <h2 className="font-semibold text-lg mb-1 milestone_title">{item.title}</h2>
                                {/* <div className="milestone_description">{item.content}</div> */}
                              </div>
                             
                            </div>
                            {/* <div className="milestone_image"
                              style={{display:item.image_src?"block":"none",backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/milestone_images/"+item.image_src+"')"}}
                            ></div> */}
                            <div style={{display:item.image_src?"inline-grid":"none"}} className="view_image_button" onClick={() => this.milestone_preview_button_click(idx)}>
                              <div className="label">View</div>
                              <i className="fas fa-image"></i>
                            </div>
                          </div>
                        </div>

                        <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                          <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-1 bg-purple-800 pointer-events-none"></div>
                          </div>
                          <div
                            className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-purple-500 shadow"
                          ></div>
                        </div>
                      </div>
                      :
                      <div className="flex md:contents">
                      {/* <!-- right --> */}
                        <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                          <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-1 bg-purple-800 pointer-events-none"></div>
                          </div>
                          <div
                            className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-purple-500 shadow"
                          ></div>
                        </div>
                        <div
                          className="single_milestone col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md"
                        >
                          <h2 className="font-semibold text-lg mb-1 milestone_date">{new Date(item.date).toLocaleString(new Date().getTimezoneOffset(),{
                              day: 'numeric', month: 'long', year: 'numeric'
                              })}</h2>
                          <div className="image_descriptions_grid">
                            <div className="description_container">
                              <div className="title_description">
                                <h2 className="font-semibold text-lg mb-1 milestone_title">{item.title}</h2>
                                {/* <div className="milestone_description">{item.content}</div> */}
                              </div>
                              {/* <div className="show_more_button">
                                <i className="fa fa-chevron-up up_arrow"></i>
                                <div className="label">Show more</div>
                                <i className="fa fa-chevron-down down_arrow"></i>
                              </div> */}
                            </div>
                            {/* <div className="milestone_image"
                              style={{display:item.image_src?"block":"none", backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/milestone_images/"+item.image_src+"')"}}
                            ></div> */}
                            <div className="view_image_button" style={{display:item.image_src?"inline-grid":"none"}} onClick={() => this.milestone_preview_button_click(idx)}>
                              <div className="label">View</div>
                              <i className="fas fa-image"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      ))}

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" 
              ref={this.condolence_ref} 
              id="condolence"
            >
              <div className="single_info_content" 
              style={{backgroundColor:"rgb(217, 216, 216)"}}>
                <div className="single_info_head">
                  <h1>TRIBUTES</h1>
                </div>
                <div className="single_info_body" style={{textAlign:"center"}}>
                  <form className="new_tribute_form condolence_form" onSubmit={(e) => this.tribute_form_submit(e,"note")} method="post">
                    <div className="form_body">
                      <div className="tribute_message_grid">
                        <div className="selected_tribute_type_image" style={{ backgroundImage:  "url('"+ note + "')"}}></div>
                        <div className="tribute_message">
                          <textarea
                            name="tribute_text"
                            value={this.state.tribute_text}
                            placeholder="Write your message (Max 250 characters)"
                            rows={5}
                            onChange={(e) => this.form_change(e,"condolence")}
                            required
                            ref={this.tribute_textarea_ref}
                          />
                          {/* <RichTextEditor
                            value={this.state.value}
                            onChange={this.onEditorChange}
                            toolbarConfig={toolbarConfig}
                          /> */}
                        </div>
                      </div>
                      <div className="form_footer">
                        <div></div>
                        <div className="form_footer_spacer"></div>
                        <div>
                          <div className="hide_tribute_form_button" onClick={(e) => {this.hide_form("condolence")}}>HIDE</div>
                          <ButtonTheme2 label="Publish" className="save_tribute_button" />
                        </div>
                      </div>
                      <div className="disclaimer_container" style={{color:"white"}}>
                        <span style={{fontweight:"500",color:"darkred"}}>Disclaimer:</span> 
                        <div className="disclaimer_text" >
                          Please write your correct name and other information requested.
                          Kindly do not post any personal, abusive, defamatory, infringing, obscene,
                            indecent, discriminatory, or unlawful or similar comments. 
                            Amrityum.com will not be responsible for any defamatory message posted 
                            under this notice.
                            
                          Please note that sending false messages to insult, defame, intimidate, mislead,
                            or deceive people or to intentionally cause public disorder is punishable
                            under law. It is obligatory on amrityum.com to provide all details we
                              hold of senders of such comments, to the authority concerned upon request.
                        
                          Hence, sending offensive comments using amrityum will be purely at your own risk,
                            and in no way will amrityum.com be held responsible.
                        </div>
                        <span className="read_or_hide_disclaimer">Read More</span>
                      </div>
                      {/* <div className="general_messages_container">
                        {this.state.general_condolence_messages && this.state.general_condolence_messages.map((item,idx) =>(
                          <div className="single_message" key={idx}>
                            <div className="message" onClick={(e) => this.message_change(e)}>{item.message}</div>
                            <div className="full_message" onClick={(e) => this.message_change(e)}>{item.message}</div>
                          </div>
                        ))}
                      </div> */}
                    </div>
                  </form>
                  <div style={{textAlign:"center"}}>
                    <button className="new_memorial_message_button condolence_button" 
                      onClick={(e) => {this.show_form(e,"condolence")}}
                    >
                      <div className="button_ghost"></div>
                      <i className="fas fa-pencil-alt"></i>
                      <span className="button_label"> Leave a Tribute</span>
                    </button>
                  </div>
                  <div className="tribute_messages_container">
                    {this.state.showing_condolence_messages && this.state.showing_condolence_messages.map((item, idx) => (
                      <div className="single_tribute_message" key={idx} id={item.id}>
                       {item.profile_img_src?
                          (!item.login_type)?
                            <div className="tribute_type_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+item.profile_img_src+"')"}}></div>
                            :
                            <img className="tribute_type_image" src={(item.profile_img_src)} referrerPolicy="no-referrer"/>
                          :
                          <div className="tribute_type_image" style={{backgroundImage: "url('"+user_img+"')"}}></div> 
                        }
                        <div className="name_address_container">
                          <div className="posted_details">{item.written_by}</div>
                          <div className="location">{item.user_location}</div>
                        </div>
                        <div className="date_tribute_text_buttons_grid">
                          <div className="posted_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(),{
                              weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                              })}</div>
                          <div className="message">{item.tribute_text}</div>
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(item.id,"condolence",e)}
                                style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                                {/* <AiOutlineHeart color="white" className="like_icon" style={{display:item.liked?"none":"block"}}/> */}
                                <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                              </div>
                              <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}> {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                            </div>
                            <div className="report_button" onClick={() => this.report_message(item.id,item.tribute_type)}> 
                              <GoReport />
                              <div className="report_label">REPORT </div> 
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    ))} 
                  </div>
                  <div className="show_more_messages_button"
                    onClick={() => this.show_more_message("condolence")}
                    style={{display:this.state.memorial_condolences.length == this.state.showing_condolence_messages.length?"none":"inline-block"}}
                  >Show more</div>
                  <div className="show_less_messages_button"
                    onClick={() => {
                      this.setState({
                        showing_condolence_messages : this.state.memorial_condolences.slice(0,3),
                      },() => {
                        window.scroll({
                          top: (this.condolence_ref.current.offsetTop-20), 
                          left: 0, 
                          behavior: 'smooth' 
                        });
                      });
                    }}
                    style={{display:this.state.memorial_condolences.length > 3 && this.state.memorial_condolences.length == this.state.showing_condolence_messages.length && this.state.memorial_condolences.length?"inline-block":"none"}}
                  >Show less</div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list"
              ref={this.mementos_ref}
              id="mementos"
            >
              <div className="single_info_content" style={{backgroundColor:"rgba(187, 219, 242, 0.4)"}}>
                <div className="single_info_head">
                  <h1>REMEMBRANCES</h1>
                </div>
                <div className="single_info_body" style={{textAlign:"center"}}>
                  <form className="new_tribute_form tribute_form" style={{backgroundColor:""}} onSubmit={ (e) => this.tribute_form_submit(e,"tribute")} method="post">
                    <div className="form_body">
                      <div className="tribute_types_container">
                        <label className="single_tribute_type">
                          <input
                            type="radio"
                            name="tribute_type"
                            value="candle"
                            onChange={this.tribute_type_change}
                            checked={this.state.tribute_type == "candle"}
                          />
                          <div className={this.state.tribute_type == "candle" ? "image_label_container tribute_type_selected" : "image_label_container"} >
                            <div className="tribute_type_image" style={{ backgroundImage: "url('" + candle + "')" }}></div>
                            <div className="tribute_type_label"> Light a Candle</div>
                          </div>
                        </label>
                        <label className="single_tribute_type">
                          <input
                            type="radio"
                            name="tribute_type"
                            value="flower"
                            onChange={this.tribute_type_change}
                            checked={this.state.tribute_type == "flower"}
                          />
                          <div className={this.state.tribute_type == "flower" ? "image_label_container tribute_type_selected" : "image_label_container"}>
                            <div className="tribute_type_image" style={{ backgroundImage: "url('" + flower + "')" }}></div>
                            <div className="tribute_type_label"> Lay a Flower</div>
                          </div>
                        </label>
                      </div>
                      <div className="tribute_type_images_container candle_images" style={{display:this.state.tribute_type=="candle"?"block":"none"}}>
                        {this.state.candle_images && this.state.candle_images.map((item,idx) => (
                          <div className="single_tribute_type_image"
                            key={idx}
                            onClick={() => this.tribute_image_change(item)}
                            style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')","borderColor":this.state.current_tribute_image==item.tribute_type_image?"#bd5895":""}}></div>
                        ))}
                      </div>
                      <div className="tribute_type_images_container flower_images" style={{display:this.state.tribute_type=="flower"?"block":"none"}}>
                        {this.state.flower_images && this.state.flower_images.map((item,idx) => (
                          <div className="single_tribute_type_image"
                            key={idx}
                            onClick={() => this.tribute_image_change(item)}
                            style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')","borderColor":this.state.current_tribute_image==item.tribute_type_image?"#bd5895":""}}></div>
                        ))}
                      </div>
                      {/* <div className="tribute_message_grid">
                        <div className="selected_tribute_type_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+this.state.current_tribute_image+"')"}}></div>
                        <div className="tribute_message">
                          <textarea
                            name="tribute_text"
                            value={this.state.tribute_text}
                            placeholder="Write your tribute message"
                            onChange={this.form_change}
                            required
                          />
                        </div>
                      </div> */}
                      {/* <div className="disclaimer">
                        <span style={{fontweight:"500",color:"darkred"}}>Disclaimer:</span> Please write your correct name and other information requested.
                        Kindly do not post any personal, abusive, defamatory, infringing, obscene,
                          indecent, discriminatory, or unlawful or similar comments. 
                          Amrityum.com will not be responsible for any defamatory message posted 
                          under this notice.
                        Please note that sending false messages to insult, defame, intimidate, mislead,
                          or deceive people or to intentionally cause public disorder is punishable
                          under law. It is obligatory on amrityum.com to provide all details we
                            hold of senders of such comments, to the authority concerned upon request.
                        Hence, sending offensive comments using amrityum will be purely at your own risk,
                          and in no way will amrityum.com be held responsible.
                      </div> */}
                      <div className="form_footer">
                        <div></div>
                        <div className="form_footer_spacer"></div>
                        <ButtonTheme2 label="Publish" className="save_tribute_button" />
                      </div>

                    </div>
                  </form>
                  <div className="mementos_container">
                    {this.state.showing_mementos && this.state.showing_mementos.map((item, idx) => (
                      <div className="single_memento_container" id={item.id} key={idx}>
                        <div className="label_quotes_grid">
                          <div className="mementos_label">REMEMBRANCES</div>
                          <div className="quotes">
                            {item.tribute_type == "candle"?
                            <div>
                              Your love will light our way.  Your memory will forever be with us.
                            </div>
                            :
                            <div>
                              <span>Your life was a blessing,</span>
                              <span> your memory a treasure, </span>
                              <span>and now missed beyond measure.</span>
                            </div>    
                          }</div>
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(item.id,"tribute",e)}
                                style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                                {/* <AiOutlineHeart color="white" className="like_icon" style={{display:item.liked?"none":"block"}}/> */}
                                <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                              </div>
                              <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}> {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                            </div>
                            {/* <div className="report_button" style={{marginTop:"5px"}} onClick={() => this.report_message(item.id,item.tribute_type)}> 
                              <GoReport />
                              <div className="report_label">REPORT </div> 
                            </div> */}
                          </div>
                        </div>
                        <div className="published_details">
                          <div className="published_by">{item.written_by}</div>
                          <div className="location">{item.user_location}</div>
                          <div className="posted_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(),{
                              weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                              })}</div>
                        </div>
                        <div className="tribute_type_image" style={{ backgroundImage: item.tribute_type_image?"url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')" :item.tribute_type == "candle" ?"url('" + candle + "')" : item.tribute_type == "flower" ? "url('" + flower + "')" : "url('" + note + "')" }}></div>
                      </div>
                    ))} 
                  </div>
                  <div className="show_more_messages_button"
                    onClick={() => this.show_more_message("mementos")}
                    style={{display:this.state.memorial_tributes.length == this.state.showing_mementos.length?"none":"inline-block"}}
                  >Show more</div>
                  <div className="show_less_messages_button"
                    onClick={() => {
                      this.setState({
                        showing_mementos : this.state.memorial_tributes.slice(0,3),
                      },() =>{
                        window.scroll({
                          top: (this.mementos_ref.current.offsetTop-20), 
                          left: 0, 
                          behavior: 'smooth' 
                        });
                      });
                    }}
                    style={{display:this.state.memorial_tributes.length > 3 && this.state.memorial_tributes.length == this.state.showing_mementos.length && this.state.memorial_tributes.length?"inline-block":"none"}}
                  >Show less</div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" ref={this.gallery_ref} id="gallery">
              <div className="single_info_content" style={{backgroundColor:"rgb(217, 212, 206)"}}>
                <div className="single_info_head">
                  <h1>GALLERY</h1>
                  <p className="gallery_details">Memories don’t leave once they make a home in your heart. Share your exciting moments!.</p>
                </div>
                <div className="single_info_body" style={{display:this.state.gallery_images.length?"block":"none"}}>
                  <div className="gallery_images_container">
                    {this.state.gallery_images && this.state.gallery_images.map((item,idx) => (
                      <div className="single_image_container" key={idx}>
                        <div className="single_image" 
                          onClick={() => this.zoomImage(item,idx)}
                          style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/gallery_images/"+item.gallery_image_src+"')"}}>
                        </div>
                        <div className="img_caption">{item.gallery_image_caption}</div>
                      </div>
                    ))}
                  </div>{/* End of gallery images container */}
                  <div className="gallery_images_slider">
                    <AutoplaySlider cssModule={AwsSliderStyles}
                    play={false}
                    cancelOnInteraction={false}
                    interval={1000}
                    onTransitionStart={this.onTransitionStart}
                    onTransitionEnd={this.onTransitionEnd}
                    transitionDelay={100}
                    showTime={false}
                    buttons={this.state.gallery_images.length >1?true:false}
                    mobileTouch={this.state.gallery_images.length >1?true:false}
                    bullets={false}
                    organicArrows={true}
                    // mobileTouch={true}
                    >
                      {this.state.gallery_images && this.state.gallery_images.map((item,idx) => (
                        <div className="slider_image" key={idx} data-src={process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/gallery_images/"+item.gallery_image_src}></div>
                      ))}
                    </AutoplaySlider>
                  </div>
                </div>
              </div>
            </div>
                
            <div className="single_info_container single_list" ref={this.life_qs_ans_ref} id="life_qs_ans" >
              <div className="single_info_content" style={{backgroundColor:"rgba(234, 236, 235, 1)"}}>
                <div className="single_info_head">
                  <h1>LET’s reminisce</h1>
                </div>
                <div className="single_info_body">
                  {!this.state.life_questions.length && this.state.memorial.user_email == this.context.session.email?
                    <div className="tool_tip">We encourage you to ask relevant questions to engage visitors to the profile.</div>
                    :
                    <div className="tool_tip">Recall and reflect on happy memories from the past. Take a trip down memory lane and recall special moments, experiences, and memories from the past with a sense of nostalgia and happiness.</div>
                  }
                  {this.state.life_questions && this.state.life_questions.map((question,index) => (

                  <div className="single_question_answers_grid" 
                    key={index}
                    id={question.id}
                    style={{gridTemplateColumns:question.answers.length?"250px auto":"auto"}}
                    >
                    <div className="question_textarea_container">
                      <div className="question_no_label">Question {index+1}</div>
                      <div className="question">{question.question}</div>
                      {this.context.session?
                      <form className="life_question_answer_form" 
                        onSubmit={(e) => this.life_question_answer_form_submit(e,index,question.id)}
                        >
                        <textarea
                          name="life_question_answer"
                          value={question.user_answer}
                          placeholder="Answer in 200 characters"
                          rows={5}
                          onChange={(e) => this.life_question_answer_change(e,index)}
                          required
                        />
                        <button className="save_answer_button" 
                        style={{visibility:question.user_answer.length?"visible":"hidden"}}
                        > <div className="button_ghost"></div> Publish</button>
                      </form>
                      :
                      <button className="new_memorial_message_button" 
                        onClick={() => {
                          this.context.confirm("Sign in to continue",() => {
                            this.props.history.push({ pathname: "/Login", url : "/Memorials/"+this.props.match.params.memorial_id});
                          });
                          return;
                        }}
                        >
                          <div className="button_ghost"></div>
                          <i className="fas fa-pencil-alt"></i>&nbsp;
                        <span className="button_label">Answer this question</span>
                      </button>
                      }
                    </div>
                    <div className="answers_slider_container">
                    {question.answers && question.answers.map((container,idx) => (
                      <div className="single_slider_container" id={idx} key={idx}>
                        <div className="slider_arrows_container">
                          <div className="quote_container">
                            <FaQuoteRight className="quote_icon" />
                          </div>
                          <div className="slider_arrows">
                            <div className={idx==0?"slide_left_arrow disabled_arrow_icon":"slide_left_arrow enabled_arrow_icon"} 
                              onClick={() => this.faq_slide_left(question.id,idx)}
                            >
                              <HiArrowSmLeft className="left_arrow_icon"/>
                            </div>
                            <div className={idx==question.answers.length-1?"slide_right_arrow disabled_arrow_icon":"slide_right_arrow enabled_arrow_icon"}
                              onClick={() => {this.faq_slide_right(question.id,idx)}}
                            >
                              <HiArrowSmRight className="right_arrow_icon"/>
                            </div>
                          </div>
                        </div>
                        {container && container.map((answer,jdx) => (
                        <div className={jdx%2 == 0?"answer_left_top":"answer_right_bottom"} key={jdx}>
                          <div className="image_name_grid">
                            {answer.profile_img_src?
                            (!answer.login_type)?
                              <div className="profile_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+answer.profile_img_src+"')"}}></div>
                              :
                              <img className="profile_image" src={(answer.profile_img_src)} referrerPolicy="no-referrer"/>
                            :
                            <div className="profile_image" style={{backgroundImage: "url('"+user_img+"')"}}></div> 
                          }
                            {/* <div className="profile_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+answer.profile_img_src+"')"}}></div> */}
                            <div className="name_location_container">
                              <div className="name">{answer.fullname}</div>
                              <div className="location">{answer.user_location}</div>
                            </div>
                          </div>
                          <div className="answer">" {answer.answer} "</div>
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(answer.id,"faq",e)}
                                style={{backgroundColor:answer.liked?"":"#ff365f",color:answer.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{answer.liked?"Liked":"Like"}</div>
                                {/* <AiOutlineHeart color="white" className="like_icon" style={{display:answer.liked?"none":"block"}}/> */}
                                <AiFillHeart color="#ff365f" className="like_icon" style={{color:answer.liked?'#ff365f':"white"}} />
                              </div>
                              <span className="likes_count" style={{display:answer.like_count>0?"block":"none"}}>  {answer.like_count} {answer.like_count>1?"Likes":"Like"}</span>
                            </div>
                            <div className="report_button" onClick={() => this.report_message(answer.id,"faq")}> 
                              <GoReport />
                              <div className="report_label">REPORT </div> 
                            </div>
                          </div>
                        </div>
                        ))}
                        <div className="mobile_slider_arrows">
                            <div className={idx==0?"slide_left_arrow disabled_arrow_icon":"slide_left_arrow enabled_arrow_icon"} 
                              onClick={() => this.faq_slide_left(question.id,idx)}
                            >
                              <HiArrowSmLeft className="left_arrow_icon"/>
                            </div>
                            <div className={idx==question.answers.length-1?"slide_right_arrow disabled_arrow_icon":"slide_right_arrow enabled_arrow_icon"}
                              onClick={() => {console.log(idx);this.faq_slide_right(question.id,idx)}}
                            >
                              <HiArrowSmRight className="right_arrow_icon"/>
                            </div>
                          </div>
                      </div>
                      ))}
                    </div>
                  </div>
                  ))}
                
                  {/* <div style={{display:this.state.life_questions.length?"none":"block",textAlign:"center"}}>
                    <button className="reminisce_button"   
                    onClick={() => this.props.history.push("/Dashboard/Memorials/New")}
                   >
                      <div className="button_ghost"></div>
                      <i className="fas fa-pencil-alt"></i>&nbsp;
                      <span className="button_label">Choose Questions</span>
                    </button>
                  </div> */}
                 <div className="disclaimer_container" style={{color:"white"}}>
                    <span style={{fontweight:"500",color:"darkred"}}>Disclaimer:</span> 
                    <div className="disclaimer_text" >
                      Please write your correct name and other information requested.
                      Kindly do not post any personal, abusive, defamatory, infringing, obscene,
                        indecent, discriminatory, or unlawful or similar comments. 
                        Amrityum.com will not be responsible for any defamatory message posted 
                        under this notice.
                        
                      Please note that sending false messages to insult, defame, intimidate, mislead,
                        or deceive people or to intentionally cause public disorder is punishable
                        under law. It is obligatory on amrityum.com to provide all details we
                          hold of senders of such comments, to the authority concerned upon request.
                    
                      Hence, sending offensive comments using amrityum will be purely at your own risk,
                        and in no way will amrityum.com be held responsible.
                    </div>
                    <span className="read_or_hide_disclaimer">Read More</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" ref={this.donate_ref} id="donate_block">
              <div className="single_info_content" style={{backgroundColor:"white"}}>
                {/* <div className="single_info_head">
                  <h1>Donate to charity</h1>
                </div> */}
                <div className="single_info_body">
                  <div className="donate_container" style={{gridTemplateColumns:this.state.charity && this.state.charity.id?"auto 250px":"auto"}}>
                    <div>
                      <div className="donate_title">DONATE TO CHARITY</div>
                      {
                      this.state.charity && this.state.charity.id?
                      <div className="charity_description">
                        In Memory donations are one of the best ways to honour the lives of those who have passed away.
                        Please find below the charity recommended by <b style={{color:"#5a2d8f"}}>{this.state.memorial.first_name} {this.state.memorial.surname}</b>'s family. Every contribution you make brings a positive change in the lives of the neediest.
                      </div>
                      :
                      <div className="charity_description">
                        In Memory donations are one of the best ways to honour the lives of those who have passed away. Please find below charities you may like to support in memory of someone special. Every contribution you make brings a positive change in the lives of the neediest. 
                      </div>
                      }
                      <div className="charity_description">Alternatively, you may also view here our complete charity database to make donations.</div>
                      <div className="charity_description">Please note: Amrityum does not collect or process the donation.</div>
                      <div className="button_container">
                      <Link to={"/Charity/"+this.state.charity.id} 
                        className="donate_now_button"
                        style={{display:this.state.charity && this.state.charity.id?"inline-block":"none"}} target={"_blank"}
                         >Donate Now</Link>
                        <Link to="/Charities" className="all_charity_button" target={"_blank"}>All Charities</Link>
                      </div>
                    </div>
                    <div className="selected_notice" style={{display:this.state.charity && this.state.charity.id?"inline-grid":"none"}}>
                      {/* <div className="selected_label">Charity Details</div> */}
                      <Link className="single_charity" to={"/Charity/"+this.state.charity.id} target={"_blank"}>
                        <div className="charity_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+this.state.charity.image_src+"')"}}></div>
                        <div className="charity_name">{this.state.charity.name}</div>
                        <div className="charity_address">{this.state.charity.locality}, {this.state.charity.city?this.state.charity.city+", ":""}{this.state.charity.state?this.state.charity.state+", ":""}{this.state.charity.country}</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list mobile_buttons_block">
              <div className="single_info_content" style={{backgroundColor:"#dfd1e0"}}>
                <div className="single_info_body">
                  <div className="mobile_buttons_container">
                    <Link className="custom_button" to="/Feedback" style={{pointerEvents:this.state.preview?"none":"auto"}}>
                      <div className="button_label">Feedback</div>
                      <ImArrowRight2 size={16}  className="arrow_icon"/>
                    </Link>

                    <Link className="custom_button"
                      style={{display:this.state.family_notice_mapped_id?"grid":"none",pointerEvents:this.state.preview?"none":"auto"}}
                      to={"/InMemoryNotices/"+this.state.family_notice_mapped_id}
                      target="_blank"
                      rel="noopener noreferrer" >
                      <div className="button_label">In Memory Notice</div>
                      <ImArrowRight2 size={16}  className="arrow_icon"/>
                    </Link>
                    <Link className="custom_button"
                      style={{display:this.state.death_notice_mapped_id?"grid":"none",pointerEvents:this.state.preview?"none":"auto"}}
                      to={"/DeathNotices/"+this.state.death_notice_mapped_id}
                      target="_blank"
                      rel="noopener noreferrer" >
                      <div className="button_label">Death Notice</div>
                      <ImArrowRight2 size={16}  className="arrow_icon"/>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="single_info_container single_list" ref={this.contact_admin_ref} id="contact_admin_block">
              <div className="single_info_content" style={{backgroundColor:"rgb(225, 225, 225)"}}>
                <div className="single_info_head">
                  <h1>CONTACT PAGE ADMIN</h1>
                </div>
                <div className="single_info_body">
                  <form className="contact_page_admin_form" onSubmit={this.contact_admin_form_submit}>
                    <div className="inputs_grid">
                      <input
                        name = "name"
                        type = "text"
                        value ={ this.state.name}
                        onChange = {this.form_change}
                        required = {true}
                        placeholder="Full Name *"
                      />
                      <input
                        name = "email"
                        type = "email"
                        value = {this.state.email}
                        onChange = {this.form_change}
                        required = {true}
                        placeholder="Email Address *"
                      />
                    </div>
                    <textarea
                      name="message"
                      value={this.state.message}
                      rows={2}
                      onChange={this.form_change}
                      maxLength={200}
                      required
                      placeholder="Message *"
                    />
                    <div className="maximum_character_label">Max 200 characters</div>
                    <ReCAPTCHA 
                       sitekey={process.env.REACT_APP_SITE_KEY} 
                       ref={this.captchaRef}
                    />
                    <button className="save_message_button">
                      <div className="button_ghost"></div>
                      <div className="button_label">SEND</div>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list buttons_block">
              <div className="single_info_content">
                <div className="single_info_body">
                  <div className="buttons_container">
                    <div className="back_button custom_button"
                      onClick={() => this.props.history.goBack()}
                    >
                      <div className="icon_container">
                        <MdOutlineKeyboardBackspace className="icon"/>
                      </div>
                      <div className="button_label">Back</div>
                      {/* <MdOutlineArrowForwardIos size={18}  className="arrow_icon"/> */}
                    </div>
                    <div className="report_page_button custom_button" 
                      onClick={this.show_report_page_form}
                    >
                      <div className="icon_container">
                        <GoReport className="icon"/>
                      </div>
                      <div className="button_label">Report this page</div>
                      {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
                    </div>
                    <div className="share_button custom_button">
                      <div className="icon_container">
                        <MdOutlineShare className="icon"/>
                      </div>
                      <div className="button_label">Share</div>
                      {/* <MdOutlineArrowForwardIos size={18}  className="arrow_icon"/> */}
                      <div className="share_buttons_container">
                        <a href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(this.state.page_share_url)} target={"_blank"}>
                          <i className="fab fa-facebook-f share_icon" style={{color:"#0f92f3"}}></i>
                          <span>Facebook</span>
                        </a>
                        <a href={"https://api.whatsapp.com/send?text=" + this.state.fullname + " " + encodeURIComponent(this.state.page_share_url)} data-action="share/whatsapp/share" target={"_blank"}>
                          <i className="fab fa-whatsapp share_icon" style={{color:"#40bf50"}}></i>
                          <span>WhatsApp</span>
                        </a>
                        {/* <i className="fab fa-instagram share_icon"></i> */}
                        <a href={"mailto:?subject=Memorial of "+this.state.fullname +"&body=" + this.state.fullname + " " + this.state.page_share_url} target={"_blank"}>
                          <i className="far fa-envelope-open share_icon" style={{color:"#d10a0a"}}></i>
                          <span>Mail</span>
                        </a>
                        <a href={"https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.state.page_share_url)} target={"_blank"}>
                          <i className="fab fa-twitter share_icon" style={{color:"#4ea6e9"}}></i>
                          <span>Twitter</span>
                        </a>
                        <a onClick={() => {
                          var copyURL = this.state.page_share_url;
                          navigator.clipboard.writeText(copyURL);
                          $.alert("URL copied to clipboard");
                          setTimeout(() => {
                            $(".custom_alert_container .custom_alert_box .custon_alert_ok_button").trigger("click");
                          },2000);
                        }}>
                            <i className="fa fa-clone share_icon" style={{color:"grey"}}></i>
                          <span>Copy URL</span>
                        </a>
                      </div>
                    </div>

                    <div className="print_button custom_button"
                      onClick={() => window.print()}
                    >
                    <div className="icon_container">
                      <MdOutlinePrint className="icon"/>
                    </div>
                      <div className="button_label">Print</div>
                      {/* <MdOutlineArrowForwardIos size={18}  className="arrow_icon"/> */}
                    </div>
                  </div>
                  <p className="about_amrityum">Amrityum strives to create a virtual platform for people to come together, get
                    connected and remember and celebrate their deceased loved ones by
                    publishing Death &amp; Family Notices, Create a Memorial, as well as preserve
                    our own stories and make them enduring by creating Life Events &amp; Family
                    Tree. Immortalise your tamed companions with Pet Tributes.</p>
                </div>
                <div className="logo_container">
                  <img src={logo} width="200" />
                </div>
              </div>
            </div>
            <div className="approve_reject_buttons_container" style={{display:this.state.preview?"block":"none"}}>
              <ButtonTheme1
                label="<< Back"
                className="previous_page_button"
                onClick={() => this.props.history.goBack()}
              ></ButtonTheme1>
              {this.state.memorial.status == "PENDING" && this.context.session.user_type == "admin"?
              <>
              <ButtonTheme1
                label="Reject"
                className="reject_button"
                onClick={() => { this.rejectMemorial() }}
                style={{display:this.state.memorial.status == "DELETED"?"none":"inline-block"}}
              ></ButtonTheme1>
              <ButtonTheme1
                label="Approve"
                className="approve_button"
                onClick={() => { this.approveMemorial() }}
                style={{display:this.state.memorial.status == "APPROVED"?"none":"inline-block"}}
              ></ButtonTheme1>
              </>:""}
              {/* <ButtonTheme1
                label="Back"
                className="previous_page_button"
                onClick={() => { this.props.history.goBack() }}
              ></ButtonTheme1> */}
            </div>
          </div>
          <div className="right_gap"></div>
          {this.state.preview?
          "":
          <Footer/>
          }
        </div>
        <div className="report_page_form_ghost"
          onClick={() => $(".report_page_form_ghost").css("display","none")}
        >
          <form className="report_page_form" 
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.report_page_form_submit}
          >
            <div className="report_page_title">Report this Notice</div>
            <div className="leave_message_label">Reasons for reporting notice</div>
            <textarea
              name="reason"
              value={this.state.reason}
              rows={5}
              onChange={this.form_change}
              maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => $(".report_page_form_ghost").css("display","none")}
            >
              <div>x</div>
            </div>
          </form>
        </div>
        <div className="reject_reason_container" ref={this.reject_reason_container_ref}>
          <form className="reject_reason_form" onSubmit={this.reject_form_submit} method="post">
            <div>Reject Reason</div>
            <textarea name="reject_reason"
              rows={4}
              value={this.state.reject_reason}
              onChange={this.form_change}
              required
            />
            <button className="reject_form_submit_button">Submit</button>
            <i className="fa fa-times reject_form_close_button" onClick={() => { this.reject_reason_container_ref.current.style.display = "none"; this.setState({ reject_reason: "" }) }}></i>
          </form>
        </div>
        {/* Gallery */}
        <div className="zoomed_image_ghost" onClick={() => this.setState({zoomed_item : ""})} style={{display:this.state.zoomed_item.gallery_image_src?"block":"none"}}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()}>
            <div className="image_caption_container">
              <img src={process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/gallery_images/"+this.state.zoomed_item.gallery_image_src} />
              <div style={{display:this.state.zoomed_item.gallery_image_caption?"block":"none"}} className="image_caption">{this.state.zoomed_item.gallery_image_caption}</div>
              <div className="close_zoomed_image_button" onClick={this.minimizeImage}>
                <VscChromeClose/>
              </div>
            </div>
            <div className="arrow_container">
              <MdOutlineArrowBackIos
                className="image_previous_button"
                size={45}
                color="#333" 
                onClick={this.previousImage}
                style={{pointerEvents:this.state.image_index == 0?"none":"auto",opacity:this.state.image_index == 0?0.2:1}}
              />
              <MdOutlineArrowForwardIos
                className="image_next_button"
                size={45}
                color="#333"
                onClick={this.nextImage}
                style={{pointerEvents:this.state.gallery_images.length == this.state.image_index+1?"none":"auto",opacity:this.state.gallery_images.length == this.state.image_index+1?0.2:1}}
              />
            </div>
          </div>
         
        </div>
        {/* Milestone */}
        <div className="zoomed_image_ghost milestone_ghost" style={{display: this.state.milestone_preview.title?"block":"none"}}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()} style={{display:this.state.milestone_preview.title?"block":"none"}}>
            <div className="image_caption_container">
              {this.state.milestone_preview.image_src?
                <img src={process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/milestone_images/"+this.state.milestone_preview.image_src} />
                :<div className="default_milestone_image">
                  <img src={logo_icon} />
                </div>
              }
              <div className="image_caption">
                <div className="milestone_date">{new Date(this.state.milestone_preview.date).toLocaleString(new Date().getTimezoneOffset(),{
                  day: 'numeric', month: 'long', year: 'numeric'
                })}</div>
                <div className="milestone_title">{this.state.milestone_preview.title}</div>
              </div>
              <div className="close_zoomed_image_button" onClick={() => this.setState({milestone_preview : ""})}>
                <VscChromeClose/>
              </div>
            </div>
            
            <div className="arrow_container">
              <MdOutlineArrowBackIos
                className="image_previous_button"
                size={45}
                onClick={() => {
                  --this.state.milestone_index;
                  this.milestone_preview(this.state.milestone_index);
                }}
                style={{pointerEvents:this.state.milestone_index == 0?"none":"auto",opacity:this.state.milestone_index == 0?0.2:1}}
              />
              <MdOutlineArrowForwardIos
                className="image_next_button"
                size={45}
                onClick={() => {
                  ++this.state.milestone_index;
                  this.milestone_preview(this.state.milestone_index);
                }}
                style={{pointerEvents: this.state.milestones.length == this.state.milestone_index+1?"none":"auto",opacity:this.state.milestones.length == this.state.milestone_index+1?0.2:1}}
              />
            </div>
          </div>
            <LogoLoading/>
        </div>
      </div>
      </>

    )
  }
}

export default SingleMemorial;
SingleMemorial.contextType = MyContext;