import React from "react";
import "./NewPartner.scss";
import { InputTheme4, InputTheme1, InputTheme3, TextAreaTheme1 } from "../../../res/styler/InputThemes/InputThemes";
import ImageUpload from "../../../res/styler/ImageUpload/ImageUpload";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import $ from "jquery";
import MyContext from "../../MyContext";
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { FaInfoCircle } from "react-icons/fa";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import { VscChromeClose } from "react-icons/vsc";
import { BsEyeFill } from "react-icons/bs";

class NewPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      partner_id: "",
      name: "",
      email: "",
      password: "",
      cnf_password: "",
      state: "",
      phone_no: "",
      business_name: "",
      business_address: "",
      areas_covered: "",
      google_location: "",
      locality: "",
      country: "",
      state: "",
      city: "",
      business_phone_no: "",
      business_phone_no_code: "IN",
      business_whatsapp_no: "",
      business_whatsapp_no_code: "IN",
      business_email: "",
      business_description: "",
      editorState1: EditorState.createEmpty(),
      editorState2: EditorState.createEmpty(),
      services_description: "",
      image: "",
      image_src: "",
      cover_image: "",
      cover_image_src: "",
      states: [],
      status: "",
      services: [],
      countries: [],
      selected_services: [],
      password_error: "",
      otp: "",
      mail_status: "",
      website: "",
      business_hours: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "",
      gallery_images: [],
      logo_image_flag: 0,
      cover_image_flag: 0,
      other_service: "",
      zoomed_item: {}
    }
    this.image_change = this.image_change.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.revert_image = this.revert_image.bind(this);
    this.cover_image_change = this.cover_image_change.bind(this);
    this.delete_cover_image = this.delete_cover_image.bind(this);
    this.revert_cover_image = this.revert_cover_image.bind(this);
    this.country_change = this.country_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.service_change = this.service_change.bind(this);
    this.form_change = this.form_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.delete_services = this.delete_services.bind(this);
    this.mobile_no_change = this.mobile_no_change.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.whatsapp_no_change = this.whatsapp_no_change.bind(this);
    this.whatsappCountryChange = this.whatsappCountryChange.bind(this);
    this.onEditorState1Change = this.onEditorState1Change.bind(this);
    this.onEditorState2Change = this.onEditorState2Change.bind(this);
    this.gallery_image_change = this.gallery_image_change.bind(this);
    this.add_gallery_row = this.add_gallery_row.bind(this);
    this.delete_gallery_row = this.delete_gallery_row.bind(this);
    this.new_partner_page_ref = React.createRef();
    this.business_name_ref = React.createRef();
    this.business_address_ref = React.createRef();
    this.locality_ref = React.createRef();
    this.country_ref = React.createRef();
    this.state_ref = React.createRef();
    this.city_ref = React.createRef();
    this.phone_no_ref = React.createRef();
    this.whatsapp_no_ref = React.createRef();
    this.email_ref = React.createRef();
    this.google_location_ref = React.createRef();
    this.logo_ref = React.createRef();
    this.cover_ref = React.createRef();
    this.image_input_ref = React.createRef();
    this.business_description_ref = React.createRef();
    this.service_description_ref = React.createRef();
    this.services_ref = React.createRef();
    this.reg_form_ref = React.createRef();
    this.website_ref = React.createRef();
    this.facebook_ref = React.createRef();
    this.instagram_ref = React.createRef();
    this.twitter_ref = React.createRef();
    this.linkedin_ref = React.createRef();
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    const partner_id = this.props.match.params.partner_id;
    if (this.props.location.pathname == "/Dashboard/Services/Edit") {
      $.ajax({
        url: process.env.REACT_APP_API + "/api/editPartner",
        data: this.context.bind_session({}),
        type: "POST"
      }).done(function (return_data) {
        // console.log(return_data);
        if (return_data.status == "success") {
          const contentBlock1 = htmlToDraft(return_data.partner.business_description);
          if (contentBlock1) {
            const contentState1 = ContentState.createFromBlockArray(contentBlock1.contentBlocks);
            const editorState1 = EditorState.createWithContent(contentState1);
            return_data.partner.editorState1 = editorState1;
          }
          const contentBlock2 = htmlToDraft(return_data.partner.services_description);
          if (contentBlock2) {
            const contentState2 = ContentState.createFromBlockArray(contentBlock2.contentBlocks);
            const editorState2 = EditorState.createWithContent(contentState2);
            return_data.partner.editorState2 = editorState2;
          }
          for(let i =0; i<return_data.partner.gallery_images.length; i++){
            return_data.partner.gallery_images[i].gallery_image_src_old = process.env.REACT_APP_CDN_URL+"/"+return_data.partner.user_id+"/gallery_images/"+return_data.partner.gallery_images[i].gallery_image_src
          }
          obj_this.context.setSession(return_data.session);
          obj_this.setState(return_data.partner);
          obj_this.context.loading(0);
        } else if (return_data.status == "deleted") {
          obj_this.context.delete_session();
          alert("Your services deleted by admin. Please login and register new services.");
          // window.location.reload();
          obj_this.props.history.push({ pathname: "/Login", url: "/Dashboard" })
        } else if (return_data.status == "rejected") {
          obj_this.context.delete_session();
          alert("Your services rejected by admin. Please login and register new services.");
          // window.location.reload();
          obj_this.props.history.push({ pathname: "/Login", url: "/Dashboard" })
        } else if (return_data.status == "session failed") {
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    } else {
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getPartnerServices",
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState({
            countries: return_data.countries,
            services: return_data.services
          });
          obj_this.context.loading(0);
        } else if (return_data.status == "session failed") {
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }

    $("form input[type=text]").on("keypress", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        $(this).nextAll("input:first").trigger("focus");
      }
    });
  }

  image_change(e) {
    const file = e.target.files[0];
    // if (!(file.size / 1024 > 2048)){
    this.setState({
      image: file,
    });
    // }
  }

  delete_image() {
    this.setState({
      logo_image_flag: 1,
      image: "",
      image_src: ""
    });
  }

  revert_image() {
    this.setState({
      image: ""
    });
  }

  cover_image_change(e) {
    const file = e.target.files[0];
    // if (!(file.size / 1024 > 2048)){
    this.setState({
      cover_image: file,
    });
    // }
  }

  delete_cover_image() {
    this.setState({
      cover_image_flag: 1,
      cover_image: "",
      cover_image_src: ""
    });
  }

  revert_cover_image() {
    this.setState({
      cover_image: ""
    });
  }

  gallery_image_change(e, idx) {
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
      var gallery_images = this.state.gallery_images;
      var gallery = gallery_images[idx];
      gallery.image= file;
      gallery.gallery_image_src =  URL.createObjectURL(file);
      this.setState({
        gallery_images : gallery_images
      });
      console.log(gallery_images)
    // }
  }

  add_gallery_row() {
    var gallery_images = this.state.gallery_images;
    for (var i = 0; i < gallery_images.length; i++) {
      if (gallery_images[i]['image'] || gallery_images[i]['gallery_image_src']) {

      }else{
        $.alert("Upload a photo to continue")
        return;
      }
    }
    var item = {
      image: "",
      gallery_image_src: "",
      gallery_image_caption: ""
    }
    gallery_images.push(item);
    this.setState({
      gallery_images: gallery_images
    });
  }

  delete_gallery_row(idx,e) {
    const table_row = $(e.target).parents(".single_image_container");
    table_row.find(".preview_image").attr("src",table_row.next().find(".preview_image").attr("src"));
    var gallery_images = this.state.gallery_images;
    gallery_images[idx]['image'] = undefined;
    gallery_images[idx]['gallery_image_src'] = undefined;
    const new_gallery_images = gallery_images.filter((item, index) => index != idx);
    this.setState({
      gallery_images: new_gallery_images
    });
  }

  form_change(e) {
    if (e.target.name == "business_description") {
      if (e.target.value.length > 400) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }

    if (e.target.name == "services_description") {
      if (e.target.value.length > 600) {
        $.alert("Maximum characters exceeded");
        return;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      password_error: ""
    });
  }

  onEditorState1Change(value) {
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
      editorState1: value,
      business_description: draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  onEditorState2Change(value) {
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
      editorState2: value,
      services_description: draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  country_change(e) {
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getPartnerRegistrationStates",
      data: this.context.bind_session({ country: e.target.value }),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const country = obj_this.state.countries.find((item) => item.id === e.target.value);
        obj_this.setState({
          states: return_data.states,
          state: "",
          country: e.target.value,
          country_label: country.name
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  };

  state_change(e) {
    const state = this.state.states.find((item) => item.id === e.target.value);
    this.setState({
      state: e.target.value,
      state_label: state.name
    });

  };

  mobile_no_change(value) {
    this.setState({
      business_phone_no: value??""
    })
  }

  onCountryChange(country) {
    const country_code = country ?? this.state.country_code;
    this.setState({
      business_phone_no_code: country_code
    })
  }

  whatsapp_no_change(value) {
    this.setState({
      business_whatsapp_no: value??""
    })
  }

  whatsappCountryChange(country) {
    const country_code = country ?? this.state.country_code;
    this.setState({
      business_whatsapp_no_code: country_code
    })
  }

  service_change(id) {
    let selected_services = this.state.selected_services
    console.log(id);
    let find = selected_services.indexOf(id)
    if (find > -1) {
      selected_services.splice(find, 1)
    } else {
      if (id == 24) {
        $.alert("Specify your service");
      }
      selected_services.push(id)
    }
    this.setState({ selected_services })
  }

  // gallery_image_change(e) {
  //   if (e.target.files.length > 12) {
  //     $.alert("Maximum limit exceeded.")
  //     this.image_input_ref.current.value = "";
  //     return;
  //   }
  //   this.setState({
  //     gallery_images: e.target.files,
  //   });
  // }

  form_submit(e) {
    e.preventDefault();
    if (!this.state.business_name) {
      $.alert("Business Name Required");
      window.scrollTo({
        top: $(this.business_name_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.business_name_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (!this.state.business_address) {
      $.alert("Business Address Required");
      window.scrollTo({
        top: $(this.business_address_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.business_address_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (!this.state.locality) {
      $.alert("Locality Required");
      window.scrollTo({
        top: $(this.locality_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.locality_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (!this.state.country) {
      $.alert("Country Required");
      window.scrollTo({
        top: $(this.country_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (this.state.states.length && !this.state.state) {
      $.alert("State/Province/County/Region Required");
      window.scrollTo({
        top: $(this.state_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.city) {
      $.alert("City/Town Required");
      window.scrollTo({
        top: $(this.city_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      $(this.city_ref.current).addClass('input_theme4_SELECTED');
      return;
    }
    if (this.state.business_phone_no.length < 4) {
      $(".PhoneInputInput").focus();
      $.alert("Business Phone Number Required");
      window.scrollTo({
        top: $(this.phone_no_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (this.state.business_whatsapp_no.length < 4) {
      $(".PhoneInputInput").focus();
      $.alert("Business Whatsapp Number Required");
      window.scrollTo({
        top: $(this.whatsapp_no_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.business_email) {
      $.alert("Business Email Required");
      window.scrollTo({
        top: $(this.email_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var re = /\S+@\S+\.\S+/;
    if (!re.test(this.state.business_email)) {
      $.alert("Please enter an email address");
      window.scrollTo({
        top: $(this.email_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }

    if (!this.state.google_location) {
      $.alert("Google Location Required");
      window.scrollTo({
        top: $(this.google_location_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.google_location) && (this.state.google_location)) {
      $.alert("Please enter an valid Google Location URL");
      window.scrollTo({
        top: $(this.google_location_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }

    if (this.state.image || this.state.image_src) {

    } else {
      $.alert("Logo Required");
      window.scrollTo({
        top: $(this.logo_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (this.state.cover_image || this.state.cover_image_src) {

    } else {
      $.alert("Cover Image Required");
      window.scrollTo({
        top: $(this.cover_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.business_description) {
      $.alert("Business Description Required");
      window.scrollTo({
        top: $(this.business_description_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.services_description) {
      $.alert("Services Description Required");
      window.scrollTo({
        top: $(this.service_description_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.selected_services.length) {
      $.alert("Select atleast one service");
      window.scrollTo({
        top: $(this.services_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.website) && (this.state.website) ) {
      $.alert("Please enter an valid Website URL");
      window.scrollTo({
        top: $(this.website_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.facebook) && (this.state.facebook) ) {
      $.alert("Please enter an valid Facebook URL");
      window.scrollTo({
        top: $(this.facebook_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.instagram) && (this.state.instagram) ) {
      $.alert("Please enter an valid Instagram URL");
      window.scrollTo({
        top: $(this.instagram_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.twitter) && (this.state.twitter) ) {
      $.alert("Please enter an valid Twitter URL");
      window.scrollTo({
        top: $(this.twitter_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var urlPattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'); 
    if (!urlPattern.test(this.state.linkedin) && (this.state.linkedin)) {
      $.alert("Please enter an valid LinkedIn URL");
      window.scrollTo({
        top: $(this.linkedin_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    const partner_details = this.state;
    // var gallery_images = partner_details.gallery_images;
    // for (var i = 0; i < gallery_images.length; i++) {
    //   if (gallery_images[i]['image'] || gallery_images[i]['gallery_image_src']) {

    //   } else {
    //     $.alert("Gallery Image Required")
    //     return;
    //   }
    // }
    // console.log(this.state);
    // return;
    delete partner_details.services;
    delete partner_details.editorState1;
    delete partner_details.editorState2;
    if (partner_details.google_location) {
      partner_details.google_location = partner_details.google_location.replace('width="600"', "width='100%'").replace('style="border:0;"', "").replace('allowfullscreen', "allowFullScreen").replace('referrerpolicy', "referrerPolicy");
    }
    // return;
    const form_data = new FormData();
    for (var i = 0; i < partner_details.gallery_images.length; i++) {
      if (partner_details.gallery_images[i].image || partner_details.gallery_images[i].gallery_image_src) {
     
      } else {
        $.alert("It looks like the gallery image section is incomplete. Please complete this section to continue.");
        return;
      }
    }
    for (var i = 0; i < partner_details.gallery_images.length; i++) {
      
      if (partner_details.gallery_images[i].image || partner_details.gallery_images[i].gallery_image_src) {
        if (partner_details.id) {
          form_data.append('gallery_images_src[' + i + ']', partner_details.gallery_images[i].gallery_image_src); // change this to value
        }
        form_data.append('gallery_images_list[' + i + ']', partner_details.gallery_images[i].image ?? ""); // change this to value
      } else {
        // $.alert("It looks like the gallery image section is incomplete. Please complete this section to continue.");
        return;
      }
      delete partner_details.gallery_images[i]['gallery_image_src_old'];
    }
    form_data.append("partner_details", JSON.stringify(partner_details));
    form_data.append("image", this.state.image);
    form_data.append("cover_image", this.state.cover_image);
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/savePartnerDetails",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data);
      obj_this.context.loading(0);
      if (return_data.status == "insert") {
        obj_this.context.setSession(return_data.session);
        alert("Hi, Thank you for sharing your business information. You will be notified when the administrator approves your business. Please check back later.")
        obj_this.props.history.replace('/Dashboard');
      } else if (return_data.status == "update") {
        alert("Business details Modified.")
        obj_this.props.history.replace('/Dashboard');
      } else if (return_data.status == "exist") {
        $.alert("Business name already exist");
        obj_this.setState({
          business_name: ""
        });
        $("input[name='business_description']").focus();
      } else {
        $.alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function () {
      $.alert("Network error");
    });
  }

  delete_services() {
    if (!window.confirm("Are your sure")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deletePartnerAccount",
      data: this.context.bind_session({ partner_id: this.state.id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.setSession(return_data.session);
        alert("Services deleted");
        obj_this.props.history.replace('/Dashboard');
        // window.location.reload(false);
      } else {
        $.alert("Something Went Wrong. Please Try Again.");
      }
      obj_this.context.loading(0);
    }).fail(function () {
      alert("Network error");
    });
  }

  // otp_form_submit(e){
  //   e.preventDefault();
  //   this.context.loading(1);
  //   var obj_this = this;
  //   $.ajax({
  //     url : process.env.REACT_APP_API + "/api/partnerOTPValidation",
  //     data : {
  //       otp : this.state.otp,
  //       user_id : this.state.user_id
  //     },
  //     type : "POST"
  //   }).done(function(return_data){
  //     console.log(return_data);
  //     obj_this.context.loading(0);
  //     if(return_data.status == "success"){
  //       alert("OTP verified.");
  //       obj_this.context.setSession(return_data.session);
  //       obj_this.props.history.push('/Services');
  //     }else{
  //       obj_this.setState({
  //         otp : ""
  //       });
  //       alert("OTP verification failed.Please try again");
  //     }
  //   }).fail(function(){
  //     alert("Network error");
  //   });
  // }

  render() {
    return (
      <div className="new_partner_page_container page_container" ref={this.new_partner_page_ref}>
        <div className="page_header">
          <div className="title">{this.state.id ? "Edit Service Details" : "Service Provider Registration"}</div>
          <div className="partner_status" style={{ display: this.context.session.partner_status == "PENDING" ? "block" : "none" }}>Status : Admin Approval Pending</div>
        </div>
        <form className="new_partner_form" encType="multipart/form-data" onSubmit={this.form_submit} ref={this.reg_form_ref}>

          <div className="partner_details">
            <div className="">
              <div className="business_details_container block">
                <div className="block_title">Enter your business details</div>
                <InputTheme4
                  label="Business name *"
                  attrs={{
                    name: "business_name",
                    type: "text",
                    value: this.state.business_name,
                    onChange: this.form_change,
                  }}
                  input_ref={this.business_name_ref}
                />
                {/* <InputTheme4
                  label="Business Address *"
                  attrs={{
                    name: "business_address",
                    type: "text",
                    value: this.state.business_address,
                    onChange: this.form_change,
                  }}
                  input_ref={this.business_address_ref}
                /> */}
                <div className="tooltip">Business Address *</div>
                <textarea
                  name="business_address"
                  placeholder=""
                  value={this.state.business_address}
                  onChange={this.form_change}
                  rows={5}
                  ref={this.business_address_ref}
                />
                <InputTheme4
                  label="Locality *"
                  attrs={{
                    name: "locality",
                    type: "text",
                    value: this.state.locality,
                    onChange: this.form_change,
                  }}
                  input_ref={this.locality_ref}
                />
                <FormControl variant="standard" ref={this.country_ref} style={{ display: "grid" }} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                  <InputLabel id="demo-simple-select-standard-label">Country *</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.country}
                    onChange={this.country_change}
                    label="Country"
                  >
                    {this.state.countries && this.state.countries.map((item, idx) => (
                      <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="standard" ref={this.state_ref} style={{ display: "grid" }} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                  <InputLabel id="demo-simple-select-standard-label">State/Province/County/Region {this.state.states.length ? "*" : ""}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.state}
                    onChange={this.state_change}
                    label="State"
                  >
                    {this.state.states && this.state.states.map((item, idx) => (
                      <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <InputTheme4
                  label="City/Town *"
                  attrs={{
                    name: "city",
                    type: "text",
                    value: this.state.city,
                    onChange: this.form_change,
                  }}
                  input_ref={this.city_ref}
                />
                <div className="mobile_no_label" style={{ fontSize: "16px" }} ref={this.phone_no_ref}>Business phone number *</div>
                <PhoneInput
                  placeholder="Phone number *"
                  international
                  value={this.state.business_phone_no}
                  onChange={this.mobile_no_change}
                  defaultCountry={this.state.business_phone_no_code}
                  onCountryChange={(country) => this.onCountryChange(country)}
                />
                <div className="whatsapp_no_label" style={{ fontSize: "16px" }} ref={this.whatsapp_no_ref}>Business whatsapp number *</div>
                <PhoneInput
                  placeholder="Whatsapp number *"
                  international
                  value={this.state.business_whatsapp_no}
                  onChange={this.whatsapp_no_change}
                  defaultCountry={this.state.business_whatsapp_no_code}
                  onCountryChange={(country) => this.whatsappCountryChange(country)}
                />
                <InputTheme4
                  label="Business email address *"
                  attrs={{
                    name: "business_email",
                    type: "email",
                    value: this.state.business_email,
                    onChange: this.form_change,
                  }}
                  input_ref={this.email_ref}
                />
                <div className="alert">* This email address will be used for customer enquiries.</div>
                <div>
                </div>
                <div className="google" ref={this.google_location_ref}>
                  <span className="google_tooltip">Google map URL *</span>
                </div>
                <textarea
                  name="google_location"
                  value={this.state.google_location}
                  onChange={this.form_change}
                  rows={4}
                  // required={true}
                  // ref={this.google_location_ref}
                />
                <div className="image_requirements" ref={this.logo_ref}>
                  Insert Business Logo *<br></br>
                  <span>230px x 230px recommended.
                    JPG or PNG only.</span>
                </div>
                <ImageUpload className="image_upload_component"
                  attrs={{
                    name: "image",
                    accept: "image/png, image/gif, image/jpeg",
                    max_size: 4096,
                  }}
                  onChange={this.image_change}
                  deleteImage={this.delete_image}
                  revertImage={this.revert_image}
                  src={this.state.image_src ? process.env.REACT_APP_CDN_URL + "/partners/logo/" + this.state.image_src : ""}
                />
                <div className="image_requirements" ref={this.cover_ref}>
                  Insert Cover Image *<br></br>
                  <span>16:9 ratio recommended.
                    JPG or PNG only.</span>
                </div>
                <ImageUpload className="image_upload_component"
                  attrs={{
                    name: "image",
                    accept: "image/png, image/gif, image/jpeg",
                    max_size: 4096
                  }}
                  onChange={this.cover_image_change}
                  deleteImage={this.delete_cover_image}
                  revertImage={this.revert_cover_image}
                  src={this.state.cover_image_src ? process.env.REACT_APP_CDN_URL + "/partners/cover_images/" + this.state.cover_image_src : ""}
                />
              </div>
            </div>
            <div>
              <div className="business_descriptions_container block" ref={this.business_description_ref}>
                <div className="block_title">About your business *</div>
                <textarea
                  name="business_description"
                  value={this.state.business_description}
                  placeholder="Your Company Description (Max 400 Characters)"
                  onChange={this.form_change}
                  rows={8}
                />
                {/* <Editor
                editorState={this.state.editorState1}
                toolbarClassName="editor_toolbar"
                wrapperClassName="editor_component"
                editorClassName="editor_input_area"
                onEditorStateChange={this.onEditorState1Change}
                placeholder={"Your company description (Max 600 words)"}
                required={true}
                handlePastedText={() => false}
                stripPastedStyles={true}
                toolbar={{
                  options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                  },list: {
                    options: ['unordered', 'ordered'],
                  },
                  fontSize: {
                    options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
                  },
                }}
              /> */}
                <div className="block_title" ref={this.service_description_ref}>About your services *</div>
                <textarea
                  name="services_description"
                  value={this.state.services_description}
                  placeholder="Your Services Description (Max 600 Characters)"
                  onChange={this.form_change}
                  rows={10}
                />
                {/* <Editor
                editorState={this.state.editorState2}
                toolbarClassName="editor_toolbar"
                wrapperClassName="editor_component"
                editorClassName="editor_input_area"
                onEditorStateChange={this.onEditorState2Change}
                placeholder={"Your services description (Max 600 words)"}
                required={true}
                handlePastedText={() => false}
                stripPastedStyles={true}
                toolbar={{
                  options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                  },list: {
                    options: ['unordered', 'ordered'],
                  },
                  fontSize: {
                    options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
                  },
                }}
              />         */}
              </div>
              <div className="services_container block" ref={this.services_ref}>
                <div className="choose_service_label block_title">Choose services *</div>
                <div className="checkbox_container">
                  {this.state.services && this.state.services.map((item, idx) => (
                    <div className="single_service_container" key={idx}>
                      <input
                        type="checkbox"
                        name="selected_services"
                        value={item.id}
                        onChange={() => this.service_change(item.id)}
                        checked={this.state.selected_services.includes(item.id)}
                      />
                      <div className="service_name">{item.service}</div>
                    </div>
                  ))}
                </div>
                <div className="other_service_details" style={{ display: this.state.selected_services.includes(24) ? "block" : "none" }}>
                  <InputTheme4
                    label="Specify your service"
                    attrs={{
                      name: "other_service",
                      type: "text",
                      value: this.state.other_service,
                      onChange: this.form_change,
                      required: this.state.selected_services.includes(24)
                    }}
                  />
                </div>
              </div>
              <div className="other_details_container block">
                <div className="block_title">Other details</div>
                <div className="alert">* Please include the HTTPS format, for example: https://www.example.com/</div>
                <InputTheme4
                  label="Website"
                  attrs={{
                    name: "website",
                    type: "text",
                    value: this.state.website,
                    onChange: this.form_change,
                  }}
                  input_ref={this.website_ref}
                />
                <InputTheme4
                  label="Business hours"
                  attrs={{
                    name: "business_hours",
                    type: "text",
                    value: this.state.business_hours,
                    onChange: this.form_change,
                  }}
                />
                <InputTheme4
                  label="Facebook URL"
                  attrs={{
                    name: "facebook",
                    type: "text",
                    value: this.state.facebook,
                    onChange: this.form_change,
                  }}
                  input_ref={this.facebook_ref}
                />
                <InputTheme4
                  label="Instagram URL"
                  attrs={{
                    name: "instagram",
                    type: "text",
                    value: this.state.instagram,
                    onChange: this.form_change,
                  }}
                  input_ref={this.instagram_ref}
                />
                <InputTheme4
                  label="Twitter URL"
                  attrs={{
                    name: "twitter",
                    type: "text",
                    value: this.state.twitter,
                    onChange: this.form_change,
                  }}
                  input_ref={this.twitter_ref}
                />
                <InputTheme4
                  label="LinkedIn URL"
                  attrs={{
                    name: "linkedin",
                    type: "text",
                    value: this.state.linkedin,
                    onChange: this.form_change,
                  }}
                  input_ref={this.linkedin_ref}
                />
              </div>

            </div>

          </div>
          <div className="gallery_container block">
            <div className="choose_service_label block_title">Gallery Images</div>
            <div className="other_details_title">Maximum 12 Images ({this.state.gallery_images.length}/12) </div>
            <div className="gallery_image_details_container">
              <div className="gallery_image_details">

                {this.state.gallery_images && this.state.gallery_images.map((item, idx) => (
                  <div className="single_image_container" key={idx} >
                    <div className="">
                      <ImageUpload className="gallery_image"
                        attrs={{
                          name: "gallery_image",
                          accept: "image/png, image/gif, image/jpeg",
                        }}
                        onChange={(e) => { this.gallery_image_change(e, idx) }}
                        // deleteImage = {this.delete_image}
                        // revertImage = {this.revert_image}
                        src={item.gallery_image_src_old ??item.gallery_image_src}
                      />
                    </div>
                    <div className="preview_delete_button_container">
                      <div className="delete_row_button"
                        onClick={(e) => this.delete_gallery_row(idx,e)}
                      >
                        <AiOutlineDelete />
                      </div>
                      <div className="image_preview_button"
                        style={{ display: item.gallery_image_src ? "grid" : "none" }}
                        onClick={() => {
                          this.setState({
                            zoomed_item: item
                          })
                        }}
                      >
                        <BsEyeFill />
                      </div>
                    </div>
                  </div>
                ))}

              </div>
              <div className="button_count_container">
                <button className="other_details_button"
                  type="button"
                  style={{ visibility: this.state.gallery_images.length < 12 ? "visible" : "hidden" }}
                  onClick={this.add_gallery_row}
                ><GrAdd color="green" size={12} /> Add New Image</button>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className="password_error" style={{ visibility: this.state.password_error ? "visible" : "hidden" }}>{this.state.password_error}&nbsp;</div>
            <br></br>
            <div className="buttons_container" style={{ display: this.state.id ? "inline-block" : "none" }}>
              <ButtonTheme1
                label="Delete"
                className="delete_button"
                onClick={this.delete_services}
                type="button"
              ></ButtonTheme1>
            </div>
            <button type="submit" className="submit_button">{this.state.partner_id ? "Modify" : "Submit"}</button>
          </div>
        </form>
        <div className="zoomed_image_ghost" onClick={() => this.setState({ zoomed_item: "" })} style={{ display: this.state.zoomed_item.gallery_image_src_old || this.state.zoomed_item.gallery_image_src ? "block" : "none" }}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()}>
            <div className="image_caption_container">
              <img src={this.state.zoomed_item.gallery_image_src_old??this.state.zoomed_item.gallery_image_src} />
              <div className="close_zoomed_image_button" onClick={() => this.setState({ zoomed_item: "" })}>
                <VscChromeClose />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewPartner;
NewPartner.contextType = MyContext;