import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen((loc,action) => {
      // console.log(loc,action)
      // if(typeof history.location.state == undefined){
      //   return;
      // }
      if (action === "REPLACE") {
        // Do your stuff
        // console.log("skipping scroll restoration");
        return;
      }
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);