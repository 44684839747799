import React from "react";
import "./NoticeFeedbacks.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import {Link} from "react-router-dom";
import { FaAngleRight, FaEye } from "react-icons/fa";
import { FcFullTrash } from "react-icons/fc";
import ReactPaginate from 'react-paginate';

class NoticeFeedbacks extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      death_notice_feedbacks : [],
      family_notice_feedbacks : [],
      memorial_feedbacks : [],
      death_notice_new_feedbacks : "",
      family_notice_new_feedbacks : "",
      memorial_new_feedbacks : "",
      user : {},
      offset_value : 0,
      current_page : 0,
      per_page : 10,
      page_count : 0,
      notice_type : "death_notice",
      page_no_input : 1
    }
    this.notice_feedback_page_ref = React.createRef();
    this.view_visitor_message = this.view_visitor_message.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.getNoticeFeedbacks = this.getNoticeFeedbacks.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.delete_visitor_message = this.delete_visitor_message.bind(this);
  }

  componentDidMount(){
    this.getNoticeFeedbacks();
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    }); 
  }

  page_no_form_submit(e){
    window.scrollTo({
      top : this.notice_feedback_page_ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getNoticeFeedbacks();
    });
  }

  getNoticeFeedbacks(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/noticeFeedbacks",
      data : this.context.bind_session({
        offset_value : this.state.offset_value,
      }),
      type : "POST"
    }).done(function(return_data){
      var page_count = "";
      if(obj_this.state.notice_type == "death_notice"){
        page_count =  Math.ceil(return_data.death_notice_page_count / obj_this.state.per_page);
      }else if(obj_this.state.notice_type == "memorial"){
        page_count = Math.ceil(return_data.memorial_page_count/obj_this.state.per_page);
      }else if(obj_this.state.notice_type == "family_notice"){
        page_count = Math.ceil(return_data.family_notice_page_count/obj_this.state.per_page);
      }
      if(return_data.status == "success"){
        obj_this.setState({
          death_notice_feedbacks : return_data.death_notice_feedbacks,
          family_notice_feedbacks : return_data.family_notice_feedbacks,
          memorial_feedbacks : return_data.memorial_feedbacks,
          death_notice_new_feedbacks : return_data.death_notice_feedbacks.filter((item) => (item.status == "NEW")).length,
          family_notice_new_feedbacks : return_data.family_notice_feedbacks.filter((item) => (item.status == "NEW")).length,
          memorial_new_feedbacks : return_data.memorial_feedbacks.filter((item) => (item.status == "NEW")).length,
          page_count :  page_count,
        }, () => {
          obj_this.context.loading(0);
          obj_this.notice_feedback_page_ref.current.style.visibility = "visible";
          obj_this.notice_feedback_page_ref.current.style.opacity = 1;
        });
     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
  }

  handle_page_click(e){
    window.scrollTo({
      top : this.notice_feedback_page_ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getNoticeFeedbacks()
    });
  }

  view_visitor_message(feedback_id){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/viewNoticeFeedbackDetails",
      data : this.context.bind_session({
        feedback_id : feedback_id,
        offset_value : this.state.offset_value,
      }),
      type : "POST"
    }).done(function(return_data){
      if (return_data.status == "success") {
        var page_count = "";
        if(obj_this.state.notice_type == "death_notice"){
          page_count =  Math.ceil(return_data.death_notice_page_count / obj_this.state.per_page);
        }else if(obj_this.state.notice_type == "memorial"){
          page_count = Math.ceil(return_data.memorial_page_count/obj_this.state.per_page);
        }else if(obj_this.state.notice_type == "family_notice"){
          page_count = Math.ceil(return_data.family_notice_page_count/obj_this.state.per_page);
        }
        obj_this.setState({
          user : return_data.feedback_details,
          death_notice_feedbacks : return_data.death_notice_feedbacks,
          family_notice_feedbacks : return_data.family_notice_feedbacks,
          memorial_feedbacks : return_data.memorial_feedbacks,
          death_notice_new_feedbacks : return_data.death_notice_feedbacks.filter((item) => (item.status == "NEW")).length,
          family_notice_new_feedbacks : return_data.family_notice_feedbacks.filter((item) => (item.status == "NEW")).length,
          memorial_new_feedbacks : return_data.memorial_feedbacks.filter((item) => (item.status == "NEW")).length,
          page_count :  page_count,
        },() => {
          // obj_this.view_notice_feedback_page_ref.current.style.visibility = "visible";
        });
        
        obj_this.context.loading(0);
      } else {
        alert("Network error!!")
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  delete_visitor_message(feedback_id){
    if(!window.confirm("Delete message?")){
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/deleteNoticeFeedbackDetails",
      data : this.context.bind_session({
        feedback_id : feedback_id,
        offset_value : this.state.offset_value,
      }),
      type : "POST"
    }).done(function(return_data){
      if (return_data.status == "success") {
        if((obj_this.state.notice_type == "death_notice" && !return_data.death_notice_feedbacks.length) ||( obj_this.state.notice_type == "memorial" && !return_data.memorial_feedbacks.length) || (obj_this.state.notice_type == "family_notice" && !return_data.family_notice_feedbacks.length)){
          obj_this.maximize_block(obj_this.state.notice_type)
        }
        var page_count = "";
        if(obj_this.state.notice_type == "death_notice"){
          page_count =  Math.ceil(return_data.death_notice_page_count / obj_this.state.per_page);
        }else if(obj_this.state.notice_type == "memorial"){
          page_count = Math.ceil(return_data.memorial_page_count/obj_this.state.per_page);
        }else if(obj_this.state.notice_type == "family_notice"){
          page_count = Math.ceil(return_data.family_notice_page_count/obj_this.state.per_page);
        }
        obj_this.setState({
          user : return_data.feedback_details,
          death_notice_feedbacks : return_data.death_notice_feedbacks,
          family_notice_feedbacks : return_data.family_notice_feedbacks,
          memorial_feedbacks : return_data.memorial_feedbacks,
          death_notice_new_feedbacks : return_data.death_notice_feedbacks.filter((item) => (item.status == "NEW")).length,
          family_notice_new_feedbacks : return_data.family_notice_feedbacks.filter((item) => (item.status == "NEW")).length,
          memorial_new_feedbacks : return_data.memorial_feedbacks.filter((item) => (item.status == "NEW")).length,
          page_count :  page_count,
        },() => {
          obj_this.notice_feedback_page_ref.current.style.visibility = "visible";
          obj_this.notice_feedback_page_ref.current.style.opacity = 1;
        });
        
        obj_this.context.loading(0);
      } else {
        alert("Network error!!")
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  maximize_block(block){
    this.setState({
      offset_value : 0,
      current_page : 0,
      notice_type : block,
      page_no_input : 1
    },() => {
      this.getNoticeFeedbacks();
    });
    $(".table_container").css("display","none");
    $("."+block).css("display","block");
    $(".single_toggle").removeClass("single_toggle_selected");
    $("."+block+"_toggle").addClass("single_toggle_selected");

  }

  minimize_block(block){
    $("."+block).css({"height":"0px"});
    $("."+block+"_button").find(".maximize_button").css("display","inline-block");
    $("."+block+"_button").find(".minimize_button").css("display","none");
  }

  render(){
    return(
      <div className="notice_feedback_page_container page_container" ref={this.notice_feedback_page_ref}>
        <div className="page_header">
          <div className="page_title">Visitor Messages</div>
          <div className="new_pet_tribute_button_container">
          </div>
        </div>
        <div className="toggles_container">
          <div 
            className="single_toggle single_toggle_selected death_notice_toggle"
            onClick={() => this.maximize_block("death_notice")}
          >
            <span>Death Notice </span>
            <span className="pending_count" style={{display:this.state.death_notice_new_feedbacks>0?"inline-block":"none"}}><i className="fa fa-solid fa-circle"></i></span>
          </div>
          <div className="single_toggle memorial_toggle"
            onClick={() => this.maximize_block("memorial")}
          >
            <span>Memorial </span>
            <span className="pending_count" style={{display:this.state.memorial_new_feedbacks>0?"inline-block":"none"}}><i className="fa fa-solid fa-circle"></i></span>
          </div>
          <div className="single_toggle family_notice_toggle"
            onClick={() => this.maximize_block("family_notice")}
          >
            <span>In Memory Notice </span>
            <span className="pending_count" style={{display:this.state.family_notice_new_feedbacks>0?"inline-block":"none"}}><i className="fa fa-solid fa-circle"></i></span>
          </div>
        </div>
        <div className="table_container death_notice">
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no" style={{width:"100px"}}>Sl No.</div>
              <div className="table_cell fullname">Name</div>
              <div className="table_cell notice_type">Email</div>
              <div className="table_cell notice_type">Notice</div>
              <div className="table_cell ">Status</div>
              <div className="table_cell ">Date</div>
              <div className="table_cell ">Action</div>
              
            </div>
            {this.state.death_notice_feedbacks && this.state.death_notice_feedbacks.map((item,idx) => (
              <div className="table_row body"
                key={idx}
              >
                <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
                <div className="table_cell">{item.name}</div>
                <div className="table_cell notice_type">{item.email}</div>
                <div className="table_cell notice_type">{item.first_name} {item.surname}</div>
                <div className="table_cell notice_type">
                <div
                    className="status_button"
                    style={{
                      backgroundColor:
                        item.status == "NEW"
                          ? "#fae69d"
                          : "#e3f9dd",
                      color:
                        item.status == "VIEWED"
                          ? "#229a16"
                          : "rgb(240, 137, 26)",
                    }}
                  >
                    {item.status}
                  </div>
                  </div>
                <div className="table_cell">{new Date(item.created_at).toLocaleDateString('en-GB')}</div>
                <div className="table_cell" style={{minWidth:"115px"}}>
                  <div className="action">
                    <div className="tooltip approve" onClick={() => this.view_visitor_message(item.id)}>
                      <span className="tooltipText approve">View</span>
                      <FaEye
                        className="approve_button"/>
                    </div>
                    <div className="tooltip delete" style={{display:item.status == "NEW"?"none":"inline-block"}} onClick={() => this.delete_visitor_message(item.id)}>
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button"/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="table_row no_results" style={{display:this.state.death_notice_feedbacks.length?"none":"block"}}>No results.</div>
          </div>
          <div className="pagination_container" style={{display:this.state.death_notice_feedbacks.length && this.state.page_count > 1?"block":"none"}}>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handle_page_click}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.current_page}
            />
            <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
              <span className="page_count">Go to page</span>
              <input className="page_no_input"
                type="number"
                name="page_count"
                value={this.state.page_no_input}
                onChange={this.page_no_change}
                min={1}
                max={this.state.page_count}
              />
              <button type="submit" className="page_no_search_button">
                <span>Go</span>
                <FaAngleRight/>
              </button>
            </form>
          </div>
        </div>
      
        <div className="table_container memorial">
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no" style={{width:"100px"}}>Sl No.</div>
              <div className="table_cell fullname">Name</div>
              <div className="table_cell notice_type">Email</div>
              <div className="table_cell notice_type">Notice</div>
              <div className="table_cell ">Status</div>
              <div className="table_cell ">Date</div>
              <div className="table_cell">Action</div>
            </div>
            {this.state.memorial_feedbacks && this.state.memorial_feedbacks.map((item,idx) => (
              <div className="table_row body"
                key={idx}
              >
                <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
                <div className="table_cell">{item.name}</div>
                <div className="table_cell notice_type">{item.email}</div>
                <div className="table_cell notice_type">{item.first_name} {item.surname}</div>
                <div className="table_cell notice_type">
                  <div
                    className="status_button"
                    style={{
                      backgroundColor:
                        item.status == "NEW"
                          ? "#fae69d"
                          : "#e3f9dd",
                      color:
                        item.status == "VIEWED"
                          ? "#229a16"
                          : "rgb(240, 137, 26)",
                    }}
                  >
                    {item.status}
                  </div>
                  </div>
                <div className="table_cell">{new Date(item.created_at).toLocaleDateString('en-GB')}</div>
                <div className="table_cell" style={{minWidth:"115px"}}>
                  <div className="action">
                    <div className="tooltip approve" onClick={() => this.view_visitor_message(item.id)}>
                      <span className="tooltipText approve">View</span>
                      <FaEye
                        className="approve_button"/>
                    </div>
                    <div className="tooltip delete" style={{display:item.status == "NEW"?"none":"inline-block"}} onClick={() => this.delete_visitor_message(item.id)} >
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button"/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="table_row no_results" style={{display:this.state.memorial_feedbacks.length?"none":"block"}}>No results.</div>
          </div>
          <div className="pagination_container" style={{display:this.state.memorial_feedbacks.length && this.state.page_count > 1?"block":"none"}}>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handle_page_click}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.current_page}
            />
             <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span>
              <FaAngleRight/>
            </button>
          </form>
          </div>
        </div>

        <div className="table_container family_notice">
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no" style={{width:"100px"}}>Sl No.</div>
              <div className="table_cell fullname">Name</div>
              <div className="table_cell notice_type">Email</div>
              <div className="table_cell notice_type">Notice</div>
              <div className="table_cell ">Status</div>
              <div className="table-cell">Date</div>
              <div className="table_cell">Action</div>
            </div>
            {this.state.family_notice_feedbacks && this.state.family_notice_feedbacks.map((item,idx) => (
              <div className="table_row body"
                key={idx}
              >
                <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
                <div className="table_cell">{item.name}</div>
                <div className="table_cell notice_type">{item.email}</div>
                <div className="table_cell notice_type">{item.first_name} {item.surname}</div>
                <div className="table_cell notice_type">
                  <div
                    className="status_button"
                    style={{
                      backgroundColor:
                        item.status == "NEW"
                          ? "#fae69d"
                          : "#e3f9dd",
                      color:
                        item.status == "VIEWED"
                          ? "#229a16"
                          : "rgb(240, 137, 26)",
                    }}
                  >
                    {item.status}
                  </div>
                </div>
                <div className="table_cell">{new Date(item.created_at).toLocaleDateString('en-GB')}</div>
                <div className="table_cell" style={{minWidth:"115px"}}>
                  <div className="action">
                    <div className="tooltip approve" onClick={() => this.view_visitor_message(item.id)}>
                      <span className="tooltipText approve">View</span>
                      <FaEye
                        className="approve_button"/>
                    </div>
                    <div className="tooltip delete" style={{display:item.status == "NEW"?"none":"inline-block"}} onClick={() => this.delete_visitor_message(item.id)} >
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button"/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="table_row no_results" style={{display:this.state.family_notice_feedbacks.length?"none":"block"}}>No results.</div>
          </div>
          <div className="pagination_container" style={{display:this.state.family_notice_feedbacks.length && this.state.page_count > 1?"block":"none"}}>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handle_page_click}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.current_page}
            />
             <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span>
              <FaAngleRight/>
            </button>
          </form>
          </div>
        </div>
    
        <div className="view_message_ghost" style={{display:this.state.user.name?"block":"none"}}>
          <div className="view_message" 
          >
          <div className="add_message_title">Visitor Message</div>
            <div className="details_grid">
              <div className="label">Name</div>
              <div className="value">{this.state.user.name}</div>
              <div className="label">Email</div>
              <div className="value">{this.state.user.email}</div>
              <div className="label">Message</div>
              <div className="value">{this.state.user.message}</div>
              <div className="label">Notice Page Link</div>
              <div className="value">
                <Link style={{color:"#7b40c1"}} target="_blank" to={this.state.user.notice_type == "death_notice"?"/DeathNotices/"+this.state.user.notice_id:this.state.user.notice_type == "memorial"?"/Memorials/"+this.state.user.notice_id:this.state.user.notice_type == "family_notice"?"/InMemoryNotices/"+this.state.user.notice_id:""}>
                {this.state.user.notice_type == "death_notice"?process.env.REACT_APP_URL+"/DeathNotices/"+this.state.user.notice_id:this.state.user.notice_type == "memorial"?process.env.REACT_APP_URL+"/Memorials/"+this.state.user.notice_id:this.state.user.notice_type == "family_notice"?process.env.REACT_APP_URL+"/InMemoryNotices/"+this.state.user.notice_id:""}
                </Link>  
              </div>
            </div>
            <div className="close_message_form"
              onClick={() => {
                this.setState({
                  user  : {}
                })
              }}
            >
              <div>x</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NoticeFeedbacks;
NoticeFeedbacks.contextType = MyContext;