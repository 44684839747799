import React from "react";
import './CustomSlider.scss';
import left_arrow_icon from "./images/left_arrow.png";
import right_arrow_icon from "./images/right_arrow.png";

class CustomSlider extends React.Component{
    constructor(props){
        super(props);

        this.interval=("interval" in this.props)?this.props.interval:2500;
        this.transitionTime=("transitionTime" in this.props)?this.props.interval:800;
        
        if(this.interval<this.transitionTime)
            this.transitionTime = this.interval;

        this.state={
            view_index: 1,
            sliding: 0,
            loop_index: 0,
            container_1_idx: Array.isArray(this.props.children)?this.props.children.length-1:0,
            container_2_idx: 0,
            container_3_idx: Array.isArray(this.props.children)?1:0,
            transitionTime:(this.transitionTime/1000)+"s"
        };
        this.sliding = 0;
        this.click_hold = 0;
        this.clickX_begin = 0;
        this.swiped_percentage = 0;

        this.window_focus = true;

        this.scroll_left = this.scroll_left.bind(this);
        this.scroll_right = this.scroll_right.bind(this);
        this.click_down = this.click_down.bind(this);
        this.click_release = this.click_release.bind(this);
        this.click_drag = this.click_drag.bind(this);
        this.start_auto_sliding = this.start_auto_sliding.bind(this);
        this.stop_auto_sliding = this.stop_auto_sliding.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.custom_slider_ref = React.createRef();
    }
    componentDidMount(){
        this.start_auto_sliding();
        window.addEventListener("focus", this.onFocus);
        window.addEventListener("blur", this.onBlur);
    }

    componentWillUnmount(){
        clearInterval(this.auto_slide_interval);
        window.removeEventListener("focus", this.onFocus)
        window.removeEventListener("blur", this.onBlur)
    }
    onFocus(){
        // console.log("focus");
        this.window_focus = true;
    }
    onBlur(){
        // console.log("blur "+this.window_focus);
        this.window_focus = false;
    }
    

    start_auto_sliding(){
        if(this.props.autoPlay==false)
            return;
        // console.log("auto sliding started");
        this.auto_slide_interval=setInterval(()=>{
            // console.log("auto sliding "+this.window_focus);
            if(this.window_focus)
                this.scroll_right();
        },this.interval);
    }

    stop_auto_sliding(){
        // console.log("auto sliding stopped");
        clearInterval(this.auto_slide_interval);
    }

    scroll_left(){
        // console.log("before left "+this.state.loop_index);
        if(!Array.isArray(this.props.children)||this.sliding)
            return;

        this.setState({sliding : 1});
        this.sliding = 1;
        const left_container = this.custom_slider_ref.current.querySelector("#content_container_left");
        const center_container = this.custom_slider_ref.current.querySelector("#content_container_center");
        const right_container = this.custom_slider_ref.current.querySelector("#content_container_right");

        right_container.style.visibility = "hidden";

        left_container.id = "content_container_center";
        center_container.id = "content_container_right";
        right_container.id = "content_container_left";

        setTimeout(() => {
            const center_container_idx = parseInt(left_container.getAttribute("idx"));
            const left_container_idx = (center_container_idx-1==0)?3:center_container_idx-1;
            
            --this.state.loop_index;
            if(this.state.loop_index == -1)
                this.state.loop_index = this.props.children.length-1;
    
            this.setState({
                ["container_"+left_container_idx+"_idx"]:(this.state.loop_index-1==-1)?this.props.children.length-1:this.state.loop_index-1,
            });

            this.setState({sliding : 0});
            this.sliding = 0;
            right_container.style.visibility = "";
        }, this.transitionTime);
    }
    
    scroll_right(){
        if(!Array.isArray(this.props.children)||this.sliding)
            return;
        this.setState({sliding : 1});
        this.sliding = 1;
        // console.log(this.state.sliding);

        const left_container = this.custom_slider_ref.current.querySelector("#content_container_left");
        const center_container = this.custom_slider_ref.current.querySelector("#content_container_center");
        const right_container = this.custom_slider_ref.current.querySelector("#content_container_right");

        left_container.style.visibility = "hidden";

        left_container.id = "content_container_right";
        center_container.id = "content_container_left";
        right_container.id = "content_container_center";

        setTimeout(() => {
            const center_container_idx = parseInt(right_container.getAttribute("idx"));
            const right_container_idx = (center_container_idx+1==4)?1:center_container_idx+1;
            
            ++this.state.loop_index;
            if(this.state.loop_index == this.props.children.length)
                this.state.loop_index = 0;
    
            this.setState({
                ["container_"+right_container_idx+"_idx"]:(this.state.loop_index+1==this.props.children.length)?0:this.state.loop_index+1,
            });

            this.setState({sliding : 0});
            this.sliding = 0;
            left_container.style.visibility = "";
        }, this.transitionTime);
    }

    click_down(method,e){
        if(e.target.className=="right_arrow" || e.target.className=="left_arrow"){
            console.log("arrow");
            return;
        }
        
        this.click_hold = 1;
        this.stop_auto_sliding();

        if(method == "mouse")
            this.clickX_begin = e.pageX;
        else
            this.clickX_begin = e.touches[0].pageX;

        const left_container = this.custom_slider_ref.current.querySelector("#content_container_left");
        const center_container = this.custom_slider_ref.current.querySelector("#content_container_center");
        const right_container = this.custom_slider_ref.current.querySelector("#content_container_right");

        left_container.style.transitionDuration = "0.05s";
        center_container.style.transitionDuration = "0.05s";
        right_container.style.transitionDuration = "0.05s";
    }

    click_drag(method,e){

        if(!this.click_hold || !Array.isArray(this.props.children))
            return;
            
        var distance;
        if(method == "mouse")
            distance = e.pageX - this.clickX_begin;
        else
            distance = e.touches[0].pageX - this.clickX_begin;

        // console.log(distance);
        const ignore_dist=30;

        if(distance>-ignore_dist && distance<ignore_dist)
            return;

        distance = distance<0?distance+ignore_dist:distance-ignore_dist;

        const container_width = this.custom_slider_ref.current.offsetWidth;
        const percentage = (distance/container_width)*100;

        const left_container = this.custom_slider_ref.current.querySelector("#content_container_left");
        const center_container = this.custom_slider_ref.current.querySelector("#content_container_center");
        const right_container = this.custom_slider_ref.current.querySelector("#content_container_right");

        left_container.style.left=(percentage-100)+"%";
        center_container.style.left=percentage+"%";
        right_container.style.left=(100+percentage)+"%";

        this.swiped_percentage = percentage;

    }

    click_release(){

        this.click_hold = 0;
        this.start_auto_sliding();

        const left_container = this.custom_slider_ref.current.querySelector("#content_container_left");
        const center_container = this.custom_slider_ref.current.querySelector("#content_container_center");
        const right_container = this.custom_slider_ref.current.querySelector("#content_container_right");

        left_container.style.transitionDuration = this.state.transitionTime;
        center_container.style.transitionDuration = this.state.transitionTime;
        right_container.style.transitionDuration = this.state.transitionTime;
        
        left_container.style.left = "";
        center_container.style.left = "";
        right_container.style.left = "";
        
        if(this.swiped_percentage<-50){
            this.scroll_right();
        }else if(this.swiped_percentage>50){
            this.scroll_left();
        };
        this.swiped_percentage = 0;
    }
    render(){
        const children = this.props.children;
        return(
            <div ref={this.custom_slider_ref} className={"custom_slider_container "+this.props.className??""}

            onMouseDown={(e)=>{this.click_down("mouse",e);}}
            onTouchStart={(e)=>{this.click_down("touch",e);}}

            onMouseMove={(e)=>{this.click_drag("mouse",e);}}
            onTouchMove={(e)=>{this.click_drag("touch",e);}}

            onMouseUp={this.click_release}
            onTouchEnd={this.click_release}

            style={this.state.sliding?{pointerEvents:"none"}:{}}>
                
                <div id="content_container_left" idx="1"
                style={{
                    // backgroundColor:"red",
                    transitionDuration:this.state.transitionTime
                }}
                >
                    {children[this.state.container_1_idx]}
                </div>
                <div id="content_container_center" idx="2"
                style={{
                    // backgroundColor:"green",
                    transitionDuration:this.state.transitionTime
                }}
                >
                    {Array.isArray(children)?children[this.state.container_2_idx]:children}
                </div>
                <div id="content_container_right" idx="3"
                style={{
                    // backgroundColor:"blue",
                    transitionDuration:this.state.transitionTime
                }}
                >
                    {children[this.state.container_3_idx]}
                </div>
            
                {(this.props.arrows!=false)?
                <>

                <div
                className="left_arrow"
                onClick={this.scroll_left}
                style={{backgroundImage:"url("+left_arrow_icon+")"}}
                ></div>

                <div
                className="right_arrow"
                onClick={this.scroll_right}
                style={{backgroundImage:"url("+right_arrow_icon+")"}}
                ></div>

                </>
                :""}
           </div>
        )
    }

}

export default CustomSlider;