import React from "react";
import "./NewPetTribute.scss";
import { InputTheme4, InputTheme1, InputTheme3, TextAreaTheme1} from "../../../res/styler/InputThemes/InputThemes";
import ImageUpload from "../../../res/styler/ImageUpload/ImageUpload";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField';
import $ from "jquery";
import MyContext from "../../MyContext";
import { Link } from "react-router-dom";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import {RiImageEditLine,RiDeleteBinLine} from "react-icons/ri";
import ImageEditor from "../../../res/styler/ImageEditor/ImageEditor";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";

class NewPetTribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pet_tribute_id : "",
      name: "",
      breed: "",
      animal: "",
      // age: "",
      colour : "",
      pet_name: "",
      gender : "",
      favourite_activity : "",
      favourite_food : "",
      favourite_place: "",
      temperament: "",
      likes: "",
      dislikes: "",
      date_of_birth : "",
      date_of_death : "",
      title : "",
      content : "",
      status : "",
      file : "",
      image1: "",
      image_src1: "",
      image2: "",
      image_src2: "",
      image3: "",
      image_src3: "",
      animals : [],
      profile_pic_delete_flag : 0,
      years : "",
      months : "",
      editorState: EditorState.createEmpty(),
      age : ""
    }
    this.getBlob = this.getBlob.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.notice_type_change = this.notice_type_change.bind(this);
    this.form_change = this.form_change.bind(this);
    this.animal_change = this.animal_change.bind(this);
    this.year_change = this.year_change.bind(this);
    this.month_change = this.month_change.bind(this);
    this.calculate_age = this.calculate_age.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.new_pet_tribute_page_ref = React.createRef();
    this.image_editor_ref = React.createRef();
    this.name_ref = React.createRef();
    this.breed_ref = React.createRef();
    this.animal_type_ref = React.createRef();
    this.pet_name_ref = React.createRef();
    this.gender_ref = React.createRef();
    this.favourite_activity_ref = React.createRef();
    this.favourite_food_ref = React.createRef();
    this.favourite_place_ref = React.createRef();
    this.likes_ref = React.createRef();
    this.dislikes_ref = React.createRef();
    this.date_of_death_ref = React.createRef();
    this.date_of_birth_ref = React.createRef();
    this.about_pet_ref = React.createRef();
  }

  componentDidMount() {
    var obj_this = this;
    const pet_tribute_id = this.props.match.params.pet_tribute_id;
    if(pet_tribute_id){
      this.context.loading(1);
      this.setState({
        pet_tribute_id : pet_tribute_id
      });
      $.ajax({
        url: process.env.REACT_APP_API + "/api/editPetTribute",
        data: this.context.bind_session({pet_tribute_id: pet_tribute_id}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          // console.log(return_data.pet_tribute.date_of_death)
          return_data.pet_tribute.date_of_death = return_data.pet_tribute.date_of_death?new Date(return_data.pet_tribute.date_of_death):"";
          // return_data.pet_tribute.date_of_birth = new Date(return_data.pet_tribute.date_of_birth);
          const contentBlock = htmlToDraft(return_data.pet_tribute.content);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return_data.pet_tribute.editorState = editorState;
          }
          obj_this.setState(return_data.pet_tribute,() => {
            obj_this.new_pet_tribute_page_ref.current.style.visibility = "visible";
            obj_this.new_pet_tribute_page_ref.current.style.opacity = 1;
          });
          obj_this.context.loading(0);
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }else{
      this.context.loading(1);
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getAnimals",
        data: this.context.bind_session({}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState({
            animals : return_data.animals
          },() => {
            obj_this.new_pet_tribute_page_ref.current.style.visibility = "visible";
            obj_this.new_pet_tribute_page_ref.current.style.opacity = 1;
          });
          obj_this.context.loading(0);
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }
    
    $("form input[type=text]").on("keypress", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        $(this).nextAll("input:first").trigger("focus");
      }
    });
  }

  getBlob(blob){
    // pass blob up from the ImageCropper component
    this.setState({
      file : blob
    });
    // console.log(blob)
  }

  delete_image(){
    this.setState({
      profile_pic_delete_flag : 1,
      image_src1 : null,
      file : ""
    });
    console.log("delete")
  }

  animal_change(e){
    this.setState({
      animal : e.target.value
    })
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  notice_type_change(e) {
    this.setState({
      notice_type: e.target.value
    });
  };

  year_change(e) {
    this.setState({
      years: e.target.value,
    });
  };

  month_change(e) {
    this.setState({
      months: e.target.value,
    });
  };

  calculate_age(date_of_birth,date_of_death){
    var months,age = "";
    months = (new Date(date_of_death).getFullYear() - new Date(date_of_birth).getFullYear()) * 12;
    months -= new Date(date_of_birth).getMonth();
    months += new Date(date_of_death).getMonth();
    months = months <= 0 ? 0 : months;
    if(isNaN(months) || months == 0){
      this.setState({
        age : ""
      });
      return;
    }
    if(months >= 12){
      var years = Math.floor(months/12);
      if(years){
        years = years>1?years+ " Years":years+ " Year";
      }else{
        years = "";
      }
      months = months%12;
      if(months){
        months = months>1?months+" Months":months+" Month";
      }else{
        months = "";
      }
      age = years+" "+months
    }else{
      age = months+ " months";
    }
    this.setState({
      age : age
    });
  }

  onEditorStateChange(value){
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
        editorState :value,
        content : draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };
  

  form_submit(e) {
    e.preventDefault();
    // console.log(this.state.file);
    if(this.state.file || this.state.image_src1){
      
    }else{
      $.alert("Profile Image Required");
      window.scrollTo({
        top : this.image_editor_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(!this.state.name){
      $.alert("Name Required");
      window.scrollTo({
        top : this.name_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.name_ref.current).addClass('input_theme4_SELECTED')
      return;
    }
    if(!this.state.breed){
      $.alert("Breed Required");
      window.scrollTo({
        top : this.breed_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.breed_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.animal){
      $.alert("Category Required");
      window.scrollTo({
        top : this.animal_type_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.animal_type_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.pet_name){
      $.alert("Pet Name Required");
      window.scrollTo({
        top : this.pet_name_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.pet_name_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.gender){
      $.alert("Gender Required");
      window.scrollTo({
        top : this.gender_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.gender_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.favourite_activity){
      $.alert("Favourite Activity Required");
      window.scrollTo({
        top : this.favourite_activity_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.favourite_activity_ref.current).addClass('textarea_theme1_SELECTED')
      return;
    }

    if(!this.state.favourite_food){
      $.alert("Favourite Food Required");
      window.scrollTo({
        top : this.favourite_food_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.favourite_food_ref.current).addClass('textarea_theme1_SELECTED')
      return;
    }

    if(!this.state.favourite_place){
      $.alert("Favourite Place Required");
      window.scrollTo({
        top : this.favourite_place_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.favourite_place_ref.current).addClass('textarea_theme1_SELECTED')
      return;
    }

    if(!this.state.likes){
      $.alert("Likes Required");
      window.scrollTo({
        top : this.likes_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.likes_ref.current).addClass('textarea_theme1_SELECTED')
      return;
    }

    if(!this.state.dislikes){
      $.alert("Dislikes Required");
      window.scrollTo({
        top : this.dislikes_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.dislikes_ref.current).addClass('textarea_theme1_SELECTED')
      return;
    }
    
    if(!this.state.date_of_birth){
      $.alert("Birth Date Required");
      window.scrollTo({
        top : this.date_of_birth_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(!this.state.date_of_death){
      $.alert("Death Date Required");
      window.scrollTo({
        top : this.date_of_death_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(Date.parse(this.state.date_of_death) < Date.parse(this.state.date_of_birth)){
      $.alert("Invalid Death Date or Birth Date");
      window.scrollTo({
        top : this.date_of_death_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    } 
    if(!this.state.editorState.getCurrentContent().hasText()){
      $.alert("Write a few words about "+ this.state.pet_name);
      window.scrollTo({
        top : this.about_pet_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }

    const pet_tribute_details = this.state;
    delete pet_tribute_details.editorState;
    pet_tribute_details.date_of_death = pet_tribute_details.date_of_death?pet_tribute_details.date_of_death.toLocaleDateString('en-CA',{ day: '2-digit', month: '2-digit', year: 'numeric'}):"";
    // pet_tribute_details.date_of_birth = pet_tribute_details.date_of_birth ? pet_tribute_details.date_of_birth.toLocaleDateString() : "";
    delete pet_tribute_details.animals;
    console.log(pet_tribute_details);
    const form_data = new FormData();
    form_data.append("pet_tribute_details", JSON.stringify(pet_tribute_details));
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image1", this.state.file);
    // form_data.append("image2", this.state.image2);
    // form_data.append("image3", this.state.image3);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/savePetTribute",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data);
      // return;
      if (return_data.status == "insert") {
        obj_this.context.loading(0);
        if(obj_this.context.session.user_type == "admin"){
          alert("Pet Tribute created");
        }else{
          alert("Admin Approval Awaiting. Please check back later.");
        }
        obj_this.props.history.push(obj_this.props.location.url ?? "/Dashboard/PetTributes/MyEntries");
      }else if (return_data.status == "update") {
        obj_this.context.loading(0);
        alert("Pet Tribute updated.");
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard/PetTributes/MyEntries",
          state : obj_this.props.location.previousState??""
        });
      }else{
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    })
  }

  render() {
    return (
      <div className="new_pet_tribute_page_container page_container" ref={this.new_pet_tribute_page_ref}>
        <form className="new_pet_tribute_form" encType="multipart/form-data" onSubmit={this.form_submit}>
          <div className="page_header">
            <div className="page_title">{this.state.pet_tribute_id?"Edit":"Create"} Pet Tribute</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/PetTributes">Pet Tributes</Link>/
              <span>{this.state.pet_tribute_id?"Edit":"Create"}</span>
            </div> */}
          </div>
          <div className="pet_tribute_details">
            <div className="image_editor_container" ref={this.image_editor_ref}>
              <ImageEditor
                getBlob={this.getBlob}
                image_src = {this.state.image_src1}
                current_image = {process.env.REACT_APP_CDN_URL+"/"+this.state.entered_by+"/pet_tribute_images/"+this.state.image_src1}
                deleteImageSrc = {this.delete_image}
                image_width = {210}
                image_height = {210}
              />
            </div>
            <div className="basic_details">
              <InputTheme4
                label="Name *"
                attrs={{
                  name: "name",
                  type: "text",
                  value: this.state.name,
                  onChange: this.form_change,
                }}
                input_ref={this.name_ref}
              />
              <InputTheme4
                label="Breed *"
                attrs={{
                  name: "breed",
                  type: "text",
                  value: this.state.breed,
                  onChange: this.form_change,
                }}
                input_ref={this.breed_ref}
              />
              <FormControl variant="standard" ref={this.animal_type_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">Select Category *</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.animal}
                  onChange={this.animal_change}
                  label="State *"
                >
                  {this.state.animals && this.state.animals.map((item, idx) => (
                    <MenuItem value={item.id} key={idx}>{item.animal}</MenuItem>
                  ))}
                </Select>
              </FormControl>
               
              {/* <InputTheme4
                label="Colour *"
                attrs={{
                  name: "colour",
                  type: "text",
                  value: this.state.colour,
                  onChange: this.form_change,
                  required: true
                }}
              /> */}
              <InputTheme4
                label="Pet Name(s) *"
                attrs={{
                  name: "pet_name",
                  type: "text",
                  value: this.state.pet_name,
                  onChange: this.form_change,
                }}
                input_ref = {this.pet_name_ref}
              />
              <InputTheme4
                label="Gender *"
                attrs={{
                  name: "gender",
                  type: "text",
                  value: this.state.gender,
                  onChange: this.form_change,
                }}
                input_ref = {this.gender_ref}
              />
              {/* <TextAreaTheme1
                label="Favourite Activity *"
                attrs={{
                  name: "favourite_activity",
                  value: this.state.favourite_activity,
                  onChange: this.form_change,
                }}
                input_ref={this.favourite_activity_ref}
              /> */}
             <div ref={this.favourite_activity_ref}> 
             <div className="tooltip">Favourite Activity *</div>
               <textarea 
                  name="favourite_activity" 
                  value={this.state.favourite_activity}
                  onChange={this.form_change}
                  rows={5} 
                  ref={this.favourite_activity_ref}
                />
                </div>
              {/* <TextAreaTheme1
                label="Favourite Food *"
                attrs={{
                  name: "favourite_food",
                  value: this.state.favourite_food,
                  onChange: this.form_change,
                }}
                input_ref={this.favourite_food_ref}
              /> */}
              <div ref={this.favourite_food_ref}> 
             <div className="tooltip">Favourite Food *</div>
              <textarea 
                  name="favourite_food" 
                  value={this.state.favourite_food}
                  onChange={this.form_change}
                  rows={5} 
                  ref={this.favourite_food_ref}
                />
                </div>
              {/* <TextAreaTheme1
                label="Favourite Place *"
                attrs={{
                  name: "favourite_place",
                  value: this.state.favourite_place,
                  onChange: this.form_change,
                }}
                input_ref={this.favourite_place_ref}
              /> */}
              <div ref={this.favourite_place_ref}> 
             <div className="tooltip">Favourite Place *</div>
              <textarea 
                  name="favourite_place" 
                  value={this.state.favourite_place}
                  onChange={this.form_change}
                  rows={5} 
                  ref={this.favourite_place_ref}
                />
                </div>
              {/* <InputTheme4
                label="Temperament"
                attrs={{
                  name: "temperament",
                  type: "text",
                  value: this.state.temperament,
                  onChange: this.form_change,
                  required: false
                }}
              /> */}
              {/* <TextAreaTheme1
                label="Likes *"
                attrs={{
                  name: "likes",
                  value: this.state.likes,
                  onChange: this.form_change,
                }}
                input_ref = {this.likes_ref}
              /> */}
              <div ref={this.likes_ref}> 
             <div className="tooltip">Likes *</div>
              <textarea 
                  name="likes" 
                  value={this.state.likes}
                  onChange={this.form_change}
                  rows={5} 
                  ref={this.likes_ref}
                />
                </div>
              {/* <TextAreaTheme1
                label="Dislikes *"
                attrs={{
                  name: "dislikes",
                  value: this.state.dislikes,
                  onChange: this.form_change,
                }}
                input_ref={this.dislikes_ref}
              /> */}
               <div ref={this.dislikes_ref}> 
             <div className="tooltip">Dislikes *</div>
              <textarea 
                  name="dislikes" 
                  value={this.state.dislikes}
                  onChange={this.form_change}
                  rows={5} 
                  ref={this.dislikes_ref}
                />
                </div>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker ref={this.date_of_birth_ref}
                  label="Born *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                    }
                  }}
                  value={this.state.date_of_birth}
                  onChange={(newValue) => {
                    this.setState({ 
                      date_of_birth: newValue,
                    });
                    this.calculate_age(newValue, this.state.date_of_death);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  allowSameDateSelection={true}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker ref={this.date_of_death_ref}
                  label="Passed Away *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                    }
                  }}
                  value={this.state.date_of_death}
                  onChange={(newValue) => {
                    this.setState({
                      date_of_death: newValue,
                    });
                    this.calculate_age(this.state.date_of_birth,newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={this.state.date_of_birth?new Date(this.state.date_of_birth):undefined}
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  allowSameDateSelection={true}
                  required={true}
                />
              </LocalizationProvider>
              {/* <div className="age_container">
                <div className="">Age </div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                  <InputLabel id="demo-simple-select-standard-label">Years</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.years}
                    onChange={this.year_change}
                    label="Years"
                  >
                    {[...Array(50).keys()].map((item,idx) => (
                      <MenuItem value={idx} key={idx}>{idx}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px 20px 10px" }}>
                  <InputLabel id="demo-simple-select-standard-label">Months </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.months}
                    onChange={this.month_change}
                    label="Months"
                  >
                    {[...Array(12).keys()].map((item,idx) => (
                      <MenuItem value={idx} key={idx}>{idx}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>           */}

              <InputTheme4
                label="Age"
                attrs={{
                  name: "age",
                  type: "text",
                  value: this.state.age,
                  onChange: this.form_change,
                  disabled : true
                }}
                />
            </div>
            
          </div>
          {/* <div className="notice_label">Title</div>
          <textarea
            name="title"
            value={this.state.title}
            placeholder="Enter your content title.."
            onChange={this.form_change}
            required={true}
            rows={2}
          /> */}
          <div className="notice_label" ref={this.about_pet_ref}>About {this.state.name} *</div>
          {/* <textarea
            name="content"
            value={this.state.content}
            placeholder="Enter your content.."
            onChange={this.form_change}
            required={true}
            rows={8}
          /> */}

          <Editor
            editorState={this.state.editorState}
            toolbarClassName="editor_toolbar"
            wrapperClassName="editor_component"
            editorClassName="editor_input_area"
            onEditorStateChange={this.onEditorStateChange}
            placeholderText="Enter biography here.."
            handlePastedText={() => false}
            stripPastedStyles={true}
            toolbar={{
              options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
              },list: {
                options: ['unordered', 'ordered'],
              },
              fontSize: {
                options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
              },
            }}
          />

         
          <div className="save_button_container">
            {this.state.pet_tribute_id?
              <ButtonTheme1
                label="Modify"
                className="create_button"
              ></ButtonTheme1>
            :
              <ButtonTheme1
                  label="Create"
                  className="create_button"
                ></ButtonTheme1>
            }
          </div>
        </form>
      </div>
    )
  }
}

export default NewPetTribute;
NewPetTribute.contextType = MyContext;