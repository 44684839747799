import React from "react";
import MyContext from "../MyContext";
import "./PartnerInformation.scss";
import partner_image1 from "../../res/images/partner_information/partner_info1.jpg";
import partner_image2 from "../../res/images/partner_information/partner_info2.jpg";
import partner_image3 from "../../res/images/partner_information/partner_info3.jpg";
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";

class PartnerInformation extends React.Component {
  constructor(props) {
    super(props);
    this.partner_information_page_ref = React.createRef();
  }

  render() {
    return (
      <div
        className="partner-information-container"
        ref={this.partner_information_page_ref}
      >
        <div className="title-container">
          <div className="title-block">
            <div className="main-title">Partner with Amrityum</div>
            <div className="title-content">
              We’re always looking for great partnerships. If you’d like to talk
              about a possible partnership with Amrityum, please let us know.
              Partnering with us will provide an established avenue to continue
              the legacy you have built. We are interested in opportunities that
              would be a win for our associates, and community.
              <br />
              <br />
             <p className="second">It’s free and easy to get started. Alternatively, you may email us
              at <a href="mailto:info@amrityum.com"><b>info@amrityum.com</b></a></p> 
            </div>
      
             <div className="button-container" style={{display:this.context.session.user_type == "admin"?"none":""}}>
             {this.context.session.user_type == "partner"?
              <ButtonTheme2
                label="CREATE AN ACCOUNT"
                className="create-button"
                onClick={() => this.props.history.push("/Dashboard/Services/Edit")}
              />
              :<ButtonTheme2
              label="CREATE AN ACCOUNT"
              className="create-button"
              onClick={() => this.props.history.push("/Dashboard/NewPartner")}
            />
            }
            </div>
            <div
              className="title-image"
              style={{ backgroundImage: "url('" + partner_image1 + "')" }}
            ></div>
          </div>
        </div>
        <div className="second-container">
          <div className="second-block">
            <div
              className="img-box"
              style={{ backgroundImage: "url('" + partner_image2 + "')" }}
            ></div>
            <div className="text-box">
              <div className="second-title">Our Winning Connection</div>
              <div className="second-content">
                Do you want to maximize your existing target group or create new
                revenue streams? Amrityum looks forward to partnership ventures
                that generate new sales, brand awareness and creating marketing
                networks that reach larger audiences.
                <br />
                <br />
                Becoming an Amrityum partner is simple and easy to join.
                Together we can create lasting legacies.
              </div>
            </div>
          </div>
        </div>
        <div className="third-container">
          <div className="third-block">
            <div className="third-content">
              <div className="heading">Become a Partner</div>
              <hr className="header-divider"></hr>
              <div className="header-content">
                We’re delighted that you are interested in exploring partnership
                opportunities with Amrityum.
              </div>
              <div className="contact-button-container">
                <ButtonTheme2
                  label="CONTACT US"
                  className="contact-button"
                  onClick={() => this.props.history.push("/ContactUs")}
                />
              </div>
            </div>
            <div
              className="third-image"
              style={{ backgroundImage: "url('" + partner_image3 + "')" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerInformation
PartnerInformation.contextType = MyContext;
