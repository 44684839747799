import React from "react";
import './CookiesPolicy.scss';

class CookiesPolicy extends React.Component{
  constructor(props){
    super(props);
    this.cookies_policy_page_ref = React.createRef();
  }

  componentDidMount(){
    // this.cookies_policy_page_ref.current.scrollIntoView({ block: "start", inline: "nearest"});
  }
  

  render(){
    return(
      <div className="cookies_policy_page_container page_container" ref={this.cookies_policy_page_ref}>
        <div className="page_title">
              <div className="title_ghost"></div>
              <div className="title">Cookies Policy</div>
          </div>
        <div className="cookies_policy_container">
          <div className="home_link" onClick={() => this.props.history.push("/")}>HOME</div>
          <div className="page_link">COOKIES POLICY</div>
          <div className="sub_heading">For website visitors</div>
          <div style={{fontWeight:"bold"}}>This Cookies Policy gives website visitors some helpful information about cookies and social media plug-ins operating on the website: www.amrityum.com.</div>
          <ul className="main_list">
            <li>Who we are: Amrityum.com is a privately owned online platform for publishing death & family notices,  create a memorial, pet tributes and life events. Our registered address is Amrityum, Kombanathottathil, Nagapuzha, Ernakulam, Kerala, India 686 668.</li>
            <li>Our website: Our website is www. amrityum.com. Our website server is located in India. Further information about the personal data we collect via our website forms [e.g. “contact us”, ‘Register’ and sign up to newsletter etc] is set out in our Privacy Notice. This Cookie Notice relates only to cookies gathered through our website.</li>
            <li>What are cookies: A cookie is a small file sent to your browser from a web server and stored on your device to identify you as the user of the website. Many internet sites use cookies. You can find out more information about cookies at <span>www.aboutcookies.org</span> </li>
            <li>THE COOKIES WE USE
              <p>We use the following cookies:</p>
              <ul className="sub_list">
                <li>Strictly necessary cookies</li>
                <p>These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</p>
                <li>Preference cookies</li>
                <p>Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</p>
                <li>Statistics cookies</li>
                <p>Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously</p>
                <li>Marketing cookies</li>
                <p>Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.</p>
              </ul>
            </li>
            <li>Privacy by design and by default: as part of our commitment to the privacy of our customers and website visitors, we set all our non-essential cookies and third party tracking to “off” by default. We use cookiebot to manage your consent preferences. When you visit our website, the cookiebot tool will explain what sort of cookies operates on the website and provide you with easy access to further details. Non-essential cookies (e.g. preferences, statistics, marketing) are set by default to “off”. This means trackers and non-essential cookies are blocked automatically unless and until you give your consent. You do not have to consent if you do not wish to do so. You can still visit our website even if you reject non-essential cookies and third party tracking. Non-essential cookies will only be dropped and third party sharing will only take place if tick the relevant boxes to signify your consent.</li>
            <li>Strictly necessary cookies: Some cookies are essential for the operation of our website (“strictly necessary”).</li>
            <div className="custom_table">
              <div className="table_row header">
                <div className="table_cell">Cookie Name</div>
                <div className="table_cell">Expiration	or	retention</div>
                <div className="table_cell">Cookie description</div>
                <div className="table_cell">Function/purpose</div>
              </div>
              <div className="table_row body">
                <div className="table_cell"></div>
                <div className="table_cell">24 hours</div>
                <div className="table_cell">HTML</div>
                <div className="table_cell">Necessary for the shopping cart functionality on the website</div>
              </div>
              <div className="table_row body">
                <div className="table_cell"></div>
                <div className="table_cell">User session</div>
                <div className="table_cell">HTML</div>
                <div className="table_cell">Necessary for the shopping cart functionality on the website</div>
              </div>
            </div>
            <p>If you adjust your browser settings to block all cookies, you may not be able to access all or parts of our website, or you may not be able to use sections of the e-commerce/service part of the website.</p>
            <li>What first party cookies are on our website: the following cookies on our website relate to Google Analytics. These are set to “off” by default. These cookies will not be dropped unless or until you give your consent. You do not have to consent if you do not wish to do so. You can still visit our website even if you reject non-essential cookies.</li>
            <div className="custom_table">
              <div className="table_row header">
                <div className="table_cell">Cookie Name</div>
                <div className="table_cell">Expiration	or	retention</div>
                <div className="table_cell">Cookie description</div>
                <div className="table_cell">Function/purpose</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">_gat (Google Analytics)</div>
                <div className="table_cell">1 minute</div>
                <div className="table_cell">Used to throttle request rate to	limit	the	number	of requests to doubleclick.net</div>
                <div className="table_cell">Tracking</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">gid	(Google Analytics)</div>
                <div className="table_cell">24 hours.</div>
                <div className="table_cell">Used to distinguish visitors to this website. Allows us to know what pages</div>
                <div className="table_cell">Analytics</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">_ga (Google Analytics)</div>
                <div className="table_cell">2 years from last visit</div>
                <div className="table_cell">Used to distinguish visitors to this website.	Allows	us to know	what pages were visited, content and user</div>
                <div className="table_cell">Analytics</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">_unam (ShareThis)</div>
                <div className="table_cell">9 months</div>
                <div className="table_cell">Used for ShareThis, a social sharing widget placed on the site to enable	sharing	of content across various social networks. This cookie counts clicks	and	shares	of	a website page.</div>
                <div className="table_cell">Social media sharing</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">_fbp (Facebook)</div>
                <div className="table_cell">3 months</div>
                <div className="table_cell">This	cookies	delivers	our advertising	to	people	who have	already	visited	our website when on Facebook (or	any	digital	platform powered	by	Facebook advertising).</div>
                <div className="table_cell">Tracking/targeting</div>
              </div>
            </div>
            <li>What third party cookies are on our website: the following third party cookies are used on the website. These are set to “off' by default. These cookies will not be dropped unless or until you give your consent. You do not have to consent if you do not wish to do so. You can still visit our website even if you reject non-essential cookies.</li>
            <div className="custom_table">
              <div className="table_row header">
                <div className="table_cell">Cookie Name</div>
                <div className="table_cell">Expiration	or	retention</div>
                <div className="table_cell">Cookie description</div>
                <div className="table_cell">Function/purpose</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">Fr (facebook)</div>
                <div className="table_cell">3 months</div>
                <div className="table_cell">This cookies delivers our advertising to people who have already visited our website when on Facebook (or any digital platform powered by Facebook advertising). </div>
                <div className="table_cell">Tracking/targeting</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">stid (Sharethis.com)</div>
                <div className="table_cell">13 months</div>
                <div className="table_cell">This third party allows website visitors to share news items on social media platforms.</div>
                <div className="table_cell">Social media sharing Tracking/targeting</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">Facebook</div>
                <div className="table_cell">Session</div>
                <div className="table_cell">Facebook is a third party that allows users to share your news items on social media </div>
                <div className="table_cell">Social media sharing Tracking/targeting</div>
              </div>
            </div>
            <li>Third party requests: Our website has third party requests to a domain that is not ours. We have set out the details below. These are set to “off' by default. These cookies will not be dropped unless or until you give your consent. You do not have to consent if you do not wish to do so. You can still visit our website even if you reject non-essential cookies.</li>
            <div className="custom_table">
              <div className="table_row body">
                <div className="table_cell">ajax.googleapis.com</div>
                <div className="table_cell">Used to help with implementation</div>
                <div className="table_cell">https://www.google.com/analytics/terms/us.html</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">c.sharethis.mgr.consensu.org</div>
                <div className="table_cell">Social media integration web parts use the corresponding social media's cookies.</div>
                <div className="table_cell">ShareThis uses browser cookies to "tag" visitors on the websites that use ShareThis Publisher Applications. These users are given a technical identifier, such as C62414AY9324FB5671069928026P0067. This browser cookie tracks the user’s browsing data. Pixel tags are then used to transfer this browsing data from visitors of Publishers’ websites to ShareThis and our data partners. Usage Data may include, without limitation: Unique IDs of a cookie placed on a web browser (example: 37d387ca-f68a-11e5-b87d-0e58954c72b1) Webpages viewed (including the URL addresses of such pages) Search queries from which users are directed to a page Navigation from page to page through the ShareThis services Time spent on each page Items clicked or selected Content highlighted IP addresses </div>
              </div>
              <div className="table_row body">
                <div className="table_cell">connect.facebook.net</div>
                <div className="table_cell">Social media integration web parts use the corresponding social media's cookies.</div>
                <div className="table_cell">https://en-gb.facebook.com/policies/cookies/</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">I.sharethis.com</div>
                <div className="table_cell">Social media integration web parts use the corresponding social media's cookies.</div>
                <div className="table_cell">ShareThis uses browser cookies to "tag" visitors on the websites that use ShareThis Publisher Applications. These users are given a technical identifier, such as C62414AY9324FB5671069928026P0067. This browser cookie tracks the user’s browsing data. Pixel tags are then used to transfer this browsing data from visitors of Publishers’ websites to ShareThis and our data partners. Usage Data may include, without limitation: Unique IDs of a cookie placed on a web browser (example: 37d387ca-f68a-11e5-b87d-0e58954c72b1) Webpages viewed (including the URL addresses of such pages) Search queries from which users are directed to a page Navigation from page to page through the ShareThis services Time spent on each page Items clicked or selected Content highlighted IP addresses </div>
              </div>
              <div className="table_row body">
                <div className="table_cell">staticxx.facebook.com</div>
                <div className="table_cell">Social media integration web parts use the corresponding social media's cookies.</div>
                <div className="table_cell">https://en-gb.facebook.com/policies/cookies/</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">stats.g.doubleclick.net</div>
                <div className="table_cell">A third-party DoubleClick cookie is used to enable remarketing—for products like AdWords on the Google Display Network.</div>
                <div className="table_cell">DoubleClick cookies contain no personally identifiable information. Sometimes the DoubleClick cookie will contain an additional identifier that is similar in appearance to the cookie ID, and is used to identify an ad campaign to which a user was exposed previously.</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">ws.sharethis.com</div>
                <div className="table_cell">Social media integration web parts use the corresponding social media's cookies.</div>
                <div className="table_cell">ShareThis uses browser cookies to "tag" visitors on the websites that use ShareThis Publisher Applications. These users are given a technical identifier, such as C62414AY9324FB5671069928026P0067. This browser cookie tracks the user’s browsing data. Pixel tags are then used to transfer this browsing data from visitors of Publishers’ websites to ShareThis and our data partners. Usage Data may include, without limitation: Unique IDs of a cookie placed on a web browser (example: 37d387ca-f68a-11e5-b87d-0e58954c72b1) Webpages viewed (including the URL addresses of such pages) Search queries from which users are directed to a page Navigation from page to page through the ShareThis services Time spent on each page Items clicked or selected Content highlighted IP addresses </div>
              </div>
              <div className="table_row body">
                <div className="table_cell">www.facebook.com</div>
                <div className="table_cell">Social media integration web parts use the corresponding social media's cookies.</div>
                <div className="table_cell">https://en-gb.facebook.com/policies/cookies/</div>
              </div>
              <div className="table_row body">
                <div className="table_cell">www.google-analytics.com</div>
                <div className="table_cell">Used to distinguish users for web statistics</div>
                <div className="table_cell">https://www.google.com/analytics/terms/us.html</div>
              </div>
            </div>
            <li>Control settings: there may be links to other websites or platforms available on our website. We have no control over those other websites’ data protection practices or cookie policies. Most web browsers will allow you to turn off cookies. You should look at your web browsers Settings or Help menu to find out how this is done.  In addition to managing your privacy when you are on our website via our cookiebots tool, you can also configure your browser settings to reject cookies/turn off tracking across other websites.
              <ul className="sub_list">
                <li>Google Chrome </li>
                <li>Internet Explorer</li>
                <li>Firefox </li>
                <li>Safari</li>
                <li>Safari Mobile</li>
                <li>Opera</li>
              </ul>
            </li>
            <li>Other technologies
              In addition to cookies, there are similar technologies that may be used by the Sites and the Apps. These include:
              Web beacons: These are tiny graphics with a unique identifier that are used to understand browsing activity. Web beacons are invisible when you open a web page.
              Social widgets: These are buttons or icons provided by third-party social media providers that allow you to interact with those social media services when you view a web page or a mobile app screen. These social widgets may collect browsing data, which may be received by the third party that provided the widget, and are controlled by the third parties.
              UTM codes: These are strings that can appear in a web address when a user moves from one web page or website to another. The string can represent information about browsing, such as which advertisement or publisher sent the user to the receiving website.</li>
            <li>Your rights: All your rights that can be exercised at any time by contacting us. Further information about those rights is set out in our Privacy Notice.</li>
            <li>Contact us: If you have any queries, please contact us by email: <span>info@amrityum.com</span> or by writing to us at: Amrityum, Kombanathottathil, Nagapuzha, Ernakulam, Kerala, India 686 668. Phone/WhatsApp: +91 7558900111</li>
          </ul>
         </div>
      </div>
    )
  }
}

export default CookiesPolicy;