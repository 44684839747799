import React from "react";
import "./ViewNoticeFeedback.scss";
import { Link } from "react-router-dom";
import $ from "jquery";
import MyContext from "../../MyContext";

class ViewNoticeFeedback extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      name : "",
      email : "",
      message : "",
      published_date : ""
    }

    this.view_notice_feedback_page_ref = React.createRef();
  }

  componentDidMount(){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/viewNoticeFeedbackDetails",
      data : this.context.bind_session({
        feedback_id : this.props.match.params.feedback_id
      }),
      type : "POST"
    }).done(function(return_data){
      if (return_data.status == "success") {
        obj_this.setState(return_data.feedback_details,() => {
          obj_this.view_notice_feedback_page_ref.current.style.visibility = "visible";
        });
        
        obj_this.context.loading(0);
      } else {
        alert("Network error!!")
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="view_notice_feedback_page_container page_container" ref={this.view_notice_feedback_page_ref}>
        <div className="page_header">
          <div className="page_title">Visitor Message</div>
          {/* <div className="breadcrumbs_container">
            <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
            <Link to="/Dashboard/NoticeFeedbacks">Notice Feedbacks</Link>/
            <span>View</span>
          </div> */}
        </div>
        <div className="feedback_details">
          <div className="details_grid">
            <div className="label">Name</div>
            <div className="column">:</div>
            <div className="value">{this.state.name}</div>
            <div className="label">Email</div>
            <div  className="column">:</div>
            <div className="value">{this.state.email}</div>
            <div className="label">Message</div>
            <div  className="column">:</div>
            <div className="value">{this.state.message}</div>
            <div className="label">Notice Page Link</div>
            <div  className="column">:</div>
            <div className="value">
              <Link style={{color:"blue"}} target="_blank" to={this.state.notice_type == "death_notice"?"/DeathNotices/"+this.state.notice_id:this.state.notice_type == "memorial"?"/Memorials/"+this.state.notice_id:this.state.notice_type == "family_notice"?"/InMemoryNotices/"+this.state.notice_id:""}>
              {this.state.notice_type == "death_notice"?process.env.REACT_APP_URL+"/DeathNotices/"+this.state.notice_id:this.state.notice_type == "memorial"?process.env.REACT_APP_URL+"/Memorials/"+this.state.notice_id:this.state.notice_type == "family_notice"?process.env.REACT_APP_URL+"/InMemoryNotices/"+this.state.notice_id:""}
              </Link>  
            </div>
          </div>
         
        </div>
      </div>
    )
  }
}

export default ViewNoticeFeedback;
ViewNoticeFeedback.contextType = MyContext;