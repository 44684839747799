import React from "react";
import "./DeathNoticeApprovalList.scss";
// import MUITable from "../MUI/MUITable";
// import Button from '@mui/material/Button';
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import $ from "jquery";
import { Link } from 'react-router-dom';
import MyContext from "../../MyContext";
import { AddButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {FcApproval, FcFullTrash} from 'react-icons/fc';
import memories from "../../../res/images/memorials/default_memories.jpg";

class DeathNoticeApprovalList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      death_notices : [],
      search_input : "",
      death_notice_messages : [],
      death_notice_prayers : [],
      death_notice_memoirs : [],
    }
    this.death_notice_approval_list_page_Ref = React.createRef();
    this.delete_tribute_message = this.delete_tribute_message.bind(this);
    this.approve_tribute_message = this.approve_tribute_message.bind(this);
  }
  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/pendingApprovalDeathNotices",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        obj_this.setState({
          death_notices : return_data.pending_approval_death_notices,
          death_notice_messages : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "note"),
          death_notice_prayers : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "prayer"),
          death_notice_memoirs : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs"),
        },() => {
          obj_this.context.loading(0);
          obj_this.death_notice_approval_list_page_Ref.current.style.visibility = "visible";
          obj_this.death_notice_approval_list_page_Ref.current.style.opacity = 1;
        });
      }else{
        obj_this.context.loading(0);
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  delete_tribute_message(death_notice_tribute_id){
    if (!window.confirm("Delete this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteDeathNoticeTribute",
      data: this.context.bind_session({ death_notice_tribute_id: death_notice_tribute_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          death_notice_messages : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "note"),
          death_notice_prayers : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "prayer"),
          death_notice_memoirs : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs"),
        });
        alert("Death notice message deleted");
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  approve_tribute_message(death_notice_tribute_id){
    if (!window.confirm("Approve this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveDeathNoticeTribute",
      data: this.context.bind_session({ death_notice_tribute_id: death_notice_tribute_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          death_notice_messages : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "note"),
          death_notice_prayers : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "prayer"),
          death_notice_memoirs : return_data.pending_approval_death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs"),
        });
        alert("Death notice message approved");
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="deathnotice_approval_list_page_container page_container" ref={this.death_notice_approval_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Pending Approval</div>
          <div className="new_death_notice_button_container">
          {/* <Link className="new_death_notice_button" to="/Dashboard/DeathNotices/New">
            <AddButtonTheme1 label="Publish a Death notice"/>
          </Link> */}
          </div>
        </div>
        <div style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
          <div className="table_title">Death Notices</div>
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no">Sl No.</div>
              <div className="table_cell fullname">First Name</div>
              <div className="table_cell notice_type">Surname</div>
              <div className="table_cell datetime">Locality</div>
              <div className="table_cell datetime">State</div>
              <div className="table_cell">Entered By</div>
              <div className="table_cell datetime">Published Date</div>
            </div>
            {this.state.death_notices && this.state.death_notices.map((item,idx) => (
              <Link className="table_row body"
                key={idx}
                to={{pathname: "/DeathNotice/Preview/" + item.id, url : "/Dashboard/DeathNotices/Approvals"}}
                >
                <div className="table_cell sl_no">{idx+1}</div>
                <div className="table_cell">{item.first_name}</div>
                <div className="table_cell">{item.surname}</div>
                <div className="table_cell">{item.locality}</div>
                <div className="table_cell">{item.flag} {item.state}</div>
                <div className="table_cell">{item.fullname}</div>
                <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              </Link>
            ))}
            <div className="table_row no_results" style={{display:this.state.death_notices.length?"none":"block"}}>No results.</div>
          </div>
        </div>
        <div className="table_title">Condolences</div>
        <div className="custom_table_theme1">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Death Notice</div>
            <div className="table_cell">Condolences</div>
            <div className="table_cell fullname">Entered By</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.death_notice_messages && this.state.death_notice_messages.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.tribute_text}
              </div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{display:item.status=="PENDING"?"inline-flex":"none"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approve_tribute_message(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_tribute_message(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.death_notice_messages.length?"none":"block"}}>No results.</div>
        </div>
        <div className="table_title">Prayers</div>
        <div className="custom_table_theme1">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Death Notice</div>
            <div className="table_cell">Prayers</div>
            <div className="table_cell fullname">Entered By</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.death_notice_prayers && this.state.death_notice_prayers.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.tribute_text}
              </div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{display:item.status=="PENDING"?"inline-flex":"none"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approve_tribute_message(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_tribute_message(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.death_notice_prayers.length?"none":"block"}}>No results.</div>
        </div>
        <div className="table_title">Memoirs</div>
        <div className="custom_table_theme1">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Death Notice</div>
            <div className="table_cell">Image</div>
            <div className="table_cell">Memoirs</div>
            <div className="table_cell fullname">Entered By</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.death_notice_memoirs && this.state.death_notice_memoirs.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.image_src?<div className="memory_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/tribute_images/"+item.image_src+"')"}}></div>
                :<div className="memory_image" style={{backgroundImage: "url('"+memories+"')"}}></div>
              }
                  
              </div>
              <div className="table_cell" dangerouslySetInnerHTML={{ __html: item.tribute_text }}></div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{display:item.status=="PENDING"?"inline-flex":"none"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approve_tribute_message(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_tribute_message(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.death_notice_memoirs.length?"none":"block"}}>No results.</div>
        </div>
      </div>
    )
  }
}

export default DeathNoticeApprovalList;
DeathNoticeApprovalList.contextType = MyContext;