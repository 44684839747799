import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {RiImageEditLine,RiDeleteBinLine} from "react-icons/ri";
import {MdOutlineDelete} from "react-icons/md";

import {GrRevert} from "react-icons/gr";
import React from "react";
import "./ImageUpload.scss";
import { fi } from "date-fns/locale";

class ImageUpload extends React.Component{
  constructor(props){
    super(props);
    this.image_input_ref = React.createRef();
    this.imageChange = this.imageChange.bind(this);
    this.state={
      input_img:"",
      delete_flag : 0
    }
    this.revert_image = this.revert_image.bind(this);
    this.delete_image = this.delete_image.bind(this);
  }

  imageChange(e){
    // console.log("hey");
    const file = e.target.files[0];
    if (this.props.attrs.max_size && (file.size / 1024) > this.props.attrs.max_size){
      alert("File size cannot exceed more than "+ this.props.attrs.max_size+ "KB");
      return;
    }
    this.setState({
      input_img : URL.createObjectURL(file),
    });
  }

  delete_image(){
    this.setState({
      delete_flag : 1,
      input_img : "",
    },() => {
      this.image_input_ref.current.value = null;
      const event = new Event("change", { bubbles: true });
      this.image_input_ref.current.dispatchEvent(event);
    });
  }


  revert_image(e){
    this.setState({
      input_img : "",
    }, () => {
      this.image_input_ref.current.value = null;
      const event = new Event("change", { bubbles: true });
      this.image_input_ref.current.dispatchEvent(event);
    });
  }
 
  render(){
    return(
      <>
      <div
        className={"image_upload_component_container "+this.props.className}
        style={this.props.style}
        // onClick={()=>{this.image_input_ref.click()}}
        onClick={()=>{this.image_input_ref.current.click()}}
        onMouseEnter={this.props.onHover}
        onMouseLeave={this.props.onBlur}
      >
        {this.state.input_img || this.props.src?
        <img className="preview_image" src={this.state.input_img?this.state.input_img:this.props.src??""}/>
        :
        <FontAwesomeIcon icon={faImage}  />
        }
        <input
          type="file"
          ref={this.image_input_ref}
          // ref={input => this.image_input_ref = input}
          onChange={e => {this.imageChange(e);this.props.onChange(e);}}
          {...this.props.attrs}
          accept="image/*"
          id = "custom_image_input"
        />
      </div>
      <div className="image_modify_buttons">
        {(this.state.input_img && this.props.src)?
         <div className="revert_button"
         onClick={() => {
          this.revert_image();
          this.props.revertImage();
          }}>
          <span>Revert</span>
          <GrRevert></GrRevert>
         </div>
         :(this.state.input_img || this.props.src)?
        <div className="delete_button"
            onClick={() => {
              if(this.props.src){
                if(!window.confirm("Are you sure?")){
                  return;
                }
              }
              this.delete_image();
              this.props.deleteImage();
            }}>
        {/* <span>Delete</span> */}
        <MdOutlineDelete 
         
        size={30} />
        
       </div>:""
        }
       
      
      {/* <RiImageEditLine 
        className="edit_button"
        onClick={this.edit_image}
      ></RiImageEditLine> */}
    </div>
    </>
    )
  }
}

export default ImageUpload;