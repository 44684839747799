import React from "react";
import './Help.scss';
import {Link} from "react-router-dom";

class Help extends React.Component{
  constructor(props){
    super(props);
    this.privacy_policy_ref = React.createRef();
  }

  render(){
    return(
      <div className="help_page_container page_container" ref={this.privacy_policy_ref}>
        <div className="page_title">
              <div className="title_ghost"></div>
              <div className="title">Help Section</div>
          </div>
        <div className="help_container">
          <div className="home_link" onClick={() => this.props.history.push("/")}>HOME</div>
          <div className="page_link">Help</div>
          <div className="WordSection1">
            <p className="MsoNormal" 
            style={{marginBottom: '0in', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Introduction
                  </span></b></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Welcome
                to the help section of Amrityum!</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Here
                you will find some guidelines and templates to our site when you want to make a
                post.
                <br></br>
                If you can't find helpful tips, Please refer to our  <Link to="/FAQs">Frequently Asked
                  Questions </Link> or the information in our <Link to="/ContactUs">Contact Us</Link> section.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write Death and Funeral Notice?</span></b></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><a name="_Hlk104825158"><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Example
                    A: Writing a General Death Notice.</span></b></a></p>
            <p style={{margin: '0in', lineHeight: '150%'}}><span style={{ color: '#0E101A'}} lang="EN-IE">The death has occurred of Family
                Name/House Name, Full Name.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Full name) (Age) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">of
                <span style={{color: 'red'}}>(place at death)</span>: <span style={{color: 'red'}}>(Full
                  name) </span>passed away in <span style={{color: 'red'}}>(name of the place)</span>
                on <span style={{color: 'red'}}>(date of death)</span>. <span style={{color: 'red'}}>(First
                  name) </span>was born in <span style={{color: 'red'}}>(place of birth) </span>on <span style={{color: 'red'}}>(date of birth) </span>to <span style={{color: 'red'}}>(parent’s
                  name)</span>. <span style={{color: 'red'}}>(He/She) </span>attended <span style={{color: 'red'}}>(name of high school and/or college)</span>.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(First name) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">worked
                as <span style={{color: 'red'}}>(job) </span>and enjoyed participating in <span style={{color: 'red'}}>(hobbies)</span>. <span style={{color: 'red'}}>(First name) </span>was
                also a proud member of <span style={{color: 'red'}}>(organizations and affiliations)</span>.
                <span style={{color: 'red'}}>(First name) </span>is survived by <span style={{color: 'red'}}>(names and details of surviving family)</span> and was
                predeceased by <span style={{color: 'red'}}>(names of family members who have died.)</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Memorial services/funeral will be held at <span style={{color: 'red'}}>(location) </span>on <span style={{color: 'red'}}>(date and time)</span>.
                Memorial donations may be made in <span style={{color: 'red'}}>(first name)'s </span>to
                the recommended charity below.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name of the deceased)'s</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE"> family would like to thank you for your support at this sad
                time.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Date of Death:&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Date Published:&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><i><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Messages of comfort and condolence can
                  be left for the family in the condolences section below.&nbsp;</span></i></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">&nbsp;</span></b></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Example B: Writing a General Death
                  Notice.</span></b></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">&nbsp;</span></p>
            <p style={{margin: '0in', lineHeight: '150%'}}><span style={{ color: '#0E101A'}} lang="EN-IE">The death has occurred of Family
                Name/House Name, Full Name.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">Full Name</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">Date from - Date to</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name of Person) (Age) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">passed
                away on <span style={{color: 'red'}}>(date) </span>in <span style={{color: 'red'}}>(location)</span>.
                <span style={{color: 'red'}}>(First name) </span>was born in <span style={{color: 'red'}}>(place) </span>on <span style={{color: 'red'}}>(date of birth)</span>. <span style={{color: 'red'}}>(He/She) </span>is survived by <span style={{color: 'red'}}>(names
                  and roles of living family members)</span> and was predeceased by <span style={{color: 'red'}}>(names and roles of family members who have died).</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Memorial/Funeral) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">services
                will be performed at <span style={{color: 'red'}}>(location) </span>on <span style={{color: 'red'}}>(date) </span>at <span style={{color: 'red'}}>(time).</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Memorial donations may be made in <span style={{color: 'red'}}>(first name)'s </span>to the recommended charity below.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name of the deceased)'s </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">family would like to thank you for your support at this sad time.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Date of Death:&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Date Published:&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><i><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Messages of comfort and condolence can
                  be left for the family in the condolences section below.&nbsp;</span></i></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Example C: Writing a General Death
                  Notice.</span></b></p>
            <p style={{margin: '0in', lineHeight: '150%'}}><span style={{ color: '#0E101A'}} lang="EN-IE">The death has occurred of </span><span style={{ color: 'red'}} lang="EN-IE">Family
                Name/House Name, Full Name.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">Full Name</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">Date from - Date to</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name of Person) (Age) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">passed away on <span style={{color: 'red'}}>(date)</span> in <span style={{color: 'red'}}>(location)</span>. <span style={{color: 'red'}}>(He/She) </span>is survived by <span style={{color: 'red'}}>(his/her)
                  (relationships, names and details of family members). (Memorial/Funeral) </span>services
                will be performed at <span style={{color: 'red'}}>(location) </span>on <span style={{color: 'red'}}>(date) </span>at <span style={{color: 'red'}}>(time).</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Memorial donations may be made in <span style={{color: 'red'}}>(first name)'s </span>to the recommended charity below.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name of the deceased)'s </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">family would like to thank you for your support at this sad
                time.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Date of Death:&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Date Published:&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><i><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0E101A'}} lang="EN-IE">Messages of comfort and condolence can
                  be left for the family in the condolences section below.&nbsp;</span></i></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A - Death Notices for a male </span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The death has occurred of&nbsp;</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(s</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: 'white'}} lang="EN-IE">urname, full
                name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red'}} lang="EN-IE">(Full name) (year of
                birth-year of death)</span><span style={{}} lang="EN-IE">.
                Passed away peacefully <span style={{color: 'red'}}>(date and month) </span>at <span style={{color: 'red'}}>(name and address of the place of death – eg: hospital,
                  hospice or home etc)</span>. Much loved husband of <span style={{color: 'red'}}>(name)</span>,
                father to <span style={{color: 'red'}}>(name of the child/children)</span>,
                father-in-law to <span style={{color: 'red'}}>(name/s)</span>, grandfather to <span style={{color: 'red'}}>(name/s)</span>.</span><span style={{ color: 'red', background: 'white'}} lang="EN-IE"> (name) </span><span style={{ color: 'black'}} lang="EN-IE">will be
                sadly missed by </span><span style={{ color: 'red'}} lang="EN-IE">(names/relationships).</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Memorial donations
                may be made in </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s) </span><span style={{ color: 'black'}} lang="EN-IE">name to the charity recommended by the
                family.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s)
              </span><span style={{ color: 'black'}} lang="EN-IE">family would like to thank you for your support during this sad
                time.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Messages of comfort
                and condolence can be left for the family in the condolences section below.</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample B - Death Notices for a male </span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The death has occurred of&nbsp;</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(s</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: 'white'}} lang="EN-IE">urname, full
                name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red'}} lang="EN-IE">(Full name) (date &amp;
                month)</span><span style={{}} lang="EN-IE">,
                suddenly but peacefully at home. <span style={{color: 'red'}}>(First name)</span> of
                <span style={{color: 'red'}}>(address)</span>, aged <span style={{color: 'red'}}>(age in
                  numbers) </span>years. Devoted husband of <span style={{color: 'red'}}>(name)</span>.
                A loving and much-loved dad to <span style={{color: 'red'}}>(name/s)</span>, brother
                to <span style={{color: 'red'}}>(name/s)</span>. A beloved grandad and
                father-in-law, brother-in-law, uncle and good friend to many. </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name) </span><span style={{ color: 'black'}} lang="EN-IE">will be sadly missed by </span><span style={{ color: 'red'}} lang="EN-IE">(names/relationships).</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">&nbsp;</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Memorial donations
                may be made in </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s) </span><span style={{ color: 'black'}} lang="EN-IE">name to the charity recommended by the
                family.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s)
              </span><span style={{ color: 'black'}} lang="EN-IE">family would like to thank you for your support during this sad
                time.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Messages of comfort
                and condolence can be left for the family in the condolences section below.</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A - Death Notices for a female </span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The death has occurred of&nbsp;</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(s</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: 'white'}} lang="EN-IE">urname, full
                name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red'}} lang="EN-IE">(Full name) (date &amp;
                month). (name)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE"> aged </span><span style={{ color: 'red'}} lang="EN-IE">(age in number) </span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">years.
                Dearly beloved wife of </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(the late) (name)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">,
                adored mum of </span><span style={{ color: 'red'}} lang="EN-IE">(name/s)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">, also a treasured and much-loved nana of </span><span style={{ color: 'red'}} lang="EN-IE">(name/s)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">. </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name) </span><span style={{ color: 'black'}} lang="EN-IE">will be sadly missed by </span><span style={{ color: 'red'}} lang="EN-IE">(names/relationships).</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Memorial donations
                may be made in </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s) </span><span style={{ color: 'black'}} lang="EN-IE">name to the charity recommended by the
                family.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s)
              </span><span style={{ color: 'black'}} lang="EN-IE">family would like to thank you for your support during this sad
                time.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Messages of comfort
                and condolence can be left for the family in the condolences section below.</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample B - Death Notices for a female </span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The death has occurred of&nbsp;</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(s</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: 'white'}} lang="EN-IE">urname, full
                name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red'}} lang="EN-IE">(Full name) (date &amp;
                month)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">, suddenly but peacefully. </span><span style={{ color: 'red'}} lang="EN-IE">(name) </span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">of </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(address)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">, aged </span><span style={{ color: 'red'}} lang="EN-IE">(age in
                number) </span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">years. Devoted wife of </span><span style={{ color: 'red'}} lang="EN-IE">(name/s)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">. A loving and much-loved mom to </span><span style={{ color: 'red'}} lang="EN-IE">(name/s)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">. A beloved grandma. Also, a very dear sister and good friend
                to many.&nbsp; </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name) </span><span style={{ color: 'black'}} lang="EN-IE">will be sadly missed by </span><span style={{ color: 'red'}} lang="EN-IE">(names/relationships).</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Memorial donations
                may be made in </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s) </span><span style={{ color: 'black'}} lang="EN-IE">name to the charity recommended by the
                family.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s)
              </span><span style={{ color: 'black'}} lang="EN-IE">family would like to thank you for your support during this sad
                time.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Messages of comfort
                and condolence can be left for the family in the condolences section below.</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A - Death Notices for a baby </span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The death has occurred of&nbsp;</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(s</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: 'white'}} lang="EN-IE">urname, full
                name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red'}} lang="EN-IE">(Full name) (date &amp;
                month) </span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">lost </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(her/his)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE"> fight aged </span><span style={{ color: 'red'}} lang="EN-IE">(age in number) (<span style={{background: 'white'}}>days/years).</span></span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE"> </span><span style={{ color: 'red'}} lang="EN-IE">(Full name)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">. Forever proud son/daughter to </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name of parents)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">, loving sister to </span><span style={{ color: 'red'}} lang="EN-IE">(name/s),</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE"> much loved granddaughter to </span><span style={{ color: 'red'}} lang="EN-IE">(name/s)</span><span style={{ color: '#333333', background: 'white'}} lang="EN-IE">. </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name) </span><span style={{ color: 'black'}} lang="EN-IE">will be sadly missed by </span><span style={{ color: 'red'}} lang="EN-IE">(names/relationships).</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Memorial donations
                may be made in </span><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s) </span><span style={{ color: 'black'}} lang="EN-IE">name to the charity recommended by the
                family.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'red', background: 'white'}} lang="EN-IE">(name’s)
              </span><span style={{ color: 'black'}} lang="EN-IE">family would like to thank you for your support during this sad
                time.</span></p>
            <p style={{margin: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{ color: 'black'}} lang="EN-IE">Messages of comfort
                and condolence can be left for the family in the condolences section below.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%', background: 'white'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write on a thank you/acknowledgement note?</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">It
                is often difficult to know what to write in an acknowledgement and for this
                reason, we provide templates for you, shown on the following pages, as well as
                a checklist of those you may wish to thank in your acknowledgement. You can
                also add a photo of the deceased to make the acknowledgement even more
                personal.</span></p>
            <ul className="main_list">
              <li>The template shown below is provided as a guide only</li>
              <li>Remove any brackets and replace with information relevant to your own situation</li>
            </ul>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A – Acknowledgement Note</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">The
                family circle of the late <span style={{color: 'red'}}>(full name) of (address) who
                  died on (date) </span>would like to thank most sincerely all those who
                sympathised with them in their recent bereavement.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">We
                offer our heartfelt thanks to the many family members, friends, colleagues,
                neighbours and well-wishers who visited our home, attended the wake, ceremonies
                and burial/cremation and who provided emotional and practical support for us at
                this difficult time. For those who telephoned, travelled long distances, sent
                tributes, cards and messages of condolences, as well as those who made
                charitable donations, we are truly grateful.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">&nbsp;A
                special word of thanks to <span style={{color: 'red'}}>(name those you would like to
                  thank).</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">To
                those who helped in any way, your contribution made our loss more bearable. As
                it would be impossible to thank everyone individually, please accept this
                acknowledgement as an expression of our deepest gratitude. Special
                prayers/rituals/religious sacrifices will be offered for your intentions <span style={{color: 'red'}}>(optional).</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Optional&nbsp;-
                An End of Mourning Period ceremony/rite/service/gathering <span style={{color: 'red'}}>(name the event) </span>for <span style={{color: 'red'}}>(name) </span>will
                be held on <span style={{color: 'red'}}>(date &amp; time) at (name venue and
                  address)</span>. All Welcome.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Optional&nbsp;-
                The First Anniversary: <span style={{color: 'red'}}>(name the ceremony/event)</span>
                for <span style={{color: 'red'}}>(name) </span>will be held on <span style={{color: 'red'}}>(date &amp; time) </span>at <span style={{color: 'red'}}>(name
                  venue and address)</span>. All Welcome.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample B - Acknowledgement Note</span></b></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(full Name and address): (name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">’s
                wife/husband/partner <span style={{color: 'red'}}>(name) </span>and children <span style={{color: 'red'}}>(names of the children)</span>,&nbsp; brother/s <span style={{color: 'red'}}>(name/s of the brother/s)</span> and sister/s <span style={{color: 'red'}}>(name/s of the sister/s) </span>and extended family members <span style={{color: 'red'}}>(name/s)</span> wish to express our heartfelt thanks and
                appreciation for the love and support shown to us following the <span style={{color: 'red'}}>(sudden) </span>death of a very beloved husband/wife, father,
                brother/sister and uncle/aunt.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">We would especially like to thank relatives,
                friends, neighbours and work colleagues who gave so generously of their time
                and care in so many ways. We cannot thank enough all those who supported us and
                for all the kindness shown to us with all kinds of practical support and
                prayers. Our heartfelt thanks to all those who sent flowers, sympathy cards,
                letters and messages of sympathy, called at the house, telephoned or sent
                condolences via social media or contacted the family in any way. Thank you to
                those who helped during the wake or attended the wake or funeral.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(if you have group of people to thank
                them, please list them here &amp; their contributions)</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE"><br />
                As it would be impossible to thank individually all those whose kindness has
                brought such comfort to us at this very difficult time. We trust that this
                acknowledgement will be accepted by all with our sincere thanks. <span style={{color: 'red'}}>(Prayers will be offered for all your intentions.)</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">An end of mourning period ceremony <span style={{color: 'red'}}>(name the ceremony) </span>for <span style={{color: 'red'}}>(name
                  of the deceased) </span>will be celebrated/conducted on <span style={{color: 'red'}}>(day) (date) at (time)</span> in <span style={{color: 'red'}}>(name the place
                  and address) </span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write on an Anniversary Notice?</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Below
                is a sample text for a death anniversary notice which you can edit as
                appropriate.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Remove
                any brackets and replace with information relevant to your own situation.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A – Anniversary Notice</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(First) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Anniversary</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">In
                loving memory of <span style={{color: 'red'}}>(full name) </span>whose <span style={{color: 'red'}}>(first)</span> anniversary occurs on <span style={{color: 'red'}}>(date).</span><br />
                Deeply loved, sadly missed, and always remembered by <span style={{color: 'red'}}>(list
                  family members). &nbsp;</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Insert a short poem or note that will convey the sentiments of the
                period and love and respect for the deceased)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#736F63'}} lang="EN-IE"><br />
              </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">An anniversary <span style={{color: 'red'}}>(name the
                  religious event/ritual/ gathering)</span> will be held in <span style={{color: 'red'}}>(name &amp; address of venue)</span> on <span style={{color: 'red'}}>(date at time)</span>.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Quietly
                thought of every day.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Missed
                much more than words can say.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(List the family members)</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050', background: '#FEFEFE'}} lang="EN-IE">Sample B </span></b><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">– Anniversary Notice</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">Full name of the deceased </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', background: '#FEFEFE'}} lang="EN-IE">Precious memories of a dear</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0A0A0A', background: '#FEFEFE'}} lang="EN-IE"> </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">Husband/wife, Father/mother, and Grandfather/mother</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0A0A0A', background: '#FEFEFE'}} lang="EN-IE"> </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', background: '#FEFEFE'}} lang="EN-IE">who died </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">(date) (month) (year)</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', background: '#FEFEFE'}} lang="EN-IE">Those special memories of you</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE"><br />
                <span style={{color: 'black', background: '#FEFEFE'}}>will always bring a smile.</span><br />
                <span style={{color: 'black', background: '#FEFEFE'}}>If only I could have you back,</span><br />
                <span style={{color: 'black', background: '#FEFEFE'}}>For just a little while.</span><br />
                <span style={{color: 'black', background: '#FEFEFE'}}>The fact that you’re no longer
                  here</span><br />
                <span style={{color: 'black', background: '#FEFEFE'}}>will always cause me pain,</span><br />
                <span style={{color: 'black', background: '#FEFEFE'}}>But you’re forever in my heart
                  until we meet again.</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Insert a short poem or note that will convey the sentiments of the
                period and love and respect for the deceased)</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">An
                anniversary <span style={{color: 'red'}}>(name the religious event/ritual/
                  gathering)</span> will be held in <span style={{color: 'red'}}>(name &amp; address
                  of venue)</span> on <span style={{color: 'red'}}>(date at time)</span>.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', background: '#FEFEFE'}} lang="EN-IE">Remembered always</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE"> </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', background: '#FEFEFE'}} lang="EN-IE">by all your family and friends.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(List the family members)</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050', background: '#FEFEFE'}} lang="EN-IE">Sample C </span></b><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">– Anniversary Notice</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">In
                loving memory of our father <span style={{color: 'red'}}>(name) </span>who died on <span style={{color: 'red'}}>(date/month/year) </span>and our mother <span style={{color: 'red'}}>(name) </span>who died on <span style={{color: 'red'}}>(date/month/year)</span>.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Your
                names are often mentioned</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Our
                thoughts are with you still</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">You
                haven’t been forgotten</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">and
                by us you never will. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Fond
                are the memories, precious they stay</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">No
                passing of time can take them away.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Deep
                in our hearts you will always stay</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Loved
                and remembered every day. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Sadly
                missed and always remembered by your <span style={{color: 'red'}}>(name/s)</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write on an end of mourning period
                  ceremony/gathering notice?</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Below
                is a sample text for an&nbsp;end of mourning period ceremony or gathering
                notice which you can edit as appropriate.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Remove any brackets and replace with information relevant to your
                own </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">situation.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A – End of Mourning Period Ceremony</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">The
                <span style={{color: 'red'}}>(Name the ceremony) </span>for <span style={{color: 'red'}}>(full
                  name)</span> will take place on <span style={{color: 'red'}}>(date) </span>at <span style={{color: 'red'}}>(time) </span>in <span style={{color: 'red'}}>(venue)</span>. All
                Welcome. The ceremony can be viewed via following this link <span style={{color: 'red'}}>(provide the link).</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">We/I </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0A0A0A', background: '#FEFEFE'}} lang="EN-IE">would like to extend </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">our/my </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0A0A0A', background: '#FEFEFE'}} lang="EN-IE">sincere and heartfelt gratitude to everyone who supported
                and sympathised with </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">us/me </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0A0A0A', background: '#FEFEFE'}} lang="EN-IE">during
              </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">his/her </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0A0A0A', background: '#FEFEFE'}} lang="EN-IE">illness and </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', background: '#FEFEFE'}} lang="EN-IE">our/my </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#0A0A0A', background: '#FEFEFE'}} lang="EN-IE">recent bereavement.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Insert a short poem or note that will convey the sentiments of the
                period and love and respect for the deceased)</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Much
                loved and sadly missed every day by your family, <span style={{color: 'red'}}>(list
                  the family members)</span>.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample B – End of Mourning Period Ceremony</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">When
                the acknowledgement is published very close to the First Anniversary.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name of the deceased and other details)</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We, the family wish to acknowledge with sincere gratitude all
                those who sympathised with us and showed us kindness and support on our sad
                loss. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Thanks to the many people who offered their condolences, sent
                floral tributes, telephoned, and left messages on various online platforms. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Our deepest appreciation to all who cared and supported </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE"> during </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">his/her </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">illness. Thanks to the
                doctors and nurses of </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(name/s)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE"> for the care they gave to </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">To the many families, friends and colleagues who were unable to be
                with us due to various inconveniences, we thank you for your message of
                support.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">As it would be impossible to than everybody individually for their
                kindness and support, please accept this acknowledgement as an expression of
                our deep gratitude and sincere appreciation. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">Mention the service or religious ceremony that will take place and
                other relevant details </span></p>
            <p className="MsoListParagraph" style={{textAlign: 'justify', textIndent: '-.25in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">-<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Sadly missed by </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(name/s)</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write on a Birthday Remembrance notice?</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Below
                is a sample text for birthday remembrance notice which you can edit as
                appropriate.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Remove
                any brackets and replace with information relevant to your own situation.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A - Birthday Remembrance</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(fortieth) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Birthday </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Remembering
                <span style={{color: 'red'}}>(name)</span>, whose (<span style={{color: 'red'}}>fortieth</span>)
                birthday occurs on <span style={{color: 'red'}}>(date).</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Insert a short poem or note that will convey the sentiments of the
                period and love and respect for the deceased)</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Happy Birthday <span style={{color: 'red'}}>(Name)</span>.</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Sadly missed
                along life's way, </span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Quietly
                remembered every day. </span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">No longer in our
                life to share, </span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">But in our
                hearts, you are always there. </span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">We miss you
                every single day, all our love always</span></p>
            <p className="MsoNormal" style={{lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Love and miss
                you always </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(List family members).</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">&nbsp;<span style={{color: '#00B050'}}>Sample B - Birthday Remembrance</span></span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(fortieth) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Birthday</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', letterSpacing: '.4pt', background: 'white'}} lang="EN-IE">(name of the deceased)</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', letterSpacing: '.4pt', background: 'white'}} lang="EN-IE">(date) (month)</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', letterSpacing: '.4pt', background: 'white'}} lang="EN-IE">, Happy </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', letterSpacing: '.4pt', background: 'white'}} lang="EN-IE">(40<sup>th</sup>) </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', letterSpacing: '.4pt', background: 'white'}} lang="EN-IE">Birthday. Sadly, passed away
                on </span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red', letterSpacing: '.4pt', background: 'white'}} lang="EN-IE">(date)
                (month) (year)</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black', letterSpacing: '.4pt', background: 'white'}} lang="EN-IE">Today is filled with memories
                with happiness and tears of birthday celebrations we’ve shared throughout the
                years. And though we’ll always miss you, the endless joy you brought, warms our
                heart with gratitude and fills our every thought. Wherever you are resting I
                hope that you can see how precious and uplifting your memory is to us. I feel
                that you are with us in everything we do, so we’ll celebrate your birthday, but
                we’ll spend it missing you. Until we meet again. </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Love
                and miss you always </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(List family members).</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write on a Memorial Service notice?</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Below
                is a sample text for memorial service notice which you can edit as appropriate.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Remove
                any brackets and replace with information relevant to your own situation.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A - Memorial Service Notice</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Memorial
                Service for&nbsp;(<span style={{color: 'red'}}>name), (address)</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">A
                memorial service for the late <span style={{color: 'red'}}>(name) </span>who passed
                away on <span style={{color: 'red'}}>(date/month/year) </span>will be held at <span style={{color: 'red'}}>(time) </span>on <span style={{color: 'red'}}>(day) (date) (month)
                  (year)(place). </span>All are welcome to join the family for the <span style={{color: 'red'}}>ritual/service/gathering.</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Donations
                in memory of <span style={{color: 'red'}}>(name of the deceased) </span>will be
                gratefully received for the charity <span style={{color: 'red'}}>(name the charity) </span>or
                following the links below to contribute. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample B - Memorial Service Notice</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Full Name)</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">: Following <span style={{color: 'red'}}>(name of the person) </span>passing on <span style={{color: 'red'}}>Month/date/year</span>, a celebration of <span style={{color: 'red'}}>his/her</span> life will be held on <span style={{color: 'red'}}>(day
                  of the week, month date, year, at time)</span>, at <span style={{color: 'red'}}>(place)</span>,
                to which all friends are respectfully invited. Donations welcome to <span style={{color: 'red'}}>(name the charity)</span>. We ask that you join us at <span style={{color: 'red'}}>(place) </span>for a cuppa and a catch up. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(name/s of the family).</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write on a Mother’s Day Remembrance
                  notice?</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Below
                is a sample text for a Mother’s Day Remembrance notice which you can edit as
                appropriate.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Remove
                any brackets and replace with information relevant to your own situation.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A - Mother’s Day Remembrance notice</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Mother’s
                Day Remembrance for&nbsp;<span style={{color: 'red'}}>(full name)</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Address)</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">,&nbsp;<span style={{color: 'red'}}>(date
                  of birth)&nbsp;</span>–&nbsp;<span style={{color: 'red'}}>(Date of death)</span>&nbsp;<span style={{color: 'red'}}>(Age)&nbsp;</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(name) First </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Mother's Day without
                you mom. Our hearts are broken </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Miss
                you every day mum. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Not
                just for today Sending all our love </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">For
                a mum who is so dear Love always &amp; forever </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">From
                all the family </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name the member/s of family if you wish)</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">What to write on a Father’s Day Remembrance
                  notice?</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Below
                is a sample text for a Father’s Day Remembrance notice which you can edit as
                appropriate.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Remove
                any brackets and replace with information relevant to your own situation.</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#00B050'}} lang="EN-IE">Sample A - Father’s Day Remembrance Notice</span></b></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Father’s
                Day Remembrance for <span style={{color: 'red'}}>(full name)</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Address)</span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">,&nbsp;<span style={{color: 'red'}}>(date
                  of birth)&nbsp;</span>–&nbsp;<span style={{color: 'red'}}>(Date of death)</span>&nbsp;<span style={{color: 'red'}}>(Age)&nbsp;</span></span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(name) </span><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">Happy Father’s Day dad! Many years
                have passed since you left us but not a day has gone by without you being in
                our thoughts. Wouldn’t it be grand if we could pack up the picnic, pile into
                the car and&nbsp;go off to your favourite place to chill out. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">So
                very loved, so very missed. Thinking of you today &amp; always </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', }} lang="EN-IE">From
                all the family </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'red'}} lang="EN-IE">(Name the member/s of family if you wish)</span></p>
          
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Your names
                are often mentioned </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Our
                thoughts are with you still</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">You haven’t
                been forgotten</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">and by us
                your never will. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Those we
                love stay with us,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">For love
                itself lives on, </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">And the
                precious memories never fade, </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Because a
                loved one is gone. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Those we
                love don’t go away</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">They walk
                beside us everyday</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Unseen,
                unheard, but always near</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Still
                loved, still missed, still very dear. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Fond are
                the memories, precious they stay</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">No passing
                of time can take them away.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Deep in our
                hearts you will always stay</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Loved and
                remembered everyday. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Memory lane
                is a beautiful place,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Where
                thoughts we have are true,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Again, and
                again we travel that lane, </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Just to be
                with you. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Although we
                sadly miss you. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We know
                we’re not alone,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We know you
                are watching over us,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">From a
                place you now call home. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Looking
                back on yesterday,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Along the
                path we trod.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We treasure
                the years we had with you,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">And leave
                the rest to God. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">No more
                tomorrows for us to share</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">But
                yesterday’s memories will always be there. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Noting can
                ever take away, </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The love a
                heart hold dear,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Fond
                memories linger every day,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Remembrance
                keeps you near. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Though her
                smile is gone forever</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">And her
                hand we cannot touch</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We still
                have so many memories of the one we love so much.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Her memory
                is now our keepsake</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">With which
                we will never part </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">God has her
                in his keeping </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We have her
                in our hearts. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Down the
                path of memories,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We gently
                tread today</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Our loving
                thoughts are with you as life goes on its way</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The hands
                of time keep turning</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The months
                have slipped away</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">But in our
                hearts and memories, you are with us every day. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Every day
                in some small way,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Memories of
                you come our way. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Though
                absent you are always near,</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Still
                loved, still missed and always dear. </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We think of
                you in silence </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">As we often
                speak your name</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">All we have
                are memories</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">And your picture
                in a frame.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Your
                memories are our keepsake</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">With which
                we will never part</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">God has you
                in his keeping </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We have you
                in our hearts.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Those
                special memories of you </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Will always
                bring a smile </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">If only we
                could have you back</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">For just a
                little while</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Then we
                could sit and talk again</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Just like
                we used to do </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">You’ve
                always meant so very much </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">And always
                will do too</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">The fact
                that you’re no longer here</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Will always
                cause us pain </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">But you’re
                forever in our hearts</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Until we
                meet again</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Just a
                prayer from the family who loved you </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Just a
                memory fond and true</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">In our
                hearts you will live forever</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Because we
                thought the world of you.</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">You had a
                smile for everyone</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">You had a
                heart of gold </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">You left
                the sweetest memories </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">This world
                could ever hold </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">We are
                today years apart </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">But you
                will always live on in our hearts</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Of all the
                joys we had in life</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">However
                great of small </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">To have you
                in our lives </span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">Was the
                greatest gift of all. </span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: 'black'}} lang="EN-IE">*****</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">Just a
                prayer from the family who loved you</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>Just a memory fond and true</span><br />
                <span style={{background: 'white'}}>In our hearts you will live forever</span><br />
                <span style={{background: 'white'}}>Because we thought the world of you</span><br />
                <span style={{background: 'white'}}>Those precious years won't come again</span><br />
                <span style={{background: 'white'}}>When we were all together</span><br />
                <span style={{background: 'white'}}>But the love and care</span><br />
                <span style={{background: 'white'}}>you gave will stay with us forever.&nbsp; &nbsp;</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">*******</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">This day
                is remembered and quietly kept</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>No words are needed</span><br />
                <span style={{background: 'white'}}>We shall never forget</span><br />
                <span style={{background: 'white'}}>For those we love don't go away</span><br />
                <span style={{background: 'white'}}>They walk beside us every day</span><br />
                <span style={{background: 'white'}}>Unseen and unheard </span><br />
                <span style={{background: 'white'}}>But always near</span><br />
                <span style={{background: 'white'}}>So loved, so missed</span><br />
                <span style={{background: 'white'}}>And so very dear.</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">*******</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">Softly
                the leaves of memory fall</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>Gentle we gather and treasure them all</span><br />
                <span style={{background: 'white'}}>Unseen, unheard, you are always near</span><br />
                <span style={{background: 'white'}}>So missed, so loved, so very dear</span><br />
                <span style={{background: 'white'}}>No longer in our lives to share</span><br />
                <span style={{background: 'white'}}>But in our hearts you are always there</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">***********</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">As life
                goes on without you</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>As days turn into years</span><br />
                <span style={{background: 'white'}}>They hold a million memories</span><br />
                <span style={{background: 'white'}}>And a million silent tears</span><br />
                <span style={{background: 'white'}}>Although I cannot see you</span><br />
                <span style={{background: 'white'}}>Your love has kept me strong</span><br />
                <span style={{background: 'white'}}>The memories you have left me</span><br />
                <span style={{background: 'white'}}>Help me carry on.</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">******</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">We do not
                need a special day</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>To bring you to our minds,</span><br />
                <span style={{background: 'white'}}>The days we do not think of you</span><br />
                <span style={{background: 'white'}}>Are very hard to find.</span><br />
                <span style={{background: 'white'}}>Each morning when we awake</span><br />
                <span style={{background: 'white'}}>We know that you are gone,</span><br />
                <span style={{background: 'white'}}>And no one knows the heartache</span><br />
                <span style={{background: 'white'}}>As we try to carry on.</span><br />
                <span style={{background: 'white'}}>Our hearts still ache with sadness</span><br />
                <span style={{background: 'white'}}>And secret tears will flow.</span><br />
                <span style={{background: 'white'}}>What it meant to lose you</span><br />
                <span style={{background: 'white'}}>No one will ever know.</span><br />
                <span style={{background: 'white'}}>Our thoughts are with&nbsp; you,</span><br />
                <span style={{background: 'white'}}>Your place no one can fill,</span><br />
                <span style={{background: 'white'}}>In life we loved you dearly,</span><br />
                <span style={{background: 'white'}}>In death we love you still.</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">******</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">Our
                Hearts are full of memories</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>With pride we speak your name</span><br />
                <span style={{background: 'white'}}>Though life goes on without you</span><br />
                <span style={{background: 'white'}}>It will never be the same</span><br />
                <span style={{background: 'white'}}>So much in life has altered</span><br />
                <span style={{background: 'white'}}>So much in life is new</span><br />
                <span style={{background: 'white'}}>But one thing never changes</span><br />
                <span style={{background: 'white'}}>Our Love &amp; Thoughts of you. </span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">********</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">We miss
                your smile, your joking ways,</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>We miss the things you used to sav.</span><br />
                <span style={{background: 'white'}}>We keep your memories one by one,</span><br />
                <span style={{background: 'white'}}>The things you said, the things you done.</span><br />
                <span style={{background: 'white'}}>They bring a smile and many a tear,</span><br />
                <span style={{background: 'white'}}>And always a wish that you were still here.</span></span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">********</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">&nbsp;</span></p>
            <p className="MsoNormal" style={{marginBottom: '0in', marginTop: '0in', lineHeight: '150%'}}><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222', background: 'white'}} lang="EN-IE">Memories
                of you will never grow old,</span><span style={{fontSize: '12.0pt', lineHeight: '150%',  color: '#222222'}} lang="EN-IE"><br />
                <span style={{background: 'white'}}>They are locked in our hearts like letters of
                  gold</span><br />
                <span style={{background: 'white'}}>Silent thoughts, tears unseen,</span><br />
                <span style={{background: 'white'}}>Wishing your absence was only a dream.</span><br />
                <span style={{background: 'white'}}>You were a special person.</span><br />
                <span style={{background: 'white'}}>Who can never be replaced.</span><br />
                <span style={{background: 'white'}}>Since the day you left us,</span><br />
                <span style={{background: 'white'}}>There has been an empty space.</span><br />
                <span style={{background: 'white'}}>We feel you're still around us.</span><br />
                <span style={{background: 'white'}}>You are always on our minds.</span><br />
                <span style={{background: 'white'}}>And when we talk about you,</span><br />
                <span style={{background: 'white'}}>It's always of happy times.</span></span></p>
            <p className="MsoNormal"><span lang="EN-IE">&nbsp;</span></p>
          </div>
        </div>
      </div>
    )
  }
}

export default Help;