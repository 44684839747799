import React from "react";
import './RefundAndCancellationPolicy.scss';

class RefundAndCancellationPolicy extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="cancellation_and_refund_policy_page_container page_container">
        <div className="page_title">
              <div className="title_ghost"></div>
              <div className="title">Cancellation and Refund Policy</div>
          </div>
        <div className="policy_container">
          <div className="home_link" onClick={() => this.props.history.push("/")}>HOME</div>
          <div className="page_link">Cancellation and Refund Policy</div>
          <div className="sub_heading">CANCELLATION, REFUND, TERMINATION, TRANSFER, AND EXPIRATION OF ACCOUNT OR SERVICES</div>
          <p>You may cancel or terminate your account and/or use of the Services, with or without cause at any time, upon providing written notice to Amrityum. If you cancel or terminate your account or any Services or upon expiration of your account or service, your cancellation/termination/expiration will take effect immediately. Upon termination, your right to use your account or subject Services immediately ceases. Your notice to Amrityum of cancellation or termination must be sent via email or conventional mail to Amrityum's address as set forth in these Terms and Conditions or contact us page.</p>
          <p>Amrityum in its sole and absolute discretion, and at any time and with or without prior notice to you. may suspend, cancel, transfer, or terminate your password, account, any site within an account and/or use of any Services for any reason whatsoever (including and without limitation, due to lack of use, commercial use, cybersquatting, Amrityum’s resolution of any dispute among multiple persons claiming the right to use the same or similar accounts or services, or Amrityum's belief, in its absolute and sole discretion, that you have violated or acted inconsistently with the letter or spirit of these Terms and Conditions). In the event of a dispute or conflict among, or complaint from, users of the Services about another's right to establish, use, or maintain an account or Services, Amrityum reserves for itself the sole right to determine whether or how to resolve such dispute, conflict or complaint, with or without factual or other investigation.</p>
          <p>Upon expiration, or cancellation or termination by either party, of your account, your password, and/or use of any Services, you will have no right to any Material or Information you Submitted, and Amrityum may temporarily or permanently block access to, remove, deactivate, delete, and discard all such Material or Information contained therein (including but not limited to any Online Memorial). Amrityum accepts no liability for removed or deleted Material or Information. In addition, any contracts (verbal, written, or assumed) with respect to your account, Material or Information you Submit, and/or use of any Services, will be terminated at Amrityum's discretion. You agree that Amrityum shall not be liable to you or any third-party for any termination of your access to any Services.</p>
          <p>Upon suspension, cancellation, or termination of your account or your use of any Service (for whatever reason), there shall be no refund of money you paid to Amrityum.</p>
</div>

      </div>
    )
  }
}

export default RefundAndCancellationPolicy;