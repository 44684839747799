import React from "react";
import './LifeEvents.scss';
import coming_soon from "../../res/images/coming_soon.jpg";

class LifeEvents extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="life_events_page_container" style={{backgroundImage:"url('"+coming_soon+"')"}}>
        <div className="container">
          {/* <div className="title">Life Events</div> */}
          <div className="content">Keep coming back.<br></br> Something Big is on your Way.</div>
          <div onClick={() => this.props.history.goBack()} className="back_button">GO BACK</div>
        </div>
      </div>
    )
  }
}

export default LifeEvents;