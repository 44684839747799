import React from "react";
import "./ViewMemorial.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import memories from "../../../res/images/memorials/default_memories.jpg";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import candle from "../../../res/images/Candle.png";
import flower from "../../../res/images/Flower.png";
import note from "../../../res/images/feather.png";
import default_image from "../../../res/images/user_icon.png";

class ViewMemorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memorial: {
        fullname: "",
        date_of_birth: "",
        date_of_passing: "",
        locality: "",
        image_src: "",
        biography : ""
      },
      reject_reason: "",
      memorial_messages: [],
      memorial_tributes: [],
      memorial_memories: [],
      life_questions : [],
      tributes_pending_count : 0,
      condolence_message_pending_count : 0,
      memories_pending_count : 0,
      deleted_reason : ""
    }
    this.view_memorial_page_Ref = React.createRef();
    this.delete_reason_container_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.delete_tribute_message = this.delete_tribute_message.bind(this);
    this.delete_life_question_answer = this.delete_life_question_answer.bind(this);
    this.maximize_block = this.maximize_block.bind(this);
    this.minimize_block = this.minimize_block.bind(this);
    this.delete_notice = this.delete_notice.bind(this);
    this.delete_form_submit = this.delete_form_submit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.location.memorial_id);
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/ViewMemorialDetails",
      data: this.context.bind_session({
        'memorial_id': this.props.match.params.memorial_id
      }),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data);
      if (return_data.status == "success") {
        var milestones = return_data.milestones;
        if(!milestones.length){
          milestones = [
            {
              date : "",
              image : "",
              image_src : "",
              title : "",
              content : ""
            }
          ]
        }
        const memorial_tributes = return_data.memorial_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
        const memorial_messages = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "note");
        const memorial_memories = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "memory");
        const life_questions = return_data.life_questions;
        var life_question_answer_pending_count = 0;
        // life_questions.forEach((item) => {
        //   item.answers.forEach( (answer) => {
        //     if(answer.status == "PENDING"){
        //       life_question_answer_pending_count++;
        //     }
        //   });
        // });
        obj_this.setState({
          memorial : return_data.memorial,
          memorial_tributes: memorial_tributes,
          memorial_messages : memorial_messages,
          memorial_memories : memorial_memories,
          life_questions : life_questions,
          milestones : milestones,
          gallery_images : return_data.gallery_images,
          tributes_pending_count : memorial_tributes.filter((item) => (item.status == "PENDING")).length,
          condolence_message_pending_count : memorial_messages.filter((item) => (item.status == "PENDING")).length,
          memories_pending_count : memorial_memories.filter((item) => (item.status == "PENDING")).length,
          life_question_answer_pending_count : life_question_answer_pending_count

        }, () => {
          obj_this.view_memorial_page_Ref.current.style.visibility = "visible";
        });
      }else if(return_data.status == "no permission"){
        alert("You have no permission to see this data");
        obj_this.props.history.goBack();
      }
      obj_this.context.loading(0);
    }).fail(function () {
      alert("Network error!!");
    });
  }

  form_change(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  delete_notice(){
    if(!window.confirm("Are your sure")){
      return;
    }
    this.delete_reason_container_ref.current.style.display = "block";
  }

  delete_form_submit(e){
    e.preventDefault();
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/deleteMemorial",
      data : this.context.bind_session({ 
        memorial_id : this.state.memorial.id,
        deleted_reason : this.state.deleted_reason
      }),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        alert("Memorial deleted");
        obj_this.setState({
          deleted_reason : "",
        });
        obj_this.props.history.replace({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      }else{
        $.alert("Something Went Wrong. Please Try Again.");
      }
    obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    });
  }

  delete_tribute_message(memorial_tribute_id){
    if (!window.confirm("Delete this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteApprovedMemorialTribute",
      data: this.context.bind_session({ memorial_tribute_id: memorial_tribute_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const memorial_tributes = return_data.memorial_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
        const memorial_messages = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "note");
        const memorial_memories = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "memory");
        obj_this.setState({
          memorial_tributes: memorial_tributes,
          memorial_messages : memorial_messages,
          memorial_memories : memorial_memories,
          tributes_pending_count : memorial_tributes.filter((item) => (item.status == "PENDING")).length,
          condolence_message_pending_count : memorial_messages.filter((item) => (item.status == "PENDING")).length,
          memories_pending_count : memorial_memories.filter((item) => (item.status == "PENDING")).length,
        });
        alert("Memorial message deleted");
        // obj_this.props.history.replace('/Dashboard/Memorials/Approvals');
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  approve_tribute_message(memorial_tribute_id){
    if (!window.confirm("Approve this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveMemorialTribute",
      data: this.context.bind_session({ memorial_tribute_id: memorial_tribute_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const memorial_tributes = return_data.memorial_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
        const memorial_messages = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "note");
        const memorial_memories = return_data.memorial_tributes.filter((item) =>  item.tribute_type == "memory");
        obj_this.setState({
          memorial_tributes: memorial_tributes,
          memorial_messages : memorial_messages,
          memorial_memories : memorial_memories,
          tributes_pending_count : memorial_tributes.filter((item) => (item.status == "PENDING")).length,
          condolence_message_pending_count : memorial_messages.filter((item) => (item.status == "PENDING")).length,
          memories_pending_count : memorial_memories.filter((item) => (item.status == "PENDING")).length,
        });
        alert("Memorial message approved");
        // obj_this.props.history.replace('/Dashboard/Memorials/Approvals');
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  delete_life_question_answer(answer_id){
    if (!window.confirm("Delete this answer")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteApprovedLifeAnswer",
      data: this.context.bind_session({
        answer_id: answer_id,
        notice_id : this.state.memorial.id,
        notice_type : "memorial"
      }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          life_questions : return_data.life_questions,
        });
        alert("Answer deleted");
        // obj_this.props.history.replace('/Dashboard/Memorials/Approvals');
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  maximize_block(block){
    console.log(block);
    $("#"+block).heightAuto();
    $("#"+block).find(".maximize_button").css("display","none");
    $("#"+block).find(".minimize_button").css("display","block");
  }

  minimize_block(block){
    $("#"+block).css("height","54px");
    $("#"+block).find(".maximize_button").css("display","block");
    $("#"+block).find(".minimize_button").css("display","none");
  }

  render() {
    return (
      <div className="view_memorial_page_container page_container" ref={this.view_memorial_page_Ref}>
        <div className="deceased_basic_details_block">
          <div className="page_header">
            <div className="page_title">Memorial</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/Memorials/MyEntries">Memorial</Link>/
              <span>View</span>
            </div> */}
          </div>
          <div className="deceased_name_duration_grid">
            <div className="deceased_name">{this.state.memorial.first_name} {this.state.memorial.surname}</div>
            {isNaN(this.state.memorial.age)?"":
            <div className="duration">({new Date(this.state.memorial.date_of_birth).toDateString()} - {new Date(this.state.memorial.date_of_death).toDateString()})</div>
            }
            </div>
          <div className="deceased_image_and_details_grid">
            <div className="deceased_image_container">
              {/* <div className="deceased_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/"+this.state.memorial.entered_by+"/memorial_images/" + this.state.memorial.image_src + "')" }}></div> */}
              {this.state.memorial.image_src?
              <div className="deceased_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/memorial_images/"+this.state.memorial.image_src+"')"}}></div>
              :
              <div className="deceased_image" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
              }
            </div>
            <div className="deceased_details_grid">
            <div className="single_detail_container" style={{display:this.state.memorial.middle_name?"block":"none"}}>
                <div className="detail_label">Middle name</div>
                <div className="detail_value">{this.state.memorial.middle_name}</div>
              </div>
              <div className="single_detail_container"  style={{display:this.state.memorial.nick_name?"block":"none"}}>
                <div className="detail_label">Nick name</div>
                <div className="detail_value">{this.state.memorial.nick_name}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Gender</div>
                <div className="detail_value">{this.state.memorial.gender}</div>
              </div>
              <div className="single_detail_container"  style={{display:this.state.memorial.nee?"block":"none"}}>
                <div className="detail_label">Ne&eacute;</div>
                <div className="detail_value">{this.state.memorial.nee}</div>
              </div>
              <div className="single_detail_container"  style={{display:this.state.memorial.family_or_house_name?"block":"none"}}>
                <div className="detail_label">Family/House name</div>
                <div className="detail_value">{this.state.memorial.family_or_house_name}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Locality</div>
                <div className="detail_value">{this.state.memorial.locality}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">City/Town</div>
                <div className="detail_value">{this.state.memorial.city}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">State/Province/County/Region</div>
                <div className="detail_value">{this.state.memorial.state}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Date of death</div>
                <div className="detail_value">{new Date(this.state.memorial.date_of_death).toLocaleDateString('en-GB')}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Date of birth</div>
                <div className="detail_value">{new Date(this.state.memorial.date_of_birth).toLocaleDateString('en-GB')}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Age</div>
                <div className="detail_value">{this.state.memorial.age}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="deceased_infos_container">
          {/* <div className="single_info_container" id="biography" style={{display:this.state.memorial.biography?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">BIOGRAPHY</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("biography")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("biography")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="biography" dangerouslySetInnerHTML={{ __html: this.state.memorial.biography }}/>
            </div>            
          </div>    */}

          <div className="single_info_container" id="memory" style={{display:this.state.memorial_memories.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">MEMORIES & STORIES </div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("memory")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("memory")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="memories_container">
                {this.state.memorial_memories && this.state.memorial_memories.map((item, idx) => (
                  <div className="single_memory" 
                    key={idx} 
                    // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                  >
                    {item.image_src?
                      <img className="memory_image" src={item.image_src?process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/tribute_images/"+item.image_src:""} />
                      :
                      <img className="memory_image" src={memories} />
                    }
                    <div className="memory_details">
                      <div className="tribute_text" dangerouslySetInnerHTML={{ __html: item.tribute_text }}/>
                      <div className="posted_by">Posted by {item.written_by} on {new Date(item.published_date).toDateString()}</div>
                    </div>
                     {/* <i className="fas fa-check approve_tribute_button" 
                      style={{visibility:this.context.session.user_type == "admin" && item.status == "PENDING"? "hidden":"hidden"}}
                      onClick={() => this.approve_tribute_message(item.id)}
                    ></i> */}
                     <i className="fas fa-trash-alt delete_tribute_button"
                      // style={{visibility:this.context.session.user_type == "admin"? "visible":"hidden"}}
                      onClick={() => this.delete_tribute_message(item.id)}
                    ></i>
                  </div>
                ))}

              </div>
            </div>
          </div>

          <div className="single_info_container" id="condol_message"  style={{display:this.state.memorial_messages.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">TRIBUTES</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("condol_message")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("condol_message")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid">
                {this.state.memorial_messages && this.state.memorial_messages.map((item, idx) => (
                  <div className="single_message" 
                    key={idx} 
                    // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                  >
                    {/* <div className="tribute_type_image" style={{ backgroundImage: "url('" + note + "')" }}></div> */}
                    <div className="msg_container">
                      <div className="tribute_text">{item.tribute_text}</div>
                      <div className="posted_by">Posted by {item.written_by} on {new Date(item.published_date).toDateString()}</div>
                    </div>
                     {/* <i className="fas fa-check approve_tribute_button" 
                      style={{visibility:this.context.session && item.status == "PENDING"? "hidden":"hidden"}}
                      onClick={() => this.approve_tribute_message(item.id)}
                    ></i> */}
                     <i className="fas fa-trash-alt delete_tribute_button" 
                      style={{visibility:this.context.session? "visible":"hidden"}}
                      onClick={() => this.delete_tribute_message(item.id)}
                    ></i>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="single_info_container" id="candle_flower" style={{display:this.state.memorial_tributes.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">REMEMBRANCES <span style={{display:this.state.tributes_pending_count>0 ?"inline":"none"}} className="pending_count"> {this.state.tributes_pending_count} </span></div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("candle_flower")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("candle_flower")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid mementos_grid">
                {this.state.memorial_tributes && this.state.memorial_tributes.map((item, idx) => (
                  <div className="single_tribute" 
                    key={idx} 
                    // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                  >
                    <div style={{textAlign:"center"}}>
                      <div className="tribute_type_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')"}}></div>
                      <div className="posted_by">Posted by {item.written_by} on {new Date(item.published_date).toDateString()}</div>
                    </div>
                     <i className="fas fa-check approve_tribute_button" 
                      style={{display:this.context.session.user_type == "admin" && item.status == "PENDING"? "inline":"none"}}
                      onClick={() => this.approve_tribute_message(item.id)}
                    ></i>
                     <i className="fas fa-trash-alt delete_tribute_button"
                      style={{visibility:this.context.session.user_type == "admin"? "visible":"hidden"}}
                      onClick={() => this.delete_tribute_message(item.id)}
                    ></i>
                  </div>
                ))}

              </div>
            </div>
          </div>


          <div className="single_info_container" id="let's_reminisce"  style={{display:this.state.memorial_messages.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">LET’S REMINISCE</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("condol_message")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("condol_message")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="questions_grid">
                {this.state.life_questions && this.state.life_questions.map((question,index) => (
                <div className="single_question_container" 
                  key={index}
                  id={question.id}
                  style={{gridTemplateColumns:question.answers.length?"250px auto":"auto"}}
                  >
                  <div className="question_container">
                    <div className="question_no_label">Question {index+1}</div>
                    <div className="question">{question.question}</div>
                  </div>
                  <div className="answers_container">
                    {question.answers && question.answers.map((answer,idx) => (
                    <div className="single_answer_container" key={idx}>
                      <div>
                        <div className="answer">{answer.answer}</div>
                        <div className="posted_by">Posted by {answer.fullname} on {new Date(answer.created_at).toDateString()}</div>
                      </div>
                      <i className="fas fa-trash-alt delete_tribute_button" 
                        style={{visibility:this.context.session? "visible":"hidden"}}
                        onClick={() => this.delete_life_question_answer(answer.id)}
                      ></i>
                    </div>
                    ))}
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="buttons_container" style={{display:this.state.memorial.status == "PUBLISHED" || this.state.memorial.status == "PENDING"?"block":"none"}}>
          <ButtonTheme1
            label="Delete"
            className="delete_button"
            onClick={this.delete_notice}
          ></ButtonTheme1>
        </div>
        <div className="reject_reason_container" ref={this.reject_reason_container_ref}>
          <form className="reject_reason_form" onSubmit={this.reject_form_submit} method="post">
            <div>Reject Reason</div>
            <textarea name="reject_reason"
              rows={4}
              value={this.state.reject_reason}
              onChange={this.form_change}
              required
            />
            <button className="reject_form_submit_button">Submit</button>
            <i className="fa fa-times reject_form_close_button" onClick={() => { this.reject_reason_container_ref.current.style.display = "none"; this.setState({ reject_reason: "" }) }}></i>
          </form>
          
        </div>
        <div className="delete_reason_container" ref={this.delete_reason_container_ref}>
          <form className="delete_reason_form" onSubmit={this.delete_form_submit} method="post">
            <div className="delete_reason_title">Delete reason</div>
            <div className="delete_reason_form_body">
              <textarea name="deleted_reason"
                rows={4}
                value={this.state.deleted_reason}
                onChange={this.form_change}
                required
                placeholder="Type here.."
              />
              <button className="delete_form_submit_button">Submit</button>
            </div>
            <div className="delete_form_close_button" onClick={() => { 
              this.delete_reason_container_ref.current.style.display = "none"; 
              this.setState({ 
                deleted_reason : "",
                memorial_id : "" }) }}>x</div>
          </form>
        </div>
      </div>
    )
  }
}

export default ViewMemorial;
ViewMemorial.contextType = MyContext;