import {Component} from "react";
import MyContext from "../MyContext";
import "./Loading.scss";

class Loading extends Component{
  render(){
    return(
      <MyContext.Consumer>
        {context => (
          <div className="loading_page_container" style={{display:this.context.show_loading?"block":"none"}}>
            <div className="loading_container">
              <div className="loadingio-spinner-ellipsis-pcv7kgdlh4"><div className="ldio-kiwae14cqa9">
                <div></div><div></div><div></div><div></div><div></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    )
  }
}

export default Loading;
Loading.contextType = MyContext;