import React from "react";
import './FAQs.scss';

class FAQs extends React.Component{
  constructor(props){
    super(props);
    this.privacy_policy_ref = React.createRef();
  }

  render(){
    return(
      <div className="faqs_page_container page_container" ref={this.privacy_policy_ref}>
        <div className="page_title">
              <div className="title_ghost"></div>
              <div className="title">Amrityum Frequently Asked Questions</div>
          </div>
        <div className="faqs_container">
          <div className="home_link" onClick={() => this.props.history.push("/")}>HOME</div>
          <div className="page_link">FAQs</div>
          <p>
            This section should answer any questions you have with regard to using the site. If you can't find the answer to your question here, you can get in touch with us either using the contact form provided or by emailing us at <span>info@amrityum.com</span>
          </p>
          <div className="sub_heading">What is amrityum.com?</div>
          <p>
            Amrityum is an independent online portal for publishing death/obituary notices, creating memorials, making family announcements, logging of events and landmarks in an individual’s life such as birth, marriage, with relevant details. Creating and maintaining a family tree  and life stories are yet another features of Amrityum and a way to celebrate family bond. 
          </p>
          <p>
            Traditionally all notices and announcements were posted on newspapers but recently online publication of these datils are the best as any details can be changed and updated in real-time, when necessary, without any issues. We will try our best to keep your death/family notices, memorials, pet tributes, lifelog entries and family trees that you make online forever so that you can revisit to leave tributes, candles, and memories whenever you want.
          </p>
          <p>
            It also provides space to create a memorial page for the dear departed. The website also has provision to add pet tributes. The members of the public who view the services have an option to add sympathy/condolence notices or contribute to the content in certain areas after signing in. This site is not related to any newspaper and therefore to place an advert in the Newspaper you need to contact them directly. In short, this site is meant to be a virtual space of remembrance, celebration, and commemoration for the deceased as well as to share and celebrate some key ‘Life Events’ as well as connecting or reconnecting with the help of Family Tree in the life of the living. At the touch of a button, you can revisit the life of a loved one through their biography & timeline memories & stories, leave a tribute message, view their life through photographs and videos and connect with family members and friends.
          </p>
          <div className="sub_heading">What makes Amirtyum special? </div>
          <p>
            Amrityum is a one stop online portal of information and depository of memories to preserve and commemorate the lives of those who have passed away. This could be one of the precious legacies that you leave for the future generations about those who have gone before them documenting and celebrating their lives. Today, almost all our photos and videos are created and stored digitally, and our family and friends are spread across the country and the world. This an apt platform to preserve and share their lives stories and to inspire and to be inspired. Rather than save the images in a personal cloud storage that only you can see, amrityum.com lets you and your family collect your favourite memories, share stories, and give your loved one a dignified legacy for generations to come. In addition to preserving the memories of the deceased, Amrityum also offers a digital platform to celebrate various life events like arrival of a baby, first birthday, first day at school, graduations, first day at college, significant birthdays, new job, new home, marriage, retirement, anniversaries and so on as one goes through various stages of life. Again, by creating a Family Tree people can explore their roots and present their family legacies to savour togetherness family bond
          </p>
          <div className="sub_heading">If I sign up with Google or Facebook, will Amrityum post on my behalf?</div>
          <p>No, Amrityum does not post anything on your behalf. We simply use your email address and name to create your account.</p>
          <div className="sub_heading">How much does it cost to avail of the services of Amrityum?</div>
          <p>There are basically two types of accounts.</p>
          <p>In order to contribute to an already existing profile by comments and answering questions is always free after creating an account. </p>
          <p>If you would like to get any of our services, we ask you to make buy a subscription. Amrityum is committed to make the subscription fee as low as possible. You may buy a subscription for 1 year (150 INR), 2 years (200 INR) or 3 years (250 INR) and may renew at the end of the subscription period. </p>
          <p>Amrityum reserves the right to offer free services from time to time for a specific period and may be chargeable after that.  </p>
          <div className="sub_heading">Can I write out my legacy and create my own memorial before I die? </div>
          <p>
            Although it may sound strange, we firmly believe that everyone should be able to plan and document their lives and its ups and downs the way one perceives as the most accurate. 
          </p>
          <p>
          When you sign up to amrityum.com, you can write out your own biography, create timeline, and begin uploading your treasured photos and videos. In order to operate your account after you pass away, it’s important that you let the person you designate know the account details and your wishes how the account should be handled so that they can gain control of your profile and add any other information to keep your memory alive. Amrityum.com does not take responsibility if you share your login details and if that happens to be misused. 
          </p>
          <div className="sub_heading">How can I create a death notice or memorial?</div>
          <p>Click on the ‘sign up’ link at the top right of the page. You may sign up directly with amirityum or you may sign in using Google or Facebook. You'll be asked to input some basic information about your loved one to get their memorial started. You will also have to fill out some information about yourself because you become the administrator of their memorial/notices or of the posts.</p>
          <div className="sub_heading">How long the Amrityum services will stay online? </div>
          <p>It is our wish that all your posts will last forever on Amrityum subject to your subscription. We hope this will last many generations making it as a reliable document of their legacies.</p>
          <div className="sub_heading">Can I use images and other content from the internet to post?</div>
          <p>Amrityum does not take responsibility for the content you post on our website. It’s your responsibility to make sure that the content you post have no copyright infringement. </p>
          <div className="sub_heading">How can I report errors if I spot them on amrityum and request them to be corrected?</div>
          <p>We are happy to correct any errors you come across on Amrityum. Please send a detailed e-mail to info@amrityum.com and be sure to include all details of error and correction requested along with your contact details. This will anyway be done only with the approval from the owner of the post if you are not the owner yourself.</p>
          <div className="sub_heading">How can I edit or delete what I have created on Amrityum?</div>
          <p>In order to edit or delete what you have created, please log in to your account and you will be directed to your dashboard. If you are the creator of a post, you can edit and delete. If you only commented or added to someone else creations, then you cannot edit or delete. </p>
          <div className="sub_heading">How do I share the creations I made on amrityum.com by email or other social media platforms?</div>
          <p>One of the easiest ways to share your creations or a page your like is by sharing Amrityum link. You can also share the page with other people immediately after you create the page with our ‘Share’ option.</p>
          <div className="sub_heading">Is there a limit to how much content I can add to Amrityum?</div>
          <p>Once the subscription is purchased, your account may be unlimited except in the case of images you upload which will be a maximum of 50. If you do need to add more content, such as lots of video, you can simply increase your allowance at any time for a small contribution towards the extra memory storage costs.</p>
          <div className="sub_heading">Can we add links to other websites on Amrityum?</div>
          <p>Whilst you can add links to other websites, these have to be text links rather than html links, in order to prevent possible problems from misuse of the service through commercial links being added, as well as links to webpages that are not maintained and then become invalid.</p>
          <div className="sub_heading">Will my Tribute be found on Google searches?</div>
          <p>Google indexes all websites on the internet and it is likely that it will be indexed by them, however this may not be for some days or even weeks after your Amrityum page has been created. Please remember that all content on Amrityum is public and we do not have private mode yet to keep your post private. </p>
          <div className="sub_heading">Is my Amrityum page content backed up?</div>
          <p>We run comprehensive back-ups of all Amrityum information to help safeguard your Amrityum page and content but duplication is always an advisable data-preservation strategy so we do recommend that you also copy and save content from your Amrityum page. In particular, since we hold some types of media in an optimised or compressed format, we always suggest that you keep your own independent copy of the original files that you add to your site.</p>
          <div className="heading2">Registration</div>
          <div className="sub_heading">Do I need to Register to use the site?</div>
          <p>You do not need to register to search and view death/family notices, memorial pages, pet tributes or Life Events. However, if you are not a registered user, the user experience will be different, and you won’t be able to post, or comment on the website and edit what you have contributed. </p>
          <div className="sub_heading">How can I access without an account?</div>
          <p>Your Amrityum page has public access and therefore anyone who visits this website can view the posts. However, you will need to be logged in as the administrator in order to see and access your Settings panel. Please note that anyone who wishes to actually post content onto your Amrityum page, such as a message or photo, will need to register with the service so that they can be authenticated.</p>
          <div className="sub_heading"> I forgot my password. How do I retrieve it? </div>
          <p>If you have forgotten your password, you can request a new one on the login page by clicking on, ‘Forgot your password?’ A new email will be sent to you to reset your password to a new one.</p>
          <div className="heading2">Writing a Sympathy/Condolence Message</div>
          <div className="sub_heading">How to I leave a sympathy/condolence message?</div>
          <p>You can leave a condolence/sympathy message on Amrityum by simply clicking on the ‘Leave a Condolence’ button which appears with all Death Notices (this is where your message will appear). The message will be displayed with the death notice and viewable as long as the death notice remains on Amrityum. There is no charge to leave your message but will be limited by certain characters. If you have a bigger message you may think of positing under ‘memories & stories’. This feature may be subject to a premium plan and if the creator of the profile has enabled the access, you may be able to use it. </p>
          <div className="sub_heading">Tips for writing a condolence/sympathy notice.</div>
          <p>Please pay attention to the following points.</p>
          <ul className="main_list">
            <li>Acknowledge the loss and refer to the deceased by name.</li>
            <li>Express your sympathy and support.</li>
            <li>Note one or more of the deceased's special qualities that come to mind.</li>
            <li>Include your favourite memory of the deceased.</li>
            <li>Remind the bereaved of his or her personal strengths and/or special qualities.</li>
            <li>Offer to help the survivor in a specific way which will be a great support to them. </li>
            <li>End with a thoughtful hope, wish or sympathy expression.</li>
          </ul>
          <div className="heading2">Family Notices/In Loving Memory Notices</div>
          <p>There are few experiences in life that touch us as profoundly as losing a loved one. It can be a time when our lives are turned upside-down and for a while we may drift along, carried by the goodness of others.
            But life goes on and with help and support we move forward, always remembering. Family notices are about remembering. They offer us one way to remember our loved ones publicly and to thank those who helped us during our time of need. </p>
          <p>Family notices include Thank You Notes, End of Mourning Period ceremonies/gathering, Anniversaries and Birthday Remembrance and at set times during the year, families also publish notices to remember their loved ones on Mother’s Day, Father’s Day and at major religious festival time.</p>
          <p>The following will be a short description of what they are and a template to guide you put together one by yourself. </p>
          <p>Family Notices that appear on the print media are often expensive depending on their edition, circulation, popularity, and the content you would want to publish. Amrityum.com offers another option for publishing family notices. You pay a modest fee, templates are provided, there is no word limit, you can add a photo. Again, depending on the plan purchased, you may add more photo to the gallery section.  </p>
          <div className="sub_heading">What is a Thank You/Acknowledgement Note?</div>
          <p>The Thank you note section comes under Family notices. This Thank You Note is for the family’s of the deceased to acknowledge and thank everyone of those who helped and supported them in any way, before, during and after the funeral.</p>
          <p>We provide templates for the Acknowledgement as well as a checklist of those you may wish to thank in your acknowledgement. You can also add a photo of your loved one and include details of any funeral ceremony or Memorial Service being held.</p>
          <p>Click here to see how an Acknowledgement looks on amrityum.com</p>
          <div className="sub_heading2">When to publish an Acknowledgement?</div>
          <p>We suggest publishing the thank you note to coincide with the end of mourning period ceremonies and that would be a fitting tribute to all who stood by in during the tough time. Some others require more time to adjust with the loss of a loved one and in that case, you may publish a thank you note to coincide with the First death Anniversary.</p>
          <div className="sub_heading2">Who Should Be Thanked?</div>
          <p>In short thank you notes or poems, you'll want to keep the acknowledgement more general and briefer to cover everyone. In longer public postings, it is appropriate to call out anyone who went above and beyond. For example, you should mention:</p>
          <ul className="main_list">
            <li>Visitors at home, at visitation/wake, and funeral attendees</li>
            <li>Friends and relatives who didn't attend but otherwise sent sympathies (cards, phone calls, flowers, etc.)</li>
            <li>Funeral service providers, such as religious leaders and musicians, event managers, light and sound</li>
            <li>Anyone else who made a special contribution</li>
            <li>The doctors, matron, nurses and staff of (name of hospital)</li>
            <li>Everyone helped you with their sensitive and professional handling of arrangements </li>
          </ul>
          <div className="sub_heading2">Do you provide any sample wordings from any of the services you offer?</div>
          <p>We do. Please check out our help page. </p>
          <p>Please check our contact page for more questions regarding Amrityum account. </p>
          <p>If you can’t find answers to your questions or concerns please use our contact form on the contact us page. We will get back to you as soon as we can. </p>
        </div>
      </div>
    )
  }
}

export default FAQs;