import "./NoSearchResults.scss";
import { AiOutlineFileSearch } from "react-icons/ai";

const NoSearchResults = () => {
  return(
    <div className="no_results_container">
      <div className="no_result_icon"><AiOutlineFileSearch/></div>
      <div className="no_results">Sorry, no results found!</div>
      <div className="spell_check">Please check the spelling or try searching for something else</div>
    </div>
  )
}

export default NoSearchResults;


