import { Component } from "react";
import "./CustomAlert.scss";
import $ from "jquery";

class CustomAlert extends Component{
  constructor(props){
    super(props);
    this.showCustomAlert = this.showCustomAlert.bind(this);
    this.hideCustomAlert = this.hideCustomAlert.bind(this);
    this.show_alert_time_out="";
    this.callback="";
    this.state={
      message: "hey",
      state: "hidden"
    };
    
    $.alert = this.showCustomAlert;
  }
  componentDidMount(){

    this.props.history.listen((location, action) => {
      this.hideCustomAlert();
    });
  }
  showCustomAlert(message="",callback){
    this.callback = typeof callback != undefined?callback : "";
    clearTimeout(this.show_alert_time_out);
    $(".custom_alert_box .custom_alert_message").html(message);
    $(".custom_alert_container").css("display","block");
    this.show_alert_time_out = setTimeout(() => {
      $(".custom_alert_container").css("opacity","1");
    }, 0);
  }
  hideCustomAlert(){
    clearTimeout(this.show_alert_time_out);
    $(".custom_alert_container").css("opacity","0");
    this.show_alert_time_out = setTimeout(() => {
      $(".custom_alert_container").css("display","none");
      $(".custom_alert_box .custom_alert_message").html("");
    }, 200);
    if(this.callback)
      this.callback();
  }
  render(){
    return(
          <div className="custom_alert_container">
          <div className="custom_alert_box">
            <div className="custom_alert_message">
            </div>
            <br/>
            <button className="custon_alert_ok_button" onClick={this.hideCustomAlert}>Ok</button>
          </div>
        </div>
    );
  }
}

export default CustomAlert;