import React from "react";
import './Features.scss';
import {RiListCheck2} from "react-icons/ri";

class Features extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="feature_page_container page_container">
        <div className="features_title">Features</div>
        <div className="features_description">
          Creating lasting memories and honouring lives is central to our ethos at
          Amrityum. At Amrityum, we firmly believe every living person and their tamed
          companions have a story to tell which is worth listening to and documented to
          cherish for generations to come. The incredible lives we intend to capture
          here on our online space in all their richness need equally matching features
          to contain them. Therefore, we bring together several excellent features on
          our website to empower you to carry out this enormous task well. We pride
          ourselves on offering the following outstanding features.
        </div>
        <div className="features_grid">
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e4b422"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Reminisce Forever</div>
            <div className="feature_description">
              Every living being is not to be gone to oblivion 
              once its life here on earth reaches its full circle. 
              Everyone did contribute to the world in their unique 
              way. This distinctive contribution is to be certainly 
              remembered forever.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#2cb1de"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Share</div>
            <div className="feature_description">
              All the content you create on Amrityum can easily 
              be shared on popular social media networks.  
              So, you and everyone connected with you can start 
              directing people to your loved one's page with a 
              simple click.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e37f43"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Create together</div>
            <div className="feature_description">
              Invite everyone to connect, contribute, comment, and share their 
              precious memories, messages, photos, videos and so on to 
              build together an everlasting legacy of your dear ones to 
              visit again and again. 
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#6c46da"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Photo & Video Library</div>
            <div className="feature_description">
              Our photo gallery provides space to keep the most 
              cherished images, while the video gallery accepts 
              YouTube links honouring your loved one or of your 
              own life logging.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#50ac23"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Everyone Can Contribute</div>
            <div className="feature_description">
              Everyone can contribute to the online memorial once 
              logged in, making the experience of documenting a loved one’s 
              life collaborative and complete.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#f14493"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Secure and Safe</div>
            <div className="feature_description">
              Industry-standard SSL Certificate encryption 
              secures your content on Amrityum. Therefore, 
              we rely on strong security to protect everything 
              you post on our website.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#50ac23"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Forgather </div>
            <div className="feature_description">
              Collect all you can about yourself, your forever 
              missed ones and your loving pets and share it with 
              everyone. Turn your page to a virtual place of 
              information you securely gather together to preserve 
              and pass on to generations.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e4b422"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Donations to Charity</div>
            <div className="feature_description">
              Amrityum facilitates donations to designated 
              charities directly. We recommend several charities 
              from different spheres of life after meticulous 
              scrutiny. All are welcome to suggest charities of 
              your choice for our consideration.    
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#6c46da"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Biography </div>
            <div className="feature_description">
              Record your life, the life of your dear departed 
              ones and your beloved pets effortlessly. Narrate 
              unforgettable stories, important events, excellent 
              qualities, contributions, and connections in a person’s 
              life for everyone to read and be inspired.  
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#f14493"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Detailed Funeral Arrangements</div>
            <div className="feature_description">
              Provide detailed information about time, date and place 
              of visitation(s), service, burial/cremation/interment, 
              contact, and other required information. 
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#2cb1de"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Link In Memoriam to Memorial</div>
            <div className="feature_description">
              Effortlessly link In Memoriam Notices to an 
              already created memorial, increasing overall user 
              engagement, and keeping all related notices together. 
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#50ac23"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Link Death Notice to Memorial</div>
            <div className="feature_description">
              Easily connect a Death Notice to its respective 
              Memorial if that has already been created. Visitors 
              to the Memorial page can navigate the Death Notice, 
              making their visit to the page complete.  
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e27e42"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Add Family Members</div>
            <div className="feature_description">
              Provide a complete list of survivors and those who 
              preceded your loved one in death while creating a 
              Death Notice or Memorial page.  
            </div>
          </div>
          
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e4b422"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Timeline </div>
            <div className="feature_description">
              Create a personalised, chronological timeline of your 
              departed dear ones' life virtually to celebrate every 
              milestone which was important to them.  
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#2cb1de"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Feedback</div>
            <div className="feature_description">
              We are all ears to your suggestions and learn about 
              how Amrityum's features help you honour and remember 
              a deceased loved one.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e4b422"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Condolences/Messages</div>
            <div className="feature_description">
              We provide meaningful space on all notices for 
              visitors to the page to leave their messages of 
              support and encouragement. You can simply write a 
              message, and will be published for everyone to see. 
              It's a great way to participate even when people 
              are far away.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#2cb1de"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Funeral Livestream </div>
            <div className="feature_description">
              We understand it is not always practical to attend 
              a loved one's funeral in person, although you long 
              for it, and you may wish to join if Livestreamed. 
              Amrityum provides a dedicated space to post and 
              share funeral Livestream links making it easily 
              accessible to everyone.   
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#f14493"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Edit & Update</div>
            <div className="feature_description">
              You control the data you enter on Amrityum, giving 
              you the exclusive option to edit and update the 
              content and information you uploaded on the site. 
              It is a handy feature, especially when you want to 
              make last-minute changes to an event and still be 
              able to efficiently communicate them.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#2cb1de"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Announcements</div>
            <div className="feature_description">
              Do you wish to send any message to all who visit 
              your page or your departed loved ones' memorial? 
              It's easy. Amrityum lets you instantly communicate 
              any notifications or updates with everyone.    
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#50ac23"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Memories & Stories  </div>
            <div className="feature_description">
              Visitors to our memorial page can share memories & 
              stories with a set of images. It's a great way to 
              bring together a complete picture of the departed 
              loved ones for everyone to cherish them.    
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e27e42"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Let's Quiz</div>
            <div className="feature_description">
              We value the active participation and collaboration 
              of everyone visiting your deceased loved one's 
              Memorial page. Hence, we have this feature to reflect 
              further on your loved ones together.    
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e27e42"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Prayer & Reflection</div>
            <div className="feature_description">
              Add prayers and reflections to give and receive 
              spiritual support and mutual enrichment from each 
              other, especially when faced with the irreplaceable 
              loss of a loved one.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#6c46da"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Preview</div>
            <div className="feature_description">
              Before you submit primary content for any of the 
              services offered, you will be able to see a preview 
              of it. It helps review the content and to know how 
              it appears when published.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#50ac23"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Special Instructions</div>
            <div className="feature_description">
              Amrityum provides space for special instructions 
              while publishing a funeral notice. This feature is 
              handy when a bereaved family wants to communicate 
              an important message to everyone.    
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#f14493"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Obituary</div>
            <div className="feature_description">
              Announce death notices with all relevant information you 
              think is the most appropriate at the time and share it 
              with everyone instantly. Add to the published content or 
              details as you go along.  
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e4b422"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Contact Page Admin</div>
            <div className="feature_description">
              We understand that you may need to contact the 
              page admin to convey a special message. Our page 
              design lets you do it effortlessly when situations 
              demand it.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#6c46da"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Print</div>
            <div className="feature_description">
              Get all information provided in an easily printable 
              format with the push of a button to archive a 
              physical copy or for immediate offline use.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon">
              <RiListCheck2 />
            </div>
            <div className="feature_name">Enduring</div>
            <div className="feature_description">
              We believe memorials and everything else, for that matter, you create on
              Amrityum have an enduring value. Everyone can regularly visit your deceased
              loved one&#39;s page to treasure sweet memories, no matter where they are. The
              page also serves as an authentic document of their lives for future
              generations.
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#f14493"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Thank You Note</div>
            <div className="feature_description">
              Have you ever thought about the best way to thank 
              everyone who stood by you, especially during your 
              bereavement? You can now perform that duty with 
              ease by publishing an acknowledgement on Amrityum. 
              No word limits and remain on it permanently.    
            </div>
          </div>
          <div className="single_feature_container">
            <div className="feature_icon" style={{backgroundColor:"#e4b422"}}>
              <RiListCheck2 />
            </div>
            <div className="feature_name">Mementoes </div>
            <div className="feature_description">
              Sometimes words alone are not adequate to convey 
              all we want, especially when we want to express 
              sincere sympathy or congratulations. A variety of 
              mementoes readily available will assist you to 
              express yourself fully.    
            </div>
          </div>          
        </div>
      </div>
    )
  }
}

export default Features;