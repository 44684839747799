import React from "react";
import "./BlogList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AddButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {Link} from "react-router-dom";
import {FcFullTrash} from "react-icons/fc";
import {FaAngleRight, FaEye, FaPencilAlt} from "react-icons/fa";
import ReactPaginate from 'react-paginate';

class BlogList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      blogs : [],
      search_input : "",
      offset_value : 0,
      current_page : 0,
      per_page : 10,
      page_count : 0,
      page_no_input : 1
    }
    this.blog_list_page_Ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.delete_blog = this.delete_blog.bind(this);
    this.getBlogs = this.getBlogs.bind(this);
    this.notices_XHR = {abort : () => {}}
  }
  componentDidMount(){
    if(this.props.history.location.state && typeof this.props.history.location.state == "object" && this.props.history.location.state.hasOwnProperty('offset_value')){
      this.setState({
        offset_value : this.props.history.location.state.offset_value,
        search_input : this.props.history.location.state.search_input,
        current_page : this.props.history.location.state.current_page,
        page_no_input : this.props.history.location.state.page_no_input
      },() => {
        window.scrollTo({
          top : this.blog_list_page_Ref.current.offsetTop - 80,
          behavior : "smooth"
        });
        this.getBlogs();
      });
    }else{ 
      this.getBlogs();
    }
  }

  form_change(e){
    this.state.offset_value = 0;
    this.state.current_page = 0;
    this.setState({
      [e.target.name] : e.target.value,
      page_no_input : 1
    },() => {
      this.getBlogs();
    });
    
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    }); 
  }

  page_no_form_submit(e){
    window.scrollTo({
      top : this.blog_list_page_Ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getBlogs();
    });
  }

  handle_page_click(e){
    window.scrollTo({
      top : this.blog_list_page_Ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getBlogs()
    });
  }

  getBlogs(){
    var obj_this = this;
    this.context.loading(1);
    this.notices_XHR.abort();
    this.notices_XHR = $.ajax({
      url : process.env.REACT_APP_API + "/api/allBlogs",
      data :this.context.bind_session({
        search_phrase : this.state.search_input,
        offset_value : this.state.offset_value
      }),
      type : "POST"
    }).done(function(return_data){
      // console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          blogs : return_data.blogs,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page)
        }, () => {
          obj_this.context.loading(0);
          obj_this.blog_list_page_Ref.current.style.visibility = "visible";
          obj_this.blog_list_page_Ref.current.style.opacity = 1;
        });
        obj_this.context.setHistoryState({
          blogs : return_data.blogs,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
          offset_value : obj_this.state.offset_value,
          current_page : obj_this.state.current_page,
          search_input : obj_this.state.search_input,
          page_no_input : obj_this.state.page_no_input,
        },obj_this.props.history);
      }else{
        obj_this.context.loading(0);
        $.alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (jqXHR, textStatus) {
      if (textStatus != "abort") {
        alert("Network error");
      } 
    });
  }

  delete_blog(blog_id){
    if(!window.confirm("Are you sure?")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/deleteBlog",
      data : this.context.bind_session({blog_id: blog_id}),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      const blogs = obj_this.state.blogs.filter((item) => (item.id != blog_id));
      if(blogs.length){
        obj_this.setState({
          blogs : blogs
        });
      }else{
        obj_this.setState({
          offset_value : (obj_this.state.current_page -1) * obj_this.state.per_page,
          current_page : obj_this.state.current_page-1,
        },() => {
          obj_this.getBlogs();
        })
      }
      $.alert("Blog deleted")
     }else{
      $.alert("Something Went Wrong. Please Try Again.");
     }
     obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    })
  }
 
  render(){
    return(
      <div className="blog_list_page_container page_container" ref={this.blog_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Blogs</div>
          <div className="search_container">
            <input
              className="search_input"
              name="search_input"
              type="text"
              value={this.state.search_input}
              onChange={this.form_change}
              placeholder="Search..."
            />
            <i className="fas fa-search search_icon"></i>
          </div>
          <div className="new_blog_button_container">
            <Link to="/Dashboard/Blogs/New">
              <AddButtonTheme1 label="Publish a Blog"/>
            </Link>
          </div>
        </div>
        <div className="custom_table_theme1">
          <div className="table_row header">
            <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Blog</div>
            <div className="table_cell">Published Date</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.blogs && this.state.blogs.map((item,idx) => (
            <div className="table_row body" key={idx} >
              <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
              <div className="table_cell">{item.title}</div>
              <div className="table_cell">{new Date(item.published_date).toLocaleDateString('en-GB')}</div>

              <div className="table_cell">
                <div className="action">
                  <Link className="tooltip approve" to={{
                      pathname: "/Blog/Preview/" + item.id,
                      url : "/Dashboard/Blogs/MyEntries",
                      previousState : {
                        offset_value : this.state.offset_value,
                        current_page : this.state.current_page,
                        search_input : this.state.search_input,
                        page_no_input : this.state.page_no_input,
                      }
                      }}>
                    <span className="tooltipText approve">View</span>
                    <FaEye
                      className="approve_button"/>
                  </Link>
                  <Link className="tooltip edit" to={{
                    pathname : "/Dashboard/Blogs/Edit/"+item.id,
                    previousState : {
                      offset_value : this.state.offset_value,
                      current_page : this.state.current_page,
                      search_input : this.state.search_input,
                      page_no_input : this.state.page_no_input,
                    }
                    }}>
                    <span className="tooltipText edit">Edit</span>
                    <FaPencilAlt className="edit_button"/>
                  </Link>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_blog(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.blogs.length?"none":"block"}}>No results.</div>
        </div>
        <div className="pagination_container" style={{display:this.state.blogs.length && this.state.page_count > 1?"block":"none"}}>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handle_page_click}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.current_page}
          />
          <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span>
              <FaAngleRight/>
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default BlogList;
BlogList.contextType = MyContext;