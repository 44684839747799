import React from "react";
import "./ViewDeathNotice.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";
import candle from "../../../res/images/Candle.png";
import flower from "../../../res/images/Flower.png";
import note from "../../../res/images/feather.png";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import default_image from "../../../res/images/user_icon.png";

class ViewDeathNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      death_notice: {
        first_name: "",
        middle_name: "",
        nick_name: "",
        nee: "",
        family_or_house_name: "",
        surname: "",
        date_of_birth: "",
        gender : "",
        date_of_passing: "",
        locality: "",
        image_src: "",
      },
      reject_reason: "",
      death_notice_messages: [],
      death_notice_tributes: [],
      death_notice_prayers: [],
      death_notice_memoirs: [],
      tributes_pending_count : 0,
      condolence_message_pending_count : 0,
      prayer_pending_count : 0,
      life_question_answer_pending_count : 0,
      deleted_reason : "",
      short_age : "",
      full_age : "",
    }
    this.view_death_notice_page_Ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.delete_tribute_message = this.delete_tribute_message.bind(this);
    // this.approve_tribute_message = this.approve_tribute_message.bind(this);
    this.maximize_block = this.maximize_block.bind(this);
    this.minimize_block = this.minimize_block.bind(this);
    this.delete_notice = this.delete_notice.bind(this);
    this.delete_form_submit = this.delete_form_submit.bind(this);
    this.delete_reason_container_ref = React.createRef();
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/viewDeathNoticeDetails",
      data: this.context.bind_session({
        'death_notice_id': this.props.match.params.death_notice_id
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        find_short_full_age(return_data.death_notice);
        function find_short_full_age(death_notice){
          if(death_notice.below_one_year){
            var age_in_months = death_notice.age_in_months;
            var short_age ="", full_age="";
            if(age_in_months){
              age_in_months = age_in_months>1?age_in_months+" Months":age_in_months+" Month";
              short_age = age_in_months;
              full_age = age_in_months+" ";
            }
            var age_in_days = death_notice.age_in_days;
            if(age_in_days){
              age_in_days = age_in_days>1?age_in_days+" Days":age_in_days+" Day";
              short_age = short_age?short_age:age_in_days;
              full_age+=age_in_days;
            }
            // console.log(short_age, full_age)
            obj_this.setState({
              short_age : short_age,
              full_age : full_age,
            })
          }
        }

        const death_notice_tributes = return_data.death_notice_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
        const death_notice_messages = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "note");
        const death_notice_prayers = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "prayer");
        const death_notice_memoirs= return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs");
        obj_this.setState({
          death_notice : return_data.death_notice,
          death_notice_tributes: death_notice_tributes,
          death_notice_prayers : death_notice_prayers,
          death_notice_messages : death_notice_messages,
          death_notice_memoirs : death_notice_memoirs,
          // tributes_pending_count : death_notice_tributes.filter((item) => (item.status == "PENDING")).length,
          // condolence_message_pending_count : death_notice_messages.filter((item) => (item.status == "PENDING")).length,
          // prayer_pending_count : death_notice_prayers.filter((item) => (item.status == "PENDING")).length,
        }, () => {
          obj_this.view_death_notice_page_Ref.current.style.visibility = "visible";
        });
      }else if(return_data.status == "no permission"){
        alert("You have no permission to see this data");
        obj_this.props.history.goBack();
      }
      obj_this.context.loading(0);
    }).fail(function () {
      alert("Network error!!");
    });
  }

  form_change(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  delete_notice(id){
    if(!window.confirm("Are your sure")){
      return;
    }
    this.delete_reason_container_ref.current.style.display = "block";
  }
  
  delete_form_submit(e){
    e.preventDefault();
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/deleteDeathNotice",
      data : this.context.bind_session({
        death_notice_id : this.state.death_notice.id,
        deleted_reason : this.state.deleted_reason
      }),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        alert("Death notice deleted");
        obj_this.setState({
          deleted_reason : ""
        });
        obj_this.delete_reason_container_ref.current.style.display = "none";
        obj_this.props.history.replace({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      }else{
        $.alert("Something Went Wrong. Please Try Again.");
      }
      obj_this.context.loading(0);
     }).fail(function(){
       alert("Network error");
     });
  }

  delete_tribute_message(death_notice_tribute_id){
    if (!window.confirm("Delete this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteApprovedDeathNoticeTribute",
      data: this.context.bind_session({ death_notice_tribute_id: death_notice_tribute_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const death_notice_tributes = return_data.death_notice_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
        const death_notice_messages = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "note");
        const death_notice_prayers = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "prayer");
        const death_notice_memoirs = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs");
        obj_this.setState({
          death_notice_tributes: death_notice_tributes,
          death_notice_messages : death_notice_messages,
          death_notice_prayers : death_notice_prayers,
          death_notice_memoirs : death_notice_memoirs,
          // tributes_pending_count : death_notice_tributes.filter((item) => (item.status == "PENDING")).length,
          // condolence_message_pending_count : death_notice_messages.filter((item) => (item.status == "PENDING")).length,
          // prayers_pending_count : death_notice_prayers.filter((item) => (item.status == "PENDING")).length,
        });
        alert("Death notice message deleted");
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  // approve_tribute_message(death_notice_tribute_id){
  //   if (!window.confirm("Approve this message")) {
  //     return;
  //   }
  //   var obj_this = this;
  //   this.context.loading(1);
  //   $.ajax({
  //     url: process.env.REACT_APP_API + "/api/approveDeathNoticeTribute",
  //     data: this.context.bind_session({ death_notice_tribute_id: death_notice_tribute_id }),
  //     type: "POST"
  //   }).done(function (return_data) {
  //     if (return_data.status == "success") {
  //       obj_this.context.loading(0);
  //       const death_notice_tributes = return_data.death_notice_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
  //       const death_notice_messages = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "note");
  //       const death_notice_prayers = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "prayer");
  //       obj_this.setState({
  //         death_notice_tributes: death_notice_tributes,
  //         death_notice_messages : death_notice_messages,
  //         death_notice_prayers : death_notice_prayers,
  //         // tributes_pending_count : death_notice_tributes.filter((item) => (item.status == "PENDING")).length,
  //         // condolence_message_pending_count : death_notice_messages.filter((item) => (item.status == "PENDING")).length,
  //         // prayer_pending_count : death_notice_prayers.filter((item) => (item.status == "PENDING")).length,
  //       });
  //       alert("Death notice message approved");
  //       // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
  //     } else {
  //       alert("Network error!!");
  //     }
  //   }).fail(function (return_data) {
  //     alert("Network error!!");
  //   });
  // }

  maximize_block(block){
    console.log(block);
    $("#"+block).heightAuto();
    $("#"+block).find(".maximize_button").css("display","none");
    $("#"+block).find(".minimize_button").css("display","block");
  }

  minimize_block(block){
    $("#"+block).css("height","54px");
    $("#"+block).find(".maximize_button").css("display","block");
    $("#"+block).find(".minimize_button").css("display","none");
  }

  render() {
    return (
      <div className="view_death_notice_page_container page_container" ref={this.view_death_notice_page_Ref}>
        <div className="deceased_basic_details_block">
          <div className="page_header">
            <div className="page_title">Death Notice</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/DeathNotices/MyEntries">Death Notices</Link>/
              <span>View</span>
            </div> */}
          </div>
          <div className="deceased_name_duration_grid"> 
            <div className="deceased_name">{this.state.death_notice.first_name} {this.state.death_notice.surname}</div>
            {this.state.death_notice.below_one_year?
            <div className="duration"></div>
            :
            <div className="duration">({new Date(this.state.death_notice.date_of_birth).getFullYear()} - {new Date(this.state.death_notice.date_of_death).getFullYear()})</div>
            }
            </div>
          <div className="deceased_image_and_details_grid">
            <div className="deceased_image_container">
            {this.state.death_notice.image_src?
              <div className="deceased_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/death_notice_images/"+this.state.death_notice.image_src+"')"}}></div>
              :
              <div className="deceased_image" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
              }
            </div>
            <div className="deceased_details_grid">
              <div className="single_detail_container" style={{display:this.state.death_notice.middle_name?"block":"none"}}>
                <div className="detail_label">Middle name</div>
                <div className="detail_value">{this.state.death_notice.middle_name}</div>
              </div>
              <div className="single_detail_container"  style={{display:this.state.death_notice.nick_name?"block":"none"}}>
                <div className="detail_label">Nick name</div>
                <div className="detail_value">{this.state.death_notice.nick_name}</div>
              </div>
              <div className="single_detail_container"  style={{display:this.state.death_notice.nee?"block":"none"}}>
                <div className="detail_label">Née</div>
                <div className="detail_value">{this.state.death_notice.nee}</div>
              </div>
              <div className="single_detail_container"  style={{display:this.state.death_notice.family_or_house_name?"block":"none"}}>
                <div className="detail_label">Family/House name</div>
                <div className="detail_value">{this.state.death_notice.family_or_house_name}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Locality</div>
                <div className="detail_value">{this.state.death_notice.locality}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">City/Town</div>
                <div className="detail_value">{this.state.death_notice.city}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">State/Province/County/Region</div>
                <div className="detail_value">{this.state.death_notice.state}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Country</div>
                <div className="detail_value">{this.state.death_notice.country}</div>
              </div>
              <div className="single_detail_container" style={{display:this.state.death_notice.gender?"block":"none"}}>
                <div className="detail_label">Gender</div>
                <div className="detail_value">{this.state.death_notice.gender}</div>
              </div>
              {/* <div className="single_detail_container">
                <div className="detail_label">Date of birth</div>
                <div className="detail_value">{new Date(this.state.death_notice.date_of_birth).toLocaleDateString('en-GB')}</div>
              </div> */}
              <div className="single_detail_container">
                <div className="detail_label">Date of death</div>
                <div className="detail_value">{new Date(this.state.death_notice.date_of_death).toLocaleDateString('en-GB')}</div>
              </div>
              <div className="single_detail_container" style={{display:this.state.death_notice.place_of_death?"block":"none"}}>
                <div className="detail_label">Place of death</div>
                <div className="detail_value">{this.state.death_notice.place_of_death}</div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Age</div>
                {this.state.death_notice.below_one_year?
                <div className="detail_value">({this.state.full_age})</div>
                :
                <div className="detail_value">{this.state.death_notice.age}</div>
                }
              </div>
              {/* <div className="single_detail_container" style={{display:this.state.death_notice.place_of_birth?"block":"none"}}>
                <div className="detail_label">Place of birth</div>
                <div className="detail_value">{this.state.death_notice.place_of_birth}</div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="deceased_infos_container">

          {/* <div className="single_info_container" id="death_notice">
            <div className="single_info_head">
              <div className="heading">DEATH NOTICE</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("death_notice")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("death_notice")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="notice_title">The death has occurred of {this.state.death_notice.first_name} {this.state.death_notice.surname}</div>
              <div className="notice_content" dangerouslySetInnerHTML={{ __html: this.state.death_notice.notice_content }}/>
            </div>
          </div> */}

          {/* <div className="single_info_container" id="biography" style={{display:this.state.biography?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">BIOGRAPHY</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("biography")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("biography")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">{this.state.death_notice.biography}</div>
            
          </div> */}
          <div className="single_info_container" id="condol_message"  style={{display:this.state.death_notice_messages.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">CONDOLENCE MESSAGES</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("condol_message")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("condol_message")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid">
                {this.state.death_notice_messages && this.state.death_notice_messages.map((item, idx) => (
                  <div className="single_message" 
                    key={idx} 
                    // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                  >
                    {/* <div className="tribute_type_image" style={{ backgroundImage: "url('" + note + "')" }}></div> */}
                    <div className="msg_container">
                      <div className="tribute_text">{item.tribute_text}</div>
                      <div className="posted_by">Posted by {item.written_by} on {new Date(item.published_date).toDateString()}</div>
                    </div>
                     <i className="fas fa-check approve_tribute_button" 
                      style={{visibility:this.context.session && item.status == "PENDING"? "hidden":"hidden"}}
                      onClick={() => this.approve_tribute_message(item.id)}
                    ></i>
                     <i className="fas fa-trash-alt delete_tribute_button" 
                      style={{visibility:this.context.session? "visible":"hidden"}}
                      onClick={() => this.delete_tribute_message(item.id)}
                    ></i>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="single_info_container" id="candle_flower" style={{display:this.state.death_notice_tributes.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">MEMENTOES <span style={{display:this.state.tributes_pending_count>0 ?"inline":"none"}} className="pending_count"> {this.state.tributes_pending_count} </span></div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("candle_flower")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("candle_flower")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid mementos_grid">
                {this.state.death_notice_tributes && this.state.death_notice_tributes.map((item, idx) => (
                  <div className="single_tribute" 
                    key={idx} 
                    // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                  >
                    <div>
                      <div className="tribute_type_image" style={{ backgroundImage: item.tribute_type_image?"url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')" :item.tribute_type == "candle" ?"url('" + candle + "')" : item.tribute_type == "flower" ? "url('" + flower + "')" : "url('" + note + "')" }}></div>
                      <div className="posted_by">Posted by {item.written_by} on {new Date(item.published_date).toDateString()}</div>
                    </div>
                     <i className="fas fa-check approve_tribute_button" 
                      style={{visibility:this.context.session.user_type == "admin" && item.status == "PENDING"? "hidden":"hidden"}}
                      onClick={() => this.approve_tribute_message(item.id)}
                    ></i>
                     <i className="fas fa-trash-alt delete_tribute_button"
                      style={{visibility:this.context.session.user_type == "admin"? "visible":"hidden"}}
                      onClick={() => this.delete_tribute_message(item.id)}
                    ></i>
                  </div>
                ))}

              </div>
            </div>
          </div>

          <div className="single_info_container" id="prayer"  style={{display:this.state.death_notice_prayers.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">PRAYERS</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("prayer")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("prayer")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid">
                {this.state.death_notice_prayers && this.state.death_notice_prayers.map((item, idx) => (
                  <div className="single_message" 
                    key={idx} 
                    // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                  >
                    {/* <div className="tribute_type_image" style={{ backgroundImage: "url('" + note + "')" }}></div> */}
                    <div className="msg_container">
                      <div className="tribute_text">{item.tribute_text}</div>
                      <div className="posted_by">Posted by {item.written_by} on {new Date(item.published_date).toDateString()}</div>
                    </div>
                     <i className="fas fa-check approve_tribute_button" 
                      style={{visibility:this.context.session && item.status == "PENDING"? "hidden":"hidden"}}
                      onClick={() => this.approve_tribute_message(item.id)}
                    ></i>
                     <i className="fas fa-trash-alt delete_tribute_button" 
                      style={{visibility:this.context.session? "visible":"hidden"}}
                      onClick={() => this.delete_tribute_message(item.id)}
                    ></i>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="single_info_container" id="memoirs"  style={{display:this.state.death_notice_memoirs.length?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">MEMOIRS</div>
              <div className="minimize_maximize_buttons_container">
                <div className="maximize_button" onClick={() => this.maximize_block("condol_message")}><AiOutlinePlus /></div>
                <div className="minimize_button" onClick={() => this.minimize_block("condol_message")}><AiOutlineMinus /></div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid">
                {this.state.death_notice_memoirs && this.state.death_notice_memoirs.map((item, idx) => (
                  <div className="single_message" 
                    key={idx} 
                    // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                  >
                    {/* <div className="tribute_type_image" style={{ backgroundImage: "url('" + note + "')" }}></div> */}
                    <div className="msg_container">
                      <div className="tribute_text" dangerouslySetInnerHTML={{ __html: item.tribute_text }}/>
                      <div className="posted_by">Posted by {item.written_by} on {new Date(item.published_date).toDateString()}</div>
                    </div>
                     <i className="fas fa-check approve_tribute_button" 
                      style={{visibility:this.context.session && item.status == "PENDING"? "hidden":"hidden"}}
                      onClick={() => this.approve_tribute_message(item.id)}
                    ></i>
                     <i className="fas fa-trash-alt delete_tribute_button" 
                      style={{visibility:this.context.session? "visible":"hidden"}}
                      onClick={() => this.delete_tribute_message(item.id)}
                    ></i>
                  </div>
                ))}
              </div>
            </div>
          </div>


        </div>
        
        <div className="buttons_container" style={{display:this.state.death_notice.status == "PUBLISHED" || this.state.death_notice.status == "PENDING"?"block":"none"}}>
          <ButtonTheme1
            label="Delete"
            className="reject_button"
            onClick={this.delete_notice}
          ></ButtonTheme1>
        </div>
        <div className="delete_reason_container" ref={this.delete_reason_container_ref}>
          <form className="delete_reason_form" onSubmit={this.delete_form_submit} method="post">
            <div className="delete_reason_title">Delete reason</div>
            <div className="delete_reason_form_body">
              <textarea name="deleted_reason"
                rows={4}
                value={this.state.deleted_reason}
                onChange={this.form_change}
                required
                placeholder="Type here.."
              />
              <button className="delete_form_submit_button">Submit</button>
            </div>
            <div className="delete_form_close_button" onClick={() => { 
              this.delete_reason_container_ref.current.style.display = "none"; 
              this.setState({ 
                deleted_reason : "",
                memorial_id : "" }) }}>x</div>
          </form>
        </div>
      </div>
    )
  }
}

export default ViewDeathNotice;
ViewDeathNotice.contextType = MyContext;