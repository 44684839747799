import React from "react";
import "./Grid.scss";
import {
  Route, BrowserRouter
} from "react-router-dom";
import MyContext from "../MyContext";
import Home from '../Home/Home';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from "../Sidebar/Sidebar";
import DeathNotices from '../DeathNotices/DeathNotices';
import SingleDeathNotice from '../DeathNotices/SingleDeathNotice';
import Dashboard from "../Dashboard/Dashboard";
import AboutUs from "../AboutUs/AboutUs";
import Users from "../Dashboard/Users/Users";
import NewDeathNotice from '../Dashboard/NewDeathNotice/NewDeathNotice';
import DeathNoticeList from "../Dashboard/DeathNoticeList/DeathNoticeList";
import FamilyNotices from "../FamilyNotices/FamilyNotices";
import ViewDeathNotice from "../Dashboard/ViewDeathNotice/ViewDeathNotice";
import DeathNoticeApprovalList from "../Dashboard/DeathNoticeApprovalList/DeathNoticeApprovalList";
import MyDeathNoticeList from "../Dashboard/MyDeathNoticeList/MyDeathNoticeList";
import NewFamilyNotice from "../Dashboard/NewFamilyNotice/NewFamilyNotice";
import SingleFamilyNotice from "../FamilyNotices/SingleFamilyNotice";
import FamilyNoticeList from "../Dashboard/FamilyNoticeList/FamilyNoticeList";
import ViewFamilyNotice from "../Dashboard/ViewFamilyNotice/ViewFamilyNotice";
import FamilyNoticeApprovalList from "../Dashboard/FamilyNoticeApprovalList/FamilyNoticeApprovalList";
import MyFamilyNoticeList from "../Dashboard/MyFamilyNoticeList/MyFamilyNoticeList";
import Memorials from "../Memorials/Memorials";
import MemorialList from "../Dashboard/MemorialList/MemorialList";
import MemorialApprovalList from "../Dashboard//MemorialApprovalList/MemorialApprovalList";
import MyMemorialList from "../Dashboard/MyMemorialList/MyMemorialList";
import NewMemorial from "../Dashboard/NewMemorial/NewMemorial";
import SingleMemorial from "../Memorials/SingleMemorial";
import NewFeedback from "../NewFeedback/NewFeedback";
import UserFeedback from "../Dashboard/UserFeedback/UserFeedback";
import ViewFeedback from "../Dashboard/ViewFeedback/ViewFeedback";
import PetTributes from "../PetTributes/PetTributes";
import NewPetTribute from "../Dashboard/NewPetTribute/NewPetTribute";
import SinglePetTribute from "../PetTributes/SinglePetTribute";
import PetTributeList from "../Dashboard/PetTributeList/PetTributeList";
import PetTributeApprovalList from "../Dashboard/PetTributeApprovaList/PetTributeApprovalList";
import MyPetTributeList from "../Dashboard/MyPetTributeList/MyPetTributeList";
import ViewPetTribute from "../Dashboard/ViewPetTribute/ViewPetTribute";
import ViewMemorial from "../Dashboard/ViewMemorial/ViewMemorial";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "../CookiesPolicy/CookiesPolicy";
import Disclaimer from "../Disclaimer/Disclaimer";
import ContactUs from "../ContactUs/ContactUs";
import CodeOfConduct from "../CodeOfConduct/CodeOfConduct";
import Services from "../Services/Services";
import {IoIosArrowUp} from "react-icons/io";
import $ from "jquery";
import NoticeFeedbacks from "../Dashboard/NoticeFeedbacks/NoticeFeedbacks";
import ViewNoticeFeedback from "../Dashboard/ViewNoticeFeedback/ViewNoticeFeedback";
import ManageEmojis from "../Dashboard/ManageEmojis/ManageEmojis";
import NewPartner from "../Dashboard/NewPartner/NewPartner";
import PartnerProfile from "../PartnerProfile/PartnerProfile";
import ManageTestimonials from "../Dashboard/ManageTestimonials/ManageTestimonials";
import PartnerList from "../Dashboard/PartnerList/PartnerList";
import PartnerApprovaList from "../Dashboard/PartnerApprovalList/PartnerApprovalList";
import Blogs from "../Blogs/Blogs";
import SingleBlog from "../Blogs/SingleBlog";
import Features from "../Features/Features";
import FAQs from "../FAQs/FAQs";
import Help from "../Help/Help";
import CustomAlert from "../CustomAlert/CustomAlert.jsx";
import RefundAndCancellationPolicy from "../RefundAndCancellationPolicy/RefundAndCancellationPolicy";
import LifeEvents from "../LifeEvents/LifeEvents";
import PartnerCommentList from "../Dashboard/PartnerCommentList/PartnerCommentList";
import DeathNoticeReportedList from "../Dashboard/DeathNoticeReportedList/DeathNoticeReportedList";
import {Link} from "react-router-dom";
import FamilyNoticeReportedList from "../Dashboard/FamilyNoticeReportedList/FamilyNoticeReportedList";
import PetTributeReportedList from "../Dashboard/PetTributeReportedList/PetTributeReportedList";
import MemorialReportedList from "../Dashboard/MemorialReportedList/MemorialReportedList";
import PartnerReportedList from "../Dashboard/PartnerReportedList/PartnerReportedList";
import NewCharity from "../Dashboard/NewCharity/NewCharity";
import CharityList from "../Dashboard/CharityList/CharityList";
import Donate from "../Donate/Donate";
import CharityProfile from "../CharityProfile/CharityProfile";
import PartnerInformation from "../PartnerInformation/PartnerInformation";
import NewBlog from "../Dashboard/NewBlog/NewBlog";
import BlogList from "../Dashboard/BlogList/BlogList";
// import Pricing from "../Pricing/Pricing";
import BlogCommentList from "../Dashboard/BlogCommentList/BlogCommentList";
import BlogReportedList from "../Dashboard/BlogReportedList/BlogReportedList";
import AccountSettings from "../Dashboard/AccountSettings/AccountSettings";
import FirstComponent from "../FirstComponent/FirstComponent";
import CharityCommentList from "../Dashboard/CharityCommentList/CharityCommentList";
import CharityReportedList from "../Dashboard/CharityReportedList/CharityReportedList";
import CustomConfirm from "../CustomConfirm/CustomConfirm";


class Grid extends React.Component{
  constructor(props){
    super(props);
    this.pages_grid_item_ref = React.createRef();
    this.scrollTop = this.scrollTop.bind(this);
    var show_cookie_alert = window.localStorage.getItem('show_cookie_alert');
    show_cookie_alert = show_cookie_alert == undefined?1:show_cookie_alert;
    this.state = {
      show_cookie_alert : show_cookie_alert
    }

    this.sidebar_active_menu = this.sidebar_active_menu.bind(this);
  }

  componentDidMount(){
    $(window).on("scroll",this.on_page_scroll);
    var path = window.location.pathname;
     // sidebar
   
    this.sidebar_active_menu(path);

    //header
    path = path.split("/");
    $(".header_menu").removeClass("selected_header_menu");
    $(".mob_menu").removeClass("selected_mob_menu");
    // console.log(path);
    $(".header_menu[href='/"+path[1]+"']").addClass("selected_header_menu");
    $(".mob_menu[href='/"+path[1]+"']").addClass("selected_mob_menu");
    $(".dropdown-content .header_menu").removeClass("selected_header_menu");
    $(".dropdown-content .header_menu[href='/" + path[1] + "']").css("color","#9100b7e6");
    if($(".mob_menu_container .items:visible").length){
      console.log("test");
      $(".mob_menu_container .sub_menu").addClass("selected_mob_menu");
    }

  }

  sidebar_active_menu(path){
    var selector = '.sidebar_component_container .menu_item[href="' + path + '"]';
if ($(selector).parent().hasClass('menu_container'))
    if($('.sidebar_component_container .menu_item[href="' + path + '"]').parent().hasClass("menu_container")){
      $('.sidebar_component_container .menu_item[href="' + path + '"]').css({
        "background-color": "#613282bd",
        "color" : "white"
      });
    }else{  
      $('.sidebar_component_container .menu_item[href="' + path + '"]').css({
        "background-color": "#9100b76e",
        "color" : "white"
      });
      // console.log($('.sidebar_component_container .menu_item[href="' + path + '"]').parent().length)
      if($('.sidebar_component_container .menu_item[href="' + path + '"]').parent().length){
        $('.sidebar_component_container .menu_item[href="' + path + '"]').parent().heightAuto();
        $('.sidebar_component_container .menu_item[href="' + path + '"]').parent().prev().css({
          backgroundColor : "#613282bd",
          color :"white"
        });
      }else{
        
      }
    }
  }
  
  on_page_scroll(){
    const scrolled_distance =$(this).scrollTop();
    var page_url = window.location.href.toString().split(window.location.host)[1];
    if(page_url == "/" || page_url == "/InMemoryNotices" || page_url == "/DeathNotices" || page_url == "/Memorials" || page_url == "/PetTributes" || page_url == "/Charities" || page_url == "/Services" || page_url =="/Feedback" || page_url =="/Pricing" || page_url.includes("/Partners/") || page_url.includes("/Charity/")){
      if(scrolled_distance > 70){
        $(".header_container").removeClass("transparent_header");
      }else{
        $(".header_container").addClass("transparent_header");
      }
    }
    if(scrolled_distance > 900){
      $(".scroll_top_button").css({
        "visibility" : "visible",
        "opacity" : 1,
      });
    }else{
      $(".scroll_top_button").css({
        "opacity" : 0,
        "visibility" : "hidden"
      });
    }
    if ($(".slide_from_left").length) {
      var slide_from_left_height = $(".slide_from_left").height();
      var top = $(".slide_from_left").offset().top;
      // console.log(top,slide_from_left_height)
      if(scrolled_distance > top-(slide_from_left_height/2)){
        $(".slide_from_left").addClass("slide_from_left_END");
      }
    }
    if ($(".zoom_from_zero").length) {
      var zoom_from_zero_height = $(".zoom_from_zero").height();
      var top = $(".zoom_from_zero").offset().top;
      // console.log(top,zoom_from_zero_height)
      if(scrolled_distance > top-500){
        $(".zoom_from_zero").addClass("zoom_from_zero_animation");
      }
    }
    // var notices_block_height = $(".recent_death_notices_block").height();
    // if ($(".recent_death_notices_block").length) {
    //   var top = $(".recent_death_notices_block").offset().top;
    //   if(top < (notices_block_height/2)+80){
    //     $(".recent_death_notices_block .notices_container").removeClass("hide_bottom").addClass("move_top");
    //   }
    // }
  }

  scrollTop(){
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    $(".scroll_top_button").css({
      "opacity" : 0,
      "visibility" : "hidden"
    });
  }

  render(){
    return(
      <MyContext.Consumer>
        {context => (
          <>
          <div className="grid_layout_container" style={{gridTemplateColumns:context.show_sidebar?"230px auto":context.show_mobile_sidebar?"50px auto":"0px auto"}}>
            <div className="sidebar_grid_item">
            </div>
            <div className="pages_grid_item" ref={this.pages_grid_item_ref}>
              <Route path="/" component={FirstComponent}></Route>
              <Route path="/" exact component={Home}></Route>
              <Route path="/DeathNotices" exact component={DeathNotices}></Route>
              {/* <Route path="/DeathNotices/:state" exact component={DeathNotices}></Route> */}
              <Route path="/DeathNotices/:death_notice_id" component={SingleDeathNotice}></Route>
              <Route path="/InMemoryNotices" exact component={FamilyNotices}></Route>
              <Route path="/InMemoryNotices/:family_notice_id" component={SingleFamilyNotice}></Route>
              <Route path="/Memorials" exact component={Memorials}></Route>
              <Route path="/PetTributes" exact component={PetTributes}></Route>
              <Route path="/Memorials/:memorial_id" component={SingleMemorial}></Route>
              <Route path="/PetTributes/:pet_tribute_id" component={SinglePetTribute}></Route>
              <Route path="/Services" exact component={Services}></Route>
              <Route path="/Feedback" exact component={NewFeedback}></Route>
              <Route path="/TermsAndConditions" exact component={TermsAndConditions}></Route>
              <Route path="/RefundAndCancellationPolicy" exact component={RefundAndCancellationPolicy}></Route>
              <Route path="/PrivacyPolicy" exact component={PrivacyPolicy}></Route>
              <Route path="/CookiesPolicy" exact component={CookiesPolicy}></Route>
              <Route path="/Disclaimer" exact component={Disclaimer}></Route>
              <Route path="/FAQs" exact component={FAQs}></Route>
              <Route path="/Help" exact component={Help}></Route>
              <Route path="/Features" exact component={Features}></Route>
              <Route path="/CodeOfConduct" exact component={CodeOfConduct}></Route>
              <Route path="/AboutUs" exact component={AboutUs}></Route>
              <Route path="/ContactUs" exact component={ContactUs}></Route>
              <Route path="/Partners/:partner_id" component={PartnerProfile}></Route>
              <Route path="/Blogs" exact component={Blogs}></Route>
              <Route path="/Blogs/:blog_id" component={SingleBlog}></Route>
              <Route path="/Charities" exact component={Donate}></Route>
              <Route path="/Charity/:charity_id" component={CharityProfile}></Route>
              <Route path="/ComingSoon" exact component={LifeEvents}></Route>
              <Route path="/PartnerInformation" exact component={PartnerInformation}></Route>
              {/* <Route path="/Pricing" exact component={Pricing}></Route> */}
              {this.context.session?
              <>
                <Route path="/Dashboard" exact component={Dashboard}></Route>
                <Route path="/Dashboard/ManageAccount" exact component={AccountSettings}></Route>
               
                {this.context.session.user_type == "admin"?
                <>
                  <Route path="/Dashboard/Users" exact component={Users}></Route>
                  <Route path="/Dashboard/Partners" exact component={Users}></Route>
                  <Route path="/Dashboard/DeathNotices/All" exact component={DeathNoticeList}></Route>
                  <Route path="/Dashboard/InMemoryNotices/All" exact component={FamilyNoticeList}></Route>
                  <Route path="/Dashboard/Memorials/All" exact component={MemorialList}></Route>
                  <Route path="/Dashboard/PetTributes/All" exact component={PetTributeList}></Route>
                  <Route path="/Dashboard/AboutUs" exact component={AboutUs}></Route>
                  <Route path="/Dashboard/Feedbacks" exact component={UserFeedback}></Route>
                  <Route path="/Dashboard/ViewFeedback/:feedback_id" exact component={ViewFeedback}></Route>
                  <Route path="/Dashboard/ManageEmojis" exact component={ManageEmojis}></Route>
                  <Route path="/Dashboard/ManageTestimonials" exact component={ManageTestimonials}></Route>
                  <Route path= "/Dashboard/Services/All" exact component={PartnerList}></Route>
                  <Route path= "/Dashboard/Services/Approvals" exact component={PartnerApprovaList}></Route>
                  {/* <Route path= "/Dashboard/Partners/MyEntries" exact component={MyPartnerList}></Route> */}
                  <Route path= "/Preview/Partners/:partner_id" exact component={PartnerProfile}></Route>
                  <Route path= "/Dashboard/NewCharity" exact component={NewCharity}></Route>
                  <Route path= "/Dashboard/Charities/All" exact component={CharityList}></Route>
                  <Route path= "/Dashboard/EditCharity/:charity_id" exact component={NewCharity}></Route>
                  <Route path= "/Preview/Charity/:charity_id" exact component={CharityProfile}></Route>
                  <Route path="/Dashboard/Blogs/New" exact component={NewBlog}></Route>
                  <Route path="/Dashboard/Blogs/MyEntries" exact component={BlogList}></Route>
                  <Route path="/Dashboard/Blogs/Edit/:blog_id" exact component={NewBlog}></Route>
                  <Route path="/Dashboard/Blogs/Approvals" exact component={BlogCommentList}></Route>
                  <Route path="/Dashboard/Blogs/ReportedContents" exact component={BlogReportedList}></Route>
                  <Route path="/Blog/Preview/:blog_id" exact component={SingleBlog}></Route>
                  <Route path="/Dashboard/Charities/Comments" exact component={CharityCommentList}></Route>
                  <Route path="/Dashboard/Charities/ReportedContents" exact component={CharityReportedList}></Route>
                </>
                :
                <>{this.context.session.user_type == "partner"?
                  <>
                  <Route path="/Dashboard/Services/Edit" exact component={NewPartner}></Route>
                  <Route path="/Dashboard/Services/Comments" exact component={PartnerCommentList}></Route>
                  </>
                  :""}
                  {/* {this.context.session.user_type == "user"? */}
                  <Route path="/Dashboard/NewPartner" exact component={NewPartner}></Route>
                  {/* :""} */}
                  </>
                }
                <Route path="/Dashboard/DeathNotices/Approvals" exact component={DeathNoticeApprovalList}></Route>
                <Route path="/Dashboard/DeathNotices/ReportedContents" exact component={DeathNoticeReportedList}></Route>
                <Route path="/Dashboard/DeathNotices/MyEntries" exact component={MyDeathNoticeList}></Route>
                <Route path="/Dashboard/ViewDeathNotice/:death_notice_id" exact component={ViewDeathNotice}></Route>
                <Route path="/DeathNotice/Preview/:death_notice_id" exact component={SingleDeathNotice}></Route>
                <Route path="/Dashboard/DeathNotices/New" exact component={NewDeathNotice}></Route>
                <Route path="/Dashboard/DeathNotices/Edit/:death_notice_id" exact component={NewDeathNotice}></Route>
                <Route path="/Dashboard/InMemoryNotices/MyEntries" exact component={MyFamilyNoticeList}></Route>
                <Route path="/Dashboard/InMemoryNotices/Approvals" exact component={FamilyNoticeApprovalList}></Route>
                <Route path="/Dashboard/InMemoryNotices/ReportedContents" exact component={FamilyNoticeReportedList}></Route>
                <Route path="/Dashboard/InMemoryNotices/New" exact component={NewFamilyNotice}></Route>
                <Route path="/Dashboard/InMemoryNotices/Edit/:family_notice_id" exact component={NewFamilyNotice}></Route>
                <Route path="/InMemoryNotice/Preview/:family_notice_id" exact component={SingleFamilyNotice}></Route>
                <Route path="/Dashboard/ViewFamilyNotice/:family_notice_id" exact component={ViewFamilyNotice}></Route>
                <Route path="/Dashboard/Memorials/MyEntries" exact component={MyMemorialList}></Route>
                <Route path="/Dashboard/Memorials/ReportedContents" exact component={MemorialReportedList}></Route>
                <Route path="/Dashboard/Memorials/Approvals" exact component={MemorialApprovalList}></Route>
                <Route path="/Dashboard/Memorials/New" exact component={NewMemorial}></Route>
                <Route path="/Dashboard/Memorials/Edit/:memorial_id" exact component={NewMemorial}></Route>
                <Route path="/Dashboard/ViewMemorial/:memorial_id" exact component={ViewMemorial}></Route>
                <Route path="/Memorial/Preview/:memorial_id" exact component={SingleMemorial}></Route>
                <Route path="/Dashboard/PetTributes/MyEntries" exact component={MyPetTributeList}></Route>
                <Route path="/Dashboard/PetTributes/Approvals" exact component={PetTributeApprovalList}></Route>
                <Route path="/Dashboard/PetTributes/ReportedContents" exact component={PetTributeReportedList}></Route>
                <Route path="/Dashboard/ViewPetTribute/:pet_tribute_id" exact component={ViewPetTribute}></Route>
                <Route path="/Dashboard/PetTributes/New" exact component={NewPetTribute}></Route>
                <Route path="/Dashboard/PetTributes/Edit/:pet_tribute_id" exact component={NewPetTribute}></Route>
                <Route path="/PetTribute/Preview/:pet_tribute_id" exact component={SinglePetTribute}></Route>
                <Route path="/Dashboard/NoticeFeedbacks" exact component={NoticeFeedbacks}></Route>
                <Route path="/Dashboard/ViewNoticeFeedback/:feedback_id" exact component={ViewNoticeFeedback}></Route>
                <Route path="/Dashboard/Services/ReportedContents" exact component={PartnerReportedList}></Route>
              </>
              :""}
              <Footer />
              <Route path="/Login" exact component={Login}></Route>
              <Route path="/Register" exact component={Register}></Route>
            </div>
            {/* <div className="header_grid_item">
              <Route path="/" component={Header} />
            </div> */}
          </div>
          <Route path="/" component={Sidebar}/>
          <Route path="/" component={Header} />
          <Route path="/" component={CustomAlert} />
          <Route path="/" component={CustomConfirm} />
          <div className="scroll_top_button" onClick={this.scrollTop}>
            <IoIosArrowUp  size={20} />
          </div>
          <div className="cookie_container" style={{display:(this.state.show_cookie_alert == 0?"none":"block")}} >
            <div className="cookie_heading">We use cookies</div>
            <div className="cookie_description">
              This website uses cookies to improve user experience.By using our website you consent
              to all cookies in accordance with our <Link to="CookiesPolicy">Cookies Policy</Link>
            </div>
            <div className="cookie_buttons_container">
            <button className="cookie_button" onClick={() => {
              window.localStorage.setItem('show_cookie_alert',0);
              this.setState({
                show_cookie_alert : 0
              });
            }}>Accept All</button>
            <button className="decline_button" onClick={() => {
              window.localStorage.setItem('show_cookie_alert',0);
              this.setState({
                show_cookie_alert : 0
              });
            }}>Reject All</button>
            </div>
            
            <div className="close_cookie_box" onClick={() => {
              window.localStorage.setItem('show_cookie_alert',0);
              this.setState({
                show_cookie_alert : 0
              });
            }}>x</div>
          </div>
          {/* <Route path="/" component={TransparentHeader} /> */}
          </>
 
        )}
      </MyContext.Consumer>
    )
  }
}

// function WithNavigate(props) {
//   let location = useLocation();
//   return <Grid {...props} location={location} />
// }

export default Grid;
Grid.contextType = MyContext;