import { Component } from "react";
import logo from "../../res/images/user_icon.png";
import "./LogoLoading.scss";
import $ from "jquery";

class LogoLoading extends Component{
  componentDidMount(){
   
  }
  render(){
    return(
      <div className="logo_loading_container">
        <img className="logo_icon" src={logo} alt="amrityum" />
      </div>
    )
  }
}

export default LogoLoading;