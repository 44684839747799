import React from "react";
import "./ViewFamilyNotice.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import default_image from "../../../res/images/user_icon.png";

class ViewFamilyNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      family_notice: {
        first_name: "",
        middle_name: "",
        nick_name: "",
        nee: "",
        family_or_house_name: "",
        locality: "",
        notice_type: "",
        date_of_birth: "",
        date_of_passing: "",
        image_src1: "",
        image_src2: "",
        image_src3: "",
        notice_location: "",
        notice_date: "",
        notice_time: "",
        prayer: "",
        notice_title: "",
        notice_content: "",
        notes: "",
        contact_details: "",
      },
      family_notice_comments: [],
      deleted_reason : ""
    };
    this.view_family_notice_page_Ref = React.createRef();
    this.delete_reason_container_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.delete_comment = this.delete_comment.bind(this);
    this.approve_comment = this.approve_comment.bind(this);
    this.maximize_block = this.maximize_block.bind(this);
    this.minimize_block = this.minimize_block.bind(this);
    this.delete_notice = this.delete_notice.bind(this);
    this.delete_form_submit = this.delete_form_submit.bind(this);
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/viewFamilyNoticeDetails",
      data: this.context.bind_session({
        family_notice_id: this.props.match.params.family_notice_id,
      }),
      type: "POST",
    })
      .done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState(
            {
              family_notice: return_data.family_notice,
              family_notice_comments: return_data.family_notice_comments,
            },
            () => {
              obj_this.view_family_notice_page_Ref.current.style.visibility =
                "visible";
            }
          );
        } else if (return_data.status == "no permission") {
          alert("You have no permission to see this data");
          obj_this.props.history.goBack();
        }
        obj_this.context.loading(0);
      })
      .fail(function () {
        alert("Network error!!");
      });
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  delete_notice(id) {
    if (!window.confirm("Are your sure")) {
      return;
    }
    this.delete_reason_container_ref.current.style.display = "block";
  }

  delete_form_submit(e) {
    e.preventDefault();
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/deleteFamilyNotice",
      data : this.context.bind_session({
        family_notice_id : this.state.family_notice.id,
        deleted_reason : this.state.deleted_reason
      }),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        $.alert("Death notice deleted");
        obj_this.delete_reason_container_ref.current.style.display = "none";
        obj_this.props.history.replace({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      }else{
        $.alert("Something Went Wrong. Please Try Again.");
      }
      obj_this.context.loading(0);
     }).fail(function(){
       alert("Network error");
     });
  }

  delete_comment(family_notice_comment_id) {
    if (!window.confirm("Delete this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteApprovedFamilyNoticeComment",
      data: this.context.bind_session({
        family_notice_comment_id: family_notice_comment_id,
      }),
      type: "POST",
    })
      .done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          obj_this.setState({
            family_notice_comments: return_data.family_notice_comments,
          });
          alert("In Memory Notice comment deleted");
          // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
        } else {
          alert("Network error!!");
        }
      })
      .fail(function (return_data) {
        alert("Network error!!");
      });
  }

  approve_comment(family_notice_comment_id) {
    if (!window.confirm("Approve this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveFamilyNoticeComment",
      data: this.context.bind_session({
        family_notice_comment_id: family_notice_comment_id,
      }),
      type: "POST",
    })
      .done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          obj_this.setState({
            family_notice_comments: return_data.family_notice_comments,
          });
          alert("In Memory Notice comment approved");
          // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
        } else {
          alert("Network error!!");
        }
      })
      .fail(function (return_data) {
        alert("Network error!!");
      });
  }

  maximize_block(block) {
    console.log(block);
    $("#" + block).heightAuto();
    $("#" + block)
      .find(".maximize_button")
      .css("display", "none");
    $("#" + block)
      .find(".minimize_button")
      .css("display", "block");
  }

  minimize_block(block) {
    $("#" + block).css("height", "54px");
    $("#" + block)
      .find(".maximize_button")
      .css("display", "block");
    $("#" + block)
      .find(".minimize_button")
      .css("display", "none");
  }

  render() {
    return (
      <div
        className="view_family_notice_page_container page_container"
        ref={this.view_family_notice_page_Ref}
      >
        <div className="deceased_basic_details_block">
          <div className="page_header">
            <div className="page_title">In Memory Notice</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/InMemoryNotices/MyEntries">In Memory Notices</Link>/
              <span>View</span>
            </div> */}
          </div>
          <div className="deceased_name_images_grid">
            <div className="deceased_name_address_container">
              <div className="deceased_name">
                {this.state.family_notice.first_name}{" "}
                {this.state.family_notice.surname}
              </div>
              <div className="notice_type">
                {this.state.family_notice.notice_type}
              </div>
              <div className="address">
                {this.state.family_notice.family_or_house_name}
              </div>
            </div>
          </div>
          <div className="deceased_image_and_details_grid">
            <div className="deceased_image_container">
              {this.state.family_notice.image_src1 ? (
                <div
                  className="deceased_image"
                  style={{
                    backgroundImage:
                      "url('" +
                      process.env.REACT_APP_CDN_URL +
                      "/" +
                      this.state.family_notice.entered_by +
                      "/family_notice_images/" +
                      this.state.family_notice.image_src1 +
                      "')",
                  }}
                ></div>
              ) : (
                <div
                  className="deceased_image"
                  style={{ backgroundImage: "url('" + default_image + "')" }}
                ></div>
              )}
            </div>
            <div className="deceased_details_grid">
              <div
                className="single_detail_container"
                style={{
                  display: this.state.family_notice.middle_name
                    ? "block"
                    : "none",
                }}
              >
                <div className="detail_label">Middle name</div>
                <div className="detail_value">
                  {this.state.family_notice.middle_name}
                </div>
              </div>
              <div
                className="single_detail_container"
                style={{
                  display: this.state.family_notice.nick_name
                    ? "block"
                    : "none",
                }}
              >
                <div className="detail_label">Nick name</div>
                <div className="detail_value">
                  {this.state.family_notice.nick_name}
                </div>
              </div>
              <div
                className="single_detail_container"
                style={{
                  display: this.state.family_notice.nee ? "block" : "none",
                }}
              >
                <div className="detail_label">Ne&eacute;</div>
                <div className="detail_value">
                  {this.state.family_notice.nee}
                </div>
              </div>
              <div
                className="single_detail_container"
                style={{
                  display: this.state.family_notice.family_or_house_name
                    ? "block"
                    : "none",
                }}
              >
                <div className="detail_label">Family/House name</div>
                <div className="detail_value">
                  {this.state.family_notice.family_or_house_name}
                </div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Locality</div>
                <div className="detail_value">
                  {this.state.family_notice.locality}
                </div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">City/Town</div>
                <div className="detail_value">
                  {this.state.family_notice.city}
                </div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">State/Province/County/Region</div>
                <div className="detail_value">
                  {this.state.family_notice.state}
                </div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Country</div>
                <div className="detail_value">
                  {this.state.family_notice.country}
                </div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Date of birth</div>
                <div className="detail_value">
                  {new Date(
                    this.state.family_notice.date_of_birth
                  ).toLocaleDateString("en-GB")}
                </div>
              </div>
              <div className="single_detail_container">
                <div className="detail_label">Date of death</div>
                <div className="detail_value">
                  {new Date(
                    this.state.family_notice.date_of_death
                  ).toLocaleDateString("en-GB")}
                </div>
              </div>
              <div
                className="single_detail_container"
                style={{
                  display: this.state.family_notice.notice_location
                    ? "block"
                    : "none",
                }}
              >
                <div className="detail_label">Program Location</div>
                <div className="detail_value">
                  {this.state.family_notice.notice_location}
                </div>
              </div>
              <div
                className="single_detail_container"
                style={{
                  display: new Date(
                    this.state.family_notice.notice_date
                  ).getDate()
                    ? "block"
                    : "none",
                }}
              >
                <div className="detail_label">Date</div>
                <div className="detail_value">
                  {new Date(
                    this.state.family_notice.notice_date
                  ).toLocaleDateString("en-GB")}
                </div>
              </div>
              <div
                className="single_detail_container"
                style={{
                  display: Date.parse(this.state.family_notice.notice_time)
                    ? "block"
                    : "none",
                }}
              >
                <div className="detail_label"> Time</div>
                <div className="detail_value">
                  {this.state.family_notice.notice_time}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="deceased_infos_container">

          {/* <div className="single_info_container" id="family_notice">
            <div className="single_info_head">
              <div className="heading">NOTICE</div>
              <div className="minimize_maximize_buttons_container">
                <div
                  className="maximize_button"
                  onClick={() => this.maximize_block("family_notice")}
                >
                  <AiOutlinePlus />
                </div>
                <div
                  className="minimize_button"
                  onClick={() => this.minimize_block("family_notice")}
                >
                  <AiOutlineMinus />
                </div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="notice_title">
                {this.state.family_notice.notice_title}
              </div>
              <div
                className="notice_content"
                dangerouslySetInnerHTML={{
                  __html: this.state.family_notice.notice_content,
                }}
              />
            </div>
          </div> */}

          {/* <div className="single_info_container" id="prayer" style={{display:this.state.family_notice.prayer?"block":"none"}}>
            <div className="single_info_head">
              <div className="heading">PRAYER</div>
              <div className="minimize_maximize_buttons_container">
                <div
                  className="maximize_button"
                  onClick={() => this.maximize_block("prayer")}
                >
                  <AiOutlinePlus />
                </div>
                <div
                  className="minimize_button"
                  onClick={() => this.minimize_block("prayer")}
                >
                  <AiOutlineMinus />
                </div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="notice_content">
                {this.state.family_notice.prayer}
              </div>
            </div>
          </div> */}

          <div
            className="single_info_container"
            id="condol_message"
            style={{
              display: this.state.family_notice_comments.length
                ? "block"
                : "none",
            }}
          >
            <div className="single_info_head">
              <div className="heading">COMMENTS</div>
              <div className="minimize_maximize_buttons_container">
                <div
                  className="maximize_button"
                  onClick={() => this.maximize_block("condol_message")}
                >
                  <AiOutlinePlus />
                </div>
                <div
                  className="minimize_button"
                  onClick={() => this.minimize_block("condol_message")}
                >
                  <AiOutlineMinus />
                </div>
              </div>
            </div>
            <div className="single_info_body">
              <div className="messages_grid">
                {this.state.family_notice_comments &&
                  this.state.family_notice_comments.map((item, idx) => (
                    <div
                      className="single_message"
                      key={idx}
                      // style={{backgroundImage:item.status == "PENDING"?"linear-gradient(to right bottom, #ff300091, #ffff0085)":""}}
                    >
                      <div className="msg_container">
                        <div className="tribute_text">{item.comment}</div>
                        <div className="posted_by">
                          Posted by {item.written_by} on{" "}
                          {new Date(item.published_date).toDateString()}
                        </div>
                      </div>
                      <i
                        className="fas fa-check approve_tribute_button"
                        style={{
                          visibility:
                            this.context.session && item.status == "PENDING"
                              ? "hidden"
                              : "hidden",
                        }}
                        onClick={() => this.approve_comment(item.id)}
                      ></i>
                      <i
                        className="fas fa-trash-alt delete_tribute_button"
                        style={{
                          visibility: this.context.session
                            ? "visible"
                            : "hidden",
                        }}
                        onClick={() => this.delete_comment(item.id)}
                      ></i>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
 
        <div className="buttons_container" style={{display:this.state.family_notice.status == "PUBLISHED" || this.state.family_notice.status == "PENDING"?"block":"none"}}>
          <ButtonTheme1
            label="Delete"
            className="delete_button"
            onClick={this.delete_notice}
          ></ButtonTheme1>
        </div>
        <div className="delete_reason_container" ref={this.delete_reason_container_ref}>
          <form className="delete_reason_form" onSubmit={this.delete_form_submit} method="post">
            <div className="delete_reason_title">Delete reason</div>
            <div className="delete_reason_form_body">
              <textarea name="deleted_reason"
                rows={4}
                value={this.state.deleted_reason}
                onChange={this.form_change}
                required
                placeholder="Type here.."
              />
              <button className="delete_form_submit_button">Submit</button>
            </div>
            <div className="delete_form_close_button" onClick={() => { 
              this.delete_reason_container_ref.current.style.display = "none"; 
              this.setState({ 
                deleted_reason : ""
              }) }}>x</div>
          </form>
        </div>
      </div>
    );
  }
}

export default ViewFamilyNotice;
ViewFamilyNotice.contextType = MyContext;
