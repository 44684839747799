
import React, { Component } from "react";
import MyContext from './MyContext.jsx';
import $ from "jquery";

export default class MyProvider extends Component {
  constructor(props){
    super(props);
    var session = window.localStorage.getItem('session_amrityum_web_app');
    session = session?JSON.parse(session):"";
    this.state = {
      version : "1.0.0",
      session : session,
      show_loading : 0,
      show_sidebar : 0,
      show_mobile_sidebar : 0,
      show_confirm_box : {
        show_flag : 0,
        message : "",
        callback : () => {}
      },
      heartbeat_XHR : {abort : () => {}}
    };
  }

  areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  
    return true;
  }
  

  render() {
    return (
      <MyContext.Provider
        value={{
          version : this.state.version,
          session : this.state.session,
          setSession : (data,callback) => {
            var isEqual = this.areObjectsEqual(this.state.session,data);
            if(!isEqual){
              this.setState(prevState => ({
                session: data // Update only session key, leave other keys unchanged
              }),callback);
              window.localStorage.setItem("session_amrityum_web_app",JSON.stringify(data));
            }
          },
          show_loading : this.state.show_loading,
          loading : (data) => {
            this.setState({
                show_loading : data
            });
          },
          show_sidebar : this.state.show_sidebar,
          show_mobile_sidebar : this.state.show_mobile_sidebar,
          sidebar : (x,y) => {
            this.setState({
                show_sidebar : x,
                show_mobile_sidebar : y
            });
          },
          bind_session : (data) => {
            data.sid = this.state.session.sid;
            data.token = this.state.session.token;
            return data;
          },
          validate_api_data : (return_data,history) => {
            if(return_data.status == "session failed"){
              this.setState({
                show_loading : 0
              });
              history.push({
                pathname : "/Login",
                url : history.location.pathname
              });
              
              return false;
            }
            return true;
          },
          delete_session : () => {
            this.setState({
              session : ""
            });
            window.localStorage.removeItem("session_amrityum_web_app");
          },
          setHistoryState : (obj,history) => {
            var current_history = history;
            if(typeof current_history.state != "object"){
              current_history.state = {};
            }
            $.extend(current_history.state, obj);
            // console.log("extend test");
            // window.history.replaceState({ key: history.location.key, state: this.state})
            history.replace({key : history.location.key, state : current_history.state});
          },
          confirm : (message,callback) => {
            this.setState({
              show_confirm_box : {
                show_flag : 1,
                message : message,
                callback : callback
              }
            });
          },
          show_confirm_box : this.state.show_confirm_box,
          hide_confirm_box : () => {
            this.setState({
              show_confirm_box : {
                show_flag : 0,
                message : "",
                callback : ""
              }
            });
          },
          heartbeat_XHR : this.state.heartbeat_XHR
        }}
      >
      {this.props.children}
      </MyContext.Provider>
    );
  }
}
