import React from "react";
import "./NewFamilyNotice.scss";
import { InputTheme4, InputTheme1, InputTheme3, TextAreaTheme1} from "../../../res/styler/InputThemes/InputThemes";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField';
import $ from "jquery";
import MyContext from "../../MyContext";
import { Link } from "react-router-dom";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import ImageEditor from "../../../res/styler/ImageEditor/ImageEditor";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import default_image from "../../../res/images/user_icon.png";

class NewFamilyNotice extends React.Component {
  constructor(props) {
    super(props);
    var date = new Date()
    // Add a day
    date.setDate(date.getDate() + 150);
    this.state = {
      family_notice_id : "",
      first_name: "",
      middle_name : "",
      surname : "",
      nick_name : "",
      nee : "",
      family_or_house_name: "",
      locality: "",
      state: "",
      country : "",
      state_label: "",
      city: "",
      city_label: "",
      countries : [],
      states : [],
      date_of_death: "",
      date_of_birth : "",
      age : "",
      notice_location: "",
      notice_date : "",
      notice_time : "",
      notice_types : [],
      notice_type : "",
      file : "",
      image1: "",
      image_src1: "",
      image2: "",
      image_src2: "",
      image3: "",
      image_src3: "",
      notice_title: "",
      notice_content: "",
      editorState: EditorState.createEmpty(),
      prayer : "",
      notes : "",
      contact_details : "",
      status : "",
      profile_pic_delete_flag : 0,
      death_notice_search_input : "",
      death_notices : [],
      death_notice : {},
      memorial_search_input : "",
      memorials : [],
      memorial : {},
      maximum_date : date.toLocaleDateString('en-CA')
    }
    this.getBlob = this.getBlob.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.notice_type_change = this.notice_type_change.bind(this);
    this.form_change = this.form_change.bind(this);
    this.country_change = this.country_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.calculate_age = this.calculate_age.bind(this);
    // this.add_program_row = this.add_program_row.bind(this);
    // this.delete_program_row = this.delete_program_row.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.death_notices_search = this.death_notices_search.bind(this);
    this.memorials_search = this.memorials_search.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.new_family_notice_page_ref = React.createRef();
    this.notice_type_ref = React.createRef();
    this.first_name_ref = React.createRef();
    this.surname_ref = React.createRef();
    this.locality_ref = React.createRef();
    this.country_ref = React.createRef();
    this.state_ref = React.createRef();
    this.city_ref = React.createRef();
    this.age_ref = React.createRef();
    this.date_of_death_ref = React.createRef();
    this.date_of_birth_ref = React.createRef();
    this.notice_content_ref = React.createRef();
    this.notice_title_ref = React.createRef();
  }

  componentDidMount() {
    // $.alert("In memory notice created.");
    this.context.loading(1);
    var obj_this = this;
    const family_notice_id = this.props.match.params.family_notice_id;
    if(family_notice_id){
      this.setState({
        family_notice_id : family_notice_id
      });
      $.ajax({
        url: process.env.REACT_APP_API + "/api/editFamilyNotice",
        data: this.context.bind_session({family_notice_id: family_notice_id}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          return_data.family_notice.date_of_death = new Date(return_data.family_notice.date_of_death);
          return_data.family_notice.date_of_birth = new Date(return_data.family_notice.date_of_birth);
          return_data.family_notice.death_notice = return_data.family_notice.death_notice??{};
          return_data.family_notice.memorial = return_data.family_notice.memorial??{};
          var date = new Date()
          // Add 150 day
          date.setDate(date.getDate() + 150);
          // console.log(date)
          return_data.family_notice.maximum_date = date.toLocaleDateString('en-CA');
          const contentBlock = htmlToDraft(return_data.family_notice.notice_content);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return_data.family_notice.editorState = editorState;
          }
          obj_this.setState(return_data.family_notice,() => {
            obj_this.new_family_notice_page_ref.current.style.visibility = "visible";
            obj_this.new_family_notice_page_ref.current.style.opacity = 1;
          });
          obj_this.context.loading(0);
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }else{
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getNoticeTypes",
        data: this.context.bind_session({}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState({
            notice_types: return_data.notice_types,
            countries : return_data.countries
          },() => {
            obj_this.new_family_notice_page_ref.current.style.visibility = "visible";
            obj_this.new_family_notice_page_ref.current.style.opacity = 1;
          });
          obj_this.context.loading(0);
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }

    $("form input[type=text]").on("keypress", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        $(this).nextAll("input:first").trigger("focus");
      }
    });
  }

  getBlob(blob){
    // pass blob up from the ImageCropper component
    this.setState({
      file : blob
    });
    // console.log(blob)
  }

  delete_image(){
    this.setState({
      profile_pic_delete_flag : 1,
      image_src1 : null,
      file : ""
    });
    console.log("delete")
  }

  form_change(e) {
    if(e.target.name == "notice_content"){
      if(e.target.value.length > 2000){
        $.alert("Maximum limit is exceeded");
        return;
      }
    }
    if(e.target.name == "prayer"){
      if(e.target.value.length > 800){
        $.alert("Maximum limit is exceeded");
        return;
      }
    }
    if(e.target.name == "notes"){
      if(e.target.value.length > 180){
        $.alert("Maximum limit is exceeded");
        return;
      }
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  notice_type_change(e) {
    this.setState({
      notice_type: e.target.value
    });
  };

  calculate_age(date_of_birth,date_of_death){
    var months,age = "";
    months = (new Date(date_of_death).getFullYear() - new Date(date_of_birth).getFullYear()) * 12;
    months -= new Date(date_of_birth).getMonth();
    months += new Date(date_of_death).getMonth();
    months = months ==0?1:months;
    // months = months <= 0 ? 0 : months;
    if(isNaN(months)){
      this.setState({
        age : ""
      });
      return;
    }
    if(months >= 12){
      age = Math.floor(months/12);
    }else{
      age = months>1?months+ " Months":months+" Month";
    }
    this.setState({
      age : age
    });
  }

  onEditorStateChange(value){
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
        editorState :value,
        notice_content : draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  country_change(e) {
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: this.context.bind_session({ country: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const country = obj_this.state.countries.find((item) => item.id === e.target.value ); 
        obj_this.setState({
          states : return_data.states,
          cities : [],
          state : "",
          city: "",
          country: e.target.value,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  };

  state_change(e) {
    const state = this.state.states.find((item) => item.id === e.target.value ); 
    this.setState({
      state: e.target.value,
      state_label : state.name
    });
  };

  // program_change(e,idx){
  //   var program_details = this.state.program_details;
  //   var program_detail = program_details[idx];
  //   program_detail[e.target.name] = e.target.value;
  //   this.setState({
  //     program_details : program_details
  //   });
  // }

  // add_program_row(idx){
  //   var program_details = this.state.program_details;
  //   var item =  {
  //     time : "",
  //     program : "",
  //     presented_by : ""
  //   };
  //   program_details.splice(idx+1, 0, item);
  //   this.setState({
  //     program_details : program_details
  //   });
  // }

  // delete_program_row(idx){
  //   console.log(idx)
  //   var program_details = this.state.program_details;
  //   const new_program_details = program_details.filter((item,index) => index != idx);
  //   this.setState({
  //     program_details : new_program_details
  //   });
  // }

  form_submit(e) {
    e.preventDefault();
    // console.log(this.state);
    // return;
    if(!this.state.notice_type){
      $.alert("Choose Notice Type");
      window.scrollTo({
        top : this.notice_type_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.notice_type_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.first_name){
      $.alert("First Name Required");
      window.scrollTo({
        top : this.first_name_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.first_name_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    // if(!this.state.surname){
    //   $.alert("Surname Required");
    //   window.scrollTo({
    //     top : this.surname_ref.current.offsetTop,
    //     behavior : "smooth"
    //   });
    //   $(this.surname_ref.current).addClass('input_theme4_SELECTED')
    //   return;
    // }

    if(!this.state.locality){
      $.alert("Locality Required");
      window.scrollTo({
        top : this.locality_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.locality_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.country){
      $.alert("Country Required");
      window.scrollTo({
        top : this.country_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }

    if(this.state.states.length && !this.state.state){
      $.alert("State/Province/County/Region Required");
      window.scrollTo({
        top : this.state_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }

    if(!this.state.city){
      $.alert("City/Town Required");
      window.scrollTo({
        top : this.city_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.city_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.date_of_birth){
      $.alert("Date of Birth Required");
      window.scrollTo({
        top : this.date_of_birth_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(!this.state.date_of_death){
      $.alert("Date of Death Required");
      window.scrollTo({
        top : this.date_of_death_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(Date.parse(this.state.date_of_death) < Date.parse(this.state.date_of_birth)){
      $.alert("Invalid Date of Death or Date of Birth");
      window.scrollTo({
        top : this.date_of_death_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    } 
    if(!this.state.notice_title){
      $.alert("Notice Title Required");
      window.scrollTo({
        top : this.notice_title_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(!this.state.editorState.getCurrentContent().hasText()){
      $.alert("Fill Notice Content");
      window.scrollTo({
        top : this.notice_content_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    const family_notice_details = this.state;
    delete family_notice_details.editorState;
    delete family_notice_details.notice_types;
    delete family_notice_details.state_label;
    delete family_notice_details.city_label;
    delete family_notice_details.death_notice_search_input;
    delete family_notice_details.memorial_search_input;
    family_notice_details.date_of_death = family_notice_details.date_of_death.toLocaleDateString('en-CA',{ day: '2-digit', month: '2-digit', year: 'numeric'});
    family_notice_details.date_of_birth = family_notice_details.date_of_birth ? family_notice_details.date_of_birth.toLocaleDateString('en-CA',{ day: '2-digit', month: '2-digit', year: 'numeric'}) : "";
    console.log(family_notice_details);
    // return;
    const form_data = new FormData();
    form_data.append("family_notice_details", JSON.stringify(family_notice_details));
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image1", this.state.file);
    // form_data.append("image2", this.state.image2);
    // form_data.append("image3", this.state.image3);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveFamilyNotice",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data);
      // return;
      if (return_data.status == "insert") {
        obj_this.context.loading(0);
        obj_this.setState({
          death_notices : []
        });
        if(obj_this.context.session.user_type == "admin"){
          alert("In Memory Notice created");
        }else{
          alert("Admin Approval Awaiting. Please check back later.");
        }
        obj_this.props.history.push(obj_this.props.location.url ?? "/Dashboard/InMemoryNotices/MyEntries");
      }else if (return_data.status == "update") {
        obj_this.context.loading(0);
        alert("In Memory Notice updated.");
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard/InMemoryNotices/MyEntries",
          state : obj_this.props.location.previousState??""
        });
      }else{
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    })
  }

  death_notices_search(e){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/searchDeathNotices",
      data: this.context.bind_session({ phrase: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          death_notices : return_data.death_notices,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    this.setState({
      death_notice_search_input : e.target.value
    });
  }

  memorials_search(e){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/searchMemorials",
      data: this.context.bind_session({ phrase: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          memorials : return_data.memorials,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    this.setState({
      memorial_search_input : e.target.value
    });
  }

  render() {
    return (
      <div className="new_family_notice_page_container page_container" ref={this.new_family_notice_page_ref}>
        <form className="new_family_notice_form" encType="multipart/form-data" onSubmit={this.form_submit}>
          <div className="page_header">
            <div className="page_title">{this.state.family_notice_id?"Edit":"Create"} In Memory Notice</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/InMemoryNotices/MyEntries">In Memory Notices</Link>/
              <span>{this.state.family_notice_id?"Edit":"Create"}</span>
            </div> */}
          </div>
          <div className="family_notice_details">
            <div className="image_editor_container">
              <ImageEditor
                getBlob={this.getBlob}
                image_src = {this.state.image_src1}
                current_image = {process.env.REACT_APP_CDN_URL+"/"+this.state.entered_by+"/family_notice_images/"+this.state.image_src1}
                deleteImageSrc = {this.delete_image}
                image_width = {210 }
                image_height = {252}
              />
            </div>
            <div className="basic_details">
              <FormControl variant="standard" ref={this.notice_type_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">Notice Type *</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.notice_type}
                  onChange={this.notice_type_change}
                  label="Notice Type *"
                >
                  {this.state.notice_types && this.state.notice_types.map((item, idx) => (
                    <MenuItem value={item.id} key={idx}>{item.notice_type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputTheme4
                label="First Name *"
                attrs={{
                  name: "first_name",
                  type: "text",
                  value: this.state.first_name,
                  onChange: this.form_change,
                }}
                input_ref={this.first_name_ref}
              />
              <InputTheme4
                label="Middle Name"
                attrs={{
                  name: "middle_name",
                  type: "text",
                  value: this.state.middle_name,
                  onChange: this.form_change,
                }}
              />
              <InputTheme4
                label="Surname"
                attrs={{
                  name: "surname",
                  type: "text",
                  value: this.state.surname,
                  onChange: this.form_change,
                }}
                input_ref={this.surname_ref}
              />
              {/* <InputTheme4
                label="Nick Name"
                attrs={{
                  name: "nick_name",
                  type: "text",
                  value: this.state.nick_name,
                  onChange: this.form_change,
                }}
              />
              <div style={{display:this.state.gender == "Female"?"block":"none"}}>
                <InputTheme4
                  label="Ne&eacute; (A woman’s maiden family name)"
                  attrs={{
                    name: "nee",
                    type: "text",
                    value: this.state.nee,
                    onChange: this.form_change,
                  }}
                />
              </div> */}
              <InputTheme4
                label="Family/House name"
                attrs={{
                  name: "family_or_house_name",
                  type: "text",
                  value: this.state.family_or_house_name,
                  onChange: this.form_change
                }}
              />
              <InputTheme4
                label="Locality *"
                attrs={{
                  name: "locality",
                  value: this.state.locality,
                  onChange: this.form_change,
                  type: "text",
                }}
                input_ref={this.locality_ref}
              />
              
              <FormControl variant="standard" ref={this.country_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">Country *</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.country}
                  onChange={this.country_change}
                  label="Country"
                >
                  {this.state.countries && this.state.countries.map((item, idx) => (
                    <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" ref={this.state_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">State/Province/County/Region {this.state.states.length?"*":""}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.state}
                  onChange={this.state_change}
                  label="State"
                >
                  {this.state.states && this.state.states.map((item, idx) => (
                    <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputTheme4
                label="City/Town *"
                attrs={{
                  name: "city",
                  value: this.state.city,
                  onChange: this.form_change,
                  type: "text",
                }}
                input_ref={this.city_ref}
              />

              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker ref={this.date_of_birth_ref}
                  label="Date of birth *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                    }
                  }}
                  value={this.state.date_of_birth}
                  onChange={(newValue) => {
                    this.calculate_age(newValue,this.state.date_of_death);
                    this.setState({ 
                      date_of_birth: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  allowSameDateSelection={true}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker ref={this.date_of_death_ref}
                  label="Date of death *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                    }
                  }}
                  value={this.state.date_of_death}
                  onChange={(newValue) => {
                    this.calculate_age(this.state.date_of_birth,newValue);
                    this.setState({
                      date_of_death: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={this.state.date_of_birth?new Date(this.state.date_of_birth):undefined}
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  allowSameDateSelection={true}
                />
              </LocalizationProvider>
             
            </div>
          </div>
         
          <div className="notice_prayer_grid">
            <div className="notice_container">
              <div className="notice_label">Notice Details *</div>
              <div className="tooltip">Please provide the specific name of the occasion or event. (Eg: 5th Death Anniversary, 7th Birthday Remembrance etc.)</div>
              <InputTheme4
                label="Notice title *"
                attrs={{
                  name: "notice_title",
                  type: "text",
                  value: this.state.notice_title,
                  onChange: this.form_change,
                }}
                input_ref={this.notice_title_ref}
              />
              <div className="tooltip">Check out <Link to="/Help" target={"_blank"}>help</Link> section for wordings/examples.</div>
              <div className="tooltip" >(Maximum character limit is 2000)</div>
              {/* <textarea
                name="notice_content"
                value={this.state.notice_content}
                placeholder="Enter your notice content.."
                onChange={this.form_change}
                required={true}
                rows={10}
              /> */}
              <div ref={this.notice_content_ref}>
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="editor_toolbar"
                  wrapperClassName="editor_component"
                  editorClassName="editor_input_area"
                  onEditorStateChange={this.onEditorStateChange}
                  placeholder={"Enter your notice content.."}
                  handlePastedText={() => false}
                  stripPastedStyles={true}
                  toolbar={{
                    options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                    },list: {
                      options: ['unordered', 'ordered'],
                    },
                    fontSize: {
                      options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
                    },
                  }}
                />
              </div>
              <div className="location_time_grid">
                <TextAreaTheme1
                  label="Program Address"
                  attrs={{
                    name: "notice_location",
                    value: this.state.notice_location,
                    onChange: this.form_change,
                  }}
                />
                <InputTheme1
                  label ="Date of program"
                  attrs = {{
                    name : "notice_date",
                    value : this.state.notice_date,
                    onChange : this.form_change,
                    type :"date",
                    min: this.state.date_of_death?new Date(this.state.date_of_death).toLocaleDateString('en-CA'):undefined,
                    required : false,
                    min : this.state.family_notice_id?"":new Date().toLocaleDateString('en-CA'),
                    max : this.state.maximum_date
                  }}
                />
                <InputTheme1
                  label ="Time of program"
                  attrs = {{
                    name : "notice_time",
                    value : this.state.notice_time,
                    onChange : this.form_change,
                    type :"time",
                    required : false
                  }}
                />
              </div>
            </div>
            <div className="prayer_container">
              <div className="notice_label">Prayer</div>
              <div className="tooltip">Check out <Link to="/Help" target={"_blank"}>help</Link> section for wordings/examples.</div>
              <div className="tooltip">(Maximum character limit is 800)</div>
              <textarea
                name="prayer"
                value={this.state.prayer}
                onChange={this.form_change}
                rows={8}
              />
              <div className="notes_contact_details_container">
                <div>
                <div className="notice_label">Notes</div>
                  <div className="tooltip">Please provide special instructions or other general information that people need to keep in mind regarding the event/day.</div>
                  <div className="tooltip">(Maximum character limit is 180)</div>
                  <textarea
                    name="notes"
                    value={this.state.notes}
                    onChange={this.form_change}
                    rows={4}
                  />
                  {/* <TextAreaTheme1
                    label="Notes"
                    attrs={{
                      name: "notes",
                      value: this.state.notes,
                      onChange: this.form_change,
                    }}
                  /> */}
                </div>
                <div>
                <div className="notice_label">Contact Details</div>
                  <div className="tooltip">Name and contact number, if provided, will be displayed to anyone visiting this In Memory Notice page.</div>
                  <textarea
                    name="contact_details"
                    value={this.state.contact_details}
                    onChange={this.form_change}
                    rows={4}
                  />
                  {/* <TextAreaTheme1
                    label="Contact Details"
                    attrs={{
                      name: "contact_details",
                      value: this.state.contact_details,
                      onChange: this.form_change,
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="program_details_container">
            <div className="programs_title">Program Details</div>
            <table className="programs_table">
              <thead>
                <tr>
                  <th>Sl no.</th>
                  <th>Time</th>
                  <th>Program</th>
                  <th>Presented by</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.program_details && this.state.program_details.map((item,idx) => (
                  <tr key={idx}>
                    <td>{idx+1}.</td>
                    <td>
                      <input 
                        name="time"
                        type="time"
                        value={item.program_time}
                        onChange={(e) => this.program_change(e,idx)}
                        required
                      />
                    </td>
                    <td>
                      <input
                        name="program"
                        value={item.program}
                        onChange={(e) => this.program_change(e,idx)}
                        required
                        placeholder="Enter program"
                      />
                    </td>
                    <td>
                      <input
                        name="presented_by"
                        value={item.presented_by}
                        onChange={(e) => this.program_change(e,idx)}
                        required
                        placeholder="Presented by"
                      />
                    </td>
                    <td>
                      <i className="fas fa-plus add_row_button"
                        onClick={() => this.add_program_row(idx)}
                      ></i>
                      <i className="fas fa-trash delete_row_button"
                        style={{visibility:this.state.program_details.length == 1?"hidden":"visible"}}
                        onClick={() => this.delete_program_row(idx)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}

          <div className="death_notice_memorial_selection_container">
            <div className="notice_label" style={{ marginTop: "30px"}}>Connect Death Notice and Memorial</div>
            <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}>If you wish to connect this notice with an existing profile, please pin your loved one here.</div>
            <div className="selection_grid">
            <div className="death_notice_section">
              <div className="search_and_suggestions_container">
                <div className="selected_notice" style={{display:this.state.death_notice.id?"inline-grid":"none"}}>
                  {/* <div className="selected_label">Death Notice Selected</div> */}
                  <div className="single_suggestion">
                    {this.state.death_notice.image_src ? <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/death_notice_images/"+this.state.death_notice.image_src+"')"}}></div> :
                    <div className="suggestion_image" style={{backgroundImage: "url('"+default_image+"')"}}></div>
                    }
                    
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">{this.state.death_notice.first_name} {this.state.death_notice.middle_name} {this.state.death_notice.surname}</div>
                      <div className="suggestion_address">{this.state.death_notice.family_or_house_name}, {this.state.death_notice.locality}, {this.state.death_notice.city}, {this.state.death_notice.state}</div>
                    </div>
                    <div className="charity_close_button" onClick={
                    () => {
                      this.setState({
                      death_notice : {}
                    })
                  }}>x</div>
                  </div>
                </div>
                <div className="search_container">
                  <input
                    className="search_input"
                    name="death_notice_search_input"
                    type="text"
                    value={this.state.death_notice_search_input}
                    onChange={this.death_notices_search}
                    placeholder="Search Death Notice"
                    autoComplete = "off"
                  />
                  <i className="fas fa-search search_icon"></i>
                </div>
                <div className="suggestions_container" style={{boxShadow:this.state.death_notices.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                {this.state.death_notices && this.state.death_notices.map((item,idx) => (
                  <div className="single_suggestion" onClick={() => this.setState({
                    death_notice : item,
                    death_notices : [],
                    death_notice_search_input : ""
                    })}

                  >
                    {item.image_src ? <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/death_notice_images/"+item.image_src+"')"}}></div>
                    : <div className="suggestion_image" style={{backgroundImage: "url('"+default_image+"')"}}></div>
                    }
                  
                  <div className="suggestion_name_address">
                    <div className="suggestion_name">{item.first_name} {item.middle_name} {item.surname}</div>
                    <div className="suggestion_address">{item.family_or_house_name}, {item.locality}, {item.city}, {item.state}</div>
                  </div>
                </div>
              ))}
                <div className="suggestions_container" style={{display: this.state.death_notice_search_input && !this.state.death_notices.length?"block":"none", boxShadow:!this.state.death_notices.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                  <div className="single_suggestion">
                    <div className="suggestion_image"></div>
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">No results</div>
                      <div className="suggestion_address"></div>
                    </div>
                  </div>
                </div>
                  
                </div>
                
              </div>
            </div>
            <div className="memorial_section">
              <div className="search_and_suggestions_container">
              <div className="selected_notice" style={{display:this.state.memorial.id?"inline-grid":"none"}}>
                {/* <div className="selected_label">Memorial Selected</div> */}
                <div className="single_suggestion">
                  {this.state.memorial.image_src ? <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/memorial_images/"+this.state.memorial.image_src+"')"}}></div> 
                  : <div className="suggestion_image" style={{backgroundImage: "url('"+default_image+"')"}}></div>
                  }
                  
                  <div className="suggestion_name_address">
                    <div className="suggestion_name">{this.state.memorial.first_name}{this.state.memorial.middle_name? " "+this.state.memorial.middle_name:""} {this.state.memorial.surname}</div>
                    <div className="suggestion_address">{this.state.memorial.family_or_house_name}, {this.state.memorial.locality}, {this.state.memorial.city}, {this.state.memorial.state}</div>
                  </div>
                  <div className="charity_close_button" onClick={
                    () => {
                      this.setState({
                      memorial : {}
                    })
                  }}>x</div>
                </div>
              </div>
                <div className="search_container">
                  <input
                    className="search_input"
                    name="memorial_search_input"
                    type="text"
                    value={this.state.memorial_search_input}
                    onChange={this.memorials_search}
                    placeholder="Search Memorial"
                    autoComplete = "off"
                  />
                  <i className="fas fa-search search_icon"></i>
                </div>
                <div className="suggestions_container" style={{border:this.state.memorials.length?"1px solid lightgrey":""}}>
                  {this.state.memorials && this.state.memorials.map((item,idx) => (
                    <div className="single_suggestion" onClick={() => this.setState({
                      memorial : item,
                      memorials : [],
                      memorial_search_input : ""
                      })}
                    >
                      {item.image_src ? <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/memorial_images/"+item.image_src+"')"}}></div> 
                      : <div className="suggestion_image" style={{backgroundImage: "url('"+default_image+"')"}}></div>
                      }
                      
                      <div className="suggestion_name_address">
                        <div className="suggestion_name">{item.first_name}{item.middle_name ? ' '+item.middle_name : ''} {item.surname}</div>
                        <div className="suggestion_address">{item.family_or_house_name}, {item.locality}, {item.city}, {item.state}</div>
                      </div>
                    </div>
                  ))}
                  <div className="suggestions_container" style={{display: this.state.memorial_search_input && !this.state.memorials.length?"block":"none", boxShadow:!this.state.memorials.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                    <div className="single_suggestion">
                      <div className="suggestion_image"></div>
                      <div className="suggestion_name_address">
                        <div className="suggestion_name">No results</div>
                        <div className="suggestion_address"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            </div>
           
          </div>
          
          <div className="save_button_container">
            {this.state.family_notice_id?
              <ButtonTheme1
                label="Modify"
                className="create_button"
              ></ButtonTheme1>
            :
              <ButtonTheme1
                  label="Create"
                  className="create_button"
                ></ButtonTheme1>
            }
          </div>

        </form>
      </div>
    )
  }
}

export default NewFamilyNotice;
NewFamilyNotice.contextType = MyContext;