import { Component} from "react";
import "./ButtonThemes.scss";
import {GrAdd} from "react-icons/gr";

class ButtonTheme1 extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  } 
  render(){
    return(
      <button 
        className={"button_theme1_container "+this.props.className}
        onClick={this.props.onClick}
        style={this.props.style}
        type={this.props.type}
      >
        {this.props.label}
      </button>
    )
  }
}

class ButtonTheme2 extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  } 
  render(){
    return(
      <button className={"noselect button_theme2_container "+this.props.className} 
        onClick={this.props.onClick}
        type = {this.props.type}
      >
      <div className="button_ghost"></div>
      <div className="button_label">{this.props.label}</div>
    </button>
    )
  }
}

class ButtonTheme3 extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  } 
  render(){
    return(
      <div onClick={this.props.onClick}
        className="button_theme3_container">
        <i className="fa fa-plus"></i>&nbsp;
        {this.props.label}
      </div>
    )
  }
}

class ButtonTheme4 extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  } 
  render(){
    return(
      <div onClick={this.props.onClick} className="button_theme4_container">
        {this.props.label}
      </div>
    )
  }
}

class AddButtonTheme1 extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  } 
  render(){
    return(
      <div onClick={this.props.onClick} className={"add_button_theme1_container "+this.props.className}>
        <GrAdd className="add_button_icon" color="white"></GrAdd>
        <span>{this.props.label}</span>
      </div>
    )
  }
}

export {
  ButtonTheme1,
  ButtonTheme2,
  ButtonTheme3,
  ButtonTheme4,
  AddButtonTheme1
};