import React from "react";
import "./Users.scss";
import { Link } from "react-router-dom";
import $ from "jquery";
import MyContext from "../../MyContext";
import { FcApproval, FcFullTrash } from 'react-icons/fc';
import ReactPaginate from 'react-paginate';
import { FaAngleRight } from "react-icons/fa";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_input: "",
      users: [],
      offset_value: 0,
      current_page: 0,
      per_page: 8,
      page_count: 0,
      page_no_input : 1
    }
    this.form_change = this.form_change.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.delete_user = this.delete_user.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.users_XHR = { abort: () => { } }
    this.users_page_Ref = React.createRef();
  }

  componentDidMount() {
    if(this.props.history.location.state && typeof this.props.history.location.state == "object"){
      this.setState(this.props.history.location.state);
      this.users_page_Ref.current.style.visibility = "visible";
      this.users_page_Ref.current.style.opacity = 1;
    }else{ 
      this.getRegisteredUsers();
    }
  }

  form_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    this.setState({
      [e.target.name]: e.target.value,
      page_no_input : 1
    }, () => {
      this.getRegisteredUsers();
    });
  }

  handle_page_click(e) {
    window.scrollTo({
      top: this.users_page_Ref.current.offsetTop - 80,
      behavior: "smooth"
    });
    this.setState({
      offset_value: e.selected * 8,
      current_page: e.selected,
      page_no_input : e.selected +1
    }, () => {
      this.getRegisteredUsers()
    });
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    }); 
  }

  page_no_form_submit(e){
    window.scrollTo({
      top : this.users_page_Ref.current.offsetTop - 80,
      behavior : "smooth"
    });
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getRegisteredUsers();
    });
  }

  delete_user(id) {
    if (window.location.pathname == "/Dashboard/Users") {
      if (!window.confirm("All information related to this account will be deleted. Are you sure you want to delete?")) {
        return;
      }
      var obj_this = this;
      this.context.loading(1);
      $.ajax({
        url: process.env.REACT_APP_API + "/api/deleteUserAccount",
        data: this.context.bind_session({
          user_id: id ,
          offset_value: this.state.offset_value,
        }),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          $.alert("User account deleted")
          obj_this.setState({
            users: return_data.users,
            page_count: Math.ceil(return_data.page_count / obj_this.state.per_page),
          });
        } else {
          $.alert("Something Went Wrong. Please Try Again.");
        }
        obj_this.context.loading(0);
      }).fail(function () {
        alert("Network error");
      });
    } else {
      if (!window.confirm("All information related to this account will be deleted. Are you sure you want to delete?")) {
        return;
      }
      var obj_this = this;
      this.context.loading(1);
      $.ajax({
        url: process.env.REACT_APP_API + "/api/deletePartnerUserAccount",
        data: this.context.bind_session({ 
          user_id: id,
          offset_value: this.state.offset_value,
        }),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          $.alert("Partner account deleted")
          obj_this.setState({
            users: return_data.partners,
            page_count: Math.ceil(return_data.page_count / obj_this.state.per_page),
          });
        } else {
          $.alert("Something Went Wrong. Please Try Again.");
        }
        obj_this.context.loading(0);
      }).fail(function () {
        alert("Network error");
      });
    }
  }

  getRegisteredUsers() {
    this.context.loading(1);
    var obj_this = this;
    if(window.location.pathname == "/Dashboard/Users") {
      console.log(window.location.pathname)
      this.context.loading(1);
      this.users_XHR.abort();
      this.users_XHR = $.ajax({
        url: process.env.REACT_APP_API + "/api/getRegisteredUsers",
        data: this.context.bind_session({
          offset_value: this.state.offset_value,
          search_phrase: this.state.search_input
        }),
        type: "POST"
      }).done(function (return_data) {
        // console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState({
            users: return_data.users,
            page_count: Math.ceil(return_data.page_count / obj_this.state.per_page),
          }, () => {
            obj_this.context.loading(0);
            obj_this.users_page_Ref.current.style.visibility = "visible";
            obj_this.users_page_Ref.current.style.opacity = 1;
          });
          obj_this.context.setHistoryState({
            users : return_data.users,
            page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
            offset_value : obj_this.state.offset_value,
            current_page : obj_this.state.current_page,
          },obj_this.props.history);
        } else {
          obj_this.context.loading(0);
          $.alert("Something Went Wrong. Please Try Again.");
        }
      }).fail(function (jqXHR, textStatus) {
        if (textStatus != "abort") {
          alert("Network error");
        }
      });
    } else if (window.location.pathname == "/Dashboard/Partners") {
      console.log(window.location.pathname)
      this.context.loading(1);
      this.users_XHR.abort();
      this.users_XHR = $.ajax({
        url: process.env.REACT_APP_API + "/api/getRegisteredPartners",
        data: this.context.bind_session({
          offset_value: this.state.offset_value,
          search_phrase: this.state.search_input
        }),
        type: "POST"
      }).done(function (return_data) {
         console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState({
            users: return_data.partners,
            page_count: Math.ceil(return_data.page_count / obj_this.state.per_page),
          }, () => {
            obj_this.context.loading(0);
            obj_this.users_page_Ref.current.style.visibility = "visible";
            obj_this.users_page_Ref.current.style.opacity = 1;
          });
          obj_this.context.setHistoryState({
            users : return_data.partners,
            page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
            offset_value : obj_this.state.offset_value,
            current_page : obj_this.state.current_page,
          },obj_this.props.history);
        } else {
          obj_this.context.loading(0);
          $.alert("Something Went Wrong. Please Try Again.");
        }
      }).fail(function (jqXHR, textStatus) {
        if (textStatus != "abort") {
          alert("Network error");
        }
      });
    }
  }

  render() {
    return (
      <div className="users_page_container page_container" ref={this.users_page_Ref}>
        <div className="page_header">
          <div className="page_title">{window.location.pathname == "/Dashboard/Users" ? "Users" : "Partners"}</div>
          <div className="search_container">
            <input
              className="search_input"
              name="search_input"
              type="text"
              value={this.state.search_input}
              onChange={this.form_change}
              placeholder="Search..."
            />
            <i className="fas fa-search search_icon"></i>
          </div>
          <div className="new_user_button_container">

          </div>
        </div>
        <div className="custom_table_theme1">
          <div className="table_row header" style={{ fontFamily: "Montserrat_Regular" }}>
            <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Fullname</div>
            <div className="table_cell notice_type">Location</div>
            <div className="table_cell">Email</div>
            <div className="table_cell">Mobile No.</div>
            <div className="table_cell">Country</div>
            <div className="table_cell">Account Type</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.users && this.state.users.map((item, idx) => (
            <div className="table_row body" key={idx} style={{ backgroundColor: item.status == "PENDING" || item.partner_status == "PENDING" ? "#ebf8a4" : item.status == "REJECTED" ? "#ffc0cb" : "" }}>
              <div className="table_cell sl_no">{this.state.offset_value + idx + 1}</div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell notice_type">{item.location}</div>
              <div className="table_cell">{item.email}</div>
              <div className="table_cell">{item.mobile_no}</div>
              <div className="table_cell">{item.country}</div>
              <div className="table_cell">{item.user_type == "user" ? "User" : item.user_type == "partner" ? "Partner" : ""}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_user(item.id)} />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{ display: this.state.users.length ? "none" : "block" }}>No results.</div>
        </div>
        <div className="pagination_container" style={{ display: this.state.users.length && this.state.page_count > 1 ? "block" : "none" }}>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handle_page_click}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.current_page}
          />
          <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span>
              <FaAngleRight/>
            </button>
          </form>
        </div>
        <div className="indication_container">
          <div className="color_label_grid">
            <div className="color_grid_item"></div>
            <div className="label_grid_item">OTP not verified</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Users;
Users.contextType = MyContext;