import React from "react";
import "./NewMemorial.scss";
import { InputTheme4, InputTheme1, InputTheme3, TextAreaTheme1} from "../../../res/styler/InputThemes/InputThemes";
import ImageUpload from "../../../res/styler/ImageUpload/ImageUpload";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import $ from "jquery";
import MyContext from "../../MyContext";
import { Link } from "react-router-dom";
import {GrAdd} from "react-icons/gr";
import {AiOutlinePlus,AiOutlineDelete} from "react-icons/ai";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import ImageEditor from "../../../res/styler/ImageEditor/ImageEditor";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import default_image from "../../../res/images/user_icon.png";

class NewMemorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memorial_id : "",
      first_name: "",
      middle_name : "",
      surname : "",
      nick_name : "",
      nee : "",
      family_or_house_name: "",
      locality: "",
      gender: "Male",
      country: "",
      countries: [],
      state: "",
      state_label: "",
      city: "",
      city_label: "",
      date_of_death: "",
      place_of_death: "",
      age: "",
      place_of_birth: "",
      date_of_birth: "",
      image: "",
      image_src: "",
      file : "",
      countries: [],
      states: [],
      cities: [],
      status : "",
      editorState: EditorState.createEmpty(),
      biography : null,
      survived_peoples : [],
      predeceased_peoples : [],
      milestones : [],
      gallery_images : [],
      life_questions : [],
      general_questions : [],
      profile_pic_delete_flag : 0,
      charity_search_input : "",
      charities : [],
      charity : {},
      death_notice_search_input : "",
      death_notices : [],
      death_notice : {}
    }
    this.getBlob = this.getBlob.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.country_change = this.country_change.bind(this);
    this.state_change = this.state_change.bind(this);
    // this.city_change = this.city_change.bind(this);
    this.gender_change = this.gender_change.bind(this);
    this.form_change = this.form_change.bind(this);
    this.calculate_age = this.calculate_age.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.survived_change = this.survived_change.bind(this);
    this.add_survived_row = this.add_survived_row.bind(this);
    this.delete_survived_row = this.delete_survived_row.bind(this);
    this.survived_image_change = this.survived_image_change.bind(this);
    this.predeceased_change = this.predeceased_change.bind(this);
    this.add_predeceased_row = this.add_predeceased_row.bind(this);
    this.delete_predeceased_row = this.delete_predeceased_row.bind(this);
    this.predeceased_image_change = this.predeceased_image_change.bind(this);
    this.milestone_change = this.milestone_change.bind(this);
    this.milestone_image_change = this.milestone_image_change.bind(this);
    this.add_milestone_row = this.add_milestone_row.bind(this);
    this.delete_milestone_row = this.delete_milestone_row.bind(this);
    this.gallery_change = this.gallery_change.bind(this);
    this.gallery_image_change = this.gallery_image_change.bind(this);
    this.add_gallery_row = this.add_gallery_row.bind(this);
    this.delete_gallery_row = this.delete_gallery_row.bind(this);
    this.choose_question = this.choose_question.bind(this);
    this.life_question_change = this.life_question_change.bind(this);
    this.add_life_question_row = this.add_life_question_row.bind(this);
    this.delete_life_question_row = this.delete_life_question_row.bind(this);
    this.charities_search = this.charities_search.bind(this);
    this.death_notices_search = this.death_notices_search.bind(this);
    this.new_memorial_page_ref = React.createRef();
    this.first_name_ref = React.createRef();
    this.surname_ref = React.createRef();
    this.locality_ref = React.createRef();
    this.country_ref = React.createRef();
    this.state_ref = React.createRef();
    this.city_ref = React.createRef();
    this.age_ref = React.createRef();
    this.date_of_death_ref = React.createRef();
    this.date_of_birth_ref = React.createRef();
    this.biography_ref = React.createRef();
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    const memorial_id = this.props.match.params.memorial_id;
    if(memorial_id){
      this.setState({
        memorial_id : memorial_id
      });
      $.ajax({
        url: process.env.REACT_APP_API + "/api/editMemorial",
        data: this.context.bind_session({memorial_id: memorial_id}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          if(return_data.memorial.first_name){
            const general_questions = return_data.memorial.general_questions;
            general_questions.map((item) => {
              item.question = item.question.replace("---",return_data.memorial.first_name);
            });
          }
          return_data.memorial.date_of_death = new Date(return_data.memorial.date_of_death);
          return_data.memorial.date_of_birth = new Date(return_data.memorial.date_of_birth);
          const family_details = return_data.memorial.family_details;
          return_data.memorial.survived_peoples= family_details.filter((item) => item.type == "survived");
          return_data.memorial.predeceased_peoples= family_details.filter((item) => item.type == "predeceased");
          for(let i =0; i<return_data.memorial.gallery_images.length; i++){
            return_data.memorial.gallery_images[i].gallery_image_src_old = return_data.memorial.gallery_images[i].gallery_image_src?process.env.REACT_APP_CDN_URL+"/"+return_data.memorial.entered_by+"/gallery_images/"+return_data.memorial.gallery_images[i].gallery_image_src:null;
          }
          for(let i =0; i<return_data.memorial.milestones.length; i++){
            return_data.memorial.milestones[i].image_src_old = return_data.memorial.milestones[i].image_src?process.env.REACT_APP_CDN_URL+"/"+return_data.memorial.entered_by+"/milestone_images/"+return_data.memorial.milestones[i].image_src:null;
          }
          const contentBlock = htmlToDraft(return_data.memorial.biography);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return_data.memorial.editorState = editorState;
          }
          obj_this.setState(return_data.memorial,() => {
            obj_this.new_memorial_page_ref.current.style.visibility = "visible";
            obj_this.new_memorial_page_ref.current.style.opacity = 1;
          });
          obj_this.context.loading(0);
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }else{
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getCountriesQuestions",
        data: this.context.bind_session({}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState({
            countries: return_data.countries,
            general_questions: return_data.general_questions
          },() => {
            obj_this.new_memorial_page_ref.current.style.visibility = "visible";
            obj_this.new_memorial_page_ref.current.style.opacity = 1;
          });
          obj_this.context.loading(0);
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }
    $("form input[type=text]").on("keypress", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        $(this).nextAll("input:first").trigger("focus");
      }
    });
  }

  getBlob(blob){
    // pass blob up from the ImageCropper component
    this.setState({
      file : blob
    });
    // console.log(blob)
  }

  delete_image(){
    this.setState({
      profile_pic_delete_flag : 1,
      image_src : null,
      file : ""
    });
    console.log("delete")
  }

  gender_change(e){
    this.setState({
      gender : e.target.value,
      nee : ""
    });
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onEditorStateChange(value){
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
        editorState :value,
        biography : draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  calculate_age(date_of_birth,date_of_death){
    var months,age = "";
    months = (new Date(date_of_death).getFullYear() - new Date(date_of_birth).getFullYear()) * 12;
    months -= new Date(date_of_birth).getMonth();
    months += new Date(date_of_death).getMonth();
    months = months ==0?1:months;
    // months = months <= 0 ? 0 : months;
    if(isNaN(months)){
      this.setState({
        age : ""
      });
      return;
    }
    if(months >= 12){
      age = Math.floor(months/12);
    }else{
      age = months>1?months+ " Months":months+" Month";
    }
    this.setState({
      age : age
    });
  }

  country_change(e) {
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: this.context.bind_session({ country: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const country = obj_this.state.countries.find((item) => item.id === e.target.value ); 
        obj_this.setState({
          states : return_data.states,
          cities : [],
          state : "",
          city: "",
          country: e.target.value,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  };

  state_change(e) {
    const state = this.state.states.find((item) => item.id === e.target.value ); 
    this.setState({
      state: e.target.value,
      state_label : state.name
    });
  };

  // city_change(e) {
  //   const city = this.state.cities.find((item) => item.id === e.target.value ); 
  //   this.setState({
  //     city: e.target.value,
  //     city_label : city.name
  //   });
  // };
  
  survived_change(e,idx){
    var survived_peoples = this.state.survived_peoples;
    var survived_detail = survived_peoples[idx];
    // survived_detail[e.target.name] = e.target.value.replace(/[^a-z ]/gi, '');
    survived_detail[e.target.name] = e.target.value;
    this.setState({
      survived_peoples : survived_peoples
    });
  }

  survived_image_change(e,idx){
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
      var survived_peoples = this.state.survived_peoples;
      var survived_people = survived_peoples[idx];
      survived_people.image= file;
      this.setState({
        survived_peoples : survived_peoples
      });
    // }
  }

  add_survived_row(idx){
    var survived_peoples = this.state.survived_peoples;
    var item = {
     name : "",
     image_src : "",
     image : "",
     relation : ""
    }
    survived_peoples.splice(idx+1, 0, item);
    this.setState({
      survived_peoples : survived_peoples
    });
  }

  delete_survived_row(idx){
    console.log(idx)
    var survived_peoples = this.state.survived_peoples;
    const new_survived_peoples = survived_peoples.filter((item,index) => index != idx);
    this.setState({
      survived_peoples : new_survived_peoples
    });
  }

  predeceased_change(e,idx){
    var predeceased_peoples = this.state.predeceased_peoples;
    var predeceased_detail = predeceased_peoples[idx];
    // predeceased_detail[e.target.name] = e.target.value.replace(/[^a-z ]/gi, '');
    predeceased_detail[e.target.name] = e.target.value;
    this.setState({
      predeceased_peoples : predeceased_peoples
    });
  }

  predeceased_image_change(e,idx){
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
      var predeceased_peoples = this.state.predeceased_peoples;
      var predeceased_people = predeceased_peoples[idx];
      predeceased_people.image= file;
      this.setState({
        predeceased_peoples : predeceased_peoples
      });
    // }
  }

  add_predeceased_row(idx){
    var predeceased_peoples = this.state.predeceased_peoples;
    var item = {
     name : "",
     image_src : "",
     image : "",
     relation : ""
    }
    predeceased_peoples.splice(idx+1, 0, item);
    this.setState({
      predeceased_peoples : predeceased_peoples
    });
  }

  delete_predeceased_row(idx){
    console.log(idx)
    var predeceased_peoples = this.state.predeceased_peoples;
    const new_predeceased_peoples = predeceased_peoples.filter((item,index) => index != idx);
    this.setState({
      predeceased_peoples : new_predeceased_peoples
    });
  }

  milestone_change(e,idx){
    // console.log(e.target.value,idx);
    if(e.target.value.length > 140){
      alert("Maximum characters exceeded");
      return;
    }
    var milestones = this.state.milestones;
    var milestone = milestones[idx];
    milestone[e.target.name] = e.target.value;
    this.setState({
      milestones : milestones
    });
  }

  milestone_image_change(e,idx){
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
      var milestones = this.state.milestones;
      var milestone = milestones[idx];
      milestone.image= file;
      milestone.image_src=  URL.createObjectURL(file);
      this.setState({
        milestones : milestones
      });
    // }
  }

  add_milestone_row(idx){
    var milestones = this.state.milestones;
    var item = {
      date : "",
      image : "",
      image_src : "",
      title : "",
    }
    milestones.splice(idx+1, 0, item);
    this.setState({
      milestones : milestones
    });
  }

  delete_milestone_row(e,idx){
    const table_row = $(e.target).parents(".table_row");
    table_row.find(".preview_image").attr("src",table_row.next().find(".preview_image").attr("src"));
    var milestones = this.state.milestones;
    const new_milestones = milestones.filter((item,index) => index != idx);
    this.setState({
      milestones : new_milestones
    });
  }

  gallery_change(e,idx){
    // console.log(e.target.value,idx);
    if(e.target.value.length > 140){
      alert("Maximum characters exceeded");
      return;
    }
    var gallery_images = this.state.gallery_images;
    var gallery = gallery_images[idx];
    gallery[e.target.name] = e.target.value;
    this.setState({
      gallery_images : gallery_images
    });
  }

  gallery_image_change(e,idx){
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
      var gallery_images = this.state.gallery_images;
      var gallery = gallery_images[idx];
      gallery.image= file;
      gallery.gallery_image_src=  URL.createObjectURL(file);
      this.setState({
        gallery_images : gallery_images,
      });
  }

  add_gallery_row(idx){
    var gallery_images = this.state.gallery_images;
    var item = {
      image : "",
      gallery_image_src : "",
      gallery_image_caption : ""
    }
    gallery_images.splice(idx+1, 0, item);
    this.setState({
      gallery_images : gallery_images
    });
  }

  delete_gallery_row(e,idx){
    const table_row = $(e.target).parents(".table_row");
    table_row.find(".preview_image").attr("src",table_row.next().find(".preview_image").attr("src"));
    var gallery_images = this.state.gallery_images;
    // console.log(gallery_images[idx]);
    // return;
    gallery_images[idx]['image'] = undefined;
    gallery_images[idx]['gallery_image_src'] = undefined;
    gallery_images = gallery_images.filter((item,index) => index != idx);
    // const new_gallery_images = gallery_images.splice(idx,1);
    
    console.log(gallery_images);
    this.setState({
      gallery_images : gallery_images
    });
  }

  life_question_change(e,idx){
    if(e.target.value.length > 140){
      alert("Maximum characters exceeded");
      return;
    }
    var life_questions = this.state.life_questions;
    var life_question = life_questions[idx];
    life_question[e.target.name] = e.target.value;
    this.setState({
      life_questions : life_questions
    });
  }
  
  choose_question(idx,e){
    console.log(e.target.value,idx);
    const question = this.state.general_questions.find(item => item.id === e.target.value);
      var life_questions = this.state.life_questions;
      var life_question = life_questions[idx];
    if(e.target.value != 0){
      life_question.selected_question = question.question;
      life_question.question = question.question;
    }else{
      life_question.selected_question = "new";
      life_question.question = "";
    }
    this.setState({
      life_questions : life_questions
    });
  }

  add_life_question_row(idx){
    var life_questions = this.state.life_questions;
    var item = {
      question : ""
    }
    life_questions.splice(idx+1, 0, item);
    this.setState({
      life_questions : life_questions
    });
  }

  delete_life_question_row(idx){
    console.log(idx)
    var life_questions = this.state.life_questions;
    const new_life_questions = life_questions.filter((item,index) => index != idx);
    this.setState({
      life_questions : new_life_questions
    });
  }

  form_submit(e) {
    e.preventDefault();
    // console.log(this.state);
    // return;
    if(!this.state.first_name){
      $.alert("First Name Required");
      window.scrollTo({
        top : this.first_name_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.first_name_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    // if(!this.state.surname){
    //   $.alert("Surname Required");
    //   window.scrollTo({
    //     top : this.surname_ref.current.offsetTop,
    //     behavior : "smooth"
    //   });
    //   $(this.surname_ref.current).addClass('input_theme4_SELECTED')
    //   return;
    // }

    if(!this.state.locality){
      $.alert("Locality Required");
      window.scrollTo({
        top : this.locality_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.locality_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.country){
      $.alert("Country Required");
      window.scrollTo({
        top : this.country_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }

    if(this.state.states.length && !this.state.state){
      $.alert("State/Province/County/Region Required");
      window.scrollTo({
        top : this.state_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }

    if(!this.state.city){
      $.alert("City/Town Required");
      window.scrollTo({
        top : this.city_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.city_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.date_of_birth){
      $.alert("Date of Birth Required");
      window.scrollTo({
        top : this.date_of_birth_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(!this.state.date_of_death){
      $.alert("Date of Death Required");
      window.scrollTo({
        top : this.date_of_death_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(Date.parse(this.state.date_of_death) < Date.parse(this.state.date_of_birth)){
      $.alert("Invalid Date of Death or Date of Birth");
      window.scrollTo({
        top : this.date_of_death_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    } 
    if(!this.state.editorState.getCurrentContent().hasText()){
      $.alert("Create a Biography for "+ this.state.first_name+" "+this.state.surname);
      window.scrollTo({
        top : this.biography_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    // return;
    const memorial_details = this.state;
    delete memorial_details.editorState;
    delete memorial_details.general_questions;
    delete memorial_details.state_label;
    delete memorial_details.city_label;
    delete memorial_details.charity_search_input;
    delete memorial_details.death_notice_search_input;
    // memorial_details.biography = memorial_details.biography;
    const form_data = new FormData();
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image", this.state.file);
    const survived_peoples = this.state.survived_peoples;
    for(var i=0;i<survived_peoples.length;i++){
      if(memorial_details.memorial_id){
        form_data.append('survived_images_src['+i+']', survived_peoples[i].image_src??""); // change this to value
      }
      form_data.append('survived_images['+i+']', survived_peoples[i].image??""); // change this to value
    }
    const predeceased_peoples = this.state.predeceased_peoples;
    for(var i=0;i<predeceased_peoples.length;i++){
      if(memorial_details.memorial_id){
        form_data.append('predeceased_images_src['+i+']', predeceased_peoples[i].image_src??""); // change this to value
      }
      form_data.append('predeceased_images['+i+']', predeceased_peoples[i].image??""); // change this to value
    }
    for(var i=0;i<memorial_details.milestones.length;i++){
      delete memorial_details.milestones[i]['image_src_old'];
      if(memorial_details.memorial_id){
        form_data.append('milestone_images_src['+i+']', memorial_details.milestones[i].image_src??""); // change this to value
      }
      // console.log(memorial_details.milestones[i].image);
      form_data.append('milestone_images['+i+']', memorial_details.milestones[i].image??"");
    }
    // return;
    for(var i=0;i<memorial_details.gallery_images.length;i++){
      delete memorial_details.gallery_images[i]['gallery_image_src_old'];
      if(memorial_details.gallery_images[i].image || memorial_details.gallery_images[i].gallery_image_src){
        if(memorial_details.memorial_id){
          form_data.append('gallery_images_src['+i+']', memorial_details.gallery_images[i].gallery_image_src); // change this to value
        }
        form_data.append('gallery_images_list['+i+']', memorial_details.gallery_images[i].image??""); // change this to value
      }else{
        $.alert("Gallery image required");
        return;
      }
    }
    memorial_details.date_of_death = memorial_details.date_of_death.toLocaleString('en-CA',{ day: '2-digit', month: '2-digit', year: 'numeric'});
    memorial_details.date_of_birth = memorial_details.date_of_birth ? memorial_details.date_of_birth.toLocaleDateString('en-CA',{ day: '2-digit', month: '2-digit', year: 'numeric'}) : "";
    form_data.append("memorial_details", JSON.stringify(memorial_details));
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveMemorial",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data);
      // return;
      if (return_data.status == "insert") {
        obj_this.context.loading(0);
        if(obj_this.context.session.user_type == "admin"){
          alert("Memorial created");
        }else{
          alert("Admin Approval Awaiting. Please check back later.");
        }
        obj_this.props.history.push(obj_this.props.location.url ?? "/Dashboard/Memorials/MyEntries/");
      }else if(return_data.status == "update"){
        obj_this.context.loading(0);
        alert("Memorial updated.")
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard/Memorials/MyEntries",
          state : obj_this.props.location.previousState??""
        });
      }else{
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    })
  }

  charities_search(e){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/searchCharities",
      data: this.context.bind_session({ phrase: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          charities : return_data.charities,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    this.setState({
      charity_search_input : e.target.value
    });
  }

  death_notices_search(e){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/searchDeathNotices",
      data: this.context.bind_session({ phrase: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          death_notices : return_data.death_notices,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    this.setState({
      death_notice_search_input : e.target.value
    });
  }

  render() {
    return (
      <div className="new_memorial_page_container page_container" ref={this.new_memorial_page_ref}>
       
        <form className="new_memorial_form" encType="multipart/form-data" onSubmit={this.form_submit}>
          <div className="page_header">
            <div className="page_title">{this.state.memorial_id?"Edit":"Create"} Memorial</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/Memorials/MyEntries">Memorials</Link>/
              <span>{this.state.memorial_id?"Edit":"Create"}</span>
            </div> */}
          </div>
          <div className="memorial_details">
            <div className="image_editor_container">
              <ImageEditor
                getBlob={this.getBlob}
                image_src = {this.state.image_src}
                current_image = {process.env.REACT_APP_CDN_URL+"/"+this.state.entered_by+"/memorial_images/"+this.state.image_src}
                deleteImageSrc = {this.delete_image}
                image_width = {210 }
                image_height = {270 }
              />
            </div>
            <div className="basic_details">
              <InputTheme4
                label="First Name *"
                attrs={{
                  name: "first_name",
                  type: "text",
                  value: this.state.first_name,
                  onChange: this.form_change,
                  maxLength : 255
                }}
                input_ref={this.first_name_ref}
              />
              <InputTheme4
                label="Middle Name"
                attrs={{
                  name: "middle_name",
                  type: "text",
                  value: this.state.middle_name,
                  onChange: this.form_change,
                  maxLength : 255
                }}
              />
              <InputTheme4
                label="Surname"
                attrs={{
                  name: "surname",
                  type: "text",
                  value: this.state.surname,
                  onChange: this.form_change,
                  maxLength : 255
                }}
                input_ref={this.surname_ref}
              />
              <InputTheme4
                label="Nick Name"
                attrs={{
                  name: "nick_name",
                  type: "text",
                  value: this.state.nick_name,
                  onChange: this.form_change,
                  maxLength : 255
                }}
              />
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Gender *</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={this.gender_change}
                  value={this.state.gender}
                >
                  <FormControlLabel value="Male" control={<Radio />} label="Male" />
                  <FormControlLabel value="Female" control={<Radio />} label="Female" />
                  <FormControlLabel value="Non-binary" control={<Radio />} label="Non-binary" />
                </RadioGroup>
              </FormControl>
              <div style={{display:this.state.gender == "Female"?"block":"none"}}>
                <InputTheme4
                  label="Née (A woman’s family name at birth)"
                  attrs={{
                    name: "nee",
                    type: "text",
                    value: this.state.nee,
                    onChange: this.form_change,
                    maxLength : 255
                  }}
                />
              </div>
              <InputTheme4
                label="Family/House name"
                attrs={{
                  name: "family_or_house_name",
                  type: "text",
                  value: this.state.family_or_house_name,
                  onChange: this.form_change,
                  maxLength : 255
                }}
              />
              <InputTheme4
                label="Locality *"
                attrs={{
                  name: "locality",
                  value: this.state.locality,
                  onChange: this.form_change,
                  type: "text",
                  maxLength : 255
                }}
                input_ref={this.locality_ref}
              />

              <FormControl variant="standard" ref={this.country_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">Country *</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.country}
                  onChange={this.country_change}
                  label="Country"
                >
                  {this.state.countries && this.state.countries.map((item, idx) => (
                    <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" ref={this.state_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">State/Province/County/Region {this.state.states.length?"*":""}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.state}
                  onChange={this.state_change}
                  label="State"
                >
                  {this.state.states && this.state.states.map((item, idx) => (
                    <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputTheme4
                label="City/Town *"
                attrs={{
                  name: "city",
                  type: "text",
                  value: this.state.city,
                  onChange: this.form_change,
                  maxLength : 255
                }}
                input_ref={this.city_ref}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker ref={this.date_of_birth_ref}
                  label="Date of birth *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                    }
                  }}
                  value={this.state.date_of_birth}
                  onChange={(newValue) => {
                    this.calculate_age(newValue, this.state.date_of_death);
                    this.setState({ 
                      date_of_birth: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy" 
                  allowSameDateSelection={true}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker ref={this.date_of_death_ref}
                  label="Date of death *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                    }
                  }}
                  value={this.state.date_of_death}
                  onChange={(newValue) => {
                    this.calculate_age(this.state.date_of_birth, newValue);
                    this.setState({
                      date_of_death: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={this.state.date_of_birth?new Date(this.state.date_of_birth):undefined}
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy" 
                  allowSameDateSelection={true}
                />
              </LocalizationProvider>

              {/* <InputTheme4
                label="Place of death"
                attrs={{
                  name: "place_of_death",
                  type: "text",
                  value: this.state.place_of_death,
                  onChange: this.form_change
                }}
              />
              <InputTheme4
                label="Place of birth"
                attrs={{
                  name: "place_of_birth",
                  type: "text",
                  value: this.place_of_birth,
                  onChange: this.form_change
                }}
              /> */}
              <InputTheme4
                label="Age"
                attrs={{
                  name: "age",
                  type: "text",
                  disabled : true,
                  value: this.state.age,
                  onChange: this.form_change,
                }}
              />

            </div>
          </div>
          <div className="notice_label" ref={this.biography_ref}>{this.state.id?"Edit":"Add"} Biography *</div>
          {/* <textarea
            name="biography"
            value={this.state.biography}
            placeholder="Enter biography here.."
            onChange={this.form_change}
            rows={8}
            required={true}
          /> */}
          <Editor
            editorState={this.state.editorState}
            toolbarClassName="editor_toolbar"
            wrapperClassName="editor_component"
            editorClassName="editor_input_area"
            onEditorStateChange={this.onEditorStateChange}
            placeholder={"Enter biography here.."}
            handlePastedText={() => false}
            stripPastedStyles={true}
            toolbar={{
              options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
              },list: {
                options: ['unordered', 'ordered'],
              },
              fontSize: {
                options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
              },
            }}
          />

          <div className="survived_peoples_details_container" style={{display:this.state.survived_peoples.length?"block":"none"}}>
            <div className="other_details_title">Surviving Family Details (Maximum: 12 Peoples)</div>
            <div className="custom_table_theme1 survived_peoples">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell">Name</div>
                <div className="table_cell">Relationship/Other Details</div>
                <div className="table_cell">Image</div>
                <div className="table_cell close_button_container">
                  <div className="close_other_details_table"
                    onClick={() => this.setState({
                      survived_peoples : []
                    })}
                  >x</div>
                </div>
              </div>
              {this.state.survived_peoples && this.state.survived_peoples.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Name</div>
                    <input 
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={item.name}
                      onChange={(e) => this.survived_change(e,idx)}
                      maxLength={25}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Relation and other details</div>
                    <input 
                      name="relation"
                      placeholder="Enter Relationship/Other Details"
                      type="text"
                      value={item.relation}
                      onChange={(e) => this.survived_change(e,idx)}
                      maxLength={35}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Image</div>
                    <input 
                       type="file"
                       name="image"
                       // value={item.image}
                       accept="image/*"
                       onChange={(e) => this.survived_image_change(e,idx)}
                    />
                    {item.image_src}
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                    style={{visibility:this.state.survived_peoples.length<12?"visible":"hidden"}}
                      onClick={() => this.add_survived_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={() => this.delete_survived_row(idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="predeceased_peoples_details_container" style={{display:this.state.predeceased_peoples.length?"block":"none"}}>
            <div className="other_details_title">Predeceased Family Details (Maximum: 6 Peoples)</div>
            <div className="custom_table_theme1 predeceased_peoples_">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell">Name</div>
                <div className="table_cell">Relationship/Other Details</div>
                <div className="table_cell">Image</div>
                <div className="table_cell close_button_container">
                  <div className="close_other_details_table"
                    onClick={() => this.setState({
                      predeceased_peoples : []
                    })}
                  >x</div>
                </div>
              </div>
              {this.state.predeceased_peoples && this.state.predeceased_peoples.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Name</div>
                    <input 
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={item.name}
                      onChange={(e) => this.predeceased_change(e,idx)}
                      maxLength={25}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Relation and other details</div>
                    <input 
                      name="relation"
                      placeholder="Enter Relationship/Other Details"
                      type="text"
                      value={item.relation}
                      onChange={(e) => this.predeceased_change(e,idx)}
                      maxLength={35}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Image</div>
                    <input 
                       type="file"
                       name="image"
                       accept="image/*"
                       onChange={(e) => this.predeceased_image_change(e,idx)}
                    />
                    {item.image_src}
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                      style={{visibility:this.state.predeceased_peoples.length<6?"visible":"hidden"}}
                      onClick={() => this.add_predeceased_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={() => this.delete_predeceased_row(idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="milestone_details_container" style={{display:this.state.milestones.length?"block":"none"}}>
            <div className="other_details_title">Milestone Details</div>
            <div className="custom_table_theme1 milestone_details">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell fullname">Date</div>
                <div className="table_cell image_column">Image</div>
                <div className="table_cell">Title</div>
                <div className="table_cell close_button_container"> <div className="close_other_details_table"
                    onClick={() => this.setState({
                      milestones : []
                    })}
                  >x</div>
                </div>
              </div>
              {this.state.milestones && this.state.milestones.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Date</div>
                    <input 
                      type="date"
                      value={item.date}
                      name="date"
                      onChange={(e) => this.milestone_change(e,idx)}
                      min={new Date(this.state.date_of_birth).toLocaleDateString('en-CA')}
                      max={new Date(this.state.date_of_death).toLocaleDateString('en-CA')}
                      required
                    />
                  </div>
                  <div className="table_cell image_column">
                    <div className="input_label">Image</div>
                    {/* <input 
                       type="file"
                       name="image"
                       accept="image/*"
                       onChange={(e) => this.milestone_image_change(e,idx)}
                    />
                    {item.image_src} */}
                    <ImageUpload  className="milestone_image"
                      attrs={{
                        name: "milestone_image",
                        accept: "image/png, image/gif, image/jpeg",
                      }}
                      onChange={(e) => {this.milestone_image_change(e,idx)}}
                      // deleteImage = {this.delete_image}
                      // revertImage = {this.revert_image}
                      src = {item.image_src_old??item.image_src}
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Title</div>
                    <textarea
                      name="title"
                      value={item.title}
                      onChange={(e) => this.milestone_change(e,idx)}
                      required
                      placeholder="Maximum 140 characters"
                    />
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                      onClick={() => this.add_milestone_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={(e) => this.delete_milestone_row(e,idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
              <div className="table_row no_results" style={{display:this.state.milestones.length?"none":"block"}}>No results.</div>
            </div>
          </div>
          <div className="gallery_life_question_grid">
            <div className="life_question_details_container" style={{display:this.state.life_questions.length?"block":"none"}}>
              <div className="other_details_title">Add Question for Let's Reminisce Section (Maximum: 6 Questions)</div>
              <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}> We encourage you to ask relevant questions to engage visitors to the profile.</div>
              <div className="custom_table_theme1 life_question_details">
                <div className="table_row header">
                  <div className="table_cell sl_no">Sl No.</div>
                  <div className="table_cell fullname">Question</div>
                  <div className="table_cell close_button_container"> <div className="close_other_details_table"
                    onClick={() => this.setState({
                      life_questions : []
                    })}
                  >x</div>
                </div>
                </div>
                {this.state.life_questions && this.state.life_questions.map((item,idx) => (
                  <div className="table_row body" key={idx} >
                    <div className="table_cell sl_no">{idx+1}</div>
                    <div className="table_cell">
                      <div className="input_label2"> You can choose question from below dropdown or can write your  own.</div>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">Choose question</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={item.selected_question}
                          onChange={(e) => this.choose_question(idx,e)}
                          label="Choose Question"
                          // required
                        >
                          <MenuItem value="0">Your own question</MenuItem>
                          {this.state.general_questions && this.state.general_questions.map((item, idx) => (
                            <MenuItem value={item.id} question={item.question} key={idx}>{item.question}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <textarea
                        style={{display:item.selected_question == "new" || item.question?"block":"none"}}
                        name="question"
                        value={item.question}
                        onChange={(e) => this.life_question_change(e,idx)}
                        required
                        placeholder="Write your question here (Max 140 characters)"
                      />
                    </div>
                    <div className="table_cell buttons_container">
                      <div className="add_row_button"
                      style={{visibility:this.state.life_questions.length < 6?"visible":"hidden"}}
                        onClick={() => this.add_life_question_row(idx)}
                      >
                        <AiOutlinePlus/>
                      </div>
                      <div className="delete_row_button"
                        style={{visibility:"visible"}}
                        onClick={() => this.delete_life_question_row(idx)}
                      >
                        <AiOutlineDelete/>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="table_row no_results" style={{display:this.state.life_questions.length?"none":"block"}}>No results.</div>
              </div>
            </div>
            <div className="gallery_image_details_container" style={{display:this.state.gallery_images.length?"block":"none"}}>
              <div className="other_details_title">Upload Gallery Images (Maximum: 20 Images)</div>
              <div className="tooltip" style={{visibility:"hidden"}}> We encourage</div>
              <div className="custom_table_theme1 gallery_image_details">
                <div className="table_row header">
                  <div className="table_cell sl_no">Sl No.</div>
                  <div className="table_cell fullname">Image</div>
                  <div className="table_cell notice_type">Caption</div>
                  <div className="table_cell close_button_container"> <div className="close_other_details_table"
                    onClick={() => this.setState({
                      gallery_images : []
                    })}
                  >x</div>
                </div>
                </div>
                {this.state.gallery_images && this.state.gallery_images.map((item,idx) => (
                  <div className="table_row body" key={idx} >
                    <div className="table_cell sl_no">{idx+1}</div>
                    <div className="table_cell image_column">
                      <div className="input_label">Image</div>
                      <ImageUpload  className="milestone_image"
                        attrs={{
                          name: "milestone_image",
                          accept: "image/png, image/gif, image/jpeg",
                        }}
                        onChange={(e) => {this.gallery_image_change(e,idx)}}
                        // deleteImage = {this.delete_image}
                        // revertImage = {this.revert_image}
                        src = {item.gallery_image_src_old??item.gallery_image_src}
                      />
                    </div>
                    <div className="table_cell">
                      <div className="input_label">Caption</div>
                      <textarea
                        name="gallery_image_caption"
                        value={item.gallery_image_caption}
                        onChange={(e) => this.gallery_change(e,idx)}
                        placeholder="Maximum 140 characters"
                      />
                    </div>
                    <div className="table_cell buttons_container">
                      <div className="add_row_button"
                        style={{visibility:this.state.gallery_images.length < 20?"visible":"hidden"}}
                        onClick={() => this.add_gallery_row(idx)}
                      >
                        <AiOutlinePlus/>
                      </div>
                      <div className="delete_row_button"
                        style={{visibility:"visible"}}
                        onClick={(e) => this.delete_gallery_row(e,idx)}
                      >
                        <AiOutlineDelete/>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="table_row no_results" style={{display:this.state.gallery_images.length?"none":"block"}}>No results.</div>
              </div>
            </div>
          </div>
          
          <div className="other_details_buttons_container">
          <button className="other_details_button"
              type="button"
              style={{display:this.state.survived_peoples.length?"none":"inline-block"}}
              onClick={() => this.setState({
                survived_peoples : [
                  {
                    name : "",
                    image_src : "",
                    relation : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("survived_peoples_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12} />
              Add Surviving Family Members
            </button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.predeceased_peoples.length?"none":"inline-block"}}
              onClick={() => this.setState({
                predeceased_peoples : [
                  {
                    name : "",
                    image_src : "",
                    relation : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("predeceased_peoples_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12} />
              Add Predeceased Family Members
            </button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.milestones.length?"none":"inline-block"}}
              onClick={() => this.setState({
                milestones : [
                  {
                    date : "",
                    image : "",
                    image_src : "",
                    title : "",
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("milestone_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12} /> Add Milestone Details</button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.life_questions.length?"none":"inline-block"}}
              onClick={() => {
              if(this.state.first_name){
                const general_questions = this.state.general_questions;
                general_questions.map((item) => {
                  item.question = item.question.replace("---",this.state.first_name);
                });
              }
              this.setState({
                life_questions : [
                  {
                    question : "",
                    // selected_question : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("gallery_life_question_grid")[0].offsetTop,
                  behavior : "smooth"
                });
              });
            }}
            ><GrAdd color="green" size={12} />
             Lets Reminisce (Choose Questions)
            </button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.gallery_images.length?"none":"inline-block"}}
              onClick={() => this.setState({
                gallery_images : [
                  {
                    image : "",
                    gallery_image_src : "",
                    gallery_image_caption : "",
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("gallery_life_question_grid")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12} /> Upload Gallery Images</button>
          </div>
          <div className="notice_label">Connect Death Notice</div>
          <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}>Please choose a death notice of your loved one if you already added.</div>
          <div className="charity_selection_container">
          {/* <Link className="view_all_charity_button" to="/Charities" target={"_blank"}>View All Charity</Link> 
          */}
            <div className="charity_button_container">
              {/* <div className="view_all_charity_button" onClick={() => this.props.history.push("/Charities")}>View All Charity</div> */}
            </div>
            <div className="charity_section">
              <div className="search_and_suggestions_container">
                <div className="selected_notice" style={{display:this.state.death_notice.id?"inline-grid":"none"}}>
                  {/* <div className="selected_label">Selected Death notice</div> */}
                  <div className="single_suggestion">
                  {this.state.death_notice.image_src ? 
                    <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/death_notice_images/"+this.state.death_notice.image_src+"')"}}></div> :
                    <div className="suggestion_image" style={{backgroundImage: "url('"+default_image+"')"}}></div>
                    }
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">{this.state.death_notice.first_name} {this.state.surname}</div>
                      <div className="suggestion_address">{this.state.death_notice.family_or_house_name} {this.state.death_notice.locality}, {this.state.death_notice.city}, {this.state.death_notice.state}</div>
                    </div>
                    <div className="charity_close_button" onClick={
                      () => {
                        this.setState({
                        death_notice : {}
                      })
                    }}>x</div>
                  </div>
                </div>
                <div className="search_container">
                  <input
                    className="search_input"
                    name="death_notice_search_input"
                    type="text"
                    value={this.state.death_notice_search_input}
                    onChange={this.death_notices_search}
                    placeholder="Search Death Notice"
                    autoComplete = "off"
                  />
                  <i className="fas fa-search search_icon"></i>
                </div>
                <div className="suggestions_container" style={{boxShadow:this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                {this.state.death_notices && this.state.death_notices.map((item,idx) => (
                  <div className="single_suggestion" key={idx} onClick={() => this.setState({
                    death_notice : item,
                    death_notices : [],
                    death_notice_search_input : ""
                    })}
                  >
                     {item.image_src ? 
                    <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/death_notice_images/"+item.image_src+"')"}}></div> :
                    <div className="suggestion_image" style={{backgroundImage: "url('"+default_image+"')"}}></div>
                    }
                  <div className="suggestion_name_address">
                    <div className="suggestion_name">{item.first_name} {item.surname}</div>
                    <div className="suggestion_address">{item.family_or_house_name} {item.locality}, {item.city}, {item.state}</div>
                  </div>
                </div>
              ))}
                <div className="suggestions_container" style={{display: this.state.death_notice_search_input && !this.state.death_notices.length?"block":"none", boxShadow:!this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                  <div className="single_suggestion">
                    <div className="suggestion_image"></div>
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">No results</div>
                      <div className="suggestion_address"></div>
                    </div>
                  </div>
                </div>
                  
                </div>
                
              </div>
            </div>
          </div>
          <div className="notice_label">Charity Details</div>
          <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}>Please choose a charity of your choice in memory of your loved one if you wish to do so.</div>
          <div className="charity_selection_container">
          {/* <Link className="view_all_charity_button" to="/Charities" target={"_blank"}>View All Charity</Link> 
          */}
            <div className="charity_button_container">
              <Link className="view_all_charity_button" to="/Charities" target={"_blank"}>View All Charity</Link>
            </div>
            <div className="charity_section">
              <div className="search_and_suggestions_container">
              <div className="search_container">
                  <input
                    className="search_input"
                    name="charity_search_input"
                    type="text"
                    value={this.state.charity_search_input}
                    onChange={this.charities_search}
                    placeholder="Search Charity"
                    autoComplete = "off"
                  />
                  <i className="fas fa-search search_icon"></i>
                </div>
                <div className="selected_notice" style={{display:this.state.charity.id?"inline-grid":"none"}}>
                  {/* <div className="selected_label">Charity Selected</div> */}
                  <div className="single_suggestion">
                    <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+this.state.charity.image_src+"')"}}></div>
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">{this.state.charity.name}</div>
                      <div className="suggestion_address">{this.state.charity.locality}, {this.state.charity.city}, {this.state.charity.state}</div>
                    </div>
                    <div className="charity_close_button" onClick={
                      () => {
                        this.setState({
                        charity : {}
                      })
                    }}>x</div>
                  </div>
                </div>
                
                <div className="suggestions_container" style={{boxShadow:this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                {this.state.charities && this.state.charities.map((item,idx) => (
                  <div className="single_suggestion" key={idx} onClick={() => this.setState({
                    charity : item,
                    charities : [],
                    charity_search_input : ""
                    })}
                  >
                  <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+item.image_src+"')"}}></div>
                  <div className="suggestion_name_address">
                    <div className="suggestion_name">{item.name}</div>
                    <div className="suggestion_address"> {item.locality}, {item.city}, {item.state}</div>
                  </div>
                </div>
              ))}
                <div className="suggestions_container" style={{display: this.state.charity_search_input && !this.state.charities.length?"block":"none", boxShadow:!this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                  <div className="single_suggestion">
                    <div className="suggestion_image"></div>
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">No results</div>
                      <div className="suggestion_address"></div>
                    </div>
                  </div>
                </div>
                  
                </div>
                
              </div>
            </div>
          </div>
          <div className="save_button_container">
            {this.state.memorial_id?
              <ButtonTheme1
                label="Modify"
                className="create_button"
              ></ButtonTheme1>
            :
              <ButtonTheme1
                  label="Create"
                  className="create_button"
                ></ButtonTheme1>
            }
          </div>
        </form>
      </div>
    )
  }
}

export default NewMemorial;
NewMemorial.contextType = MyContext;