import {useState, useEffect, Component} from "react";
import "./InputThemes.scss";
import {FiEyeOff,FiEye} from "react-icons/fi";
import $ from "jquery";

class InputTheme1 extends Component{
  constructor(props){
    super(props);
  } 
  render(){
    return(
      <div className="input_theme1_container">
        <div className="input_label">{this.props.label}</div>
        <input
          autoComplete='off'
          {...this.props.attrs}
        />
      </div>
    )
  }
}

class InputTheme2 extends Component{
  constructor(props){
    super(props);
  } 
  render(){
    return(
      <div className="input_theme2_container">
        {/* <div className="input_label">{this.props.label}</div> */}
        <input
          autoComplete='off'
          {...this.props.attrs}
        />
        <i className={"input_icon "+this.props.icon}></i>
      </div>
    )
  }
}

class InputTheme3 extends Component{
  constructor(props){
    super(props);
    this.state = {
      theme_class_name : "input_theme3_container",
      show_hide_password : "hide"
    }
    // this.inputChange = this.inputChange.bind(this);
    this.inputFocusIn = this.inputFocusIn.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
  } 


  inputFocusIn(e){
    this.setState({theme_class_name : "input_theme3_container input_theme3_SELECTED"});
  }

   inputFocusOut(e){
    if(e.target.value || e.target.value != ""){
      return;
    }
    this.setState({theme_class_name : "input_theme3_container"});
  }
  render(){
    $('input[name="password"]').on("cut copy paste",function(e) {
        e.preventDefault();
    });
    $('input[name="cnf_password"]').on("cut copy paste",function(e) {
        e.preventDefault();
    });
 
    return(
      <div className={this.props.attrs.value != "undefined" && this.props.attrs.value != ""?"input_theme3_container input_theme3_SELECTED "+this.props.className : this.state.theme_class_name+" "+this.props.className}>
        <input
          autoComplete="off"
          {...this.props.attrs}
          onFocus={this.inputFocusIn}
          onBlur={this.inputFocusOut}
          type={this.props.attrs.type == "password" && this.state.show_hide_password == "hide"?"password":this.props.attrs.type=="email"?"email":this.props.attrs.type=="number"?"number":this.props.attrs.type == "tel"?"tel":"text"}
          style={{paddingRight:this.props.attrs.type == "password"?"25px":""}}
          />
        <div className="input_label">{this.props.label}</div>
        {this.props.attrs.type == "password"?
        <>
        <FiEye className="view_password_icon" style={{display:this.state.show_hide_password == "hide"?"block":"none"}} onClick={() => this.setState({
          show_hide_password : "view"
        })}/>
        <FiEyeOff className="hide_password_icon" style={{display:this.state.show_hide_password == "view"?"block":"none"}} onClick={() => this.setState({
          show_hide_password : "hide"
        })} />
        </>:""}
      </div>
    )
  }
}

class InputTheme4 extends Component{
  constructor(props){
    super(props);
    this.state = {
      theme_class_name : "input_theme4_container",
      show_hide_password : "hide"
    }
    this.inputFocusIn = this.inputFocusIn.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
  } 

  inputFocusIn(e){
    this.setState({theme_class_name : "input_theme4_container input_theme4_SELECTED"});
  }

  inputFocusOut(e){
    if(e.target.value){
      return;
    }
    this.setState({theme_class_name : "input_theme4_container"});
  }
  render(){
    $('input[name="password"]').on("cut copy paste",function(e) {
        e.preventDefault();
    });
    $('input[name="cnf_password"]').on("cut copy paste",function(e) {
        e.preventDefault();
    });
    return(
      <div className={this.props.attrs.value != "undefined" && this.props.attrs.value != ""?"input_theme4_container input_theme4_SELECTED "+this.props.className : this.state.theme_class_name+" "+this.props.className}
        ref={this.props.input_ref}
      >  
        <input
         autoComplete='off'
         {...this.props.attrs}
          onFocus={this.inputFocusIn}
          onBlur={this.inputFocusOut}
          type={this.props.attrs.type == "password" && this.state.show_hide_password == "hide"?"password":"text"}
          style={{
            paddingRight:this.props.attrs.type == "password"?"25px":"",
            paddingLeft :this.props.attrs.type == "tel"?"40px":""
          }}
        />
        {this.props.attrs.type == "tel"?
          <div className="phone_code">+91</div>  :""
        }
        <div className="input_label">
            {this.props.label}
            {/* <i class="fas fa-asterisk asterisk"></i> */}
            </div>
        {this.props.attrs.type == "password"?
        <>
        <FiEye className="view_password_icon" style={{display:this.state.show_hide_password == "hide"?"block":"none"}} onClick={() => this.setState({
          show_hide_password : "view"
        })}/>
        <FiEyeOff className="hide_password_icon" style={{display:this.state.show_hide_password == "view"?"block":"none"}} onClick={() => this.setState({
          show_hide_password : "hide"
        })} />
        </>:""}
        
      </div>
    )
  }
}

class InputTheme5 extends Component{
  constructor(props){
    super(props);
    this.state = {
      theme_class_name : "input_theme5_container"
    }
    // this.inputChange = this.inputChange.bind(this);
    this.inputFocusIn = this.inputFocusIn.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
  } 

  // inputChange(e){
  //   console.log(e.target.value);
  // }

  inputFocusIn(e){
    this.setState({theme_class_name : "input_theme5_container input_theme5_SELECTED"});
  }

   inputFocusOut(e){
    if(e.target.value || e.target.value != ""){
      return;
    }
    this.setState({theme_class_name : "input_theme5_container"});
  }
  render(){
    return(
      <div className={this.props.attrs.value?"input_theme5_container input_theme5_SELECTED "+this.props.className : this.state.theme_class_name+" "+this.props.className}>
        <div className="input_label">{this.props.label}</div>
        <input
          autoComplete="off"
          {...this.props.attrs}
          onFocus={this.inputFocusIn}
          onBlur={this.inputFocusOut}
          />
      </div>
    )
  }
}

class InputTheme6 extends Component{
  constructor(props){
    super(props);
    this.state = {
      theme_class_name : "input_theme6_container"
    }
    // this.inputChange = this.inputChange.bind(this);
    this.inputFocusIn = this.inputFocusIn.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
  } 

  // inputChange(e){
  //   console.log(e.target.value);
  // }

  inputFocusIn(e){
    this.setState({theme_class_name : "input_theme6_container input_theme6_SELECTED"});
  }

   inputFocusOut(e){
    if(e.target.value || e.target.value != ""){
      return;
    }
    this.setState({theme_class_name : "input_theme6_container"});
  }
  render(){
    return(
      <div className={this.props.attrs.value != "undefined" && this.props.attrs.value != ""?"input_theme6_container input_theme6_SELECTED "+this.props.className : this.state.theme_class_name+" "+this.props.className}>
        <div className="input_label">{this.props.label}</div>
        <input
          autoComplete="off"
          {...this.props.attrs}
          onFocus={this.inputFocusIn}
          onBlur={this.inputFocusOut}
          />
      </div>
    )
  }
}

class TextAreaTheme1 extends Component{
  constructor(props){
    super(props);
    this.state = {
      theme_class_name : "textarea_theme1_container"
    }
    this.inputFocusIn = this.inputFocusIn.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
  } 

  inputFocusIn(e){
    this.setState({theme_class_name : "textarea_theme1_container textarea_theme1_SELECTED"});
  }

  inputFocusOut(e){
    if(e.target.value){
      return;
    }
    this.setState({theme_class_name : "textarea_theme1_container"});
  }
  render(){
    return(
      <div
      //  className={"textarea_theme1_container "+this.props.className??""}
        className={this.props.attrs.value != "undefined" && this.props.attrs.value != ""?"textarea_theme1_container textarea_theme1_SELECTED "+this.props.className : this.state.theme_class_name+" "+this.props.className}  
      ref={this.props.input_ref}
      >
        <div className="textarea_label">{this.props.label}</div>
        <textarea
          autoComplete="off"
          {...this.props.attrs}
          onFocus={this.inputFocusIn}
          onBlur={this.inputFocusOut}
        />
      </div>
    )
  }
}

class TextAreaTheme3 extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className={this.props.attrs.value?"textarea_theme3_container textarea_theme3_NOTNULL":"textarea_theme3_container"}>
        <div className="textarea_label" >{this.props.label}</div>
        <textarea 
          {...this.props.attrs}
        />
      </div>
    )
  }
}


class TextAreaTheme4 extends Component{
  constructor(props){
    super(props);
    this.state = {
      theme_class_name : "textarea_theme4_container"
    }
    // this.inputChange = this.inputChange.bind(this);
    this.inputFocusIn = this.inputFocusIn.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
  } 

  // inputChange(e){
  //   console.log(e.target.value);
  // }

  inputFocusIn(e){
    this.setState({theme_class_name : "textarea_theme4_container textarea_theme4_SELECTEDs"});
  }

   inputFocusOut(e){
     console.log(e.target.value);
    if(e.target.value){
      return;
    }
    this.setState({theme_class_name : "textarea_theme4_container"});
  }
  render(){
    return(
      <div className={this.props.attrs.value?"textarea_theme4_container textarea_theme4_SELECTEDs" : this.state.theme_class_name}>
        <div className="textarea_label">{this.props.label}</div>
        <textarea
          autoComplete="off"
          {...this.props.attrs}
          onFocus={this.inputFocusIn}
          onBlur={this.inputFocusOut}
        />
      </div>
    )
  }
}

// const InputTheme3 = (props) => {
//   const [theme_class_name, setThemeClass] = useState(); 
//   // console.log(props.value);
//   // const inputOnChange = (e) => { 
//   //   setValue(e.target.value);
//   //   props.parentCallback(e.target.value);
//   //   // console.log(value);
//   // }
//   useEffect(() => {
//     if(props.value){
//       console.log("kerii");
//       setThemeClass("input_theme3_container input_theme3_SELECTED");
//     }
//   }); 

//   const inputChange = (e) => {
//     console.log(e.target.value);
//   }

//   const inputFocusIn = (e) =>{
//     setThemeClass("input_theme3_container input_theme3_SELECTED");
//   }

//    const inputFocusOut = (e) =>{
//     if(e.target.value){
//       return;
//     }
//     setThemeClass("input_theme3_container");
//   }

//   return(
//     <div className={theme_class_name}>
//       <div className="input_label">{props.label}</div>
//       <input name=""  
//         value={props.value} 
//         onChange={e => { props.onChange(e);inputChange(e);}}  
//         autoComplete="off"
//         onFocus={inputFocusIn}
//         onBlur={inputFocusOut}
//         />
//     </div>
//     )
// }

export {
  InputTheme1,
  InputTheme2,
  InputTheme3,
  InputTheme4,
  InputTheme5,
  InputTheme6,
  TextAreaTheme1,
  TextAreaTheme3,
  TextAreaTheme4
};