import React, { useState, useRef } from 'react'
import { FaUpload } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
// import * as firebase from 'firebase/app'
import ImageCropper from './ImageCropper'
import './ImageEditor.scss';
import upload_icon from "../../images/upload_icon1.jpg";

const ImageEditor = ({getBlob, image_src, current_image, deleteImageSrc, image_width, image_height}) => {
    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('')
    const [base64, setBase64] = useState(null)
    const image_input_ref = useRef(null);

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const deleteImage = () => {
      console.log("kerii")
      setInputImg("");
      getBlob("");
    }

   const onChooseImg = () =>{ 
        image_input_ref.current.click();
    };

//     const handleSubmitImage = (e) => {
//     // upload blob to firebase 'images' folder with filename 'image'
//     var reader = new FileReader();
//     reader.readAsDataURL(blob); 
//     reader.onloadend = function() {
//     var base64data = reader.result;                
//     console.log(base64data);
//     setBase64(base64data);
// }
    // console.log(blob);
    //     e.preventDefault()
    //     // firebase
    //     //     .storage()
    //     //     .ref('images')
    //     //     .child('image')
    //     //     .put(blob, { contentType: blob.type })
    //     //     .then(() => {
    //     //         // redirect user 
    //     //     })
    // }

    return (
        <div className="image_editor_component">
          { inputImg || image_src?"":(
            <div className="image_upload_input">
                <input
                    type="file"
                    accept="image/*"
                    onChange={onInputChange}
                    style={{ display: "none" }}
                    ref={image_input_ref}
                />

                <div className="upload_image_button"
                  onClick={onChooseImg}>
                    <img className="upload_icon" src={upload_icon}/>
                    <button type="button" className="choose_image_button">Choose image</button>
                    <div className="upload_label">(Pinch-to-zoom & adjust)</div>
                </div>
            </div>
            )}
                {
                  inputImg && (
                    <div className="crop-container">
                      <ImageCropper
                        getBlob={getBlob}
                        inputImg={inputImg}
                        deleteImage ={deleteImage}
                        aspect_ratio = {image_width/image_height}
                      />
                    </div>
                    )
                }
                {image_src?(
                   <div className="image_preview" alt="" 
                    style={{backgroundImage:"url('"+current_image+"')",width:image_width,height:image_height}}>
                      <div className="image_ghost"></div>
                      <div className="delete_image_button" onClick={deleteImageSrc}>
                        <RiDeleteBinLine></RiDeleteBinLine>
                      </div>
                    </div>
                ):""}
        </div>
        
    )
}

export default ImageEditor;