import React from "react";
import "./NewDeathNotice.scss";
import { InputTheme4, InputTheme1, InputTheme3, TextAreaTheme1} from "../../../res/styler/InputThemes/InputThemes";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import {AiOutlinePlus,AiOutlineDelete} from "react-icons/ai";
import $ from "jquery";
import MyContext from "../../MyContext";
import { Link } from "react-router-dom";
import {GrAdd} from "react-icons/gr";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import ImageEditor from "../../../res/styler/ImageEditor/ImageEditor";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";

class NewDeathNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      death_notice_id : "",
      first_name: "",
      middle_name : "",
      surname : "",
      nick_name : "",
      nee : "",
      family_or_house_name: "",
      locality: "",
      gender: "Male",
      country : "",
      country_label : "",
      state: "",
      state_label: "",
      city: "",
      city_label: "",
      date_of_death: "",
      place_of_death: "",
      below_one_year : "",
      age_in_months : 0,
      age_in_days : 0,
      age: "",
      place_of_birth: "",
      date_of_birth: "",
      image: "",
      image_src: "",
      file : "",
      notice_title: "",
      notice_content: "",
      editorState: EditorState.createEmpty(),
      countries : [],
      states: [],
      cities: [],
      status : "",
      biography : "",
      visitation_details : [],
      funeral_details : [],
      committal_details : [],
      survived_peoples : [],
      predeceased_peoples : [],
      contact_no : "",
      contact_no_code : "IN",
      contact_name: "",
      profile_pic_delete_flag : 0,
      other_address : 0,
      secondary_address : "",
      teritiary_address : "",
      charity_search_input : "",
      charities : [],
      charity : {},
      age_in_months_days : ""
    }
    this.getBlob = this.getBlob.bind(this);
    this.delete_image = this.delete_image.bind(this);
    // this.revert_image = this.revert_image.bind(this);
    this.gender_change = this.gender_change.bind(this);
    this.on_below_one_year_change = this.on_below_one_year_change.bind(this);
    this.age_in_months_change = this.age_in_months_change.bind(this);
    this.age_in_days_change = this.age_in_days_change.bind(this);
    this.find_age_in_months_days = this.find_age_in_months_days.bind(this);
    this.on_other_address_change = this.on_other_address_change.bind(this);
    this.country_change = this.country_change.bind(this);
    this.state_change = this.state_change.bind(this);
    // this.city_change = this.city_change.bind(this);
    this.form_change = this.form_change.bind(this);
    this.contact_no_change = this.contact_no_change.bind(this);
    this.contact_no_code_change = this.contact_no_code_change.bind(this);
    this.calculate_age = this.calculate_age.bind(this);
    this.calculate_birth_year = this.calculate_birth_year.bind(this);
    this.notice_change = this.notice_change.bind(this);
    this.visitation_change = this.visitation_change.bind(this);
    this.add_visitation_row = this.add_visitation_row.bind(this);
    this.delete_visitation_row = this.delete_visitation_row.bind(this);
    this.funeral_change = this.funeral_change.bind(this);
    this.add_funeral_row = this.add_funeral_row.bind(this);
    this.delete_funeral_row = this.delete_funeral_row.bind(this);
    this.committal_change = this.committal_change.bind(this);
    this.add_committal_row = this.add_committal_row.bind(this);
    this.delete_committal_row = this.delete_committal_row.bind(this);
    this.survived_change = this.survived_change.bind(this);
    this.add_survived_row = this.add_survived_row.bind(this);
    this.delete_survived_row = this.delete_survived_row.bind(this);
    this.survived_image_change = this.survived_image_change.bind(this);
    this.predeceased_change = this.predeceased_change.bind(this);
    this.add_predeceased_row = this.add_predeceased_row.bind(this);
    this.delete_predeceased_row = this.delete_predeceased_row.bind(this);
    this.predeceased_image_change = this.predeceased_image_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.charities_search = this.charities_search.bind(this);
    this.create_notice = this.create_notice.bind(this);
    this.numberInWords = this.numberInWords.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.new_death_notice_page_ref = React.createRef();
    this.first_name_ref = React.createRef();
    this.surname_ref = React.createRef();
    this.locality_ref = React.createRef();
    this.country_ref = React.createRef();
    this.state_ref = React.createRef();
    this.city_ref = React.createRef();
    this.age_ref = React.createRef();
    this.age_in_months_ref = React.createRef();
    this.date_of_death_ref = React.createRef();
    this.date_of_birth_ref = React.createRef();
    this.notice_ref = React.createRef();
    this.month_names = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
  }
  componentWillUnmount() {
    //   window.removeEventListener("beforeunload", function (event) {
    //     alert("hellooww")
    //     event.returnValue = "Hellooww"
    // })
  }

  componentDidMount() {
    // window.addEventListener("beforeunload", function (event) {
    //   alert("hellooww")
    //   event.returnValue = "Hellooww"
    // })
    this.context.loading(1);
    var obj_this = this;
    console.log(obj_this.context.session.user_type);
    const death_notice_id = this.props.match.params.death_notice_id;
    if(death_notice_id){
      this.setState({
        death_notice_id : death_notice_id
      });
      $.ajax({
        url: process.env.REACT_APP_API + "/api/editDeathNotice",
        data: this.context.bind_session({death_notice_id: death_notice_id}),
        type: "POST"
      }).done(function (return_data) {
        // console.log(return_data);
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          return_data.death_notice.date_of_death = new Date(return_data.death_notice.date_of_death);
          return_data.death_notice.date_of_birth = new Date(return_data.death_notice.date_of_birth);
          const other_details = return_data.death_notice.other_details;
          const family_details = return_data.death_notice.family_details;
          return_data.death_notice.visitation_details = other_details.filter((item) => item.type == "visitation");
          return_data.death_notice.funeral_details = other_details.filter((item) => item.type == "funeral");
          return_data.death_notice.committal_details = other_details.filter((item) => item.type == "committal");
          return_data.death_notice.survived_peoples= family_details.filter((item) => item.type == "survived");
          return_data.death_notice.predeceased_peoples= family_details.filter((item) => item.type == "predeceased");
          if(return_data.death_notice.secondary_address || return_data.death_notice.teritiary_address){
            return_data.death_notice.other_address = 1;
          }
          const contentBlock = htmlToDraft(return_data.death_notice.notice_content);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return_data.death_notice.editorState = editorState;
          }
          obj_this.setState(return_data.death_notice,() => {
            obj_this.new_death_notice_page_ref.current.style.visibility = "visible";
            obj_this.new_death_notice_page_ref.current.style.opacity = 1;
          });
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }else{
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getCountries",
        data: this.context.bind_session({}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.setState({
            countries: return_data.countries
          },() => {
            obj_this.new_death_notice_page_ref.current.style.visibility = "visible";
            obj_this.new_death_notice_page_ref.current.style.opacity = 1;
          });
          obj_this.context.loading(0);
        }else if(return_data.status == "session failed"){
          obj_this.context.loading(0);
          obj_this.context.delete_session();
          alert("Your session has timed out. Please login again.");
          obj_this.props.history.replace('/Login');
        } else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }

    $(".single_death_notice_template").on("click", function(){
      $(".single_death_notice_template").removeClass("selected_death_notice_template")
      $(this).addClass("selected_death_notice_template");
      const contentBlock = htmlToDraft($(this).find(".template_content").html().replace(/\s*<br\s*\/?>\s*/g,"\n"));
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        obj_this.setState({
          notice_content : $(this).find(".template_content").html().replace(/\s*<br\s*\/?>\s*/g,"\n"),
          editorState : editorState
        });
      }
    });

    // $("form input[type=text]").on("keypress", function (e) {
    //   if (e.which === 13) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     $(this).nextAll("input:first").trigger("focus");
    //   }
    // });
  }

  getBlob(blob){
    // pass blob up from the ImageCropper component
    this.setState({
      file : blob
    });
    // console.log(blob)
  }

  delete_image(){
    this.setState({
      profile_pic_delete_flag : 1,
      image_src : null,
      file : ""
    });
  }

  gender_change(e){
    this.setState({
      gender : e.target.value,
      nee : ""
    });
  }
  
  numberInWords (num) {
    var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
      if ((num = num.toString()).length > 9) return 'overflow';
      var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
      return str;
  }
  
  form_change(e) {
    if(e.target.name == "age"){
      const re = /^[0-9\b]+$/;
      // if value is not blank, then test the regex
      if (e.target.value === '' || re.test(e.target.value)) {
        this.calculate_birth_year(this.state.date_of_death,e.target.value);
        this.setState({
          [e.target.name]: e.target.value
        });
      }
    }else{
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  }

  contact_no_change(value){
    this.setState({
      contact_no : value??""
    });
  }

  contact_no_code_change(country){
    const country_code = country??this.state.contact_no_code;
    this.setState({
      contact_no_code : country_code
    });
  }

  calculate_age(date_of_birth,date_of_death){
    var months,age = "";
    months = (new Date(date_of_death).getFullYear() - new Date(date_of_birth).getFullYear()) * 12;
    months -= new Date(date_of_birth).getMonth();
    months += new Date(date_of_death).getMonth();
    months = months <= 0 ? 0 : months;
    if(isNaN(months) || months == 0){
      this.setState({
        age : ""
      });
      return;
    }
    if(months >= 12){
      age = Math.floor(months/12);
    }else{
      age = months+ " months";
    }
    this.setState({
      age : age
    });
  }

  calculate_birth_year(date_of_death,age){
    // console.log(date_of_death,age)
    var death_year =  new Date(date_of_death).getFullYear();
    var date_of_death = new Date(date_of_death);
    var date_of_birth = date_of_death.setFullYear(death_year - age);
    // console.log(new Date(date_of_birth));
    this.setState({
      date_of_birth : new Date(date_of_birth)
    });
  }
  
  create_notice(){
    if(this.state.death_notice_id){
      const notice = this.state.notice_content;
      return notice;
    }
    var date_of_death = new Date(this.state.date_of_death);
    date_of_death = !isNaN(Date.parse(date_of_death))?this.month_names[new Date(date_of_death).getMonth()]+" "+new Date(date_of_death).getDate()+", "+new Date(date_of_death).getFullYear():null;
    const notice = "The death has occurred of "+this.state.first_name+" ("+this.state.nick_name+") "+this.state.middle_name+" "+this.state.surname+" ("+this.state.age+") (Née: "+ this.state.nee+"), "+this.state.family_or_house_name+", "+this.state.locality+", "+this.state.city_label+", "+ this.state.state_label+" on "+date_of_death+ " at "+this.state.place_of_death;
    return notice;
  }

  on_other_address_change(e){
    var other_address = this.state.other_address;
    this.setState({
      other_address : other_address?0:1
    });
  }

  on_below_one_year_change(e){
    var below_one_year = this.state.below_one_year;
    this.setState({
      below_one_year : below_one_year?0:1,
      age_in_months : 0,
      age_in_days: 0,
      age : "",
      editorState : EditorState.createEmpty()
    });
  }

  age_in_months_change(e) {
    const age_in_months_days = this.find_age_in_months_days(e.target.value,this.state.age_in_days);
    this.setState({
      age_in_months: e.target.value,
      age_in_months_days : age_in_months_days
    });
  };

  age_in_days_change(e) {
    const age_in_months_days = this.find_age_in_months_days(this.state.age_in_months,e.target.value);
    this.setState({
      age_in_days: e.target.value,
      age_in_months_days : age_in_months_days
    });
  };

  find_age_in_months_days(age_in_months, age_in_days){
    var age_in_months_days = "";
    if(age_in_months){
      age_in_months = age_in_months>1?this.numberInWords(age_in_months)+"months":this.numberInWords(age_in_months)+"month";
      age_in_months_days = age_in_months+" ";
    }
    if(age_in_days){
      age_in_days = age_in_days>1?this.numberInWords(age_in_days)+"days":this.numberInWords(age_in_days)+"day";
      age_in_months_days+=age_in_days;
    }
    return age_in_months_days;
  }

  notice_change(e){
    console.log(e.target.value.length)
    if(e.target.value.length > 1400){
      alert("Maximum limit exceeded");
      return;
    }
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  onEditorStateChange(value){
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
        editorState :value,
        notice_content : draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  country_change(e) {
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: this.context.bind_session({ country: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        const country = obj_this.state.countries.find((item) => item.id === e.target.value ); 
        obj_this.setState({
          states : return_data.states,
          cities : [],
          state : "",
          country: e.target.value,
          country_label : country.name
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  };

  state_change(e) {
    const state = this.state.states.find((item) => item.id === e.target.value ); 
    this.setState({
      state: e.target.value,
      state_label : state.name
    });
  };

  get_youtube_video_id(url){
    var video_id = "", url_split = [];
    // console.log(url)
    if(url.includes("watch?")){
      url_split = url.split("v=");
      // console.log(url_split);
      if(url_split[1]){
        url_split = url_split[1].split("&");
        video_id = url_split[0];
      }
    }else if(url.includes("live")){
      url_split = url.split("live/");
      url_split = url_split[1].split("?");
      video_id = url_split[0];
    }else if(url.includes(".be/")){
      url_split = url.split(".be/");
      video_id = url_split[1];
    }
    // console.log(video_id);
    return video_id;
  }

  visitation_change(e,idx){
    // console.log(e.target.value);
    var visitation_details = this.state.visitation_details;
    var visitation_detail = visitation_details[idx];
    if(e.target.name == "video_url"){
      var video_id = this.get_youtube_video_id(e.target.value)
      visitation_detail['video_id'] = video_id;
    }
    visitation_detail[e.target.name] = e.target.value;
    this.setState({
      visitation_details : visitation_details
    });
  }

  add_visitation_row(idx){
    var visitation_details = this.state.visitation_details;
    var item = {
      program_title : "",
      date : "",
      start_time : "",
      end_time : "",
      address : "",
      video_id : "",
      video_url : "",
      google_map_url : ""
    }
    visitation_details.splice(idx+1, 0, item);
    this.setState({
      visitation_details : visitation_details
    });
  }

  delete_visitation_row(idx){
    var visitation_details = this.state.visitation_details;
    const new_visitation_details = visitation_details.filter((item,index) => index != idx);
    this.setState({
      visitation_details : new_visitation_details
    });
  }

  funeral_change(e,idx){
    var funeral_details = this.state.funeral_details;
    var funeral_detail = funeral_details[idx];
    if(e.target.name == "video_url"){
      var video_id = this.get_youtube_video_id(e.target.value)
      funeral_detail['video_id'] = video_id;
    }
    funeral_detail[e.target.name] = e.target.value;
    this.setState({
      funeral_details : funeral_details
    });
  }

  add_funeral_row(idx){
    var funeral_details = this.state.funeral_details;
    var item = {
      program_title : "",
      date : "",
      start_time : "",
      end_time : "",
      address : "",
      video_id : "",
      video_url : "",
      google_map_url : ""
    }
    funeral_details.splice(idx+1, 0, item);
    this.setState({
      funeral_details : funeral_details
    });
  }

  delete_funeral_row(idx){
    var funeral_details = this.state.funeral_details;
    const new_funeral_details = funeral_details.filter((item,index) => index != idx);
    this.setState({
      funeral_details : new_funeral_details
    });
  }

  committal_change(e,idx){
    var committal_details = this.state.committal_details;
    var committal_detail = committal_details[idx];
    committal_detail[e.target.name] = e.target.value;
    if(e.target.name == "video_url"){
      var video_id = this.get_youtube_video_id(e.target.value)
      committal_detail['video_id'] = video_id;
    }
    this.setState({
      committal_details : committal_details
    });
  }

  add_committal_row(idx){
    var committal_details = this.state.committal_details;
    var item = {
      program_title : "",
      date : "",
      start_time : "",
      end_time : "",
      address : "",
      video_id : "",
      video_url : "",
      google_map_url : ""
    }
    committal_details.splice(idx+1, 0, item);
    this.setState({
      committal_details : committal_details
    });
  }

  delete_committal_row(idx){
    console.log(idx)
    var committal_details = this.state.committal_details;
    const new_committal_details = committal_details.filter((item,index) => index != idx);
    this.setState({
      committal_details : new_committal_details
    });
  }

  survived_change(e,idx){
    var survived_peoples = this.state.survived_peoples;
    var survived_detail = survived_peoples[idx];
    // survived_detail[e.target.name] = e.target.value.replace(/[^a-z ]/gi, '');
    survived_detail[e.target.name] = e.target.value;
    this.setState({
      survived_peoples : survived_peoples
    });
  }

  survived_image_change(e,idx){
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
      var survived_peoples = this.state.survived_peoples;
      var survived_people = survived_peoples[idx];
      survived_people.image= file;
      this.setState({
        survived_peoples : survived_peoples
      });
    // }
  }

  add_survived_row(idx){
    var survived_peoples = this.state.survived_peoples;
    var item = {
     name : "",
     image_src : "",
     image : "",
     relation : ""
    }
    survived_peoples.splice(idx+1, 0, item);
    this.setState({
      survived_peoples : survived_peoples
    });
  }

  delete_survived_row(idx){
    console.log(idx)
    var survived_peoples = this.state.survived_peoples;
    const new_survived_peoples = survived_peoples.filter((item,index) => index != idx);
    this.setState({
      survived_peoples : new_survived_peoples
    });
  }

  predeceased_change(e,idx){
    var predeceased_peoples = this.state.predeceased_peoples;
    var predeceased_detail = predeceased_peoples[idx];
   // predeceased_detail[e.target.name] = e.target.value.replace(/[^a-z ]/gi, '');
   predeceased_detail[e.target.name] = e.target.value;
    this.setState({
      predeceased_peoples : predeceased_peoples
    });
  }

  predeceased_image_change(e,idx){
    const file = e.target.files[0];
    // if (file.size / 1024 > 2048)
    //   alert("File size cannot exceed more than 2MB");
    // else{
      var predeceased_peoples = this.state.predeceased_peoples;
      var predeceased_people = predeceased_peoples[idx];
      predeceased_people.image= file;
      this.setState({
        predeceased_peoples : predeceased_peoples
      });
    // }
  }

  add_predeceased_row(idx){
    var predeceased_peoples = this.state.predeceased_peoples;
    var item = {
     name : "",
     image_src : "",
     image : "",
     relation : ""
    }
    predeceased_peoples.splice(idx+1, 0, item);
    this.setState({
      predeceased_peoples : predeceased_peoples
    });
  }

  delete_predeceased_row(idx){
    console.log(idx)
    var predeceased_peoples = this.state.predeceased_peoples;
    const new_predeceased_peoples = predeceased_peoples.filter((item,index) => index != idx);
    this.setState({
      predeceased_peoples : new_predeceased_peoples
    });
  }
 
  form_submit(e) {
    e.preventDefault();
    // console.log(this.state);
    // return;

    if(!this.state.first_name){
      $.alert("First Name Required");
      window.scrollTo({
        top : this.first_name_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.first_name_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    // if(!this.state.surname){
    //   $.alert("Surname Required");
    //   window.scrollTo({
    //     top : this.surname_ref.current.offsetTop,
    //     behavior : "smooth"
    //   });
    //   $(this.surname_ref.current).addClass('input_theme4_SELECTED')
    //   return;
    // }

    if(!this.state.locality){
      $.alert("Locality Required");
      window.scrollTo({
        top : this.locality_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.locality_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.country){
      $.alert("Country Required");
      window.scrollTo({
        top : this.country_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }

    if(this.state.states.length && !this.state.state){
      $.alert("State/Province/County/Region Required");
      window.scrollTo({
        top : this.state_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }

    if(!this.state.city){
      $.alert("City/Town Required");
      window.scrollTo({
        top : this.city_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.city_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(!this.state.age && !this.state.below_one_year){
      $.alert("Age Required");
      window.scrollTo({
        top : this.age_ref.current.offsetTop,
        behavior : "smooth"
      });
      $(this.age_ref.current).addClass('input_theme4_SELECTED')
      return;
    }

    if(this.state.below_one_year){
      if(this.state.age_in_days || this.state.age_in_months){

      }else{
        $.alert("Please provide the age, either in months or in days, or both.");
        window.scrollTo({
          top : this.age_in_months_ref.current.offsetTop,
          behavior : "smooth"
        });
        return;
      }
      
    }
    
    if(!this.state.date_of_death){
      $.alert("Date of Death Required");
      window.scrollTo({
        top : this.date_of_death_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    if(!this.state.editorState.getCurrentContent().hasText()){
      $.alert("Fill Death Notice");
      console.log(this.notice_ref.current.offsetTop)
      window.scrollTo({
        top : this.notice_ref.current.offsetTop,
        behavior : "smooth"
      });
      return;
    }
    const death_notice_details = this.state;
    delete death_notice_details.state_label;
    delete death_notice_details.editorState;
    delete death_notice_details.city_label;
    delete death_notice_details.country_label;
    delete death_notice_details.other_address;
    delete death_notice_details.charity_search_input;
    delete death_notice_details.age_in_months_days;
    death_notice_details.date_of_death = death_notice_details.date_of_death ? death_notice_details.date_of_death.toLocaleDateString('en-CA',{ day: '2-digit', month: '2-digit', year: 'numeric'}) : "";
    death_notice_details.date_of_birth = death_notice_details.date_of_birth ? death_notice_details.date_of_birth.toLocaleDateString('en-CA',{ day: '2-digit', month: '2-digit', year: 'numeric'}) : "";
    // salesorder.o_date = salesorder.order_date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    console.log(death_notice_details);
    // return;
    const form_data = new FormData();
    death_notice_details.visitation_details.map((item) => {
      item.google_map_url = item.google_map_url.includes("maps")?item.google_map_url:"";
    });
    death_notice_details.funeral_details.map((item) => {
      item.google_map_url = item.google_map_url.includes("maps")?item.google_map_url:"";
    });
    death_notice_details.committal_details.map((item) => {
      item.google_map_url = item.google_map_url.includes("maps")?item.google_map_url:"";
    });
    const survived_peoples = this.state.survived_peoples;
    for(var i=0;i<survived_peoples.length;i++){
      if(death_notice_details.death_notice_id){
        form_data.append('survived_images_src['+i+']', survived_peoples[i].image_src); // change this to value
      }
      form_data.append('survived_images['+i+']', survived_peoples[i].image??""); // change this to value
    }
    const predeceased_peoples = this.state.predeceased_peoples;
    for(var i=0;i<predeceased_peoples.length;i++){
      if(death_notice_details.death_notice_id){
        form_data.append('predeceased_images_src['+i+']', predeceased_peoples[i].image_src); // change this to value
      }
      form_data.append('predeceased_images['+i+']', predeceased_peoples[i].image??""); // change this to value
    }
    form_data.append("death_notice_details", JSON.stringify(death_notice_details));
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image", this.state.file);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveDeathNotice",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data);
      // return;
      if (return_data.status == "insert") {
        obj_this.context.loading(0);
        if(obj_this.context.session.user_type == "admin"){
          alert("Death Notice created");
        }else{
          alert("Admin Approval Awaiting. Please check back later.");
        }
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard/DeathNotices/MyEntries",
          state : ""
        });
        // return;
      }else if(return_data.status == "update"){
        obj_this.context.loading(0);
        console.log(obj_this.props.location.previousState)
        alert("Death notice updated");
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard/DeathNotices/MyEntries",
          state : obj_this.props.location.previousState??""
        });
      }else{
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    })
  }

  charities_search(e){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/searchCharities",
      data: this.context.bind_session({ phrase: e.target.value}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          charities : return_data.charities,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    this.setState({
      charity_search_input : e.target.value
    });
  }

  render() {
    return (
      <div className="new_deathnotice_page_container page_container" ref={this.new_death_notice_page_ref}>
        <form className="new_deathnotice_form" encType="multipart/form-data" onSubmit={this.form_submit}>
          <div className="page_header">
            <div className="page_title">{this.state.death_notice_id?"Edit":"Create"} Death Notice</div>
            {/* <div className="breadcrumbs_container">
              <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
              <Link to="/Dashboard/DeathNotices">Death Notices</Link>/
              <span>{this.state.death_notice_id?"Edit":"Create"}</span>
            </div> */}
          </div>
          <div className="deathnotice_details">
            <div className="image_editor_container">
              <ImageEditor
                getBlob={this.getBlob}
                image_src = {this.state.image_src}
                current_image = {process.env.REACT_APP_CDN_URL+"/"+this.state.entered_by+"/death_notice_images/"+this.state.image_src}
                deleteImageSrc = {this.delete_image}
                image_width = {210 }
                image_height = {270 }
              />
            </div>
            <div className="basic_details">
              <InputTheme4
                label="First Name *"
                attrs={{
                  name: "first_name",
                  type: "text",
                  value: this.state.first_name,
                  onChange: this.form_change,
                }}
                input_ref={this.first_name_ref}
              />
              <InputTheme4
                label="Middle Name"
                attrs={{
                  name: "middle_name",
                  type: "text",
                  value: this.state.middle_name,
                  onChange: this.form_change,
                }}
              />
              <InputTheme4
                label="Surname"
                attrs={{
                  name: "surname",
                  type: "text",
                  value: this.state.surname,
                  onChange: this.form_change,
                }}
                input_ref={this.surname_ref}
              />
              <InputTheme4
                label="Nick Name"
                attrs={{
                  name: "nick_name",
                  type: "text",
                  value: this.state.nick_name,
                  onChange: this.form_change,
                }}
              />
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Gender *</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={this.gender_change}
                  value={this.state.gender}
                >
                  <FormControlLabel value="Male" control={<Radio />} label="Male" />
                  <FormControlLabel value="Female" control={<Radio />} label="Female" />
                  <FormControlLabel value="Non-binary" control={<Radio />} label="Non-binary" />
                </RadioGroup>
              </FormControl>
              <div style={{display:this.state.gender == "Female"?"block":"none"}}>
                <InputTheme4
                  label="Née (A woman’s family name at birth)"
                  attrs={{
                    name: "nee",
                    type: "text",
                    value: this.state.nee,
                    onChange: this.form_change,
                  }}
                />
              </div>
              <InputTheme4
                label="Family/House name"
                attrs={{
                  name: "family_or_house_name",
                  type: "text",
                  value: this.state.family_or_house_name,
                  onChange: this.form_change,
                }}
              />
              <InputTheme4
                label="Locality *"
                attrs={{
                  name: "locality",
                  value: this.state.locality,
                  onChange: this.form_change,
                  type: "text",
                }}
                input_ref = {this.locality_ref}
              />
              <FormControl variant="standard"  ref={this.country_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">Country *</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.country}
                  onChange={this.country_change}
                  label="Country"
                >
                  {this.state.countries && this.state.countries.map((item, idx) => (
                    <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" ref={this.state_ref} sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">State/Province/County/Region {this.state.states.length?"*":""}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.state}
                  onChange={this.state_change}
                  label="State"
                >
                  {this.state.states && this.state.states.map((item, idx) => (
                    <MenuItem value={item.id} state={item.name} key={idx}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputTheme4
                label="City/Town *"
                attrs={{
                  name: "city",
                  value: this.state.city,
                  onChange: this.form_change,
                  type: "text",
                }}
                input_ref={this.city_ref}
              />
              
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker ref={this.date_of_birth_ref}
                  label="Date of birth *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                    }
                  }}
                  value={this.state.date_of_birth}
                  onChange={(newValue) => {
                    this.calculate_age(newValue, this.state.date_of_death);
                    this.setState({ 
                      date_of_birth: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  allowSameDateSelection={true}
                />
              </LocalizationProvider> */}
              <div className="checkbox_container">
                <input 
                  type="checkbox" 
                  value={this.state.below_one_year} 
                  id="below_one_year" 
                  onChange={this.on_below_one_year_change}
                  checked={this.state.below_one_year} />
                <label htmlFor="below_one_year"> Age below 1 year</label>
                <div className="tooltip"></div>
                <div className="tooltip_others" style={{fontFamily: "Montserrat_Regular"}}>
                  Age of baby in months and days
                </div>
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker ref={this.date_of_death_ref}
                  label="Date of death *"
                  customStyles={{
                    dateInput: {
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      // disableUnderline: true,
                    }
                  }}
                  InputProps={{
                    disableUnderline: true
                  }}
                  value={this.state.date_of_death}
                  onChange={(newValue) => {
                    this.calculate_birth_year(newValue,this.state.age);
                    this.setState({
                      date_of_death: newValue
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  // minDate={this.state.date_of_birth?new Date(this.state.date_of_birth):undefined}
                  maxDate={new Date()}
                  required={true}
                  openTo="year"
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy" 
                  allowSameDateSelection={true}
                />
              </LocalizationProvider>

              {this.state.below_one_year?
              <>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">Age in months</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.age_in_months}
                  onChange={this.age_in_months_change}
                  label="Age in months"
                  ref={this.age_in_months_ref}
                >
                  {Array.from({ length: 12 }, (_, i) => i) && Array.from({ length: 12 }, (_, i) => i).map((item, idx) => (
                    <MenuItem value={item} key={idx}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120, height: "55px", margin: "20px 0px" }}>
                <InputLabel id="demo-simple-select-standard-label">Age in days</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.age_in_days}
                  onChange={this.age_in_days_change}
                  label="Age in days"
                >
                  {Array.from({ length: 32 }, (_, i) => i) && Array.from({ length: 32 }, (_, i) => i).map((item, idx) => (
                    <MenuItem value={item} key={idx}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              </>
              :
              <>
              <InputTheme4
                label="Age *"
                attrs={{
                  name: "age",
                  type: "number",
                  value: this.state.age,
                  onChange: this.form_change,
                  maxLength : 3
                }}
                input_ref={this.age_ref}
              />
              </>
              }
               
              <InputTheme4
                label="Place of death (home/name & place of the hospital etc)"
                attrs={{
                  name: "place_of_death",
                  type: "text",
                  value: this.state.place_of_death,
                  onChange: this.form_change,
                }}
              />
              <div className="checkbox_container">
                <input 
                  type="checkbox" 
                  value={this.state.other_address} 
                  id="other_address" 
                  onChange={this.on_other_address_change}
                  checked={this.state.other_address} />
                <label htmlFor="other_address"> Add other addresses</label>
                <div className="tooltip"></div>
                <div className="tooltip_others" style={{fontFamily: "Montserrat_Regular"}}>
                  If the deceased has former addresses of importance, please add them here to appear on the search.
                </div>
              </div> 
              {this.state.other_address?
              <>
              <TextAreaTheme1
                label="Seconday Address (Max 150 characters)"
                attrs={{
                  name: "secondary_address",
                  value: this.state.secondary_address,
                  onChange: this.form_change,
                  maxLength : 150,
                  required : this.state.other_address?true:false
                }}
              />
              <TextAreaTheme1
                label="Tertiary Address (Max 150 characters)"
                attrs={{
                  name: "teritiary_address",
                  value: this.state.teritiary_address,
                  onChange: this.form_change,
                  maxLength : 150
                }}
              />
              </>:""}
            </div>
          </div>
  
          {/* <div className="charity_selection_container">
          <div className="charity_button_container">
            <Link className="view_all_charity_button" to="/Charities" target={"_blank"}>View All Charity</Link>
          </div>
            <div className="charity_section">
              <div className="search_and_suggestions_container">
                <div className="selected_notice" style={{display:this.state.charity.id?"inline-grid":"none"}}>
                  <div className="selected_label">Charity Selected</div>
                  <div className="single_suggestion">
                    <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+this.state.charity.image_src+"')"}}></div>
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">{this.state.charity.name}</div>
                      <div className="suggestion_address">{this.state.charity.locality}, {this.state.charity.city}, {this.state.charity.state}</div>
                      <div className="charity_close_button" onClick={
                        () => {
                          this.setState({
                          charity : {}
                        })
                      }}>x</div>
                    </div>
                  </div>
                </div>
                <div className="search_container">
                  <input
                    className="search_input"
                    name="charity_search_input"
                    type="text"
                    value={this.state.charity_search_input}
                    onChange={this.charities_search}
                    placeholder="Search Charity"
                    autoComplete = "off"
                  />
                  <i className="fas fa-search search_icon"></i>
                </div>
                <div className="suggestions_container" style={{boxShadow:this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                {this.state.charities && this.state.charities.map((item,idx) => (
                  <div className="single_suggestion" onClick={() => this.setState({
                    charity : item,
                    charities : [],
                    charity_search_input : ""
                    })}
                  >
                  <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+item.image_src+"')"}}></div>
                  <div className="suggestion_name_address">
                    <div className="suggestion_name">{item.name}</div>
                    <div className="suggestion_address"> {item.locality}, {item.city}, {item.state}</div>
                  </div>
                </div>
              ))}
                <div className="suggestions_container" style={{display: this.state.charity_search_input && !this.state.charities.length?"block":"none", boxShadow:!this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                  <div className="single_suggestion">
                    <div className="suggestion_image"></div>
                    <div className="suggestion_name_address">
                      <div className="suggestion_name">No results</div>
                      <div className="suggestion_address"></div>
                    </div>
                  </div>
                </div>
                  
                </div>
                
              </div>
            </div>
           
          </div> */}
          <div className="notice_instructions_contact_details" ref={this.notice_ref}>
            <div>
              <div className="notice_container" >
                <div className="notice_label">Notice details *</div>
                {/* <div className="notice_title">The death has occurred of {this.state.family_or_house_name?this.state.family_or_house_name+",":""} {this.state.first_name} {this.state.middle_name} {this.state.surname}</div> */}
                <div className="notice_hint">Notice (Maximum character limit is 1400)</div>
                <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}>You may either put together a Death Notice for your loved one yourself or use one of our examples to compile the details required. While using our example, you may edit or add to make it more relevant. Please check the spelling of all names.</div>
                <div className="death_notice_templates_container">
                  <div className="single_death_notice_template" style={{pointerEvents:this.state.below_one_year?"none":"auto",opacity:this.state.below_one_year?"0.4":""}}>
                    <div className="template_title">Default Death Notice</div>
                    <div className="template_content">
                      The death has occurred of {this.state.first_name}{this.state.middle_name?" "+this.state.middle_name:""}{this.state.surname?" "+this.state.surname:""}{this.state.nick_name?" ("+this.state.nick_name+")":""}{this.state.age?" ("+this.state.age+")":""},{this.state.nee?" (Née: "+this.state.nee+"), ":""}{this.state.family_or_house_name?" "+this.state.family_or_house_name+",":""} {this.state.locality?this.state.locality+", ":""}{this.state.city?this.state.city+", ":""}{this.state.state_label?this.state.state_label+", ":""}{this.state.country_label} on {new Date(this.state.date_of_death).toLocaleDateString('en-GB')}.
                    </div>
                  </div>
                  <div className="single_death_notice_template" style={{pointerEvents:this.state.below_one_year?"none":"auto",opacity:this.state.below_one_year?"0.4":""}}>
                    <div className="template_title">General Death notice sample 1</div>
                    <div className="template_content">
                      {this.state.first_name} {this.state.middle_name?this.state.middle_name+" ":""}{this.state.surname?this.state.surname+" ":""}{this.state.nick_name?"("+this.state.nick_name+")":""}{this.state.age?" ("+this.state.age+")":""} {this.state.place_of_death}: {this.state.first_name}{this.state.middle_name?" "+this.state.middle_name:""} {this.state.surname} passed away (suddenly/peacefully) (surrounded by the family/in the loving care of the staff) 
                      at (home/name of the hospital/hospice etc.){this.state.place_of_death?" in "+this.state.place_of_death:""} on {new Date(this.state.date_of_death).toLocaleDateString('en-GB')}. {this.state.first_name} was born to (parent’s name). 
                      (He/She) attended (name of high school and/or college).<br></br>
                      {this.state.first_name} worked as (job) and enjoyed participating in (hobbies). {this.state.first_name} was also a proud member of 
                      (organizations and affiliations). {this.state.first_name} is survived by (names and details of the surviving family) 
                      and was predeceased by (names of family members who have died.)
                    </div>
                  </div>
                  <div className="single_death_notice_template" style={{pointerEvents:this.state.below_one_year?"none":"auto",opacity:this.state.below_one_year?"0.4":""}}>
                    <div className="template_title">General Death notice sample 2</div>
                    <div className="template_content">
                      {this.state.first_name} {this.state.middle_name?this.state.middle_name+" ":""}{this.state.surname?this.state.surname+" ":""}{this.state.age?"("+this.state.age+")":""}<br></br>
                      {this.state.first_name} {this.state.middle_name?this.state.middle_name+" ":""}{this.state.surname?this.state.surname+" ":""}({new Date(this.state.date_of_birth).getFullYear()} - {new Date(this.state.date_of_death).getFullYear()}) passed away on {new Date(this.state.date_of_death).toLocaleDateString('en-GB')}{this.state.place_of_death?" in "+this.state.place_of_death:""}. (He/She) is survived by (names and roles of living family members) and was predeceased by (names and roles of family members who have died). 
                    </div>
                  </div>
                  <div className="single_death_notice_template"
                    style={{display:this.state.gender != "Female"?"inline-block":"none",pointerEvents:this.state.below_one_year?"none":"auto",opacity:this.state.below_one_year?"0.4":""}}
                  >
                    <div className="template_title"> Death Notice for a male</div>
                    <div className="template_content">
                      {this.state.first_name} {this.state.middle_name?this.state.middle_name+" ":""}{this.state.surname}<br></br>
                      {new Date(this.state.date_of_birth).getFullYear()} - {new Date(this.state.date_of_death).getFullYear()}<br></br>
                      {this.state.first_name} {this.state.middle_name?this.state.middle_name+" ":""}{this.state.surname?this.state.surname+" ":""}{this.state.nick_name?"("+this.state.nick_name+")":""}{this.state.age?" ("+this.state.age+")":""} passed away peacefully/suddenly on {new Date(this.state.date_of_death).toLocaleDateString('en-GB')}{this.state.place_of_death?" at "+this.state.place_of_death:""}. Much loved husband of (name), father to (name of the child/children), father-in-law to (name/s), grandfather to (name/s). (First name) will be sadly missed by (names/relationships).
                    </div>
                  </div>
                  <div className="single_death_notice_template"
                    style={{display:this.state.gender != "Male"?"inline-block":"none",pointerEvents:this.state.below_one_year?"none":"auto",opacity:this.state.below_one_year?"0.4":""}}
                  >
                    <div className="template_title"> Death Notice for a female</div>
                    <div className="template_content">
                      {this.state.first_name} {this.state.middle_name?this.state.middle_name+" ":""}{this.state.surname}
                      {this.state.nick_name?"("+this.state.nick_name+")":""}{this.state.age?" ("+this.state.age+")":""} {new Date(this.state.date_of_death).getDate()}, {this.month_names[new Date(this.state.date_of_death).getMonth()]}, suddenly/peacefully. {this.state.first_name} of {this.state.family_or_house_name?this.state.family_or_house_name+",":""} {this.state.locality}, {this.state.city_label}, {this.state.state_label}, aged {this.numberInWords(this.state.age)}years. Devoted wife of (name). A loving and much-loved mom to (name/s). A beloved grandma to (name/s).  Also, a very dear sister to (name/s) and a good friend to many.  (First name) will be sadly missed by (names/relationships).
                    </div>
                  </div>
                  <div className="single_death_notice_template">
                    <div className="template_title"> Death Notice for a baby</div>
                    <div className="template_content">
                      {this.state.first_name} {this.state.middle_name?this.state.middle_name+" ":""}{this.state.surname?this.state.surname+" ":""}{this.state.nick_name?"("+this.state.nick_name+") ":""}{new Date(this.state.date_of_death).getDate()}, {this.month_names[new Date(this.state.date_of_death).getMonth()]} {new Date(this.state.date_of_death).getFullYear()}: {this.state.first_name} lost (her/his) fight aged {this.state.below_one_year?this.state.age_in_months_days:this.numberInWords(this.state.age)+"years" }. 
                      Forever proud son/daughter to (name of parents), loving sister/brother to (name/s), much loved granddaughter/grandson to (name/s). (name) will be sadly missed by (names/relationships).
                      <br></br>
                      A one-of-a-kind child who touched all our lives in the best way possible – she/he will be forever present in our lives. {this.state.first_name}, you will be with us always at home in your family and friends' hearts. Always present, always cherished with so many memories from your packed, extraordinary short life.
                    </div>
                  </div>
                </div>
                {/* <textarea
                  name="notice_content"
                  value={this.state.notice_content}
                  placeholder="Type your notice content"
                  onChange={this.notice_change}
                  required={true}
                  rows={10}
                /> */}
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="editor_toolbar"
                  wrapperClassName="editor_component"
                  editorClassName="editor_input_area"
                  onEditorStateChange={this.onEditorStateChange}
                  placeholder={"Enter your notice content.."}
                  required={true}
                  handlePastedText={() => false}
                  stripPastedStyles={true}
                  toolbar={{
                    options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                    },list: {
                      options: ['unordered', 'ordered'],
                    },
                    fontSize: {
                      options: [16, 18, 20, 22, 24, 26, 28, 32, 36],
                    },
                  }}
                />
              </div>
            </div>
            <div>
              <div className="instruction_contacts_container">
                <div className="notice_label">Special instructions</div>
                <div className="notice_hint">Notice (Maximum character limit is 300)</div>
                <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}>Please provide any instructions regarding parking or other arrangements that people need to keep in mind either visiting or participating in the funeral.</div>
                <textarea
                  name="general_notes"
                  value={this.state.general_notes}
                  placeholder=""
                  onChange={this.form_change}
                  rows={4}
                  maxLength={300} 
                />
                <div className="notice_label">Contact Details</div>
                <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}>The name and contact number provided here will be displayed to anyone visiting the profile page of the deceased. </div>
                <div className="contact_details">
                  <InputTheme4
                    label="Contact Name"
                    className="contact_input"
                    attrs={{
                      name: "contact_name",
                      type: "text",
                      value: this.state.contact_name,
                      onChange: this.form_change,
                      maxLength : 30 
                    }}
                  />
                  {/* <InputTheme4
                    label="Mobile Number"
                    className="contact_input"
                    attrs={{
                      name: "contact_no",
                      type: "tel",
                      value: this.state.contact_no,
                      pattern : "[6789][0-9]{9}", 
                      maxLength: 10,
                      onChange: this.form_change,
                    }}
                  /> */}
                  <div className="mobile_no_input_container">
                  {/* <div className="mobile_no_label" style={{color:this.state.contact_no?"#4f0662":""}}>Mobile Number</div> */}
                  <PhoneInput
                    style={{borderBottom:this.state.contact_no?"2px solid #4f0662":"",color:this.state.contact_no?"#111":""}}
                    placeholder="Mobile Number"
                    international
                    value={this.state.contact_no}
                    onChange={this.contact_no_change}
                    defaultCountry= {this.state.contact_no_code}
                    // required={true}
                    onCountryChange={(country) => this.contact_no_code_change(country)}
                  />
                </div>
                </div>
              </div>
            </div>
            
          </div>

          <div className="visitation_details_container" style={{display:this.state.visitation_details.length?"block":"none"}}>
            <div className="other_details_title">Viewing Details</div>
            <div className="custom_table_theme1 visitation_details">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell fullname">Date</div>
                <div className="table_cell notice_type">Start Time</div>
                <div className="table_cell">End Time</div>
                <div className="table_cell">Address</div>
                <div className="table_cell">Youtube Video URL</div>
                <div className="table_cell">Google Map URL</div>
                <div className="table_cell close_button_container">
                <div className="close_other_details_table"
                  onClick={() => this.setState({
                    visitation_details : []
                  })}
                >x</div>
                </div>
              </div>
              {this.state.visitation_details && this.state.visitation_details.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Date</div>
                    <input 
                      name="date"
                      type="date"
                      value={item.date}
                      placeholder="dd-mm-yyyy"
                      min={new Date(this.state.date_of_death).toLocaleDateString('en-CA')}
                      onChange={(e) => this.visitation_change(e,idx)}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Start time</div>
                    <input 
                      name="start_time"
                      type="time"
                      value={item.start_time}
                      onChange={(e) => this.visitation_change(e,idx)}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">End time</div>
                    <input 
                      name="end_time"
                      type="time"
                      min={item.start_time}
                      value={item.end_time}
                      onChange={(e) => this.visitation_change(e,idx)}
                      required
                      />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Address</div>
                    <textarea
                      name="address"
                      value={item.address}
                      onChange={(e) => this.visitation_change(e,idx)}
                      required
                      placeholder="Enter address"
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Youtube video URL</div>
                    <textarea 
                      name="video_url"
                      type="text"
                      value={item.video_url}
                      onChange={(e) => this.visitation_change(e,idx)}
                      placeholder="Enter URL"
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Google map URL</div>
                    <textarea 
                      name="google_map_url"
                      type="text"
                      value={item.google_map_url}
                      onChange={(e) => this.visitation_change(e,idx)}
                      placeholder="Enter URL"
                    />
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                      onClick={() => this.add_visitation_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={() => this.delete_visitation_row(idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
              <div className="table_row no_results" style={{display:this.state.visitation_details.length?"none":"block"}}>No results.</div>
            </div>
          </div>
          <div className="funeral_service_details_container" style={{display:this.state.funeral_details.length?"block":"none"}}>
            <div className="other_details_title">Funeral Service Details</div>
            <div className="custom_table_theme1 funeral_services">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="tablel_cell">Program title</div>
                <div className="table_cell fullname">Date</div>
                <div className="table_cell notice_type">Start Time</div>
                {/* <div className="table_cell">End Time</div> */}
                <div className="table_cell">Address</div>
                <div className="table_cell">Youtube Video URL</div>
                <div className="table_cell">Google Map URL</div>
                <div className="table_cell close_button_container">
                  <div className="close_other_details_table"
                    onClick={() => this.setState({
                      funeral_details : []
                    })}
                  >x</div>
                </div>
              </div>
              {this.state.funeral_details && this.state.funeral_details.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Program title</div>
                    <input 
                      name="program_title"
                      type="text"
                      value={item.program_title}
                      placeholder="Enter title"
                      onChange={(e) => this.funeral_change(e,idx)}
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Date</div>
                    <input 
                      name="date"
                      type="date"
                      value={item.date}
                      min={new Date(this.state.date_of_death).toLocaleDateString('en-CA')}
                      onChange={(e) => this.funeral_change(e,idx)}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Start time</div>
                    <input 
                      name="start_time"
                      type="time"
                      value={item.start_time}
                      onChange={(e) => this.funeral_change(e,idx)}
                      required
                    />
                  </div>
                  {/* <div className="table_cell">
                    <div className="input_label">End time</div>
                    <input 
                      name="end_time"
                      type="time"
                      min={item.end_time?item.start_time:""}
                      value={item.end_time}
                      onChange={(e) => this.funeral_change(e,idx)}
                      />
                  </div> */}
                  <div className="table_cell">
                    <div className="input_label">Address</div>
                    <textarea
                      name="address"
                      value={item.address}
                      onChange={(e) => this.funeral_change(e,idx)}
                      required
                      placeholder="Enter address"
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Youtube video URL</div>
                    <textarea 
                      name="video_url"
                      type="text"
                      value={item.video_url}
                      onChange={(e) => this.funeral_change(e,idx)}
                      placeholder="Enter URL"
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Google map URL</div>
                    <textarea 
                      name="google_map_url"
                      type="text"
                      value={item.google_map_url}
                      onChange={(e) => this.funeral_change(e,idx)}
                      placeholder="Enter URL"
                    />
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                      onClick={() => this.add_funeral_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={() => this.delete_funeral_row(idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
              <div className="table_row no_results" style={{display:this.state.funeral_details.length?"none":"block"}}>No results.</div>
            </div>
          </div>
          <div className="committal_service_details_container" style={{display:this.state.committal_details.length?"block":"none"}}>
            <div className="other_details_title">Committal Service Details</div>
            <div className="custom_table_theme1 committal_service">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell fullname">Date</div>
                <div className="table_cell notice_type">Start Time</div>
                {/* <div className="table_cell">End Time</div> */}
                <div className="table_cell">Address</div>
                <div className="table_cell">Youtube Video URL</div>
                <div className="table_cell">Google Map URL</div>
                <div className="table_cell close_button_container">
                  <div className="close_other_details_table"
                    onClick={() => this.setState({
                      committal_details : []
                    })}
                  >x</div>
                </div>
              </div>
              {this.state.committal_details && this.state.committal_details.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Date</div>
                    <input 
                      name="date"
                      type="date"
                      value={item.date}
                      min={new Date(this.state.date_of_death).toLocaleDateString('en-CA')}
                      onChange={(e) => this.committal_change(e,idx)}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Start time</div>
                    <input 
                      name="start_time"
                      type="time"
                      value={item.start_time}
                      onChange={(e) => this.committal_change(e,idx)}
                      required
                    />
                  </div>
                  {/* <div className="table_cell">
                    <div className="input_label">End time</div>
                    <input 
                      name="end_time"
                      type="time"
                      min={item.start_time}
                      value={item.end_time}
                      onChange={(e) => this.committal_change(e,idx)}
                      />
                  </div> */}
                  <div className="table_cell">
                    <div className="input_label">Address</div>
                    <textarea
                      name="address"
                      value={item.address}
                      onChange={(e) => this.committal_change(e,idx)}
                      required
                      placeholder="Enter address"
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Youtube video URL</div>
                    <textarea 
                      name="video_url"
                      type="text"
                      value={item.video_url}
                      onChange={(e) => this.committal_change(e,idx)}
                      placeholder="Enter URL"
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Google map URL</div>
                    <textarea 
                      name="google_map_url"
                      type="text"
                      value={item.google_map_url}
                      onChange={(e) => this.committal_change(e,idx)}
                      placeholder="Enter URL"
                    />
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                      onClick={() => this.add_committal_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={() => this.delete_committal_row(idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
              <div className="table_row no_results" style={{display:this.state.committal_details.length?"none":"block"}}>No results.</div>
            </div>
          </div>
          <div className="survived_peoples_details_container" style={{display:this.state.survived_peoples.length?"block":"none"}}>
            <div className="other_details_title">Surviving Family Details (Maximum: 12 Peoples)</div>
            <div className="custom_table_theme1 survived_peoples">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell">Name</div>
                <div className="table_cell">Relationship/Other Details</div>
                <div className="table_cell">Image</div>
                <div className="table_cell close_button_container">
                  <div className="close_other_details_table"
                    onClick={() => this.setState({
                      survived_peoples : []
                    })}
                  >x</div>
                </div>
              </div>
              {this.state.survived_peoples && this.state.survived_peoples.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Name</div>
                    <input 
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={item.name}
                      onChange={(e) => this.survived_change(e,idx)}
                      maxLength={25}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Relation and other details</div>
                    <input 
                      name="relation"
                      placeholder="Enter Relationship/Other Details"
                      type="text"
                      value={item.relation}
                      onChange={(e) => this.survived_change(e,idx)}
                      maxLength={35}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Image</div>
                    <input 
                       type="file"
                       name="image"
                       // value={item.image}
                       accept="image/*"
                       onChange={(e) => this.survived_image_change(e,idx)}
                    />
                    {item.image_src}
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                    style={{visibility:this.state.survived_peoples.length<12?"visible":"hidden"}}
                      onClick={() => this.add_survived_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={() => this.delete_survived_row(idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="predeceased_peoples_details_container" style={{display:this.state.predeceased_peoples.length?"block":"none"}}>
            <div className="other_details_title">Predeceased Family Details (Maximum: 6 Peoples)</div>
            <div className="custom_table_theme1 predeceased_peoples_">
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell">Name</div>
                <div className="table_cell">Relationship/Other Details</div>
                <div className="table_cell">Image</div>
                <div className="table_cell close_button_container">
                  <div className="close_other_details_table"
                    onClick={() => this.setState({
                      predeceased_peoples : []
                    })}
                  >x</div>
                </div>
              </div>
              {this.state.predeceased_peoples && this.state.predeceased_peoples.map((item,idx) => (
                <div className="table_row body" key={idx} >
                  <div className="table_cell sl_no">{idx+1}</div>
                  <div className="table_cell">
                    <div className="input_label">Name</div>
                    <input 
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={item.name}
                      onChange={(e) => this.predeceased_change(e,idx)}
                      maxLength={25}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Relation and other details</div>
                    <input 
                      name="relation"
                      placeholder="Enter Relationship/Other Details"
                      type="text"
                      value={item.relation}
                      onChange={(e) => this.predeceased_change(e,idx)}
                      maxLength={35}
                      required
                    />
                  </div>
                  <div className="table_cell">
                    <div className="input_label">Image</div>
                    <input 
                       type="file"
                       name="image"
                       accept="image/*"
                       onChange={(e) => this.predeceased_image_change(e,idx)}
                    />
                    {item.image_src}
                  </div>
                  <div className="table_cell buttons_container">
                    <div className="add_row_button"
                      style={{visibility:this.state.predeceased_peoples.length<6?"visible":"hidden"}}
                      onClick={() => this.add_predeceased_row(idx)}
                    >
                      <AiOutlinePlus/>
                    </div>
                    <div className="delete_row_button"
                      style={{visibility:"visible"}}
                      onClick={() => this.delete_predeceased_row(idx)}
                    >
                      <AiOutlineDelete/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="other_details_buttons_container">
            <button className="other_details_button"
              type="button"
              style={{display:this.state.visitation_details.length?"none":"inline-block", fontFamily: "Montserrat_Regular"}}
              onClick={() => this.setState({
                visitation_details : [
                  {
                    date : "",
                    start_time : "",
                    end_time :"",
                    address : "",
                    video_id : "",
                    video_url : "",
                    google_map_url : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("visitation_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12} /> Add Viewing Details</button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.funeral_details.length?"none":"inline-block", fontFamily: "Montserrat_Regular"}}
              onClick={() => this.setState({
                funeral_details : [
                  {
                    date : "",
                    start_time : "",
                    end_time :"",
                    address : "",
                    video_id : "",
                    video_url : "",
                    google_map_url : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("funeral_service_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12}/> Add Funeral Details</button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.committal_details.length?"none":"inline-block", fontFamily: "Montserrat_Regular"}}
              onClick={() => this.setState({
                committal_details : [
                  {
                    date : "",
                    start_time : "",
                    end_time :"",
                    address : "",
                    video_id : "",
                    video_url : "",
                    google_map_url : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("committal_service_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12} />
              Add Committal Details
            </button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.survived_peoples.length?"none":"inline-block", fontFamily: "Montserrat_Regular"}}
              onClick={() => this.setState({
                survived_peoples : [
                  {
                    name : "",
                    image_src : "",
                    relation : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("survived_peoples_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12}/>
              Add Surviving Family Members
            </button>
            <button className="other_details_button"
              type="button"
              style={{display:this.state.predeceased_peoples.length?"none":"inline-block", fontFamily: "Montserrat_Regular"}}
              onClick={() => this.setState({
                predeceased_peoples : [
                  {
                    name : "",
                    image_src : "",
                    relation : ""
                  }
                ]
              },() => {
                window.scrollTo({
                  top : document.getElementsByClassName("predeceased_peoples_details_container")[0].offsetTop,
                  behavior : "smooth"
                });
              })}
            ><GrAdd color="green" size={12}/>
              Add Predeceased Family Members
            </button>
          </div>
          <div className="notice_label">Charity Details</div>
          <div className="tooltip" style={{fontFamily: "Montserrat_Regular"}}>Please choose a charity of your choice in memory of your loved one if you wish to do so.</div>
          <div className="charity_selection_container">
          <div className="charity_button_container">
            <Link className="view_all_charity_button" to="/Charities" target={"_blank"}>View All Charity</Link>
          </div>
          <div className="charity_section">
            
            <div className="search_and_suggestions_container">
            <div className="search_container">
                <input
                  className="search_input"
                  name="charity_search_input"
                  type="text"
                  value={this.state.charity_search_input}
                  onChange={this.charities_search}
                  placeholder="Search Charity"
                  autoComplete = "off"
                />
                <i className="fas fa-search search_icon"></i>
              </div>
              <div className="selected_notice" style={{display:this.state.charity.id?"inline-grid":"none"}}>
                {/* <div className="selected_label">Charity Selected</div> */}
                <div className="single_suggestion">
                  <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+this.state.charity.image_src+"')"}}></div>
                  <div className="suggestion_name_address">
                    <div className="suggestion_name">{this.state.charity.name}</div>
                    <div className="suggestion_address">{this.state.charity.locality}, {this.state.charity.city}, {this.state.charity.state}</div>
                    <div className="charity_close_button" onClick={
                      () => {
                        this.setState({
                        charity : {}
                      })
                    }}>x</div>
                  </div>
                </div>
              </div>
              
              <div className="suggestions_container" style={{boxShadow:this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
              {this.state.charities && this.state.charities.map((item,idx) => (
                <div className="single_suggestion" onClick={() => this.setState({
                  charity : item,
                  charities : [],
                  charity_search_input : ""
                  })}
                >
                <div className="suggestion_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+item.image_src+"')"}}></div>
                <div className="suggestion_name_address">
                  <div className="suggestion_name">{item.name}</div>
                  <div className="suggestion_address"> {item.locality}, {item.city}, {item.state}</div>
                </div>
              </div>
            ))}
              <div className="suggestions_container" style={{display: this.state.charity_search_input && !this.state.charities.length?"block":"none", boxShadow:!this.state.charities.length?"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px":""}}>
                <div className="single_suggestion">
                  <div className="suggestion_image"></div>
                  <div className="suggestion_name_address">
                    <div className="suggestion_name">No results</div>
                    <div className="suggestion_address"></div>
                  </div>
                </div>
              </div>
                
              </div>
              
            </div>
          </div>
           
          </div>
          <div className="save_button_container">
            {this.state.death_notice_id?
              <ButtonTheme1
                label="Modify"
                className="create_button"
              ></ButtonTheme1>
            :
              <ButtonTheme1
                  label="Create"
                  className="create_button"
                ></ButtonTheme1>
            }
          </div>
        </form>
      </div>
    )
  }
}

export default NewDeathNotice;
NewDeathNotice.contextType = MyContext;