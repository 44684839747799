import React from "react";
import "./ViewFeedback.scss";
import { Link } from "react-router-dom";
import $ from "jquery";
import MyContext from "../../MyContext";
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";

class ViewFeedback extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      name : "",
      email : "",
      message : "",
      published_date : ""
    }

    this.approve_feedback = this.approve_feedback.bind(this);
    this.delete_feedback = this.delete_feedback.bind(this);
    this.view_feedback_page_ref = React.createRef();
  }

  componentDidMount(){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/viewFeedbackDetails",
      data : this.context.bind_session({
        feedback_id : this.props.match.params.feedback_id
      }),
      type : "POST"
    }).done(function(return_data){
      if (return_data.status == "success") {
        const feedback_details = return_data.feedback_details;
        obj_this.setState(feedback_details,() => {
          obj_this.view_feedback_page_ref.current.style.visibility = "visible";
        });
        
        obj_this.context.loading(0);
      } else {
        alert("Network error!!")
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  approve_feedback(){
    if(!window.confirm("Approve feedback?")){
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/approveUserFeedback",
      data : this.context.bind_session({
        feedback_id : this.props.match.params.feedback_id
      }),
      type : "POST"
    }).done(function(return_data){
      if (return_data.status == "success") {
        alert("Feedback approved");
        obj_this.context.loading(0);
        obj_this.props.history.push("/Dashboard/Feedbacks")
      } else {
        alert("Network error!!")
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  delete_feedback(){
    if(!window.confirm("Delete feedback?")){
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/deleteUserFeedback",
      data : this.context.bind_session({
        feedback_id : this.props.match.params.feedback_id
      }),
      type : "POST"
    }).done(function(return_data){
      if (return_data.status == "success") {
        alert("Feedback deleted");
        obj_this.context.loading(0);
        obj_this.props.history.push({
          pathname : "/Dashboard/Feedbacks",
          state : obj_this.props.location.previousState??""
        });
      } else {
        alert("Network error!!")
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="view_feedback_page_container page_container" ref={this.view_feedback_page_ref}>
        <div className="page_header">
          {/* <div className="page_title">View Feedback</div> */}
          {/* <div className="breadcrumbs_container">
            <Link to="/Dashboard"><i className="fas fa-home"></i></Link>/
            <Link to="/Dashboard/Feedbacks">Feedbacks</Link>/
            <span>Approve</span>
          </div> */}
        </div>
        <div className="view_message">
          <div className="add_message_title">User Feedback</div>
          <div className="details_grid">
            <div className="label">Name</div>
            <div className="value">{this.state.name}</div>
            <div className="label">Email</div>
            <div className="value">{this.state.email}</div>
            <div className="label">Feedback</div>
            <div className="value">{this.state.message}</div>
            <div className="label">Date</div>
            <div className="value">{new Date(this.state.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
          </div>
          <div className="approve_reject_buttons_container">
            <ButtonTheme1
              label="<< Back"
              className="previous_page_button"
              onClick={() =>  this.props.history.push({
                pathname : "/Dashboard/Feedbacks",
                state : this.props.location.previousState??""
              })}
            ></ButtonTheme1>
            <ButtonTheme1
              label="Delete"
              className="reject_button"
              onClick={this.delete_feedback}
              style={{display : this.props.location.action == "delete"?"inline-block":"none"}}
            ></ButtonTheme1>
          </div>
        </div>
      </div>
    )
  }
}

export default ViewFeedback;
ViewFeedback.contextType = MyContext;