import React from "react";
import "./PrivacyPolicy.scss";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.privacy_policy_ref = React.createRef();
  }

  render() {
    return (
      <div
        className="privacy_policy_page_container page_container"
        ref={this.privacy_policy_ref}
      >
        <div className="page_title">
          <div className="title_ghost"></div>
          <div className="title">Privacy Policy</div>
        </div>
        <div className="privacy_policy_container">
          <div
            className="home_link"
            onClick={() => this.props.history.push("/")}
          >
            HOME
          </div>
          <div className="page_link">PRIVACY POLICY</div>
          <p>
            <b>Amrityum.com</b> ("We" or “amrityum.com”) is committed to
            protecting and respecting your privacy. This statement (together
            with our Terms and Conditions and Cookie Policy) sets out the basis
            on which any personal data we collect from you, or that you provide
            to us, will be processed by us.  Please read the following carefully
            to understand our views and practices regarding your personal data
            and how we will treat it. By using our website amrityum.com and its
            subdomains and mobile versions (our “Site”) you indicate your
            acceptance of this Privacy Statement. If you do not agree to this
            Privacy Statement, do not use, or access the Site.
          </p>
          <div className="sub_heading">
            Personal Information that We collect
          </div>
          <p>
            We do not knowingly collect your personally identifiable information
            without your consent. When you register with the Website and set up
            your personal account, we will ask you to provide us with certain
            contact and personal details such as your full name, age, email
            address and password to log-in, physical address, including your
            country and state, cellular phone number, etc. Additionally, certain
            Services, like opening a new Create a Memorial, Pet Tributes and
            Death/Family Notices, Life Events or Family Tree on the Website (all
            as defined in the Terms of Use), may require you to furnish us with
            payment details to register with the respective Service. We will
            explicitly state the information we require on the relevant web
            pages.
          </p>
          <p>
            We may collect further information when you use any of the Services
            on the Website. For example, We may record the frequency and scope
            of your use of the Services, the duration of your sessions, the web
            pages that you visit, information that you read, content that you
            use or create, advertisements that you view or click on, your
            communications with other users and third parties on the Website,
            the Internet protocol (IP) address and the name of the domain that
            serve you to access the Website, or any of the Services, and the
            geographic location of the computer system that you are using to
            log-in to the Website.
          </p>
          <p>
            We may automatically gather this information using log files. If you
            are registered with the Website, the information we gather may be
            anonymous or in some instances personally identifiable. If you are
            not a registered user, then we will not knowingly make use of any
            information which personally identifies you, except as specifically
            indicated in this Policy.
          </p>
          <p>
            Further information may be collected when we exchange communications
            with you, for example, if you submit an inquiry or a request to
            info@amrityum.com, or when you contact any of our team.
          </p>
          <div className="sub_heading">
            The Personal Data records held by amrityum.com may include:
          </div>
          <b>Staff records</b>
          <b>a) Categories of Staff Data</b>
          <p>
            As well as existing members of staff (and former members of staff),
            these records may also relate to applicants applying for positions
            within the company, trainee staff and staff under probation. These
            staff records may include: 
          </p>
          <ul className="main_list">
            <li>
              Name, address and contact details. Name and contact details of
              next-of-kin in case of emergency.
            </li>
            <li>Banking details.</li>
            <li>
              Original records of application and appointment to promotion
              posts.
            </li>
            <li>
              Details of approved absences (career breaks, parental leave, study
              leave, etc.).
            </li>
            <li>
              Details of work record (qualifications, courses attended etc.).
            </li>
            <li>
              Details of any accidents/injuries sustained on company property or
              in connection with the staff member carrying out their duties.
            </li>
          </ul>
          <b>b) Purposes of processing</b>
          <p>Staff records are kept for the purposes of:</p>
          <ul className="main_list">
            <li>
              The management and administration of amrityum.com (now and in the
              future)
            </li>
            <li>
              To facilitate the payment of staff, and calculate other
              benefits/entitlements (including reckonable service for the
              purpose of calculation of pension payments, entitlements and/or
              redundancy payments where relevant)
            </li>
            <li>Human resources management</li>
            <li>
              Recording promotions made (documentation relating to promotions
              applied for) and changes in responsibilities, etc.
            </li>
            <li>
              To enable amrityum.com to comply with its obligations as an
              employer, including the preservation of a safe, efficient working
              environment
            </li>
            <li>
              To enable amrityum.com to comply with requirements set down by any
              governmental, statutory and/or regulatory departments and/or
              agencies
            </li>
            <li>For compliance with legislation relevant to amrityum.com</li>
          </ul>
          <b>c) Lawful Bases for Processing</b>
          <ul className="main_list">
            <li>Statutory Obligations (e.g. payment of payroll taxes etc.)</li>
            <li>Necessary for the execution of contractual obligations</li>
            <li>
              Necessary for obligations under employment, taxation, and social
              security law
            </li>
            <li>
              Legitimate interests – It is in the legitimate interest of
              amrityum.com to efficiently manage staff and ensure compliance
              with duties of care and other obligations.
            </li>
          </ul>
          <b>d) Location and Security Procedures</b>
          <ul className="main_list">
            <li>
              Manual records are kept (a) in a secure, locked filing cabinet in
              a locked administration office (b) in a locked room only
              accessible to personnel who are authorised to use the data.
              Employees are required to maintain the confidentiality of any data
              to which they have access.
            </li>
            <li>
              Digital records are stored on password-protected computer with
              adequate encryption and firewall software in a locked office.
            </li>
          </ul>
          <b>Clients, Creditors, Job Applicants.</b>
          <b>a) Categories of Personal Data</b>
          <p>
            Amrityum.com may hold some or all the following information about
            the above data subjects (some of whom may be self-employed
            individuals):
          </p>
          <ul className="main_list">
            <li>Name</li>
            <li>Address</li>
            <li>Contact details</li>
            <li>GST Registration</li>
            <li>Tax details</li>
            <li>Bank details</li>
            <li>Amount paid</li>
            <li>Work history</li>
            <li>Qualifications</li>
            <li>Third-Party employment references</li>
          </ul>
          <b>b) Purposes of processing</b>
          <p>The purposes for processing client records are:</p>
          <ul className="main_list">
            <li>
              To enable amrityum.com to conduct its legitimate business
              operations and to market its products and / or services.
            </li>
          </ul>
          <p>The purposes for processing creditor records are:</p>
          <ul className="main_list">
            <li>
              This information is required for routine management and
              administration of amrityum.com financial affairs, including the
              payment of invoices, the compiling of annual financial accounts
              and complying with audits and investigations by the Department of
              Finance.
            </li>
          </ul>
          <p>The purposes for processing Job Applicant records are:</p>
          <ul className="main_list">
            <li>To facilitate the recruitment and hiring of staff.</li>
          </ul>
          <b>c) Lawful Bases for Processing</b>
          <ul className="main_list">
            <li>Statutory Obligations (eg. payment of GST etc.)</li>
            <li>Necessary for the execution of contractual obligations</li>
          </ul>
          <b>d) Location and Security Procedures</b>
          <ul className="main_list">
            <li>
              Manual records are kept (a) in a secure, locked filing cabinet in
              a locked administration office (b) in a locked room only
              accessible to personnel who are authorised to use the data.
              Employees are required to maintain the confidentiality of any data
              to which they have access.
            </li>
            <li>
              Digital records are stored on password-protected computer with
              adequate encryption and firewall software in a locked office.
            </li>
          </ul>
          <div className="sub_heading">
            Information we collect about you via this website.
          </div>
          <p>
            Regarding each of your visits to our Site we may automatically
            collect the following information:
          </p>
          <ul className="main_list">
            <li>
              Technical information, including the Internet protocol (IP)
              address used to connect your computer to the Internet, your login
              information, browser type and version, time zone setting, browser
              plug-in types and versions, operating system and platform.
            </li>
            <li>
              Information about your visit, including the full Uniform Resource
              Locators (URL) clickstream to, through and from our Site
              (including date and time); products you viewed or searched for;
              page response times, download errors, length of visits to certain
              pages, page interaction information (such as scrolling, clicks,
              and mouse-overs), and methods used to browse away from the page.
            </li>
          </ul>
          <p>
            This is statistical data about our users' browsing actions and
            patterns, and we cannot identify any individual.
          </p>
          <p>
            In cases where registered Members who login to the Members’ Area on
            amrityum.com using their Facebook/google login details,
            Facebook/google becomes a Joint Controller of the Joint Processing
            of their data. More details can be obtained from Facebook/Google
            Data Policy.
          </p>
          <div className="sub_heading">
            Information we receive from other sources.
          </div>
          <p>
            We work closely with third parties (including, for example, business
            partners, sub-contractors in technical, payment and delivery
            services, advertising networks, analytics providers, search
            information providers) and may receive information about you from
            them. The Personal Data records held by amrityum.com may include:
          </p>
          <div className="sub_heading">Personal Data</div>
          <p>
            We will collect personal data about you in accordance with the
            purposes outlined in this document.  This will be basic or regular
            personal data used to facilitate a consultant/client type
            relationship usually your name and email address and from time to
            time billing information.  If you are a sole trader or partnership,
            we would consider your address to be personal data.
          </p>
          <div className="sub_heading">Special Category Personal Data</div>
          <p>
            Certain types of Personal Data are classed as ‘Special Category’
            data. The special categories of data are:
          </p>
          <ul className="main_list">
            <li>Personal data revealing racial or ethnic origin.</li>
            <li>Political opinions.</li>
            <li>Religious or philosophical beliefs.</li>
            <li>Trade union membership.</li>
            <li>
              Genetic data and biometric data processed for the purpose of
              uniquely identifying a natural person.
            </li>
            <li>Data concerning health.</li>
            <li>
              Data concerning a natural person’s sex life or sexual orientation.
            </li>
          </ul>
          <p>We will not collect special category data from you.</p>
          <div className="sub_heading">Criminal Conviction Data</div>
          <p>We will not collect criminal conviction data from you.</p>
          <div className="sub_heading">Children’s Personal Data</div>
          <p>
            If you would like to make use of our services and you are not yet 18
            years old, we require that an adult is present when you register, if
            registration is required. Where consent is required to process your
            Personal Data as a child, we will obtain that consent from the adult
            who is authorised to give the consent on your behalf.  You must be
            at least 18 years old to create an account and engage in activities
            and transactions on our digital and social media. By creating an
            account or engaging in activities or transactions on our digital and
            social media, you affirm that you are at least 18 years old and are
            fully able to enter into and comply with our regular Terms of Use
            and this Privacy Policy. If we are notified or learn that a child
            has submitted Personal Data to us through our digital or social
            media without the correct permissions or consents, we will delete
            such Personal Data.
          </p>
          <div className="sub_heading">USES MADE OF THE INFORMATION</div>
          <p>
            We use information collected in the following ways:<br></br>
            Where you provide information to us we may use this:
          </p>
          <ul className="main_list">
            <li>
              To carry out our obligations arising from any contracts entered
              into between you and us.
            </li>
            <li>
              To provide you with information, products or services that you
              request from us or to provide you with information about other
              services we offer that are similar to those that you have already
              purchased or enquired about.
            </li>
            <li>To notify you about changes to our service.</li>
            <li>
              To ensure that content from our Site is presented in the most
              effective manner for you and for your computer.
            </li>
            <li>
              We may also provide third parties with aggregate information about
              our users that does not identify them as individuals.
            </li>
          </ul>
          <p>Where we collect information about you we may use this:</p>
          <ul className="main_list">
            <li>
              To administer our Site and for internal operations, including
              troubleshooting, data analysis, testing, research, statistical and
              survey purposes.
            </li>
            <li>
              To improve our Site to ensure that content is presented in the
              most effective manner for you and for your computer.
            </li>
            <li>
              To allow you to participate in interactive features of our
              service, when you choose to do so.
            </li>
            <li>As part of our efforts to keep our Site safe and secure.</li>
            <li>
              To measure or understand the effectiveness of advertising we serve
              to you and others, and to deliver relevant advertising to you.
            </li>
            <li>
              To make suggestions and recommendations to you and other users of
              our Site about goods or services that may interest you or them.
            </li>
          </ul>
          <div className="sub_heading">DISCLOSURE OF YOUR INFORMATION</div>
          <p>
            Your personal information may be disclosed in the following ways:
          </p>
          <ul className="main_list">
            <li>
              We may disclose your personal information to any member of our
              group, which means our subsidiaries, our ultimate holding company
              and its subsidiaries, as defined by appropriate Laws/ACTS
              governing in India. The reason for this disclosure includes, but
              is not limited to, enabling one of our related companies to
              perform certain services on our behalf.
            </li>
            <li>
              We may disclose your personal information to business partners,
              suppliers and sub-contractors for the performance of any contract
              we enter into with them or you.
            </li>
            <li>
              In the event that we sell or buy any business or assets, in which
              case we may disclose your personal data to the prospective seller
              or buyer of such business or assets.
            </li>
            <li>
              If amrityum.com or a substantial part of its assets are acquired
              by a third-party personal data held by us about our customers will
              be one of the transferred assets.
            </li>
            <li>
              Where advertising is displayed on our Site we may disclose data to
              advertisers and advertising networks that require the data to
              select and serve relevant adverts to you and others.  We do not
              disclose information about identifiable individuals to our
              advertisers, but we may provide them with aggregate information
              about our users (for example, we may inform them that 500 people
              from Ernakulam District have clicked on their advertisement on any
              given day). We may also use such aggregate information to help
              advertisers reach the kind of audience they want to target (for
              example, visitors from the Sate of Goa). We may make use of the
              personal data we have collected from you to enable us to comply
              with our advertisers' wishes by displaying their advertisement to
              that target audience.
            </li>
            <li>
              Analytics and search engine providers that assist us in the
              improvement and optimisation of our Site.
            </li>
            <li>
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation, or in order to enforce
              or apply our terms of use, or to protect the rights, property, or
              safety of amrityum.com, our customers, or others. This includes
              exchanging information with other companies and organisations for
              the purposes of fraud protection.
            </li>
          </ul>
          <div className="sub_heading">WHERE WE STORE YOUR PERSONAL DATA</div>
          <p>
            The data that we collect from you may be transferred to, and stored
            at, a destination outside India. It may also be processed by staff
            operating outside India who work for us or for one of our suppliers.
            Such staff may be engaged in, among other things, the fulfilment of
            your order, the processing of your payment details, entry or
            approval of your data entry and the provision of support services.
            By submitting your personal data, you agree to this transfer,
            storing or processing. We will take all steps reasonably necessary
            to ensure that your data is treated securely and in accordance with
            this Privacy Statement.
          </p>
          <p>
            All information you provide to us is stored on our secure servers
            and we take the security of the data that we store seriously.
            However, the transmission of information via the internet is not
            completely secure.
          </p>
          <p>
            Although we will do our best to protect your personal data, we
            cannot guarantee the security of your data transmitted to our Site
            and any transmission is at your own risk. Once we have received your
            information, we will use strict procedures and security features to
            try to prevent unauthorised access.
          </p>
          <div className="sub_heading">USER RIGHTS</div>
          <p>
            You have the right to access information held about you. Your right
            of access can be exercised by sending an email to 
            <span>info@amrityum.com</span>
          </p>
          <p>
            Users have the right to ask us not to process personal data for
            marketing purposes by contacting us at 
            <span>info@amrityum.com</span>
            For processing activities for which we rely on consent as a basis
            for processing your data, you have the right to withdraw your
            consent at any time.
          </p>
          <p>
            For processing activities which are based on a statutory or
            contractual requirement, you may request your data not be processed
            for that purpose. However, this is not an absolute right and may be
            over-ridden by our statutory obligations. In other cases, requesting
            that data should not be processed for a particular reason may
            prevent us from executing a contract or delivering a service to you.
          </p>
          <p>You have the right to request:</p>
          <ul className="main_list">
            <li>A copy of data we hold about you. </li>
            <li>That any error in data we hold about you is corrected.</li>
            <li>
              That data we hold about you be erased, unless we have an
              overriding interest or legal obligation to retain it.
            </li>
            <li>
              That we refrain from processing data for a specific purpose.
            </li>
          </ul>
          <p>
            To exercise your user rights noted above, please contact us by email
            at <span>info@amrityum.com</span>{" "}
          </p>
          <p>
            Our Site contains links to and from the websites of our partner
            networks, advertisers and affiliates.  If you follow a link to any
            of these websites, please note that these websites have their own
            privacy policies and that we do not accept any responsibility or
            liability for these policies.  Please check these policies before
            you submit any personal data to these websites. 
          </p>
          <div className="sub_heading">COOKIES</div>
          <p>
            We may use cookies when operating the Services on the Website.
            Cookies are packets of information sent by Our servers to your web
            browser and then sent back by the browser. We may use cookies for
            various purposes, for example, to save you the need to re-enter your
            password each time you log-in to the Website, to facilitate the use
            of the Services, to collect information for statistical and
            analytical purposes, to verify information, to customize the
            Services to your personal preferences and for information security
            purposes. Some of the cookies may expire when the session ends and
            you exit your browser. Other cookies may be stored on your
            computer’s hard drive. If you wish to block the cookies, then please
            use the help button in your browser and follow the necessary
            instructions. However, bear in mind that disabling cookies may
            complicate or even prevent your use of the Website and Services, or
            certain features thereof.
          </p>
          <div className="sub_heading">ADVERTISING</div>
          <p>
            We may permit certain other companies to conduct advertising
            campaigns on or through the Website. The advertisements that you see
            when accessing the Website are redirected from the servers of those
            advertisers. Some advertisers may use cookies on your computer. The
            use of cookies by the advertisers is subject to their own privacy
            policies and not to this Policy. If you wish to study the privacy
            policies of those advertisers you should visit their websites, or
            contact them directly.
          </p>
          <div className="sub_heading">CHANGES TO OUR PRIVACY STATEMENT</div>
          <p>
            Any changes we may make to our privacy statement in the future will
            be posted on this page. Please check back frequently to see any
            updates or changes to this statement.
          </p>
          <p>
            You agree to be bound by any of the changes made in the Privacy
            Policy, including changes to any and all documents, forms and
            policies incorporated thereto. Continuing to use the Website will
            indicate your acceptance of the amended Policy. If you do not agree
            with any of the amended terms in this Policy, then you must avoid
            any further use of the Website.
          </p>
          <p>
            We advise you to periodically read the Privacy Policy, as it may
            change from time to time. Last Updated: March 14, 2022
          </p>
          <div className="sub_heading">CONTACT</div>
          <p>
            Questions, comments and requests regarding this privacy statement
            are welcomed and should be addressed to 
            <span>info@amrityum.com</span>
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
