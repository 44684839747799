import React from "react";
import ManageAccount from "../ManageAccount/ManageAccount";

class AccountSettings extends React.Component{
  constructor(props){
    super(props);
    
  }

  render(){
    return(
      <div className="account_setting_page_container">
        <ManageAccount history = {this.props.history}/> 
      </div>
    )
  }
}

export default AccountSettings;