import React from "react";
import "./PetTributeReportedList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AddButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {Link} from "react-router-dom";
import {FcApproval, FcFullTrash} from 'react-icons/fc';
import { FaInfoCircle } from "react-icons/fa";

class PetTributeReportedList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      pet_tributes : [],
      comments : [],
      reject_reason : "",
      deleted_reason : "",
      selected_reported_reason : "",
      pet_tribute_id : "",
    }
    this.pet_tribute_reported_list_page_Ref = React.createRef();
    this.delete_reason_container_ref = React.createRef();
    this.delete_reported_message = this.delete_reported_message.bind(this);
    this.delete_form_submit = this.delete_form_submit.bind(this);
    this.ignore_reported_message = this.ignore_reported_message.bind(this);
    this.delete_reported_notice = this.delete_reported_notice.bind(this);
    this.ignore_reported_notice = this.ignore_reported_notice.bind(this);
    this.form_change = this.form_change.bind(this);
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/pendingPetTributeReportedContents",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        pet_tributes : return_data.pet_tributes,
        comments : return_data.comments
      }, () => {
          obj_this.context.loading(0);
          obj_this.pet_tribute_reported_list_page_Ref.current.style.visibility = "visible";
          obj_this.pet_tribute_reported_list_page_Ref.current.style.opacity = 1;
      });
     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
  }

  form_change(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  delete_reported_notice(id, reported_reason){
    if(!window.confirm("Are your sure")){
      return;
    }
    this.delete_reason_container_ref.current.style.display = "block";
    this.setState({
      pet_tribute_id : id,
      selected_reported_reason : reported_reason
    });
  }

  delete_form_submit(e){
    e.preventDefault();
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/deleteReportedPetTribute",
      data : this.context.bind_session({
        pet_tribute_id : this.state.pet_tribute_id,
        deleted_reason : this.state.deleted_reason,
        selected_reported_reason : this.state.selected_reported_reason
      }),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        obj_this.context.loading(0);
        $.alert("Pet tribute deleted")
        obj_this.setState({
          pet_tributes : obj_this.state.pet_tributes.filter((item) => item.id != obj_this.state.pet_tribute_id),
          comments : return_data.reported_comments,
          deleted_reason : "",
          selected_reported_reason : "",
          pet_tribute_id : ""
        });
        obj_this.delete_reason_container_ref.current.style.display = "none";
      }else{
        alert("Something Went Wrong. Please Try Again.");
      }
     }).fail(function(){
       alert("Network error");
     });
  }

  ignore_reported_notice(reported_notice_id){
    if(!window.confirm("Are your sure")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/ignoreReportedPetTribute",
      data : this.context.bind_session({reported_notice_id : reported_notice_id}),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        $.alert("Pet tribute removed from reported list.")
        obj_this.setState({
          pet_tributes : obj_this.state.pet_tributes.filter((item) => item.reported_notice_id != reported_notice_id),
        });
      }else{
        alert("Something Went Wrong. Please Try Again.");
      }
      obj_this.context.loading(0);
     }).fail(function(){
       alert("Network error");
     });
  }

  delete_reported_message(comment_id){
    if (!window.confirm("Delete this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteReportedPetTributeComment",
      data: this.context.bind_session({ comment_id: comment_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          comments : obj_this.state.comments.filter((item) => item.id != comment_id),
        });
        alert("Pet tribute comment deleted");
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  ignore_reported_message(comment_id, tribute_type){
    if(!window.confirm("Are your sure")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/ignoreReportedPetTributeComment",
      data: this.context.bind_session({ comment_id: comment_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          comments : obj_this.state.comments.filter((item) => item.id != comment_id),
        });
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="pet_tribute_reported_list_page_container page_container" ref={this.pet_tribute_reported_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Reported Contents</div>
          <div className="new_pet_tribute_button_container">
            {/* <Link to="/Dashboard/PetTributes/New">
              <AddButtonTheme1 label="Publish a Pet Tribute"/>
            </Link> */}
          </div>
        </div>
        <div style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
          <div className="table_title">Pet Tributes</div>
          <div className="custom_table_theme1">
              <div className="table_row header">
              <div className="table_cell sl_no">Sl No.</div>
              <div className="table_cell notice_type">Name</div>
              <div className="table_cell fullname">Category</div>
              <div className="table_cell notice_type">Breed</div>
              <div className="table_cell">Age</div>
              <div className="table_cell">Reported By</div>
              <div className="table_cell datetime">Reported On</div>
              <div className="table_cell">Action</div>
            </div>
            {this.state.pet_tributes && this.state.pet_tributes.map((item,idx) => (
              <div className="table_row body"
                key={idx}
                >
                <div className="table_cell sl_no">{idx+1}</div>
                <div className="table_cell notice_type">{item.name}</div>
                <div className="table_cell">{item.animal}</div>
                <div className="table_cell notice_type">{item.breed}</div>
                <div className="table_cell">{item.age}</div>
                <div className="table_cell">{item.reported_by}</div>
                <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
                <div className="table_cell">
                  <div className="action">
                    <div className="tooltip approve">
                      <span className="tooltipText approve">Ignore</span>
                      <FcApproval className="approve_button" onClick={() => this.ignore_reported_notice(item.reported_notice_id)}/>
                    </div>
                    <div className="tooltip delete">
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button" onClick={() => this.delete_reported_notice(item.id, item.reason)}/>
                    </div>
                    <div className="tooltip reason" onClick={() => this.setState({reported_reason : item.reason})}>
                      <span className="tooltipText reason">Reason</span>
                      <FaInfoCircle className="delete_button"/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="table_row no_results" style={{display:this.state.pet_tributes.length?"none":"block"}}>No results.</div>
          </div>
        </div>
        <div className="table_title">Comments</div>
        <div className="custom_table_theme1">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Pet Tribute</div>
            <div className="table_cell">Comment</div>
            <div className="table_cell fullname">Reported By</div>
            <div className="table_cell datetime">Reported On</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.comments && this.state.comments.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.name}</div>
              <div className="table_cell">
                {item.comment}
              </div>
              <div className="table_cell">{item.reported_by}</div>
              <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve">
                    <span className="tooltipText approve">Ignore</span>
                    <FcApproval className="approve_button" onClick={() => this.ignore_reported_message(item.id, item.tribute_type)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_reported_message(item.id, item.tribute_type )}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.comments.length?"none":"block"}}>No results.</div>
        </div>
        <div className="view_message_ghost" style={{display:this.state.reported_reason?"block":"none"}}>
          <div className="view_message" 
          >
          <div className="add_message_title">Reported Reason</div>
            <div className="details_grid">
              <div className="label"></div>
              <div className="value">{this.state.reported_reason}</div>
            </div>
            <div className="close_message_form"
              onClick={() => {
                this.setState({
                  reported_reason  : ""
                })
              }}
            >
              <div>x</div>
            </div>
          </div>
        </div>
        <div className="delete_reason_container" ref={this.delete_reason_container_ref}>
          <form className="delete_reason_form" onSubmit={this.delete_form_submit} method="post">
            <div className="delete_reason_title">Delete reason</div>
            <div className="delete_reason_form_body">
              <textarea name="deleted_reason"
                rows={4}
                value={this.state.deleted_reason}
                onChange={this.form_change}
                required
                placeholder="Type here.."
              />
              <button className="delete_form_submit_button">Submit</button>
            </div>
            <div className="delete_form_close_button" onClick={() => { 
              this.delete_reason_container_ref.current.style.display = "none"; 
              this.setState({ 
                deleted_reason : "",
                selected_reported_reason : "",
                pet_tribute_id : "" }) }}>x</div>
          </form>
        </div>
      </div>
    )
  }
}

export default PetTributeReportedList;
PetTributeReportedList.contextType = MyContext;