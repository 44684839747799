import React, {MouseEvent} from "react";
import "./Dashboard.scss";
import $ from "jquery";
import MyContext from "../MyContext";
import {Link} from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, InteractionItem } from 'chart.js';
import { Pie, getElementAtEvent } from 'react-chartjs-2';
import { SiTheirishtimes } from "react-icons/si";

ChartJS.register(ArcElement, Tooltip, Legend);
class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      users_count : "",
      death_notices_count : "",
      family_notices_count : "",
      memorial_services_count : "",
      pet_tributes_count: "",
      partners_count : "",
      pending_death_notice_count : "",
      pending_memorial_count : "",
      pending_family_notice_count : "",
      pending_pet_tribute_count : "",
      pending_service_count : "",
      pending_blog_count : "",
      charities_count : "",
      user_only_count : "",
      pie_data1 : {
        labels: ['Amrityum Users', 'Charities', 'Partners',],
        datasets: [
          {
            label: '# of Votes',
            data: [0, 0, 0],
            backgroundColor: [
              '#ff4842',
              '#ffc107',
              '#2065d1',
            ],
            borderColor: [
              'white',
              'white',
              'white',
            ],
            borderWidth: 4,
          },
        ],
      },
      pie_data2 : {
        labels: ['Charities', 'Partners',],
        datasets: [
          {
            label: '# of Votes',
            data: [0, 0,],
            backgroundColor: [
              '#ffc107',
              '#2065d1',
            ],
            borderColor: [
              'white',
              'white',
            ],
            borderWidth: 4,
          },
        ],
      },
      reported_death_notice_count : "",
      reported_memorial_count : "",
      reported_family_notice_count : "",
      reported_pet_tribute_count : "",
      reported_partner_count : "",
      reported_blog_count : ""
    }
    this.dashboard_page_ref = React.createRef();
    this.chartRef1 = React.createRef();
    this.chartRef2 = React.createRef();
  }

  componentDidMount(){
    this.context.loading(1);
    $(".menu_list").css({
      "height": "0"
    });
    $(".sidebar_component_container .menu_item_theme1").css({backgroundColor : "", color :"#637381"});
    $(".menu_item[href='/Dashboard']").css({backgroundColor : "#613282bd", color :"white"});
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/getDashboardDetails",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        // console.log(return_data);
        const pie_data1 = {
          labels: ['Amrityum Users', 'Charities', 'Partners'],
          datasets: [
            {
              label: '# of Votes',
              data: [return_data.user_only_count, return_data.charity_count, return_data.partners_count],
              backgroundColor: [
                '#00a24f',
                '#ffc107',
                '#2065d1',
              ],
              borderColor: [
                'white',
                'white',
                'white',
              ],
              borderWidth: 4,
            },
          ],
        }
        const pie_data2 = {
          labels: ['Charities', 'Partners'],
          datasets: [
            {
              label: '# of Votes',
              data: [return_data.charity_count, return_data.partners_count],
              backgroundColor: [
                '#ffc107',
                '#2065d1',
              ],
              borderColor: [
                'white',
                'white',
              ],
              borderWidth: 4,
            },
          ],
        }
        obj_this.context.setSession(return_data.session);
        obj_this.setState({
          users_count : return_data.users_count,
          death_notices_count : return_data.death_notices_count,
          family_notices_count : return_data.family_notices_count,
          pet_tributes_count: return_data.pet_tributes_count,
          memorial_services_count : return_data.memorial_services_count,
          partners_count : return_data.partners_count,
          charities_count : return_data.charity_count,
          pending_death_notice_count : return_data.pending_death_notice_count,
          pending_memorial_count : return_data.pending_memorial_count,
          pending_family_notice_count : return_data.pending_family_notice_count,
          pending_pet_tribute_count : return_data.pending_pet_tribute_count,
          pending_service_count : return_data.pending_partner_count,
          pending_blog_count : return_data.pending_blog_count,
          pie_data1 : pie_data1,
          pie_data2 : pie_data2,
          reported_death_notice_count : return_data.reported_death_notice_count,
          reported_memorial_count : return_data.reported_memorial_count,
          reported_family_notice_count : return_data.reported_family_notice_count,
          reported_pet_tribute_count : return_data.reported_pet_tribute_count,
          reported_partner_count : return_data.reported_partner_count,
          reported_blog_count : return_data.reported_blog_count,
        },() => {
          obj_this.context.loading(0);
          obj_this.dashboard_page_ref.current.style.visibility = "visible";
          obj_this.dashboard_page_ref.current.style.opacity = 1;
        });
      }else if(return_data.status == "deleted"){
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        alert("Sorry, your account has been deleted.");
        obj_this.props.history.replace('/');
      }else if(return_data.status == "session failed"){
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        alert("Your session has timed out. Please login again.");
        obj_this.props.history.replace('/Login');
      }else{
        obj_this.context.loading(0);
        alert("Network error");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  onClick1 = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = this.chartRef1;
    if (!chart) {
      return;
    }
    this.printElementAtEvent1(getElementAtEvent(chart, event));
  };

  printElementAtEvent1 = (element: InteractionItem[]) => {
    if (!element.length) return;
      const { datasetIndex, index } = element[0];
    if(this.state.pie_data1.labels[index] == "Amrityum Users"){
      this.props.history.push('/Dashboard/Users')
    }else if(this.state.pie_data1.labels[index] == "Partners"){
      this.props.history.push('/Dashboard/Partners')
    }else{
      this.props.history.push("/Dashboard/Charities/All")
    }
    // console.log(this.state.pie_data.labels[index], this.state.pie_data.datasets[datasetIndex].data[index]);
  };

  onClick2 = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = this.chartRef2;

    if (!chart) {
      return;
    }

    this.printElementAtEvent2(getElementAtEvent(chart, event));
  };

  printElementAtEvent2 = (element: InteractionItem[]) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];
    if(this.state.pie_data2.labels[index] == "Partners"){
      this.props.history.push("/Services");
    }else if(this.state.pie_data2.labels[index] == "Charities"){
      this.props.history.push("/Charities");
    }
    // console.log(this.state.pie_data2.labels[index], this.state.pie_data2.datasets[datasetIndex].data[index]);
  };

  render(){
    return(
      <div className="dashboard_page_container page_container" ref={this.dashboard_page_ref}>
        <div className="page_header">
          <div className="page_title">Dashboard</div>
        </div>
        <div className="count_grid_container">
          {/* <div className="container_title">Website Visits</div> */}
          <div className="dashboard_count_grid">
            <Link className="single_count_grid_item"
              to={this.context.session.user_type == "admin"?'/Dashboard/DeathNotices/All':'/Dashboard/DeathNotices/MyEntries'}
              style={{backgroundColor:"rgb(208, 242, 255)"}}>
              <div className="count_type_icon" style={{color : "rgb(12, 83, 183)"}}><i className="far fa-address-card menu_icon"></i></div>
              <div className="count_label_container">
                <div className="count_value" style={{color:"rgb(4, 41, 122)"}}>{this.state.death_notices_count}</div>
                <div className="count_type_label" style={{color:"rgb(4, 41, 122)"}}>Death Notices</div>
              </div>
            </Link>
            <Link className="single_count_grid_item"
              to={this.context.session.user_type == "admin"?'/Dashboard/Memorials/All':'/Dashboard/Memorials/MyEntries'}
              style={{backgroundColor:"rgb(255, 231, 217)"}}>
              <div className="count_type_icon" style={{color: "rgb(183, 33, 54)",backgroundImage: "linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 100%)"}}><i className="fas fa-gifts"></i></div>
              <div className="count_label_container">
                <div className="count_value" style={{color : "rgb(122, 12, 46)"}}>{this.state.memorial_services_count}</div>
                <div className="count_type_label" style={{color: "rgb(122, 12, 46)"}}>Memorials</div>
              </div>
            </Link>
            <Link className="single_count_grid_item"
              to={this.context.session.user_type == "admin"?'/Dashboard/InMemoryNotices/All':'/Dashboard/InMemoryNotices/MyEntries'}
              style={{backgroundColor:"rgb(255, 247, 205)"}}>
              <div className="count_type_icon" style={{color:"rgb(183, 129, 3)",backgroundImage: "linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%)"}}>
                <i className="far fa-newspaper"></i>
              </div>
              <div className="count_label_container">
                <div className="count_value" style={{color:"rgb(122, 79, 1)"}}>{this.state.family_notices_count}</div>
                <div className="count_type_label" style={{color: "rgb(122, 79, 1)"}}>In Memory Notices</div>
              </div>
            </Link>
            <Link className="single_count_grid_item"
              to={this.context.session.user_type == "admin"?'/Dashboard/PetTributes/All':'/Dashboard/PetTributes/MyEntries'}
              style={{backgroundColor:"#e5f7bf"}}>
              <div className="count_type_icon" style={{color: "rgb(16, 57, 150)",backgroundImage: "linear-gradient(135deg, #ffe700 0%, rgba(16, 57, 150, 0.24) 100%)"}}><i className="fas fa-paw"></i></div>
              <div className="count_label_container">
                <div className="count_value" style={{color : "#061b64"}}>{this.state.pet_tributes_count}</div>
                <div className="count_type_label" style={{color: "rgb(6, 27, 100)"}}>Pet Tributes</div>
              </div>
            </Link>
          </div>
        </div>
        {/* <div className="page_title" style={{marginTop:"60px"}}>Pending Approvals</div> */}
        <div className="pending_pie_chart_grid">
          <div className="pending_count_grid_container">
            <div className="container_title">Pending Approvals</div>
            <div className="approval_pending_grid">
              <Link className="single_pending_grid_item" to="/Dashboard/DeathNotices/Approvals">
                <div className="icon_container" style={{backgroundColor:"#fd9100",border:"1px solid #e6871c"}}><i className="far fa-address-card menu_icon"></i></div>
                <div className="count_container">
                  <div className="count_label">Death Notices</div>
                  <div className="count_value" >{this.state.pending_death_notice_count}</div>
                </div>
                <div className="pending_label">To be approved</div>
              </Link>
              <Link className="single_pending_grid_item" to="/Dashboard/Memorials/Approvals">
                <div className="icon_container" style={{backgroundColor:"#47a84c",border:"1px solid #369039"}}><i className="fas fa-gifts"></i></div>
                <div className="count_container">
                  <div className="count_label">Memorials</div>
                  <div className="count_value">{this.state.pending_memorial_count}</div>
                </div>
                <div className="pending_label">To be approved</div>
              </Link>
              <Link className="single_pending_grid_item"  to="/Dashboard/InMemoryNotices/Approvals">
                <div className="icon_container" style={{backgroundColor:"#e83d32",border:"1px solid #d12b26"}}><i className="far fa-newspaper"></i></div>
                <div className="count_container">
                  <div className="count_label">In Memory Notices</div>
                  <div className="count_value">{this.state.pending_family_notice_count}</div>
                </div>
                <div className="pending_label">To be approved</div>
              </Link>

              <Link className="single_pending_grid_item" to="/Dashboard/PetTributes/Approvals">
                <div className="icon_container" style={{backgroundColor:"#01b6cd",border:"1px solid #13a1b8"}}><i className="fas fa-paw"></i></div>
                <div className="count_container">
                  <div className="count_label" >Pet Tributes</div>
                  <div className="count_value">{this.state.pending_pet_tribute_count}</div>
                </div>
                <div className="pending_label">To be approved</div>
              </Link>
              <Link className="single_pending_grid_item" style={{display:this.context.session.user_type != "user" && this.state.pending_service_count?"inline-grid":"none" }} to={this.context.session.user_type=="admin"?"/Dashboard/Services/Approvals":"/Dashboard/Services/Comments"}>
                <div className="icon_container" style={{backgroundColor:"rgb(205, 183, 1)",border:"1px solid rgb(183, 163, 0)"}}><i className="fas fa-suitcase"></i></div>
                <div className="count_container">
                  <div className="count_label" >Services</div>
                  <div className="count_value">{this.state.pending_service_count}</div>
                </div>
                <div className="pending_label">To be approved</div>
              </Link>
              <Link className="single_pending_grid_item" style={{display:this.context.session.user_type == "admin" && this.state.pending_blog_count?"inline-grid":"none"}} to={this.context.session.user_type=="admin"?"/Dashboard/Blogs/Approvals":"#"}>
                <div className="icon_container" style={{backgroundColor:"rgb(185, 90, 219)",border:"1px solid rgb(174, 39, 223)"}}><i className="fas fa-comment"></i></div>
                <div className="count_container">
                  <div className="count_label" >Blogs</div>
                  <div className="count_value">{this.state.pending_blog_count}</div>
                </div>
                <div className="pending_label">To be approved</div>
              </Link>
            </div>
          </div>
          <div className="pie_chart_grid_item">
            {this.context.session.user_type == "admin"?
            <div className="pie_chart_container">
              <div className="container_title">Overview</div>
              <Pie data={this.state.pie_data1} 
                onClick={this.onClick1} 
                ref={this.chartRef1} 
                className="111111"
                />
            </div>
            :
            <div className="pie_chart_container">
              <div className="container_title">Overview</div>
              <Pie data={this.state.pie_data2} 
                onClick={this.onClick2} 
                ref={this.chartRef2} />
            </div>
            }
          </div>
        </div>
        <div className="reported_grid_container">
          <div className="container_title">Reported Contents</div>
          <div className="reported_pending_grid">
            <Link className="single_pending_grid_item" to="/Dashboard/DeathNotices/ReportedContents">
              <div className="icon_container" style={{backgroundColor:"#fd9100",border:"1px solid #e6871c"}}><i className="far fa-address-card menu_icon"></i></div>
              <div className="count_container">
                <div className="count_label">Death Notices</div>
                <div className="count_value" >{this.state.reported_death_notice_count}</div>
              </div>
              <div className="pending_label">To be reviewed</div>
            </Link>
            <Link className="single_pending_grid_item" to="/Dashboard/Memorials/ReportedContents">
              <div className="icon_container" style={{backgroundColor:"#47a84c",border:"1px solid #369039"}}><i className="fas fa-gifts"></i></div>
              <div className="count_container">
                <div className="count_label">Memorials</div>
                <div className="count_value" >{this.state.reported_memorial_count}</div>
              </div>
              <div className="pending_label">To be reviewed</div>
            </Link>
            <Link className="single_pending_grid_item"  to="/Dashboard/InMemoryNotices/ReportedContents">
              <div className="icon_container" style={{backgroundColor:"#e83d32",border:"1px solid #d12b26"}}><i className="far fa-newspaper"></i></div>
              <div className="count_container">
                <div className="count_label">In Memory Notices</div>
                <div className="count_value" >{this.state.reported_family_notice_count}</div>
              </div>
              <div className="pending_label">To be reviewed</div>
            </Link>
            <Link className="single_pending_grid_item"  to="/Dashboard/PetTributes/ReportedContents">
              <div className="icon_container" style={{backgroundColor:"#01b6cd",border:"1px solid #13a1b8"}}><i className="fas fa-paw"></i></div>
              <div className="count_container">
                <div className="count_label">Pet Tributes</div>
                <div className="count_value" >{this.state.reported_pet_tribute_count}</div>
              </div>
              <div className="pending_label">To be reviewed</div>
            </Link>
            <Link className="single_pending_grid_item" style={{display:this.context.session.user_type != "user" && this.state.reported_partner_count?"inline-grid":"none"}} to={"/Dashboard/Services/ReportedContents"}>
              <div className="icon_container" style={{backgroundColor:"rgb(205, 183, 1)",border:"1px solid rgb(183, 163, 0)"}}><i className="fas fa-suitcase"></i></div>
              <div className="count_container">
                <div className="count_label" >Services</div>
                <div className="count_value">{this.state.reported_partner_count}</div>
              </div>
              <div className="pending_label">To be reviewed</div>
            </Link>
            <Link className="single_pending_grid_item" style={{display:this.context.session.user_type == "admin" && this.state.reported_blog_count?"inline-grid":"none"}} to={this.context.session.user_type=="admin"?"/Dashboard/Blogs/ReportedContents":"#"}>
              <div className="icon_container" style={{backgroundColor:"rgb(185, 90, 219)",border:"1px solid rgb(174, 39, 223)"}}><i className="fas fa-comment"></i></div>
              <div className="count_container">
                <div className="count_label" >Blogs</div>
                <div className="count_value">{this.state.reported_blog_count}</div>
              </div>
              <div className="pending_label">To be reviewed</div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;
Dashboard.contextType = MyContext;