import React from "react";
import "./AboutUs.scss";
import about_us from "../../res/images/about_us/about_us.jpg";
import about_us2 from "../../res/images/about_us/about_us2.jpg";
import about_image from "../../res/images/about_us/about1.jpg";
import about_image2 from "../../res/images/about_us/about2.jpg";
import quote from "../../res/images/about_us/quotes.jpg";
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import $ from "jquery";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import MyContext from "../MyContext";
import {Link} from "react-router-dom";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.about_us_page_ref = React.createRef();
  }

  componentDidMount() {
    $(".show_video_button").on("click", function () {
      $(".video_container_ghost").css({ visibility: "visible", opacity: 1 });
    });

    $(".video_close_button").on("click", function () {
      $(".video_container_ghost").css({ opacity: 0, visibility: "hidden" });
      $("#iframeid").attr("src", $("#iframeid").attr("src"));
    });
  }

  render() {
    return (
      <div
        className="about_us_page_container page_container"
        ref={this.about_us_page_ref}
      >
        <div className="title_block">
          <div
            className="image_container"
            style={{ backgroundImage: "url('" + about_us2 + "')" }}
          ></div>
          <div className="about_us_main_content">
            <div className="main_title">
              Our <span>Story.</span>
            </div>
            <div className="content">
              We are all made of stories; different genres, different
              narrations. Sometimes the world gives us a chance to etch our
              lives before we bid adieu, but for some, their stories merge into
              oblivion as they leave the world. Small efforts can ensure that
              the memories of the deceased are preserved well. It is this
              thought that led us to the creation of Amrityum, a uniquely
              beautiful platform to commemorate and preserve the life, memories
              and times of a loved one.{" "}
            </div>
            <div className="content">
              You might have heard interesting incidents and life experiences
              from your grandparents. You can share them with your grandchildren
              and those stories can travel to their grandchildren...!!! We are
              providing you with a platform to preserve memories and share
              stories for future generations to read and cherish. It could be
              something as small as a joke they said or a forgotten poem from
              their diary. We are here to provide you with a space to share the
              memories of your loved ones. We comprehend the efficacy of your
              sentiments and strive to preserve them for generations as the
              repository of memories, information and legacy.
            </div>
          </div>
          <div className="bottom_content"></div>
        </div>
        {/* <img className="image_block" src={about_us}/> */}
        <div className="our_vision_mission_block">
          <div className="our_vision_grid">
            <div className="our_vision_title">
              <div>Our</div>
              <div style={{ fontWeight: "700" }}>Vision</div>
            </div>
            <div className="our_vision_content">
              To become the home of memories and to preserve life stories,
              celebrating togetherness.
            </div>
          </div>
          <div className="our_vision_grid">
            <div className="our_vision_title">
              <div>Our</div>
              <div style={{ fontWeight: "700" }}>Mission</div>
            </div>
            <div className="our_vision_content">
              To create a virtual platform for people to come together, get
              connected and remember and celebrate their deceased loved ones as
              well as preserve our own stories and make them enduring.
            </div>
          </div>
        </div>
        <div className="quotes_block">
          <div className="quotes_container">
            <RiDoubleQuotesL className="quotes_left"></RiDoubleQuotesL>
            <div className="quotes">
              We comprehend the depth of your sentiments. Preserve them on
              Amrityum.
            </div>
            <div className="quote_author">Celine Mathew, Director</div>
            <RiDoubleQuotesR className="quotes_right"></RiDoubleQuotesR>
          </div>
          <div
            className="image_container"
            style={{ backgroundImage: "url('" + quote + "')" }}
          ></div>
        </div>
        <div className="video_block block">
          <div className="image_details_grid">
            <div className="image_container">
              <div className="image" />
              <div className="show_video_button">
                <i className="fa fa-play"></i>
              </div>
            </div>
            <div className="details_grid_item">
              <div className="title">At Amrityum</div>
              <div className="heading">Keep all precious memories forever</div>
              <p>
                Family is a thread of love and a pillar of strength that keeps
                one going. Amrityum secures the bond between families and
                friends whether you share their lives here now or long after
                they are not in the world. The oath of closeness remains intact
                for generations to come. Remembering and celebrating their
                illustrious lives shaped the concept behind Amrityum.
              </p>
              We present this fully interactive and engaging memorial website,
              Amrityum, to individuals, families, and communities with the hope
              that this platform will offer you much-needed solace and comfort
              in preserving treasured memories.
              <p></p>
              <ButtonTheme2
                label="View video"
                onClick={() => $(".show_video_button").trigger("click")}
              ></ButtonTheme2>
            </div>
          </div>
        </div>
        {/* <div className="intro_block">
          <div className="image_details_grid">
            <div className="image" style={{backgroundImage:"url('"+about_us+"')"}}></div>
            <div className="intro_details">
              <div className="about_amrityum">ABOUT AMRITYUM</div>
              <div className="intro_title">Suitable For Any Crative Agency</div>
              <div className="intro_content">
                <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                 Suscipit eum illum tempora? Ducimus eum culpa. Integer 
                 lobortis sem consequat consequat imperdiet. In nulla sed
                  viverra ut lorem ut, dapibus consectetur diam. Nunc 
                  bibendum imperdiet condimentum duis lacinia pulvinar.
                </p>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                 Suscipit eum illum tempora? Ducimus eum culpa. Integer 
                 lobortis sem consequat consequat imperdiet. 
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="our_services_block">
          <div className="services_title">
            <span>Services</span> we're proud of
          </div>
          <div className="services_grid">
            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/DeathNotices"
                  )
                }>
              <div className="single_service_title">Death Notices</div>
              <div className="single_service_content">
                Amrityum provides you with the perfect space to publish
                obituaries, death notices and funeral information quickly and
                efficiently. The visitors may write condolence messages, place
                virtual mementoes and much more. Thus, family and friends have
                the support of each other to sail through the difficult time of
                their lives.
              </div>
            </div>
            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/Memorials"
                  )
                }>
              <div className="single_service_title">Memorials</div>
              <div className="single_service_content">
                The Internet seems to be a witty choice for creating memorials,
                and Amrityum is the most reliable platform. Everyone can come
                together and contribute their share of stories. Together we
                build an ensemble of life stories for generations to imbibe and
                learn nurturing family bonds and togetherness.
              </div>
            </div>
            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/InMemoryNotices"
                  )
                }>
              <div className="single_service_title">In Memory Notices</div>
              <div className="single_service_content">
                Remembering the deceased on their death anniversary and other
                significant occasions denotes your share of tribute. Hence,
                Amrityum provides a convenient and streamlined space to make all
                family announcements. We endeavour to keep individuals’
                reminiscences fresh just the way one likes.
              </div>
            </div>
            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/"
                  )
                }>
              <div className="single_service_title">Life Events</div>
              <div className="single_service_content">
                We not only offer a stage to cherish the precious moments spent
                with those who are not in this world but also the living. One
                can relish people on their graduation, marriages, anniversaries,
                and other life events. Make them feel distinct and loved by
                generating wishes online.
              </div>
            </div>
            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/ComingSoon"
                  )
                }>
              <div className="single_service_title">Family Tree</div>
              <div className="single_service_content">
                Discover your family history with a click. You can add family
                members and branches that you know. Different people can
                contribute and update the family tree. Build a family tree here
                to help future generations to connect and collaborate.
              </div>
            </div>
            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/"
                  )
                }>
              <div className="single_service_title">Life Stories</div>
              <div className="single_service_content">
                In Life Stories, you will be able to arrange together a journal
                of your life, telling the world your story the way you want the
                world to know you. Be proud of who you are and define how you
                want to be remembered, even long after you are gone.
              </div>
            </div>

            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/PetTributes"
                  )
                }>
              <div className="single_service_title">Pet Tribute</div>
              <div className="single_service_content">
                We give great importance to human-pet bonds. Create everlasting
                memorials for your beloved pets. You can remember them on
                Amrityum and celebrate their existence in your life by sharing
                stories and preserving photographs. Let us travel together,
                overcoming the grief of losing your pet.
              </div>
            </div>
            <div className="single_service_container" onClick={() =>
                  this.props.history.push(
                    "/Services"
                  )
                }>
              <div className="single_service_title">Services Directory</div>
              <div className="single_service_content">
                Our Service Directory brings together a host of diverse
                businesses under one roof to give them the necessary visibility
                and easy connectedness to their customers. Our database provides
                relevant businesses opportunities to register their businesses,
                describe their niche, list their resources, and provide contact
                information.
              </div>
            </div>
          </div>
        </div>
        <div className="video_container_ghost">
          <div className="video_container">
            <iframe
              id="iframeid"
              src="https://www.youtube.com/embed/85NeaYn-m_M"
              title="Amrityum: About Us"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <i className="fa fa-times video_close_button"></i>
          </div>
        </div>
        <div className="final_container">
          <div className="image_block">
            <div
              className="image_item1"
              style={{ backgroundImage: "url('" + about_image + "')" }}
            ></div>
            <div
              className="image_item2"
              style={{ backgroundImage: "url('" + about_image2 + "')" }}
            ></div>
          </div>
          <div className="title">It all starts here!</div>
          <div className="contents_block">
            <div className="main_contents">
              Create a final goodbye that is remembered forever. Take the first
              step to collect, cherish, preserve and share memories by creating
              an online memorial of the people you have lost. <br /><br/>
              Amrityum helps families extraordinarily honour life and celebrate
              goodbyes, by offering a platform to honour the life of your loved
              one.
              <br />
              <br />
              We listen closely to your <Link className="feedback" to="/Feedback">
              feedback</Link> and remain committed to
              continually improving our services.
            </div>
            <div className="create-button-container">
            {this.context.session ?
              <ButtonTheme2
                label="SIGN UP"
                className="create-button"
                onClick={() => this.props.history.push("/Dashboard/")}
              />
              :this.context.session.user_type == "admin"?
              <ButtonTheme2
              label="SIGN UP"
              className="create-button"
              onClick={() => this.props.history.push("/Dashboard/")}
            />
            :<ButtonTheme2
            label="SIGN UP"
            className="create-button"
            onClick={() => this.props.history.push("/Register")}
          />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs
AboutUs.contextType = MyContext;
