import React from "react";
import "./FamilyNotices.scss";
import $ from "jquery";
import { Link } from 'react-router-dom';
import MyContext from "../MyContext";
import {FaUserCircle, FaTh,FaList, FaAngleRight} from "react-icons/fa";
import {ImUser,ImClock} from "react-icons/im";
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import title_image from "../../res/images/home/slider2.jpg";
import about_us from "../../res/images/family_notices/intro.jpg";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { InputTheme6 } from "../../res/styler/InputThemes/InputThemes";
import default_image from "../../res/images/user_icon.png";
import ReactPaginate from 'react-paginate';
import NoSearchResults from "../NoSearchResults/NoSearchResults";

class FamilyNotices extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      family_notices : [],
      search_input : "",
      notice_types : [],
      states : [],
      countries : [],
      search_parameters : {
        first_name : "",
        locality : "",
        notice_type : 0,
        surname : "",
        city : "",
        state : "",
        country : "",
        middle_name : "",
        family_or_house_name : "",
        nick_name : "",
        nee : "",
        from_date : "",
        to_date : "",
        fromdate : "",
        todate : ""
      },
      offset_value : 0,
      current_page : 0,
      per_page : 16,
      page_count : 0,
      page_no_input : 1
    }
    this.form_change = this.form_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.country_change = this.country_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.reset_search_form = this.reset_search_form.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.family_notices_XHR = {abort : () => {}}
    this.family_notices_page_Ref = React.createRef();
    this.search_block_ref = React.createRef();
    this.family_notices_block_ref = React.createRef();
    this.handle_page_click = this.handle_page_click.bind(this);
    this.notice_type_change = this.notice_type_change.bind(this);
    this.month_names = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.offset_value = 0;
  }

  componentDidMount(){
    $(".header_container").addClass("transparent_header");
    if(this.props.history.location.state && typeof this.props.history.location.state == "object"){
      this.setState(this.props.history.location.state,() => {
        this.family_notices_page_Ref.current.style.visibility = "visible";
        this.family_notices_page_Ref.current.style.opacity = 1;
      });
    }else{ 
      var search_parameters = this.props.location.search_parameters;
      if(search_parameters){
        search_parameters = JSON.parse(search_parameters);
        search_parameters.notice_type = 0;
        var obj_this = this;
        this.setState({
          search_parameters : search_parameters
        },() => {
          this.context.loading(1);
          this.getFamilyNotices();
        });
      }else{
        this.context.loading(1);
        this.getFamilyNotices(() => {
          window.scrollTo({
            top : this.family_notices_page_Ref.current.offsetTop - 80,
            behavior : "smooth"
          });
        });
      }
    }
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    });
  }

  page_no_form_submit(e){
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getFamilyNotices(() => {
        window.scrollTo({
          top : this.family_notices_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  handle_page_click(e){
    this.context.loading(1);
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getFamilyNotices(() => {
        window.scrollTo({
          top : this.family_notices_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  getFamilyNotices(callback){
    var obj_this = this;
    console.log(this.state.offset_value)
    $.ajax({
      url : process.env.REACT_APP_API + "/api/familyNotices",
      data :{
        offset_value : this.state.offset_value,
        search_parameters : JSON.stringify(this.state.search_parameters)
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      obj_this.setState({
        family_notices : return_data.family_notices,
        countries : return_data.countries,
        notice_types : return_data.notice_types,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
      },() => {
        obj_this.context.loading(1);
        if(obj_this.props.location.search_parameters){
          window.scrollTo({
            top : obj_this.family_notices_block_ref.current.offsetTop - 80,
            behavior : "smooth"
          });
        }
        if(callback){
          callback();
        }
        obj_this.context.loading(0);
        obj_this.family_notices_page_Ref.current.style.visibility = "visible";
        obj_this.family_notices_page_Ref.current.style.opacity = 1;
      });
      obj_this.context.setHistoryState({
        family_notices : return_data.family_notices,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
        offset_value : obj_this.state.offset_value,
        current_page : obj_this.state.current_page,
        page_no_input : obj_this.state.page_no_input,
        search_parameters : obj_this.state.search_parameters
      },obj_this.props.history);
      obj_this.context.loading(0);
    }).fail(function (jqXHR, textStatus) {
      if (textStatus != "abort") {
        alert("Network error");
      } 
    });
  }

  notice_type_change(e){
    this.state.offset_value = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters['notice_type'] = e.target.value;
    this.setState({search_parameters});
  }

  show_hide_advanced_form(){
    if($(".advanced_search_inputs_container").height() == 0){
      $(".advanced_search_inputs_container").heightAuto();
      $(".show_advanced_search_button").find(".icon").html("-");
      $(".show_advanced_search_button").find(".label").html("Hide");
    }else{
      $(".advanced_search_inputs_container").css("height",0);
      $(".show_advanced_search_button").find(".icon").html("+");
      $(".show_advanced_search_button").find(".label").html("Show");
    }
  }

  form_change(e){
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters[e.target.name] = e.target.value;
    this.setState({search_parameters});
  }

  country_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: { country: e.target.value},
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          states : return_data.states,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    const search_parameters = this.state.search_parameters;
    search_parameters['country'] = e.target.value;
    search_parameters['state'] = "";
    this.setState({search_parameters});
  }

  state_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters['state'] = e.target.value;
    this.setState({search_parameters});
  }

  form_submit(e){
    e.preventDefault();
    this.context.loading(1);
    const search_parameters = this.state.search_parameters;
    console.log(search_parameters);
    search_parameters['fromdate'] = search_parameters.from_date?search_parameters.from_date.toLocaleDateString():"";
    search_parameters['todate'] = search_parameters.to_date?search_parameters.to_date.toLocaleDateString():"";
    if(this.state.offset_value == 0 ){
      this.setState({
        // family_notices : [],
        search_parameters : search_parameters,
        page_no_input : 1
      },() => {
        this.getFamilyNotices(() => {
          window.scrollTo({
            top : this.family_notices_block_ref.current.offsetTop - 80,
            behavior : "smooth"
          });
        });
      });
    }
   
  }

  reset_search_form(callback = ""){
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = {
      first_name : "",
      locality : "",
      notice_type : 0,
      surname : "",
      city : "",
      state : "",
      country : "",
      middle_name : "",
      family_or_house_name : "",
      nick_name : "",
      nee : "",
      from_date : null,
      to_date : null,
      fromdate : "",
      todate : ""
    };
    this.setState({
      search_parameters : search_parameters,
      family_notices : [],
      page_no_input : 1
    },() => {
      // $(".input_theme6_container input").trigger("focus");
      // $(".input_theme6_container input:last").trigger("blur");
      this.getFamilyNotices(() => {
        if(typeof callback == "function"){
          callback();
        }
      });
    })
  }
  
  componentWillUnmount(){
    $(".header_container").removeClass("transparent_header")
  }
  render(){
    return(
      <div className="familynotice_page_container" ref={this.family_notices_page_Ref}>
        <div className="title_block" style={{backgroundImage:"url('"+title_image+"')"}}>
          <div className="title_buttons_container no_select">
            <div className="page_title">In Memory Notices</div>
            <div className="title_content">  Enliven the fond memories of our departed loved ones on their remembrances.</div>
            <div className="buttons_container">
              <div className="notice_button_theme1" onClick={() =>{
                if(!this.context.session){
                  this.context.confirm("Sign in to publish an In-Memory Notice",() => {
                    this.props.history.push({ pathname: "/Login", url : "/Dashboard/InMemoryNotices/New"})
                  });
                  return;
                }else{
                  this.props.history.push("/Dashboard/InMemoryNotices/New");
                }
              }}>Publish an in-memory notice</div>
              <div className="notice_button_theme1 search_button1" onClick={() => 
                { window.scrollTo({
                    top : this.search_block_ref.current.offsetTop - 80,
                    behavior : "smooth"
                  });
                }}
              >Search in-memory notices</div>
              <div className="notice_button_theme1"  onClick={() => {
                this.reset_search_form(() => {
                  window.scrollTo({
                    top : this.family_notices_block_ref.current.offsetTop - 80,
                    behavior : "smooth"
                  });
                });
              }}>View all in-memory notices</div>
            </div>
          </div>
        </div>
        <div className="intro_block block">
          <div className="image_details_grid">
            <div className="image_container slide_from_left">
              <img src={about_us} />
            </div>
            <div className="details_grid_item">
              <div className="title">In memory notices</div>
              <div className="heading">Create In Loving Memory Notices</div>
              <div className="border_bottom"></div>
              <p>
                Remembering the deceased on their death anniversary and other significant occasions denotes your share of tribute. Hence, Amrityum provides you with space to announce death anniversaries, remembrances, a convenient and streamlined space to publish thank you notes after a funeral or other celebrations, information on memorial gatherings and more. We endeavour to keep individuals’ reminiscences fresh just the way one likes.
              </p>
             
            </div>
          </div>
        </div>
        <div className="search_block" ref={this.search_block_ref}>
          <div className="search_block_ghost"></div>
          <div className="search_form_container">
            <div className="title">Search</div>
            <div className="heading">Have trouble in finding Notices?</div>
            <form className="search_form" onSubmit={this.form_submit} method="post">
              <div className="form_grid">
                <div className="input_container">
                  {/* <div className="title1">First Name</div> */}
                  <InputTheme6 
                    label="First Name"
                    className="search_input"
                    attrs={{
                      type : "text",
                      name : "first_name",
                      value : this.state.search_parameters.first_name,
                      onChange : this.form_change
                    }}
                  />
                </div>
                <div className="input_container">
                  {/* <div className="title1">Surname</div> */}
                  <InputTheme6 
                    label="Surname"
                    className="search_input"
                    attrs={{
                      type : "text",
                      name : "surname",
                      value : this.state.search_parameters.surname,
                      onChange : this.form_change
                    }}
                  />
                </div>
                <div className="input_container">
                  {/* <div className="title1">NOTICE TYPE</div> */}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Notice type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.search_parameters.notice_type}
                      label=""
                      onChange={this.notice_type_change}
                    >
                      <MenuItem value="0" >All Notices</MenuItem>
                      {this.state.notice_types && this.state.notice_types.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.notice_type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                
              </div>
              <button className="show_advanced_search_button" 
                onClick={this.show_hide_advanced_form}
                type="button"
              >
                <div className="button_ghost"></div>
                {/* <span className="label">Show</span>&nbsp; */}
                Advanced Search&nbsp;
                <span className="icon">+</span>
              </button>
              <div className="advanced_search_inputs_container">
                <div className="advanced_search_inputs"><div className="input_container">
                  {/* <div className="title1">Locality</div> */}
                  <InputTheme6 
                    label="Locality"
                    className="search_input"
                    attrs={{
                      type : "text",
                      name : "locality",
                      value : this.state.search_parameters.locality,
                      onChange : this.form_change
                    }}
                  />
                </div>
                  <div className="input_container">
                    {/* <div className="title1">Middle Name</div> */}
                    <InputTheme6 
                      label="Middle Name"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "middle_name",
                        value : this.state.search_parameters.middle_name,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">House/Family Name</div> */}
                    <InputTheme6 
                      label="House/Family Name"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "family_or_house_name",
                        value : this.state.search_parameters.family_or_house_name,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">Nick Name</div> */}
                    <InputTheme6 
                      label="Nick Name"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "nick_name",
                        value : this.state.search_parameters.nick_name,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">Nee</div> */}
                    <InputTheme6 
                      label="Née"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "nee",
                        value : this.state.search_parameters.nee,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  <div className="input_container">
                  {/* <div className="title1">city</div> */}
                    <FormControl fullWidth style={{width:"100%"}}>
                      <InputLabel id="demo-simple-select-label">Country</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.search_parameters.country}
                        label="Country"
                        onChange={this.country_change}

                        style={{width:"100%"}}
                      >
                        {this.state.countries && this.state.countries.map((item,index) => (
                          <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  
                  <div className="input_container">
                    {/* <div className="title1">STATE</div> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">State/Province/County/Region</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={
                          this.state.search_parameters.country ? false : true
                        }
                        value={this.state.search_parameters.state}
                        label="State/Province/County/Region"
                        onChange={this.state_change}
                      >
                        {this.state.states && this.state.states.map((item, index) => (
                          <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">Nee</div> */}
                    <InputTheme6 
                      label="City/Town"
                      className="search_input"
                      attrs={{
                        type : "text",
                        name : "city",
                        value : this.state.search_parameters.city,
                        onChange : this.form_change
                      }}
                    />
                  </div>
                  
                  <div className="input_container">
                    {/* <div className="title1">FROM DATE</div> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DatePicker
                        label="From date"
                        value={this.state.search_parameters.from_date}
                        onChange={(newValue) => {
                          this.state.offset_value = 0;
                          this.state.current_page = 0;
                          const search_parameters = this.state.search_parameters;
                          search_parameters['from_date'] = newValue;
                          this.setState({ search_parameters});
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        maxDate={new Date()}
                        required={true}
                        openTo="year"
                        views={["year", "month", "day"]}
                        inputFormat="dd/MM/yyyy"
                        allowSameDateSelection={true}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">TO DATE</div> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DatePicker
                        label="To date"
                        value={this.state.search_parameters.to_date}
                        onChange={(newValue) => {
                          this.state.offset_value = 0;
                          this.state.current_page = 0;
                          const search_parameters = this.state.search_parameters;
                          search_parameters['to_date'] = newValue;
                          this.setState({ search_parameters});
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={this.state.search_parameters.from_date?new Date(this.state.search_parameters.from_date):undefined}
                        maxDate={new Date()}
                        required={true}
                        openTo="year"
                        views={["year", "month", "day"]}
                        inputFormat="dd/MM/yyyy"
                        allowSameDateSelection={true}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="buttons_container">
                <ButtonTheme2 label="Find Notices" className="search_button"></ButtonTheme2>
                <ButtonTheme2
                  label="Reset"
                  type="button"
                  className="reset_form_button"
                  onClick={this.reset_search_form}
                ></ButtonTheme2>
              </div>
            </form>
          </div>
        </div>
        <div className="family_notices_block block" ref={this.family_notices_block_ref}>
          <div className="toggle_container">
            <div className="heading" style={{visibility:this.props.location.search_parameters?"visible":"hidden"}}>In Memory Notices</div>
            <div className="notices_view_type_button_container">
              <div className="image_view_button selected_view_type"
                onClick={() => {
                  $(".image_view_button").addClass("selected_view_type")
                      $(".text_view_button").removeClass("selected_view_type")
                  $(".family_notices_grid").css("display","grid");
                  $(".table_container").css("display","none");
              }}
              ><FaTh /></div>
              <div className="text_view_button"
                onClick={() => {
                  $(".image_view_button").removeClass("selected_view_type")
                      $(".text_view_button").addClass("selected_view_type")
                  $(".family_notices_grid").css("display","none");
                  $(".table_container").css("display","block");
                }}
              ><FaList /></div>
            </div>
          </div>
          <div className="family_notices_grid" >
            {this.state.family_notices && this.state.family_notices.map((item,idx) => (
              <div className="single_family_notice" key={idx}>
                <div className="notice_image_container">
                {item.image_src1?
                  <div className="notice_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/family_notice_images/"+item.image_src1+"')"}}></div>
                  :
                  <div className="notice_image" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
                  }

                </div>
                <div className="notice_details">
                  <div className="notice_published_grid">
                    <ImUser className="published_icon"/>
                    <div className="published_by">{item.published_by} </div>
                    <ImClock className="published_icon"/>
                    <div className="published_at">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')} </div>
                  </div>
                  <div className="notice_type">{item.notice_type}</div>
                  <div className="person_name">{item.first_name} {item.surname}</div>
                  {/* <div className="date_of_event">{new Date(item.notice_date).toLocaleDateString('en-GB')}</div> */}
                </div>
                <Link className="notice_details_ghost" to={"/InMemoryNotices/"+item.id}>
                  <div className="name">{item.first_name} {item.surname} </div>
                  {isNaN(item.age)?
                  <div className="lifespan">({item.age})</div>
                  :
                  <div className="lifespan">({new Date(item.date_of_birth).getFullYear()} - {new Date(item.date_of_death).getFullYear()})</div>
                  }
                  <div className="address">{item.family_or_house_name?item.family_or_house_name+", ":""}{item.locality}{item.city?", ":""}{item.city}{item.state?", ":""}{item.state}</div>
                  {/* <div className="date_of_event">{new Date(item.notice_date).getDate()}-{new Date(item.notice_date).getMonth()+1}-{new Date(item.notice_date).getFullYear()}</div> */}
                </Link>
              </div>
            ))}            
          </div>
          <div className="table_container">
            <div className="custom_table_theme2" style={{display:this.state.family_notices.length?"table":"none"}}>
              <div className="table_row header">
                <div className="table_cell sl_no">Sl No.</div>
                <div className="table_cell"></div>
                <div className="table_cell fullname">Name</div>
                <div className="table_cell">Locality</div>
                <div className="table_cell">State</div>
                <div className="table_cell">Country</div>
                <div className="table_cell">Notice Type</div>
                <div className="table_cell">Published By</div>
              </div>
              {this.state.family_notices &&
              this.state.family_notices.map((item, idx) => (
              <Link
                className="table_row body"
                key={idx}
                to={"/InMemoryNotices/"+ item.id}
              >
                <div className="table_cell sl_no">{this.state.offset_value+idx+1}</div>
                <div className="table_cell">
                  {item.image_src1?
                  <div className="person_image" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/family_notice_images/"+item.image_src1+"')"}}></div>
                  :
                  <div className="person_image" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
                  }
                </div>
                <div className="table_cell fullname">
                  {item.first_name} {item.surname}
                  {isNaN(item.age)?
                  <div className="lifespan">({item.age})</div>
                  :
                  <div className="lifespan">({new Date(item.date_of_birth).getFullYear()} - {new Date(item.date_of_death).getFullYear()})</div>
                  }
                  </div>
                <div className="table_cell">{item.locality}</div>
                <div className="table_cell">{item.state}</div>
                <div className="table_cell">{item.country}</div>
                <div className="table_cell">{item.notice_type}</div>
                <div className="table_cell">{item.published_by}</div>
              </Link>
              ))}
            </div>
          </div>
          {
            this.state.family_notices.length?"":
            <NoSearchResults/>
          }
          <div className="pagination_container" style={{display:this.state.family_notices.length && this.state.page_count > 1?"block":"none"}}>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handle_page_click}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.current_page}
            />
            <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
              <span className="page_count">Go to page</span>
              <input className="page_no_input"
                type="number"
                name="page_count"
                value={this.state.page_no_input}
                onChange={this.page_no_change}
                min={1}
                max={this.state.page_count}
              />
              <button type="submit" className="page_no_search_button">
                <span>Go</span>
                <FaAngleRight/>
              </button>
            </form>
          </div>
        </div>
        
        
      </div>
    )
  }
}

export default FamilyNotices;
FamilyNotices.contextType = MyContext;