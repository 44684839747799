import React from "react";
import "./ManageTestimonials.scss";
import {Link} from "react-router-dom";
import $ from "jquery";
import MyContext from "../../MyContext";
import {AddButtonTheme1, ButtonTheme1, ButtonTheme2} from "../../../res/styler/ButtonThemes/ButtonThemes";
import {AiOutlinePlus} from "react-icons/ai";
import { InputTheme4 } from "../../../res/styler/InputThemes/InputThemes";

class ManageTestimonials extends React.Component{
  constructor(props){
    super(props);
    this.state={
      testimonials : [],
      name : "",
      email : "",
      message : ""
    }

    this.manage_testimonials_page_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.delete_testimonial = this.delete_testimonial.bind(this);
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/getTestimonials",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
      console.log(return_data.testimonials);
      if(return_data.status == "success"){
        obj_this.setState({
          testimonials : return_data.testimonials,
        },() => {
          obj_this.context.loading(0);
          obj_this.manage_testimonials_page_ref.current.style.visibility = "visible";
          obj_this.manage_testimonials_page_ref.current.style.opacity = 1;
        });
      }else{
        obj_this.context.loading(0);
        alert("Network error");
      }
    }).fail(function(){
      alert("Network error");
    })
  }

  form_change(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  form_submit(e){
    e.preventDefault();
    console.log(this.state);
    var obj_this = this;
    this.context.loading(1);
    const testimonial_data = {
      name : this.state.name,
      email : this.state.email,
      message : this.state.message
    }
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveTestimonial",
      data: this.context.bind_session({
        "testimonial_data" : JSON.stringify(testimonial_data)
      }),
      type: "POST",
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        obj_this.setState({
          testimonials : return_data.testimonials,
          name : "",
          email : "",
          message : ""
        });
        obj_this.context.loading(0);
        $(".testimonial_form_container").css("display","none");
        alert("Testimonial inserted");
      }else{
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  delete_testimonial(id){
    if (!window.confirm("Delete this testimonial")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteTestimonial",
      data: this.context.bind_session({ testimonial_id: id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          testimonials : return_data.testimonials
        });
        alert("Testimonial deleted");
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="manage_testimonials_page_container page_container" ref={this.manage_testimonials_page_ref}>
      <div className="page_header">
        <div className="page_title"> Manage Testimonials</div>
         <a style={{textAlign:"right"}} onClick={() => $(".testimonial_form_container").css("display","block")} >
              <AddButtonTheme1 label="Publish a Testimonial" />
            </a>
      </div>
    
      <div className="testimonials_grid">
        {this.state.testimonials && this.state.testimonials.map((item,idx) => (
          <div className="single_testimonial_container" key={idx}>
          <div className="message">{item.message}</div>
          <div className="name">{item.name}</div>
          <div className="name">{item.email}</div>
          <div className="published_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
          <div className="delete_testimonial_button"
            onClick={() => this.delete_testimonial(item.id)}>
            <i className="fa fa-times icon" ></i>
          </div>
        </div>
        ))}
      </div>
      <div className="testimonial_form_container" onClick={()=>$(".testimonial_form_container").css("display","none")}>
        <form className="testimonial_form"
          onClick={(e) => e.stopPropagation()}
          onSubmit={this.form_submit}>
          <div className="block_title">New Testimonial </div>
          <InputTheme4
            label="Name"
            attrs={{
              name: "name",
              type: "text",
              value: this.state.name,
              onChange: this.form_change,
              required: true
            }}
          />
          <InputTheme4
            label="Email"
            attrs={{
              name: "email",
              type: "email",
              value: this.state.email,
              onChange: this.form_change,
              required : true,
              autoComplete : "off"
            }}
          />
          <textarea
            name="message"
            value={this.state.message}
            placeholder="Message (max 150 words)"
            onChange={this.form_change}
            rows={5}
            required={true}
          />
          <div className="close_button" onClick={() => $(".testimonial_form_container").css("display","none")}>
            <AiOutlinePlus className="close_icon"></AiOutlinePlus>
          </div>
          <button className="submit_button" type="submit">Submit</button>
        </form>
      </div>
    </div>
    )
  }
}

export default ManageTestimonials;
ManageTestimonials.contextType = MyContext;