import React from "react";
import "./Blogs.scss";
import {Link} from "react-router-dom";
import $ from "jquery";
import MyContext from "../MyContext";
import ReactPaginate from 'react-paginate';
import { FaAngleRight } from "react-icons/fa";

class Blogs extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      blogs : [],
      offset_value : 0,
      current_page : 0,
      per_page : 9,
      page_count : 0,
      page_no_input : 1
    }
    this.handle_page_click = this.handle_page_click.bind(this);
    this.getBlogs = this.getBlogs.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.blogs_page_ref = React.createRef();
  }

  componentDidMount(){
    if(this.props.history.location.state && typeof this.props.history.location.state == "object"){
      this.setState(this.props.history.location.state);
    }else{ 
      this.getBlogs(() => {
        window.scrollTo({
          top : this.blogs_page_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      });
    }

    var obj_this = this;
    $(".blogs_refresh").on("click",() => {
      console.log("sdlfsdlfj")
      this.state.offset_value = 0;
      this.state.current_page = 0;
      obj_this.setState(
        { 
          offset_value : 0,
          page_no_input : 1,
          blogs : []
        },
        () => {
          obj_this.getBlogs();
        }
      );
    });
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    });
  }

  page_no_form_submit(e){
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getBlogs(() => {
        window.scrollTo({
          top : this.blogs_page_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  handle_page_click(e){
    this.context.loading(1);
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getBlogs(() => {
        window.scrollTo({
          top : this.blogs_page_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }
  
  getBlogs(callback){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/getBlogs",
      type : "POST",
      data : {
        offset_value : this.state.offset_value
      }
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        blogs : return_data.blogs,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
      },() => {
        if(callback){
          callback();
        }
      });
      obj_this.context.setHistoryState({
        blogs : return_data.blogs,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
        offset_value : obj_this.state.offset_value,
        current_page : obj_this.state.current_page,
        page_no_input : obj_this.state.page_no_input,
      },obj_this.props.history);
     }else{
       alert("Network error");
     }
     
     obj_this.context.loading(0);
    }).fail(function(){
      alert("Network error");
    })
  }

  render(){
    return(
      <div className="blogs_page_container page_container" ref={this.blogs_page_ref}>
        <div className="blogs_title">Amrityum Blogs</div>
        <div className="blogs_grid">
          {this.state.blogs && this.state.blogs.map((item,idx) => (
          <Link className="single_blog_container" to={"/Blogs/"+item.id} key={idx}>
            <div className="image_container" style={{backgroundImage:item.image_src?"url('"+process.env.REACT_APP_CDN_URL+"/blog_images/"+item.image_src+"')":""}}></div>
            <div className="heading">{item.title}</div>
            {/* <div className="content">
              Our loved ones are always in our hearts and prayers. However, 
              sometimes we feel like going this extra mile to honour their legacies..
            </div> */}
            <div className="read_more_button">Read full story..</div>
            <div className="published_details">
              <span>By: {item.entered_by} </span>
              <span>|</span>
              <span> On: {new Date(item.published_date).toLocaleString(new Date().getTimezoneOffset(),{
                 day: 'numeric', month: 'long', year: 'numeric'
                })}
              </span>
              <span className="remove_in_mobile">|</span>
              <span className="remove_in_mobile"> 5 Minutes Read</span>
            </div>
          </Link>  
          ))}
          
        </div>
        <div className="pagination_container" style={{display:this.state.blogs.length && this.state.page_count > 1?"block":"none"}}>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handle_page_click}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.current_page}
          />
          <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button">
              <span>Go</span> <FaAngleRight/></button>
          </form>
        </div>
      </div>
    )
  }
}

export default Blogs;
Blogs.contextType = MyContext;