import React from "react";
import "./FamilyNoticeApprovalList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AddButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {Link} from "react-router-dom";
import {FcApproval, FcFullTrash} from 'react-icons/fc';

class FamilyNoticeApprovalList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      family_notices : [],
      comments : []
    }
    this.family_notice_approval_list_page_Ref = React.createRef();
    this.delete_notice_comment = this.delete_notice_comment.bind(this);
    this.approve_notice_comment = this.approve_notice_comment.bind(this);
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/pendingApprovalFamilyNotices",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        family_notices : return_data.family_notices,
        comments : return_data.comments,
      },() => {
        obj_this.context.loading(0);
        obj_this.family_notice_approval_list_page_Ref.current.style.visibility = "visible";
        obj_this.family_notice_approval_list_page_Ref.current.style.opacity = 1;
      });
     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
  }

  delete_notice_comment(comment_id){
    if(!window.confirm("Delete this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteFamilyNoticeComment",
      data: this.context.bind_session({ comment_id: comment_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          comments : return_data.comments,
        });
        alert("Comment deleted");
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  approve_notice_comment(comment_id){
    if (!window.confirm("Approve this comment")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveFamilyNoticeComment",
      data: this.context.bind_session({ comment_id: comment_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          comments : return_data.comments,
        });
        alert("Comment approved");
      } else {
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }
  render(){
    return(
      <div className="family_notice_approval_list_page_container page_container" ref={this.family_notice_approval_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Pending Approval</div>
          <div className="new_family_notice_button_container">
            {/* <Link to="/Dashboard/InMemoryNotices/New">
              <AddButtonTheme1 label="Publish In Memory Notice"/>
            </Link> */}
          </div>
        </div>
        {/* <div className="custom_table_theme1">
          <div className="table_row header">
            <div className="table_cell sl_no">Sl no.</div>
            <div className="table_cell notice_type">Notice Type</div>
            <div className="table_cell fullname">First name</div>
            <div className="table_cell notice_type">Surname</div>
            <div className="table_cell">House/Family Name</div>
            <div className="table_cell">Locality</div>
            <div className="table_cell">Published Date</div>
            <div className="table_cell">Status</div>
          </div>
          {this.state.filtered_notices && this.state.filtered_notices.map((item,idx) => (
            <div className="table_row body"
              style={{backgroundColor:item.status == "PENDING"?"#ebf8a4":item.status == "REJECTED"?"#ffc0cb":""}}
              key={idx} 
              onClick={() => this.props.history.push("/Dashboard/ViewFamilyNotice/"+item.id)}>
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell notice_type">{item.notice_type}</div>
              <div className="table_cell">{item.first_name}</div>
              <div className="table_cell">{item.surname}</div>
              <div className="table_cell">{item.family_or_house_name}</div>
              <div className="table_cell">{item.locality}</div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">{item.status}</div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.filtered_notices.length?"none":"block"}}>No results.</div>
        </div> */}
        <div style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
          <div className="table_title">In Memory Notices</div>
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no">Sl No.</div>
              <div className="table_cell ">Notice Type</div>
              <div className="table_cell fullname">First Name</div>
              <div className="table_cell notice_type">Surname</div>
              <div className="table_cell datetime">Locality</div>
              <div className="table_cell datetime">State</div>
              <div className="table_cell">Entered By</div>
              <div className="table_cell datetime">Published Date</div>
            </div>
            {this.state.family_notices && this.state.family_notices.map((item,idx) => (
              <Link className="table_row body"
                key={idx} 
                to={{pathname:"/InMemoryNotice/Preview/" + item.id, url :"/Dashboard/InMemoryNotices/Approvals"}}>
                <div className="table_cell sl_no">{idx+1}</div>
                <div className="table_cell notice_type">{item.notice_type}</div>
                <div className="table_cell">{item.first_name}</div>
                <div className="table_cell">{item.surname}</div>
                <div className="table_cell">{item.locality}</div>
                <div className="table_cell">{item.flag} {item.state}</div>
                <div className="table_cell">{item.fullname}</div>
                <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              </Link>
            ))}
            <div className="table_row no_results" style={{display:this.state.family_notices.length?"none":"block"}}>No results.</div>
          </div>
        </div>
        <div className="table_title">Comments</div>
        <div className="custom_table_theme1">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">In Memory Notice</div>
            <div className="table_cell">Comment</div>
            <div className="table_cell fullname">Entered By</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.comments && this.state.comments.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.comment}
              </div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{display:item.status=="PENDING"?"inline-flex":"none"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approve_notice_comment(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_notice_comment(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.comments.length?"none":"block"}}>No results.</div>
        </div>
      </div>
    )
  }
}

export default FamilyNoticeApprovalList;
FamilyNoticeApprovalList.contextType = MyContext;