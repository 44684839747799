import React from "react";
import "./MemorialReportedList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AddButtonTheme1, ButtonTheme3 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {Link} from "react-router-dom";
import {FcApproval, FcFullTrash} from 'react-icons/fc';
import memories from "../../../res/images/memorials/default_memories_01.jpg";
import { FaInfoCircle } from "react-icons/fa";

class MemorialReportedList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      reported_memorials : [],
      reported_memories : [],
      reported_answers : [],
      reported_condolences : [],
      reported_reason : "",
      deleted_reason : "",
      memorial_id : "",
    }
    this.memorial_reported_page_Ref = React.createRef();
    this.delete_reason_container_ref = React.createRef();
    this.delete_reported_notice = this.delete_reported_notice.bind(this);
    this.ignore_reported_notice = this.ignore_reported_notice.bind(this);
    this.delete_reported_memories = this.delete_reported_memories.bind(this);
    this.ignore_reported_memories = this.ignore_reported_memories.bind(this);
    this.delete_reported_condolences = this.delete_reported_condolences.bind(this);
    this.ignore_reported_condolences = this.ignore_reported_condolences.bind(this);
    this.delete_reported_answer = this.delete_reported_answer.bind(this);
    this.ignore_reported_answer = this.ignore_reported_answer.bind(this);
    this.delete_form_submit = this.delete_form_submit.bind(this);
    this.form_change = this.form_change.bind(this);
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/pendingMemorialReportedContents",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        reported_memorials : return_data.reported_memorials,
        reported_memories : return_data.reported_memories,
        reported_condolences : return_data.reported_condolences,
        reported_answers : return_data.reported_answers
      }, () => {
        obj_this.context.loading(0);
        obj_this.memorial_reported_page_Ref.current.style.visibility = "visible";
        obj_this.memorial_reported_page_Ref.current.style.opacity = 1;
      });
     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
  }

  form_change(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  delete_reported_notice(id, reported_reason){
    if(!window.confirm("Are your sure")){
      return;
    }
    this.delete_reason_container_ref.current.style.display = "block";
    this.setState({
      memorial_id : id,
      selected_reported_reason : reported_reason
    });
  }

  delete_form_submit(e){
    e.preventDefault();
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/deleteReportedMemorial",
      data : this.context.bind_session({
        memorial_id : this.state.memorial_id,
        deleted_reason : this.state.deleted_reason,
        selected_reported_reason : this.state.selected_reported_reason
      }),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        obj_this.context.loading(0);
        obj_this.delete_reason_container_ref.current.style.display = "none";
        $.alert("Memorial deleted")
        obj_this.setState({
          reported_memorials : obj_this.state.reported_memorials.filter((item) => item.id != obj_this.state.memorial_id),
          reported_memories : return_data.reported_memories,
          reported_condolences : return_data.reported_condolences,
          reported_answers : return_data.reported_answers,
          deleted_reason : "",
          selected_reported_reason : "",
          memorial_id : ""
        });

      }else{
        alert("Something Went Wrong. Please Try Again.");
      }
     }).fail(function(){
        obj_this.context.loading(0);
       alert("Network error");
     });
  }

  ignore_reported_notice(reported_notice_id){
    if(!window.confirm("Are your sure")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/ignoreReportedMemorial",
      data : this.context.bind_session({reported_notice_id : reported_notice_id}),
      type : "POST"
    }).done(function(return_data){
      if(return_data.status == "success"){
        $.alert("Memorial removed from reported list.")
       obj_this.setState({
        reported_memorials : obj_this.state.reported_memorials.filter((item) => item.reported_notice_id != reported_notice_id),
       });
      }else{
        alert("Something Went Wrong. Please Try Again.");
      }
      obj_this.context.loading(0);
     }).fail(function(){
       alert("Network error");
     });
  }

  delete_reported_memories(memories_id){
    if (!window.confirm("Delete this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteReportedMemorialMemories",
      data: this.context.bind_session({ memories_id: memories_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          reported_memories : obj_this.state.reported_memories.filter((item) => item.id != memories_id),
        });
        alert("Memories and stories deleted");
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  ignore_reported_memories(memories_id){
    if(!window.confirm("Are your sure")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/ignoreReportedMemorialMemories",
      data: this.context.bind_session({ memories_id: memories_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          reported_memories : obj_this.state.reported_memories.filter((item) => item.id != memories_id),
        });
      } else {
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  delete_reported_condolences(condolence_id){
    console.log(condolence_id);
    if (!window.confirm("Delete this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteReportedMemorialCondolences",
      data: this.context.bind_session({ condolence_id: condolence_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          reported_condolences : obj_this.state.reported_condolences.filter((item) => item.id != condolence_id),
        });
        alert("Condolence message deleted");
        // obj_this.props.history.replace('/Dashboard/DeathNotices/Approvals');
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }
  
  ignore_reported_condolences(condolence_id){
    if(!window.confirm("Are your sure")){
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/ignoreReportedMemorialCondolences",
      data: this.context.bind_session({ condolence_id: condolence_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          reported_condolences : obj_this.state.reported_condolences.filter((item) => item.id != condolence_id),
        });
      } else {
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  delete_reported_answer(answer_id){
    if (!window.confirm("Delete this answer")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteReportedMemorialLifeAnswer",
      data: this.context.bind_session({ answer_id: answer_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          reported_answers : obj_this.state.reported_answers.filter((item) => item.id != answer_id),
        });
        alert("Answer deleted");
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  ignore_reported_answer(answer_id){
    if (!window.confirm("Are you sure?")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/ignoreReportedMemorialLifeAnswer",
      data: this.context.bind_session({ answer_id: answer_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          reported_answers : obj_this.state.reported_answers.filter((item) => item.id != answer_id),
        });
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="memorial_reported_list_page_container page_container" ref={this.memorial_reported_page_Ref}>
        <div className="page_header">
          <div className="page_title">Reported Contents</div>
          <div className="new_memorial_button_container">
            {/* <Link to="/Dashboard/Memorials/New">
              <AddButtonTheme1 label="Publish a Memorial"/>
            </Link> */}
          </div>
        </div>
        <div style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
          <div className="table_title">Memorials</div>
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no">Sl No.</div>
              <div className="table_cell fullname">First Name</div>
              <div className="table_cell notice_type">Surname</div>
              <div className="table_cell datetime">Locality</div>
              <div className="table_cell datetime">State</div>
                <div className="table_cell">Reported By</div>
              <div className="table_cell datetime">Reported On</div>
                <div className="table_cell">Action</div>
            </div>
            {this.state.reported_memorials && this.state.reported_memorials.map((item,idx) => (
              <div className="table_row body"
                key={idx}
                >
                <div className="table_cell sl_no">{idx+1}</div>
                <div className="table_cell">{item.first_name}</div>
                <div className="table_cell">{item.surname}</div>
                <div className="table_cell">{item.locality}</div>
                <div className="table_cell">{item.flag} {item.state}</div>
                <div className="table_cell">{item.fullname}</div>
                <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
                <div className="table_cell">
                  <div className="action">
                    <div className="tooltip approve">
                      <span className="tooltipText approve">Ignore</span>
                      <FcApproval className="approve_button" onClick={() => this.ignore_reported_notice(item.reported_notice_id)}/>
                    </div>
                    <div className="tooltip delete">
                      <span className="tooltipText delete">Delete</span>
                      <FcFullTrash className="delete_button" onClick={() => this.delete_reported_notice(item.id, item.reason)}/>
                    </div>
                    <div className="tooltip reason">
                      <span className="tooltipText reason">Reason</span>
                      <FaInfoCircle className="delete_button" onClick={() => this.setState({reported_reason : item.reason})}/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="table_row no_results" style={{display:this.state.reported_memorials.length?"none":"block"}}>No results.</div>
          </div>
        </div>
        <div className="table_title">Memories & Stories</div>
        <div className="custom_table_theme1 memories_table">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Memorial</div>
            <div className="table_cell fullname">Image</div>
            <div className="table_cell">Memories & Stories</div>
            <div className="table_cell fullname">Reported By</div>
            <div className="table_cell datetime">Reported On</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.reported_memories && this.state.reported_memories.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.image_src? <div className="memory_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/tribute_images/"+item.image_src+"')"}}></div>
                : <div className="memory_image" style={{backgroundImage: "url('"+memories+"')"}}></div>
              }
              </div>
              <div className="table_cell" dangerouslySetInnerHTML={{ __html: item.tribute_text }}></div>
              <div className="table_cell">{item.reported_by}</div>
              <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve">
                    <span className="tooltipText approve">Ignore</span>
                    <FcApproval className="approve_button" onClick={() => this.ignore_reported_memories(item.id, item.tribute_type)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_reported_memories(item.id, item.tribute_type )}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.reported_memories.length?"none":"block"}}>No results.</div>
        </div>
        <div className="table_title">Tributes</div>
        <div className="custom_table_theme1 memories_table">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Memorial</div>
            <div className="table_cell">Tributes</div>
            <div className="table_cell fullname">Reported By</div>
            <div className="table_cell datetime">Reported On</div>
            <div className="table_cell">Action</div>
          </div>
          {this.state.reported_condolences && this.state.reported_condolences.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.tribute_text}
              </div>
              <div className="table_cell">{item.reported_by}</div>
              <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve">
                    <span className="tooltipText approve">Ignore</span>
                    <FcApproval className="approve_button" onClick={() => this.ignore_reported_condolences(item.id, item.tribute_type)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_reported_condolences(item.id, item.tribute_type )}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.reported_condolences.length?"none":"block"}}>No results.</div>
        </div>
        <div className="table_title">Let's Reminisce Answers</div>
        <div className="custom_table_theme1">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Memorial</div>
            <div className="table_cell">Answer</div>
            <div className="table_cell fullname">Reported By</div>
            <div className="table_cell datetime">Reported On</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.reported_answers && this.state.reported_answers.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.answer}
              </div>
              <div className="table_cell">{item.reported_by}</div>
              <div className="table_cell">{new Date(item.reported_at).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve">
                    <span className="tooltipText approve">Ignore</span>
                    <FcApproval className="approve_button" onClick={() => this.ignore_reported_answer(item.id, item.tribute_type)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_reported_answer(item.id, item.tribute_type )}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.reported_answers.length?"none":"block"}}>No results.</div>
        </div>
        <div className="view_message_ghost" style={{display:this.state.reported_reason?"block":"none"}}>
          <div className="view_message" 
          >
          <div className="add_message_title">Reported Reason</div>
            <div className="details_grid">
              <div className="label"></div>
              <div className="value">{this.state.reported_reason}</div>
            </div>
            <div className="close_message_form"
              onClick={() => {
                this.setState({
                  reported_reason  : ""
                })
              }}
            >
              <div>x</div>
            </div>
          </div>
        </div>
        <div className="delete_reason_container" ref={this.delete_reason_container_ref}>
          <form className="delete_reason_form" onSubmit={this.delete_form_submit} method="post">
            <div className="delete_reason_title">Delete reason</div>
            <div className="delete_reason_form_body">
              <textarea name="deleted_reason"
                rows={4}
                value={this.state.deleted_reason}
                onChange={this.form_change}
                required
                placeholder="Type here.."
              />
              <button className="delete_form_submit_button">Submit</button>
            </div>
            <div className="delete_form_close_button" onClick={() => { 
              this.delete_reason_container_ref.current.style.display = "none"; 
              this.setState({ 
                deleted_reason : "",
                selected_reported_reason : "",
                memorial_id : "" }) }}>x</div>
          </form>
        </div>
      </div>
    )
  }
}

export default MemorialReportedList;
MemorialReportedList.contextType = MyContext;