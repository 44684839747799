import React from "react";
import "./Services.scss";
import $ from "jquery";
import { Link } from 'react-router-dom';
import MyContext from "../MyContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {IoIosCall} from "react-icons/io";
import {MdHomeRepairService} from "react-icons/md";
import {GrLocation} from "react-icons/gr";
import { InputTheme4,InputTheme3 } from "../../res/styler/InputThemes/InputThemes";
// import {service_img,service_img1,service_img2,service_img3} from "../../res/images/services_directory";
import service_img from "../../res/images/services_directory/service.jpg"; 
import service_img1 from "../../res/images/services_directory/service_1.jpg";
import service_img2 from "../../res/images/services_directory/service_2.jpg"; 
import service_img3 from "../../res/images/services_directory/service_3.jpg"; 
import {ButtonTheme1, ButtonTheme2} from "../../res/styler/ButtonThemes/ButtonThemes";
import ReactPaginate from 'react-paginate';
import { FaAngleRight } from "react-icons/fa";
import NoSearchResults from "../NoSearchResults/NoSearchResults";

class Services extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      service_types : [],
      states : [],
      search_parameters : {
        service_type : 0,
        locality : "",
        city : "",
        state : "",
        country : ""
      },
      partner_services : [],
      filtered_services : [],
      offset_value : 0,
      current_page : 0,
      per_page : 6,
      page_count : 0,
      page_no_input : 1
    }
    this.form_change = this.form_change.bind(this);
    this.service_type_change = this.service_type_change.bind(this);
    this.country_change = this.country_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.getPartnerServices = this.getPartnerServices.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.services_page_ref = React.createRef();
    this.search_block_ref = React.createRef();
    this.services_block_ref = React.createRef();
    this.search_flag = 0;
  }

  componentDidMount(){
    $(".header_container").addClass("transparent_header");
    // console.log(this.props.history.location.state)
    if(this.props.history.location.state && typeof this.props.history.location.state == "object"){
      this.setState(this.props.history.location.state,() => {
        this.services_page_ref.current.style.visibility = "visible";
      });
    }else{ 
      this.getPartnerServices(() => {
        window.scrollTo({
          top : this.services_page_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      });
    }

    var obj_this = this;
    $(".services_refresh").on("click",() => {
      const search_parameters = {
        service_type : 0,
        locality : "",
        city : "",
        state : "",
        country : ""
      };
      this.state.offset_value = 0;
      this.state.current_page = 0;
      obj_this.setState(
        { 
          search_parameters: search_parameters,
          offset_value : 0,
          filtered_services : [],
          partner_services : []
        },
        () => {
          obj_this.getPartnerServices();
        }
      );
    });
  }

  form_change(e){
    this.search_flag = 1;
    this.state.offset_value = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters[e.target.name] = e.target.value;
    this.setState({search_parameters});
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    });
  }

  page_no_form_submit(e){
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getPartnerServices(() => {
        window.scrollTo({
          top : this.search_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  handle_page_click(e){
    this.context.loading(1);
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getPartnerServices(() => {
        window.scrollTo({
          top : this.services_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  service_type_change(e){
    this.search_flag = 1;
    const search_parameters = this.state.search_parameters;
    search_parameters['service_type'] = e.target.value;
    this.setState({search_parameters});
  }

  country_change(e) {
    this.state.offset_value = 0;
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: { country: e.target.value},
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          states : return_data.states,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    const search_parameters = this.state.search_parameters;
    search_parameters['country'] = e.target.value;
    search_parameters['state'] = "";
    search_parameters['city'] = "";
    this.setState({search_parameters});
  }

  state_change(e) {
    this.state.offset_value = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters['state'] = e.target.value;
    this.setState({search_parameters});
  }

  getPartnerServices(callback){
    var obj_this = this;
    const search_parameters = this.state.search_parameters;
    console.log(search_parameters)
    $.ajax({
      url : process.env.REACT_APP_API + "/api/partnerServices",
      data :{
        search_parameters : JSON.stringify(search_parameters),
        offset_value : this.state.offset_value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      obj_this.context.loading(0);
      const service_types = obj_this.state.service_types.length?obj_this.state.service_types:return_data.service_types;
      if(obj_this.search_flag){
        obj_this.setState({
          partner_services : return_data.partner_services,
          filtered_services : return_data.partner_services,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
        },() => {
          if(callback){
            callback();
          }
        });
      }else{
        obj_this.setState({
          partner_services : return_data.partner_services,
          filtered_services : return_data.partner_services,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
          service_types : service_types,
          countries : return_data.countries,
        },() => {
          obj_this.services_page_ref.current.style.visibility = "visible";
          if(callback){
            callback();
          }
        });
      }
      obj_this.context.setHistoryState({
        partner_services : return_data.partner_services,
        filtered_services : return_data.partner_services,
        page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
        offset_value : obj_this.state.offset_value,
        service_types : obj_this.state.service_types,
        countries : obj_this.state.countries,
        current_page : obj_this.state.current_page,
        search_parameters : obj_this.state.search_parameters,
        page_no_input : obj_this.state.page_no_input,
      },obj_this.props.history);
      obj_this.context.loading(0);
    }).fail(function (jqXHR, textStatus) {
      if (textStatus != "abort") {
        alert("Network error");
      } 
    });
  }

  form_submit(e){
    e.preventDefault();
    this.state.offset_value = 0;
    this.state.current_page = 0;
    this.context.loading(1);
    this.setState({
      // filtered_services : [],
      // partner_services : [],
      page_no_input : 1
    },() => {
      this.getPartnerServices(() => {
        window.scrollTo({
          top : this.services_block_ref.current.offsetTop -100,
          behavior : "smooth"
        })
      });
    });
  }

  componentWillUnmount() {
    $(".header_container").removeClass("transparent_header");
  }

  render(){
    return(
      <div className="services_page_container" ref={this.services_page_ref}>
        <div className="page_header">
          <div className="page_title_container">
            <div className="page_title">Amrityum Service Directory </div>
            <div className="page_description">Find local listings of businesses and services near you. 
              <br></br>Get descriptions, addresses, directions, phone numbers, and more   </div>
            <div className="buttons_container2">
              <div className="notice_button_theme1"
                style={{display:this.context.session.user_type == "partner" || this.context.session.user_type == "admin"?"none":"inline-block"}}
                onClick={(e) => {
                if(this.context.session){
                  this.props.history.push("/Dashboard/NewPartner")
                }else{
                  if(!window.confirm("Sign in to continue")){
                    return;
                  }
                  this.props.history.push({ pathname: "/Login", url : "/Dashboard/NewPartner"})
                }
              }}>Add a new listing</div>
              <div className="notice_button_theme1 search_button1" onClick={() =>
              window.scrollTo({
                top : this.search_block_ref.current.offsetTop - 80,
                behavior : "smooth"
              })
              }>Search</div>
              <div className="notice_button_theme1"  onClick={() => {
                const search_parameters = {
                  service_type : 0,
                  locality : "",
                  country : "",
                  state : "",
                  city : ""
                };
                this.setState({search_parameters :  search_parameters}, () => {
                  this.getPartnerServices(() => {
                    window.scrollTo({
                      top : this.services_block_ref.current.offsetTop -100,
                      behavior : "smooth"
                    });
                  });
                });
              }}>View all</div>
            </div>
          </div>
        </div>
       <div className="intro_block">
          <div className="image_details_grid">
            <div className="image_container">
              <div className="images_grid">
                <div>
                  <div className="image_label">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </div>
                  <div className="image" style={{backgroundImage:"url('"+service_img+"')"}}></div>
                  <div className="image" style={{backgroundImage:"url('"+service_img2+"')"}}></div>
                </div>
                <div>
                  <div className="image" style={{backgroundImage:"url('"+service_img1+"')"}}></div>
                  <div className="image" style={{backgroundImage:"url('"+service_img3+"')"}}></div>
                </div>
              </div>
            </div>
            <div className="intro_details">
              <div className="about_amrityum">Service Directory</div>
              <div className="intro_title">Hosting Diverse Businesses Under One Roof</div>
              <div className="intro_content">
                <p>
                  Our Service Directory brings together a host of diverse businesses under one roof to
                  give them the necessary visibility and easy connectedness to their customers. There
                  are several services that are either directly or indirectly related to everything that
                  Amrityum offers. 
                </p>
                <p>
                  Our database provides relevant businesses opportunities to register
                  their businesses, describe their niche, list their resources, and provide contact
                  information. Amrityum Directory service, therefore, is designed to be a one-stop
                  solution to look up everything essential when needing them the most.
                </p>
                <p>
                  Amrityum Service Directory allows service providers to record and promote their
                  businesses enhancing local presence and customer satisfaction.
                  Would you like your business to be listed here? Please register
                  here and we will get back to you shortly.
                </p>
                <div to="/Dashboard/NewPartner"
                  style={{display:this.context.session.user_type == "partner" || this.context.session.user_type == "admin"?"none":"inline-block"}}
                  onClick={() => {
                   if(this.context.session){
                    this.props.history.push("/Dashboard/NewPartner")
                  }else{
                    if(!window.confirm("Sign in to continue")){
                      return;
                    }
                    this.props.history.push({ pathname: "/Login", url : "/Dashboard/NewPartner"})
                  }
                }}>
                  <ButtonTheme2 label="Service provider registration" className="partner_registration_button"></ButtonTheme2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search_block" ref={this.search_block_ref}>
          <div className="search_title">Browse services</div>
          <div className="search_description">Our Services Directory brings together a host of diverse businesses under one roof to give them the necessary visibility and easy connectedness to their customers.</div>
          <form className="search_container_grid" onSubmit={this.form_submit}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Service</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.search_parameters.service_type}
                label="Age"
                onChange={this.service_type_change}
              >
                <MenuItem value="0" >All Services</MenuItem>
                {this.state.service_types && this.state.service_types.map((item, idx) => (
                  <MenuItem value={item.id} key={idx}>{item.service}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputTheme3
              label="Locality"
              className="locality_input"
              attrs={{
                name: "locality",
                value: this.state.search_parameters.locality,
                onChange: this.form_change,
                type: "text",
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.search_parameters.country}
                label="Country"
                onChange={this.country_change}
              >
                <MenuItem value="">All</MenuItem>
                {this.state.countries && this.state.countries.map((item, idx) => (
                  <MenuItem value={item.id} key={idx}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">State/Province</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.search_parameters.state}
                disabled={this.state.search_parameters.country?false:true}
                label="State/Province"
                onChange={this.state_change}
              >
                <MenuItem value="">All</MenuItem>
                {this.state.states && this.state.states.map((item, idx) => (
                  <MenuItem value={item.id} key={idx}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputTheme3
              label="City/Town"
              className="locality_input"
              attrs={{
                name: "city",
                value: this.state.search_parameters.city,
                onChange: this.form_change,
                type: "text",
              }}
            />
            <ButtonTheme1 label="Let’s Explore!" className="service_search_button"></ButtonTheme1>
          </form>
        </div>
        <div className="services_block"  ref={this.services_block_ref}>
          <div className="services_grid">
            {this.state.filtered_services && this.state.filtered_services.map((item,idx) => (
              <Link className="single_service" key={idx} to={"/Partners/"+item.id}>
                <div className="image_type_container">
                  <div className="image" 
                    style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/partners/cover_images/"+item.cover_image_src+"')"}} />
                </div>
                <div className="service_details">
                  <div className="partner_name">{item.business_name}</div>
                  <div className="partner_address"><GrLocation  color="lightgrey" size={15} /> {item.business_address}</div>
                  <div className="contact_no"><a onClick={(e) => e.stopPropagation()} href={"tel:"+item.business_phone_no}><IoIosCall size={16} className="call_icon" /> {item.business_phone_no}</a></div>
                </div>
                <div className="service_type"><MdHomeRepairService size={20} /> {item.service_id == 24?item.other_service:item.service}</div>
              </Link>
            ))}
          </div>
          {
            this.state.filtered_services.length?"":
            <NoSearchResults/>
          }
          <div className="pagination_container" style={{display:this.state.partner_services.length && this.state.page_count>1?"block":"none"}}>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handle_page_click}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.current_page}
            />
            <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
              <span className="page_count">Go to page</span>
              <input className="page_no_input"
                type="number"
                name="page_count"
                value={this.state.page_no_input}
                onChange={this.page_no_change}
                min={1}
                max={this.state.page_count}
              />
              <button type="submit" className="page_no_search_button">
                <span>Go</span>
                <FaAngleRight/>
              </button>
          </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Services;
Services.contextType = MyContext;