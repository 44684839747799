import React from "react";
import "./NewBlog.scss";
import {TextAreaTheme1} from "../../../res/styler/InputThemes/InputThemes";
import ImageUpload from "../../../res/styler/ImageUpload/ImageUpload";
import $ from "jquery";
import MyContext from "../../MyContext";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { ButtonTheme1 } from "../../../res/styler/ButtonThemes/ButtonThemes";

class NewBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_id : "",
      title: "",
      content: "",
      image: "",
      image_src: "",
      profile_pic_delete_flag : 0,
      editorState: EditorState.createEmpty(),
    }
    this.image_change = this.image_change.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.revert_image = this.revert_image.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.form_change = this.form_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.new_blog_ref = React.createRef();
  }

  componentDidMount() {
    var obj_this = this;
    const blog_id = this.props.match.params.blog_id;
    console.log(blog_id)
    if(blog_id){
      this.context.loading(1);
      this.setState({
        blog_id : blog_id
      });
      $.ajax({
        url: process.env.REACT_APP_API + "/api/editBlog",
        data: this.context.bind_session({blog_id: blog_id}),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          const contentBlock = htmlToDraft(return_data.blog_details.content);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return_data.blog_details.editorState = editorState;
          }
          obj_this.setState(return_data.blog_details);
          obj_this.context.loading(0);
        }else {
          alert("Network error!!")
        }
      }).fail(function () {
        alert("Network error!!");
      });
    }
  }

  image_change(e) {
    const file = e.target.files[0];
    this.setState({
      image: file,
    });
  }

  delete_image(){
    this.setState({
      profile_pic_delete_flag : 1,
      image: "",
      image_src : ""
    });
  }

  revert_image(){
    this.setState({
      image: ""
    });
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  onEditorStateChange(value){
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
        editorState :value,
        content : draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  form_submit(e) {
    e.preventDefault();
    console.log(this.state);
    if(this.state.image || this.state.image_src){
      
    }else{
      $.alert("Blog Image Required");
      return;
    }
    if(!this.state.editorState.getCurrentContent().hasText()){
      $.alert("Write content of blog");
      return;
    }

    const blog_details = this.state;
    delete blog_details.editorState;
    // console.log(blog_details);
    const form_data = new FormData();
    form_data.append("blog_details", JSON.stringify(blog_details));
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image", this.state.image);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveBlog",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data);
      // return;
      // if(!return_data.image){
      //   obj_this.context.loading(0);
      //   $.alert("Please upload blog image");
      //   return;
      // }
      if (return_data.status == "insert") {
        obj_this.context.loading(0);
        alert("Blog inserted.")
        obj_this.props.history.replace('/Dashboard/Blogs/MyEntries');
      }else if (return_data.status == "update") {
        obj_this.context.loading(0);
        alert("Blog updated.")
        obj_this.props.history.push({
          pathname : "/Dashboard/Blogs/MyEntries",
          state : obj_this.props.location.previousState??""
        });
      }else{
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    })
  }

  render() {
    return (
      <div className="new_blog_page_container page_container" ref={this.new_blog_ref}>
        <form className="new_blog_form" encType="multipart/form-data" onSubmit={this.form_submit}>
          <div className="page_header">
            <div className="page_title">{this.state.blog_id?"Edit":"Create"} Blog</div>
          </div>
          <div className="blog_details">
            <div className="image_editor_container">
             
              <div className="image_label">Image *</div>
              <ImageUpload className="image_upload_component"
                attrs={{
                  name: "image1",
                  accept: "image/png, image/gif, image/jpeg",
                  max_size : "",
                  // required: true
                }}
                onChange={this.image_change}
                deleteImage = {this.delete_image}
                revertImage = {this.revert_image}
                src = {this.state.image_src?process.env.REACT_APP_CDN_URL+"/blog_images/"+this.state.image_src:""}
              />
        
            </div>
            {/* <TextAreaTheme1
              label="Blog Title *"
              attrs={{
                name: "title",
                value: this.state.title,
                onChange: this.form_change,
                required: true,
                maxLength : 120
              }}
              className="blog_title"
            /> */}
            <div className="blog_title">
              <div className="tooltip">Blog Title *</div>
            <textarea 
                  name="title" 
                  value={this.state.title}
                  onChange={this.form_change}
                  required={true}
                  rows={5} 
                />
            </div>
                
            <div className="notice_label">Blog Content *</div>
            {/* <textarea
              name="content"
              value={this.state.content}
              placeholder="Enter your content.."
              onChange={this.form_change}
              required={true}
              rows={8}
            /> */}
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="editor_toolbar"
              wrapperClassName="editor_component"
              editorClassName="editor_input_area"
              onEditorStateChange={this.onEditorStateChange}
              placeholderText="Enter biography here.."
              handlePastedText={() => false}
              stripPastedStyles={true}
              toolbar={{
                options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                },list: {
                  options: ['unordered', 'ordered'],
                },
                fontSize: {
                  options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
                },
              }}
            />
            <div className="save_button_container">
            {this.state.blog_id?
              <ButtonTheme1
                label="Modify"
                className="create_button"
              ></ButtonTheme1>
            :
              <ButtonTheme1
                  label="Create"
                  className="create_button"
                ></ButtonTheme1>
            }
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default NewBlog;
NewBlog.contextType = MyContext;