import React from "react";
import "./Donate.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import MyContext from "../MyContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoIosCall } from "react-icons/io";
import { MdHomeRepairService } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import {
  InputTheme4,
  InputTheme3,
} from "../../res/styler/InputThemes/InputThemes";
import partner from "../../res/images/partner.jpg";
import {
  ButtonTheme1,
  ButtonTheme2,
} from "../../res/styler/ButtonThemes/ButtonThemes";
import donate_image from "../../res/images/donate/donate.jpg";
import donate_image2 from "../../res/images/donate/donate2.jpg";
import donate_image3 from "../../res/images/donate/donate3.jpg";
import donate_image4 from "../../res/images/donate/donate4.jpg";
import charity_image from "../../res/images/charity.jpg";
import ReactPaginate from 'react-paginate';
import { FaAngleRight } from "react-icons/fa";
import NoSearchResults from "../NoSearchResults/NoSearchResults";

class Donate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      cities: [],
      countries : [],
      search_parameters: {
        name: "",
        locality: "",
        city: "",
        state: "",
        country : ""
      },
      charities: [],
      filtered_charities: [],
      offset_value : 0,
      current_page : 0,
      per_page : 6,
      page_count : 0,
      page_no_input : 1
    };
    this.form_change = this.form_change.bind(this);
    this.getCharities = this.getCharities.bind(this);
    this.country_change = this.country_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.handle_page_click = this.handle_page_click.bind(this);
    this.page_no_change = this.page_no_change.bind(this);
    this.page_no_form_submit = this.page_no_form_submit.bind(this);
    this.charity_page_ref = React.createRef();
    this.search_block_ref = React.createRef();
    this.charities_block_ref = React.createRef();
    this.search_flag = 0;
  }

  componentDidMount() {
    $(".header_container").addClass("transparent_header");
    if(this.props.history.location.state && typeof this.props.history.location.state == "object"){
      this.setState(this.props.history.location.state,() => {
        this.charity_page_ref.current.style.visibility = "visible";
      });
    }else{ 
      this.getCharities(() => {
        window.scrollTo({
          top : this.charity_page_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      });
    }
    
    var obj_this = this;
    $(".charity_refresh").on("click",() => {
      const search_parameters = {
        name: "",
        locality: "",
        city: "",
        state: "",
        country : ""
      };
      this.state.offset_value = 0;
      this.state.current_page = 0;
      obj_this.setState(
        { 
          search_parameters: search_parameters,
          offset_value : 0,
          filtered_charities : [],
          charities : []
        },
        () => {
          obj_this.getCharities();
        }
      );
    });
  }

  form_change(e) {
    this.search_flag = 1;
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters[e.target.name] = e.target.value;
    this.setState({ search_parameters });
  }

  page_no_change(e){
    this.setState({
      page_no_input : e.target.value
    });
  }

  page_no_form_submit(e){
    e.preventDefault();
    this.setState({
      offset_value : (this.state.page_no_input -1) * this.state.per_page,
      current_page : this.state.page_no_input -1,
    },() => {
      this.getCharities(() => {
        window.scrollTo({
          top : this.charities_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }
  
  service_type_change(e) {
    this.search_flag = 1;
    this.state.offset_value = 0;
    this.state.current_page = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters["service_type"] = e.target.value;
    this.setState({ search_parameters });
  }

  country_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: { country: e.target.value},
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          states : return_data.states,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    const search_parameters = this.state.search_parameters;
    search_parameters['country'] = e.target.value;
    search_parameters['state'] = "";
    search_parameters['city'] = "";
    this.setState({search_parameters});
  }

  handle_page_click(e){
    this.context.loading(1);
    this.setState({
      offset_value : e.selected * this.state.per_page,
      current_page : e.selected,
      page_no_input : e.selected +1
    },() => {
      this.getCharities(() => {
        window.scrollTo({
          top : this.charities_block_ref.current.offsetTop - 80,
          behavior : "smooth"
        });
      })
    });
  }

  state_change(e) {
    this.state.offset_value = 0;
    this.state.current_page = 0;
    this.search_flag = 1;
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getCities",
      data: { state: e.target.value },
      type: "POST",
    })
      .done(function (return_data) {
        // console.log(return_data.cities);
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          obj_this.setState({
            cities: return_data.cities,
          });
        } else {
          alert("Network error!!");
        }
      })
      .fail(function (return_data) {
        alert("Network error!!");
      });
    const search_parameters = this.state.search_parameters;
    search_parameters["state"] = e.target.value;
    search_parameters["city"] = "";
    // console.log(search_parameters);
    this.setState({ search_parameters });
  }

  getCharities(callback) {
    var obj_this = this;
    const search_parameters = this.state.search_parameters;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/charities",
      data: {
        search_parameters: JSON.stringify(search_parameters),
        offset_value : this.state.offset_value
      },
      type: "POST",
    })
      .done(function (return_data) {
        console.log(return_data);
        obj_this.context.loading(0);
        if (obj_this.search_flag) {
          obj_this.setState({
            charities: return_data.charities,
            filtered_charities: return_data.charities,
            page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
          },() => {
            if(callback){
              callback();
            }
          });
        } else {
          obj_this.setState(
            {
              charities: return_data.charities,
              filtered_charities: return_data.charities,
              countries: return_data.countries,
              page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
            },
            () => {
              if(callback){
                callback();
              }
              obj_this.charity_page_ref.current.style.visibility = "visible";
            }
          );
        }
        obj_this.context.setHistoryState({
          charities : return_data.charities,
          filtered_charities : return_data.charities,
          page_count :  Math.ceil(return_data.page_count / obj_this.state.per_page),
          offset_value : obj_this.state.offset_value,
          countries : obj_this.state.countries,
          current_page : obj_this.state.current_page,
          search_parameters : obj_this.state.search_parameters,
          page_no_input : obj_this.state.page_no_input,
        },obj_this.props.history);
        obj_this.context.loading(0);
      })
      .fail(function (jqXHR, textStatus) {
        if (textStatus != "abort") {
          alert("Network error");
        }
      });
  }

  form_submit(e) {
    e.preventDefault();
    this.context.loading(1);
    this.state.offset_value = 0;
    this.state.current_page = 0;
    this.setState(
      {
        // filtered_charities: [],
        // charities : [],
        page_no_input : 1
      },
      () => {
        this.getCharities(() => {
          window.scrollTo({
            top: this.charities_block_ref.current.offsetTop -100,
            behavior: "smooth",
          });
        });
      }
    );
  }

  componentWillUnmount() {
    $(".header_container").removeClass("transparent_header");
  }

  render() {
    return (
      <div
        className="donate_page_container"
        ref={this.charity_page_ref}
      >
        <div className="page_header" style={{backgroundImage:"url('"+charity_image+"')"}}>
          <div className="page_title_container no_select">
            <div className="page_title">Donate to a Featured Charity</div>
            <div className="page_description">
              {" "}
              Make a difference for a good cause.
              <br></br>Give a donation either in memory of or in celebration.
            </div>
            <div className="buttons_container2">
              {/* <div className="notice_button_theme1"
                style={{display:this.context.session.user_type == "partner" || this.context.session.user_type == "admin"?"none":"inline-block"}}
                onClick={(e) => {
                if(this.context.session){
                  this.props.history.push("/Dashboard/NewPartner")
                }else{
                  this.props.history.push({ pathname: "/Login", url : "/Dashboard/NewPartner"})
                }
              }}>Add a new listing</div> */}
              {/* <ButtonTheme2
                label="Recommend a charity"
                className="recommend_button"
                onClick={() => this.props.history.push("/ContactUs")}
              /> */}

              {/* <ButtonTheme2
                label="Search"
                className="search_button1"
                onClick={() =>
                  window.scrollTo({
                    top: this.search_block_ref.current.offsetTop,
                    behavior: "smooth",
                  })
                }
              /> */}
              <div
                style={{ display:this.context.session.user_type == "admin"?"none":"inline-block"}}
                className="notice_button_theme1 search_button1"
                onClick={() => this.props.history.push("/ContactUs")}
              >
               Recommend a charity
              </div>
              <div
                className="notice_button_theme1 search_button1"
                onClick={() =>
                  window.scrollTo({
                    top: this.search_block_ref.current.offsetTop - 90,
                    behavior: "smooth",
                  })
                }
              >
                Search
              </div>
              <div
                className="notice_button_theme1 search_button1"
                onClick={() => {
                  const search_parameters = {
                    name: "",
                    locality: "",
                    city: "",
                    state: "",
                    country : ""
                  };
                  this.setState(
                    { 
                      search_parameters: search_parameters,
                      offset_value : 0,
                      filtered_charities : [],
                      charities : []
                    },
                    () => {
                      this.getCharities(() => {
                        window.scrollTo({
                          top: this.charities_block_ref.current.offsetTop -100,
                          behavior: "smooth",
                        });
                      });
                    }
                  );
                  // $(".input_theme3_container input").trigger("focus");
                  // $(".input_theme3_container input:last").trigger("blur");
                }}
              >
                View all
              </div>
            </div>
          </div>
        </div>
        {/* <div className="intro_block">
          <div className="image_details_grid">
            <div className="image_container">
              <div className="images_grid">
                <div>
                  <div className="image_label">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </div>
                  <div className="image" style={{backgroundImage:"url('"+partner+"')"}}></div>
                  <div className="image" style={{backgroundImage:"url('"+partner+"')"}}></div>
                </div>
                <div>
                  <div className="image" style={{backgroundImage:"url('"+partner+"')"}}></div>
                  <div className="image" style={{backgroundImage:"url('"+partner+"')"}}></div>
                </div>
              </div>
            </div>
            <div className="intro_details">
              <div className="about_amrityum">Services Directory</div>
              <div className="intro_title">We have a list of all Service Type</div>
              <div className="intro_content">
                <p>
                  Our Services Directory brings together a host of diverse businesses under one roof to
                  give them the necessary visibility and easy connectedness to their customers. There
                  are several services that are either directly or indirectly related to everything that
                  Amrityum offers. 
                </p>
                <p>
                  Our database provides relevant businesses opportunities to register
                  their businesses, describe their niche, list their resources, and provide contact
                  information. Amrityum Directory service, therefore, is designed to be a one-stop
                  solution to look up everything essential when needing them the most.
                </p>
                <p>
                  Amrityum Service Directory allows service providers to record and promote their
                  businesses enhancing local presence and customer satisfaction.
                  Would you like your business to be listed here? Please register
                  here and we will get back to you shortly.
                </p>
                <div to="/Dashboard/NewPartner"
                  style={{display:this.context.session.user_type == "partner" || this.context.session.user_type == "admin"?"none":"inline-block"}}
                  onClick={() => {
                   if(this.context.session){
                    this.props.history.push("/Dashboard/NewPartner")
                  }else{
                    this.props.history.push({ pathname: "/Login", url : "/Dashboard/NewPartner"})
                  }
                }}>
                  <ButtonTheme2 label="Service provider registration" className="partner_registration_button"></ButtonTheme2>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="new_block">
          <div className="details_grid">
            <div className="images_container">
              <div
                className="image1"
                style={{ backgroundImage: "url('" + donate_image2 + "')" }}
              ></div>
              <div
                className="image2"
                style={{ backgroundImage: "url('" + donate_image + "')" }}
              ></div>
            </div>
            <div className="intro_contents">
              <div className="intro_title">Give in Celebration & Memory</div>
              <div className="title_contents">
                Donations may be dedicated in honour, memory or on behalf of
                special occasions. Charity is chosen according to the wishes of
                a deceased person or his/her survivors. This can also be decided
                by the administrator of a Life Events page. These tribute gifts
                add extra meaning to our love and respect for them. By providing
                the space here to donate,
                <br />
                <br />
                Amrityum intends to inspire everyone who visits our site to make
                a charitable donation towards a noble cause which otherwise one
                may not have considered. Every donation made is deeply
                appreciated by the charities, regardless of size, and has an
                incredible impact. Amrityum does not process the donation on
                behalf of you and your selected charity. Your donations go
                directly to the charities of your choice.
                <br />
                <br />
                We thank you for your support on behalf of Amrityum and the
                charity of your choice. If you have questions, please do contact
                us. We will be more than happy to assist you.
              </div>
              <div className="contact_button_container">
                <ButtonTheme2
                  label="CONTACT US"
                  className="contact_button"
                  onClick={() => this.props.history.push("/ContactUs")}
                />
              </div>
            </div>

            {/* <div className="first_text">
              On behalf of Amrityum and the charity of your choice, we thank you
              for your support. An audited account for charitable donations will
              be published annually on Amrityum.
              <br />
              <br />
              We deduct the card payment transaction fee before transferring to
              the respective charities. There is no other fee or deduction. All
              development and administration of the donation processing service
              are complimentary from Amrityum.
            </div> */}
          </div>
        </div>
        <div className="features_block">
          <div className="features_grid">
            <div className="features_heading">
              Would you like a charity to feature on Amrityum?
            </div>
            <div className="features_first_contents">
              Amrityum offers you a unique way to treasure the memories of those
              who have passed before us or celebrate Life Events. One of the
              most tangible ways is by making ‘In Memory of or In Celebrate
              Donations’ to the charity you love to support. <br />
              <br />
              Ask family and friends to make a donation in lieu of flowers,
              wreaths or gifts for major celebrations, milestones or in memory
              of someone special. <br />
              <br />
              All registered charities may apply to us directly.
            </div>

            <div className="features_second_contents">
              Again, a supporter may also recommend the charity with all
              relevant information and detail. Amrityum, after internal
              scrutiny, will get back to you if the charity is chosen to feature
              on our website. <br />
              <br />
              Once you become an Amrityum recommended charity partner, you
              remain on it as long as your charitable objectives do not change.{" "}
              Every Amrityum service user gets an option to choose your charity,
              if they wish, as their recommended charity when pages are set up
              either in memory of or in celebration.
            </div>
            <div className="recommend-button-container">
              <ButtonTheme2
                label="Recommend a charity"
                className="contact-button"
                onClick={() => this.props.history.push("/ContactUs")}
              />
            </div>
            <div
              className="features_image"
              style={{ backgroundImage: "url('" + donate_image4 + "')" }}
            ></div>
          </div>
        </div>
        <div className="search_block" ref={this.search_block_ref}>
          <div className="search_title">Browse Charities</div>
          <div className="search_description">
            Find charities near you.<br></br> Get descriptions, addresses,
            directions, phone numbers, and more.
          </div>
          <form className="search_container_grid" onSubmit={this.form_submit}>
            <InputTheme3
              label="Charity"
              className="locality_input"
              attrs={{
                name: "name",
                value: this.state.search_parameters.name,
                onChange: this.form_change,
                type: "text",
              }}
            />
            <InputTheme3
              label="Locality"
              className="locality_input"
              attrs={{
                name: "locality",
                value: this.state.search_parameters.locality,
                onChange: this.form_change,
                type: "text",
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.search_parameters.country}
                label="Country"
                onChange={this.country_change}
              >
                <MenuItem value="">All</MenuItem>
                {this.state.countries && this.state.countries.map((item, idx) => (
                  <MenuItem value={item.id} key={idx}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                State/Province
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.search_parameters.state}
                label="State/Province"
                onChange={this.state_change}
              >
                <MenuItem value="">All</MenuItem>
                {this.state.states &&
                  this.state.states.map((item, idx) => (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <InputTheme3
              label="City/Town"
              className="locality_input"
              attrs={{
                name: "city",
                value: this.state.search_parameters.city,
                onChange: this.form_change,
                type: "text",
              }}
            />
            <ButtonTheme1
              label="Let’s Explore!"
              className="service_search_button"
            ></ButtonTheme1>
          </form>
        </div>
        <div className="services_block" ref={this.charities_block_ref}>
          <div className="services_grid">
            {this.state.filtered_charities &&
              this.state.filtered_charities.map((item, idx) => (
                <Link
                  className="single_service"
                  key={idx}
                  to={"/Charity/" + item.id}
                >
                  <img
                    className="image"
                    src={
                      process.env.REACT_APP_CDN_URL +
                      "/charities/logo/" +
                      item.image_src
                    }
                  />
                  {/* <div className="image" 
                  style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+item.image_src+"')"}} /> */}
                  <div className="service_details">
                    <div className="partner_name">{item.name}</div>
                    <div className="partner_address">
                      <GrLocation color="lightgrey" size={15} /> {item.address}
                      {item.locality ? ", " : ""}
                      {item.locality}
                      {item.city ? ", " : ""}
                      {item.city}
                      {item.state ? ", " : ""}
                      {item.state}
                      {item.country?", ":""}
                      {item.country}
                    </div>
                    <div className="contact_no">
                      <a
                        onClick={(e) => e.stopPropagation()}
                        href={"tel:" + item.mobile_no1}
                      >
                        <IoIosCall size={16} className="call_icon" />{" "}
                        {item.mobile_no1}
                      </a>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
          {
            this.state.filtered_charities.length?"":
            <NoSearchResults/>
          }
        </div>
        <div className="pagination_container" style={{display:this.state.charities.length && this.state.page_count > 1?"block":"none"}}>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.handle_page_click}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.current_page}
          />
          <form className="page_no_search_form" onSubmit={this.page_no_form_submit}>
            <span className="page_count">Go to page</span>
            <input className="page_no_input"
              type="number"
              name="page_count"
              value={this.state.page_no_input}
              onChange={this.page_no_change}
              min={1}
              max={this.state.page_count}
            />
            <button type="submit" className="page_no_search_button"><span>Go</span> <FaAngleRight/></button>
          </form>
        </div>
      </div>
    );
  }
}

export default Donate;
Donate.contextType = MyContext;
