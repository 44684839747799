import React from "react";
import './TermsAndConditions.scss';

class TermsAndConditions extends React.Component{
  constructor(props){
    super(props);
    this.terms_and_conditions_page_ref = React.createRef();
  }

  render(){
    return(
      <div className="terms_and_conditions_page_container page_container" ref={this.terms_and_conditions_page_ref}>
        <div className="page_title">
              <div className="title_ghost"></div>
              <div className="title">Terms and Conditions</div>
          </div>
        <div className="terms_and_conditions_container">
          <div className="home_link" onClick={() => this.props.history.push("/")}>HOME</div>
          <div className="page_link">TERMS AND CONDITIONS</div>
          <p>
            Welcome to <b>Amrityum™</b> (the &quot;Website&quot;), an online death notices &amp; family
            announcements (death related notices such as: Death Anniversary, Birthday
            Remembrance etc) publishing platform as well as creating a lasting memorial for
            the dear departed. It also offers an online platform for pet tributes as well as an
            online space to celebrate significant events of a person’s life while they go
            through various stages of life. In addition to these, the website also helps one to
            design a Family Tree where the main contributor to the tree can document,
            celebrate, and share life as they want the world to see them from their
            perspectives. The Web Site is owned and operated by Amrityum, a partnership
            firm (&quot;We&quot;, &quot;Us&quot; and &quot;Our&quot;) with a registered address at Kombanathottathil House,
            Nagapuzha PO, Kalloorkad, Ernakulam Dt., Kerala, India, 686668.
            </p>
            <p>
            Please carefully read the following terms and conditions. By using and accessing
            the Website, and the content and services contained therein, you agree to be
            bound by these terms of use (the &quot;Terms&quot;, or the &quot;Terms of Use&quot;) including any
            other document incorporated thereto. If you do not agree to be bound by the
            Terms, you may not access or use the Website. These Terms and conditions
            apply to your access, use and participation in this Website.
            </p>
            <p>
              These terms and conditions apply [if you browse or otherwise use our Web Site
            and any available services in the Website] the access and use of our Web Site for
            publication of death, funeral, family notices, create a memorial, pet tributes, life
            events, family tree and other ancillary notices (such as condolences, memories &amp;
            stories, life’s frequently asked questions) listed on this site at
            www.amrityum.com and which are treated as being incorporated into these terms
            and conditions.
            </p>
            <p>
            These Terms apply to the use of the Website and the services and content
            included therein by every computer or other communication means (like cellular
            phones, PDA&#39;s, etc.). These Terms further apply to the use of the Website via the
            Internet network or any other communication networks.
            </p>
            <p>
              The Website is not intended for use by children under the age of 18. By accepting
            these Terms of Use you are declaring you are 18 years old or older. If you are
            under 18 years of age – you may not use the Website and must not provide us
            with any of your personal details.
          </p>
          <div className="sub_heading">1. DESCRIPTION OF THE SERVICES</div>
          <p>
            The Website is a social community that connects people with their family, friends and others who wish to know about a death notice, commemorate and honour the memory of their beloveds through publishing family notices and creating a memorial. The website also provides space for pet tributes as well. The website also has a section called ‘Life Events’ to celebrate the significant events of life that occur in the lives of an individual while going through the stages of life. In addition to these, the website also helps one to design a Family Tree where the main contributor to the tree can document, celebrate, and share life as they want the world to see them from their perspectives. By using the Website, you may open a personal profile account and create a profile for a person who has passed away. As a profile creator on behalf of the dear one passed away, you may invite friends and family and others to view, share and contribute to the profile. All invited members may upload and share with each other stories, photos, videos, and other content related to the deceased, like events, memorial days, favourites, TV shows, links, songs, relatives' details, etc. Such shared contents will be disclosed to all users of the deceased profile and other users of the Website. All shared content will be visible for everyone using the site and therefore it’s important to keep that in mind while posting anything on the profile. 
            Please note that the profile storage is limited according to the storage pack the profile creator has purchased upon registration. By clicking on "storage" from the profile page, you may view the amount of used space and free space left in the storage. Depending on the circumstances additional storage pack may be purchased if that facility is made available on the Website. Additional fee-based services are available on the Website.
            Further, profile creator may edit and/or remove certain content which the profile creator has created. There is also a facility to report a comment/content posted on the profile to the ‘Superadmin’ of the website if any comment/content is perceived as inappropriate. The ‘Superadmin’ reserves the right to remove or keep the reported comment/content.  Again, the ‘Superadmin’ may also block access of certain users to the profile permanently, all as they deem necessary. 
            Each user and contributor to any aspect of this website must ensure that the content you upload, and share is lawful and complying with these Terms. You are fully accountable for any consequences resulting from posting any content on the Website by using the Services.
            Please note that the details you upload to your personal profile will be disclosed to all Website's users therefore, you must exercise caution and common sense when posting your content, especially any personally identifiable information.
          </p>
          <div className="sub_heading">2. ACCEPTANCE OF TERMS AND REGISTRATION</div>
          <p>These terms and conditions apply to you if you access and browse our Web Site and by so doing you will be treated as having accepted these terms and conditions. Please do not access or browse our Web Site if you do not accept these terms and conditions.</p>
          <ul className="main_list">
            <li>
              In order to:
              <ul className="sub_list">
                <li>Comment or contribute any material to our Web Site</li>
                <li>Receive some of the services (including, but not limited to the paid services) from our Web Site</li>
                <li>Access certain areas of our Web Site;</li>
                <li>Access and/or use the Death/Family Notices service, create a memorial or pet tributes or Life Events or Family Tree</li>
                <li>We will require you to register. By completing the initial registration and entering your details you will be deemed to have accepted these terms and conditions.</li>
                <li>The authenticity, consequential responsibilities, legal implications and liabilities if any for the material contents posted by you in website will solely rest upon you. </li>
              </ul>
            </li>
            <li>When you register, you are registering [as a personal user of our Web Site as an industry user of our Web Site]. You will be required to input your email address/phone number and password to access registration areas of our Web Site including access and use of the Death/Family Notices, create a memorial, pet tributes and life events Family Tree service</li>
            <li>Once you have registered, we allow you to access and use the registration areas of our Web Site on the basis that:</li>
            <ul className="sub_list">
              <li>Your email address/phone number and password are personal to you and may not be used by anyone else to access our Web Site;</li>
              <li>You will keep your mail address and other registration details up to date and promptly notify us of any changes;</li>
              <li>You will not do anything which would assist anyone who is not a registered user to make contributions or to gain access to any registration area of our Web Site;</li>
              <li>You do not maliciously create additional registration accounts for the purpose of abusing the functionality of the Web Site, or other users, nor do you seek to pass yourself off as another user you comply with these terms.</li>
            </ul>
            <li>You agree to abide by all applicable local and international laws, regulations, and rules. You further agree that you are solely responsible for all acts or omissions associated with your access and use of the Website and the access and use of the Website by anyone on your behalf.</li>
            <li>If, for any reason, we believe that you have not complied with these requirements, we may, at our discretion, cancel your access to the registration areas of our Web Site immediately and without giving you any advance notice.</li>
          </ul>
          <div className="sub_heading">3. RESTRICTIONS ON [ACCESS AND] REGISTRATION</div>
          <ul className="main_list">
            <li>Our Web Site is intended for adults based only in countries in which participation in our Web Site is not illegal, unlawful, or otherwise restricted.</li>
            <li>You warrant that all of the personal details you provided to us on registration are true and accurate and will be kept up to date by you.</li>
            <li>The Website serves all Internet users. However, certain Services, like creating a profile or contributing any content, may be available to registered users only and may require the payment of applicable fees.</li>
            <li>When you register with the Website, or while completing our contact forms, we will ask you to provide us with certain contact and personal details, like your full name, age, email address and password to log-in, physical address, including your country and state, cellular phone number, etc. If you wish to open a new profile for the deceased or want to publish certain content (e.g.: death/family notices, create a memorial or a pet tributes or life events or Family Tree), we may ask you to provide your payment details as well (e.g. credit card number and expiration date), as set below.</li>
            <li>You must submit only true, accurate and complete details. Bear in mind that false, incorrect or outdated information may prevent you from registering and impair Our ability to provide you with the Services and contact you. We will explicitly indicate the fields for mandatory completion.</li>
            <li>If you do not enter the requisite data in these fields, you will not be able to register with the Website or complete the contact forms. We will only use your personal details in accordance with Our Privacy Policy which is incorporated by reference to these Terms.</li>
            <li>You agree to maintain your username and password in absolute confidentiality and refrain from disclosing them to others. Make sure that you change your password frequently and at least once in every six months.</li>
            <li>You are fully accountable for any outcome resulting from your failure to provide true, accurate and complete details in the course of the registration or form completion process, and for any use or misuse of your account on the Website as a result of conveying your details to someone else.</li>
            <li>We may deny, or cancel your account, for any reason, at any time and in Our sole discretion. We may notify you that We cancelled your account by sending you a message to the e-mail address that you provided during the registration process. Such notification may come into effect immediately.</li>
            <li>In addition, notwithstanding any remedies that may be available to Us under any applicable law, We may temporarily or permanently deny, limit, suspend, or terminate your personal profile account, prohibit you from accessing the Website, remove content that you have posted on the Website and take technical and legal steps to keep you off the Website, if We believe that –</li>
            <ul className="sub_list">
              <li>You have abused your rights to use the Website or any of the Services therein; or,</li>
              <li>You have breached the Terms; or,</li>
              <li>You deliberately submitted false information or content that might violate the respect for the deceased; or,</li>
              <li>You have performed any act or omission that violates any applicable law, rules, or regulations; or,</li>
              <li>You have performed any act or omission which is harmful or likely to be harmful to those who provide the services of this website, or any other third party, including other users and partners of the Website; or,</li>
              <li>You made use of the Services to perform an illegal act, or for the purpose of enabling, facilitating, assisting, or inducing the performance of such an act; or,</li>
              <li>You conveyed your password to another person; or,</li>
              <li>You have abandoned your account; or,</li>
              <li>You have failed to pay the applicable fees for certain fee-based Services on the Website.</li>
            </ul>
          </ul>
          <div className="sub_heading">4. CHARGES AND PAYMENT</div>
          <ul className="main_list">
            <li>We do not charge for you to access or browse the non-registration areas of our Web Site.</li>
            <li>Our charges for access to and use of the Death/Family Notices, Create a memorial, Pet Tribute, Life Events, or Family Tree services vary according to the use you would like to make of these services.</li>
            <li>We may, at any time and at Our sole discretion, convert a free of charge service to a fee-based service and vice versa, and change rates and payment terms. The pricing of the various storage packs will be posted on the Website. The payments for the fee-based services will be executed online, in advance, by using a valid credit card, for the full subscription period, whether the subscriber uses the services s/he purchased or not. You will be required to submit your full name, your credit card number and expiration date, your ID number and the type of credit card you wish to use in order to pay. After the payment process is completed, we will send you an email to confirm your payment. We may, at any time and at Our sole discretion, change the method of payment. Such changes will take effect immediately upon their posting on the Website.</li>
          </ul>
          <p>Online Purchases. You may be able to purchase products such as keepsake products or flowers, services, or information through our Website. If the products or services are sold by amritym.com and if you have a question about the same, please contact us at info@amrityum.com.  if the products or services from a third party, please contact them directly. </p>
          <div className="sub_heading">5. YOUR CONTRIBUTIONS</div>
          <ul className="main_list">
            <li>These terms apply to any and all material (including any comments, artwork, photos and other materials) which you contribute to our Web Site (“contributions”), and to any interactive services associated with it. You warrant that all of your contributions comply with the terms set out below and you indemnify us for any breach of that warranty.</li>
            <li>You must comply with the spirit of the following standards as well as the letter. The standards apply to each part of any contribution as well as to its whole. If, for any reason, we believe that any contribution does not comply with these terms, requirements, and standards, we may, at our discretion, cancel your access to the registration areas of our Web Site immediately and without giving you any advance notice.</li>
            <li>Contributions must:</li>
            <ul className="sub_list">
              <li>where they state facts, be accurate;</li>
              <li>where they state opinions, be genuinely held;</li>
              <li>where they state opinions, be genuinely held;</li>
            </ul>
            <li>Contributions must not:</li>
            <ul className="sub_list">
              <li>contain any material which is defamatory of any person;</li>
              <li>contain any material which is obscene, offensive, hateful or inflammatory;</li>
              <li>promote sexually explicit material;</li>
              <li>promote violence;</li>
              <li>promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
              <li>include any materials created by a third party and/or which you did not create yourself unless you have a formal written licence from the third party in question</li>
              <li>infringe any copyright, database right or trade mark of any other person;</li>
              <li>be likely to deceive any person;</li>
              <li>be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</li>
              <li>promote any illegal activity;</li>
              <li>be threatening, abusive or invade another's privacy, or cause annoyance, inconvenience or needless anxiety;</li>
              <li>include any personal details (including any image of any other person) about anyone other than yourself (and you should be cautious about revealing your own personal details on our Web Site) unless you have written authority and licence from the person in question;</li>
              <li>be likely to harass, upset, embarrass, alarm or annoy any other person;</li>
              <li>be used to impersonate any person, or to misrepresent your identity or affiliation with any person;</li>
              <li>give the impression that they emanate from us, if this is not the case;</li>
              <li>advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
              <li>be used in accordance with any applicable local, national or international law or regulation</li>
            </ul>
            <li>You grant to us the non-exclusive right to use, copy, reproduce, adapt, modify, distribute, publish and disclose to third parties any contributions for any purpose including the purposes of operating our Web Site and throughout the world via our Web Site and any related activities. This means that you remain the owner (or licensee) of the intellectual property rights in and relating to your contribution and are free to deal with it as you choose.</li>
            <li>You also waive any moral rights which you may have in relation to the contributions made to our Web Site.</li>
            <li>We have the right to disclose your identity to any third party at our discretion who is claiming that any contribution posted or uploaded by you to our Web Site constitutes a violation of their intellectual property rights, or of their right to privacy.</li>
            <li>We will not be responsible, or liable to any third party, for the content or accuracy of any contributions posted by you or any other user of our Web Site.</li>
            <li>Publication of any contribution you submit to us will be at our sole discretion. We reserve the right to make additions or deletions to the text or graphics prior to publication, or to refuse publication.</li>
            <li>We have the right to remove any contribution, material or posting you make on our Web Site at our discretion and, without limiting the foregoing, if, in our opinion, such contribution does not comply with the terms above.</li>
            <li>You must not misuse our Web Site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our Web Site, the server on which our Web Site is stored or any server, computer or database connected to our Web Site.</li>
          </ul>
          <div className="sub_heading">6. LINKS TO OTHER SITES</div>
          <p>
          The Website may contain links to content published on other websites or external sources, provided by third parties. We do not operate or monitor these websites and content. You may find them, or the information and content posted therein not compatible with your requirements, or you may object to their content, or find such content to be annoying, improper, unlawful, or immoral. By linking to a certain website, we do not endorse, or sponsor its content, or confirm its accuracy, credibility, authenticity, reliability, validity, integrity, or legality. We assume no responsibility or liability for such third-party websites or content, or their availability, or for any transactions made between you and such third-party websites.
          </p>
          <p>
          We may include paid advertisements, sponsored or free links and commercial or non-commercial information (the "advertisements"). By clicking the advertisements, you may be transferred to a website of an advertiser or receive any other messages, information or offers from the advertiser and from others. We are not responsible for any of the advertisers' practices including their privacy practices, or for the content of their websites, information, messages or offers.
          </p>
          <p>
          You may link to our home page or any other relevant page of our Web Site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.
          </p>
          <div className="sub_heading">7. MONITORING AND MODERATING</div>
          <ul className="main_list">
            <li>We are not under any obligation to oversee, monitor or moderate our Web Site. Subject to a clause which will be mentioned at a later stage in this document of these terms and conditions, we expressly exclude our liability for any loss or damage arising from use of our Web Site by any user which is contrary to these terms whether we choose to moderate and/or monitor this Web Site or not.</li>
            <li>We do not actively monitor contributions before they appear on the Web Site. We encourage open and active participation and if you are concerned about any contribution, please see the “Complaints” section of these terms.</li>
            <li>We would like our Web Site to be accurate, informative and incorporate quality content. We may monitor/moderate all contributions and make alterations at any time and at our discretion.</li>
            <li>Where you are under 18 years of age, please obtain the permission of your parent or guardian before making any Post or using any platform on our website to post or comment.</li>
          </ul>
          <div className="sub_heading">8. COMPLAINTS</div>
          <ul className="main_list">
            <li>If you have any complaint about any contribution or other content on our Web Site 
              please use the “Report Content” link in order to notify us 
              or contact us at <span style={{color:"blue",textDecoration:"underline"}}>info@amrityum.com.</span></li>
            <li>Your compliant will be dealt with by our team in accordance with our internal moderation and take down policy and guidelines in place from time to time. A member of our team will use reasonable endeavours to respond to you although you should be aware that we receive, review, investigate and deal with complaints only during our normal business hours.</li>
            <li>We reserve the right to terminate your registration if we suspect any misuse of our complaints procedure.</li>
          </ul>
          <div className="sub_heading">9. YOUR INDEMNITY</div>
          <ul className="main_list">
            <li>
            You indemnify us against all loss, damages, expenses (including legal expenses) and claims incurred by us (whether directly or indirectly and including as a result of any claim made against us by a third party) and arising in any way from your use of our Web Site and/or your failure to comply with these terms.
            </li>
          </ul>
          <div className="sub_heading">10. INTELLECTUAL PROPERTY RIGHTS</div>
          <ul className="main_list">
            <li>With the exception of the contributions, we are the owner or the licensee of all intellectual property rights in and relating to:</li>
            <ul className="sub_list">
              <li>our Web Site; and</li>
              <li>the Death Notices service; and</li>
              <li>the material (including all logos, text and graphics) published on the Web Site; and</li>
              <li>the software used to operate our Web Site</li>
              <li>These works are protected by copyright and other intellectual property rights around the world. All such rights are reserved.</li>
            </ul>
            <li>Subject to a clause mentioned below in this section regarding your use of the Death/family Notices service (if applicable), you may print off one copy, and may download extracts, of any page(s) (other than those that make up the Death Notices service) from our Web Site for your personal reference and you may draw the attention of others within your organisation to material posted on our Web Site.</li>
            <li>You must not modify the paper or digital copies of any materials you have printed off or downloaded in accordance with clause mentioned above in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</li>
            <li>Our status (and that of any identified contributors) as the authors of material on our Web Site must always be acknowledged.</li>
            <li>You must not use any part of the materials on our Web Site for commercial purposes without obtaining a licence to do so from us or our licensors.</li>
            <li>If you print off, copy or download any part of our Web Site in breach of these terms and conditions, your registration and right to use our Web Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</li>
            <li>In relation to the Death Notices or other service, your licence depends on the category of user you are and the fees you have paid.</li>
          </ul>
          <div className="sub_heading">11. DISCLAIMER AND LIABILITY</div>
          <ul className="main_list">
            <li>
            THIS IS AN IMPORTANT CLAUSE. ITS EFFECT IS TO LIMIT OUR LIABILITY UNDER THESE TERMS. YOU SHOULD READ IT CAREFULLY AND MAKE SURE YOU ARE HAPPY WITH IT BEFORE AGREEING TO THESE TERMS.
            </li>
            <li>Nothing in these terms shall limit our liability for fraud or for death or personal injury caused by our negligence or in relation to any other liability which cannot be excluded or limited by law.</li>
            <li>In relation to the Death Notices service or other services for that matter, we warrant that it will operate substantially in accordance with its published specification and without any material defect during all hours when the Web Site is operational. Subject to the foregoing, the Web Site is provided on an “as is” and “as available” basis and we offer no warranty or representation of any kind relating to our Web Site, the site, any content or otherwise.</li>
            <li>Subject to a clause mentioned above in this section and other than as stated in this clause, all implied terms, conditions or warranties are hereby excluded to the fullest extent permitted by law.</li>
            <li>Whilst we make every effort to ensure the availability and accuracy of our Web Site and any content, we do not warrant the availability of our Web Site or the Death Notices or other services will be uninterrupted or that Web Site will be error or omission free.</li>
            <li>We do not accept any responsibility for any use made of the Web Site or any of its content [including the Death/family/create a memorial/pet tributes/Life events/Family Tree and other services] and we shall not be liable:</li>
            <ul className="sub_list">
              <li>in any circumstances for any loss of profits, loss or revenue, loss of or damage to goodwill, loss of customers, loss in connection with third party claims, or any indirect, special or consequential loss (even if the party concerned has advised of the possibility of such loss);</li>
              <li>for any failures, interruptions, delays or other matters of a similar nature arising out of circumstances beyond our reasonable control; and/or</li>
              <li>[for any other loss suffered in connection with the use of our Web Site or any content to the fullest extent that we may exclude or limit such liability under applicable law.]</li>
            </ul>
            <li>[Subject to [clauses mentioned in this section above] our total aggregate liability arising from any claim relating to the use or operation of our\Web Site, including the Death/family/create a memorial/pet tributes/life events/family tree Notice services, shall not exceed an amount equal to the total amounts paid or payable by you for your participation in the Web Site (if any).</li>
            <li>Access to our site is permitted on a temporary basis, and We reserve the right to withdraw or amend the service We provide on our site without notice. We will not be liable if for any reason our site is unavailable at any time or for any period. From time to time, We may restrict access to some parts of our site, or our entire site.</li>
          </ul>
          <div className="sub_heading">12. LIMITATION OF LIABILITY</div>
          <p>THE COMPANY, INCLUDING ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS AND AGENTS WILL NOT BE LIABLE TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW, FOR ANY DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY OTHER DAMAGE, AND LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES AND PAYMENTS, EITHER IN TORT, CONTRACTUAL, OR IN ANY OTHER FORM OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE USE OF, OR THE INABILITY TO USE THE WEBSITE, OR FROM ANY FAILURE, ERROR, OR BREAKDOWN IN THE FUNCTION OF THE WEBSITE, OR FROM ANY FAULT, OR ERROR MADE BY OUR STAFF OR ANYONE ACTING ON ITS BEHALF, OR FROM YOUR RELIANCE ON POSTED CONTENT ON THE WEBSITE, OR FROM ANY COMMUNICATION WITH THE WEBSITE, OR WITH OTHER USERS ON OR THROUGH THE WEBSITE, OR FROM ANY DENIAL OR CANCELLATION OF YOUR USER ACCOUNT, OR FROM RETENTION, DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF YOUR CONTENT ON THE WEBSITE. IN ANY EVENT, YOUR SOLE REMEDY WILL BE LIMITED TO CORRECTING SUCH ERRORS, OR MALFUNCTIONS, IF APPLICABLE, AND SUBJECT TO OUR SOLE DISCRETION.</p>
          <div className="sub_heading">13. PAID SERVICES</div>
          <ul className="main_list">
            <li>We may offer paid services from time to time. We reserve the right to discontinue or modify these paid services at any time without notice to you. In order to receive paid services from amrityum.com you must register with our members’ area as set out earlier in the terms and conditions above.</li>
            <li>After placing an order for a paid service, you will receive an email from amrityum.com, or our payments partners, acknowledging that We have received your order for a paid service. However, please note that this does not mean that your order has been accepted. By sending an order you make an offer to amrityum.com to form a binding contract. All orders are subject to acceptance by amrityum.com. The contract will be formed on the sending by email to you of a confirmation of the order from amrityum.com (“<b>Order Confirmation</b>”).</li>
            <li>We reserve the right to decline orders and to terminate an already concluded contract without notice for any reason.</li>
            <li>The price of any paid service will be as quoted on our site from time to time (“<b>Price</b>”). These Prices include <b>GST</b> (if applicable) at the relevant current rate chargeable in India for the time being. We take reasonable care to ensure that the Prices are correct at the time when the relevant information was entered onto the system. However, if there is an error in our Prices We will contact you directly.</li>
            <li>Prices are liable to change at any time, but changes will not affect orders in respect of which We have already sent you an Order Confirmation.</li>
            <li>Payment for all services must be by credit/debit card. Where the Price is a recurring charge, by signing up to a service you authorise amrityum.com to deduct this price on the recurring payment date as set out in our Order Confirmation.</li>
            <li>If you would like to cancel a service at any time you must provide 30-days’ notice to amrityum.com to the email address  <span>info@amrityum.com.</span> Any payments of the Price made up to the date of cancellation will not be refunded. If the payment date for the Price falls within the cancellation period, We will charge you for this payment.</li>
            <li>You shall make all payments due under any contract with amrityum.com in full without any deduction whether by way of set-off, counterclaim, discount, abatement or otherwise unless you have a valid court order requiring an amount equal to such deduction to be paid by amrityum.com to you.</li>
            <li>We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a contract that is caused by a force majeure event (“<b>Force Majeure Event</b>”) which includes any act, event, non-happening, omission, or accident beyond our reasonable control and includes in particular (without limitation) the following:
              <ul className="sub_list">
                <li>strikes, lockouts or other industrial action;</li>
                <li>civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;</li>
                <li>fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;</li>
                <li>impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport;</li>
                <li>impossibility of the use of public or private telecommunications networks; or</li>
                <li>the acts, decrees, legislation, regulations or restrictions of any government;</li>
              </ul>
            </li>
            <li>Our performance under any contract is deemed to be suspended for the period that the Force Majeure Event continues, and We will have an extension of time for performance of the contract for the duration of that period.</li>
            <li>Where you purchase advertising space on amrityum.com, your advert(s) display on the site only for the period of time that you advertise with us.</li>
            <li>Where you purchase any services from amrityum.com, these Terms and any document expressly referred to in them represent the entire agreement between amrityum.com and you in relation to the subject matter of any contract and supersede any prior agreement, understanding or arrangement between amrityum.com, whether oral or in writing. Both you and amrityum.com each acknowledge that, in entering into a contract, neither has relied on any representation, undertaking or promise given by the other or be implied from anything said or written in negotiations between you and amrityum.com prior to such contract except as expressly stated in these terms and conditions.</li>
          </ul>
          <div className="sub_heading">14. CANCELLATION, REFUND, TERMINATION, TRANSFER, AND EXPIRATION OF ACCOUNT OR SERVICES</div>
          <p>You may cancel or terminate your account and/or use of the Services, with or without cause at any time, upon providing written notice to Amrityum. If you cancel or terminate your account or any Services or upon expiration of your account or service, your cancellation/termination/expiration will take effect immediately. Upon termination, your right to use your account or subject Services immediately ceases. Your notice to Amrityum of cancellation or termination must be sent via email or conventional mail to Amrityum's address as set forth in these Terms and Conditions or contact us page.</p>
          <p>Amrityum in its sole and absolute discretion, and at any time and with or without prior notice to you. may suspend, cancel, transfer, or terminate your password, account, any site within an account and/or use of any Services for any reason whatsoever (including and without limitation, due to lack of use, commercial use, cybersquatting, Amrityum’s resolution of any dispute among multiple persons claiming the right to use the same or similar accounts or services, or Amrityum's belief, in its absolute and sole discretion, that you have violated or acted inconsistently with the letter or spirit of these Terms and Conditions). In the event of a dispute or conflict among, or complaint from, users of the Services about another's right to establish, use, or maintain an account or Services, Amrityum reserves for itself the sole right to determine whether or how to resolve such dispute, conflict or complaint, with or without factual or other investigation.</p>
          <p>Upon expiration, or cancellation or termination by either party, of your account, your password, and/or use of any Services, you will have no right to any Material or Information you Submitted, and Amrityum may temporarily or permanently block access to, remove, deactivate, delete, and discard all such Material or Information contained therein (including but not limited to any Online Memorial). Amrityum accepts no liability for removed or deleted Material or Information. In addition, any contracts (verbal, written, or assumed) with respect to your account, Material or Information you Submit, and/or use of any Services, will be terminated at Amrityum's discretion. You agree that Amrityum shall not be liable to you or any third-party for any termination of your access to any Services.</p>
          <p>Upon suspension, cancellation, or termination of your account or your use of any Service (for whatever reason), there shall be no refund of money you paid to Amrityum.</p>
          <div className="sub_heading">15. YOUR PERSONAL INFORMATION</div>
          <ul className="main_list">
            <li>We respect your privacy. Our Privacy Policy explains the accepted privacy practices on the Website. The Privacy Policy is incorporated by reference to the Terms. The terms of the Privacy Policy may change from time to time and therefore it is recommended that you read it periodically.</li>
            <li>Please see our privacy policy for details of how we process your personal details.</li>
            <li>Please see our cookie policy.</li>
          </ul>
          <div className="sub_heading">16. CHANGES IN THE WEBSITE</div>
          <p>From time to time, we may change the Website's layout, design or display, as well as the scope and availability of the content and Services accessible therein, without giving prior notice. By their very nature, changes of this type may cause inconvenience or even malfunctions at first. You agree and acknowledge that We do not assume any responsibility with respect to, or in connection with the introduction of such changes or from any malfunctions or failures that may result therefrom.</p>
          <div className="sub_heading">17. AVAILABILITY</div>
          <p>The availability and functionality of the Website depends on various factors and elements, including software, hardware, and communication networks. Third parties provide some of these elements. None of these factors are fault free. We do not warrant or guarantee that the Website will operate without disruption, errors, or interruptions, or that it will be accessible, or available at all times, or immune from unauthorized access or error free.</p>
          <div className="sub_heading">18. TERMINATION OF OPERATION</div>
          <p>At all times, the website owner/s may terminate the operation of the Website, or any part thereof, either temporarily or permanently. The website owner/s will publish a termination notice reasonable time prior to the termination of the Website. The Company will maintain backup copies of the content on the Website for additional reasonable time, and afterwards will be entitled to delete the content without maintaining any backup copy thereof and without giving additional notice. You agree and acknowledge that the website owner/s does/do not assume any responsibility with respect to, or in connection with the termination of the Website's operations and loss of any data as a result.</p>
          <div className="sub_heading">19. VARIATIONS</div>
          <p>We may revise these terms and conditions at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you. Variations will be summarised, when possible, on this website. Some of the provisions contained in these terms and conditions may also be superseded by provisions or notices published elsewhere on our Web Site.
            You agree to be bound by any of the changes made in the Terms, including changes to any and all documents, forms and policies incorporated thereto. Continuing to use the Website will indicate your acceptance of the amended terms. If you do not agree with any of the amended terms, then you must avoid from any further use of the Website.
            We advise you to periodically read the Terms, as they may change from time to time.</p>
          <div className="sub_heading">20 WAIVER</div>
          <p>If we fail, at any time, to insist upon strict performance of any of your obligations under any of these terms and conditions, or if we fail to exercise any of the rights or remedies to which we are entitled under these terms and conditions, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with such obligations.</p>
          <div className="sub_heading">21. LAW</div>
          <p>These terms are governed by Indian law. You agree to submit to the exclusive jurisdiction of the Indian courts provided that nothing in this clause will prevent us from taking any action in any court that has jurisdiction over you.</p>
          <div className="sub_heading">22. YOUR CONCERNS</div>
          <p>We put great effort in ensuring the reliability of facts and information that We post on the Website. However, errors may sometimes occur. If you think that any information or publication that We have posted on the Website is erroneous, contact us at <span>info@amrityum.com</span>.</p>
          <div className="sub_heading">23. CONTACT US</div>
          <p>Amrityum respects the privacy and good reputation of the Website users. If you think that certain content published on the Website harms or offends you in any way, you may contact us at <span>info@amrityum.com</span>, and We will do our best to provide a prompt response to your complaint.</p>
        </div>

      </div>
    )
  }
}

export default TermsAndConditions;