import React from "react";
import "./SearchList1.scss";
import $ from "jquery";
import MyContext from "../../MyContext";

class SearchList1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      death_notices: [],
      search_input: ""
    }
    this.form_change = this.form_change.bind(this);
    this.death_notices_XHR = { abort: () => { } }
  }

  componentDidMount() {
    this.context.loading(1);
    this.getDeathNotices();
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.getDeathNotices(e.target.value);
  }

  getDeathNotices(phrase) {
    const search_phrase = phrase ?? "";
    var obj_this = this;
    this.death_notices_XHR.abort();
    this.death_notices_XHR = $.ajax({
      url: process.env.REACT_APP_API + this.props.API,
      data: this.context.bind_session({
        search_phrase: search_phrase
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          death_notices: return_data.death_notices
        });
      }
      obj_this.context.loading(0);
    }).fail(function (jqXHR, textStatus) {
      if (textStatus != "abort") {
        alert("Network error");
      } 
  });
  }

  render() {
    return (
      <div className="search_list1_component_container">
        <div className="search_container">
          <input
            className="search_input"
            name="search_input"
            type="text"
            value={this.state.search_input}
            onChange={this.form_change}
            placeholder="Search..."
          />
          <i className="fas fa-search search_icon"></i>
        </div>
        <div className="table_container">
          <table className="death_notices_table table_theme1">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th align="left">Fullname</th>
                <th align="left">House Name</th>
                <th align="left">Locality</th>
                <th>District</th>
                <th>Status</th>
                <th>Published date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.death_notices && this.state.death_notices.map((item, idx) => (
                <tr key={idx}
                  onClick={() => {
                    this.props.history.push(this.props.rowOnClick + item.id)
                  }}
                  id={item.id}
                  style={{ backgroundColor: item.status == "PENDING" ? "#f9bb016e" : item.status == "REJECTED"?"#ff00003b":"" }}
                >
                  <td align="center" style={{ minWidth: "60px" }}>{idx + 1}</td>
                  <td>{item.fullname}</td>
                  <td>{item.house_name}</td>
                  <td>{item.locality}</td>
                  <td align="center">{item.district}</td>
                  <td align="center">{item.status}</td>
                  <td align="center">{new Date(item.published_date).getDate() + "-" + new Date(item.published_date).getMonth() + 1 + "-" + new Date(item.published_date).getFullYear()}</td>
                </tr>
              ))}
              <tr style={{ display: this.state.death_notices.length ? "none" : "table-row" }}><th colSpan={7}>No results!!</th></tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default SearchList1;
SearchList1.contextType = MyContext;