import { Component } from "react";
import "./Login.scss";
import image from "../../res/images/login_background.jpg";
import { Link } from "react-router-dom";
import { InputTheme3 } from "../../res/styler/InputThemes/InputThemes";
import $ from "jquery";
import MyContext from "../MyContext";
import logo from "../../res/images/Amrityum_01.png";
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import google_icon from "../../res/images/login/google_icon.png";
import facebook_icon from "../../res/images/login/facebook_icon.png";
import dove_icon from "../../res/images/AMRITYUM-02.jpg";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
} from 'reactjs-social-login';
import ManageAccount from "../Dashboard/ManageAccount/ManageAccount";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      remember_password: "",
      login_error: "",
      otp : "",
      keep_me_signed_in : 1,
      enter_email : 0,
      mail_status : 0,
      change_password : 0,
      password : "",
      cnf_password : ""
    }
    this.form_change = this.form_change.bind(this);
    this.on_checkbox_change = this.on_checkbox_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.heartbeat = this.heartbeat.bind(this);
    this.mail_form_submit = this.mail_form_submit.bind(this);
    this.otp_form_submit = this.otp_form_submit.bind(this);
    this.password_form_submit = this.password_form_submit.bind(this);
    this.resend_otp = this.resend_otp.bind(this);
    this.successGoogleLogin = this.successGoogleLogin.bind(this);
    this.failureGoogleLogin = this.failureGoogleLogin.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
  }
  componentDidMount() {
    $(".header_container .header_menu, .header_container .signin_signup_button").css("display","none");
    $(".header_container .header_menu, .header_container .dropdown").css("display","none");
    $(".header_container .register_button").css("display","block");
    if($("input[name='email']").length){
      $("input[name='email']")[0].focus();
    }
    this.context.loading(0);
  }
  
  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value,
      login_error: ''
    });
  }

  on_checkbox_change(e){
    var keep_me_signed_in = this.state.keep_me_signed_in;
    this.setState({
      keep_me_signed_in : keep_me_signed_in?0:1
    });
  }

  successGoogleLogin(data) {
    // console.log(data);
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/googleLogin",
      data: {
        token_id : data.access_token
      },
      type: "POST"
    }).done(function(return_data) {
      obj_this.context.loading(0);
      if (return_data.status == "success") {
        // console.log(return_data);
        // return;
        obj_this.context.setSession(return_data.session);
        setTimeout(obj_this.heartbeat,5000);
        if(return_data.session.redirect_page == "redirect"){
          alert("Please complete your profile details, to continue using Amrityum.")
          $(".profile_complete_popup").css("display","block");
          $(".manage_account_page_container").scrollTop(0);
        }else{
          obj_this.props.history.replace(obj_this.props.location.url ?? "/");
        }
      }else if(return_data.status == "deleted"){
        alert("You can't login with this email. Please contact administrator.")
      } else {
        alert("Login error");
      }
    }).fail(function () {
      alert("Network error");
    });
  }

  failureGoogleLogin(response) {
    console.log(response);
    // alert("Can't sign in to your Google Account")
  }
  
  responseFacebook(data){
    // console.log(data);
    // console.log(data.profile)
    if(data && ("name" in data)){
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/facebookLogin",
        data: {
          token_id : data.accessToken
        },
        type: "POST"
      }).done(function(return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          // console.log(return_data);
          // return;
          obj_this.context.setSession(return_data.session);
          setTimeout(obj_this.heartbeat,5000);
          if(return_data.session.redirect_page == "redirect"){
            alert("Please complete your profile details, to continue using Amrityum.")
            $(".profile_complete_popup").css("display","block");
          }else{
            obj_this.props.history.replace(obj_this.props.location.url ?? "/");
          }
        }else if(return_data.status == "deleted"){
          alert("You can't login with this email. Please contact administrator.")
        } else {
          obj_this.context.loading(0);
          alert("Login error");
        }
      }).fail(function () {
        alert("Network error");
      });
    }else{
      alert("Can't sign in to your Facebook Account")
    }
  }

  form_submit(e) {
    e.preventDefault();
    delete this.state.remember_password;
    delete this.state.login_error;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/userLogin",
      data: {
        user_data: JSON.stringify(this.state)
      },
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        console.log(return_data);
        // return;
        obj_this.context.setSession(return_data.session);
        setTimeout(obj_this.heartbeat,5000);
        obj_this.props.history.push(obj_this.props.location.url ?? "/");
      }else if(return_data.status == "deleted"){
        alert("You can't login with this email. Please contact administrator.")
      }else {
        obj_this.setState({
          login_error: "Invalid email or password."
        });
      }
      obj_this.context.loading(0);
    }).fail(function () {
      alert("Network error");
    });
  }

  heartbeat(){
    // console.log("heartbeat in login");
    var obj_this = this;
    var heartbeat_timeout;
    this.context.heartbeat_XHR = $.ajax({
      url: process.env.REACT_APP_API + "/api/heartbeat",
      data: this.context.bind_session({}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data);
      if(return_data.status == "success"){
        if(window.location.href.includes("/Dashboard/Services/Edit")){
          if(return_data.session.user_type == "user"){
            obj_this.context.setSession(return_data.session);
            alert("We regret to inform you that your services have been deleted/rejected by the administrator. Please check your email for further information regarding the reason for this action");
            obj_this.props.history.replace('/Dashboard');
          }
        }
        if(obj_this.context.session)
          heartbeat_timeout = setTimeout(obj_this.heartbeat,5000);
        obj_this.context.setSession(return_data.session);
      }else if(return_data.status == "deleted"){
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        alert("Sorry, your account has been deleted. Contact Administrator");
        obj_this.props.history.replace('/');
      }else{
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        // alert("Your session has timed out. Please login again.");
        // obj_this.props.history.replace("/Login");
      }
    }).fail(function(jqXHR,textStatus, errorThrown){
      console.log(errorThrown);
      if(errorThrown == "abort"){
        console.log("aborting");
        clearTimeout(heartbeat_timeout);
      }else{
        setTimeout(obj_this.heartbeat,5000);
      }
    });
  }

  mail_form_submit(e){
    e.preventDefault();
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/findYourAccount",
      data : {
        email : this.state.email
      },
      type : "POST"
    }).done(function(return_data){
      obj_this.context.loading(0);
      console.log(return_data);
      if(return_data.status == "success"){
        if(return_data.mail_status == "success"){
          obj_this.setState({
            mail_status : 1,
            enter_email : 0,
            otp: "",
            user_id : return_data.user_id
          });
        }else{
          alert("Can't send OTP to your email")
          obj_this.setState({
            mail_status : 0,
            enter_email : 0,
          });
        }
      }else if (return_data.status == "failed"){
        alert("No user account with your email");
        obj_this.setState({
          enter_email : 0,
          email : ""
        });
      }else{
        alert("Network error");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  otp_form_submit(e){
    e.preventDefault();
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/resetPasswordOtpValidation",
      data : {
        otp : this.state.otp,
        user_id : this.state.user_id
      },
      type : "POST"
    }).done(function(return_data){
      obj_this.context.loading(0);
      // console.log(return_data);
      if(return_data.status == "success"){
        console.log(return_data.status);
        obj_this.setState({
          enter_email : 0,
          mail_status : 0,
          change_password : 1,
        });
      }else if (return_data.status == "timeout"){
        alert("OTP expired. Please click resend OTP button");
        obj_this.setState({
          otp: ""
        });
      }else{
        obj_this.setState({
          otp : "",
          enter_email : 0,
          mail_status : 0,
          change_password : 0,
        });
        alert("OTP verification failed. Please try again");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  password_form_submit(e){
    e.preventDefault();
    // if(this.state.password.length<8){
    //   this.setState({
    //     password_error : "Your password is not strong enough. Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character."
    //   });
    //   return;
    // }
    // if(this.state.password != this.state.cnf_password){
    //   this.setState({
    //     password_error : "Your password is not strong enough. Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character.",
    //     password : "",
    //     cnf_password : ""
    //   });
    //   return;
    // }

    var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"@#$%',-./:;<=>?`{}~^&*()_+])[A-Za-z\d][A-Za-z\d!"@#$%',-./:;<=>?`{}~^&*()_+]{7,19}$/);
    // return;
    if(!pattern.test(this.state.password)){
      $.alert("Your password is not strong enough. Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character.")
      return;
    }
    if(this.state.password != this.state.cnf_password){
      $.alert("Password mismatch!");
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/saveNewPassword",
      data : {
        user_id : this.state.user_id,
        password : this.state.password,
      },
      type : "POST"
    }).done(function(return_data){
      obj_this.context.loading(0);
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          user_id : "",
          password : "",
          cnf_password : "",
          enter_email : 0,
          mail_status : 0,
          change_password : 0,
        });
        alert("Please login with new password");
      }else{
        obj_this.setState({
          user_id : "",
          password : "",
          cnf_password : "",
          email : "",
          enter_email : 0,
          mail_status : 0,
          change_password : 0,
        });
        alert("Something happened.");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  resend_otp(){
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url : process.env.REACT_APP_API + "/api/resendOTP",
      data : {
        user_id : this.state.user_id
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      obj_this.context.loading(0);
      if(return_data.status == "success"){
        if(return_data.mail_status == "success"){
          alert("New OTP sent to your email")
          obj_this.setState({
            mail_status : 1,
            enter_email : 0,
            user_id : return_data.user_id
          });
        }else{
          alert("Can't send OTP to your email")
          obj_this.setState({
            mail_status : 0,
            enter_email : 0,
          });
        }
      }else if (return_data.status == "failed"){
        alert("No user account with your email");
        obj_this.setState({
          enter_email : 0,
          email : ""
        });
      }else{
        alert("Network error");
      }
    }).fail(function(){
      alert("Network error");
    });
  }

  componentWillUnmount(){
    $(".header_container .header_menu, .header_container .signin_signup_button").css("display","inline  ");
    $(".header_container .header_menu, .header_container .dropdown").css("display","inline-block");
    $(".header_container .register_button").css("display","none");
  }

  render() {
    var preventUser = function() {
      return "Your work will be lost";
  }
  window.addEventListener('beforeunload',preventUser);
    return (
      <div className="login_page_container">
        <div className="login_grid" style={{display:this.context.session?"none":"grid"}}>
          <div className="image_container" style={{ backgroundImage: "url('" + image + "')" }}></div>
          <div className="login_form_container">
            <div className="register_container">
              New to Amrityum ? &nbsp;
              <Link to="/Register" className="user_registration">Register</Link>
            </div>
            {this.state.enter_email?
              <form className="mail_form" onSubmit={this.mail_form_submit}>
                <div className="form_body">
                  <div className="dove_icon" style={{backgroundImage:"url('"+dove_icon+"')"}}></div>
                  <div className="otp_verification">Find Your Account</div>
                  <div className="otp_message">
                      Please enter your email address to find your account.
                    </div>
                  <InputTheme3 
                    class=""
                    label="Email"
                    attrs= {{
                      type : "email",
                      name : "email",
                      value : this.state.email,
                      onChange : this.form_change,
                      required : true,
                    }}
                  />
                  <ButtonTheme2 className="verify_button" label="Find"></ButtonTheme2>
                  <div className="close_form_button"
                  style={{display:this.state.enter_email?"block":"none"}}
                  onClick={() => {this.setState({
                      enter_email : 0,
                      email : "",
                      password : "",
                      cnf_password : "",
                      otp : ""
                    })
                }}>x</div>
                </div>
              </form>
            :this.state.mail_status?
              <form className="otp_verification_form" onSubmit={this.otp_form_submit}>
                <div className="form_body">
                  <div className="dove_icon" style={{backgroundImage:"url('"+dove_icon+"')"}}></div>
                  <div className="otp_verification">OTP Verification</div>
                  <div className="otp_message">
                    <p> Please enter the One Time Password (OTP), received via email({this.state.email}).  
                    If you didn’t get the email, <b>check your junk folder</b> or try again. </p>
                   <p>
                    By continuing you agree to our <Link to="TermsAndConditions">Terms and Conditions</Link>,<Link to="PrivacyPolicy">&nbsp;
                    Privacy Policy</Link> and  <Link to="/CookiesPolicy">Cookies Statement.</Link> 
                   </p>
                    </div>
                  <InputTheme3 
                    class="otp_input"
                    label="OTP"
                    attrs= {{
                      type : "tel",
                      name : "otp",
                      value : this.state.otp,
                      pattern : "^[0-9]{6}$",
                      onChange : this.form_change,
                      required : true,
                      maxLength: 6
                    }}
                  />
                  <div className="resend_otp_button_container">
                    Not received your OTP?&nbsp;
                    <div className="resend_otp_button"
                      onClick={this.resend_otp}
                    > Resend OTP</div>
                  </div>
                  <ButtonTheme2 className="verify_button" label="VERIFY"></ButtonTheme2>
                </div>
                <div className="close_form_button"
                  style={{display:this.state.enter_email?"block":"none"}}
                  onClick={() => {this.setState({
                      mail_status : 0,
                      email : "",
                      password : "",
                      cnf_password : "",
                      otp : ""
                    })
                }}>x</div>
              </form>
            : 
            this.state.change_password?
            <form className="change_password_form" onSubmit={this.password_form_submit}>
              <div className="form_body">
                <div className="dove_icon" style={{backgroundImage:"url('"+dove_icon+"')"}}></div>
                <div className="otp_verification">Change your password</div>
                <div className="otp_message"></div>
                <InputTheme3
                  label = "Password *"
                  attrs = {{
                    name : "password",
                    type : "password",
                    value : this.state.password,
                    onChange : this.form_change,
                    required : true
                  }}
                  className="register_input"
                />
                <InputTheme3
                  label = "Confirm password *"
                  className="cnf_input register_input"
                  attrs = {{
                    name : "cnf_password",
                    type : "password",
                    value : this.state.cnf_password,
                    onChange : this.form_change,
                  }}
                />
                <div className="password_hint"> 	
                  Password must be minimum 8 characters long, contain an uppercase letter, lowercase letter, one or more numbers, and atleast one special character.
                </div>
                <ButtonTheme2 className="verify_button" label="SAVE"></ButtonTheme2>
              </div>
              <div className="close_form_button"
                  style={{display:this.state.enter_email?"block":"none"}}
                  onClick={() => {this.setState({
                      change_password : 0,
                      email : "",
                      password : "",
                      cnf_password : "",
                      otp : ""
                    })
                }}>x</div>
            </form> 
            :
            <form className="login_form" onSubmit={this.form_submit} method="post">
              <div className="form_body">
              <img className="logo_image" onClick={() => { this.props.history.push("/") }} src={logo}/>
              {/* <div className="signin_label">Sign In</div> */}
              <div className="login_error" style={{ visibility: this.state.login_error ? "visible" : "hidden" }}>{this.state.login_error}&nbsp;</div>
              <InputTheme3
                label="Email"
                attrs={{
                  name: "email",
                  value: this.state.email,
                  onChange: this.form_change,
                  type: "email",
                  required: true
                }}
              />
              <InputTheme3
                label="Password"
                attrs={{
                  name: "password",
                  value: this.state.password,
                  onChange: this.form_change,
                  type: "password",
                  required: true
                }}
              />
              <div className="remember_forgot_grid">
                <div>
                  {/* <input 
                    type="checkbox" 
                    value={this.state.keep_me_signed_in} 
                    id="keep_me" 
                    onChange={this.on_checkbox_change}
                    checked={this.state.keep_me_signed_in} />
                  <label htmlFor="keep_me">Keep me signed in.</label> */}
                </div>
                <div onClick={() => this.setState({ enter_email : 1})} className="forgot_container">Forgot password?</div>
                </div>
                <ButtonTheme2 className="login_button" label="LOGIN" />
                <div className="or_sign_in">OR</div>
                <div className="social_media_login_container">
                  <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GG_APP_ID || ''}
                    // onLoginStart={() => this.context.loading(1)}
                    redirect_uri="https://amrityum.com"
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onLoginStart={() => {
                      this.context.loading(1);
                    }}
                    onResolve={({ data }, IResolveParams) => {
                      this.successGoogleLogin(data)
                    }}
                    onReject={err => {
                      console.log(err);
                      this.context.loading(0);
                      return;
                    }}
                    
                  >
                    <div className="social_button">
                      <div className="social_icon" style={{backgroundImage:"url('"+google_icon+"')"}}></div>
                      <div className="social_login_label">Login</div>
                    </div>
                  </LoginSocialGoogle>
                  <LoginSocialFacebook
                    appId={process.env.REACT_APP_FB_APP_ID || ''}
                    fieldsProfile={
                      'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                    }
                    // onLogoutSuccess={this.responseFacebook}
                    redirect_uri="https://amrityum.com"
                    onLoginStart={() => {
                      this.context.loading(1);
                    }}
                    onResolve={({ data }, IResolveParams) => {
                      this.responseFacebook(data);
                    }}
                    onReject={err => {
                      console.log(err);
                      this.context.loading(0);
                      return;
                    }}
                    autoLoad = {false}
                    >
                    <div className="social_button">
                      <div className="social_icon" style={{backgroundImage:"url('"+facebook_icon+"')"}}></div>
                      <div className="social_login_label">Login</div>
                    </div>
                  </LoginSocialFacebook>
                </div>
              </div>
            </form>
            }
            
          </div>
        </div>
        {this.context.session?
        <div className="profile_complete_popup">
          <ManageAccount history = {this.props.history} redirect={1}/>
        </div>:""}
      </div>
    )
  }
}

export default Login;
Login.contextType = MyContext;