import jQuery from 'jquery';
(function ( $ ) {

 $.fn.heightAuto = function () {
 var elem, height, width;
 return this.each(function (i, el) {
     el = jQuery(el);
     elem = el.clone().css({
      "height": "auto",
     }).appendTo($(this).parent());
  //            $(this).parent()
     height = elem.css("height");
      elem.remove();

     el.css({
      "height": height
     });
 });
}


}( jQuery ));
