import React from "react";
import "./MemorialApprovalList.scss";
import $ from "jquery";
import MyContext from "../../MyContext";
import { AddButtonTheme1, ButtonTheme3 } from "../../../res/styler/ButtonThemes/ButtonThemes";
import {Link} from "react-router-dom";
import {FcApproval, FcFullTrash} from 'react-icons/fc';
import memories from "../../../res/images/memorials/default_memories_01.jpg";

class MemorialApprovalList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      memorials : [],
      condolences : [],
      memories : [],
      answers : []
    }
    this.memorial_approved_list_page_Ref = React.createRef();
    this.delete_tribute_message = this.delete_tribute_message.bind(this);
    this.approve_tribute_message = this.approve_tribute_message.bind(this);
    this.delete_answer = this.delete_answer.bind(this);
    this.approve_answer = this.approve_answer.bind(this);

  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url : process.env.REACT_APP_API+"/api/pendingApprovalMemorials",
      data : this.context.bind_session({}),
      type : "POST"
    }).done(function(return_data){
     if(return_data.status == "success"){
      obj_this.setState({
        memorials : return_data.memorials,
        memories : return_data.pending_tributes.filter((item) => item.tribute_type == "memory"),
        condolences : return_data.pending_tributes.filter((item) => item.tribute_type == "note"),
        answers : return_data.pending_answers
      },() => {
        obj_this.memorial_approved_list_page_Ref.current.style.visibility = "visible";
        obj_this.memorial_approved_list_page_Ref.current.style.opacity = 1;
        obj_this.context.loading(0);
      });
     }else{
       obj_this.context.loading(0);
       alert("Network error");
     }
    }).fail(function(){
      alert("Network error");
    })
  }
  
  delete_tribute_message(memorial_tribute_id){
    if (!window.confirm("Delete this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteMemorialTribute",
      data: this.context.bind_session({ memorial_tribute_id: memorial_tribute_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          memories : return_data.pending_tributes.filter((item) =>  item.tribute_type == "memory"),
          condolences : return_data.pending_tributes.filter((item) =>  item.tribute_type == "note"),
        });
        alert("Memorial message deleted");
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }
  

  approve_tribute_message(memorial_tribute_id){
    if (!window.confirm("Approve this message")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveMemorialTribute",
      data: this.context.bind_session({ memorial_tribute_id: memorial_tribute_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          memories : return_data.pending_tributes.filter((item) =>  item.tribute_type == "memory"),
          condolences : return_data.pending_tributes.filter((item) =>  item.tribute_type == "note"),
        });
        alert("Memorial message approved");
      } else {
        alert("Something Went Wrong. Please Try Again");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  delete_answer(answer_id){
    if (!window.confirm("Delete this answer")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteMemorialLifeAnswer",
      data: this.context.bind_session({ answer_id: answer_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          answers : return_data.answers,
        });
        alert("Answer deleted");
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  approve_answer(answer_id){
    if (!window.confirm("Approve this answer")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveMemorialLifeAnswer",
      data: this.context.bind_session({ answer_id: answer_id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          answers : return_data.answers,
        });
      } else {
        alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render(){
    return(
      <div className="memorial_approval_list_page_container page_container" ref={this.memorial_approved_list_page_Ref}>
        <div className="page_header">
          <div className="page_title">Pending Approval</div>
          <div className="new_memorial_button_container">
            {/* <Link to="/Dashboard/Memorials/New">
              <AddButtonTheme1 label="Publish a Memorial"/>
            </Link> */}
          </div>
        </div>
        <div style={{display:this.context.session.user_type == "admin"?"block":"none"}}>
          <div className="table_title">Memorials</div>
          <div className="custom_table_theme1">
            <div className="table_row header">
              <div className="table_cell sl_no">Sl No.</div>
              <div className="table_cell fullname">First Name</div>
              <div className="table_cell notice_type">Surname</div>
              <div className="table_cell datetime">Locality</div>
              <div className="table_cell datetime">State</div>
              <div className="table_cell">Entered By</div>
              <div className="table_cell datetime">Published Date</div>
            </div>
            {this.state.memorials && this.state.memorials.map((item,idx) => (
              <Link className="table_row body"
                key={idx} 
                to={{pathname: "/Memorial/Preview/" + item.id, url : "/Dashboard/Memorials/Approvals"}}
              >
                <div className="table_cell sl_no">{idx+1}</div>
                <div className="table_cell">{item.first_name}</div>
                <div className="table_cell">{item.surname}</div>
                <div className="table_cell">{item.locality}</div>
                <div className="table_cell">{item.flag} {item.state}</div>
                <div className="table_cell">{item.fullname}</div>
                <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              </Link>
            ))}
            <div className="table_row no_results" style={{display:this.state.memorials.length?"none":"block"}}>No results.</div>
          </div>
        </div>
        <div className="table_title">Memories & Stories</div>
        <div className="custom_table_theme1 memories_table">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Memorial</div>
            <div className="table_cell">Image</div>
            <div className="table_cell">Memories & Stories</div>
            <div className="table_cell fullname">Entered By</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.memories && this.state.memories.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
                <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.image_src? <div className="memory_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+item.entered_by+"/tribute_images/"+item.image_src+"')"}}></div>
                : <div className="memory_image" style={{backgroundImage: "url('"+memories+"')"}}></div>
              }
              </div>
              <div className="table_cell" dangerouslySetInnerHTML={{ __html: item.tribute_text }}></div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{display:item.status=="PENDING"?"inline-flex":"none"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approve_tribute_message(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_tribute_message(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.memories.length?"none":"block"}}>No results.</div>
        </div>

        <div className="table_title">Tributes</div>
        <div className="custom_table_theme1 memories_table">
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Memorial</div>
            <div className="table_cell">Tributes</div>
            <div className="table_cell fullname">Entered By</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.condolences && this.state.condolences.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.tribute_text}
              </div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell">{new Date(item.published_date.replace(/-/g, "/")).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{display:item.status=="PENDING"?"inline-flex":"none"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approve_tribute_message(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_tribute_message(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.condolences.length?"none":"block"}}>No results.</div>
        </div>
        <div className="table_title">Let's Reminisce Answers</div>
        <div className="custom_table_theme1" style={{marginBottom:30}}>
          <div className="table_row header">
          <div className="table_cell sl_no">Sl No.</div>
            <div className="table_cell fullname">Memorial</div>
            <div className="table_cell">Answer</div>
            <div className="table_cell fullname">Entered By</div>
            <div className="table_cell datetime">Published Date</div>
            <div className="table_cell">Action</div>
            
          </div>
          {this.state.answers && this.state.answers.map((item,idx) => (
            <div className="table_row body"
              key={idx}
              >
              <div className="table_cell sl_no">{idx+1}</div>
              <div className="table_cell">{item.first_name} {item.surname}</div>
              <div className="table_cell">
                {item.answer}
              </div>
              <div className="table_cell">{item.fullname}</div>
              <div className="table_cell">{new Date(item.created_at).toLocaleDateString('en-GB')}</div>
              <div className="table_cell">
                <div className="action">
                  <div className="tooltip approve" style={{display:item.status=="PENDING"?"inline-flex":"none"}}>
                    <span className="tooltipText approve">Approve</span>
                    <FcApproval className="approve_button" onClick={() => this.approve_answer(item.id)}/>
                  </div>
                  <div className="tooltip delete">
                    <span className="tooltipText delete">Delete</span>
                    <FcFullTrash className="delete_button" onClick={() => this.delete_answer(item.id)}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="table_row no_results" style={{display:this.state.answers.length?"none":"block"}}>No results.</div>
        </div>
      </div>
    )
  }
}

export default MemorialApprovalList;
MemorialApprovalList.contextType = MyContext;