import React from "react";
import "./Home.scss";
import $ from "jquery";
import MyContext from "../MyContext";
import IndiaMap from "../IndiaMap/IndiaMap";
import AwesomeSlider from "react-awesome-slider";
import AwsSliderStyles from "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/open-animation.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import { FaUserCircle, FaRegNewspaper } from "react-icons/fa";
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import slider1 from "../../res/images/home/slider1.jpg";
import slider2 from "../../res/images/home/slider2.jpg";
import slider3 from "../../res/images/home/slider3.jpg";
import service2 from "../../res/images/home/1152398.png";
import name from "../../res/images/home/name.png";
import matter from "../../res/images/home/matter.png";
import work from "../../res/images/home/work.png";
import in_memory from "../../res/images/home/in_memory.png";
import events from "../../res/images/home/events.png";
import service from "../../res/images/home/service.png";
import shop from "../../res/images/home/shop.png";
import blog from "../../res/images/home/blog.png";
import eternal from "../../res/images/home/eternal.png";
import equality from "../../res/images/home/equality.png";
import cooper from "../../res/images/home/cooper.png";
import empower from "../../res/images/home/empower.png";
import { BsStarHalf, BsStarFill, BsArrowRightCircle } from "react-icons/bs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Box from "@mui/material/Box";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { InputTheme5 } from "../../res/styler/InputThemes/InputThemes";
import { Link } from "react-router-dom";
import default_image from "../../res/images/user_icon.png";
import tree1 from "../../res/images/home/tree1.jpg";
import tree2 from "../../res/images/home/tree2.jpg";
import tree3 from "../../res/images/home/tree3.jpg";
import CustomSlider from "../../res/styler/CustomSlider/CustomSlider";

class Home extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      death_notices: [],
      family_notices: [],
      memorials: [],
      graph_data: "",
      testimonials: [],
      memorials: [],
      countries : [],
      // cities: [],
      states: [],
      search_parameters: {
        first_name: "",
        locality: "",
        surname: "",
        city: "",
        state: "",
        country : "",
        middle_name: "",
        family_or_house_name: "",
        nick_name: "",
        nee: "",
        from_date: "",
        to_date: "",
        fromdate: "",
        todate: "",
        notice_type: "",
      },
    };
    this.home_page_Ref = React.createRef();
    this.to_date_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.notice_change = this.notice_change.bind(this);
    // this.city_change = this.city_change.bind(this);
    this.state_change = this.state_change.bind(this);
    this.country_change = this.country_change.bind(this);
    this.reset_search_form = this.reset_search_form.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.handleSliderFirstMount = this.handleSliderFirstMount.bind(this);
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    // console.log(this.props)
    $(".header_container").addClass("transparent_header");
    // $(".header_menu[href='/']").addClass("selected_header_menu");
    var obj_this = this;
    this.context.loading(1);
    // console.log(this.context.session.sid)
    $.ajax({
      url: process.env.REACT_APP_API + "/api/recentNotices",
      type: "POST",
    })
      .done(function (return_data) {
        // console.log(return_data);
        if (
          !obj_this.context.validate_api_data(
            return_data,
            obj_this.props.history
          )
        ) {
          return;
        }
        var graph_data = {};
        return_data.death_states.forEach(function (item) {
          graph_data[item.state_name] = {
            value: item.total,
            id: item.state_id,
          };
         
        });
        return_data.death_notices.map((item) => {
          find_short_full_age(item);
        });

        function find_short_full_age(death_notice){
          if(death_notice.below_one_year){
            var age_in_months = death_notice.age_in_months;
            var short_age ="", full_age="";
            if(age_in_months){
              age_in_months = age_in_months>1?age_in_months+" months":age_in_months+" month";
              short_age = age_in_months;
              full_age = age_in_months+" ";
            }
            var age_in_days = death_notice.age_in_days;
            if(age_in_days){
              age_in_days = age_in_days>1?age_in_days+" days":age_in_days+" day";
              short_age = short_age?short_age:age_in_days;
              full_age+=age_in_days;
            }
              death_notice.short_age= short_age;
          }
        }
        // console.log(graph_data)
        obj_this.setState(prevState => ({
            death_notices: return_data.death_notices,
            memorials: return_data.memorials,
            family_notices: return_data.family_notices,
            graph_data: graph_data,
            countries: return_data.countries,
            testimonials: return_data.testimonials,
          }),
          () => {
            if(obj_this.props.location.type && obj_this.props.location.type == "logout"){
              window.scrollTo(0, 0);
            }
            obj_this.context.loading(0);
            obj_this.home_page_Ref.current.style.visibility = "visible";
            obj_this.home_page_Ref.current.style.opacity = 1;
          }
        );
        obj_this.context.loading(0);
        // obj_this.death_notice_slide_interval = setInterval(obj_this.death_notice_slide_right,2000);
      })
      .fail(function () {
        alert("Network error!!");
      });

    // $(".datamap-state").on("click", function () {
    //   const state_id = $(".state_details_container .state").attr("state_id");
    //   if (state_id) {
    //     const search_parameters = obj_this.state.search_parameters;
    //     search_parameters["state"] = state_id;
    //     obj_this.context.loading(1);
    //     obj_this.props.history.push({
    //       pathname: "/DeathNotices",
    //       search_parameters: JSON.stringify(search_parameters),
    //     });
    //   } else {
    //     alert("No notices");
    //   }
    // });

    $(".show_video_button").on("click", function () {
      $(".video_container_ghost").css({ visibility: "visible", opacity: 1 });
    });

    $(".video_close_button").on("click", function () {
      $(".video_container_ghost").css({ opacity: 0, visibility: "hidden" });
      $("#iframeid").attr("src", $("#iframeid").attr("src"));
    });

    // if (obj_this.sliderRef.current) {
    //   if ('ontouchstart' in window) {
    //     obj_this.sliderRef.current.element.removeEventListener('touchstart', obj_this.sliderRef.current.startHandler);
    //     obj_this.sliderRef.current.element.removeEventListener('touchmove', obj_this.sliderRef.current.moveHandler);
    //     obj_this.sliderRef.current.element.removeEventListener('touchend', obj_this.sliderRef.current.endHandler);
    //   }
    // }
  }

  handleSliderFirstMount(){
    if (this.sliderRef.current) {
      this.sliderRef.current.goto(0); // Go to the 0th slide
    }
  };

  onTransitionStart(e) {
    $(".awssld img").css("transform", "scale(1)");
  }

  onTransitionEnd(e) {
    $(".awssld img").css("transform", "scale(1.1)");
  }

  form_change(e) {
    this.offset_value = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters[e.target.name] = e.target.value;
    this.setState({ search_parameters });
  }

  notice_change(e) {
    const search_parameters = this.state.search_parameters;
    search_parameters["notice_type"] = e.target.value;
    this.setState({ search_parameters });
  }

  country_change(e) {
    this.offset_value = 0;
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/getStates",
      data: { country: e.target.value},
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data.cities);
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.setState({
          states : return_data.states,
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
    const search_parameters = this.state.search_parameters;
    search_parameters['country'] = e.target.value;
    search_parameters['state'] = "";
    this.setState({search_parameters});
  }

  state_change(e) {
    this.offset_value = 0;
    const search_parameters = this.state.search_parameters;
    search_parameters['state'] = e.target.value;
    this.setState({search_parameters});
  }

  // city_change(e){
  //   this.offset_value = 0;
  //   const search_parameters = this.state.search_parameters;
  //   search_parameters['city'] = e.target.value;
  //   this.setState({search_parameters});
  // }

  form_submit(e) {
    e.preventDefault();

    const search_parameters = this.state.search_parameters;
    if (!search_parameters["notice_type"]) {
      $.alert("Notice type required");
      return;
    }
    search_parameters["fromdate"] = search_parameters.from_date
      ? search_parameters.from_date.toLocaleDateString()
      : "";
    search_parameters["todate"] = search_parameters.to_date
      ? search_parameters.to_date.toLocaleDateString()
      : "";
    // this.context.loading(1);
    if (search_parameters["notice_type"] == "death_notice") {
      delete search_parameters["notice_type"];
      this.props.history.push({
        pathname: "/DeathNotices",
        search_parameters: JSON.stringify(search_parameters),
      });
    } else if (search_parameters["notice_type"] == "memorial") {
      delete search_parameters["notice_type"];
      this.props.history.push({
        pathname: "/Memorials",
        search_parameters: JSON.stringify(search_parameters),
      });
    } else if (search_parameters["notice_type"] == "in_memory_notice") {
      delete search_parameters["notice_type"];
      this.props.history.push({
        pathname: "/InMemoryNotices",
        search_parameters: JSON.stringify(search_parameters),
      });
    }
  }

  reset_search_form() {
    this.offset_value = 0;
    const search_parameters = {
      first_name: "",
      locality: "",
      notice_type: "",
      surname: "",
      city: "",
      state: "",
      middle_name: "",
      family_or_house_name: "",
      nick_name: "",
      nee: "",
      from_date: null,
      to_date: null,
      fromdate: "",
      todate: "",
    };
    this.setState({
      search_parameters: search_parameters,
    },() => {
      $(".input_theme5_container input").trigger("focus");
      $(".input_theme5_container input:last").trigger("blur");
    });
  }

  show_hide_advanced_form() {
    if ($(".advanced_search_inputs_container").height() == 0) {
      $(".advanced_search_inputs_container").heightAuto();
      $(".show_advanced_search_button").find(".icon").html("-");
      $(".show_advanced_search_button").find(".label").html("Hide");
    } else {
      $(".advanced_search_inputs_container").css("height", 0);
      $(".show_advanced_search_button").find(".icon").html("+");
      $(".show_advanced_search_button").find(".label").html("Show");
    }
  }

  componentWillUnmount() {
    $(".header_container").removeClass("transparent_header");
  }

  render() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const death_notices = this.state.death_notices;
    return (
      <div className="home_page_container" ref={this.home_page_Ref}>
        <div className="slider_block block">
          <AutoplaySlider
            cssModule={AwsSliderStyles}
            play={true}
            animation="openAnimation"
            cancelOnInteraction={true} // should stop playing on user interaction
            interval={4000}
            onTransitionStart={this.onTransitionStart}
            onTransitionEnd={this.onTransitionEnd}
            transitionDelay={100}
            showtime={false}
            infinite={true}
          >
            <div data-src={slider1} key={0} className="single_slide">
              <div className="slider_content">
                <div className="slider_title">
                  Bid them farewell.<br></br>Hold them in your heart.
                </div>
                <div className="content">
                  Never miss the death notices of your loved ones.
                  <br />
                  Keep informed of funeral arrangements.
                  <br />
                  Pay tributes to those who matter the most.
                </div>
                <div className="buttons_container">
                  <ButtonTheme2
                  className="create_a_death_notice"
                    label="Create a Free Death Notice"
                    onClick={() =>{
                      if(!this.context.session){
                        this.context.confirm("Sign in to create a Death Notice",() => {
                          this.props.history.push({ pathname: "/Login", url : "/Dashboard/DeathNotices/New"})
                        });
                        return;
                      }else{
                        this.props.history.push("/Dashboard/DeathNotices/New");
                      }
                    }}
                  ></ButtonTheme2>
                  {/* <button className="about_us_button" onClick={() => this.props.history.push("/MEMORIALS")}>MEMORIALS</button> */}
                </div>
              </div>
            </div>
            <div data-src={slider2} key={1}>
              <div className="slider_content">
                <div className="slider_title">
                  Cherished Memories.<br></br> Remembered Forever
                </div>
                <div className="content">
                  Immortalise those gone before you.
                  <br />
                  Celebrate and preserve their uniqueness.
                </div>
                <div className="buttons_container">
                  <ButtonTheme2
                  className="create_a_memorial"
                    label="Create a FREE Memorial"
                    onClick={() =>{
                      if(!this.context.session){
                        this.context.confirm("Sign in to create a Memorial",() => {
                          this.props.history.push({ pathname: "/Login", url : "/Dashboard/Memorials/New"})
                        });
                        return;
                      }else{
                        this.props.history.push("/Dashboard/Memorials/New")
                      }
                    }}
                  ></ButtonTheme2>
                  {/* <button className="about_us_button" onClick={() => this.props.history.push("/MEMORIALS")}>MEMORIALS</button> */}
                </div>
              </div>
            </div>
            <div data-src={slider3} key={2}>
              <div className="slider_content">
                <div className="slider_title">
                  Remember. Honour. Commemorate <br />
                </div>
                <div className="content">
                  They mattered, Always.
                  <br />
                  Think of your loved ones on every occasion of their
                  remembrance.
                </div>
                <div className="buttons_container">
                  <ButtonTheme2
                  className="create_a_in_memory_notice"
                    label="create A free in memory NOTICE"
                    onClick={() =>{
                      if(!this.context.session){
                        this.context.confirm("Sign in to create a In-Memory Notice",() => {
                          this.props.history.push({ pathname: "/Login", url : "/Dashboard/InMemoryNotices/New"})
                        });
                        return;
                      }else{
                        this.props.history.push("/Dashboard/InMemoryNotices/New")
                      }
                    }}
                  ></ButtonTheme2>
                  {/* <button className="about_us_button" onClick={() => this.props.history.push("/MEMORIALS")}>In</button> */}
                </div>
              </div>
            </div>
          </AutoplaySlider>
        </div>
        <div className="block little_about_us_block">
          {/* <div className="title">AMRITYUM</div> */}
          <div className="heading">A little about us</div>
          <div className="service_intro">
            Every life has stories that make it worth living. We’ll help you
            tell them. Amrityum offers you a chance to etch your legacy forever.
            Begin here on your journey of immortalising everything. Amrityum
            focuses on life here and after.
          </div>
          <div className="services_grid">
            <div className="single_service_container">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + name + "')" }}
                />
                <div className="service_name">
                  <span>Amrityum</span>. What is in a name?
                </div>
                <p>
                  Amrityum, a complete life logging solution, is one of its
                  kind. Amrityum is a premium website solution offering a
                  comprehensive way to connect, honour and remember every life
                  in its uniqueness and passion. We fully reimagine every
                  occasion of remembrance by providing you and the generations a
                  superlative experience forever.
                </p>
              </div>
            </div>
            <div className="single_service_container service2">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + matter + "')" }}
                />
                <div className="service_name">
                  <span>Amrityum</span>. Why does it matter?
                </div>
                <p>
                  Amrityum is on a mission. We are on a mission to make your
                  life and of your deceased loved one an extended celebration
                  and a constant remembrance. Our mission is at the heart of
                  everything we do. That's why we reimagined the art of
                  commemoration, gathering all facets connected with it under
                  one comprehensive service, Amrityum.
                </p>
              </div>
            </div>
            <div className="single_service_container service3">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + work + "')" }}
                />
                <div className="service_name">
                  <span>Amrityum</span>. How does it work?
                </div>
                <p>
                  Amrityum makes superhuman efforts to provide you with
                  essential tools to celebrate every life, whether alive or
                  dead, in all its richness, uniqueness and complexity. You may
                  directly publish content honouring lives or contribute to an
                  already published profile. We are in the business of helping
                  you tell the story, both yours and others.
                </p>
              </div>
            </div>
          </div>
          <Link to="/AboutUs">
            <ButtonTheme2
              label="Read our story"
              className="read_our_story_button"
            ></ButtonTheme2>
          </Link>
        </div>
        <div className="block graph_search_block">
          <div className="graph_search_block_ghost"></div>
          <div className="graph_search_grid">
            {/* <IndiaMap states={this.state.graph_data} /> */}
            <div className="search_form_container">
              <div className="title1">Search</div>
              <div className="heading1">Quickly Find Amrityum Notices</div>
              <form
                className="search_form"
                onSubmit={this.form_submit}
                method="post"
              >
                <div className="form_grid">
                  <div className="input_container">
                    {/* <div className="title1">First Name</div> */}
                    <InputTheme5
                      label="First Name"
                      className="search_input"
                      attrs={{
                        type: "text",
                        name: "first_name",
                        value: this.state.search_parameters.first_name,
                        onChange: this.form_change,
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">Surname</div> */}
                    <InputTheme5
                      label="Surname"
                      className="search_input"
                      attrs={{
                        type: "text",
                        name: "surname",
                        value: this.state.search_parameters.surname,
                        onChange: this.form_change,
                      }}
                    />
                  </div>
                  <div className="input_container">
                    {/* <div className="title1">STATE</div> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Notice Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.search_parameters.notice_type}
                        label="Age"
                        onChange={this.notice_change}
                      >
                        <MenuItem value="death_notice">Death Notice</MenuItem>
                        <MenuItem value="memorial">Memorials</MenuItem>
                        <MenuItem value="in_memory_notice">
                          In Memory Notice
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <button
                  className="show_advanced_search_button"
                  onClick={this.show_hide_advanced_form}
                  type="button"
                >
                  <div className="button_ghost"></div>
                  {/* <span className="label">Show</span>&nbsp; */}
                  Advanced Search&nbsp;
                  <span className="icon">+</span>
                </button>
                <div className="advanced_search_inputs_container">
                  <div className="advanced_search_inputs">
                    <div className="input_container">
                      {/* <div className="title1">Locality</div> */}
                      <InputTheme5
                        label="Locality"
                        className="search_input"
                        attrs={{
                          type: "text",
                          name: "locality",
                          value: this.state.search_parameters.locality,
                          onChange: this.form_change,
                        }}
                      />
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">Middle Name</div> */}
                      <InputTheme5
                        label="Middle Name"
                        className="search_input"
                        attrs={{
                          type: "text",
                          name: "middle_name",
                          value: this.state.search_parameters.middle_name,
                          onChange: this.form_change,
                        }}
                      />
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">House/Family Name</div> */}
                      <InputTheme5
                        label="House/Family Name"
                        className="search_input"
                        attrs={{
                          type: "text",
                          name: "family_or_house_name",
                          value:
                            this.state.search_parameters.family_or_house_name,
                          onChange: this.form_change,
                        }}
                      />
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">Nick Name</div> */}
                      <InputTheme5
                        label="Nick Name"
                        className="search_input"
                        attrs={{
                          type: "text",
                          name: "nick_name",
                          value: this.state.search_parameters.nick_name,
                          onChange: this.form_change,
                        }}
                      />
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">Nee</div> */}
                      <InputTheme5
                        label="Née"
                        className="search_input"
                        attrs={{
                          type: "text",
                          name: "nee",
                          value: this.state.search_parameters.nee,
                          onChange: this.form_change,
                        }}
                      />
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">STATE</div> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.search_parameters.country}
                          label="Age"
                          onChange={this.country_change}
                        >
                          {this.state.countries &&
                            this.state.countries.map((item, index) => (
                              <MenuItem value={item.id} key={index}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">STATE</div> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          State/Province/County/Region
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={
                            this.state.search_parameters.country ? false : true
                          }
                          value={this.state.search_parameters.state}
                          label="State/Province/County/Region"
                          onChange={this.state_change}
                        >
                          {this.state.states &&
                            this.state.states.map((item, index) => (
                              <MenuItem value={item.id} key={index}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">Nee</div> */}
                      <InputTheme5
                        label="City/Town"
                        className="search_input"
                        attrs={{
                          type: "text",
                          name: "city",
                          value: this.state.search_parameters.city,
                          onChange: this.form_change,
                        }}
                      />
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">FROM DATE</div> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="From date"
                          value={this.state.search_parameters.from_date}
                          onChange={(newValue) => {
                            const search_parameters =
                              this.state.search_parameters;
                            search_parameters["from_date"] = newValue;
                            this.setState({ search_parameters });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          maxDate={new Date()}
                          required={true}
                          openTo="year"
                          views={["year", "month", "day"]}
                          inputFormat="dd/MM/yyyy"
                          allowSameDateSelection={true}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="input_container">
                      {/* <div className="title1">TO DATE</div> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="To date"
                          value={this.state.search_parameters.to_date}
                          onChange={(newValue) => {
                            const search_parameters =
                              this.state.search_parameters;
                            search_parameters["to_date"] = newValue;
                            this.setState({ search_parameters });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          maxDate={new Date()}
                          minDate={
                            this.state.search_parameters.from_date
                              ? new Date(this.state.search_parameters.from_date)
                              : undefined
                          }
                          required={true}
                          openTo="year"
                          views={["year", "month", "day"]}
                          inputFormat="dd/MM/yyyy"
                          allowSameDateSelection={true}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="buttons_container">
                  <ButtonTheme2
                    label="Find"
                    className="search_button"
                  ></ButtonTheme2>
                  <ButtonTheme2
                    label="Reset"
                    type="button"
                    className="reset_form_button"
                    onClick={this.reset_search_form}
                  ></ButtonTheme2>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="block death_notice_grid">
          <div className="notice_details">
            <h2 className="heading">Death Notices</h2>
            <div className="notice_content">
              <p>
                Amrityum provides you with the perfect space to publish
                obituaries, death notices and funeral information. When time is
                less and spaces are afar, you can use our website to inform your
                dear ones quickly and efficiently.
              </p>
              <p>
                {" "}
                The viewers have the option to write condolence messages, place
                virtual mementos, share memories and stories and much more.
                Thus, family and friends have the support of each other to sail
                through the difficult time of their lives. We provide you with
                tools to share your memories online with friends and family and
                safeguard them for future generations.
              </p>
            </div>
            <div className="buttons_container">
              <ButtonTheme2
                label="PUBLISH A DEATH NOTICE"
                className="publish_notice_button"
                onClick={() => {
                  if(!this.context.session){
                    this.context.confirm("Sign in to publish a Death Notice",() => {
                      this.props.history.push({ pathname: "/Login", url : "/Dashboard/DeathNotices/New"})
                    });
                    return;
                  }else{
                    this.props.history.push("/Dashboard/DeathNotices/New");
                  }
                }}
              />
              <Link to="/DeathNotices">
                <ButtonTheme2
                  label="VIEW ALL"
                  className="view_all_notice_button"
                />
              </Link>
            </div>
          </div>
          <div
            className="notice_slider_container"
            // onMouseEnter={() => clearInterval(this.death_notice_slide_interval)}
            // onMouseLeave={() => {this.death_notice_slide_interval = setInterval(this.death_notice_slide_right,2000);}}
          >
            {/* <AutoplaySlider
              play={true}
              animation="foldOutAnimation"
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={3000}
              bullets={false}
              organicArrows={false}
              fillParent={true}
              showtime={false}
              ref={this.sliderRef}
            >
              {death_notices &&
                death_notices.map((item, idx) => (
                  <div
                    className="single_notice_container"
                    key={idx}
                    onClick={() => {
                      this.props.history.push("/DeathNotices/" + item.id);
                    }}
                  >
                    {item.image_src ? (
                      <div
                        className="deceased_image"
                        onClick={(e) =>
                          this.toggleChange(e, this.death_notice_ref)
                        }
                        style={{
                          backgroundImage:
                            "url('" +
                            process.env.REACT_APP_CDN_URL +
                            "/" +
                            item.entered_by +
                            "/death_notice_images/" +
                            item.image_src +
                            "')",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="deceased_image"
                        onClick={(e) =>
                          this.toggleChange(e, this.death_notice_ref)
                        }
                        style={{
                          backgroundImage: "url('" + default_image + "')",
                          borderBottom: "2px solid #6a008c4f"
                        }}
                      ></div>
                    )}
                    <div className="name">
                      {item.first_name} {item.surname}
                      {item.age ? " (" + item.age + ")" : ""}
                    </div>
                    <div className="short_age">
                    {item.below_one_year?"("+item.short_age+")":""}
                    </div>
                    <div className="locality">{item.locality}</div>
                  </div>
                ))}
            </AutoplaySlider> */}
            <CustomSlider
              className="slider_test_class"
              // autoPlay={false} //default autoplay true
              arrows={false}
              interval={3000}
              >
              {death_notices &&
                death_notices.map((item, idx) => (
                  <div
                    className="single_notice_container"
                    key={idx}
                    onClick={() => {
                      this.props.history.push("/DeathNotices/" + item.id);
                    }}
                  >
                    {item.image_src ? (
                      <div
                        className="deceased_image"
                        style={{
                          backgroundImage:
                            "url('" +
                            process.env.REACT_APP_CDN_URL +
                            "/" +
                            item.entered_by +
                            "/death_notice_images/" +
                            item.image_src +
                            "')",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="deceased_image"
                        style={{
                          backgroundImage: "url('" + default_image + "')",
                          borderBottom: "2px solid #6a008c4f"
                        }}
                      ></div>
                    )}
                    <div className="name">
                      {item.first_name} {item.surname}
                      {item.age ? " (" + item.age + ")" : ""}
                    </div>
                    <div className="short_age">
                    {item.below_one_year?"("+item.short_age+")":""}
                    </div>
                    <div className="locality">{item.locality}</div>
                    {/* <div className="duration">{new Date(item.date_of_death).getDate() + "-" +new Date(item.date_of_death).getMonth()+1 + "-"+new Date(item.date_of_death).getFullYear() }</div> */}
                  </div>
                ))}
            </CustomSlider>
          </div>
        </div>
        <div className="memorial_block block">
          <div className="memorial_grid">
            <div className="notice_slider_container">
              {/* <AutoplaySlider
                play={true}
                animation="foldOutAnimation"
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={3000}
                bullets={false}
                organicArrows={false}
                fillParent={true}
                showtime={false}
                infinite={true}
              >
                {this.state.memorials &&
                  this.state.memorials.map((item, idx) => (
                    <div
                      className="single_notice_container"
                      key={idx}
                      onClick={() => {
                        this.props.history.push("/Memorials/" + item.id);
                      }}
                    >
                      {item.image_src ? (
                        <div
                          className="deceased_image"
                          onClick={(e) =>
                            this.toggleChange(e, this.death_notice_ref)
                          }
                          style={{
                            backgroundImage:
                              "url('" +
                              process.env.REACT_APP_CDN_URL +
                              "/" +
                              item.entered_by +
                              "/memorial_images/" +
                              item.image_src +
                              "')",
                          }}
                        ></div>
                      ) : (
                        <div
                          className="deceased_image"
                          onClick={(e) =>
                            this.toggleChange(e, this.death_notice_ref)
                          }
                          style={{
                            backgroundImage: "url('" + default_image + "')",
                            borderBottom: "2px solid #6a008c4f"
                          }}
                        ></div>
                      )}
                      <div className="name">
                        {item.first_name} {item.surname}
                      </div>
                      {isNaN(item.age)?
                      <div className="duration">
                        ({item.age})
                      </div>:
                      <div className="duration">
                      ({new Date(item.date_of_birth).getFullYear()} -{" "}
                      {new Date(item.date_of_death).getFullYear()})
                    </div>
                  }
                    </div>
                  ))}
              </AutoplaySlider> */}
              <CustomSlider
              className="slider_test_class"
              // autoPlay={false} //default autoplay true
              arrows={false}
              interval={3000}
              >
                {this.state.memorials &&
                this.state.memorials.map((item, idx) => (
                  <div
                    className="single_notice_container"
                    key={idx}
                    onClick={() => {
                      this.props.history.push("/Memorials/" + item.id);
                    }}
                  >
                    {item.image_src ? (
                      <div
                        className="deceased_image"
                        style={{
                          backgroundImage:
                            "url('" +
                            process.env.REACT_APP_CDN_URL +
                            "/" +
                            item.entered_by +
                            "/memorial_images/" +
                            item.image_src +
                            "')",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="deceased_image"
                        style={{
                          backgroundImage: "url('" + default_image + "')",
                          borderBottom: "2px solid #6a008c4f"
                        }}
                      ></div>
                    )}
                    <div className="name">
                      {item.first_name} {item.surname}
                    </div>
                    {isNaN(item.age)?
                    <div className="duration">
                      ({item.age})
                    </div>:
                    <div className="duration">
                    ({new Date(item.date_of_birth).getFullYear()} -{" "}
                    {new Date(item.date_of_death).getFullYear()})
                  </div>
                }
                  </div>
                ))}
              </CustomSlider>
            </div>
            <div className="notice_details">
              <div className="heading">Memorials</div>
              <div className="notice_content">
                <p>
                  Amrityum is an online platform where you can create lasting
                  memorials to remember and honour your loved ones who have
                  passed away. They have left behind tons of fond memories and
                  special moments which need to be preserved. Our website offers
                  you a virtual space to remember and celebrate everything that
                  they ever were and preserve their legacy forever.
                </p>
                <p>
                  Everyone can come together and contribute their share of
                  stories. Together we build an ensemble of life stories for
                  generations to imbibe and learn nurturing family bonds and
                  togetherness. Memories are priceless. Preserve and build on
                  them while you can.
                </p>
              </div>
              <div className="buttons_container">
                <ButtonTheme2
                  label="PUBLISH A MEMORIAL"
                  className="publish_notice_button"
                  onClick={() => {
                    if(!this.context.session){
                      this.context.confirm("Sign in to publish a Memorial",() => {
                        this.props.history.push({ pathname: "/Login", url : "/Dashboard/Memorials/New"})
                      });
                      return;
                    }else{
                      this.props.history.push("/Dashboard/Memorials/New");
                    }
                  }}
                />
                <Link to="/Memorials">
                  <ButtonTheme2
                    label="VIEW ALL"
                    className="view_all_notice_button"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="block services_block">
          <div className="title">WHAT ELSE WE OFFER?</div>
          <div className="heading">Our Other Services</div>
          <div className="services_grid">
            <div className="single_service_container">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + in_memory + "')" }}
                />
                <div className="service_name">In Memory Notices</div>
                <p>
                  Amrityum provides a streamlined space to announce death
                  anniversaries, remembrances, thank you notes after a funeral
                  or other celebrations, information on memorial gatherings and
                  more. We endeavour to keep reminiscences alive.
                </p>
                <Link
                  className="show_more_button"
                  to={"/InMemoryNotices"}
                >
                  <div className="button_ghost"></div>
                  <span>VIEW</span>
                  <BsArrowRightCircle className="view_icon" />
                </Link>
              </div>
            </div>
            <div className="single_service_container">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + service2 + "')" }}
                />
                <div className="service_name">Pet Tributes</div>
                <p>
                  We give great importance to human-pet bonds. We do understand
                  how difficult it would be for one to lose a pet who is part of
                  the family. Create everlasting memorials for your beloved
                  pets.
                </p>
                <Link
                  className="show_more_button"
                  to={"/PetTributes"}
                >
                  <div className="button_ghost"></div>
                  <span>VIEW</span>
                  <BsArrowRightCircle className="view_icon" />
                </Link>
              </div>
            </div>
            <div className="single_service_container">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + events + "')" }}
                />
                <div className="service_name">Life Events</div>
                <p>
                  Amrityum honours not just the deceased. Use our platform to
                  wish and bless people on their birthdays, graduation,
                  marriages, anniversaries, and other life events. Make them
                  feel cherished and loved by generating wishes online.
                </p>
                <Link
                  className="show_more_button"
                  to="/ComingSoon"
                >
                  <div className="button_ghost"></div>
                  <span>VIEW</span>
                  <BsArrowRightCircle className="view_icon" />
                </Link>
              </div>
            </div>
            <div className="single_service_container">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + service + "')" }}
                />
                <div className="service_name">Service Directory</div>
                <p>
                  Our Service Directory brings together a host of diverse
                  businesses under one roof to give them the necessary
                  visibility and easy connectedness to their customers. Our
                  database provides relevant businesses opportunities to
                  register, list resources, and provide contact information.
                </p>
                <Link
                  className="show_more_button"
                  to={"/Services"}
                >
                  <div className="button_ghost"></div>
                  <span>VIEW</span>
                  <BsArrowRightCircle className="view_icon" />
                </Link>
              </div>
            </div>
            <div className="single_service_container">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + shop + "')" }}
                />
                <div className="service_name">Shop</div>
                <p>
                  One of our most valued commitments here at Amrityum is our
                  promise to create everything possible to help you in your
                  commitment to honouring loved ones. Hence, Amrityum shop page
                  brings together a selection of items to choose from to make
                  your experience complete.
                </p>
                <a
                  className="show_more_button"
                  href={"https://shop.amrityum.com"} target={"_blank"}
                >
                  <div className="button_ghost"></div>
                  <span>VIEW</span>
                  <BsArrowRightCircle className="view_icon" />
                </a>
              </div>
            </div>
            <div className="single_service_container">
              <div className="service_details">
                <div
                  className="service_icon"
                  style={{ backgroundImage: "url('" + blog + "')" }}
                />
                <div className="service_name">Life Stories</div>
                <p>
                  In Life Stories, you will be able to arrange together a
                  journal of your life, telling the world your story the way you
                  want the world to know you. Be proud of who you are and define
                  how you want to be remembered, even long after you are gone.
                </p>
                <Link
                  className="show_more_button"
                  to={"/ComingSoon"}
                >
                  <div className="button_ghost"></div>
                  <span>VIEW</span>
                  <BsArrowRightCircle className="view_icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="block family_tree_block">
          <div className="family_tree_grid">
            <div className="details_container">
              <div className="heading">
                Family <span style={{ color: "rgb(130, 168, 88)" }}>Tree</span>
              </div>
              <div className="description">
                Amrityum Family Tree offers a visual representation of a
                person’s lineage, tracing relationships to ancestors. Record the
                people, places, and events that make up your family history, and
                then share it with others. <br />
                <br />
                You can add family members and
                branches that you know. Different people can contribute and
                update the family tree. Build a family tree here to help future
                generations to connect and collaborate.
                <br />
                <br />
                Discover your family history with a click! 
              </div>
              <Link className="show_more_button" to="/ComingSoon">
                <div className="button_ghost"></div>
                <span>VIEW</span>
                <BsArrowRightCircle className="view_icon" />
              </Link>
            </div>
            <div
              className="image_container"
              style={{ backgroundImage: "url('" + tree1 + "')" }}
            ></div>
            <div
              className="image_container"
              style={{ backgroundImage: "url('" + tree2 + "')" }}
            ></div>
            {/* <div className="image_container" style={{backgroundImage:"url('"+tree3+"')"}}></div> */}
          </div>
        </div>
        <div className="block core_values_block">
          <div className="core_value_grid">
            <div className="core_value_heading_grid_item">
              <div className="core_value_heading">
                Our Core <span style={{ color: "#32c085" }}>Values</span>
              </div>
              <div className="core_value_details">
                At Amrityum, we believe there is a better way to treasure
                memories, be that of those who passed away, our dear ones or
                ourselves. A more collective, less lonesome way where everyone
                pitches in together to complete each life story giving it
                deserving merit. We are obsessively passionate about it, and our
                mission is to help everyone achieve perpetual remembrance.
              </div>
              <div className="single_core_value_container">
                <div
                  className="core_value_icon"
                  style={{ backgroundImage: "url('" + equality + "')" }}
                />
                <div className="core_value_name">We are Square</div>
                <div className="core_value_description">
                  We acknowledge your basic right to remember and be remembered,
                  no matter your circumstances. We make no discrimination based
                  on your creed, gender, culture or other prejudices. We are
                  universal.
                </div>
              </div>
              <div className="single_core_value_container">
                <div
                  className="core_value_icon"
                  style={{ backgroundImage: "url('" + empower + "')" }}
                />
                <div className="core_value_name">We Empower</div>
                <div className="core_value_description">
                  Amrityum is committed to uniting people to harness support,
                  share inspirations and empower everyone. We are
                  community-focused and believe you should not be left to
                  yourself when remembrance matters.
                </div>
              </div>
            </div>
            <div className="core_value_contents_grid_item">
              <div className="single_core_value_container">
                <div
                  className="core_value_icon"
                  style={{ backgroundImage: "url('" + eternal + "')" }}
                />
                <div className="core_value_name">We are Eternal</div>
                <div className="core_value_description">
                  As our name suggests, we are created for eternity. We desire
                  you and every memory you cherish to remain eternal as well. We
                  never compromise on it. Be everlasting with us!
                </div>
              </div>
              <div className="single_core_value_container">
                <div
                  className="core_value_icon"
                  style={{ backgroundImage: "url('" + cooper + "')" }}
                />
                <div className="core_value_name">We Cooperate</div>
                <div className="core_value_description">
                  We accept there is so much goodness existing already in our
                  world. People selflessly make genuine efforts for this to
                  happen. We, therefore, aspire to cooperate with them. We are
                  better together.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block testimonials_block">
          <div className="title">Testimonials</div>
          <div className="heading">What Our Patrons Say</div>
          <div className="testimonials_intro">
            We take great pride in treasuring your memories and legacies. But
            don&#39;t just take our word for it. Every testimony below is a window into the lives of those who have used Amrityum.
          </div>
          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className="slider_container"
            containerClass="slider_container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2400,
                  min: 1024,
                },
                items: 2,
                partialVisibilityGutter: 100,
              },
              mobile: {
                breakpoint: {
                  max: 780,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 780,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {this.state.testimonials && this.state.testimonials.map((item,idx) => (
              
            <div className="testimonials_item" key={idx}>
              <div className="rating">
                <BsStarFill className="rating_star" />
                <BsStarFill className="rating_star" />
                <BsStarFill className="rating_star" />
                <BsStarFill className="rating_star" />
                <BsStarHalf className="rating_star" />
              </div>
              <div className="message">
               {item.message}
              </div>
              <FaUserCircle
                size={80}
                color="#693780"
                className="testimonial_user_icon"
              />
              <div className="name">{item.name}</div>
              <div className="published_date">{new Date(item.published_date).toLocaleString(new Date().getTimezoneOffset(),{
                weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                })}</div>
            </div>
            ))}
            
          
           
          </Carousel>
        </div>
        <div className="video_container_ghost">
          <div className="video_container">
            <iframe
              width="560"
              height="315"
              id="iframeid"
              src="https://www.youtube.com/embed/35npVaFGHMY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <i className="fa fa-times video_close_button"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default React.memo(Home);
Home.contextType = MyContext;
